import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const overInitialState = {
  loading: false,
  tableData: {}
  // updateOverallAssessment: {}
};

export const OverallAssessmentSlice = createSlice({
  name: 'overallassessment',
  initialState: overInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setTableDataResponse: (state, action) => {
      state.tableData = action.payload.data;
      state.loader = false;
    },
    setupdateOverallAssessmentData: (state) => {
      // state.updateOverallAssessment = action.payload.data;
      state.loader = false;
    }
  }
});

export const getOverallAssessment = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/overall-assessment',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setTableDataResponse(response.data));
  }
};

export const updateOverallAssessment = (jobId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + jobId + '/overall-assessment',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdateOverallAssessmentData(response.data));
  }
};

export const { setLoader, setTableDataResponse, setupdateOverallAssessmentData } =
  OverallAssessmentSlice.actions;

export const OverallAssessmentState = (state) => {
  return state.overallassessment;
};

export default OverallAssessmentSlice.reducer;
