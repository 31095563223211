import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Input from '../../../components/Input';
import Label from '../../../components/Label';
import Autocomplete from 'react-google-autocomplete';
import Header from '../Header';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import {
  businessOverviewState,
  getBusinessOverview,
  updateBusinessOverview,
  setUpdatedbusinessOverviewResponse
} from '../../../slices/slices/BusinessOverviewSlice';
import { businessOverviewSchema } from '../../common/Validation';
import { Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import FormActions from '../../../components/FormActions';
import DialogModal from '../../../components/modal/DialogModal';
import constants from '../../../constant';
import LabelTooltip from '../../../components/tooltip/LabelTooltip';
import label from '../../../LabelConstant';
import ErrorFocus from '../../common/ErrorFocus';
import { useNavigatingAway } from '../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../common/components/DialogLeavingPage';
import { formatAddress } from '../../../utils/helper';

const BusinessOverview = () => {
  const dispatch = useDispatch();
  const [individualsInvolved, setIndividualsInvolved] = useState(EditorState.createEmpty());
  const [businessname, setBusinessname] = useState();
  const [websiteaddress, setWebsiteaddress] = useState();
  const [commencedtrading, setCommencedtrading] = useState();
  const [location, setLocation] = useState();
  const [activity, setActivity] = useState(EditorState.createEmpty());
  const [history, setHistory] = useState(EditorState.createEmpty());
  const [customers, setCustomers] = useState(EditorState.createEmpty());
  const [suppliers, setSuppliers] = useState(EditorState.createEmpty());
  const [competitors, setCompetitors] = useState(EditorState.createEmpty());
  const [advantage, setAdvantage] = useState(EditorState.createEmpty());
  const [employees, setEmployees] = useState(EditorState.createEmpty());
  const [industry, setIndustry] = useState(EditorState.createEmpty());

  const { jobId } = useParams();

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const { businessOverviewResponse, updatebusinessOverviewResponse } =
    useSelector(businessOverviewState);

  useEffect(() => {
    if (businessOverviewResponse && businessOverviewResponse?.business_name) {
      setBusinessname(businessOverviewResponse?.business_name);
    }
    if (businessOverviewResponse && businessOverviewResponse?.website_address) {
      setWebsiteaddress(businessOverviewResponse?.website_address);
    }
    if (businessOverviewResponse && businessOverviewResponse?.commenced_trading) {
      setCommencedtrading(businessOverviewResponse?.commenced_trading);
    }
    if (businessOverviewResponse && businessOverviewResponse?.location) {
      setLocation(businessOverviewResponse?.location);
    }

    if (businessOverviewResponse && businessOverviewResponse?.individuals_involved) {
      const data = businessOverviewResponse?.individuals_involved;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setIndividualsInvolved(editorState);
      }
    }
    if (businessOverviewResponse && businessOverviewResponse?.activity) {
      const data = businessOverviewResponse?.activity;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setActivity(editorState);
      }
    }
    if (businessOverviewResponse && businessOverviewResponse?.history) {
      const data = businessOverviewResponse?.history;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setHistory(editorState);
      }
    }
    if (businessOverviewResponse && businessOverviewResponse?.customers) {
      const data = businessOverviewResponse?.customers;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setCustomers(editorState);
      }
    }
    if (businessOverviewResponse && businessOverviewResponse?.suppliers) {
      const data = businessOverviewResponse?.suppliers;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setSuppliers(editorState);
      }
    }
    if (businessOverviewResponse && businessOverviewResponse?.competitors) {
      const data = businessOverviewResponse?.competitors;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setCompetitors(editorState);
      }
    }
    if (businessOverviewResponse && businessOverviewResponse?.competitive_advantage) {
      const data = businessOverviewResponse?.competitive_advantage;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setAdvantage(editorState);
      }
    }
    if (businessOverviewResponse && businessOverviewResponse?.employees) {
      const data = businessOverviewResponse?.employees;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEmployees(editorState);
      }
    }
    if (businessOverviewResponse && businessOverviewResponse?.industry) {
      const data = businessOverviewResponse?.industry;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setIndustry(editorState);
      }
    }
  }, [businessOverviewResponse]);

  useEffect(() => {
    dispatch(getBusinessOverview(jobId));
  }, []);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeaving(false);
    values.individuals_involved = draftToHtml(
      convertToRaw(values.individuals_involved.getCurrentContent())
    );
    values.activity = draftToHtml(convertToRaw(values.activity.getCurrentContent()));
    values.history = draftToHtml(convertToRaw(values.history.getCurrentContent()));
    values.customers = draftToHtml(convertToRaw(values.customers.getCurrentContent()));
    values.suppliers = draftToHtml(convertToRaw(values.suppliers.getCurrentContent()));
    values.competitors = draftToHtml(convertToRaw(values.competitors.getCurrentContent()));
    values.competitive_advantage = draftToHtml(
      convertToRaw(values.competitive_advantage.getCurrentContent())
    );
    values.employees = draftToHtml(convertToRaw(values.employees.getCurrentContent()));
    values.industry = draftToHtml(convertToRaw(values.industry.getCurrentContent()));
    dispatch(updateBusinessOverview(jobId, values, setErrors));
  };

  useEffect(() => {
    if (updatebusinessOverviewResponse.message) {
      setCanShowDialogLeaving(false);
      dispatch(getBusinessOverview(jobId));
      dispatch(setUpdatedbusinessOverviewResponse({}));
    }
  }, [updatebusinessOverviewResponse.message]);

  const handelChangeIndividualsInvolved = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setIndividualsInvolved(rawDraftContentState);
  };
  const handleChangeActivity = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setActivity(rawDraftContentState);
  };
  const handleChangeHistory = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setHistory(rawDraftContentState);
  };
  const handleChangeCustomers = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setCustomers(rawDraftContentState);
  };
  const handleChangeSUppliers = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setSuppliers(rawDraftContentState);
  };
  const handleChangeCompetitors = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setCompetitors(rawDraftContentState);
  };
  const handleChangeAdvantage = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setAdvantage(rawDraftContentState);
  };
  const handleChangeEmployees = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setEmployees(rawDraftContentState);
  };
  const handleChangeIndustry = (rawDraftContentState) => {
    handleInputChange(rawDraftContentState);
    setIndustry(rawDraftContentState);
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/group-setup/members'}>Group Setup</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>Business Overview</strong>
              </li>
            </ul>
          </div>
          <div className="breadcrumb-right d-flex">
            <DialogModal
              title="Business Overview"
              p1={constants.business1}
              p2={constants.business2}
            />
          </div>
        </div>
        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-start align-items-center">
            <h1>Business Overview</h1>
          </div>
        </div>
        <Formik
          initialValues={{
            business_name: businessname,
            website_address: websiteaddress,
            commenced_trading: commencedtrading,
            location: location,
            individuals_involved: individualsInvolved || '',
            activity: activity || '',
            history: history || '',
            customers: customers || '',
            suppliers: suppliers || '',
            competitors: competitors || '',
            competitive_advantage: advantage || '',
            employees: employees || '',
            industry: industry || '',
            is_completed: 0
          }}
          enableReinitialize
          validationSchema={businessOverviewSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, errors, handleBlur, handleSubmit }) => (
            <>
              {' '}
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form onSubmit={handleSubmit} className="row needs-validation" noValidate>
                <section className="business_overview">
                  <div className="row addmember">
                    <div className="col-md-3">
                      <Label>Business Name</Label>

                      <Input
                        type="text"
                        name="business_name"
                        className="form-control"
                        style={{ height: '49px' }}
                        onChange={(e) => {
                          setFieldValue('business_name', e.target.value);
                          setBusinessname(e.target.value);
                          handleInputChange(e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.business_name}
                        required
                      />
                      <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                        {errors.business_name}
                      </span>
                    </div>
                    <div className="col-md-3">
                      <Label>Website Address</Label>
                      <Input
                        type="text"
                        name="website_address"
                        className="form-control"
                        style={{ height: '49px' }}
                        onChange={(e) => {
                          setFieldValue('website_address', e.target.value);
                          setWebsiteaddress(e.target.value);
                          handleInputChange(e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.website_address}
                      />
                      <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                        {errors.website_address}
                      </span>
                    </div>
                    <div className="col-md-3">
                      <Label>Commenced Trading</Label>
                      <Input
                        type="text"
                        name="commenced_trading"
                        className="form-control"
                        style={{ height: '49px' }}
                        onChange={(e) => {
                          setFieldValue('commenced_trading', e.target.value);
                          setCommencedtrading(e.target.value);
                          handleInputChange(e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.commenced_trading}
                      />
                    </div>
                    <div className="col-md-3">
                      <Label>Location</Label>
                      <Autocomplete
                        autoComplete="off"
                        apiKey={process.env.REACT_APP_API_KEY}
                        style={{ height: '49px' }}
                        name="location"
                        placeholder=""
                        className="form-control"
                        defaultValue={values?.location}
                        onChange={(val) => {
                          setFieldValue('location', val?.target?.value);
                        }}
                        onPlaceSelected={(place) => {
                          let formattedAddress = formatAddress(place);
                          setLocation(formattedAddress);
                          setFieldValue('location', formattedAddress);
                          handleInputChange(formattedAddress);
                        }}
                        onBlur={handleBlur}
                        options={{
                          types: 'street_address',
                          componentRestrictions: { country: ['au', 'nz'] }
                        }}
                      />
                      {/* <textarea
                      className="form-control"
                      name="location"
                      style={{ height: '49px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.location}
                    ></textarea> */}
                    </div>
                  </div>
                  <div className="row addmember">
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.individualTip.key}
                        label={label.businessOverview.individualTip.label}
                        hover_text={label.businessOverview.individualTip.hover_text}
                      />
                      {/* <textarea
                      name="individuals_involved"
                      className="form-control"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.individuals_involved}
                    ></textarea> */}
                      <Field name="individuals_involved">
                        {({ field }) => (
                          <Editor
                            editorState={individualsInvolved}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handelChangeIndividualsInvolved}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.individuals_involved}
                            textareaName="individuals_involved"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.activityTip.key}
                        label={label.businessOverview.activityTip.label}
                        hover_text={label.businessOverview.activityTip.hover_text}
                      />
                      {/* <textarea
                      className="form-control"
                      name="activity"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.activity}
                    ></textarea> */}
                      <Field name="activity">
                        {({ field }) => (
                          <Editor
                            editorState={activity}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChangeActivity}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.activity}
                            textareaName="activity"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.historyTip.key}
                        label={label.businessOverview.historyTip.label}
                        hover_text={label.businessOverview.historyTip.hover_text}
                      />
                      {/* <textarea
                      className="form-control"
                      name="history"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.history}
                    ></textarea> */}
                      <Field name="history">
                        {({ field }) => (
                          <Editor
                            editorState={history}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChangeHistory}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.history}
                            textareaName="history"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.customerTip.key}
                        label={label.businessOverview.customerTip.label}
                        hover_text={label.businessOverview.customerTip.hover_text}
                      />
                      <Field name="customers">
                        {({ field }) => (
                          <Editor
                            editorState={customers}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChangeCustomers}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.customers}
                            textareaName="customers"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                      {/* <textarea
                      className="form-control"
                      name="customers"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.customers}
                    ></textarea> */}
                    </div>
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.supplierTip.key}
                        label={label.businessOverview.supplierTip.label}
                        hover_text={label.businessOverview.supplierTip.hover_text}
                      />
                      {/* <textarea
                      className="form-control"
                      name="suppliers"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.suppliers}
                    ></textarea> */}
                      <Field name="suppliers">
                        {({ field }) => (
                          <Editor
                            editorState={suppliers}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChangeSUppliers}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.suppliers}
                            textareaName="suppliers"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.competitorTip.key}
                        label={label.businessOverview.competitorTip.label}
                        hover_text={label.businessOverview.competitorTip.hover_text}
                      />
                      {/* <textarea
                      className="form-control"
                      name="competitors"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.competitors}
                    ></textarea> */}
                      <Field name="competitors">
                        {({ field }) => (
                          <Editor
                            editorState={competitors}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChangeCompetitors}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.competitors}
                            textareaName="competitors"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.competitiveTip.key}
                        label={label.businessOverview.competitiveTip.label}
                        hover_text={label.businessOverview.competitiveTip.hover_text}
                      />
                      {/* <textarea
                      className="form-control"
                      name="competitive_advantage"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.competitive_advantage}
                    ></textarea> */}
                      <Field name="competitive_advantage">
                        {({ field }) => (
                          <Editor
                            editorState={advantage}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChangeAdvantage}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.competitive_advantage}
                            textareaName="competitive_advantage"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.employeeTip.key}
                        label={label.businessOverview.employeeTip.label}
                        hover_text={label.businessOverview.employeeTip.hover_text}
                      />
                      {/* <textarea
                      className="form-control"
                      name="employees"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employees}
                    /> */}
                      <Field name="employees">
                        {({ field }) => (
                          <Editor
                            editorState={employees}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChangeEmployees}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.employees}
                            textareaName="employees"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <LabelTooltip
                        labelId={label.businessOverview.industryTip.key}
                        label={label.businessOverview.industryTip.label}
                        hover_text={label.businessOverview.industryTip.hover_text}
                      />
                      {/* <textarea
                      className="form-control"
                      name="industry"
                      style={{ height: '100px' }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.industry}
                    /> */}
                      <Field name="industry">
                        {({ field }) => (
                          <Editor
                            editorState={industry}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChangeIndustry}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.industry}
                            textareaName="industry"
                            editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                            stripPastedStyles={true}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <FormActions cancel={'/job/' + jobId + '/dashboard'} values={values} />
                </section>
                <ErrorFocus />
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BusinessOverview;
