import React, { useState } from 'react';

const UsePasswordToggle = () => {
  const [visible, setVisible] = useState(false);

  const Icon = (
    <i
      className={visible ? 'icon-eye' : 'icon-eye-slash'}
      onClick={() => setVisible(!visible)}
      id="icon"
    />
  );
  const InputType = visible ? 'text' : 'password';
  return [InputType, Icon];
};

export default UsePasswordToggle;
