import React from 'react';
import Button from '../../../Button';

export default function FormAction(props) {
  return (
    <div className="btn_area">
      <Button
        type="button"
        name="cancel"
        onClick={props.onhandleCancel}
        className="btn btn-outline-secondary"
      >
        Cancel
      </Button>
      <Button type="submit" name="save" className="btn btn-success">
        Save
      </Button>
    </div>
  );
}
