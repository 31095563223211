import React from 'react';
import TableHeadItem from '../../../../components/job/groupAnalysis/proposedFacilities/FStable/table/TableHead';
import TableRow from '../../../../components/job/groupAnalysis/proposedFacilities/FStable/table/TableRow';
import { formatNumber } from '../../../../pages/common/Misc';

export default function TableData(props) {
  const { theadData, tbodyData, taxData, years } = props;

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="commercial-justified"
        role="tabpanel"
        aria-labelledby="commercial-tab"
      >
        <div className="row justify-content-start toppd">
          <div className="col-md-6">
            <table className="table tx-halftable">
              <thead>
                <tr>
                  {theadData &&
                    theadData.map((h) => {
                      return (
                        <TableHeadItem
                          key={h.key}
                          headClassName={h.headClassName}
                          keyvalue={h.key}
                          item={h.title}
                          colSpan={0}
                          //   sorting={h.sort}
                          //   style={h.style}
                        />
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {tbodyData && tbodyData.length > 0 ? (
                  tbodyData?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        item={item}
                        index={index}
                        theadData={theadData}
                        colSpan={0}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">No Records Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-12">
            <table className="table mb-0 proposed">
              <thead>
                <tr>
                  <th className="text-start sensitisedpro">Consolidated</th>
                  <th className="text-center sensitisedpro">Proposed</th>
                  <th className="text-center sensitisedpro">Sensitised</th>
                </tr>
                <tr className="innerproposed">
                  <th className="text-start"></th>
                  <th className="text-center years">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          {years && years?.length
                            ? years?.map((year) => {
                                return <th key={year}>{year}</th>;
                              })
                            : null}
                        </tr>
                      </thead>
                    </table>
                  </th>
                  <th className="text-center years">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          {years && years?.length
                            ? years?.map((year) => {
                                return <th key={year}>{year}</th>;
                              })
                            : null}
                        </tr>
                      </thead>
                    </table>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  className="accordion-toggle clickable"
                  data-toggle="collapse"
                  data-target="#accordion1"
                >
                  <td className="text-start name">
                    <strong>Adjusted EBITDA </strong> <i className="fa fa-caret-down"></i>
                  </td>
                  <td className="text-center years pd-0">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          {years && years?.length
                            ? years?.map((year) => {
                                let total = 0;
                                return (
                                  <td key={year}>
                                    {taxData && taxData?.length > 0
                                      ? taxData?.map((item) => {
                                          total += Object.assign(
                                            {},
                                            Object.assign(
                                              {},
                                              item?.record?.['tax-calculation']?.proposed
                                            )['adjusted-ebitda']
                                          )[year]
                                            ? Object.assign(
                                                {},
                                                Object.assign(
                                                  {},
                                                  item?.record?.['tax-calculation']?.proposed
                                                )['adjusted-ebitda']
                                              )[year]
                                            : 0;
                                        })
                                      : null}
                                    {formatNumber(total) === '0' ? '' : formatNumber(total)}
                                  </td>
                                );
                              })
                            : null}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="text-end last years pd-0">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          {years && years?.length
                            ? years?.map((year) => {
                                let total = 0;
                                return (
                                  <td key={year} className="skyopt">
                                    {taxData && taxData?.length > 0
                                      ? taxData?.map((item) => {
                                          total += Object.assign(
                                            {},
                                            Object.assign(
                                              {},
                                              item?.record?.['tax-calculation']?.sensitised
                                            )['adjusted-ebitda']
                                          )[year]
                                            ? Object.assign(
                                                {},
                                                Object.assign(
                                                  {},
                                                  item?.record?.['tax-calculation']?.sensitised
                                                )['adjusted-ebitda']
                                              )[year]
                                            : 0;
                                        })
                                      : null}
                                    {formatNumber(total) === '0' ? '' : formatNumber(total)}
                                  </td>
                                );
                              })
                            : null}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr className="accordion-body collapse" id="accordion1">
                  <td colSpan="3" className="gray">
                    <table className="table inner_table memberarea">
                      <thead>
                        <tr>
                          <th className="text-start">Member Name</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxData && taxData?.length > 0
                          ? taxData?.map((item, index) => {
                              let isDisplay = false;
                              years &&
                                years?.length > 0 &&
                                years?.map((val) => {
                                  if (
                                    item?.record?.['tax-calculation']?.proposed?.[
                                      'adjusted-ebitda'
                                    ][val] != 0 ||
                                    item?.record?.['tax-calculation']?.sensitised?.[
                                      'adjusted-ebitda'
                                    ][val] != 0
                                  ) {
                                    isDisplay = true;
                                  }
                                });

                              if (isDisplay && item)
                                return (
                                  <tr key={index}>
                                    <td className="text-start">{item?.member_name}</td>
                                    <td className="pd-0 years">
                                      <table className="table mb-0">
                                        <tbody>
                                          <tr>
                                            {years && years?.length
                                              ? years?.map((year) => {
                                                  return (
                                                    <td key={year}>
                                                      {formatNumber(
                                                        Object.assign(
                                                          {},
                                                          Object.assign(
                                                            {},
                                                            item?.record?.['tax-calculation']
                                                              ?.proposed
                                                          )['adjusted-ebitda']
                                                        )[year]
                                                      )}
                                                    </td>
                                                  );
                                                })
                                              : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td className="text-end pd-0 years">
                                      <table className="table mb-0">
                                        <tbody>
                                          <tr>
                                            {years && years?.length
                                              ? years?.map((year) => {
                                                  return (
                                                    <td key={year} className="skyopt">
                                                      {formatNumber(
                                                        Object.assign(
                                                          {},
                                                          Object.assign(
                                                            {},
                                                            item?.record?.['tax-calculation']
                                                              ?.sensitised
                                                          )['adjusted-ebitda']
                                                        )[year]
                                                      )}
                                                    </td>
                                                  );
                                                })
                                              : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                );
                            })
                          : null}
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr
                  className="accordion-toggle clickable"
                  data-toggle="collapse"
                  data-target="#accordion2"
                >
                  <td className="text-start name">
                    <strong> Adjusted Taxable Income</strong> <i className="fa fa-caret-down"></i>
                  </td>
                  <td className="text-center years pd-0">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          {years && years?.length > 0
                            ? years?.map((year) => {
                                let total = 0;
                                return (
                                  <td key={year}>
                                    {taxData && taxData?.length > 0
                                      ? taxData?.map((item) => {
                                          total += Object.assign(
                                            {},
                                            Object.assign(
                                              {},
                                              item?.record?.['tax-calculation']?.proposed
                                            )['adjusted-taxable-income']
                                          )[year]
                                            ? Object.assign(
                                                {},
                                                Object.assign(
                                                  {},
                                                  item?.record?.['tax-calculation']?.proposed
                                                )['adjusted-taxable-income']
                                              )[year]
                                            : 0;
                                        })
                                      : null}
                                    {formatNumber(total) === '0' ? '' : formatNumber(total)}
                                  </td>
                                );
                              })
                            : null}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="text-end years last pd-0">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          {years && years?.length
                            ? years?.map((year) => {
                                let total = 0;
                                return (
                                  <td key={year} className="skyopt">
                                    {taxData && taxData?.length > 0
                                      ? taxData?.map((item) => {
                                          total += Object.assign(
                                            {},
                                            Object.assign(
                                              {},
                                              item?.record?.['tax-calculation']?.sensitised
                                            )['adjusted-taxable-income']
                                          )[year]
                                            ? Object.assign(
                                                {},
                                                Object.assign(
                                                  {},
                                                  item?.record?.['tax-calculation']?.sensitised
                                                )['adjusted-taxable-income']
                                              )[year]
                                            : 0;
                                        })
                                      : null}
                                    {formatNumber(total) === '0' ? '' : formatNumber(total)}
                                  </td>
                                );
                              })
                            : null}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr className="accordion-body collapse" id="accordion2">
                  <td colSpan="3" className="gray">
                    <table className="table inner_table memberarea">
                      <thead>
                        <tr>
                          <th className="text-start">Member Name</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxData && taxData?.length > 0
                          ? taxData?.map((item, index) => {
                              let isDisplay = false;
                              years &&
                                years?.length > 0 &&
                                years?.map((val) => {
                                  if (
                                    item?.record?.['tax-calculation']?.proposed?.[
                                      'adjusted-taxable-income'
                                    ][val] != 0 ||
                                    item?.record?.['tax-calculation']?.sensitised?.[
                                      'adjusted-taxable-income'
                                    ][val] != 0
                                  ) {
                                    isDisplay = true;
                                  }
                                });
                              if (isDisplay && item) {
                                return (
                                  <tr key={index}>
                                    <td className="text-start">{item?.member_name}</td>
                                    <td className="pd-0 years">
                                      <table className="table mb-0">
                                        <tbody>
                                          <tr>
                                            {years && years?.length
                                              ? years?.map((year) => {
                                                  return (
                                                    <td key={year}>
                                                      {formatNumber(
                                                        Object.assign(
                                                          {},
                                                          Object.assign(
                                                            {},
                                                            item?.record?.['tax-calculation']
                                                              ?.proposed
                                                          )['adjusted-taxable-income']
                                                        )[year]
                                                      )}
                                                    </td>
                                                  );
                                                })
                                              : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td className="text-end pd-0 years">
                                      <table className="table mb-0">
                                        <tbody>
                                          <tr>
                                            {years && years?.length
                                              ? years?.map((year) => {
                                                  return (
                                                    <td key={year} className="skyopt">
                                                      {formatNumber(
                                                        Object.assign(
                                                          {},
                                                          Object.assign(
                                                            {},
                                                            item?.record?.['tax-calculation']
                                                              ?.sensitised
                                                          )['adjusted-taxable-income']
                                                        )[year]
                                                      )}
                                                    </td>
                                                  );
                                                })
                                              : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                );
                              }
                            })
                          : null}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr
                  className="accordion-toggle clickable"
                  data-toggle="collapse"
                  data-target="#accordion3"
                >
                  <td className="text-start name">
                    Tax Payable on Adjusted Taxable Income <i className="fa fa-caret-down"></i>
                  </td>
                  <td className="text-center pd-0">
                    <table className="table mb-0 fotside">
                      <tbody>
                        <tr>
                          {years && years?.length > 0
                            ? years?.map((year) => {
                                let total = 0;
                                if (year) {
                                  return (
                                    <td key={year}>
                                      {taxData && taxData?.length > 0
                                        ? taxData?.map((item) => {
                                            total += Object.assign(
                                              {},
                                              Object.assign(
                                                {},
                                                item?.record?.['tax-calculation']?.proposed
                                              )['tax-payable-on-adjusted-taxable-income']
                                            )[year]
                                              ? Object.assign(
                                                  {},
                                                  Object.assign(
                                                    {},
                                                    item?.record?.['tax-calculation']?.proposed
                                                  )['tax-payable-on-adjusted-taxable-income']
                                                )[year]
                                              : 0;
                                          })
                                        : null}
                                      {formatNumber(total) === '0' ? '' : formatNumber(total)}
                                    </td>
                                  );
                                }
                              })
                            : null}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="text-end pd-0">
                    <table className="table mb-0 fotside">
                      <tbody>
                        <tr>
                          {years && years?.length
                            ? years?.map((year) => {
                                let total = 0;
                                return (
                                  <td key={year}>
                                    {taxData && taxData?.length > 0
                                      ? taxData?.map((item) => {
                                          total += Object.assign(
                                            {},
                                            Object.assign(
                                              {},
                                              item?.record?.['tax-calculation']?.sensitised
                                            )['tax-payable-on-adjusted-taxable-income']
                                          )[year]
                                            ? Object.assign(
                                                {},
                                                Object.assign(
                                                  {},
                                                  item?.record?.['tax-calculation']?.sensitised
                                                )['tax-payable-on-adjusted-taxable-income']
                                              )[year]
                                            : 0;
                                        })
                                      : null}
                                    {formatNumber(total) === '0' ? '' : formatNumber(total)}
                                  </td>
                                );
                              })
                            : null}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr className="accordion-body collapse" id="accordion3">
                  <td colSpan="3" className="gray">
                    <table className="table inner_table memberarea">
                      <thead>
                        <tr>
                          <th className="text-start">Member Name</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxData && taxData?.length > 0
                          ? taxData?.map((item, index) => {
                              let isDisplay = false;
                              years &&
                                years?.length > 0 &&
                                years?.map((val) => {
                                  if (
                                    item?.record?.['tax-calculation']?.proposed?.[
                                      'tax-payable-on-adjusted-taxable-income'
                                    ][val] != 0 ||
                                    item?.record?.['tax-calculation']?.sensitised?.[
                                      'tax-payable-on-adjusted-taxable-income'
                                    ][val] != 0
                                  ) {
                                    isDisplay = true;
                                  }
                                });

                              if (isDisplay && item) {
                                return (
                                  <tr key={index}>
                                    <td className="text-start">{item?.member_name}</td>
                                    <td className="pd-0 years">
                                      <table className="table mb-0">
                                        <tbody>
                                          <tr>
                                            {years && years?.length
                                              ? years?.map((year) => {
                                                  return (
                                                    <td key={year}>
                                                      {formatNumber(
                                                        Object.assign(
                                                          {},
                                                          Object.assign(
                                                            {},
                                                            item?.record?.['tax-calculation']
                                                              ?.proposed
                                                          )[
                                                            'tax-payable-on-adjusted-taxable-income'
                                                          ]
                                                        )[year]
                                                      )}
                                                    </td>
                                                  );
                                                })
                                              : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td className="text-end pd-0 years">
                                      <table className="table mb-0">
                                        <tbody>
                                          <tr>
                                            {years && years?.length
                                              ? years?.map((year) => {
                                                  return (
                                                    <td key={year} className="skyopt">
                                                      {formatNumber(
                                                        Object.assign(
                                                          {},
                                                          Object.assign(
                                                            {},
                                                            item?.record?.['tax-calculation']
                                                              ?.sensitised
                                                          )[
                                                            'tax-payable-on-adjusted-taxable-income'
                                                          ]
                                                        )[year]
                                                      )}
                                                    </td>
                                                  );
                                                })
                                              : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                );
                              }
                            })
                          : null}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
