import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';
import constant from './../../../src/constant';
import moment from 'moment';

export const jobSetupInitialState = {
  loading: false,
  membersData: {},
  success: {},
  jobSettingsDataResponse: {},
  addJobSettingsDataResponse: {},
  GetUpdateMemberResponse: {},
  memberListResponse: {},
  notApplicableDataResponse: {},
  xpmMemberResponse: {},
  selectedMemberResponse: {},
  getGroupmemberdata: {},
  selectJobdata: {}
};

export const JobSetupSlice = createSlice({
  name: 'jobSetup',
  initialState: jobSetupInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setMembersData: (state, action) => {
      state.membersData = action.payload;
      state.loader = false;
    },
    setxpmMemberResponse: (state, action) => {
      state.xpmMemberResponse = action.payload;
      state.loader = false;
    },
    setselectMember: (state, action) => {
      state.selectedMemberResponse = action.payload;
      state.loader = false;
    },
    setSelectJob: (state, action) => {
      state.selectJobdata = action.payload;
      state.loader = false;
    },
    setGroupmemberdata: (state, action) => {
      state.getGroupmemberdata = action.payload;
      state.loader = false;
    },
    setJobSettingsDataResponse: (state, action) => {
      state.jobSettingsDataResponse = action.payload.data;
      state.loader = false;
    },
    setAddJobSettingsDataResponse: (state, action) => {
      state.addJobSettingsDataResponse = action.payload;
      state.loader = false;
    },
    setGetUpdateMemberResponse: (state, action) => {
      state.GetUpdateMemberResponse = action.payload;
      state.loader = false;
    },
    setSuccessResponse: (state, action) => {
      state.success = action.payload;
      state.loader = false;
    },
    setmemberListResponse: (state, action) => {
      state.memberListResponse = action.payload;
      state.loader = false;
    },
    setUpdatedMarkAllResponse: (state, action) => {
      state.updatedMarkAllResponse = action.payload;
      state.loader = false;
    },
    setNotApplicable: (state, action) => {
      state.notApplicableDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getMembers =
  (id, limit = 500, setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/members?limit=' + limit,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setMembersData(response.data));
    }
  };

export const setMemberListPosition = (id, memberId, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' + id + '/members/' + memberId + '/position',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
  }
};

export const selectMember = (xpm_job_id, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' + xpm_job_id + '/xpm/members',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setselectMember(response.data));
  }
};

export const getgroupMember = (xpm_job_id, group_id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + xpm_job_id + '/xpm/group/' + group_id + '/members',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGroupmemberdata(response.data));
  }
};

export const importxpmMember = (xpm_job_id, tenantId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + xpm_job_id + '/xpm/members',
    setErrors,
    dispatch,
    false,
    true,
    { 'xero-tenant-id': tenantId }
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setxpmMemberResponse(response.data));
  }
};

export const selectJob = (jobId, tenantId, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' + jobId + '/xpm',
    {},
    setErrors,
    dispatch,
    false,
    false,
    {
      'xero-tenant-id': tenantId
    }
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSelectJob(response.data));
  }
  return response;
};

export const addMember = (id, data, setErrors, navigate) => async (dispatch) => {
  // data.date_of_birth =
  //   data.date_of_birth !== '' && data.date_of_birth !== null
  //     ? moment(data.date_of_birth).format('DD-MM-YYYY')
  //     : '';
  // data.date_of_birth = moment(data.date_of_birth, 'YYYY/MM/DD').format('DD-MM-YYYY');
  data = {
    ...data,
    date_of_birth: data?.date_of_birth ? moment(data.date_of_birth).format('DD-MM-YYYY') : ''
  };

  let response;
  let {
    abn,
    acn,
    activity,
    directors,
    legal_name,
    shareholders,
    trading_name,
    type,
    website,
    date_of_birth,
    occupation,
    first_name,
    last_name,
    preferred_first_name,
    other_names,
    beneficiary,
    partners,
    beneficiaries,
    trust_type,
    trust_name,
    trustee_name,
    is_completed,
    unitholders,
    members,
    title,
    internal_notes,
    report_comments
  } = data;
  // initial data
  type === '' ? (type = 'company') : type;
  // const is_completed = 1;
  if (type === 'company') {
    let dataVal = {
      legal_name,
      abn,
      acn,
      trading_name,
      activity,
      website,
      directors,
      shareholders,
      type,
      is_completed,
      internal_notes,
      report_comments
    };
    response = await clientService.post('jobs/' + id + '/members', dataVal, setErrors, dispatch);
  } else if (type === 'trust') {
    let dataVal = {
      trustee_name,
      trust_name,
      acn,
      abn,
      activity,
      trust_type,
      trading_name,
      beneficiaries,
      beneficiary,
      // website,
      shareholders,
      directors,
      type,
      is_completed,
      unitholders,
      members,
      internal_notes,
      report_comments
    };
    response = await clientService.post('jobs/' + id + '/members', dataVal, setErrors, dispatch);
  } else if (type === 'partnership') {
    let dataVal = {
      legal_name,
      abn,
      trading_name,
      activity,
      website,
      partners,
      type,
      is_completed,
      internal_notes,
      report_comments
    };
    response = await clientService.post('jobs/' + id + '/members', dataVal, setErrors, dispatch);
  } else if (type === 'sole-trader') {
    let dataVal = {
      legal_name,
      abn,
      trading_name,
      activity,
      // website,
      type,
      is_completed,
      internal_notes,
      report_comments
    };
    response = await clientService.post('jobs/' + id + '/members', dataVal, setErrors, dispatch);
  } else if (type === 'individual') {
    let dataVal = {
      title,
      first_name,
      last_name,
      other_names,
      preferred_first_name,
      type,
      occupation,
      date_of_birth,
      is_completed,
      internal_notes,
      report_comments
    };
    response = await clientService.post('jobs/' + id + '/members', dataVal, setErrors, dispatch);
  } else if (type === 'other') {
    let dataVal = {
      legal_name,
      acn,
      type,
      abn,
      trading_name,
      activity,
      website,
      is_completed,
      internal_notes,
      report_comments
    };
    response = await clientService.post('jobs/' + id + '/members', dataVal, setErrors, dispatch);
  }

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
    navigate('/job/' + id + '/group-setup/members');
  }
  return response;
};

export const getJobSettingsData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get('jobs/' + id + '/settings', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setJobSettingsDataResponse(response.data));
  }
};

export const addJobSettingsData = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put('jobs/' + id + '/settings', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAddJobSettingsDataResponse(response.data));
  }
};

export const updateMember = (id, memberId, data, setErrors, navigate) => async (dispatch) => {
  data = {
    ...data,
    date_of_birth: data?.date_of_birth_day
      ? moment(data.date_of_birth_day).format('DD-MM-YYYY')
      : ''
  };

  let {
    abn,
    acn,
    activity,
    directors,
    legal_name,
    shareholders,
    title,
    trading_name,
    type,
    website,
    date_of_birth,
    occupation,
    first_name,
    last_name,
    preferred_first_name,
    other_names,
    beneficiary,
    beneficiaries,
    partners,
    trust_type,
    trust_name,
    trustee_name,
    is_completed,
    unitholders,
    members,
    internal_notes,
    report_comments
  } = data;

  // initial data
  type === '' ? (type = 'company') : type;
  // const is_completed = 1;
  let dataVal;
  if (type === 'company') {
    dataVal = {
      legal_name,
      abn,
      acn,
      trading_name,
      activity,
      website,
      directors,
      shareholders,
      type,
      is_completed,
      internal_notes,
      report_comments
    };
  } else if (type === 'trust') {
    dataVal = {
      trustee_name,
      trust_name,
      acn,
      abn,
      trust_type,
      trading_name,
      activity,
      beneficiary,
      beneficiaries,
      shareholders,
      directors,
      type,
      is_completed,
      unitholders,
      members,
      internal_notes,
      report_comments
    };
  } else if (type === 'partnership') {
    dataVal = {
      legal_name,
      abn,
      trading_name,
      activity,
      website,
      partners,
      type,
      is_completed,
      internal_notes,
      report_comments
    };
  } else if (type === 'sole-trader') {
    dataVal = {
      legal_name,
      abn,
      trading_name,
      activity,
      website,
      type,
      is_completed,
      internal_notes,
      report_comments
    };
  } else if (type === 'individual') {
    dataVal = {
      title,
      first_name,
      last_name,
      other_names,
      preferred_first_name,
      type,
      occupation,
      date_of_birth,
      is_completed,
      internal_notes,
      report_comments
    };
  } else if (type === 'other') {
    dataVal = {
      legal_name,
      acn,
      abn,
      trading_name,
      activity,
      website,
      is_completed,
      type,
      internal_notes,
      report_comments
    };
  }

  const response = await clientService.put(
    'jobs/' + id + '/members/' + memberId,
    dataVal,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
    navigate('/job/' + id + '/group-setup/members');
  }
  return response;
};
export const getUpdateMember = (jobId, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + jobId + '/members/' + memberId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGetUpdateMemberResponse(response.data));
  }
};

export const deleteMember = (id, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + id + '/members/' + memberId,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
  }
};

export const getMemberList = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members-list',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setmemberListResponse(response.data));
  }
};

export const NotApplicable = (id, memberId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/members/' + memberId + '/not-applicable',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setNotApplicable(response.data));
  }
};

export const updateMarkAll = (id, memberId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/members/' + memberId + '/mark-all',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedMarkAllResponse(response.data));
  }
};

export const getABN = (val, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    '/getAbn',
    setErrors,
    dispatch,
    false,
    true,
    {},
    `https://abr.business.gov.au/json/MatchingNames.aspx?name=${val}&maxResults=5&guid=${constant.guid_abn}`
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setMembersData(response.data));
  }
};

export const {
  setLoader,
  setMembersData,
  setJobSettingsDataResponse,
  setAddJobSettingsDataResponse,
  setSuccessResponse,
  setGetUpdateMemberResponse,
  setmemberListResponse,
  setNotApplicable,
  setUpdatedMarkAllResponse,
  setxpmMemberResponse,
  setselectMember,
  setGroupmemberdata,
  setSelectJob
} = JobSetupSlice.actions;

export const jobSetupState = (state) => {
  return state.jobSetup;
};

export default JobSetupSlice.reducer;
