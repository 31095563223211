import React from 'react';
import DebtServicingTable from './table/index';
import TableHeadItem from '../proposedFacilities/FStable/table/TableHead';
import { formatNumber } from '../../../../pages/common/Misc';

export default function Sensitised(props) {
  const { mainheadData, headData, tbodyData, pbodyData, personaltotalValue, businesstotalValue } =
    props;
  const getHeadData = (title) => {
    return [{ ...headData[0], title: title }, ...headData.slice(1, headData.length)];
  };

  return (
    <>
      <table className="table debtservicing mr-0">
        <thead>
          <tr>
            {mainheadData.map((h) => {
              return (
                <TableHeadItem
                  key={h.key}
                  headClassName={h.headClassName}
                  keyvalue={h.key}
                  sorting={h.sort}
                  item={h.title}
                  colSpan={h.colSpan}
                  style={h.style}
                />
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="16" className="darkgary">
              <DebtServicingTable
                theadData={headData}
                tbodyData={tbodyData}
                headSlice={1}
                customClass="debtbusiness"
                colSpan={16}
                tfootData={
                  <tfoot>
                    <tr>
                      <td colSpan="4" className="text-start">
                        Subtotal
                      </td>
                      <td className="text-center totalarea">
                        {formatNumber(businesstotalValue.limit)}
                      </td>
                      <td colSpan="2"></td>
                      <td className="text-center totalarea">
                        {formatNumber(businesstotalValue.balloon)}
                      </td>
                      <td></td>
                      <td className="text-center totalarea">
                        {formatNumber(businesstotalValue.repaytype)}
                      </td>
                      <td></td>
                      <td className="text-center totalarea">
                        {formatNumber(businesstotalValue.interestpa)}
                      </td>
                      <td className="text-center totalarea">
                        {formatNumber(businesstotalValue.repaypa)}
                      </td>
                      <td className="act_area"></td>
                    </tr>
                  </tfoot>
                }
              />
              <DebtServicingTable
                theadData={getHeadData('Personal')}
                tbodyData={pbodyData}
                headSlice={1}
                customClass="debtbusiness"
                colSpan={16}
                tfootData={
                  <tfoot>
                    <tr>
                      <td colSpan="4" className="text-start">
                        Subtotal
                      </td>
                      <td className="text-center totalarea">
                        {formatNumber(personaltotalValue.limit)}
                      </td>
                      <td colSpan="2"></td>
                      <td className="text-center totalarea">
                        {formatNumber(personaltotalValue.balloon)}
                      </td>
                      <td></td>
                      <td className="text-center totalarea">
                        {formatNumber(personaltotalValue.repaytype)}
                      </td>
                      <td></td>
                      <td className="text-center totalarea">
                        {formatNumber(personaltotalValue.interestpa)}
                      </td>
                      <td className="text-center totalarea">
                        {formatNumber(personaltotalValue.repaypa)}
                      </td>
                      <td className="act_area"></td>
                    </tr>
                  </tfoot>
                }
              />
              <table className="table lasttotal">
                <thead>
                  <tr>
                    <th colSpan="4" className="text-start">
                      Total
                    </th>
                    <th className="text-center __totalarea">
                      {formatNumber(businesstotalValue.limit + personaltotalValue.limit)}
                    </th>
                    <th colSpan="2" style={{ width: '13%' }}></th>
                    <th className="text-center totalarea" style={{ width: '118px' }}>
                      {formatNumber(businesstotalValue.balloon + personaltotalValue.balloon)}
                    </th>
                    <th colSpan="1" style={{ width: '7%' }}></th>
                    <th className="text-center totalarea">
                      {formatNumber(businesstotalValue.repaytype + personaltotalValue.repaytype)}
                    </th>
                    <th className="eptspace" style={{ width: '83px' }}></th>
                    <th className="text-center totalarea" style={{ width: '118px' }}>
                      {formatNumber(businesstotalValue.interestpa + personaltotalValue.interestpa)}
                    </th>
                    <th className="text-center totalarea" style={{ width: '98px' }}>
                      {formatNumber(businesstotalValue.repaypa + personaltotalValue.repaypa)}
                    </th>
                    <th className="act_area"></th>
                  </tr>
                </thead>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
