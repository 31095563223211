import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const groupFinancialInitialState = {
  loading: false,
  businessFinancialDataResponse: {},
  businessFinancialResponse: {},
  personalFinancialDataResponse: {},
  personalFinancialResponse: {},
  deleteFinancialDataResponse: {},
  currentRentalIncomeDataResponse: {},
  currentRentalIncomeResponse: {}
};

export const GroupFinancialSlice = createSlice({
  name: 'groupFinancial',
  initialState: groupFinancialInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setBusinessFinancialDataResponse: (state, action) => {
      state.businessFinancialDataResponse = action.payload.data;
      state.loader = false;
    },
    setBusinessFinancialResponse: (state, action) => {
      state.businessFinancialResponse = action.payload;
      state.loader = false;
    },
    setPersonalFinancialDataResponse: (state, action) => {
      state.personalFinancialDataResponse = action.payload.data;
      state.loader = false;
    },
    setPersonalFinancialResponse: (state, action) => {
      state.personalFinancialResponse = action.payload;
      state.loader = false;
    },
    setDeleteFinancialDataResponse: (state, action) => {
      state.deleteFinancialDataResponse = action.payload;
      state.loader = false;
    },
    setCurrentRentalIncomeDataResponse: (state, action) => {
      state.currentRentalIncomeDataResponse = action.payload.data;
      state.loader = false;
    },
    setCurrentRentalIncomeResponse: (state, action) => {
      state.currentRentalIncomeResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getBusinessFinancialData =
  (id, type = 'business', setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/group-analysis/financial-analysis?type=' + type,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setBusinessFinancialDataResponse(response.data));
    }
  };

export const updateBusinessFinancial = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/group-analysis/financial-analysis',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setBusinessFinancialResponse(response.data));
  }
};

export const getPersonalFinancialData =
  (id, type = 'personal', setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/group-analysis/financial-analysis?type=' + type,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setPersonalFinancialDataResponse(response.data));
    }
  };

export const updatePersonalFinancial = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/group-analysis/financial-analysis',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setPersonalFinancialResponse(response.data));
  }
};

export const deleteFinancial = (jobId, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/members/' + memberId + '/financials',
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteFinancialDataResponse(response.data));
  }
};

export const getCurrentRentalIncomeData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/group-analysis/rental-income',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setCurrentRentalIncomeDataResponse(response.data));
  }
};

export const updateCurrentRentalIncome = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/group-analysis/rental-income',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setCurrentRentalIncomeResponse(response.data));
  }
};

export const {
  setLoader,
  setBusinessFinancialDataResponse,
  setBusinessFinancialResponse,
  setPersonalFinancialDataResponse,
  setPersonalFinancialResponse,
  setDeleteFinancialDataResponse,
  setCurrentRentalIncomeDataResponse,
  setCurrentRentalIncomeResponse
} = GroupFinancialSlice.actions;

export const groupFinancialState = (state) => {
  return state.groupFinancial;
};

export default GroupFinancialSlice.reducer;
