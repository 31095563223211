import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const rentalInitialState = {
  loading: false,
  proposeddebtservicingList: [],
  updatecomment: {}
};

export const ProposedDebtServicingSlice = createSlice({
  name: 'proposedDebtServicing',
  initialState: rentalInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setproposeddebtServicing: (state, action) => {
      state.proposeddebtservicingList = JSON.parse(JSON.stringify(action.payload.data));
    },
    setupdatecomment: (state, action) => {
      state.updatecomment = action.payload.data;
    }
  }
});

export const getproposedDebtServicing = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/group-analysis/proposed-debt-servicing',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setproposeddebtServicing(response.data));
  }
};

export const updateComment = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/group-analysis/proposed-debt-servicing',
    data,
    setErrors,
    dispatch,
    true
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdatecomment(response.data));
  }
};

export const { setLoader, setproposeddebtServicing, setupdatecomment } =
  ProposedDebtServicingSlice.actions;

export const proposedDebtServicingState = (state) => {
  return state.proposedDebtServicing;
};

export default ProposedDebtServicingSlice.reducer;
