import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import Commercial from './commercial/Commercial';
import Residential from './residential/Residential';
import { getMemberData, facilityState } from '../../../../slices/slices/FacilitySlice';
import { useDispatch, useSelector } from 'react-redux';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType } from '../../../common/Misc';
import Info from '../../../../components/Info';
import { updateMarkAll } from '../../../../slices/slices/JobSetupSlice';

const Rentals = () => {
  const { jobId, memberId } = useParams();
  const [searchParams] = useSearchParams();

  const [status, setStatus] = useState(searchParams.get('tab') ? 'residential' : 'commercial');
  const memberDataResponse = useSelector(facilityState);
  const dispatch = useDispatch();

  const handleTabChange = (status) => {
    setStatus(status);
  };

  const handleMarkAllCompleted = (setErrors) => {
    let formData = { module: 'rental' };
    // formData.append('module', 'facility');
    dispatch(updateMarkAll(jobId, memberId, formData, setErrors));
  };

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
  }, []);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <strong>Rental</strong>
              </li>
            </ul>
            <h1>Rentals - {displayNameFromType(memberDataResponse?.memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <Link
              to={
                status === 'commercial'
                  ? '/job/' + jobId + '/data-entry/' + memberId + '/add-commercial-rental'
                  : '/job/' + jobId + '/data-entry/' + memberId + '/add-residential-rental'
              }
            >
              <button type="button" className="green topbtn">
                <i className="icon-plus"></i> Rental Property
              </button>
            </Link>
            <DialogModal
              title="Rentals"
              p1={
                status === 'commercial' ? constants.rentalCommercial1 : constants.rentalResidential1
              }
              p2={
                status === 'commercial' ? constants.rentalCommercial2 : constants.rentalResidential2
              }
            />
          </div>
        </div>
        <div className="mt-30" style={{ marginRight: '-15px' }}>
          <Info />
        </div>
        <div className="white-card mt-30">
          <div className="rentalview">
            <Tabs
              className="nav nav-tabs d-flex"
              id="myTabjustified"
              role="tablist"
              activeKey={status}
              onSelect={(status) => handleTabChange(status)}
            >
              <Tab
                className="nav-item nav-link"
                role="presentation"
                eventKey="commercial"
                title="Commercial"
                style={{ padding: '0' }}
              >
                <Commercial />
              </Tab>
              <Tab
                className="nav-item nav-link"
                role="presentation"
                eventKey="residential"
                title="Residential"
                style={{ padding: '0' }}
              >
                <Residential />
              </Tab>
            </Tabs>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '30px',
            marginBottom: '20px'
          }}
        >
          <button
            className="btn green"
            onClick={() => {
              handleMarkAllCompleted();
            }}
          >
            Section Completed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Rentals;
