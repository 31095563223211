import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditTableRows from './EditTableRows';
import tableHead from '../../../../../TableHeadConstant';
import LabelTooltip from '../../../../../components/tooltip/LabelTooltip';
import { updateFinancialDate } from '../../../../../slices/slices/FinancialsSlice';
import { dataEntryState } from '../../../../../slices/slices/DataEntrySlice';

const FixedColumnTableRows = ({
  category,
  data,
  years,
  fixedName,
  type,
  noAddition,
  negative,
  handleRemove,
  handleInputChange,
  isSelectable,
  itemIndex,
  manageExtrap
}) => {
  FixedColumnTableRows.propTypes = {
    category: PropTypes.string,
    data: PropTypes.object,
    years: PropTypes.array,
    fixedName: PropTypes.string,
    type: PropTypes.any,
    itemIndex: PropTypes.any,
    noAddition: PropTypes.bool,
    negative: PropTypes.bool,
    handleRemove: PropTypes.func,
    handleInputChange: PropTypes.any,
    isSelectable: PropTypes.bool,
    manageExtrap: PropTypes.func
  };
  const dispatch = useDispatch();
  const { selectedMember } = useSelector(dataEntryState);

  const [items, setItems] = useState(
    data?.items
      ? Object.entries(data?.items).filter((value) => {
          return (
            String(value[1]?.category) === String(category) ||
            String(value[1]?.bundleName) === String(category)
          );
        })
      : {}
  );
  // Show new row
  const handleAddRow = (index) => {
    let obj = {};
    let emptyDate = {};
    if (data?.key === 'surplus_profit') {
      emptyDate = { profit: {}, percentage: {}, type: {} };
      years.forEach((year) => {
        emptyDate.profit[year] = '';
        emptyDate.percentage[year] = '';
        emptyDate.type[year] = 'add';
      });
      obj = {
        title: category === 'add' || category === 'less' ? 'Select if Applicable' : '',
        data: emptyDate,
        category: category,
        member_id: '',
        deletable: true
      };
    } else {
      years.forEach((year) => {
        emptyDate[year] = '';
      });
      obj = {
        title: category === 'add' || category === 'less' ? 'Select if Applicable' : '',
        data: emptyDate,
        category: category,
        deletable: true
      };
    }
    // let tempItems = JSON.parse(JSON.stringify(items));
    // let index = Object.entries(data.items).length;

    handleUpdate(index ? index : data.items.length, obj, index);
    // tempItems.push(Object.entries(obj)[0]);
    // setItems(tempItems);
  };

  const handleUpdate = (index, financialData, year = '') => {
    let updates = {};
    updates['key'] = data.key;
    updates['index'] = index;
    updates['data'] = financialData;
    updates['member_id'] = '';
    if (year !== '') {
      updates['year'] = year;
    }
    if (financialData !== null) {
      dispatch(updateFinancialDate({ ...updates, type: selectedMember.type }));
    }
  };

  useEffect(() => {
    setItems(
      data?.items
        ? Object.entries(data.items).filter((value) => {
            return value[1]?.category === category || value[1]?.bundleName === category;
          })
        : []
    );
  }, [data]);

  return (
    <>
      <Table.Row key={category} className={''}>
        <Table.Cell rowSpan={items.length + 1} style={{ verticalAlign: 'top' }}>
          {!noAddition ? (
            <>
              <i
                className="add"
                onClick={(e) => {
                  handleAddRow(itemIndex + items.length);
                  handleInputChange(e.target.value);
                }}
              >
                +
              </i>
            </>
          ) : null}
          {fixedName === 'Rental' && (
            <LabelTooltip
              labelId={tableHead.businessFinancial.rentalTip.key}
              label={tableHead.businessFinancial.rentalTip.label}
              hover_text={tableHead.businessFinancial.rentalTip.hover_text}
            />
          )}
          {fixedName !== 'Rental' && fixedName}
        </Table.Cell>
      </Table.Row>
      {/* {type ? (
        <EditTableRows
          data_key={data.key}
          items={Object.values(items)}
          years={years}
          handleRemove={handleRemove}
          handleInputChange={handleInputChange}
          colspan="1"
          negative={negative}
        />
      ) : ( */}
      <EditTableRows
        manageExtrap={manageExtrap}
        data_key={data?.key}
        items={Object.values(items)}
        years={years}
        handleRemove={handleRemove}
        handleInputChange={handleInputChange}
        colspan="1"
        negative={negative}
        isSelectable={isSelectable}
        type={type ? type : null}
        itemIndex={itemIndex}
      />
      {/* )} */}
    </>
  );
};

export default FixedColumnTableRows;
