import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { displayNameFromType } from '../pages/common/Misc';
import Button from '../components/Button';
import { getxero, refreshXero } from '../slices/slices/FinancialsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { jobSetupState } from '../slices/slices/JobSetupSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { getTaxRateByMember, getFinancialData } from '../slices/slices/FinancialsSlice';
export default function XeroimportModal(props) {
  const { show, onHide, member } = props;
  const { jobSettingsDataResponse } = useSelector(jobSetupState);
  const currentMonth = new Date().getMonth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId, memberId } = useParams();

  var monthNames = [
    { label: 'Jan', value: '01' },
    { label: 'Feb', value: '02' },
    { label: 'Mar', value: '03' },
    { label: 'Apr', value: '04' },
    { label: 'May', value: '05' },
    { label: 'Jun', value: '06' },
    { label: 'July', value: '07' },
    { label: 'Aug', value: '08' },
    { label: 'Sep', value: '09' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' }
  ];

  const onChangehandle = (month) => {
    localStorage.setItem('month', month);
  };

  const xeroLogin = () => {
    dispatch(getxero()).then((response) => {
      localStorage.setItem(
        'data',
        JSON.stringify({
          jobId: jobId,
          member: memberId,
          name: displayNameFromType(member)
        })
      );
      openInNewTab(response?.data?.data);
    });
  };
  const openInNewTab = (url) => {
    dispatch(refreshXero()).then(() => {
      window.open(url, '_self');
    });
  };

  const handleManualFinancialAnalysis = () => {
    dispatch(getTaxRateByMember(jobId, memberId));
    dispatch(getFinancialData(jobId, memberId, member?.type));
    onHide();
  };

  const handleonClick = () => {
    navigate('/job/' + jobId + '/group-setup/settings');
  };

  useEffect(() => {
    onChangehandle(monthNames[currentMonth].value);
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="create-job">
        <h3>Create Financial Analysis</h3>
        <p>Member Name: {displayNameFromType(member)}</p>
        <div className="create-btn">
          <div>
            <div className="txt">Select the end of the financial year:</div>
            <div className="d-flex">
              <select
                onChange={(e) => {
                  localStorage.setItem('month', e.target.value);
                }}
                className="form-select"
                // onSelect={}
              >
                {monthNames.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={item.value}
                      // selected={key === selectedMonth}
                    >
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={xeroLogin}
            disabled={!jobSettingsDataResponse?.anticipated_settlement_date ? true : false}
          >
            Import from Xero
          </Button>
          or
          <Button
            type="button"
            className="btn btn-secondary"
            id="manualjobbtn"
            onClick={() => {
              handleManualFinancialAnalysis(member);
            }}
            disabled={!jobSettingsDataResponse?.anticipated_settlement_date ? true : false}
          >
            Manual Input
          </Button>
        </div>
        {!jobSettingsDataResponse?.anticipated_settlement_date ? (
          <p
            style={{
              textAlign: 'center',
              margin: 0,
              color: 'red',
              fontSize: '16px',
              marginTop: '10px'
            }}
          >
            Please setup
            <a style={{ color: '#3278c2' }} onClick={handleonClick}>
              {' Financial Year Structure '}
            </a>
            first.
          </p>
        ) : (
          ''
        )}
      </Modal.Body>
    </Modal>
  );
}
