import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';
import { setAddSecurityDataResponse } from './SecuritySlice';
import { financialKeyValuePair } from './FinancialsSlice';
import { calculateDebtServicingForTrust } from '../../utils/businessDataEntryCalculations';
import { roundOff } from '../../pages/common/Misc';

export let taxKeyValuePair = {
  'Franking Credits': 'franking-credits',
  'Deductible Amortisation': 'amortisation-added-back',
  'Amortisation Added Back': 'amortisation-added-back',
  'Deductible Amortisation on New Assets (Current & Projection years only)':
    'amortisation-on-new-assets',
  'Amortisation on New Assets (Current & Projection years only)': 'amortisation-on-new-assets',
  'Deductible Depreciation': 'deductible-depreciation',
  'Deductible Depreciation on New Assets (Current & Projection years only)':
    'deductible-depreciation-new-assets',
  'Deductible Depreciation on New Assets (Current &amp; Projection years only)':
    'deductible-depreciation-new-assets',
  'Tax Deductible Interest': 'tax-deductible-interest'
};

export let defaultValue = {
  'adjusted-tax-income': {
    key: 'adjusted-tax-income',
    title: '',
    is_expandable: true,
    items: [
      {
        title: 'Franking Credits',
        key: 'franking-credits',
        category: 'add',
        data: {},
        editable: true,
        deletable: false
      },
      {
        title: 'Amortisation Added Back',
        key: 'amortisation-added-back',
        category: 'less',
        data: {},
        editable: true,
        deletable: false
      },
      {
        title: 'Amortisation on New Assets (Current & Projection years only)',
        category: 'less',
        key: 'amortisation-on-new-assets',
        data: {},
        editable: false,
        deletable: false
      },
      {
        title: 'Deductible Depreciation',
        key: 'deductible-depreciation',
        category: 'less',
        data: {},
        editable: true,
        deletable: false
      },
      {
        title: 'Deductible Depreciation on New Assets (Current & Projection years only)',
        key: 'deductible-depreciation-new-assets',
        category: 'less',
        data: {},
        deletable: false
      },
      {
        title: 'Tax Deductible Interest',
        key: 'tax-deductible-interest',
        category: 'less',
        data: {},
        editable: true,
        deletable: false
      }
    ],
    totals: {
      key: 'total-adjusted-tax-income',
      title: 'Adjusted Taxable Income',
      data: {},
      deletable: false
    }
  },
  deductions: {
    key: 'deductions',
    title: '',
    is_expandable: true,
    items: [
      {
        title: 'Franking Credits',
        key: 'franking-credits',
        category: 'less',
        data: {},
        editable: true,
        deletable: false
      }
    ],
    totals: {
      key: 'deductions',
      title: 'Deductions',
      data: {},
      deletable: false
    }
  }
};

export const taxInitialState = {
  taxData: {},
  taxRateByMember: {},
  financials: {},
  taxDataResponse: [],
  years: [],
  taxDataTotal: []
};

export const TaxSlice = createSlice({
  name: 'tax',
  initialState: taxInitialState,
  reducers: {
    setTaxData: (state, action) => {
      let taxData = JSON.parse(JSON.stringify(action.payload));
      let adjustedTaxKeyList = ['add', 'less'];
      let adjustedTaxItems = [];
      let toIdSurplusData = taxData['adjusted-tax-income'].items.filter((item) => item.to_id);
      adjustedTaxKeyList.filter((el) => {
        adjustedTaxItems = adjustedTaxItems.concat(
          taxData['adjusted-tax-income'].items.filter(
            (item) => item.category === el && !Object.keys(item).includes('to_id')
          )
        );
      });
      if (toIdSurplusData?.length > 0) {
        adjustedTaxItems = adjustedTaxItems.concat(toIdSurplusData);
      }
      taxData['adjusted-tax-income'].items = adjustedTaxItems;
      let adjustedFrankingIndex = taxData['adjusted-tax-income']['items'].findIndex((el) =>
        ['franking-credits', 'franking-credits-received'].includes(el.key)
      );
      if (adjustedFrankingIndex > -1)
        taxData['adjusted-tax-income']['items'][adjustedFrankingIndex].title =
          'Tax Credits Received';
      if (taxData['deductions'] && action.payload.type !== 'trust') {
        let duductionFrankingIndex = taxData['deductions']['items'].findIndex((el) =>
          ['franking-credits', 'franking-credits-received'].includes(el.key)
        );
        taxData['deductions']['items'][duductionFrankingIndex].title = 'Tax Credits Received';
      }

      const years = JSON.parse(JSON.stringify(state.years));
      years.map((year) => {
        calculateRemainingAdjustedTaxableIncome(taxData, year, state);
        let sum = taxCalculation(action.payload, year, 'adjusted-tax-income');
        if (taxData['adjusted-tax-income'] && taxData['adjusted-tax-income'].totals)
          taxData['adjusted-tax-income'].totals.data[year] = sum;
        let duductionSum = taxCalculation(action.payload, year, 'deductions');
        taxData['deductions'].totals.data[year] = duductionSum;
      });

      let taxObj = {};
      for (let el of Object.keys(taxData)) {
        if (Object.keys(el).includes('items')) {
          let temp = [];
          let obj = {};
          const setArr = () => {
            for (let item of Object.keys(obj)) {
              temp.push({ [item]: obj[item] });
            }
          };
          for (let item of taxData[el].items) {
            if (!item?.category) {
              obj[item.category ? item.category : null] = [item];
              setArr();
              obj = {};
              // temp.push({ [item.category]: [item] });
            } else if (Object.keys(obj).includes(item.category)) {
              obj[item.category ? item.category : null].push(item);
            } else {
              obj[item.category ? item.category : null] = [item];
            }
          }
          setArr();
          taxObj[el] = temp;

          taxData[el].items = [];
          for (let item of Object.values(obj)) {
            taxData[el].items = taxData[el].items.concat(item);
          }
        }
      }
      state.taxData = taxData;
    },
    setTaxDataTotal: (state, action) => {
      state.taxDataTotal = action.payload;
    },
    setTaxFinancialsData: (state, action) => {
      state.financials = action.payload;
    },
    setTaxDataResponse: (state, action) => {
      state.taxDataResponse = JSON.parse(JSON.stringify(action.payload));
    },
    setTaxRateByMember: (state, action) => {
      state.taxRateByMember = action.payload;
    },
    setYearsData: (state, action) => {
      state.years = action.payload;
    },
    // initialTaxData: (state, action) => {
    //   state.taxData[action.payload.key].items[action.payload.index] = action.payload.data;
    // },
    updateTaxData: (state, action) => {
      // state.taxData[action.payload.key].items[action.payload.index] = action.payload.data;
      // if (action.payload.year) {
      //   // let year = action.payload.year;
      // }

      let data = JSON.parse(JSON.stringify(state.taxData));

      if (action.payload.year) {
        data[action.payload.key].items[action.payload.index].data = {
          ...data[action.payload.key].items[action.payload.index].data,
          [action.payload.year]: action.payload.data
            ? String(action.payload.data)
            : action.payload.data
        };

        calculateRemainingAdjustedTaxableIncome(data, action.payload.year, state);
        let sum = taxCalculation(
          JSON.parse(JSON.stringify(data)),
          action.payload.year,
          action.payload.key
        );
        data[action.payload.key].totals.data[action.payload.year] = sum;
      } else {
        data[action.payload.key].items[action.payload.index].title = action.payload.data;
      }
      state.taxData = data;
    },
    updateTaxDate: (state, action) => {
      let data = JSON.parse(JSON.stringify(state.taxData));
      data[action.payload.key].items.splice(action.payload.index, 0, action.payload.data);
      state.taxData = data;
    }
  }
});

export const deleteRecord = (data, years, financials) => async (dispatch) => {
  if (
    data.type == 'sole-trader' ||
    data.type == 'trust' ||
    data.type == 'partnership' ||
    data.type == 'other'
  ) {
    years.map((year) => {
      let totalAdjustedEbitda = calculateDebtServicingForTrust(year, data, financials);

      let addTotal = 0;
      let lessTotal = 0;
      let finalTotal = 0;
      Object.values(data['adjusted-tax-income'].items).forEach((item) => {
        if (item.category == 'add' && !Object.keys(item).includes('to_id')) {
          addTotal +=
            item.data[year] != undefined && !isNaN(item.data[year]) ? Number(item.data[year]) : 0;
        }
        if (item.category == 'less' && !Object.keys(item).includes('to_id')) {
          lessTotal +=
            item.data[year] != undefined && !isNaN(item.data[year]) ? Number(item.data[year]) : 0;
        }
      });

      finalTotal = totalAdjustedEbitda + addTotal + lessTotal;
      Object.values(data['adjusted-tax-income'].items).forEach((item) => {
        if (item.key == 'surplus_profit' && Object.keys(item).includes('to_id')) {
          item.data[year] = finalTotal * (item.percentage[year] / 100) * -1;
          if (!isNaN(item.data[year])) {
            if (item.data[year] < 0) {
              item.category = 'less';
            } else {
              item.category = 'add';
            }
          }
        }
      });
    });
  }
  dispatch(setTaxData(data));
};

export const taxCalculation = (taxData, year, key) => {
  // let taxData = JSON.parse(JSON.stringify(state.taxData));
  let sum = 0;
  Object.values(taxData[key].items).forEach((item) => {
    if (item.data[year]) {
      sum += Number(item.data[year]);
    }
  });
  return sum;
};

export const calculateRemainingAdjustedTaxableIncome = (taxData, year, state) => {
  if (
    taxData.type === 'sole-trader' ||
    taxData.type === 'trust' ||
    taxData.type === 'partnership' ||
    taxData.type === 'other'
  ) {
    let totalAdjustedEbitda = calculateDebtServicingForTrust(year, taxData, state.financials);

    let addTotal = 0;
    let lessTotal = 0;
    let finalTotal = 0;

    Object.values(taxData['adjusted-tax-income'].items).forEach((item) => {
      if (item.category == 'add' && !Object.keys(item).includes('to_id')) {
        addTotal +=
          item.data[year] != undefined && !isNaN(item.data[year]) ? Number(item.data[year]) : 0;
      }
      if (item.category == 'less' && !Object.keys(item).includes('to_id')) {
        lessTotal +=
          item.data[year] != undefined && !isNaN(item.data[year]) ? Number(item.data[year]) : 0;
      }
    });
    //TODO: Franking credit entry considered here.
    finalTotal = totalAdjustedEbitda + addTotal + lessTotal;
    Object.values(taxData['adjusted-tax-income'].items).forEach((item) => {
      if (item.key == 'surplus_profit' && Object.keys(item).includes('to_id')) {
        item.data[year] = finalTotal * (item.percentage[year] / 100) * -1;
        if (!isNaN(item.data[year])) {
          if (item.data[year] < 0) {
            item.category = 'less';
          } else {
            item.category = 'add';
          }
        }
      }
    });
  }
};

export const getTaxData = (id, memberId, type, setErrors) => async (dispatch) => {
  const keyList = [
    'adjusted-ebitda',
    'cost-of-sales',
    'dividend',
    'ebit',
    'ebitda',
    'operating-expenses',
    'other-income',
    'trading-income',
    'related-party-income'
  ];
  let response = await clientService.get(
    'jobs/' + id + '/members/' + memberId + '/tax',
    setErrors,
    dispatch,
    false
  );

  if (response) {
    dispatch(setTaxDataResponse(response));
  }
  if (response.data.data.years) {
    dispatch(setYearsData(response.data.data.years));
    dispatch(setTaxDataTotal(response.data.data.tax_data));
    let rentalIndex =
      response?.data?.data['financial_data']['adjusted-ebitda'] &&
      response?.data?.data['financial_data']['adjusted-ebitda'].items.findIndex(
        (item) => item.key === 'add-sensitised-current-rental'
      );
    if (rentalIndex > -1) {
      if (
        response.data.data['financial_data']['adjusted-ebitda'].items[rentalIndex].data[
          response.data.data.years[0]
        ] !== response.data.data.add_sensitised_current_rental
      ) {
        response.data.data.years.map((el) => {
          response.data.data['financial_data']['adjusted-ebitda'].items[rentalIndex].data[el] =
            response.data.data.add_sensitised_current_rental;
        });
      }
    }

    keyList.map((item) => {
      if (Object.keys(response.data.data['financial_data']).includes(item)) {
        response.data.data['financial_data'][item].totals.data = {};
        response.data.data.years.map((el) => {
          let total = 0;
          if (response.data.data['financial_data'][item]?.items?.length > 0) {
            response.data.data['financial_data'][item].items.map((elem) => {
              if (elem?.data && Object.keys(elem?.data).includes(String(el))) {
                total +=
                  // Math.round(Number(elem.data[el]));
                  roundOff(Number(elem.data[el]));
              } else {
                elem.data[el] = null;
              }
            });
          }
          response.data.data['financial_data'][item].totals.data[el] = total;
        });
      }
    });

    response.data.data.years.map((el) => {
      let total = 0;
      if (response.data.data['financial_data']['adjusted-ebitda']?.items?.length > 0) {
        response.data.data['financial_data']['adjusted-ebitda'].items.map((elem) => {
          if (elem?.data && Object.keys(elem?.data).includes(String(el))) {
            total +=
              // Math.round(Number(elem.data[el]));
              roundOff(Number(elem.data[el]));
          } else {
            elem.data[el] = null;
          }
        });
      }
      if (response.data.data['financial_data']['adjusted-ebitda'])
        response.data.data['financial_data']['adjusted-ebitda'].totals.data[el] = total;
    });

    if (response.data.data.data) {
      let items;
      if (Object.entries(response.data.data.data).length === 0) {
        items = JSON.parse(JSON.stringify(defaultValue));
      } else {
        items = JSON.parse(JSON.stringify(response.data.data.data));
      }

      items = generateTaxPayload(items, response);

      dispatch(setTaxData({ ...items, type }));

      // dispatch(initialTaxData(items));
      const financial_data = response.data.data.financial_data;
      for (let item of Object.values(financial_data)) {
        if (item?.items?.length > 0) {
          for (let el of item.items) {
            if (!Object.keys(el).includes('key')) {
              el.key = financialKeyValuePair[el.title];
            }
          }
        }
      }

      dispatch(setTaxFinancialsData(financial_data));
    }
  }
};

const generateTaxPayload = (items, response) => {
  let itemData = JSON.parse(JSON.stringify(items));

  let obj = JSON.parse(JSON.stringify(response.data.data));

  obj['tax_data']['ebitda'].items.data = Object.fromEntries(
    Object.entries(response.data.data['tax_data']['ebitda'].items.data).map((item) => {
      if (item[1] > 0) {
        item[1] = item[1] * -1;
      }
      return item;
    })
  );

  itemData['interest_pa'] = response.data.data.interest_pa ? response.data.data.interest_pa : 0;
  if (itemData['adjusted-tax-income'].items.length > 0) {
    for (let el of itemData['adjusted-tax-income'].items) {
      if (!Object.keys(el).includes('key')) {
        let index = itemData['adjusted-tax-income'].items.findIndex(
          (elem) => elem.title === el.title
        );
        if (index > -1) {
          itemData['adjusted-tax-income']['items'][index]['key'] = taxKeyValuePair[el.title];
        }
      }
    }
  }

  if (itemData['deductions'] && itemData['deductions'].items.length > 0) {
    for (let el of itemData['deductions'].items) {
      if (!Object.keys(el).includes('key')) {
        let index = itemData['deductions'].items.findIndex((elem) => elem.title === el.title);
        if (index > -1) {
          itemData['deductions']['items'][index]['key'] = taxKeyValuePair[el.title];
        }
      }
    }
  }
  for (let item of Object.values(obj.financial_data)) {
    if (item?.items?.length > 0) {
      for (let el of item.items) {
        if (!Object.keys(el).includes('key')) {
          el.key = financialKeyValuePair[el.title];
        }
      }
    }
  }

  // return;
  let amortationIndex = itemData['adjusted-tax-income']['items'].findIndex(
    (el) => el.key == 'amortisation-added-back'
  );
  let deductibleIndex = itemData['adjusted-tax-income']['items'].findIndex(
    (el) => el.key == 'deductible-depreciation'
  );
  let taxIndex = itemData['adjusted-tax-income']['items'].findIndex(
    (el) => el.key == 'tax-deductible-interest'
  );
  let adjustedFrankingIndex = itemData['adjusted-tax-income']['items'].findIndex((el) =>
    ['franking-credits', 'franking-credits-received'].includes(el.key)
  );
  let deductionFrankingIndex =
    itemData['deductions'] &&
    itemData['deductions']['items'].findIndex((el) =>
      ['franking-credits', 'franking-credits-received'].includes(el.key)
    );
  let financialAmortisationIndex = obj?.financial_data?.ebitda?.items.findIndex(
    (el) => el.key == 'amortisation'
  );

  if (deductibleIndex > -1) {
    itemData['adjusted-tax-income']['items'][deductibleIndex]['data'] =
      obj['tax_data']['ebitda']?.items?.data;
  }
  obj?.years.map((val) => {
    if (taxIndex > -1) {
      itemData['adjusted-tax-income']['items'][taxIndex]['data'][val] = obj?.interest_pa
        ? Math.ceil(obj?.interest_pa * -1)
        : 0;
    }
    // Deductible Amortisation added from here in Tax array.

    if (adjustedFrankingIndex > -1) {
      itemData['adjusted-tax-income']['items'][adjustedFrankingIndex]['data'][val] = obj
        ?.franking_credits_data[val]
        ? obj?.franking_credits_data[val] > 0
          ? obj?.franking_credits_data[val]
          : obj?.franking_credits_data[val] * -1
        : null;
    }

    if (amortationIndex > -1) {
      itemData['adjusted-tax-income']['items'][amortationIndex]['data'][val] = obj?.financial_data
        ?.ebitda?.items?.[financialAmortisationIndex]?.data[val]
        ? obj?.financial_data?.ebitda?.items[financialAmortisationIndex].data[val] * -1
        : 0;
    }

    if (deductionFrankingIndex > -1) {
      itemData['deductions']['items'][deductionFrankingIndex]['data'][val] = obj
        ?.franking_credits_data[val]
        ? obj?.franking_credits_data[val] > 0
          ? obj?.franking_credits_data[val] * -1
          : obj?.franking_credits_data[val]
        : null;
    }
  });

  return itemData;
};

export const getTaxRateByMember = (jobId, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `tax-rate/${jobId}/${memberId}`,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setTaxRateByMember(response.data));
  }
};

export const updateTax = (jobId, memberId, tax, data, setErrors) => async (dispatch) => {
  if (Object.keys(tax).includes('interest_pa')) {
    delete tax.interest_pa;
  }
  data.tax = tax;

  const response = await clientService.put(
    'jobs/' + jobId + '/members/' + memberId + '/tax',
    data,
    setErrors,
    dispatch
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAddSecurityDataResponse(response.data));
  }
};

export const {
  setTaxFinancialsData,
  setTaxDataResponse,
  setTaxData,
  setTaxDataTotal,
  updateTaxData,
  updateTaxDate,
  setYearsData,
  setTaxRateByMember
} = TaxSlice.actions;

export const taxState = (state) => {
  return state.tax;
};

export default TaxSlice.reducer;
