import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAverageLiving,
  getHouseHold,
  livingExpenseState,
  setUpdatedAverageLivingResponse,
  setUpdatedHouseHoldResponse,
  updateAverageLiving,
  updateHouseHold
} from '../../../slices/slices/LivingExpenseSlice';
import { useAlert } from 'react-alert';
import { averageLivingSchema } from '../../common/Validation';
import Button from '../../../components/Button';
//import Input from '../../../components/Input';
import Label from '../../../components/Label';
import Header from '../Header';
import { Link, useParams } from 'react-router-dom';
import { houseHoldSchema } from '../../common/Validation';
import FormActions from '../../../components/FormActions';
import NumberFormat from 'react-number-format';
import DialogModal from '../../../components/modal/DialogModal';
import constants from '../../../constant';
import LabelTooltip from '../../../components/tooltip/LabelTooltip';
import label from '../../../LabelConstant';
import { formatNumber, unformatNumber } from '../../common/Misc';
import ErrorFocus from '../../common/ErrorFocus';
import { useNavigatingAway } from '../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../common/components/DialogLeavingPage';
import ContentEditable from 'react-contenteditable';
import { Modal } from 'react-bootstrap';

const LivingExpense = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { jobId } = useParams();
  // const RegExp = /([.]\d)/;
  // const [symbolsArr] = useState(['e', 'E', '+', '.']);

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const {
    houseHoldResponse,
    updatedHouseHoldResponse,
    averageLivingResponse,
    updateAverageLivingResponse
  } = useSelector(livingExpenseState);

  const calculate = (values, index) => {
    let estimated_average_living_expenses =
      values.household[index].number_of_adult <= '0' &&
      values.household[index].number_of_dependant > '0'
        ? averageLivingResponse.first_dependant +
          (values.household[index].number_of_dependant - 1) *
            averageLivingResponse.subsequent_dependant
        : values.household[index].number_of_dependant <= '0' &&
          values.household[index].number_of_adult > '0'
        ? averageLivingResponse.first_adult +
          (values.household[index].number_of_adult - 1) * averageLivingResponse.subsequent_adult
        : values.household[index].number_of_adult <= '0' &&
          values.household[index].number_of_dependant <= '0'
        ? '0'
        : averageLivingResponse.first_adult +
          (values.household[index].number_of_adult - 1) * averageLivingResponse.subsequent_adult +
          averageLivingResponse.first_dependant +
          (values.household[index].number_of_dependant - 1) *
            averageLivingResponse.subsequent_dependant;

    return `${values.household[index].adjustments_based_on_lifestyle}`
      ? estimated_average_living_expenses +
          unformatNumber(`${values.household[index].adjustments_based_on_lifestyle}`)
      : estimated_average_living_expenses;
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    const updatedData = values.household.map((x, i) =>
      x
        ? {
            ...x,
            adjustments_based_on_lifestyle: `${values.household[i].adjustments_based_on_lifestyle}`
              ? unformatNumber(`${values.household[i].adjustments_based_on_lifestyle}`)
              : 0,
            number_of_dependant: `${values.household[i].number_of_dependant}`
              ? `${values.household[i].number_of_dependant}`
              : 0,
            total_estimated_living_expenses: calculate(values, i)
          }
        : x
    );
    values.household = updatedData;
    dispatch(updateHouseHold(jobId, values, setErrors));
  };
  const averageLiving = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    const data = {
      ...values,
      first_adult: parseInt(`${values?.first_adult}`.replace(/,/g, '')),
      subsequent_adult: parseInt(`${values?.subsequent_adult}`.replace(/,/g, '')),
      first_dependant: parseInt(`${values?.first_dependant}`.replace(/,/g, '')),
      subsequent_dependant: parseInt(`${values?.subsequent_dependant}`.replace(/,/g, ''))
    };
    dispatch(updateAverageLiving(jobId, data, setErrors));
  };

  useEffect(() => {
    dispatch(getHouseHold(jobId));
    dispatch(getAverageLiving(jobId));
  }, [jobId]);

  useEffect(() => {
    // dispatch(getAverageLiving(jobId));
    if (updatedHouseHoldResponse?.message) {
      dispatch(getHouseHold(jobId));
      setCanShowDialogLeavingPage(false);
      dispatch(setUpdatedHouseHoldResponse({}));
    }
  }, [updatedHouseHoldResponse?.message]);

  useEffect(() => {
    if (updateAverageLivingResponse?.message) {
      dispatch(getHouseHold(jobId));
      dispatch(getAverageLiving(jobId));
      setCanShowDialogLeavingPage(false);
      dispatch(setUpdatedAverageLivingResponse({}));
    }
  }, [updateAverageLivingResponse?.message]);

  const handleTotalChange = (index, e, setFieldValue, total_estimated_living_expenses) => {
    setFieldValue(`household.${index}.adjustments_based_on_lifestyle`, e.target.innerHTML);
    setFieldValue(
      `household.${index}.total_estimated_living_expenses`,
      Math.abs(total_estimated_living_expenses)
    );
  };

  const handleFocusEvent = (event, index, setFieldValue) => {
    const value = unformatNumber(event.target.innerHTML);
    setFieldValue(`household.${index}.adjustments_based_on_lifestyle`, value);
  };

  const handleDelete = (index, e) => {
    setShowDelete(!showDelete);
    handleInputChange(e.target.value);
    setDeleteIndex(index);
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/group-setup/members'}>Group Setup</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>Living Expenses Calculator</strong>
              </li>
            </ul>
          </div>
          <div className="breadcrumb-right d-flex">
            <DialogModal title="Living Expense" p1={constants.livingEx1} p2={constants.livingEx2} />
          </div>
        </div>
        <div className="pagetitle proversion pg-0">
          <div className="title_pro d-flex justify-content-between align-items-center">
            <h1>Living Expenses Calculator</h1>
          </div>
        </div>
        <section className="settingsgroup">
          <div className="addmember">
            <div className="row expenses">
              <div className="col-md-4">
                <div className="average">
                  <Formik
                    initialValues={{
                      first_adult:
                        averageLivingResponse?.first_adult !== null &&
                        averageLivingResponse?.first_adult !== ''
                          ? averageLivingResponse?.first_adult
                          : '',
                      subsequent_adult:
                        averageLivingResponse?.subsequent_adult !== null &&
                        averageLivingResponse?.subsequent_adult !== ''
                          ? averageLivingResponse?.subsequent_adult
                          : '',
                      first_dependant:
                        averageLivingResponse?.first_dependant !== null &&
                        averageLivingResponse?.first_dependant !== ''
                          ? averageLivingResponse?.first_dependant
                          : '',
                      subsequent_dependant:
                        averageLivingResponse?.subsequent_dependant !== null &&
                        averageLivingResponse?.subsequent_dependant !== ''
                          ? averageLivingResponse?.subsequent_dependant
                          : '',
                      total_estimated_living_expenses:
                        averageLivingResponse?.total_estimated_living_expenses !== null &&
                        averageLivingResponse?.total_estimated_living_expenses !== ''
                          ? averageLivingResponse?.total_estimated_living_expenses
                          : ''
                    }}
                    enableReinitialize
                    validationSchema={averageLivingSchema}
                    onSubmit={averageLiving}
                  >
                    {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                      <>
                        {' '}
                        <DialogLeavingPage
                          showDialog={showDialogLeavingPage}
                          confirmNavigation={confirmNavigation}
                          cancelNavigation={cancelNavigation}
                        />
                        <form onSubmit={handleSubmit} noValidate>
                          <table className="table">
                            <thead>
                              <tr>
                                <th colSpan="2">Average Living Expense Rates $ p.a.</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>First Adult</td>
                                <td>
                                  <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false}
                                    name="first_adult"
                                    value={values?.first_adult}
                                    className="form-control"
                                    thousandSeparator={true}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleInputChange(e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    required
                                  />
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.first_adult}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>Subsequent Adult</td>
                                <td>
                                  <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false}
                                    name="subsequent_adult"
                                    value={values.subsequent_adult}
                                    className="form-control"
                                    thousandSeparator={true}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleInputChange(e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.subsequent_adult}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>First Dependant</td>
                                <td>
                                  <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false}
                                    name="first_dependant"
                                    value={values.first_dependant}
                                    className="form-control"
                                    thousandSeparator={true}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleInputChange(e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors?.first_dependant}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>Subsequent Dependant</td>
                                <td>
                                  <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false}
                                    name="subsequent_dependant"
                                    value={values.subsequent_dependant}
                                    className="form-control"
                                    thousandSeparator={true}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleInputChange(e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.subsequent_dependant}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2">
                                  <Button className="btn btn-success">Save</Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <ErrorFocus />
                        </form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="col-md-8 expenses_clac">
                <Formik
                  initialValues={{
                    household: houseHoldResponse?.data
                      ? houseHoldResponse.data.household
                      : [
                          {
                            number_of_adult: '',
                            number_of_dependant: '',
                            average_living_exp: '',
                            adjustments_based_on_lifestyle: '',
                            is_completed: 0,
                            total_estimated_living_expenses: 0
                          }
                        ]
                  }}
                  enableReinitialize
                  validationSchema={houseHoldSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({ values, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <FieldArray
                        name="household"
                        render={(arrayHelpers) => (
                          <div>
                            {values.household && values.household.length > 0 ? (
                              values.household.map((item, index) => {
                                let estimated_average_living_expenses = [],
                                  total_estimated_living_expenses = [];

                                estimated_average_living_expenses =
                                  values.household[index].number_of_adult <= '0' &&
                                  values.household[index].number_of_dependant > '0'
                                    ? averageLivingResponse.first_dependant +
                                      (values.household[index].number_of_dependant - 1) *
                                        averageLivingResponse.subsequent_dependant
                                    : values.household[index].number_of_dependant <= '0' &&
                                      values.household[index].number_of_adult > '0'
                                    ? averageLivingResponse.first_adult +
                                      (values.household[index].number_of_adult - 1) *
                                        averageLivingResponse.subsequent_adult
                                    : values.household[index].number_of_adult <= '0' &&
                                      values.household[index].number_of_dependant <= '0'
                                    ? '0'
                                    : averageLivingResponse.first_adult +
                                      (values.household[index].number_of_adult - 1) *
                                        averageLivingResponse.subsequent_adult +
                                      averageLivingResponse.first_dependant +
                                      (values.household[index].number_of_dependant - 1) *
                                        averageLivingResponse.subsequent_dependant;

                                total_estimated_living_expenses =
                                  `${values.household[index].adjustments_based_on_lifestyle}`
                                    ? estimated_average_living_expenses +
                                      unformatNumber(
                                        `${values.household[index].adjustments_based_on_lifestyle}`
                                      )
                                    : estimated_average_living_expenses;

                                return (
                                  <>
                                    <h2>Household {index + 1}</h2>
                                    <div className="group_areaof row">
                                      <div key={index} className="row">
                                        <div className="col-11"></div>
                                        <div className="col-1">
                                          <h2>
                                            <a className="delete">
                                              <i
                                                className="icon-delete"
                                                aria-hidden="true"
                                                onClick={(e) => {
                                                  handleDelete(index, e);
                                                }}
                                              ></i>
                                            </a>
                                          </h2>
                                        </div>

                                        <div className="col-4">
                                          <div className="form-group">
                                            <Label>Number of Adults</Label>
                                            <NumberFormat
                                              decimalScale={0}
                                              allowNegative={false}
                                              name={`household.${index}.number_of_adult`}
                                              value={values?.household[index]?.number_of_adult}
                                              className="form-control"
                                              // thousandSeparator={true}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(e.target.value);
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            <span
                                              className="error"
                                              style={{ color: 'red', fontSize: '20px' }}
                                            >
                                              <ErrorMessage
                                                name={`household.${index}.number_of_adult`}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-8 dependents">
                                          <div className="form-group">
                                            <Label>Number of Dependents</Label>
                                            <NumberFormat
                                              decimalScale={0}
                                              allowNegative={false}
                                              name={`household.${index}.number_of_dependant`}
                                              value={values?.household[index]?.number_of_dependant}
                                              className="form-control"
                                              // thousandSeparator={true}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(e.target.value);
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            <span
                                              className="error"
                                              style={{ color: 'red', fontSize: '20px' }}
                                            >
                                              <ErrorMessage
                                                name={`household.${index}.number_of_dependant`}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-4 living">
                                          <div className="form-group">
                                            <Label>Estimated Average Living Expenses $ p.a.</Label>
                                            <Field
                                              value={formatNumber(
                                                estimated_average_living_expenses
                                              )}
                                              className="form-control"
                                              disabled
                                            />
                                          </div>
                                        </div>
                                        <div className="col-4 living">
                                          <div className="form-group">
                                            {/*<Label>Lifestyle Adjustment +/- $ p.a.</Label>*/}
                                            <LabelTooltip
                                              labelId={label.livingExpenses.lifeAdjTip.key}
                                              label={label.livingExpenses.lifeAdjTip.label}
                                              hover_text={
                                                label.livingExpenses.lifeAdjTip.hover_text
                                              }
                                            />
                                            <ContentEditable
                                              html={formatNumber(
                                                values?.household[index]
                                                  ?.adjustments_based_on_lifestyle
                                              )}
                                              data-column="item"
                                              // data-row={year}
                                              className="form-control"
                                              // onKeyPress={(e) => validateNumber(e)}
                                              onBlur={(e) => {
                                                handleTotalChange(
                                                  index,
                                                  e,
                                                  setFieldValue,
                                                  total_estimated_living_expenses
                                                );
                                                handleInputChange(total_estimated_living_expenses);
                                              }}
                                              onFocus={(event) =>
                                                handleFocusEvent(event, index, setFieldValue)
                                              }
                                            />
                                            {/* <Field
                                              name={`household.${index}.adjustments_based_on_lifestyle`}
                                              value={formatNumber(
                                                `${values?.household[index]?.adjustments_based_on_lifestyle}`
                                              )}
                                              className="form-control"
                                              onChange={(e) => {
                                                handleChange;
                                                handleTotalChange(
                                                  index,
                                                  e,
                                                  setFieldValue,
                                                  total_estimated_living_expenses
                                                );
                                                handleInputChange(total_estimated_living_expenses);
                                              }}
                                              onBlur={handleBlur}
                                              required
                                            /> */}
                                          </div>
                                        </div>
                                        <div className="col-4 living">
                                          <div className="form-group">
                                            <Label>Total Estimated Living Expenses $ p.a.</Label>
                                            <Field
                                              // name="total"
                                              name={`household.${index}.total_estimated_living_expenses`}
                                              // value={Math.abs(total_estimated_living_expenses)}
                                              // value={`${values?.household[index]?.total_estimated_living_expenses}`}
                                              value={formatNumber(
                                                `${values.household[index].adjustments_based_on_lifestyle}`
                                                  ? estimated_average_living_expenses +
                                                      unformatNumber(
                                                        `${values.household[index].adjustments_based_on_lifestyle}`
                                                      )
                                                  : estimated_average_living_expenses
                                              )}
                                              // prefix={
                                              //   total_estimated_living_expenses < 0 ? '(' : null
                                              // }
                                              // suffix={
                                              //   total_estimated_living_expenses < 0 ? ')' : null
                                              // }
                                              className="form-control"
                                              // thousandSeparator={true}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <div className="blank blank-household">
                                  <p>
                                    You have no Household. Add new Household from click the button
                                    below
                                  </p>
                                  <Button
                                    type="button"
                                    className="btn btn-secondary btn-sm mr-1 mr-sm-2 mb-1 mb-md-0"
                                    onClick={() => {
                                      if (!averageLivingResponse) {
                                        alert.error(
                                          'please add detail of Average Living Expense Rates'
                                        );
                                      } else {
                                        arrayHelpers.push({
                                          number_of_adult: '',
                                          number_of_dependant: '',
                                          average_living_exp: '',
                                          adjustments_based_on_lifestyle: '',
                                          total_estimated_living_expenses: 0
                                        });
                                      }
                                    }}
                                  >
                                    <i className="icon-plus"></i>Household
                                  </Button>
                                </div>
                              </>
                            )}
                            <div className="addmemberarea add-household">
                              <Button
                                type="button"
                                className="btn btn-secondary btn-sm mr-1 mr-sm-2 mb-1 mb-md-0"
                                onClick={() => {
                                  arrayHelpers.push({
                                    number_of_adult: '',
                                    number_of_dependant: '',
                                    average_living_exp: '',
                                    adjustments_based_on_lifestyle: '',
                                    total_estimated_living_expenses: 0
                                  });
                                }}
                              >
                                <i className="icon-plus"></i>Household
                              </Button>
                            </div>
                            <Modal
                              show={showDelete}
                              onHide={handleDelete}
                              dialogClassName="modal-50w"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <Modal.Header>
                                <h2>Delete Household?</h2>
                              </Modal.Header>
                              <Modal.Body>
                                <p>Are you sure you want to delete this household permanently?</p>
                              </Modal.Body>
                              <div className="modal-action modal-footer">
                                <Button className="btn btn-secondary" onClick={handleDelete}>
                                  Cancel
                                </Button>
                                <Button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setShowDelete(!showDelete);
                                    arrayHelpers.remove(deleteIndex);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </Modal>
                          </div>
                        )}
                      />
                      <FormActions cancel={'/job/' + jobId + '/dashboard'} values={values} />
                      <ErrorFocus />
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default LivingExpense;
