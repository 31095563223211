import React from 'react';

export default function Item(props) {
  const { itemData } = props;
  let value = JSON.parse(itemData?.value);

  let itemList = value.map((Title, index) => {
    return (
      <li key={index}>
        <p>{Title.value}</p>
      </li>
    );
  });
  itemList.push(
    <li>
      <label>Comment</label>
      <p>{itemData.comment}</p>
    </li>
  );

  return itemList;
}
