import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import DataEntryHeader from '../../../../../components/DataEntryHeader';
import { addCommercialRentalSchema } from '../../../../common/Validation';
import { getMemberData, facilityState } from '../../../../../slices/slices/FacilitySlice';
import {
  rentalState,
  getRentalData,
  editRental,
  setEditRentalResponse
} from '../../../../../slices/slices/RentalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from './component/Form';
import moment from 'moment';
import { displayNameFromType } from '../../../../common/Misc';
import { getMemberList, jobSetupState } from '../../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../../common/components/DialogLeavingPage';

const EditCommercialRental = () => {
  const dispatch = useDispatch();
  const { jobId, memberId, rentalId } = useParams();
  const memberDataResponse = useSelector(facilityState);
  const { memberListResponse } = useSelector(jobSetupState);
  const editRentalResponse = useSelector(rentalState);
  const rentalDataResponse = useSelector(rentalState);
  let existingValues = [];
  let overwrittenFields = [];
  const formValues = useRef();

  const navigate = useNavigate();
  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const getOwners = () => {
    let temp;
    if (
      rentalDataResponse?.rentalDataResponse?.data?.length > 0 &&
      Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]?.rental_owners?.length > 0
    ) {
      temp = Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]?.rental_owners?.map(
        (share) => ({
          member_id: newColumnsForAll?.find((item) => item?.value === share?.pivot?.member_id),
          holding: share?.pivot?.holding
        })
      );
    } else {
      temp = [
        {
          member_id: '',
          holding: 0
        }
      ];
    }
    return temp;
  };

  const handleFormSubmit = (values, { setErrors }) => {
    let members = [];
    for (let item of JSON.parse(JSON.stringify(values?.owners))) {
      members.push({
        holding: item?.holding,
        member_id: item?.member_id?.value
      });
    }

    setCanShowDialogLeavingPage(false);
    {
      if (values?.is_completed === 0) {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += parseInt(values.owners[index].holding);
        });
        total <= 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      } else {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += parseInt(values.owners[index].holding);
        });
        total === 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      }
    }

    const updatedData = values.tenant.map((x, i) =>
      x
        ? {
            ...x,
            lease_start:
              values.tenant[i].lease_start_date === null ||
              values.tenant[i].lease_start_date === '' ||
              values.tenant[i].lease_start === 'Invalid date'
                ? ''
                : moment(values.tenant[i].lease_start_date, 'yyyy-MM-DD').format('DD-MM-YYYY'),
            lease_end: values.tenant[i].lease_end_date
              ? moment(values.tenant[i].lease_end_date, 'yyyy-MM-DD').format('DD-MM-YYYY')
              : null,
            current_rental_income_pa: values.tenant[i].current_rental_income_pa
              ? parseInt(`${values.tenant[i].current_rental_income_pa}`.replace(/,/g, ''))
              : null,
            rental_bond:
              values.tenant[i].rental_bond === undefined ||
              values.tenant[i].rental_bond === '' ||
              values.tenant[i].rental_bond === null
                ? ''
                : parseInt(`${values.tenant[i].rental_bond}`.replace(/,/g, '')),
            annual_rent_review:
              values.tenant[i].annual_rent_review === '' ||
              values.tenant[i].annual_rent_review === null ||
              values.tenant[i].annual_rent_review === 'Select'
                ? ''
                : values.tenant[i].annual_rent_review,

            option:
              (values.tenant[i].option1 === ('' || null || undefined)
                ? ''
                : values.tenant[i].option1) +
              'X' +
              (values.tenant[i].option2 === ('' || null || undefined)
                ? ''
                : values.tenant[i].option2)
          }
        : x
    );
    values.tenant = updatedData;
    values.owners = members;
    dispatch(editRental(jobId, memberId, rentalId, values, setErrors));
  };

  const getTenantData = (rentalData) => {
    if (Object.assign({}, rentalData?.rentalDataResponse?.data)[0]?.additional_info) {
      let rentalArray = JSON.parse(
        JSON.stringify(Object.assign({}, rentalData?.rentalDataResponse?.data)[0]?.additional_info)
      );
      return rentalArray?.tenant && rentalArray?.tenant.length > 0
        ? rentalArray?.tenant?.map((item) => {
            item.lease_start_date = item?.lease_start;
            item.lease_end_date = item?.lease_end;
            item.option1 = item?.option?.split('X')[0];
            item.option2 = item?.option?.split('X')[1];
            return item;
          })
        : [];
    } else {
      return [
        {
          tenant_name: '',
          tenant_activity: '',
          lease_start_date: '',
          lease_end_date: null,
          option: '',
          rental_bond: '',
          sensitised_rental_pa: '50',
          current_rental_income_pa: '',
          shading: '',
          annual_rent_review: ''
        }
      ];
    }
  };

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getMemberList(jobId));
    dispatch(getRentalData(jobId, memberId, rentalId));
    if (editRentalResponse?.editRentalResponse?.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setEditRentalResponse({}));
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/rentals');
    }
  }, [editRentalResponse?.editRentalResponse?.message]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/rentals'}>Rental</Link>
              </li>
            </ul>
            <h1>Rentals - {displayNameFromType(memberDataResponse?.memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <NavDropdown
              title=""
              id="nav-dropdown"
              style={{ marginLeft: 10 + 'px' }}
              className="d-flex align-items-center icon-info"
            >
              <NavDropdown.Item className="dropdown-item information">
                <h4>
                  Information about
                  <br />
                  relative screen
                </h4>
                <a href="/facilities" className="close">
                  <i className="icon-close"></i>
                </a>
                <p>
                  Lorem ipsum dolor sit amet, consecte tur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                  fugiat nulla pariatur.
                </p>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <Formik
          initialValues={{
            type: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]?.type,
            owners: getOwners(),

            address: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]?.address,
            internal_notes: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]
              ?.additional_info?.internal_notes,
            report_comments: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]
              ?.additional_info?.report_comments,
            is_completed: '0',
            shading: rentalDataResponse?.rentalDataResponse?.data?.[0]?.additional_info?.tenant?.[0]
              ?.shading
              ? rentalDataResponse?.rentalDataResponse?.data?.[0]?.additional_info?.tenant?.[0]
                  ?.shading
              : '',
            tenant: getTenantData(rentalDataResponse)
          }}
          enableReinitialize
          validationSchema={addCommercialRentalSchema}
          onSubmit={handleFormSubmit}
          innerRef={formValues}
        >
          {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <>
                <DialogLeavingPage
                  showDialog={showDialogLeavingPage}
                  confirmNavigation={confirmNavigation}
                  cancelNavigation={cancelNavigation}
                />
                <Form
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  handleInputChange={handleInputChange}
                  memberListResponse={memberListResponse}
                  memberDataResponse={memberDataResponse}
                  memberId={memberId}
                  jobId={jobId}
                  formValues={formValues}
                  overwrittenFields={overwrittenFields}
                  existingValues={existingValues}
                  setFieldValue={setFieldValue}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EditCommercialRental;
