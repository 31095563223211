import React, { useEffect } from 'react';
import AuthFooter from './Auth-Footer';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendEmailOTP,
  verifyEmailOTP,
  authState,
  setSendEmailResponse,
  setVerifyEmailResponse
} from '../../slices/slices/AuthSlice';

import { useNavigate } from 'react-router';
import { AuthenticationEmailSchema } from '../common/Validation';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Label from '../../components/Label';

const AuthenticationEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emailVerifyResponse, emailOTPResponse } = useSelector(authState);
  useEffect(() => {
    dispatch(sendEmailOTP());
  }, []);

  useEffect(() => {
    if (emailOTPResponse.message) {
      dispatch(setSendEmailResponse({}));
    }
  }, [emailOTPResponse.message]);

  const handleFormSubmit = (values, { setErrors }) => {
    dispatch(verifyEmailOTP(values, setErrors));
  };

  const login = () => {
    // localStorage.clear();
    localStorage.setItem('token', '');
    navigate('/login');
  };

  useEffect(() => {
    if (emailVerifyResponse.message) {
      if (emailVerifyResponse) {
        navigate('/dashboard');
        dispatch(setVerifyEmailResponse({}));
      }
    }
  }, [emailVerifyResponse]);

  return (
    <div className="influence-account-page">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="" className="logo d-flex align-items-center w-auto">
                    <img src="images/logo.png" alt="" />
                  </a>
                </div>
                <div className="card mb-3 loginarea au_thenticator">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        token: ''
                      }}
                      validationSchema={AuthenticationEmailSchema}
                      onSubmit={handleFormSubmit}
                    >
                      {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="row needs-validation" noValidate>
                          <div className="col-12 d-flex flex-row justify-content-center textintro btmgap digital">
                            <img
                              className="authenticator"
                              src="images/email-icon.svg"
                              alt="Authentcator Code"
                            />
                            <Label>Enter the 6-digit code recieved on your Email address</Label>
                          </div>
                          <div className="col-12">
                            <div className="input-group has-validation">
                              <Input
                                type="text"
                                name="token"
                                placeholder="Authentication Code"
                                className="form-control"
                                id="yourUsername"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.token}
                                required
                              />
                            </div>
                            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                              {errors.token}
                            </span>
                          </div>
                          <div className="col-12 topgap btnarea justify-content-center">
                            <Button className="btn btn-primary" type="submit">
                              Confirm
                            </Button>
                          </div>
                          <div className="col-12 justify-content-center middletext">
                            <p className="topspace">
                              <a
                                href="/login"
                                onClick={() => {
                                  login();
                                }}
                              >
                                Back to Login
                              </a>
                            </p>
                            <p className="topspace">
                              <a href="/authentication">Use another authentication method</a>
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AuthFooter />
    </div>
  );
};

export default AuthenticationEmail;
