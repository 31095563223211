import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { addConfigSchema } from '../pages/common/Validation';
import { addConfig, updateConfig, adminGetConfig } from '../slices/slices/CommonSlice';

export default function ConfigModal(props) {
  const dispatch = useDispatch();

  const { show, close, itemData, configId } = props;

  const handleFormSubmit = (values, { setErrors }) => {
    let temp = {
      title: values.maintitle,
      comment: values.comment,
      values: []
    };
    values.title?.map((item) => {
      temp.values.push({
        value: item.title,
        slug: item.report_value ? item.report_value : ''
      });
    });
    if (itemData?.type == 'add') {
      dispatch(addConfig(temp, setErrors)).then(() => {
        dispatch(adminGetConfig([], 1));
      });
    } else {
      dispatch(updateConfig(configId, temp, setErrors)).then(() => {
        dispatch(adminGetConfig([], 1, false));
      });
    }

    close();
  };

  return (
    <>
      <Modal
        className="bd-example-modal-lg"
        show={show}
        onHide={close}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <h2>{itemData?.type == 'edit' ? 'Edit' : 'Add '} Config</h2>
        </div>
        <Formik
          initialValues={
            itemData?.type == 'edit' ? itemData : { maintitle: '', comment: '', title: [] }
          }
          onSubmit={handleFormSubmit}
          validationSchema={addConfigSchema}
          validateOnBlur={false}
          validateOnChange={false}
          render={({ errors, values, handleChange }) => (
            <Form>
              <Modal.Body>
                <div className="col-md-12">
                  <Field
                    name="maintitle"
                    placeholder="Main Title"
                    style={{ padding: '0.375rem 0.75rem' }}
                  />
                  <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                    {errors?.maintitle}
                  </span>
                </div>
                <FieldArray
                  name="title"
                  render={(arrayHelpers) => {
                    return (
                      <div className="shareholder">
                        {values.title && values.title.length > 0 ? (
                          values.title.map((item, index) => (
                            <div key={index} className="row mt-3">
                              <div className="col-md-5">
                                <Field
                                  name={`title.${index}.title`}
                                  placeholder="Title"
                                  style={{ padding: '0.375rem 0.75rem' }}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`title.${index}.title`} />
                                </span>
                              </div>
                              <div className="col-md-4">
                                <Field
                                  name={`title.${index}.report_value`}
                                  placeholder="Short Name"
                                  style={{ padding: '0.375rem 0.75rem' }}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`title.${index}.report_value`} />
                                </span>
                              </div>

                              <div
                                className="col-md-3 mt-1"
                                style={{ padding: 0, textAlign: 'center' }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    margin: '1px',
                                    height: '35px',
                                    width: '35px',
                                    padding: 0,
                                    lineHeight: '33px',
                                    borderRadius: '50%'
                                  }}
                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                  -
                                </button>
                                <button
                                  type="button"
                                  style={{
                                    margin: '1px',
                                    height: '35px',
                                    width: '35px',
                                    padding: 0,
                                    lineHeight: '33px',
                                    borderRadius: '50%'
                                  }}
                                  onClick={() =>
                                    arrayHelpers.insert(index + 1, { title: '', report_value: '' })
                                  } // insert an empty string at a position
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <button type="button btn-secondary" onClick={() => arrayHelpers.push('')}>
                            {/* show this when user has removed all friends from the list */}
                            Add a config
                          </button>
                        )}

                        <div className="col-md-12">
                          <textarea
                            name="comment"
                            className="form-control mt-2"
                            placeholder="Comment"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={values?.comment}
                          ></textarea>
                          <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {errors?.comment}
                          </span>
                        </div>
                      </div>
                    );
                  }}
                />
              </Modal.Body>
              <div className="modal-action modal-footer">
                <button type="button" className="btn btn-secondary" onClick={close}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  {itemData?.type == 'edit' ? 'Update' : 'Create '}
                </button>
              </div>
            </Form>
          )}
        />
      </Modal>
    </>
  );
}
