import React from 'react';
import FSTables from '../FStable/table/index';
import { formatNumber } from '../../../../../pages/common/Misc';
export default function Securities(props) {
  const { detailComponent, theadData, securityData, securitytotal, values } = props;

  return (
    <FSTables
      values={values}
      detailComponent={detailComponent}
      theadData={theadData}
      tbodyData={securityData}
      customClass="table security_owner fac-sec-table"
      Type="securities"
      totalSpan={4}
      theadColor={'#3278C2'}
      tfootData={
        <tfoot>
          <tr>
            <td colSpan={4}>Total</td>
            <td className="text-end">{formatNumber(securitytotal?.marketvaluetotal)}</td>
            <td colSpan={2} className="text-end">
              {formatNumber(securitytotal?.landingtotal)}
            </td>

            <td></td>
          </tr>
        </tfoot>
      }
    />
  );
}
