import { Formik } from 'formik';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState, useMemo } from 'react';
import {
  facilityState,
  getFacilityData,
  getMemberData,
  getMetaData,
  setUpdateFacilityDataResponse,
  updateFacility
} from '../../../../slices/slices/FacilitySlice';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from './components/Form';
import { displayNameFromType } from '../../../common/Misc';
import { addFacilitySchema } from '../../../common/Validation';
import {
  getMemberList,
  jobSetupState,
  getJobSettingsData
} from '../../../../slices/slices/JobSetupSlice';
import Calculations from '../../../../utils/calculations';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';
import moment from 'moment';

const EditFacility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formValues = useRef();
  const { jobId, memberId, facilityId } = useParams();
  const [sensitiseFacility, setSensitiseFacility] = useState(0);
  // const [defaultData, setDefaultData] = useState({});
  const [afterData, setAfterData] = useState({});
  const [arrayDisable, setArrayDisable] = useState([]);
  const { memberDataResponse, facilityDataResponse, updateFacilityDataResponse, metaDataResponse } =
    useSelector(facilityState);
  const { memberListResponse, jobSettingsDataResponse } = useSelector(jobSetupState);
  let existingValues = [];
  let overwrittenFields = [];

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useMemo(() => {
    setAfterData({});
  }, []);

  useEffect(() => {
    dispatch(getMemberList(jobId));
  }, []);

  useEffect(() => {
    updateValue(
      {
        ...facilityDataResponse,
        purpose: facilityDataResponse.purpose
      },
      'default'
    );

    if (
      facilityDataResponse.additional_info !== undefined &&
      facilityDataResponse.additional_info.sensitise_facility !== undefined
    ) {
      setSensitiseFacility(facilityDataResponse.additional_info.sensitise_facility);
    } else {
      setSensitiseFacility(0);
    }
  }, [facilityDataResponse]);

  useEffect(() => {
    dispatch(getJobSettingsData(jobId));
    dispatch(getMemberData(jobId, memberId));
    dispatch(getFacilityData(jobId, memberId, facilityId));
    dispatch(getMetaData());
  }, []);

  useEffect(() => {
    if (updateFacilityDataResponse.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setUpdateFacilityDataResponse({}));
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/facilities');
    }
  }, [updateFacilityDataResponse]);

  const handleFormSubmit = (values, { setErrors }) => {
    let member = [];
    setCanShowDialogLeavingPage(false);
    {
      values?.owners?.map((item) => {
        member.push({
          member_id: item.member_id?.value,
          holding: item?.holding
        });
      });

      values.sensitise_facility = sensitiseFacility;
      if (values?.is_completed === 0) {
        values?.owners?.map((val, index) => {
          return (values.owners[index].member_id = val?.member_id?.value);
        });
      } else {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += values.owners[index].holding;
        });
        total === 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      }
    }

    let limit = values?.proposed_limit;
    if (
      (values?.product === 'asset-finance-chp' ||
        values?.product === 'asset-finance-chattel-mortgage' ||
        values?.product === 'asset-finance-lease') &&
      values?.facility_status === 'existing'
    ) {
      limit = values?.existing_limit;
    }

    let interest_values = Calculations.calculatePAValues(
      limit,
      values?.balloon,
      values?.interest_rate_pa,
      values?.facility_fee ? values?.facility_fee : 0,
      values?.repayment_amount,
      values?.repayment_term_months,
      values?.term_remaining_months,
      values?.repayment_frequency,
      values?.repayment_type
    );
    let sensitised_interest_rate = Calculations.calculateSensitisedPAValues(
      limit,
      values?.balloon,
      values?.sensitise_interest_rate,
      values?.repayment_amount,
      values?.sensitise_repayment_term_months,
      values?.sensitise_repayment_term_months,
      values?.repayment_frequency,
      values?.sensitise_repayment_type
        ? values?.sensitise_repayment_type
        : values?.repayment_type
        ? values?.repayment_type
        : null
    );
    let sensitise_repayment_pa = sensitised_interest_rate?.repayment_pa;
    let sensitise_interest_pa = sensitised_interest_rate?.interest_pa;

    const data = {
      ...values,
      disabledFields: arrayDisable,
      existing_limit: values.facility_status === 'new' ? 0 : values.existing_limit,
      current_balance: values.facility_status === 'new' ? 0 : values.current_balance,
      ...interest_values,
      sensitise_repayment_pa,
      sensitise_interest_pa,
      owners: member
    };

    dispatch(updateFacility(jobId, memberId, facilityId, data, setErrors, updateValue));
  };

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const getOwners = (data) => {
    let temp;

    if (
      (data?.facility_owners && data?.facility_owners?.length > 0) ||
      (data?.owners && data?.owners?.length > 0)
    ) {
      let ownerArray = data?.facility_owners ? data?.facility_owners : data?.owners;
      temp = ownerArray?.map((share) => {
        return {
          member_id: newColumnsForAll?.find(
            (item) =>
              item?.value ===
              (share?.pivot?.member_id
                ? share?.pivot?.member_id
                : share?.member_id?.value
                ? share?.member_id?.value
                : share?.member_id)
          ),
          holding: share?.pivot?.holding ? share?.pivot?.holding : share?.holding
        };
      });
    } else {
      temp = [
        {
          member_id: '',
          holding: 0
        }
      ];
    }

    return temp;
  };

  const updateValue = (data, value = 'updated') => {
    if (value == 'default') {
      setAfterData({
        purpose: data?.purpose,
        loan: data?.loan,
        owners: getOwners(data),
        facility_status: data?.additional_info?.facility_status,
        product: data?.product_facility,
        repayment_type: data?.additional_info?.repayment_type,
        lender: data?.lender,
        existing_limit: data?.existing_limit,
        current_balance: data?.additional_info?.current_balance,
        proposed_limit: data?.additional_info?.proposed_limit,
        balloon: data?.additional_info?.balloon,
        interest_rate_pa: data?.additional_info?.interest_rate_pa,
        facility_fee: data?.additional_info?.facility_fee,
        interest_rate_type: data?.additional_info?.interest_rate_type,
        // interest_rate_identifier: data?.additional_info?.interest_rate_identifier,
        fixed_rate_expiry_date_value_init: data?.additional_info?.fixed_rate_expiry_date,
        repayment_term_months: data?.additional_info?.repayment_term_months,
        // repayment_term_identifier: data?.additional_info?.repayment_term_identifier,
        repayment_amount: data?.additional_info?.repayment_amount,
        repayment_frequency: data?.additional_info?.repayment_frequency,
        facility_expiry_date_value_init: data?.additional_info?.facility_expiry_date,
        term_remaining_months: data?.additional_info?.term_remaining_months,
        other_fees: data?.additional_info?.other_fees,
        amount: data?.additional_info?.amount,
        frequency: data?.additional_info?.frequency,
        sensitise_facility: data?.additional_info?.sensitise_facility,
        sensitise_repayment_type: data?.additional_info?.sensitise_repayment_type,
        sensitise_interest_rate: data?.additional_info?.sensitise_interest_rate,
        // sensitise_interest_rate_assumption:
        //   data?.additional_info?.sensitise_interest_rate_assumption,
        sensitise_repayment_term_months: data?.additional_info?.sensitise_repayment_term_months,
        // sensitise_repayment_term_assumption:
        //   data?.additional_info?.sensitise_repayment_term_assumption,
        sensitise_facility_expiry_date_value_init:
          data?.additional_info?.sensitise_facility_expiry_date,
        report_comments: data?.additional_info?.report_comments,
        internal_notes: data?.additional_info?.internal_notes,
        is_completed: 0,
        disabledFields: data?.additional_info?.disabledFields
          ? data?.additional_info?.disabledFields
          : []
      });
    } else {
      delete data.term_remaining_months;
      setAfterData({
        ...data,
        owners: getOwners(data),
        fixed_rate_expiry_date_value_init: data?.fixed_rate_expiry_date
          ? moment(data?.fixed_rate_expiry_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : '',
        facility_expiry_date_value_init: data?.facility_expiry_date
          ? moment(data?.facility_expiry_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : '',
        is_completed: 0
      });
    }
  };
  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/facilities'}>
                  Facilities
                </Link>
              </li>
            </ul>
            <h1>Facilities - {displayNameFromType(memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right">
            <NavDropdown title="" id="nav-dropdown" className="d-flex align-items-center icon-info">
              <NavDropdown.Item className="dropdown-item information">
                <h4>
                  Information about
                  <br />
                  relative screen
                </h4>
                <a href="/facilities" className="close">
                  <i className="icon-close"></i>
                </a>
                <p>
                  Lorem ipsum dolor sit amet, consecte tur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                  fugiat nulla pariatur.
                </p>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <section className="new_addmember">
            <Formik
              initialValues={
                afterData
                //   {
                //   purpose: defaultData?.purpose,
                //   loan: defaultData?.loan,
                //   owners: getOwners(),
                //   facility_status: defaultData?.additional_info?.facility_status,
                //   product: defaultData?.product_facility,
                //   repayment_type: defaultData?.additional_info?.repayment_type,
                //   lender: defaultData?.lender,
                //   existing_limit: defaultData?.existing_limit,
                //   current_balance: defaultData?.additional_info?.current_balance,
                //   proposed_limit: defaultData?.additional_info?.proposed_limit,
                //   balloon: defaultData?.additional_info?.balloon,
                //   interest_rate_pa: defaultData?.additional_info?.interest_rate_pa,
                //   facility_fee: defaultData?.additional_info?.facility_fee,
                //   interest_rate_type: defaultData?.additional_info?.interest_rate_type,
                //   // interest_rate_identifier: defaultData?.additional_info?.interest_rate_identifier,
                //   fixed_rate_expiry_date_value_init:
                //     defaultData?.additional_info?.fixed_rate_expiry_date,
                //   repayment_term_months: defaultData?.additional_info?.repayment_term_months,
                //   // repayment_term_identifier: defaultData?.additional_info?.repayment_term_identifier,
                //   repayment_amount: defaultData?.additional_info?.repayment_amount,
                //   repayment_frequency: defaultData?.additional_info?.repayment_frequency,
                //   facility_expiry_date_value_init: defaultData?.additional_info?.facility_expiry_date,
                //   term_remaining_months: defaultData?.additional_info?.term_remaining_months,
                //   other_fees: defaultData?.additional_info?.other_fees,
                //   amount: defaultData?.additional_info?.amount,
                //   frequency: defaultData?.additional_info?.frequency,
                //   sensitise_facility: defaultData?.additional_info?.sensitise_facility,
                //   sensitise_repayment_type: defaultData?.additional_info?.sensitise_repayment_type,
                //   sensitise_interest_rate: defaultData?.additional_info?.sensitise_interest_rate,
                //   // sensitise_interest_rate_assumption:
                //   //   defaultData?.additional_info?.sensitise_interest_rate_assumption,
                //   sensitise_repayment_term_months:
                //     defaultData?.additional_info?.sensitise_repayment_term_months,
                //   // sensitise_repayment_term_assumption:
                //   //   defaultData?.additional_info?.sensitise_repayment_term_assumption,
                //   // sensitise_facility_expiry_date_value_init:
                //   //   defaultData?.additional_info?.sensitise_facility_expiry_date,
                //   report_comments: defaultData?.additional_info?.report_comments,
                //   internal_notes: defaultData?.additional_info?.internal_notes,
                //   is_completed: 0
                // }
              }
              enableReinitialize
              onSubmit={handleFormSubmit}
              innerRef={formValues}
              validationSchema={addFacilitySchema}
            >
              {({
                values,
                setErrors,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                resetForm
              }) => {
                return (
                  <>
                    <DialogLeavingPage
                      showDialog={showDialogLeavingPage}
                      confirmNavigation={confirmNavigation}
                      cancelNavigation={cancelNavigation}
                    />
                    <Form
                      values={values}
                      errors={errors}
                      setErrors={setErrors}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      handleInputChange={handleInputChange}
                      setSensitiseFacility={setSensitiseFacility}
                      sensitiseFacility={sensitiseFacility}
                      metaDataResponse={metaDataResponse}
                      jobSettingsDataResponse={jobSettingsDataResponse}
                      memberDataResponse={memberDataResponse}
                      memberListResponse={memberListResponse}
                      memberId={memberId}
                      jobId={jobId}
                      facilityId={facilityId}
                      formValues={formValues}
                      overwrittenFields={overwrittenFields}
                      existingValues={existingValues}
                      arrayDisable={arrayDisable}
                      setArrayDisable={setArrayDisable}
                      resetForm={resetForm}
                    />
                  </>
                );
              }}
            </Formik>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditFacility;
