import React, { useState } from 'react';
// import { Table } from 'react-bootstrap';
import { Button, Modal, Table } from 'react-bootstrap';
import Label from '../../components/Label';
import Index from './index';

const Profile = () => {
  // const [show, setShow] = useState(false);
  // const [showDelete, setShowDelete] = useState(false);
  // const [showArchive, setShowArchive] = useState(false);
  const [showLock, setShowLock] = useState(false);
  // const [showCopy, setShowCopy] = useState(false);

  const handleLock = () => {
    setShowLock(false);
  };
  // const handleDelete = () => {
  //   setShowDelete(false);
  // };
  // const handleArchive = () => {
  //   setShowArchive(false);
  // };
  // const handleCopy = () => {
  //   setShowCopy(false);
  // };
  return (
    // <div className="setting toggle-sidebar">
    //   <UserHeader />
    <div id="main" className="main" style={{ marginTop: '115px' }}>
      <div className="card">
        <div className="card-body">
          <Index />
          {/* <div className="profile-tabs">
              <ul className="nav nav-tabs d-flex" id="myTab" role="tablist">
                <li className="nav-item flex-fill" role="presentation">
                  <button
                    className="nav-link w-100 active"
                    id="current-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    JobTable
                  </button>
                </li>
                <li className="nav-item flex-fill" role="presentation">
                  <button
                    className="nav-link w-100"
                    id="archived-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Archived
                  </button>
                </li>
                <li className="nav-item flex-fill" role="presentation">
                  <button
                    className="nav-link w-100"
                    id="deleted-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Deleted
                  </button>
                </li>
              </ul>
              <div className="searchbox">
                <form>
                  <input type="text" placeholder="" className="searchbox-field" />
                </form>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#CreatJobModal"
                  className="green topbtn"
                >
                  + Job
                </button>
              </div>
            </div> */}
          <div className="tab-content profile-table-block" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="current-tab"
            >
              <form className="row needs-validation" noValidate>
                <div className="table-btn">
                  <button
                    type="button"
                    onClick={() => {
                      setShowLock(true);
                    }}
                  >
                    Lock
                  </button>
                  <button type="button" data-toggle="modal" data-target="#ArchiveModal">
                    Archive
                  </button>
                  <button type="button" data-toggle="modal" data-target="#DeleteModal">
                    Delete
                  </button>
                  <button type="button" data-toggle="modal" data-target="#CopyModal">
                    Copy
                  </button>
                </div>
                <div className="innertab">
                  <Table striped bordered hover className="table table-hover" size="sm">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input type="checkbox" />
                        </th>
                        <th scope="col">Job No.</th>
                        <th scope="col">Group Name</th>
                        <th scope="col">Job Name</th>
                        <th scope="col">State</th>
                        <th scope="col">Start</th>
                        <th scope="col">Due</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1033</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr className="lock">
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="table-pagination">
                  <div className="limiter">
                    Showing{' '}
                    <select>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>{' '}
                    jobs per page
                  </div>
                  <div className="pager">
                    <button>
                      <i className="fa fa-angle-left"></i> Prev
                    </button>
                    <ul className="pages">
                      <li className="active">
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                    </ul>
                    <button>
                      Next <i className="fa fa-angle-right"></i>
                    </button>
                    <button>
                      End <i className="fa fa-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="archived-tab"
            >
              <form className="row needs-validation" noValidate>
                <div className="table-btn">
                  <button type="button" data-toggle="modal" data-target="#LockModal">
                    Lock
                  </button>
                  <button type="button" data-toggle="modal" data-target="#ArchiveModal">
                    Archive
                  </button>
                  <button type="button" data-toggle="modal" data-target="#DeleteModal">
                    Delete
                  </button>
                  <button type="button" data-toggle="modal" data-target="#CopyModal">
                    Copy
                  </button>
                </div>
                <div className="innertab">
                  <Table striped bordered hover className="table table-hover" size="sm">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input type="checkbox" />
                        </th>
                        <th scope="col">Job No.</th>
                        <th scope="col">Group Name</th>
                        <th scope="col">Job Name</th>
                        <th scope="col">State</th>
                        <th scope="col">Start</th>
                        <th scope="col">Due</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1033</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr className="lock">
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="table-pagination">
                  <div className="limiter">
                    Showing{' '}
                    <select>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>{' '}
                    jobs per page
                  </div>
                  <div className="pager">
                    <button>
                      <i className="fa fa-angle-left"></i> Prev
                    </button>
                    <ul className="pages">
                      <li className="active">
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                    </ul>
                    <button>
                      Next <i className="fa fa-angle-right"></i>
                    </button>
                    <button>
                      End <i className="fa fa-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="deleted-tab"
            >
              <form className="row needs-validation" noValidate>
                <div className="table-btn">
                  <button type="button" data-toggle="modal" data-target="#LockModal">
                    Lock
                  </button>
                  <button type="button" data-toggle="modal" data-target="#ArchiveModal">
                    Archive
                  </button>
                  <button type="button" data-toggle="modal" data-target="#DeleteModal">
                    Delete
                  </button>
                  <button type="button" data-toggle="modal" data-target="#CopyModal">
                    Copy
                  </button>
                </div>
                <div className="innertab">
                  <Table striped bordered hover className="table table-hover" size="sm">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input type="checkbox" />
                        </th>
                        <th scope="col">Job No.</th>
                        <th scope="col">Group Name</th>
                        <th scope="col">Job Name</th>
                        <th scope="col">State</th>
                        <th scope="col">Start</th>
                        <th scope="col">Due</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1033</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr className="lock">
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <input type="checkbox" />
                        </th>
                        <td>J1032</td>
                        <td>Expo Superstore</td>
                        <td>Purchase as new warehouse</td>
                        <td>
                          <select>
                            <option>Planned</option>
                            <option>Information Request</option>
                            <option>Prepare Proposal</option>
                            <option>Proposal Submitted</option>
                            <option>Offer Recieved</option>
                            <option>Application form Submitted</option>
                            <option>Approved and Accepted</option>
                            <option>Documentation Completed</option>
                            <option>Settlement</option>
                            <option>Payment Received</option>
                          </select>
                        </td>
                        <td>1 Jul 2020</td>
                        <td>28 Oct 2020</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="table-pagination">
                  <div className="limiter">
                    Showing{' '}
                    <select>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>{' '}
                    jobs per page
                  </div>
                  <div className="pager">
                    <button>
                      <i className="fa fa-angle-left"></i> Prev
                    </button>
                    <ul className="pages">
                      <li className="active">
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                    </ul>
                    <button>
                      Next <i className="fa fa-angle-right"></i>
                    </button>
                    <button>
                      End <i className="fa fa-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Lock Modal */}

      <Modal
        show={showLock}
        onHide={handleLock}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Lock Job</Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <p>Are you sure you want to lock this job?</p>
            <div className="modal-action modal-footer">
              <Button className="btn btn-secondary" onClick={handleLock}>
                Cancel
              </Button>
              <Button className="btn btn-primary">Lock</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* {showLock ? (
          <div className="modal" id="LockModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Lock Job</h5>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to lock this job?</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary">
                    Lock
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null} */}

      {/* Archive Modal */}
      <div className="modal" id="ArchiveModal" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Archive Job</h5>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to archive this job?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Archive
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <div className="modal" id="DeleteModal" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Job?</h5>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to delete this job permanently? The job will be permanently
                deleted after 30 days
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Copy Modal */}
      <div className="modal" id="CopyModal" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Copy Job</h5>
            </div>
            <div className="modal-body">
              <input type="text" value="Enter New Job Name" />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Create Modal */}
      <div className="modal" id="CreatJobModal" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body create-job">
              <h3>Create New Job</h3>
              <div className="create-btn">
                <button type="button" className="btn btn-primary">
                  Import from XPM
                </button>
                or
                <button type="button" className="btn btn-secondary" id="manualjobbtn">
                  Manual Job Set Up
                </button>
              </div>
              <div className="createjob_v2">
                <form>
                  <fieldset>
                    <div className="field-box">
                      <Label>Group Name</Label>
                      <input type="text" />
                    </div>
                    <div className="field-box">
                      <Label>Job Name</Label>
                      <input type="text" />
                    </div>
                    <div className="field-box date" id="datetimepicker1">
                      <Label>Due Date</Label>
                      <input type="text" id="datepicker" />
                    </div>
                  </fieldset>
                  <div className="modal-action">
                    <button type="button" className="btn btn-secondary" id="createbtnjob">
                      Cancel
                    </button>
                    <button type="button" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Profile;
