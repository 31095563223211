import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const jobInitialState = {
  loading: false,
  jobData: {},
  xpmjobData: {},
  dashboardData: {},
  reloadJobs: 0,
  successResponse: {},
  groupDataResponse: {},
  stateJobDataResponse: {},
  updateStateResponse: {},
  addxpmJob: {}
};

export const JobSlice = createSlice({
  name: 'job',
  initialState: jobInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setToolsAndTemplatesResponse: (state, action) => {
      state.toolsAndTemplatesResponse = action.payload.data;
      state.loader = false;
    },
    setJobDataResponse: (state, action) => {
      state.jobData = action.payload.data;
      state.loader = false;
    },
    setXpmJobDataResponse: (state, action) => {
      state.xpmjobData = action.payload.data;
      state.loader = false;
    },
    setDashboardResponse: (state, action) => {
      state.dashboardData = action.payload;
      state.loader = false;
    },
    setReloadJob: (state) => {
      if (state.reloadJobs === 0) {
        state.reloadJobs = 1;
      } else {
        state.reloadJobs = 0;
      }
    },
    setGroupDataResponse: (state, action) => {
      state.groupDataResponse = action.payload;
      state.loader = false;
    },
    setSuccessResponse: (state, action) => {
      state.successResponse = action.payload;
      state.loader = false;
    },
    setUpdateStateResponse: (state, action) => {
      state.updateStateResponse = action.payload;
      state.loader = false;
    },
    setaddxpmJob: (state, action) => {
      state.addxpmJob = action.payload;
      state.loader = false;
    },
    setStateJobDataResponse: (state, action) => {
      state.stateJobDataResponse = action.payload;
      state.loader = false;
    }
  }
});
export const getDownloadToolsAndTemplates = (fielURL) => async () => {
  const response = await axios.get(fielURL, {
    responseType: 'blob'
  });
  return response;
};

export const getJobByStatus =
  ({ status, page = 1, showing = 25, search = '', setErrors }) =>
  async (dispatch) => {
    let exist = localStorage.getItem('isOTPVerified');
    if (exist === 'true') {
      let body = 'status=' + status + '&page=' + page + '&limit=' + showing;
      if (search !== '') {
        body += '&search=' + search;
      }
      const response = await clientService.get('jobs?' + body, setErrors, dispatch, false);
      if (response) {
        if (typeof response === 'string') {
          dispatch(setErrorMessage(response));
        } else {
          dispatch(setJobDataResponse(response.data));
        }
      }
    }
  };

export const getJobByXpm = (tenantId) => async (dispatch) => {
  const response = await clientService.get('xpm/jobs', {}, dispatch, true, true, {
    'xero-tenant-id': tenantId
  });
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setXpmJobDataResponse(response.data));
  }
  return response;
};

export const getGroupData = (setErrors) => async (dispatch) => {
  const response = await clientService.get('groups', setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGroupDataResponse(response.data));
  }
};

export const getStateJobData = (setErrors) => async (dispatch) => {
  const response = await clientService.get('states', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setStateJobDataResponse(response.data));
  }
};

export const addJob = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('jobs', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
  }
  return response;
};

export const addxpmJob = (data, tenantId, setErrors) => async (dispatch) => {
  const response = await clientService.post('xpm/jobs', data, setErrors, dispatch, true, true, {
    'xero-tenant-id': tenantId,
    cancel: 'yes'
  });
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setaddxpmJob(response.data));
  }
  return response;
};

export const updateJob = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put('jobs', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
    dispatch(setReloadJob());
  }
};

export const updateState = (id, state, setErrors) => async (dispatch) => {
  var tmp_data = {
    ids: [id],
    state: state
  };
  const response = await clientService.put('jobs/action/state', tmp_data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdateStateResponse(response.data));
    // dispatch(setReloadJob());
  }
};

export const unlockFile = (id, setErrors) => async (dispatch) => {
  var tmp_data = {
    ids: [id]
  };
  const response = await clientService.put('jobs/action/unlock', tmp_data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
  }
};

export const changeStatus = (ids, status, setErrors) => async (dispatch) => {
  var tmp_data = {
    ids: ids,
    status: status
  };
  const response = await clientService.put('jobs/action/status', tmp_data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
  }
};

export const copyFile = (id, data, setErrors) => async (dispatch) => {
  var job_data = id;
  const response = await clientService.post(
    'jobs/action/copy/' + job_data,
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setReloadJob());
  }
};

export const getDashboardJobData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get('jobs/' + id + '/dashboard', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDashboardResponse(response.data));
  }
};

export const {
  setLoader,
  setToolsAndTemplatesResponse,
  setJobDataResponse,
  setXpmJobDataResponse,
  setDashboardResponse,
  setReloadJob,
  setGroupDataResponse,
  setStateJobDataResponse,
  setSuccessResponse,
  setUpdateStateResponse,
  setaddxpmJob
} = JobSlice.actions;

export const jobState = (state) => {
  return state.job;
};

export default JobSlice.reducer;
