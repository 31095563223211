import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import Header from '../Header';
import Table from 'react-bootstrap/Table';
import {
  reportState,
  getPublishedReportData,
  deleteReport,
  setDeleteReportDataResponse,
  getDownloadReportData
} from '../../../slices/slices/ReportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Button from '../../../components/Button';
import { ReportPath } from './common/ReportPath';

const PublishedReport = () => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { publishedReportDataResponse, deleteReportDataResponse } = useSelector(reportState);
  const [reportId, setReportId] = useState();
  const [showDelete, setShowDelete] = useState(false);

  const delJob = () => {
    setShowDelete(false);
    dispatch(deleteReport(jobId, reportId));
    dispatch(getPublishedReportData(jobId));
  };

  const handleDelete = () => {
    setShowDelete(false);
  };

  const handleDownload = async (id) => {
    dispatch(getDownloadReportData(jobId, id)).then((res) => {
      if (res) {
        try {
          const export_report_path = ReportPath + res.data;
          var file = export_report_path.split('/');
          saveAs(export_report_path, file[file.length - 1] || 'published_report.pdf');
          // if (export_report_path) {
          //   var a = document.createElement('a');
          //   a.href = export_report_path;
          //   var file = export_report_path.split('/');
          //   a.setAttribute('download', file[file.length - 1] || 'published_report.pdf');
          //   a.setAttribute('target', '_blank');
          //   document.body.appendChild(a);
          //   a.click();
          //   a.remove();
          // }
        } catch (e) {
          console.log(e);
        }
      }
    });
  };

  // useEffect(() => {
  //   if (downloadReportDataResponse?.data) {
  //   }
  // });

  useEffect(() => {
    if (deleteReportDataResponse?.message) {
      setShowDelete(false);
      dispatch(getPublishedReportData(jobId));
      dispatch(setDeleteReportDataResponse({}));
    }
  }, [deleteReportDataResponse?.message]);

  useEffect(() => {
    dispatch(getPublishedReportData(jobId));
  }, [showDelete]);
  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="#">Group Reports</a>
              </li>
              <li>
                <strong>Published Reports</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-between align-items-center">
            <h1>Published Reports</h1>
          </div>
        </div>
        <div className="white-card">
          <Table className="table table-hover">
            <thead>
              <tr>
                <th scope="col" className="text-start">
                  Job Name
                </th>
                <th scope="col" className="text-start">
                  Report Type
                </th>
                <th scope="col" className="text-start">
                  Published By
                </th>
                <th scope="col" className="text-start">
                  Date Created
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {publishedReportDataResponse && publishedReportDataResponse?.length > 0 ? (
                publishedReportDataResponse?.map((item) => {
                  return (
                    <tr key={item?.id}>
                      <td className="text-start">{item?.job?.name}</td>
                      <td className="text-start">{item?.report_type}</td>
                      <td className="text-start">{item?.published_by}</td>
                      <td className="text-start">
                        {moment(item?.created_at).format('DD/MM/YYYY HH:mm:ss A')}
                      </td>
                      <td>
                        {item?.path ? (
                          <ul className="action-btn">
                            {/* <li>
                          <Button>
                            <i className="fa fa-file-alt"></i>
                          </Button>
                        </li> */}
                            <li>
                              <Button
                                onClick={() => {
                                  setReportId(item?.id);
                                  handleDownload(item?.id);
                                }}
                              >
                                <i className="fa fa-download"></i>
                              </Button>
                            </li>
                            <li>
                              <Button
                                onClick={() => {
                                  setReportId(item?.id);
                                  handleDownload(item?.id);
                                }}
                              >
                                <i className="fa fa-print"></i>
                              </Button>
                            </li>
                            {/* <li>
                          <Button>
                            <i className="fa fa-file-export"></i>
                          </Button>
                        </li> */}
                            <li>
                              <Button
                                onClick={() => {
                                  setReportId(item?.id);
                                  setShowDelete(true);
                                }}
                              >
                                <i className="fa fa-trash-alt"></i>
                              </Button>
                            </li>
                          </ul>
                        ) : item?.report_status === 3 ? (
                          'Error while creating'
                        ) : item?.report_status === 1 ? (
                          'In Progress'
                        ) : (
                          'Not Started'
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5">No Records Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={showDelete}
        onHide={handleDelete}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Delete Job?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this job?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button className="btn btn-secondary" onClick={handleDelete}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              delJob();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default PublishedReport;
