import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FSTables from './table';
import Label from '../../../../Label';
import Facilities from './Facilities';
import Securities from './Securities';
import CustomModal from '../../../../CustomModal';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import { Field } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import {
  // displayNameFromType,
  formatNumber,
  displayOwnerNameFromType
} from '../../../../../pages/common/Misc';
import { unlinkSecurity } from '../../../../../slices/slices/ProposedFacilitiesSecuritySlice';
import Button from '../FStable/Button';
import Title from '../FStable/Title';

export default function FSTable(props) {
  const { facilitiesGroupData, activeTab, values, /* setFieldValue, */ handleInputChange } = props;
  const [personalData, setPersonalData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [securitiesData, setsecurityData] = useState([]);
  const [securitytotalAmount, setSecurityTotal] = useState({});
  const [showModel, setShowModel] = useState(false);
  const [unlinkId, setUnlinkId] = useState({});

  // const [reportComment, setReportcomment] = useState(EditorState.createEmpty());

  const navigate = useNavigate();
  const { jobId } = useParams();
  const dispatch = useDispatch();

  const handleOnClick = (member, facilities, type) => {
    navigate('/job/' + jobId + '/data-entry/' + member + '/' + type + '/' + facilities);
  };

  const handleCancel = () => {
    setShowModel(false);
  };

  const handleUnlink = () => {
    if (jobId) {
      dispatch(
        unlinkSecurity(
          jobId,
          unlinkId.facilityId,
          unlinkId.securityId,
          unlinkId.memberId,
          activeTab
        )
      ).then((response) => {
        if (response.status == 200) {
          if (unlinkId.type == 'business' || unlinkId.type == 'personal') {
            let arrayList = unlinkId.type == 'personal' ? personalData : businessData;
            let securityData = arrayList[
              unlinkId.parentIndex
            ].detailData.securitydetailarray.splice(unlinkId.index, 1);
            arrayList[unlinkId.parentIndex].detailData.detailDataTotal.lending_value -= parseFloat(
              securityData[0].landingvalue
            );
            arrayList[unlinkId.parentIndex].detailData.detailDataTotal.market_value -= parseFloat(
              securityData[0].marketvalue
            );
            unlinkId.type == 'personal'
              ? setPersonalData([...arrayList])
              : setBusinessData([...arrayList]);
            setShowModel(false);
          } else {
            let arrayList = securitiesData;
            let facilityData = arrayList[
              unlinkId.parentIndex
            ].detailData.facilitydetailarray.splice(unlinkId.index, 1);

            arrayList[unlinkId.parentIndex].detailData.facilitytotal.existing_limit -= parseFloat(
              facilityData[0].existingLimit
            );
            arrayList[unlinkId.parentIndex].detailData.facilitytotal.proposed_limit -= parseFloat(
              facilityData[0].proposedlimit
            );

            setsecurityData([...arrayList]);
            setShowModel(false);
          }
        }
      });
    }
  };

  const onUnlinkhandle = (securityId, facilityId, memberId, index, type, parentIndex) => {
    setShowModel(true);
    setUnlinkId({ securityId, facilityId, memberId, index, type, parentIndex });
  };

  const tMainheadData = [
    {
      key: 't_no',
      className: 't_no',
      title: ''
    },
    {
      key: 'borrower',
      className: 'text-start t_heads',
      headClassName: 'text-start',
      colSpan: 0,
      style: { width: '19%' },
      title: 'Borrower'
    },
    {
      key: 'purpose',
      className: 'text-start t_heads',
      headClassName: 'text-start',
      style: { width: '19.75%' },
      title: 'Purpose'
    },
    {
      key: 'productFacility',
      className: 'text-center t_heads',
      headClassName: 'text-center',
      style: { width: '11.75%' },
      title: 'Product/Facility'
    },
    {
      key: 'lender',
      className: 'text-center t_heads',
      style: { width: '11%' },
      title: 'Lender'
    },
    {
      key: 'existingLimit',
      className: 'text-end t_heads',
      style: { width: '10%' },
      title: 'Existing Limit',
      headClassName: 'text-center',
      render: (item) => formatNumber(item)
    },
    {
      key: 'status',
      className: 'text-center t_heads',
      style: { width: '12.75%' },
      title: 'Status',
      headClassName: 'text-center',
      render: (item) => item
    },
    {
      key: 'change',
      className: 'text-end t_heads',
      headClassName: 'text-center',
      style: { width: '12%' },
      title: 'Change +/- ',
      render: (item) => formatNumber(item)
    },
    {
      key: 'proposedlimit',
      className: 'text-end t_head',
      headClassName: 'text-center',
      colSpan: 0,
      style: { width: '9.6%' },
      title: 'Proposed Limit',
      render: (item) => formatNumber(item)
    },
    {
      key: 't_last',
      className: 'text-center t_head',
      style: { width: '105px' },
      title: '',
      render: (item, record) => {
        return (
          <>
            <i
              className={
                record?.is_completed == 1 ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'
              }
              aria-hidden="true"
              style={{
                color: `${record?.is_completed == 1 ? '#42ba96' : '#eed202'}`
              }}
            ></i>
            <a
              href="#"
              className="link"
              onClick={() =>
                onUnlinkhandle(
                  record.securitiesId,
                  record.key,
                  record.memberId,
                  record.index,
                  record.type,
                  record.parentIndex
                )
              }
            >
              Unlink
            </a>
          </>
        );
      }
    }
  ];
  const theadData = [
    {
      key: 'updown',
      className: 'text-center t_no clickable',
      colSpan: 9,
      title: 'Business',
      render: (item, record) => {
        const { open, setOpen } = record;
        return (
          <i
            className={open ? 'fa fa-caret-up' : 'fa fa-caret-down'}
            onClick={() => {
              setOpen(!open);
            }}
          ></i>
        );
      }
    },
    {
      key: 'business',
      className: 'text-start',
      colSpan: 0,
      title: '',
      width: '23%'
    },
    {
      key: 'purpose',
      className: 'text-start t_head',
      headClassName: 'text-center',
      colSpan: 0,
      title: ''
    },
    {
      key: 'lender',
      className: 'text-start t_head',
      colSpan: 0,
      title: ''
    },
    {
      key: 'existinglimit',
      className: 'text-end t_head',
      colSpan: 0,
      title: '',
      render: (item) => formatNumber(item)
    },
    {
      key: 'status',
      className: 'text-center t_head',
      colSpan: 0,
      title: '',
      render: (item) => item
    },
    {
      key: 'change',
      className: 'text-end t_head',
      colSpan: 0,
      title: '',
      render: (item) => formatNumber(item)
    },
    {
      key: 'proposedlimit',
      className: 'text-end t_head',
      colSpan: 0,
      title: '',
      render: (item) => formatNumber(item)
    },
    {
      key: 'edit',
      className: 'text-center t_no',
      title: '',
      render: (item, record) => {
        return (
          <>
            <i
              className={
                record?.is_completed == 1 ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'
              }
              aria-hidden="true"
              style={{
                marginRight: '10px',
                color: `${record?.is_completed == 1 ? '#42ba96' : '#eed202'}`
              }}
            />
            <a className="edit" style={{ marginRight: 0 }}>
              <i
                className="icon-edit"
                onClick={() => handleOnClick(record.memberId, record.key, 'facilities')}
              />
            </a>
          </>
        );
      }
    }
  ];

  const theadDetailData = [
    {
      key: 'updown',
      className: 'text-center t_no clickable',
      title: '',
      render: (item, record) => {
        const { open, setOpen } = record;
        return (
          <i
            className={open ? 'fa fa-caret-up' : 'fa fa-caret-down'}
            onClick={() => {
              setOpen(!open);
            }}
          ></i>
        );
      }
    },
    {
      key: 'securityowner',
      className: 'text-start t_head',
      headClassName: 'text-start',
      // colSpan: '8',
      title: 'Security Owner'
    },
    {
      key: 'address',
      className: 'text-start',
      headClassName: 'text-start',
      title: 'Address'
    },
    {
      key: 'assetclass',
      className: 'text-center',
      headClassName: 'text-center',
      title: 'Description'
    },
    {
      key: 'marketvalue',
      className: 'text-end',
      headClassName: 'text-center',
      title: 'Market Value',
      render: (item) => formatNumber(item)
    },
    {
      key: 'shading',
      className: 'text-center',
      headClassName: 'text-center',
      title: 'Shading'
    },
    {
      key: 'landingvalue',
      className: 'text-end',
      headClassName: 'text-center',
      title: 'Lending Value',
      render: (item) => formatNumber(item)
    },
    {
      key: 't_last',
      className: 'text-center',
      title: '',
      render: (item, record) => {
        return (
          <>
            <i
              className={
                record?.is_completed == 1 ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'
              }
              aria-hidden="true"
              style={{
                color: `${record?.is_completed == 1 ? '#42ba96' : '#eed202'}`
              }}
            ></i>
            <a
              href="#"
              className="link"
              onClick={() =>
                onUnlinkhandle(
                  record.key,
                  record.facilitiesId,
                  record.memberId,
                  record.index,
                  record.type,
                  record.parentIndex
                )
              }
            >
              Unlink
            </a>
          </>
        );
      }
    },
    {
      key: 'edit',
      className: 'text-center t_no',
      title: '',
      render: (item, record) => {
        return (
          <>
            <i
              className={
                record?.is_completed == 1 ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'
              }
              aria-hidden="true"
              style={{
                marginRight: '10px',
                color: `${record?.is_completed == 1 ? '#42ba96' : '#eed202'}`
              }}
            ></i>
            <a className="edit" style={{ marginRight: 0 }}>
              <i
                className="icon-edit"
                onClick={() => handleOnClick(record.memberId, record.key, 'securities')}
              ></i>
            </a>
          </>
        );
      }
    }
  ];

  const getSecurityHead = () => {
    return [
      ...theadDetailData.slice(0, theadDetailData.length - 2),
      theadDetailData[theadDetailData.length - 1]
    ];
  };

  const getMainHeadData = () => {
    return [...tMainheadData.slice(0, 3), ...tMainheadData.slice(4, tMainheadData.length)];
  };

  useEffect(() => {
    if (activeTab == 2) {
      let personalDataarray = new Array();
      let businessDataarray = new Array();
      if (facilitiesGroupData?.data && facilitiesGroupData?.data.length > 0) {
        let personalDataTotal = { existing_limit: 0, proposed_limit: 0 };
        let businessdataTotal = { existing_limit: 0, proposed_limit: 0 };
        let personnalParentIndex, businessParentIndex;
        personnalParentIndex = businessParentIndex = 0;

        facilitiesGroupData?.data.map((item) => {
          let personalOwners = item.facility_owners.filter((item) => item.type === 'individual');
          if (personalOwners.length > 0) {
            let existing_limit = Number(item?.existing_limit);
            if (
              (item?.product_facility === 'asset-finance-chp' ||
                item?.product_facility === 'asset-finance-chattel-mortgage' ||
                item?.product_facility === 'asset-finance-lease') &&
              item?.additional_info?.facility_status === 'existing'
            ) {
              existing_limit = Number(item?.additional_info?.current_balance);
            }
            personalDataTotal.existing_limit += existing_limit;
            personalDataTotal.proposed_limit += parseFloat(item.additional_info.proposed_limit);
            let detailDataTotal = { market_value: 0, lending_value: 0 };
            let securitydetailarray = new Array();
            item?.securities.map((security) => {
              detailDataTotal.market_value += security.market_value;
              detailDataTotal.lending_value += security.lending_value;
              securitydetailarray.push({
                key: security.id,
                t_last: '',
                memberId: security?.security_owners[0]?.id,
                facilitiesId: item.id,
                securityowner: displayOwnerNameFromType(security?.security_owners),
                address: security?.address,
                assetclass: security?.asset_class_val,
                is_completed: security?.is_completed,
                marketvalue: security?.market_value,
                status:
                  item?.additional_info?.facility_status == 'new'
                    ? 'New'
                    : item?.additional_info?.facility_status == 'refinance'
                    ? 'Refinance'
                    : item?.additional_info?.facility_status == 'variation'
                    ? 'Variation'
                    : item?.additional_info?.facility_status == 'existing'
                    ? 'No Change'
                    : '',
                shading: formatNumber(security?.shading) + '%',
                type: 'personal',
                parentIndex: personnalParentIndex,
                landingvalue: security?.lending_value
              });
            });
            personalDataarray.push({
              key: item.id,
              updown: '',
              edit: '',
              memberId: item?.facility_owners[0]?.id,
              business: displayOwnerNameFromType(personalOwners),
              purpose: item.purpose,
              lender: item.lender_val,
              existinglimit: existing_limit,
              status:
                item?.additional_info?.facility_status == 'new'
                  ? 'New'
                  : item?.additional_info?.facility_status == 'refinance'
                  ? 'Refinance'
                  : item?.additional_info?.facility_status == 'variation'
                  ? 'Variation'
                  : item?.additional_info?.facility_status == 'existing'
                  ? 'No Change'
                  : '',
              is_completed: item.is_completed,
              change: parseFloat(item.additional_info.proposed_limit) - existing_limit,
              proposedlimit: parseFloat(item.additional_info.proposed_limit),
              detailData: { securitydetailarray, detailDataTotal },
              type: 'personal',
              personalDataTotal
            });
            personnalParentIndex++;
          }
          let businessOwners = item.facility_owners.filter((item) => item.type != 'individual');
          if (businessOwners.length > 0) {
            let existing_limit = Number(item?.existing_limit);
            if (
              (item?.product_facility === 'asset-finance-chp' ||
                item?.product_facility === 'asset-finance-chattel-mortgage' ||
                item?.product_facility === 'asset-finance-lease') &&
              item?.additional_info?.facility_status === 'existing'
            ) {
              existing_limit = Number(item?.additional_info?.current_balance);
            }
            businessdataTotal.existing_limit += existing_limit;
            businessdataTotal.proposed_limit += parseFloat(item.additional_info.proposed_limit);

            let detailDataTotal = { market_value: 0, lending_value: 0 };
            let securitydetailarray = new Array();
            item?.securities.map((security) => {
              detailDataTotal.market_value += security?.market_value;
              detailDataTotal.lending_value += security?.lending_value;
              securitydetailarray.push({
                key: security.id,
                t_last: '',
                memberId: security?.security_owners[0]?.id,
                facilitiesId: item.id,
                securityowner: displayOwnerNameFromType(security.security_owners),
                address: security.address,
                is_completed: security.is_completed,
                assetclass: security.asset_description_val,
                marketvalue: security.market_value,
                shading: formatNumber(security?.shading) + '%',
                type: 'business',
                parentIndex: businessParentIndex,
                landingvalue: security.lending_value
              });
            });

            businessDataarray.push({
              key: item.id,
              updown: '',
              edit: '',
              memberId: item?.facility_owners[0]?.id,
              business: displayOwnerNameFromType(businessOwners),
              purpose: item.purpose,
              lender: item.lender_val,
              existinglimit: existing_limit,
              status:
                item?.additional_info?.facility_status == 'new'
                  ? 'New'
                  : item?.additional_info?.facility_status == 'refinance'
                  ? 'Refinance'
                  : item?.additional_info?.facility_status == 'variation'
                  ? 'Variation'
                  : item?.additional_info?.facility_status == 'existing'
                  ? 'No Change'
                  : '',
              is_completed: item.is_completed,
              change: parseFloat(item.additional_info.proposed_limit) - existing_limit,
              proposedlimit: parseFloat(item.additional_info.proposed_limit),
              detailData: { securitydetailarray, detailDataTotal },
              type: 'business',
              businessdataTotal
            });
            businessParentIndex++;
          }
        });
      }
      setPersonalData([...personalDataarray]);
      setBusinessData([...businessDataarray]);
    } else if (activeTab == 3) {
      let securityDataarray = new Array();
      let securitytotal = {
        marketvaluetotal: 0,
        landingtotal: 0
      };
      if (facilitiesGroupData?.data && facilitiesGroupData?.data.length > 0) {
        securityDataarray = facilitiesGroupData?.data.map((item, index) => {
          let facilitytotal = {
            existing_limit: 0,
            proposed_limit: 0
          };
          let facilitydetailarray = new Array();

          securitytotal.marketvaluetotal += item?.market_value;
          securitytotal.landingtotal += item?.lending_value;

          item?.facilities.length > 0 &&
            item.facilities.map((facilities) => {
              facilitytotal.existing_limit += parseFloat(facilities?.existing_limit);
              facilitytotal.proposed_limit += parseFloat(
                facilities?.additional_info?.proposed_limit
              );
              facilitydetailarray.push({
                key: facilities.id,
                t_last: '',
                securitiesId: item.id,
                borrower: facilities?.facility_owners
                  ? displayOwnerNameFromType(facilities?.facility_owners)
                  : '',
                memberId: facilities?.facility_owners[0]?.id,
                business: facilities?.id,
                purpose: facilities?.purpose,
                lender: facilities?.lender_val,
                productFacility: facilities?.product_facility_val,
                existingLimit: facilities?.existing_limit,
                is_completed: facilities.is_completed,
                status:
                  facilities?.additional_info?.facility_status == 'new'
                    ? 'New'
                    : facilities?.additional_info?.facility_status == 'refinance'
                    ? 'Refinance'
                    : facilities?.additional_info?.facility_status == 'variation'
                    ? 'Variation'
                    : facilities?.additional_info?.facility_status == 'existing'
                    ? 'No Change'
                    : '',
                change:
                  parseFloat(facilities?.additional_info?.proposed_limit) -
                  parseFloat(facilities?.existing_limit),
                type: 'security',
                parentIndex: index,
                proposedlimit: parseFloat(facilities?.additional_info.proposed_limit)
              });
            });

          return {
            key: item?.id,
            updown: '',
            edit: '',
            securityowner: displayOwnerNameFromType(item?.security_owners),
            memberId: item?.security_owners[0]?.id,
            address: item?.address,
            assetclass: item?.asset_description_val,
            marketvalue: item?.market_value,
            shading: formatNumber(item?.shading) + '%',
            landingvalue: item?.lending_value,
            is_completed: item.is_completed,
            type: 'security',
            detailData: { facilitydetailarray, facilitytotal }
          };
        });

        setSecurityTotal(securitytotal);
      }

      setsecurityData([...securityDataarray]);
    }
  }, [facilitiesGroupData]);

  const getComment = (val) => {
    const contentBlock = htmlToDraft(val ? val : '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return EditorState.createEmpty();
  };

  // const setValue = (val) => {
  //   setReportcomment(getComment(val));
  // };
  // const setValue1 = (val) => {
  //   setInternalnotes(getComment(val));
  // };

  const facilitiesDetailComponent = (
    personalData,
    index,
    type,
    reportComment,
    setReportComment,
    internalNotes,
    setInternalNotes
  ) => {
    // setReportComment();
    // setInternalNotes();
    return (
      <>
        <div className="fuds_facilites d-flex justify-content-between align-items-center">
          <Title Type="Facility" />
          <Button Type="Facility" />
        </div>
        <FSTables
          theadData={theadDetailData.slice(1, theadDetailData.length - 1)}
          tbodyData={personalData.securitydetailarray}
          customClass="facilites_fuds"
          pagination={false}
          rowSelection={false}
          theadColor={'#52B3E5'}
          totalSpan={4}
          tfootData={
            <tfoot>
              <tr>
                <td colSpan="3" className="text-start" style={{ borderTopColor: '#52B3E5' }}>
                  Total
                </td>
                <td className="text-end" style={{ borderTopColor: '#52B3E5' }}>
                  {formatNumber(personalData?.detailDataTotal?.market_value)}
                </td>
                <td style={{ borderTopColor: '#52B3E5' }}></td>
                <td className="text-end" style={{ borderTopColor: '#52B3E5' }}>
                  {formatNumber(personalData?.detailDataTotal?.lending_value)}
                </td>

                <td style={{ borderTopColor: '#52B3E5' }}></td>
              </tr>
            </tfoot>
          }
        />
        <div className="comment-section">
          <div className="form-box">
            <div className="row">
              <div className="col-md-6">
                <div className="form-field">
                  <Label>Report Comments</Label>
                  {/* <textarea
                    value={values[type][index]?.report_comments}
                    className="form-control"
                    onChange={(e) => {
                      setFieldValue(`${type}.${index}.report_comments`, e.target.value);
                    }}
                    name={`${type}.${index}.report_comments`}
                  ></textarea> */}
                  <Field name={`${type}.${index}.report_comments`}>
                    {({ field }) => (
                      <Editor
                        editorState={reportComment}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onBlur={() => {
                          props.setFieldValue(
                            `${type}.${index}.report_comments`,
                            draftToHtml(convertToRaw(reportComment.getCurrentContent()))
                          );
                        }}
                        onEditorStateChange={(rawDraftContentState) => {
                          setReportComment(rawDraftContentState);
                          handleInputChange(rawDraftContentState);
                        }}
                        toolbar={{
                          options: ['inline', 'list'],
                          inline: {
                            options: ['bold', 'italic', 'underline']
                          },
                          list: {
                            options: ['unordered', 'ordered']
                          }
                        }}
                        name={`${type}.${index}.report_comments`}
                        id={field.name}
                        values={getComment(
                          values[type][index]?.report_comments
                            ? values[type][index]?.report_comments
                            : ''
                        )}
                        textareaName={`${type}.${index}.report_comments`}
                        editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                        stripPastedStyles={true}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-field">
                  <label>Internal Notes</label>
                  <Field name={`${type}.${index}.internal_notes`}>
                    {({ field }) => (
                      <Editor
                        editorState={internalNotes}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onBlur={() => {
                          props.setFieldValue(
                            `${type}.${index}.internal_notes`,
                            draftToHtml(convertToRaw(internalNotes.getCurrentContent()))
                          );
                        }}
                        onEditorStateChange={(rawDraftContentState) => {
                          setInternalNotes(rawDraftContentState);
                          handleInputChange(rawDraftContentState);
                        }}
                        toolbar={{
                          options: ['inline', 'list'],
                          inline: {
                            options: ['bold', 'italic', 'underline']
                          },
                          list: {
                            options: ['unordered', 'ordered']
                          }
                        }}
                        name={`${type}.${index}.internal_notes`}
                        id={field.name}
                        values={getComment(
                          values[type][index]?.internal_notes
                            ? values[type][index]?.internal_notes
                            : ''
                        )}
                        textareaName={`${type}.${index}.internal_notes`}
                        editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                        stripPastedStyles={true}
                      />
                    )}
                  </Field>
                  {/* <textarea
                    value={values[type][index]?.internal_notes}
                    className="form-control"
                    onChange={(e) => {
                      setFieldValue(`${type}.${index}.internal_notes`, e.target.value);
                    }}
                    name={`${type}.${index}.internal_notes`}
                  ></textarea> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const securitiesDetailComponent = (
    facilityData,
    index,
    type,
    reportComment,
    setReportComment,
    internalNotes,
    setInternalNotes
  ) => {
    return (
      <>
        <div className="fuds_facilites d-flex justify-content-between align-items-center">
          <Title Type="Securities" />
          <Button Type="Securities" />
        </div>
        <FSTables
          theadData={tMainheadData.slice(1, tMainheadData.length)}
          tbodyData={facilityData.facilitydetailarray}
          customClass="facilites_fuds"
          pagination={false}
          rowSelection={false}
          theadColor={'#52B3E5'}
          tfootData={
            <tfoot>
              <tr>
                <td colSpan={4} style={{ borderTopColor: '#52B3E5' }}>
                  Total
                </td>
                <td className="text-end" style={{ borderTopColor: '#52B3E5' }}>
                  {formatNumber(facilityData?.facilitytotal?.existing_limit)}
                </td>
                <td colSpan={3} className="text-end" style={{ borderTopColor: '#52B3E5' }}>
                  {formatNumber(facilityData?.facilitytotal?.proposed_limit)}
                </td>
                <td style={{ borderTopColor: '#52B3E5' }}></td>
              </tr>
            </tfoot>
          }
          // headSlice={7}
          // totalSpan={4}
        />
        <div className="comment-section">
          <div className="form-box">
            <div className="row">
              <div className="col-md-6">
                <div className="form-field">
                  <label>Internal Notes</label>
                  <Field name={`comment.${index}.internal_notes`}>
                    {(field) => (
                      <Editor
                        editorState={internalNotes}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onBlur={() => {
                          props.setFieldValue(
                            `comment.${index}.internal_notes`,
                            draftToHtml(convertToRaw(internalNotes.getCurrentContent()))
                          );
                        }}
                        onEditorStateChange={(rawDraftContentState) => {
                          setInternalNotes(rawDraftContentState);
                          handleInputChange(rawDraftContentState);
                        }}
                        toolbar={{
                          options: ['inline', 'list'],
                          inline: {
                            options: ['bold', 'italic', 'underline']
                          },
                          list: {
                            options: ['unordered', 'ordered']
                          }
                        }}
                        name={`comment.${index}.internal_notes`}
                        id={field.name}
                        values={getComment(
                          values.comment[index]?.internal_notes
                            ? values.comment[index]?.internal_notes
                            : ''
                        )}
                        textareaName={`comment.${index}.internal_notes`}
                        editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                        stripPastedStyles={true}
                      />
                    )}
                  </Field>
                  {/* <textarea
                    value={values.comment[index]?.internal_notes}
                    className="form-control"
                    onChange={(e) => {
                      setFieldValue(`comment.${index}.internal_notes`, e.target.value);
                      handleInputChange(e.target.value);
                    }}
                    name={`comment.${index}.internal_notes`}
                  ></textarea> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="tab-pane fade  show  active"
        id="residential-justified"
        role="tabpanel"
        aria-labelledby="residential-tab"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="funds">
              {activeTab == '2' ? (
                <Facilities
                  detailComponent={facilitiesDetailComponent}
                  theadData={theadData}
                  personalData={personalData}
                  businessData={businessData}
                  values={values}
                  tMainheadData={getMainHeadData()}
                  customClass="table business_area"
                  headSlice={1}
                  totalSpan={5}
                  theadColor={'#52B3E5'}
                />
              ) : (
                <Securities
                  detailComponent={securitiesDetailComponent}
                  theadData={getSecurityHead()}
                  securityData={securitiesData}
                  securitytotal={securitytotalAmount}
                  values={values}
                  customClass="table business_area"
                  totalSpan={5}
                  theadColor={'#52B3E5'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        value="unlink"
        show={showModel}
        title="Unlink Security?"
        text="Are you sure you want to Unlink this Security?"
        cancel={handleCancel}
        success={handleUnlink}
      />
    </>
  );
}
