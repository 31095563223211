import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const groupInitialState = {
  loading: false,
  groupservicingList: [],
  personalgroupservicingList: [],
  updatecomment: {}
};

export const GroupServiceabilitySlice = createSlice({
  name: 'groupServiceability',
  initialState: groupInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setgroupservicing: (state, action) => {
      state.groupservicingList = action.payload.data;
    },
    setpersonalservicing: (state, action) => {
      state.personalgroupservicingList = action.payload.data;
    },
    setupdatecomment: (state, action) => {
      state.updatecomment = action.payload.data;
    }
  }
});

export const getgroupservicing = (id, type, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/group-analysis/group-serviceability?type=' + type,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    if (type == 'business') {
      dispatch(setgroupservicing(response));
    } else {
      dispatch(setpersonalservicing(response));
    }
  }
};

export const updateComment = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/group-analysis/group-serviceability',
    data,
    setErrors,
    dispatch,
    true
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdatecomment(response.data));
  }
};

export const { setLoader, setgroupservicing, setpersonalservicing, setupdatecomment } =
  GroupServiceabilitySlice.actions;

export const groupServiceabillityState = (state) => {
  return state.groupServiceability;
};

export default GroupServiceabilitySlice.reducer;
