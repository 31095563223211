import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const GetCookies = (name) => {
  return cookies.get(name, {
    doNotParse: false
  });
};

export const SetCookie = (name, value, expires = 84600) => {
  cookies.set(name, value, {
    path: '/',
    maxAge: expires
  });
};

export const RemoveCookie = (name) => {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // return cookies.remove(name);
};
