import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const SoleTrader = (props) => {
  SoleTrader.propTypes = {
    data: PropTypes.any
  };

  const { jobId } = useParams();

  return (
    <div className="corporate-structure-blocks soletrader">
      <h4>
        Sole Trader
        <Link to={'/job/' + jobId + '/group-setup/members/edit/' + props.data?.id}>
          <button type="button" className="fa fa-edit"></button>
        </Link>
      </h4>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <tr>
          <td width="34%">Legal Name</td>
          <td>{props.data.name}</td>
        </tr>
        <tr>
          <td>ABN</td>
          <td>{props.data?.additional_info?.abn}</td>
        </tr>
        <tr>
          <td>Trading Name</td>
          <td>{props.data?.additional_info?.trading_name}</td>
        </tr>
        <tr>
          <td>Activity</td>
          <td>{props.data?.activity}</td>
        </tr>
      </table>
      {/* <ul>
        <li>
          <label>Legal Name</label>
          <p>{props.data.name}</p>
        </li>
        <li>
          <label>ABN</label>
          <p>{props.data?.additional_info?.abn}</p>
        </li>
        <li>
          <label>Trading Name</label>
          <p>{props.data?.additional_info?.trading_name}</p>
        </li>
        <li>
          <label>Activity</label>
          <p>{props.data?.activity}</p>
        </li>
      </ul> */}
    </div>
  );
};

export default SoleTrader;
