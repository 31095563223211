import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const reportInitialState = {
  loading: false,
  reportDataResponse: {},
  addReportResponse: {},
  publishedReportDataResponse: {},
  archivedReportDataResponse: {},
  deleteReportDataResponse: {},
  downloadReportDataResponse: {},
  restoreReportDataResponse: {}
};

export const ReportSlice = createSlice({
  name: 'report',
  initialState: reportInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setReportDataResponse: (state, action) => {
      state.reportDataResponse = action.payload.data;
      state.loader = false;
    },
    setAddReportResponse: (state, action) => {
      state.addReportResponse = action.payload;
      state.loader = false;
    },
    setPublishedReportDataResponse: (state, action) => {
      state.publishedReportDataResponse = action.payload.data;
      state.loader = false;
    },
    setArchivedReportDataResponse: (state, action) => {
      state.archivedReportDataResponse = action.payload.data;
      state.loader = false;
    },
    setDeleteReportDataResponse: (state, action) => {
      state.deleteReportDataResponse = action.payload;
      state.loader = false;
    },
    setDownloadReportResponse: (state, action) => {
      state.downloadReportDataResponse = action.payload;
      state.loader = false;
    },
    setRestoreReportDataResponse: (state, action) => {
      state.restoreReportDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getReportData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/module-status',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setReportDataResponse(response.data));
  }
};

export const addReportData = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' + id + '/generate-report',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAddReportResponse(response.data));
  }
};

export const getPublishedReportData =
  (id, type = 'publish', setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/report?report_status=' + type + '&=',
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setPublishedReportDataResponse(response.data));
    }
  };

export const getArchivedReportData =
  (id, type = 'archive', setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/report?report_status=' + type + '&=',
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setArchivedReportDataResponse(response.data));
    }
  };

export const deleteReport = (jobId, reportId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/report/' + reportId,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteReportDataResponse(response.data));
  }
};

export const getDownloadReportData = (id, reportId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/report-download/' + reportId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDownloadReportResponse(response.data));
    return response.data;
  }
};

export const restoreReport = (jobId, reportId, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' + jobId + '/report-restore/' + reportId,
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setRestoreReportDataResponse(response.data));
  }
};

export const {
  setLoader,
  setReportDataResponse,
  setAddReportResponse,
  setPublishedReportDataResponse,
  setArchivedReportDataResponse,
  setDeleteReportDataResponse,
  setDownloadReportResponse,
  setRestoreReportDataResponse
} = ReportSlice.actions;

export const reportState = (state) => {
  return state.report;
};

export default ReportSlice.reducer;
