import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { PieChart } from 'react-minimal-pie-chart';
import SelectionModel from '../../../components/SelectionModel';
import PieSegment from '../../../components/job/groupAssessment/PieSegment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  getOverallAssessment,
  OverallAssessmentState,
  updateOverallAssessment
} from '../../../slices/slices/OverallAssessmentSlice';
import DialogModal from '../../../components/modal/DialogModal';
import constants from '../../../constant';
import { Plock } from 'react-plock';
import domtoimage from 'dom-to-image';
import { useNavigatingAway } from '../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../common/components/DialogLeavingPage';

export default function OverallAssessment() {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const navigate = useNavigate();
  const responseData = useSelector(OverallAssessmentState);
  const [selectedId, setselectedId] = useState();
  const [showModal, setshowModal] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [dataMock, setDataMock] = useState([]);
  const [overallOption, setOverallOption] = useState([]);
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  let dataMockArray = [];

  const onClickhandle = (index) => {
    setselectedId(index);
    setshowModal(true);
  };

  const onhandleClose = () => {
    setshowModal(false);
  };

  const handleFormSubmit = (value) => {
    handleInputChange(value);
    let chartArray = dataMock;
    chartArray[selectedId]['color'] = value == 1 ? '#2bb673' : value == 2 ? '#f5c520' : '#ff4e4e';
    chartArray[selectedId]['selectedValue'] = value;
    setshowModal(false);
    setDataMock([...chartArray]);
  };

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    dispatch(getOverallAssessment(jobId));
  }, []);

  const breakpoints = [
    { size: 640, columns: 1 },
    { size: 768, columns: 2 },
    { size: 1024, columns: 3 },
    { size: 1280, columns: 3 }
  ];

  useEffect(() => {
    responseData?.tableData?.chart &&
      JSON.parse(responseData.tableData.chart).length > 0 &&
      JSON.parse(responseData.tableData.chart)?.map((item) => {
        setCanShowDialogLeavingPage(false);
        dataMockArray.push({
          title: item.name,
          category_id: item.category_id,
          value: Number(item.percentage),
          selectedValue: item.value,
          color: item.value == 1 ? '#2bb673' : item.value == 2 ? '#f5c520' : '#ff4e4e'
        });
      });
    if (responseData?.tableData?.assessment_options) {
      let temp =
        JSON.parse(responseData.tableData.assessment_options).length > 0 &&
        JSON.parse(responseData.tableData.assessment_options);
      temp.length > 0 &&
        temp[0].options.map((item) => {
          item.label = item.title;
          item.value = item.options;
          delete item.title;
          delete item.options;
        });
      temp[0].selectedValue = temp[0].options.find(
        (item) => Number(item.value) == Number(temp[0].value)
      );
      setOverallOption(temp);
    }
    setDataMock([...dataMockArray]);
    if (responseData?.tableData?.items) setItemData(JSON.parse(responseData.tableData.items));
  }, [responseData]);

  const loadpiechart = (dataMock) => {
    {
      if (dataMock.length > 0)
        return (
          <PieChart
            data={dataMock}
            paddingAngle={2}
            segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
            label={({ dataEntry }) => dataEntry.title}
            labelStyle={() => ({
              // fill: dataMock[index].color,
              fontSize: '2px',
              fill: '#000',
              fontFamily: 'sans-serif'
            })}
            radius={30}
            labelPosition={112}
            lineWidth={70}
            viewBoxSize={[100, 75]}
            center={[50, 35]}
            onClick={(_, index) => {
              onClickhandle(index);

              // setSelected(index === selected ? undefined : index);
            }}
          />
        );
    }
  };

  const handleMainFormSubmit = (val) => {
    setCanShowDialogLeavingPage(false);
    var node = document.getElementById('main_layer'); //.style.marginTop = '20px');
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;

        let tempArray = [];
        dataMock?.map((item) => {
          tempArray.push({
            name: item.title,
            value: item.selectedValue,
            category_id: item.category_id,
            percentage: item.value
          });
        });
        let options = JSON.parse(JSON.stringify(overallOption));
        options[0].options.map((item) => {
          item.title = item.label;
          item.options = item.value;
          delete item.label;
          delete item.value;
        });
        delete options[0].selectedValue;

        var data = {
          // chart: tempArray,
          // items: itemData,
          ...responseData.tableData,
          assessment_options: JSON.stringify(options),
          chart: JSON.stringify(tempArray),
          items: JSON.stringify(itemData),
          chart_image: img.src,
          is_completed: val
        };
        dispatch(updateOverallAssessment(jobId, data));
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  const onhandleCancel = () => {
    navigate('/job/' + jobId + '/dashboard');
  };

  // const getOverallOptions = () => {};

  return (
    <>
      <div className="dashboard toggle-sidebar">
        <Header />
        <main id="main" className="main" style={{ marginTop: '115px' }}>
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <a href="#">Group Assessment</a>
                </li>
                <li>
                  <a href="#">Bankability Assessment</a>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Overall Assessment</strong>
                </li>
              </ul>
            </div>

            <div className="breadcrumb-right d-flex">
              <DialogModal
                title="Overall Assessment"
                p1={constants.overall_assessment1}
                p2={constants.overall_assessment2}
              />
            </div>
          </div>
          <div className="pagetitle groupanalysispro">
            <div className="title_pro overall_title d-flex justify-content-between align-items-center">
              <h1>Overall Assessment</h1>
            </div>
          </div>
          <div className="facility-form form-data" style={{ overflow: 'hidden' }}>
            <div className="white-card">
              <div className="row">
                <>
                  <DialogLeavingPage
                    showDialog={showDialogLeavingPage}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                  />
                  <Plock nColumns={breakpoints}>
                    {itemData.map((Item, index) => {
                      return (
                        <div key={index}>
                          <PieSegment
                            subItem={Item.subcategory}
                            heading={Item.category_name}
                            mainIndex={index}
                            itemData={itemData}
                            setItemData={setItemData}
                            handleInputChange={handleInputChange}
                          />
                        </div>
                      );
                    })}
                  </Plock>
                  <div id="main_layer" style={{ width: '1600px', margin: '0 auto' }}>
                    <div className="col-12 d-flex justify-content-center pie-graph">
                      {loadpiechart(dataMock)}
                    </div>
                    {overallOption && (
                      <Select
                        // className="form-control"
                        styles={{
                          control: (base) => ({
                            ...base,
                            background:
                              overallOption && overallOption[0]?.selectedValue.value == 1
                                ? '#2bb673'
                                : overallOption && overallOption[0]?.selectedValue.value == 2
                                ? '#f5c520'
                                : '#ff4e4e'
                          })
                        }}
                        name={'overall'}
                        aria-label="Default select example"
                        color="black"
                        className="pie-select"
                        // styles={{ backgroundColor: 'red' }}
                        value={overallOption && overallOption[0]?.selectedValue}
                        onChange={(val) => {
                          handleInputChange(val);
                          let temp = overallOption;
                          temp[0].selectedValue = val;
                          temp[0].value = val.value;
                          setOverallOption([...temp]);
                        }}
                        options={overallOption && overallOption.length && overallOption[0].options}
                      ></Select>
                    )}
                  </div>
                </>
              </div>
            </div>

            <div className="action-box full">
              <button className="button reset" onClick={() => onhandleCancel()}>
                Cancel
              </button>
              <button className="button" onClick={() => handleMainFormSubmit(0)}>
                Save as draft
              </button>
              <button className="button green" onClick={() => handleMainFormSubmit(1)}>
                Completed
              </button>
            </div>
          </div>
        </main>
        <SelectionModel show={showModal} close={onhandleClose} onClickSubmit={handleFormSubmit} />
      </div>
    </>
  );
}
