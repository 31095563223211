import React from 'react';
import PropTypes from 'prop-types';
import FixedColumnTableRows from './FixedColumnTableRows';

const EBITDA = ({ data, years, categoryList, handleRemove, handleInputChange }) => {
  EBITDA.propTypes = {
    data: PropTypes.object,
    years: PropTypes.array,
    categoryList: PropTypes.array,
    percentageCalculation: PropTypes.func,
    handleRemove: PropTypes.func,
    handleInputChange: PropTypes.func
  };

  return (
    <>
      {categoryList &&
        categoryList.map((item, index) => {
          return (
            <FixedColumnTableRows
              key={index}
              category={item.key}
              data={data}
              handleRemoveRow={handleRemove}
              handleInputChange={handleInputChange}
              years={years}
              fixedName={item.name}
              noAddition={true}
            />
          );
        })}
    </>
  );
};

export default EBITDA;
