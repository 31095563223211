import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import {
  addFacility,
  facilityState,
  getMemberData,
  getMetaData,
  manageSecurityRelation,
  setAddFacilityDataResponse,
  setManageSecurityDataResponse
} from '../../../../slices/slices/FacilitySlice';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from './components/Form';
// import { unformatNumber } from '../../../common/Misc';
import { addFacilitySchema } from '../../../common/Validation';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType } from '../../../common/Misc';
import {
  getJobSettingsData,
  getMemberList,
  jobSetupState
} from '../../../../slices/slices/JobSetupSlice';
import Calculations from '../../../../utils/calculations';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const AddFacility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formValues = useRef();
  const [MemberValue, setMemberValue] = useState({});
  const { jobId, memberId, securityType, securityId } = useParams();
  const [sensitiseFacility, setSensitiseFacility] = useState(0);
  const [isDraftPopup, setIsDraftPopup] = useState(0);
  const { memberListResponse, jobSettingsDataResponse } = useSelector(jobSetupState);
  const {
    memberDataResponse,
    addFacilityDataResponse,
    metaDataResponse,
    manageSecurityDataResponse
  } = useSelector(facilityState);
  let existingValues = [];
  let overwrittenFields = [];

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  const [arrayDisable, setArrayDisable] = useState([]);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    let facility_value = JSON.parse(JSON.stringify(values));

    let member = [];
    values?.owners?.map((item) => {
      member.push({
        member_id: item.member_id?.value,
        holding: item?.holding
      });
    });
    values.owners = member;
    facility_value.facility_expiry_date_value_init = values.facility_expiry_date_value_init;
    facility_value.fixed_rate_expiry_date_value_init = values.fixed_rate_expiry_date_value_init;

    if (facility_value?.is_completed === 0) {
      facility_value?.owners?.map((val, index) => {
        return (facility_value.owners[index].member_id = val?.member_id?.value);
      });
    } else {
      let total = 0;
      facility_value?.owners?.map((val, index) => {
        total += parseInt(facility_value.owners[index].holding);
      });
      total === 100 &&
        facility_value?.owners?.map((val, index) => {
          return (facility_value.owners[index].member_id = val?.member_id?.value);
        });
    }

    let limit = facility_value?.proposed_limit;
    if (
      (facility_value?.product === 'asset-finance-chp' ||
        facility_value?.product === 'asset-finance-chattel-mortgage' ||
        facility_value?.product === 'asset-finance-lease') &&
      facility_value?.facility_status === 'existing'
    ) {
      limit = facility_value?.existing_limit;
    }

    let term_remaining_months = facility_value?.term_remaining_months;
    if (
      [
        'bank-guarantee',
        'floor-plan-finance',
        'import-finance',
        'line-of-credit',
        'overdraft',
        'trade-finance'
      ].includes(facility_value?.product) &&
      facility_value?.repayment_type === 'interest-only'
    ) {
      term_remaining_months = 12;
    }

    let interest_values = Calculations.calculatePAValues(
      limit,
      facility_value?.balloon,
      facility_value?.interest_rate_pa,
      facility_value?.facility_fee ? facility_value?.facility_fee : 0,
      facility_value?.repayment_amount,
      facility_value?.repayment_term_months,
      term_remaining_months,
      facility_value?.repayment_frequency,
      facility_value?.repayment_type
    );
    let sensitised_interest_rate = Calculations.calculateSensitisedPAValues(
      limit,
      facility_value?.balloon,
      facility_value?.sensitise_interest_rate,
      facility_value?.repayment_amount,
      facility_value?.sensitise_repayment_term_months,
      facility_value?.sensitise_repayment_term_months,
      facility_value?.repayment_frequency,
      facility_value?.sensitise_repayment_type
        ? facility_value?.sensitise_repayment_type
        : facility_value?.repayment_type
        ? facility_value?.repayment_type
        : null
    );
    let sensitise_repayment_pa = sensitised_interest_rate?.repayment_pa;
    let sensitise_interest_pa = sensitised_interest_rate?.interest_pa;
    facility_value.disabledFields = arrayDisable;
    // facility_value.proposed_limit = unformatNumber(facility_value.proposed_limit);
    const data = {
      ...facility_value,
      existing_limit: facility_value.facility_status === 'new' ? 0 : facility_value.existing_limit,
      current_balance:
        facility_value.facility_status === 'new' ? 0 : facility_value.current_balance,
      ...interest_values,
      sensitise_repayment_pa,
      sensitise_interest_pa
    };
    dispatch(addFacility(jobId, memberId, data, setErrors));
  };

  // Relate newly created facility to requested security
  const handleSecurityLinking = (facilityId) => {
    dispatch(manageSecurityRelation(jobId, memberId, facilityId, securityId));
  };

  // Execute on form load
  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getMetaData());
  }, []);

  // Execute when new facility created
  useEffect(() => {
    if (addFacilityDataResponse.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setAddFacilityDataResponse({}));
      if (securityId !== undefined) {
        handleSecurityLinking(addFacilityDataResponse?.data?.id);
      } else {
        if (isDraftPopup === 1) {
          navigate(
            '/job/' +
              jobId +
              '/data-entry/' +
              memberId +
              '/securities/return/facilityList/' +
              addFacilityDataResponse?.data?.id
          );
        } else {
          navigate('/job/' + jobId + '/data-entry/' + memberId + '/facilities');
        }
      }
    }
  }, [addFacilityDataResponse]);

  // Execute when newly created facility relate to requested security
  useEffect(() => {
    if (manageSecurityDataResponse?.message) {
      dispatch(setManageSecurityDataResponse({}));
      if (securityType === 'securityList') {
        // If facility added from security list
        navigate('/job/' + jobId + '/data-entry/' + memberId + '/securities');
      }
      if (securityId != undefined) {
        navigate('/job/' + jobId + '/data-entry/' + memberId + '/securities/' + securityId);
      }
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/facilities/add');
    }
  }, [manageSecurityDataResponse]);

  useEffect(() => {
    // dispatch(getMemberData(jobId, memberId));
    dispatch(getJobSettingsData(jobId));
    dispatch(getMemberList(jobId));
  }, []);

  useEffect(() => {
    displayNameFromType(memberDataResponse) &&
      setMemberValue({
        label: displayNameFromType(memberDataResponse),
        value: memberId
      });
  }, [memberDataResponse]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/facilities'}>
                  Facilities
                </Link>
              </li>
            </ul>
            <h1>Facilities - {displayNameFromType(memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right">
            <DialogModal
              title="Add Facility"
              p1={constants.facilities1}
              p2={constants.facilities2}
              p3={constants.facilities3}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <section className="new_addmember">
            <Formik
              initialValues={{
                owners: [
                  {
                    member_id: {
                      label: displayNameFromType(memberDataResponse),
                      value: memberId
                    },
                    holding: '100'
                  }
                ],
                loan: '100',
                purpose: '',
                facility_status: '',
                product: '',
                repayment_type: '',
                lender: '',
                existing_limit: '',
                current_balance: '',
                proposed_limit: '',
                balloon: 0,
                interest_rate_pa: '',
                facility_fee: '',
                interest_rate_type: '',
                // interest_rate_identifier: '',
                fixed_rate_expiry_date_value_init: '',
                repayment_term_months: '',
                // repayment_term_identifier: '',
                repayment_amount: '',
                repayment_frequency: 12,
                facility_expiry_date_value_init: '',
                term_remaining_months: '',
                other_fees: '',
                amount: '',
                frequency: '',
                sensitise_facility: 0,
                sensitise_repayment_type: '',
                sensitise_interest_rate: '',
                // sensitise_interest_rate_assumption: '',
                sensitise_repayment_term_months: '',
                // sensitise_repayment_term_assumption: '',
                sensitise_facility_expiry_date_value_init: '',
                report_comments: '',
                internal_notes: '',
                is_completed: 0,
                disabledFields: []
              }}
              onSubmit={handleFormSubmit}
              innerRef={formValues}
              validationSchema={addFacilitySchema}
            >
              {({
                values,
                errors,
                setErrors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                resetForm
              }) => (
                <>
                  <DialogLeavingPage
                    showDialog={showDialogLeavingPage}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                  />
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    handleInputChange={handleInputChange}
                    setSensitiseFacility={setSensitiseFacility}
                    sensitiseFacility={sensitiseFacility}
                    metaDataResponse={metaDataResponse}
                    memberListResponse={memberListResponse}
                    jobSettingsDataResponse={jobSettingsDataResponse}
                    // memberDataResponse={memberDataResponse}
                    memberId={memberId}
                    jobId={jobId}
                    securityId={securityId}
                    setIsDraftPopup={setIsDraftPopup}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    MemberValue={MemberValue}
                    arrayDisable={arrayDisable}
                    setArrayDisable={setArrayDisable}
                    resetForm={resetForm}
                  />
                </>
              )}
            </Formik>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddFacility;
