import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

export const DialogLeavingPage = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  isParams
}) => {
  const [location, setLocation] = useState();

  useEffect(() => {
    setLocation(isParams?.location);
  }, [isParams]);

  return (
    <Modal
      show={showDialog}
      onHide={cancelNavigation}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h2>Leaving Page?</h2>
      </Modal.Header>
      <Modal.Body>
        <p>Changes that you made may not be saved.</p>
      </Modal.Body>
      <div className="modal-action modal-footer">
        <Button className="btn btn-secondary" onClick={cancelNavigation}>
          Cancel
        </Button>
        <Button className="btn btn-primary" onClick={confirmNavigation} state={location}>
          Leave
        </Button>
      </div>
    </Modal>
  );
};
