import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const rentalInitialState = {
  loading: false,
  sensitiseddebtservicingList: [],
  updatecomment: {}
};

export const SensitisedDebtServicingSlice = createSlice({
  name: 'sensitisedDebtServicing',
  initialState: rentalInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setsensitiseddebtServicing: (state, action) => {
      state.sensitiseddebtservicingList = action.payload.data;
    },
    setupdatecomment: (state, action) => {
      state.updatecomment = action.payload.data;
    }
  }
});

export const getsensitisedDebtServicing = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/group-analysis/sensitised-debt-servicing',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setsensitiseddebtServicing(response.data));
  }
};

export const updateComment = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/group-analysis/sensitised-debt-servicing',
    data,
    setErrors,
    dispatch,
    true
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdatecomment(response.data));
  }
};

export const { setLoader, setsensitiseddebtServicing, setupdatecomment } =
  SensitisedDebtServicingSlice.actions;

export const sensitisedDebtServicingState = (state) => {
  return state.sensitisedDebtServicing;
};

export default SensitisedDebtServicingSlice.reducer;
