import React, { useEffect } from 'react';
import { Formik } from 'formik';
import DialogModal from './modal/DialogModal';
import Button from './Button';
import CurrencyInput from 'react-currency-input-field';
import { AddFundValidation } from '../pages/common/Validation';
import constants from '../constant';
import { transactionSelected } from '../utils/constant';
import CreatableSelect from 'react-select/creatable';

export default function AddFund(props) {
  const {
    options,
    transactionType,
    fundsId,
    getfundsData,
    // configList,
    transactionTypeName,
    handleFormSubmit,
    handleCancel
  } = props;

  const formikRef = React.createRef();
  // const [getfundsData, setInitial] = useState(getfundsData);

  // const handleonClickChange = (e, setFunction) => {
  //   let fieldName = e.target.name;
  //   let fieldValue = e.target.value;
  //   setFunction(fieldName, fieldValue);
  // };

  useEffect(() => {
    // formikRef?.current?.setValues({});
    if (formikRef?.current?.resetForm) {
      // console.log('formikRef : ', formikRef.current.resetForm());

      formikRef.current.resetForm();
    }
  }, [transactionType]);

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  return transactionType && ((fundsId && getfundsData) || (!fundsId && !getfundsData)) ? (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        // funds_use: '',
        funds_use: getfundsData?.data
          ? {
              label: getfundsData?.data[transactionSelected[transactionType]]
                ? capitalize(
                    getfundsData?.data[transactionSelected[transactionType]].replaceAll('-', ' ')
                  )
                : capitalize(getfundsData?.data?.custom_item.replaceAll('-', ' ')),
              value: getfundsData?.data[transactionSelected[transactionType]]
                ? getfundsData?.data[transactionSelected[transactionType]]
                : getfundsData?.data?.custom_item
            }
          : '',
        amount: getfundsData?.data?.amount ? getfundsData?.data?.amount : '',
        comment: getfundsData?.data?.comment ? getfundsData?.data?.comment : ''
      }}
      validationSchema={AddFundValidation}
      // validateOnBlur={true}
      validateOnChange={false}
      onSubmit={handleFormSubmit}
    >
      {({ values, errors, handleBlur, handleSubmit, handleChange, setFieldValue }) => {
        return (
          <form className="facility-form form-data mt-30 fund-form" noValidate>
            <div className="white-card">
              <h3 className="titlePro text-center">{transactionTypeName}</h3>
              <div className="breadcrumb-right">
                {transactionType === 'application-funds' ? (
                  <DialogModal title="Application of Funds" p1={constants.application_of_funds} />
                ) : transactionType === 'associated_funds' ? (
                  <DialogModal
                    title="Associated Transaction"
                    p1={constants.associated_transaction}
                  />
                ) : (
                  <DialogModal title="Source of Funds" p1={constants.source_of_fund} />
                )}
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-md-12">
                  <div className="group_areaof row">
                    <div className="col-6">
                      <label>
                        {transactionType === 'application-funds'
                          ? 'Fund Used'
                          : transactionType === 'associated_funds'
                          ? 'Item'
                          : 'How Sourced'}
                      </label>
                      {/* <CreatableSelect
                        type="select"
                        classNamePrefix="select"
                        className="custom-select"
                        value={{ label: item[1].title, value: item[1].title }}
                        options={
                          transactionType == 'source-funds'
                            ? configList['proposed_transaction_how_sourced']
                            : transactionType == 'application-funds'
                            ? configList['proposed_transaction_funds_use']
                            : configList['proposed_transaction_associated_transaction_item']
                        }
                        aria-label="Default select example"
                        placeholder="Select or type a New Entry"
                        onChange={(event) => {
                          handleTitleUpdate(index, index, event, 'dropdown');
                          handleInputChange(event.target.value);
                        }}
                      ></CreatableSelect>  */}
                      <CreatableSelect
                        name="funds_use"
                        placeholder={
                          transactionType === 'application-funds'
                            ? 'Select or Type Application of Funds'
                            : transactionType === 'associated_funds'
                            ? 'Select or Type Associated Transaction'
                            : 'Select or Type Source of Funds'
                        }
                        value={values.funds_use}
                        options={options}
                        onBlur={handleBlur}
                        classNamePrefix="fund-used"
                        onChange={(val) => {
                          setFieldValue('funds_use', val);
                        }}
                        // handleChange={(e) => handleonClickChange(e, setFieldValue)}
                      />
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors.funds_use}
                      </span>
                    </div>
                    <div className="col-6">
                      <label>Amount $</label>
                      <CurrencyInput
                        allowNegativeValue={true}
                        className="form-control"
                        name="amount"
                        value={values?.amount}
                        allowDecimals={false}
                        onValueChange={(value, name) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={handleBlur}
                      />
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors.amount}
                      </span>
                    </div>
                    <div className="col-12">
                      <label>Comment (if applicable)</label>
                      <textarea
                        name="comment"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={values?.comment}
                        className="form-control"
                        style={{ height: '100px' }}
                      ></textarea>

                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors.comment}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-box full">
              <div className="btn_area">
                <Button
                  type="button"
                  name="cancel"
                  className="btn btn-outline-secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  name="save"
                  onClick={handleSubmit}
                  className="btn btn-success"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  ) : (
    <></>
  );
}
