import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import CommentsNotes from '../../../../components/fields/CommentsNotes';

import {
  deleteProperty,
  getUnencumberedPropertyListData,
  unencumberedPropertyState,
  setDeletePropertyDataResponse,
  updateUnencumberedProperty,
  getMemberData
} from '../../../../slices/slices/UnencumberedPropertySlice';
import Button from '../../../../components/Button';
import FormActions from '../../../../components/FormActions';
import moment from 'moment';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType } from '../../../common/Misc';
import Info from '../../../../components/Info';
import { updateMarkAll } from '../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const UnencumberedProperty = () => {
  const unencumberedPropertyListDataResponse = useSelector(unencumberedPropertyState);
  const deletePropertyDataResponse = useSelector(unencumberedPropertyState);
  const memberDataResponse = useSelector(unencumberedPropertyState);

  const [items, setItems] = useState(
    unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[0]?.data
  );

  const { jobId, memberId } = useParams();
  const dispatch = useDispatch();
  let market_value_total = 0;

  const internal_notes =
    unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[1]?.internal_notes;

  const report_comments =
    unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[1]?.report_comments;

  const [showDelete, setShowDelete] = useState(false);
  const [propertyId, setPropertyId] = useState(0);
  const [internalNotes, setInternalNotes] = useState(internal_notes);
  const [reportComments, setReportComments] = useState(report_comments);

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    dispatch(updateUnencumberedProperty(jobId, memberId, values, setErrors)).then(() => {
      if (values.is_completed === 1) {
        let formData = { module: 'unencumbered-property' };
        dispatch(updateMarkAll(jobId, memberId, formData, {}));
      }
    });
  };

  const handleDelete = () => {
    setShowDelete(false);
  };

  const delProperty = () => {
    setShowDelete(false);
    dispatch(deleteProperty(jobId, memberId, propertyId));
    dispatch(getUnencumberedPropertyListData(jobId, memberId));
    dispatch(getMemberData(jobId, memberId));
  };

  useEffect(() => {
    setInternalNotes(
      unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[1]?.internal_notes
    );
  }, [
    unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[1]?.internal_notes
  ]);

  useEffect(() => {
    setReportComments(
      unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[1]?.report_comments
    );
  }, [
    unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[1]?.report_comments
  ]);

  useEffect(() => {
    setItems(unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[0]?.data);
  }, [unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[0]?.data]);

  useEffect(() => {
    dispatch(getUnencumberedPropertyListData(jobId, memberId));
    dispatch(getMemberData(jobId, memberId));
  }, [showDelete]);

  useEffect(() => {
    if (deletePropertyDataResponse?.message) {
      setShowDelete(false);
      dispatch(getUnencumberedPropertyListData(jobId, memberId));
      dispatch(setDeletePropertyDataResponse({}));
    }
  }, [deletePropertyDataResponse?.message]);

  const initialValue = {
    report_comments: reportComments,
    internal_notes: internalNotes,
    is_completed: 0
  };

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <strong>Unencumbered Property</strong>
              </li>
            </ul>
            <h1>
              Unencumbered Property - {displayNameFromType(memberDataResponse?.memberDataResponse)}
            </h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/add-property'}>
              <button type="button" className="green topbtn">
                + Property
              </button>
            </Link>
            <DialogModal
              title="Unencumbered-Property"
              p1={constants.unencumbered1}
              p2={constants.unencumbered2}
              p3={constants.unencumbered3}
            />
          </div>
        </div>
        <div className="mt-30" style={{ marginRight: '-15px' }}>
          <Info />
        </div>
        <div className="facility-form form-data">
          <div className="gray-card">
            <div className="un_property-table">
              <table className="table">
                <thead>
                  <tr>
                    <th className="align-left" style={{ width: '15%' }}>
                      Asset Owner
                    </th>
                    <th className="align-left" style={{ width: '18%' }}>
                      Address
                    </th>
                    <th style={{ width: '12%' }}>Asset Class</th>
                    <th style={{ width: '10%' }}>Asset Type</th>
                    <th style={{ width: '10%' }}>Asset Usage</th>
                    <th style={{ width: '15%' }}>Description</th>
                    <th style={{ width: '10%' }}>Market Value</th>
                    <th style={{ width: '12%' }}>Confirmation</th>
                    <th style={{ width: '8%' }}>Date</th>
                    <th style={{ width: '120px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse &&
                  unencumberedPropertyListDataResponse?.unencumberedPropertyListDataResponse[0]
                    ?.data.length > 0 ? (
                    items?.map((item) => {
                      market_value_total = item?.additional_info.market_value - -market_value_total;
                      return (
                        <tr key={item?.id}>
                          <td className="align-left">
                            {displayNameFromType(memberDataResponse?.memberDataResponse)}
                          </td>
                          <td className="align-left">{item?.address}</td>
                          <td>{item?.asset_class_val}</td>
                          <td>{item?.asset_type_val}</td>
                          <td>{item?.asset_usage_val}</td>
                          <td>{item?.asset_description_val}</td>
                          <td className="align-right text-end">
                            {item?.additional_info.market_value.toLocaleString(undefined, {
                              maximumFractionDigits: 3
                            })}
                          </td>
                          <td>{item?.confirmation_val}</td>
                          <td>
                            {item?.additional_info?.date_confirmation === null
                              ? ''
                              : moment(item?.additional_info?.date_confirmation, 'MM-YY').format(
                                  'MMM YYYY'
                                )}
                          </td>

                          <td className="actionarea" width="10%">
                            <i
                              className={
                                item?.is_completed == 1
                                  ? 'fa fa-check-circle'
                                  : 'fa fa-exclamation-triangle'
                              }
                              aria-hidden="true"
                              style={{
                                color: `${item?.is_completed == 1 ? '#42ba96' : '#eed202'}`
                              }}
                            ></i>
                            <Link
                              className="edit"
                              to={
                                '/job/' +
                                jobId +
                                '/data-entry/' +
                                memberId +
                                '/edit-property/' +
                                item?.id
                              }
                            >
                              <i className="icon-edit"></i>
                            </Link>
                            <a
                              onClick={() => {
                                setPropertyId(item?.id);
                                setShowDelete(true);
                              }}
                              className="delete"
                            >
                              <i className="icon-delete"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="11">No Records Found</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-end">
                      {market_value_total.toLocaleString(market_value_total, {
                        maximumFractionDigits: 3
                      })}
                    </td>
                    <td></td>
                    <td></td>

                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
          {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} className="facility-form form-data">
                <CommentsNotes
                  values={values ? values : ''}
                  errors={errors}
                  handleChange={handleChange}
                  handleInputChange={handleInputChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
                <div className="action-box full">
                  <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Modal
        show={showDelete}
        onHide={handleDelete}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Delete Property?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this property permanently?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button className="btn btn-secondary" onClick={handleDelete}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              delProperty();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UnencumberedProperty;
