import UserHeader from '../dashboard/UserHeader';

const Privacy = () => {
  return (
    <div className="setting toggle-sidebar">
      <UserHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="/dashboard">Dashboard</a>
              </li>
              <li>
                <strong>Privacy</strong>
              </li>
            </ul>
            <h1>Privacy</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
