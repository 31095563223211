import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import UserHeader from '../../dashboard/UserHeader';
import ProcessTable from './ProcessTable';

const Brokers = () => {
  const [status, setStatus] = useState('active');

  const handleTabChange = (status) => {
    setStatus(status);
    // dispatch(getJobByStatus({ status, showing: limit, search: search }));
  };

  return (
    <>
      <div className="setting toggle-sidebar">
        <UserHeader />
        <div id="main" className="main">
          <h1></h1>
          <div className="card">
            <div className="card-body">
              <div className="profile-tabs tools">
                <Tabs
                  className="nav nav-tabs d-flex"
                  id="myTab"
                  role="tablist"
                  activeKey={status}
                  onSelect={(status) => handleTabChange(status)}
                >
                  <Tab className="nav-item flex-fill" eventKey="active" title="Process">
                    <img
                      style={{ width: '100%' }}
                      src="https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/IP+Tools+%26+Templates.png"
                    />
                  </Tab>
                  <Tab
                    className="nav-item flex-fill"
                    eventKey="archived"
                    title="Influence Templates"
                  >
                    <ProcessTable />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="setting toggle-sidebar">
        <UserHeader />
        <div id="main" className="main">
          <div className="group-breadcrumb">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li>
                  <strong>Brokers</strong>
                </li>
              </ul>
              <h1>Brokers</h1>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Brokers;
