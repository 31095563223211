import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const recommendationInitialState = {
  loading: false,
  recommendationDataResponse: {},
  editUnencumberedPropertyResponse: {}
};

export const RecommendationSlice = createSlice({
  name: 'recommendation',
  initialState: recommendationInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setUpdatedRecommendationResponse: (state, action) => {
      state.updateRecommendationResponse = action.payload;
      state.loader = false;
    },
    setRecommendationDataResponse: (state, action) => {
      state.RecommendationDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const updateRecommendation = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/business-assessment/recommendation',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedRecommendationResponse(response.data));
  }
};

export const getRecommendationData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/business-assessment/recommendation',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setRecommendationDataResponse(response.data));
  }
};

export const { setLoader, setUpdatedRecommendationResponse, setRecommendationDataResponse } =
  RecommendationSlice.actions;

export const recommendationState = (state) => {
  return state.recommendation;
};

export default RecommendationSlice.reducer;
