import React, { useEffect, useRef, useState } from 'react';
import DataEntryHeader from '../../../../../components/DataEntryHeader';
import { addCommercialRentalSchema } from '../../../../common/Validation';
import { getMemberData, facilityState } from '../../../../../slices/slices/FacilitySlice';
import {
  rentalState,
  addCommercialRentalData,
  setAddCommercialRentalResponse
} from '../../../../../slices/slices/RentalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Form from './component/Form';
import DialogModal from '../../../../../components/modal/DialogModal';
import constants from '../../../../../constant';
import { Formik } from 'formik';
import { displayNameFromType } from '../../../../common/Misc';
import { getMemberList, jobSetupState } from '../../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../../common/components/DialogLeavingPage';

const AddCommercialRental = () => {
  const dispatch = useDispatch();
  const { jobId, memberId } = useParams();
  const [MemberValue, setMemberValue] = useState({});
  const memberDataResponse = useSelector(facilityState);
  const addCommercialRentalResponse = useSelector(rentalState);
  const navigate = useNavigate();
  let existingValues = [];
  let overwrittenFields = [];
  const formValues = useRef();
  const { memberListResponse } = useSelector(jobSetupState);
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFormSubmit = (values, { setErrors }) => {
    let members = [];
    for (let item of JSON.parse(JSON.stringify(values?.owners))) {
      members.push({
        holding: item?.holding,
        member_id: item?.member_id?.value
      });
    }
    setCanShowDialogLeavingPage(false);
    {
      if (values?.is_completed === 0) {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += parseInt(values.owners[index].holding);
        });
        total <= 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      } else {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += parseInt(values.owners[index].holding);
        });
        total === 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      }
    }
    {
      values?.tenant && values?.tenant.length > 0
        ? values?.tenant.map(
            (item, index) => (
              (values.tenant[index].lease_start =
                values.tenant[index].lease_start_date === '' ||
                values.tenant[index].lease_start_date === undefined
                  ? ''
                  : moment(values.tenant[index].lease_start_date, 'YYYY-MM-DD').format(
                      'DD-MM-YYYY'
                    )),
              (values.tenant[index].lease_end = values.tenant[index].lease_end_date
                ? moment(values.tenant[index].lease_end_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                : null),
              (values.tenant[index].rental_bond =
                values.tenant[index].rental_bond === undefined ||
                values.tenant[index].rental_bond === '' ||
                values.tenant[index].rental_bond === null
                  ? ''
                  : parseInt(`${values.tenant[index].rental_bond}`.replace(/,/g, ''))),
              (values.tenant[index].current_rental_income_pa = parseInt(
                `${values.tenant[index].current_rental_income_pa}`.replace(/,/g, '')
              )),
              (values.tenant[index].option1 =
                values.tenant[index].option1 === ('' || null || undefined)
                  ? ''
                  : values.tenant[index].option1),
              (values.tenant[index].option2 =
                values.tenant[index].option2 === ('' || null || undefined)
                  ? ''
                  : values.tenant[index].option2),
              (values.tenant[index].option =
                values.tenant[index].option1 + 'X' + values.tenant[index].option2)
            )
          )
        : null;
    }
    values.owners = members;
    dispatch(addCommercialRentalData(jobId, memberId, values, setErrors));
  };

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    if (addCommercialRentalResponse?.addCommercialRentalResponse?.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setAddCommercialRentalResponse({}));
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/rentals');
    }
  }, [addCommercialRentalResponse?.addCommercialRentalResponse?.message]);

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getMemberList(jobId));
  }, []);

  useEffect(() => {
    displayNameFromType(memberDataResponse?.memberDataResponse) &&
      setMemberValue({
        label: displayNameFromType(memberDataResponse?.memberDataResponse),
        value: memberId
      });
  }, [memberDataResponse]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/rentals'}>Rental</Link>
                {/* <strong>Rental</strong> */}
              </li>
            </ul>
            <h1>Rentals - {displayNameFromType(memberDataResponse?.memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <DialogModal
              title="Commercial-Rental"
              p1={constants.rentalCommercial1}
              p2={constants.rentalCommercial2}
            />
          </div>
        </div>
        <Formik
          initialValues={{
            type: 'commercial',
            report_comments:
              'The Weighted Average Lease Expiry (WALE) is conservative as it does not take into account any future rental increases provided for in the lease agreement.',
            internal_notes: '',
            owners: [
              {
                member_id: {
                  label: displayNameFromType(memberDataResponse?.memberDataResponse),
                  value: memberId
                },
                holding: 100
              }
            ],
            address: '',
            is_completed: 0,
            tenant: [
              {
                tenant_name: '',
                tenant_activity: '',
                lease_start_date: '',
                lease_end_date: '' /*moment('13-1-2022', 'DD-MM-YYYY').format('YYYY-MM-DD')*/,
                option: '',
                rental_bond: '',
                sensitised_rental_pa: '50',
                current_rental_income_pa: '',
                shading: '',
                annual_rent_review: ''
              }
            ]
          }}
          // enableReinitialize // Removed due to showing Property Address error while adding new Commercial Rental.
          validationSchema={addCommercialRentalSchema}
          onSubmit={handleFormSubmit}
          innerRef={formValues}
        >
          {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
                memberListResponse={memberListResponse}
                memberDataResponse={memberDataResponse}
                memberId={memberId}
                jobId={jobId}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
                setFieldValue={setFieldValue}
                MemberValue={MemberValue}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddCommercialRental;
