import React, { useState, useEffect } from 'react';
import UserHeader from '../dashboard/UserHeader';
import AccountManagement from '../settings/AccountManagement';
import { useSelector, useDispatch } from 'react-redux';
// import FSTables from '../../components/job/groupAnalysis/proposedFacilities/FStable/table';
import {
  commonState,
  adminGetConfig,
  getpracticeManager,
  // addPracticeManager,
  deleteConfig,
  gettaxrate,
  getOverallAssessment,
  addCategory,
  setCategoryData,
  editCategory,
  setEditCategoryData,
  deleteCateg,
  setdeleteCateg,
  getindividualtaxrate
} from '../../slices/slices/CommonSlice';
import ConfigModal from '../../components/ConfigModal';
import CustomModal from '../../components/CustomModal';
import Admin from '../../components/cms/Admin';
import Tax from '../../components/cms/Tax';
import TaxRateModal from '../../components/TaxRateModal';
import { updateTaxRateConfig } from '../../slices/slices/CommonSlice';
import OverallAssessment from '../../components/cms/OverallAssessment';
import PracticeUser from '../../components/cms/PracticeUser';
import AddPracticeModal from '../../components/AddPracticeModal';
import IndividualTaxRate from '../settings/IndividualTaxRate';

export default function AdminSetting() {
  const dispatch = useDispatch();
  const {
    adminConfigList,
    adminCreditCardConfigList,
    getpracticeManagerList,
    setindividualtaxrateList,
    settaxrateList,
    setOverallAssesmentList,
    setCategoryList,
    setEditCategoryList,
    setdeleteCategResponse
  } = useSelector(commonState);

  const [tbodyData, settbodyData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [taxData, settaxData] = useState([]);
  const [itemTax, setItemTax] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [openModel, setopenModel] = useState(false);
  const [showDeleteModel, setshowdeleteModel] = useState(false);
  const [itemData, setItemData] = useState();
  const [configId, setConfigId] = useState();
  const [TaxId, setTaxId] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [showAddOverall, setShowAddOverall] = useState(false);
  const [showEditOverall, setShowEditOverall] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setshowdeleteModel(false);
  };
  const handleonClose = () => {
    setShowModel(false);
  };
  const handleOnClick = (configId) => {
    setConfigId(configId);
    setshowdeleteModel(true);
  };

  const handleOnAddClick = () => {
    setItemData({ type: 'add' });
    setShowModel(true);
  };
  const handleOneditClick = (itemData) => {
    setConfigId(itemData.id);
    let temp = {
      maintitle: itemData.title,
      comment: itemData.comment,
      // report_value: [],
      title: [
        // {
        //   title: '',
        //   report_value: ''
        // }
      ],

      type: 'edit'
    };
    JSON.parse(itemData?.detailData.value)?.map((item) => {
      temp.title.push({
        title: item.value,
        report_value: item.report_value
      });
      // temp.report_value.push(item.report_value);
    });

    setItemData(temp);
    setShowModel(true);
  };

  const deleteConfigData = () => {
    dispatch(deleteConfig(configId)).then(() => {
      dispatch(adminGetConfig([], 1, false));
    });
    setshowdeleteModel(false);
  };

  useEffect(() => {
    let configArray = [];
    adminConfigList &&
      adminConfigList.length > 0 &&
      adminConfigList.map((item, index) => {
        configArray.push({
          key: index,
          updown: '',
          id: item.id,
          title: item.title,
          comment: item.comment,
          edit: '',
          detailData: { value: item.value, comment: item.comment }
        });
      });
    settbodyData(configArray);
  }, [adminConfigList]);

  useEffect(() => {
    let configArray = [];
    adminCreditCardConfigList &&
      adminCreditCardConfigList.length > 0 &&
      adminCreditCardConfigList.map((item, index) => {
        configArray.push({
          key: index,
          updown: '',
          id: item.id,
          title: item.title,
          comment: item.comment,
          edit: '',
          value: item.value
        });
      });
    setCardData(configArray);
  }, [adminCreditCardConfigList]);

  useEffect(() => {
    let taxArray = [];
    settaxrateList &&
      settaxrateList.length > 0 &&
      settaxrateList.map((item, index) => {
        taxArray.push({
          key: index,
          updown: '',
          id: item.id,
          title: item.name,
          comment: item.comment,
          edit: '',
          detailData: { value: item.options, comment: item.comment }
        });
      });
    settaxData(taxArray);
  }, [settaxrateList]);

  useEffect(() => {
    dispatch(adminGetConfig([], 1));
    dispatch(adminGetConfig([], 2));
    getTaxRateData();
    getOverallAssessmentData();
    getIndividualTaxRateData();
    dispatch(getpracticeManager(25, 1));
  }, []);

  const getTaxRateData = () => {
    dispatch(gettaxrate(false));
  };

  const getIndividualTaxRateData = () => {
    dispatch(getindividualtaxrate(false));
  };

  const getOverallAssessmentData = () => {
    dispatch(getOverallAssessment(false));
  };

  const updateTaxRate = (taxId, temp, setErrors) => {
    dispatch(updateTaxRateConfig(taxId, temp, setErrors)).then(() => {
      getTaxRateData();
    });
  };

  const handletabOnclick = (tab) => {
    setActiveTab(tab);
  };

  const handleoneditTaxRate = (data) => {
    let taxdata = {
      id: data.id,
      maintitle: data.title,
      comment: data.comment,
      options: [],
      type: 'edit'
    };
    JSON.parse(data?.detailData.value)?.map((item) => {
      taxdata.options.push(item);
    });

    setItemTax(taxdata);

    setopenModel(true);
    setTaxId(data.id);
  };
  const handleonTaxClose = () => {
    setopenModel(false);
  };

  const handleOverallAssessment = (value, id, setErrors) => {
    value.options = JSON.stringify(value?.option);
    dispatch(addCategory(id, value, setErrors));
  };

  useEffect(() => {
    if (setCategoryList?.message) {
      dispatch(setCategoryData({}));
      getOverallAssessmentData();
      setShowAddOverall(false);
    }
  }, [setCategoryList?.message]);

  const handleEditOverallAssessment = (value, categoryItemId, id, setErrors) => {
    value.options = JSON.stringify(value?.option);
    dispatch(editCategory(id, categoryItemId, value, setErrors));
  };

  useEffect(() => {
    if (setEditCategoryList?.message) {
      dispatch(setEditCategoryData({}));
      getOverallAssessmentData();
      setShowEditOverall(false);
    }
  }, [setEditCategoryList?.message]);

  const handleDeleteCategory = (id, categId) => {
    dispatch(deleteCateg(id, categId));
  };

  useEffect(() => {
    if (setdeleteCategResponse?.message) {
      dispatch(setdeleteCateg({}));
      getOverallAssessmentData();
      setShowDeleteModal(false);
    }
  }, [setdeleteCategResponse?.message]);

  const onhandleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="setting toggle-sidebar">
        <UserHeader />
        <div id="main" className="main">
          <div className="group-breadcrumb">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li>
                  <strong>Admin Settings</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="pagetitle groupanalysispro">
            <div className="title_pro d-flex justify-content-between align-items-center">
              <h1>Admin Settings</h1>
              <div className="addmemberarea">
                {activeTab == 1 ? (
                  <button
                    type="button"
                    className="button green"
                    data-bs-toggle="modal"
                    data-bs-target="#verticalycentered"
                    onClick={() => handleOnAddClick()}
                  >
                    <i className="icon-plus"></i>Add New Labels
                  </button>
                ) : (
                  ''
                )}
                {activeTab == 3 ? (
                  <div className="breadcrumb-right">
                    <button type="button" className="button green" onClick={onhandleClick}>
                      <i className="icon-plus"></i>Add New Practice
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="facility-form form-data">
            <div className="white-card">
              <div className="rentalview">
                <ul className="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 1 && 'active'}`}
                      id="residential-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#residential-justified"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="false"
                      onClick={() => handletabOnclick(1)}
                    >
                      Data Input Labels
                    </button>
                  </li>
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 2 && 'active'}`}
                      id="residential-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#residential-justified"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="false"
                      onClick={() => handletabOnclick(2)}
                    >
                      Overall Assessment
                    </button>
                  </li>
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 3 && 'active'}`}
                      id="residential-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#residential-justified"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="false"
                      onClick={() => handletabOnclick(3)}
                    >
                      Practice User
                    </button>
                  </li>
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 4 && 'active'}`}
                      id="residential-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#residential-justified"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="false"
                      onClick={() => handletabOnclick(4)}
                    >
                      Credit Card Rate
                    </button>
                  </li>
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 5 && ' active'}`}
                      id="securities-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#securities-justified"
                      type="button"
                      role="tab"
                      aria-controls="securities"
                      aria-selected="false"
                      onClick={() => handletabOnclick(5)}
                    >
                      Entity Tax Rates
                    </button>
                  </li>
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 6 && 'active'}`}
                      id="residential-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#residential-justified"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="false"
                      onClick={() => handletabOnclick(6)}
                    >
                      Individual Tax Rates
                    </button>
                  </li>
                </ul>
                <div className="tab-content pt-6" id="myTabjustifiedContent">
                  {activeTab == 1 ? (
                    <Admin
                      tbodyData={tbodyData}
                      handleOneditClick={handleOneditClick}
                      handleOnClick={handleOnClick}
                    />
                  ) : activeTab == 2 ? (
                    <OverallAssessment
                      tbodyData={setOverallAssesmentList}
                      handleOverallAssessment={handleOverallAssessment}
                      showAddOverall={showAddOverall}
                      handleEditOverallAssessment={handleEditOverallAssessment}
                      showEditOverall={showEditOverall}
                      handleDeleteCategory={handleDeleteCategory}
                      showDeleteCategory={showDeleteModal}
                    />
                  ) : activeTab == 3 ? (
                    <PracticeUser tbodyData={getpracticeManagerList} />
                  ) : activeTab == 4 ? (
                    <AccountManagement cardData={cardData} />
                  ) : activeTab == 5 ? (
                    <Tax tbodyData={taxData} handleOneditClick={handleoneditTaxRate} />
                  ) : (
                    <IndividualTaxRate tbodyData={setindividualtaxrateList} />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <section className="settingsgroup">
            <div className="addmember">
              <div className="row expenses">
                <FSTables
                  detailComponent={detailData}
                  theadData={theadData}
                  tbodyData={tbodyData}
                  customClass="table fac-sec-table"
                />
              </div>
            </div>
          </section> */}
        </div>
      </div>
      <ConfigModal show={showModel} close={handleonClose} itemData={itemData} configId={configId} />
      <TaxRateModal
        show={openModel}
        close={handleonTaxClose}
        updateTaxRate={updateTaxRate}
        itemData={itemTax}
        setItemTax={setItemTax}
        TaxId={TaxId}
      />
      <CustomModal
        value="Delete"
        show={showDeleteModel}
        title="Delete Config?"
        text="Are you sure you want to Delete this Config?"
        cancel={handleCancel}
        success={() => deleteConfigData()}
      />
      <AddPracticeModal
        open={open}
        setOpen={setOpen}
        type={true}
        lastPage={getpracticeManagerList?.last_page}
      />
    </>
  );
}
