import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import {
  addSecurity,
  securityState,
  getMemberData,
  getMetaData,
  setAddSecurityDataResponse,
  manageFacilityRelation,
  setManageFacilityDataResponse
} from '../../../../slices/slices/SecuritySlice';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from './components/Form';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType } from '../../../common/Misc';
import { addSecuritySchema } from '../../../common/Validation';
import { getMemberList, jobSetupState } from '../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const AddSecurity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [MemberValue, setMemberValue] = useState({});
  const [isDraftPopup, setIsDraftPopup] = useState(0);
  const { memberListResponse } = useSelector(jobSetupState);

  const formValues = useRef();
  const { jobId, memberId, facilityType, facilityId } = useParams();
  const {
    memberDataResponse,
    addSecurityDataResponse,
    metaDataResponse,
    manageFacilityDataResponse
  } = useSelector(securityState);
  let existingValues = [];
  let overwrittenFields = [];
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    let member = [];
    values?.owners?.map((item) => {
      member.push({
        member_id: item.member_id?.value,
        holding: item?.holding
      });
    });
    let security_value = JSON.parse(JSON.stringify(values));
    security_value.date_value = values.date_value;
    security_value.date_value_confirmed = values.date_value_confirmed;
    security_value.date_value_confirmed_init = values.date_value_confirmed_init;
    if (security_value?.is_completed === 0) {
      security_value?.owners?.map((val, index) => {
        return (security_value.owners[index].member_id = val?.member_id?.value);
      });
    } else {
      let total = 0;
      security_value?.owners?.map((val, index) => {
        total += security_value.owners[index].holding;
      });
      total === 100 &&
        security_value?.owners?.map((val, index) => {
          return (security_value.owners[index].member_id = val?.member_id?.value);
        });
    }
    security_value.market_value = parseInt(`${security_value?.market_value}`.replace(/,/g, ''));
    security_value.lending_value = parseInt(`${security_value?.lending_value}`.replace(/,/g, ''));
    security_value.shading = parseInt(`${security_value?.shading}`);
    security_value.owners = member;
    dispatch(addSecurity(jobId, memberId, security_value, setErrors));
  };

  // Relate newly created security to requested facility
  const handleFacilityLinking = (securityId) => {
    dispatch(manageFacilityRelation(jobId, memberId, securityId, facilityId));
  };

  // Execute on form load
  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getMetaData());
  }, []);

  // Execute when new security created
  useEffect(() => {
    if (addSecurityDataResponse.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setAddSecurityDataResponse({}));
      if (facilityId !== undefined) {
        // If request from facility module
        handleFacilityLinking(addSecurityDataResponse?.data?.id);
      } else {
        if (isDraftPopup === 1) {
          navigate(
            '/job/' +
              jobId +
              '/data-entry/' +
              memberId +
              '/facilities/return/security/' +
              addSecurityDataResponse?.data?.id
          );
        } else {
          navigate('/job/' + jobId + '/data-entry/' + memberId + '/securities');
        }
      }
    }
  }, [addSecurityDataResponse]);
  // Execute when newly created security relate to requested facility
  useEffect(() => {
    if (manageFacilityDataResponse?.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setManageFacilityDataResponse({}));
      if (facilityType == 'facilityList') {
        // If security added from facility list
        navigate('/job/' + jobId + '/data-entry/' + memberId + '/facilities');
      }
      if (facilityId != undefined) {
        // If security added from facility add or edit form
        navigate('/job/' + jobId + '/data-entry/' + memberId + '/facilities/' + facilityId);
      }
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/securities/add');
    }
  }, [manageFacilityDataResponse]);

  useEffect(() => {
    dispatch(getMemberList(jobId));
  }, []);

  useEffect(() => {
    displayNameFromType(memberDataResponse) &&
      setMemberValue({
        label: displayNameFromType(memberDataResponse),
        value: memberId
      });
  }, [memberDataResponse]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/securities'}>
                  Securities
                </Link>
              </li>
            </ul>
            <h1>Securities - {displayNameFromType(memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right">
            <DialogModal
              title="Add Security"
              p1={constants.security1}
              p2={constants.security2}
              p3={constants.security3}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <section className="new_addmember">
            <Formik
              initialValues={{
                owners: [
                  {
                    member_id: {
                      label: displayNameFromType(memberDataResponse),
                      value: memberId
                    },
                    holding: 100
                  }
                ],
                address: '',
                asset_class: '',
                market_value: '',
                shading: '',
                lending_value: '',
                asset_type: '',
                asset_usage: '',
                asset_description: '',
                value_confirmed: '',
                date_value_confirmed_init: '',
                date_value: '',
                report_comments: '',
                internal_notes: '',
                is_completed: 0
              }}
              onSubmit={handleFormSubmit}
              innerRef={formValues}
              validationSchema={addSecuritySchema}
            >
              {({
                values,
                errors,
                setErrors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              }) => (
                <>
                  <DialogLeavingPage
                    showDialog={showDialogLeavingPage}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                  />
                  <Form
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange}
                    setErrors={setErrors}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    metaDataResponse={metaDataResponse}
                    memberDataResponse={memberDataResponse}
                    memberListResponse={memberListResponse}
                    memberId={memberId}
                    jobId={jobId}
                    facilityId={facilityId}
                    setIsDraftPopup={setIsDraftPopup}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    MemberValue={MemberValue}
                  />
                </>
              )}
            </Formik>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddSecurity;
