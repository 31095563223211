import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

const Individual = (props) => {
  Individual.propTypes = {
    data: PropTypes.any
  };

  const { jobId } = useParams();

  return (
    <div className="corporate-structure-blocks individual">
      <h4>
        Individual
        <Link to={'/job/' + jobId + '/group-setup/members/edit/' + props.data?.id}>
          <button type="button" className="fa fa-edit"></button>
        </Link>
      </h4>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <tr>
          <td width="34%">Title</td>
          <td>{props.data.name}</td>
        </tr>
        <tr>
          <td>First Name</td>
          <td>{props.data?.additional_info?.first_name}</td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>{props.data?.additional_info?.last_name}</td>
        </tr>
        <tr>
          <td>Other Name</td>
          <td>{props.data?.additional_info?.other_names}</td>
        </tr>
        <tr>
          <td>Preferred Name</td>
          <td>{props.data?.additional_info?.preferred_first_name}</td>
        </tr>
        <tr>
          <td>Date of Birth</td>
          <td>
            {props.data?.additional_info?.date_of_birth !== null &&
              moment(props.data?.additional_info?.date_of_birth).format('DD-MM-YYYY')}
          </td>
        </tr>
        <tr>
          <td>Occupation</td>
          <td>{props.data?.activity}</td>
        </tr>
      </table>
      {/* <ul>
        <li>
          <label>Title</label>
          <p>{props.data.name}</p>
        </li>
        <li>
          <label>First Name</label>
          <p>{props.data?.additional_info?.first_name}</p>
        </li>
        <li>
          <label>Last Name</label>
          <p>{props.data?.additional_info?.last_name}</p>
        </li>
        <li>
          <label>Other Name</label>
          <p>{props.data?.additional_info?.other_names}</p>
        </li>
        <li>
          <label>Preferred Name</label>
          <p>{props.data?.additional_info?.preferred_first_name}</p>
        </li>
        <li>
          <label>Date of Birth</label>
          <p>
            {props.data?.additional_info?.date_of_birth !== null &&
              moment(props.data?.additional_info?.date_of_birth).format('DD-MM-YYYY')}
          </p>
        </li>
        <li>
          <label>Occupation </label>
          <p>{props.data?.activity}</p>
        </li>
      </ul> */}
    </div>
  );
};

export default Individual;
