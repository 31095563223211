import React, { useEffect, useState } from 'react';
import { uniqkey } from '../../../../../../utils/helper';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const TableRow = (props) => {
  const { theadData, item, index, detailComponent, values, Type } = props;
  const [open, setOpen] = useState(false);
  const [reportComment, setReportComment] = useState(EditorState.createEmpty());
  const [internalNotes, setInternalNotes] = useState(EditorState.createEmpty());

  const getComment = (val) => {
    const contentBlock = htmlToDraft(val ? val : '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return EditorState.createEmpty();
  };

  useEffect(() => {
    setReportComment(getComment(values?.[item.type]?.[index]?.report_comments));
    setInternalNotes(
      getComment(
        Type == 'securities'
          ? values?.comment[index]?.internal_notes
          : values?.[item.type]?.[index]?.internal_notes
      )
    );
  }, [values]);

  const headArray = () => {
    let array = theadData ? theadData.filter((el) => Object.keys(item).includes(el.key)) : [];
    return array;
  };

  return (
    <>
      <tr key={item.key} className={'accordion-toggle'}>
        {headArray().map((el) => {
          return (
            <td
              key={uniqkey()}
              className={item.className ? item.className : el.className}
              colSpan={item.colSpan ? item.colSpan : 1}
            >
              {item.render && (!item?.renderKey || item?.renderKey.includes(el.key))
                ? item.render(item[el.key], { ...item, index }, el)
                : el.render
                ? el.render(item[el.key], {
                    ...item,
                    setOpen: setOpen,
                    open: open,
                    index
                  })
                : item[el.key]}
            </td>
          );
        })}
      </tr>
      <tr></tr>
      {open && detailComponent ? (
        <>
          <tr>
            <td colSpan={theadData.length} className="security_funds">
              {detailComponent(
                item.detailData,
                index,
                item.type,
                reportComment,
                setReportComment,
                internalNotes,
                setInternalNotes
              )}
            </td>
          </tr>
        </>
      ) : null}
    </>
  );
};

export default TableRow;
