import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from './Button';

const FormActions = (props) => {
  FormActions.propTypes = {
    cancel: PropTypes.string,
    values: PropTypes.any
  };
  return (
    <div className="btn_area text-end">
      <div className="expenses-btnarea">
        <Link to={props.cancel}>
          <Button className="btn btn-outline-secondary">Cancel</Button>
        </Link>

        <Button
          type="submit"
          name="is_completed"
          className="btn btn-primary"
          onClick={() => {
            props.values.is_completed = 0;
          }}
        >
          Save As Draft
        </Button>
        <Button
          type="submit"
          name="is_completed"
          className="btn btn-success"
          onClick={() => {
            props.values.is_completed = 1;
          }}
        >
          Completed
        </Button>
      </div>
    </div>
  );
};

export default FormActions;
