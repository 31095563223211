import React, { useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  deleteassociatedFunds,
  deleteFunds
} from '../../../../../slices/slices/ProposedTransactionSlice';
import { useDispatch } from 'react-redux';
import CustomModal from '../../../../CustomModal';
import { uniqkey } from '../../../../../utils/helper';

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: center;
      width: 200px;
      display: none;
      &:nth-child(2) {
        display: block;
      }
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">
    <div className="draggable-item">
      <span className="column-drag-handle" style={{ float: 'left', padding: '0 10px' }}>
        &#x2630;
      </span>
    </div>
  </Handle>
));

const TableRow = ({ item, className, removeTableRow, theadData }) => {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  // const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);

  const handleCancel = () => {
    setShowDelete(false);
  };

  // const editRow = (transaction_id, key, type) => {
  //   navigate('/job/' + jobId + '/proposed-transaction/' + transaction_id + '/funds/' + key, {
  //     state: {
  //       name: type,
  //       type: type,
  //       transactionId: transaction_id,
  //       fundsId: key
  //     }
  //   });
  // };

  const handleDelete = () => {
    removeTableRow(item.key);

    setShowDelete(false);
    if (item.type === 'associated_funds') {
      dispatch(deleteassociatedFunds(jobId, item.transaction_id, item.key));
    } else {
      dispatch(deleteFunds(jobId, item.transaction_id, item.key));
    }
  };

  // const handleSelectFund = () => {
  //   setShowDelete(true);
  // };

  return (
    <TrWrapper className={className}>
      <td>
        <RowHandler />
      </td>
      {theadData.map((el) => {
        return (
          <td key={uniqkey()}>
            {item.render && (!item?.renderKey || item?.renderKey.includes(el.key))
              ? item.render(item[el.key], item)
              : el.render
              ? el.render(item[el.key], item, {
                  ...item
                })
              : item[el.key]}
          </td>
        );
      })}
      {/* <td>
        <div className="firstElement">{item.item}</div>
      </td>
      <td>{item.comment}</td>
      <td>{item?.amount}</td>
      <td>
        <div className="d-flex">
          <div className="edit" onClick={() => editRow(item.transaction_id, item.key, item.type)}>
            <Link to="#">
              <i className="icon-edit" />
            </Link>
          </div>
          <div style={{ marginLeft: '30px' }}>
            <a className="delete" onClick={() => handleSelectFund()}>
              <i className="icon-delete" />
            </a>
          </div>
        </div>
      </td> */}

      <CustomModal
        value="delete"
        show={showDelete}
        title="Delete Fund?"
        text="Are you sure you want to delete this Fund permanently?"
        cancel={handleCancel}
        success={handleDelete}
        reference={item.key}
      />
      {/* <>
      <tr key={item.key}>
        <td>
          <img src="images/drag_drop.png" alt="" />
        </td>
        {theadData.map((el) => {
          return (
            <td key={uniqkey()}>
              {item.render && (!item?.renderKey || item?.renderKey.includes(el.key))
                ? item.render(item[el.key], item)
                : el.render
                ? el.render(item[el.key], item, {
                    ...item
                  })
                : item[el.key]}
            </td>
          );
        })}
      </tr>
    </> */}
    </TrWrapper>
  );
};

export default TableRow;
