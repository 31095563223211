import React from 'react';
import Input from '../../../components/Input';

const Annualiase = () => {
  return (
    <div className="col-6" /*style={{display: 'none-block'}}*/>
      <label>Annualise the Year to Date Financials?</label>
      <div className="checkfrom d-flex justify-content-between align-items-center">
        <div className="form-check">
          {/* <Input
                              className="form-check-input"
                              type="radio"
                              name="gridRadios"
                              id="gridRadios2"
                              value="option2"
                            /> */}
          <Input
            className="form-check-input"
            type="radio"
            name="annualise_the_year_to_date_financials"
            id="annualise_the_year_to_date_financials"
            value="option2"
          />
          <label className="form-check-label" id="annualise_the_year_to_date_financials">
            Yes
          </label>
        </div>
        <div className="form-check">
          <Input
            className="form-check-input"
            type="radio"
            name="annualise_the_year_to_date_financials"
            id="annualise_the_year_to_date_financials"
            value="option2"
          />
          <label className="form-check-label" id="annualise_the_year_to_date_financials">
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default Annualiase;
