import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const facilityInitialState = {
  loading: false,
  memberDataResponse: {},
  facilityListDataResponse: {},
  addFacilityDataResponse: {},
  facilityDataResponse: {},
  updateFacilityDataResponse: {},
  deleteFacilityDataResponse: {},
  metaDataResponse: {},
  securitiesDataResponse: {},
  manageSecurityDataResponse: {}
};

export const FacilitySlice = createSlice({
  name: 'facility',
  initialState: facilityInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setMemberDataResponse: (state, action) => {
      state.memberDataResponse = action.payload.data;
      state.loader = false;
    },
    setFacilityListDataResponse: (state, action) => {
      state.facilityListDataResponse = action.payload;
      state.loader = false;
    },
    setAddFacilityDataResponse: (state, action) => {
      state.addFacilityDataResponse = action.payload;
      state.loader = false;
    },
    setFacilityDataResponse: (state, action) => {
      state.facilityDataResponse = action.payload.data;
      state.loader = false;
    },
    setUpdateFacilityDataResponse: (state, action) => {
      state.updateFacilityDataResponse = action.payload;
      state.loader = false;
    },
    setDeleteFacilityDataResponse: (state, action) => {
      state.deleteFacilityDataResponse = action.payload;
      state.loader = false;
    },
    setMetaDataResponse: (state, action) => {
      state.metaDataResponse = action.payload;
      state.loader = false;
    },
    setSecuritiesDataResponse: (state, action) => {
      state.securitiesDataResponse = action.payload;
      state.loader = false;
    },
    setManageSecurityDataResponse: (state, action) => {
      state.manageSecurityDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getMemberData = (id, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setMemberDataResponse(response.data));
  }
};

export const getFacilityListData = (id, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId + '/facilities?limit=500',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setFacilityListDataResponse(response.data));
  }
};

export const addFacility = (jobId, memberId, data, setErrors) => async (dispatch) => {
  data.fixed_rate_expiry_date =
    data.fixed_rate_expiry_date_value_init != '' && data.fixed_rate_expiry_date_value_init != null
      ? moment(data.fixed_rate_expiry_date_value_init, 'YYYY-MM-DD', true).format('DD-MM-YYYY')
      : null;

  data.fixed_rate_expiry_date_init =
    data.fixed_rate_expiry_date_value_init != '' && data.fixed_rate_expiry_date_value_init != null
      ? moment(data.fixed_rate_expiry_date_value_init, 'YYYY-MM-DD', true).format('YYYY-MM-DD')
      : null;

  data.facility_expiry_date_value_init !== '' && data.facility_expiry_date_value_init !== null
    ? (data.facility_expiry_date = moment(data.facility_expiry_date_value_init).format(
        'DD-MM-YYYY'
      ))
    : (data.facility_expiry_date = '');

  data.facility_expiry_date_init =
    data.facility_expiry_date_value_init != '' && data.facility_expiry_date_value_init != null
      ? moment(data.facility_expiry_date_value_init, 'YYYY-MM-DD', true).format('YYYY-MM-DD')
      : null;
  // ----------

  data.sensitise_facility_expiry_date =
    data.sensitise_facility_expiry_date_value_init != '' &&
    data.sensitise_facility_expiry_date_value_init != null &&
    data.sensitise_facility_expiry_date_value_init != undefined
      ? moment(data.sensitise_facility_expiry_date_value_init).format('DD-MM-YYYY', true)
      : null;
  data.sensitise_facility_expiry_date_init =
    data.sensitise_facility_expiry_date_value_init != '' &&
    data.sensitise_facility_expiry_date_value_init != null &&
    data.sensitise_facility_expiry_date_value_init != undefined
      ? moment(data.sensitise_facility_expiry_date_value_init).format('YYYY-MM-DD')
      : null;

  //TODO: The repayment_term_months and repayment_frequency are sent as default in case of Credit Card and Sensitised P & I @ 3.80% pm
  if (
    (data.product === 'credit-card' || data.product === 'store-card') &&
    data.repayment_type === 'sensitised-p-i-at-380-pm'
  ) {
    data.repayment_term_months = 1;
    data.repayment_frequency = 12;
    data.repayment_pa = data.repayment_amount * 12;
  }

  const response = await clientService.post(
    'jobs/' + jobId + '/members/' + memberId + '/facilities',
    data,
    setErrors,
    dispatch
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAddFacilityDataResponse(response.data));
  }
};

export const getFacilityData = (jobId, memberId, facilityId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + jobId + '/members/' + memberId + '/facilities/' + facilityId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setFacilityDataResponse(response.data));
  }
};

export const updateFacility =
  (jobId, memberId, facilityId, data, setErrors, updateValue) => async (dispatch) => {
    data.fixed_rate_expiry_date =
      data.fixed_rate_expiry_date_value_init != '' && data.fixed_rate_expiry_date_value_init != null
        ? moment(data.fixed_rate_expiry_date_value_init).format('DD-MM-YYYY')
        : null;

    //Start Facility Expiry Date
    data.facility_expiry_date_value_init !== '' && data.facility_expiry_date_value_init !== null
      ? (data.facility_expiry_date = moment(data.facility_expiry_date_value_init).format(
          'DD-MM-YYYY'
        ))
      : (data.facility_expiry_date = '');

    data.facility_expiry_date_init = moment(data.facility_expiry_date_value_init).format(
      'DD-MM-YYYY'
    );
    //End Facility Expiry Date

    data.fixed_rate_expiry_date_init =
      data.fixed_rate_expiry_date_value_init != '' && data.fixed_rate_expiry_date_value_init != null
        ? moment(data.fixed_rate_expiry_date_value_init).format('YYYY-MM-DD')
        : null;

    //Start Sensitise Facility Expiry Date
    data.sensitise_facility_expiry_date_init =
      data.sensitise_facility_expiry_date_value_init != null &&
      data.sensitise_facility_expiry_date_value_init != undefined &&
      data.sensitise_facility_expiry_date_value_init != ''
        ? moment(data.sensitise_facility_expiry_date_value_init).format('YYYY-MM-DD')
        : null;

    data.sensitise_facility_expiry_date =
      data.sensitise_facility_expiry_date_value_init != null &&
      data.sensitise_facility_expiry_date_value_init != undefined &&
      data.sensitise_facility_expiry_date_value_init != ''
        ? moment(data.sensitise_facility_expiry_date_value_init).format('DD-MM-YYYY')
        : null;
    //End Sensitise Facility Expiry Date

    //TODO: The repayment_term_months and repayment_frequency are sent as default in case of Credit Card and Sensitised P & I @ 3.80% pm
    if (
      (data.product === 'credit-card' || data.product === 'store-card') &&
      data.repayment_type === 'sensitised-p-i-at-380-pm'
    ) {
      data.repayment_term_months = 1;
      data.repayment_frequency = 12;
      data.repayment_pa = data.repayment_amount * 12;
    }
    const response = await clientService.put(
      'jobs/' + jobId + '/members/' + memberId + '/facilities/' + facilityId,
      data,
      setErrors,
      dispatch,
      true,
      (ee) => {
        setTimeout(() => {
          setErrors(ee);
        });
      }
    );

    if (typeof response === 'string' || response == null) {
      updateValue(data);
    } else {
      dispatch(setUpdateFacilityDataResponse(response.data));
    }

    return response;
  };

export const deleteFacility = (jobId, memberId, facilityId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/members/' + memberId + '/facilities/' + facilityId,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteFacilityDataResponse(response.data));
  }
};

export const getMetaData = (setErrors) => async (dispatch) => {
  const metaData = new URLSearchParams({
    'keys[0]': 'facilities-products',
    'keys[1]': 'facilities-repayment-types',
    'keys[2]': 'facilities-lenders',
    'keys[3]': 'facilities-interest-rate-identifier',
    'keys[4]': 'facilities-repayment-term-identifier',
    'keys[5]': 'payment-frequency',
    'keys[6]': 'facilities-other-fees',
    'keys[7]': 'facilities-sensitise-repayment-types',
    'keys[8]': 'facilities-sensitise-interest-rate-assumptions',
    'keys[9]': 'facilities-sensitise-repayment-term-identifier',
    'keys[10]': 'job-states',
    'keys[11]': 'credit-card-rate'
  }).toString();
  const response = await clientService.get('config?' + metaData, setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setMetaDataResponse(response.data));
  }
};

export const getSecuritiesData = (jobId, memberId, facilityId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' +
      jobId +
      '/members/' +
      memberId +
      '/facilities/' +
      facilityId +
      '/securities?limit=500',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSecuritiesDataResponse(response.data));
  }
};

export const manageSecurityRelation =
  (jobId, memberId, facilityId, securityId) => async (dispatch) => {
    const response = await clientService.post(
      'jobs/' +
        jobId +
        '/members/' +
        memberId +
        '/facilities/' +
        facilityId +
        '/securities/' +
        securityId,
      {},
      {},
      dispatch
    );

    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setManageSecurityDataResponse(response.data));
    }
  };

export const {
  setLoader,
  setMemberDataResponse,
  setFacilityListDataResponse,
  setAddFacilityDataResponse,
  setFacilityDataResponse,
  setUpdateFacilityDataResponse,
  setDeleteFacilityDataResponse,
  setMetaDataResponse,
  setSecuritiesDataResponse,
  setManageSecurityDataResponse
} = FacilitySlice.actions;

export const facilityState = (state) => {
  return state.facility;
};

export default FacilitySlice.reducer;
