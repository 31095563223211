import { Formik } from 'formik';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  authState,
  setVerifyNumberResponse,
  verifyNumberOTP
} from '../../../slices/slices/AuthSlice';
import {
  setSettingVerifyNumberResponse,
  settingState,
  remove2fa,
  settingVerifyNumberOTP,
  setRemove2faResponse
} from '../../../slices/slices/SettingSlice';
import { AuthenticationSchema } from '../../common/Validation';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

const AuthenticationCode = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { settingCodeVerifyResponse, remove2faResponse } = useSelector(settingState);
  const { numberVerifyResponse } = useSelector(authState);
  const search = window.location.search;
  const tokenString = new URLSearchParams(search);
  const handleFormSubmit = (values, { setErrors }) => {
    if (tokenString.get('remove') === 'true') {
      dispatch(verifyNumberOTP(values, setErrors));
    } else {
      dispatch(settingVerifyNumberOTP(values, setErrors));
    }
  };

  useEffect(() => {
    if (numberVerifyResponse.message) {
      if (tokenString.get('remove') === 'true') {
        dispatch(remove2fa());
      }
    }
    dispatch(setVerifyNumberResponse({}));
  }, [numberVerifyResponse.message]);

  useEffect(() => {
    if (remove2faResponse.message) {
      if (remove2faResponse) {
        navigate('/setting');
      }
      dispatch(setRemove2faResponse({}));
    }
  }, [remove2faResponse.message]);
  useEffect(() => {
    if (settingCodeVerifyResponse.message) {
      navigate('/add-backup-email');
      dispatch(setSettingVerifyNumberResponse({}));
    }
  }, [settingCodeVerifyResponse.message]);

  return (
    <div className="setting toggle-sidebar">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <p onClick={() => navigate(-1)} className="backlink">
            Back
          </p>
          <div className="container two-factor-screen">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="text-center">
                  <h4>Enter 6-digit code found in your authenticator app</h4>
                </div>
                <Formik
                  initialValues={{
                    token: ''
                  }}
                  validationSchema={AuthenticationSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="row needs-validation" noValidate>
                      <div className="col-12 d-flex flex-row justify-content-center textintro digital">
                        <img
                          className="authenticator"
                          src="images/mobile-icon.png"
                          alt="Authentcator Code"
                        />
                      </div>
                      <div className="col-12">
                        <div className="input-group has-validation">
                          <Input
                            type="text"
                            name="token"
                            placeholder="Authentication Code"
                            className="form-control"
                            id="yourUsername"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.token}
                            required
                          />
                          <div className="invalid-feedback">Please enter your username.</div>
                        </div>
                        <span className="error" style={{ color: 'red', fontSize: '16px' }}>
                          {errors.token}
                        </span>
                      </div>
                      <div className="col-12 topgap btnarea justify-content-center">
                        <Button className="btn btn-primary w-100" type="submit">
                          Confirm
                        </Button>
                      </div>
                      {tokenString.get('remove') === 'true' ? null : (
                        <div className="col-12 justify-content-center middletext">
                          <p className="topspace">
                            <Link to="/two-step">I dont have a code</Link>
                          </p>
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default AuthenticationCode;
