import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Table, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  updateState,
  unlockFile,
  changeStatus,
  copyFile,
  setReloadJob
} from '../../slices/slices/JobSlice';
//
import Pagination from '../common/Pagination';
import { useNavigate } from 'react-router';
import moment from 'moment';
import InputMask from 'react-input-mask';
import CustomModal from '../../components/CustomModal';
import PropTypes from 'prop-types';
import { CopyJobSchema } from '../common/Validation';
import Label from '../../components/Label';
import Input from '../../components/Input';
import { Formik } from 'formik';
import { GetCookies, SetCookie } from '../../services/cookies';
import GroupInput from '../../components/GroupInput';

const JobTable = (props) => {
  JobTable.propTypes = {
    status: PropTypes.string,
    search: PropTypes.any,
    handleUpdateLimit: PropTypes.any,
    handleChangePage: PropTypes.any,
    limit: PropTypes.any,
    currentPage: PropTypes.any,
    jobData: PropTypes.any,
    setItems: PropTypes.any,
    items: PropTypes.any,
    stateJobDataResponse: PropTypes.any,
    total: PropTypes.any,
    selectedList: PropTypes.any,
    setSelectedList: PropTypes.any
  };

  const { selectedList, setSelectedList } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Local variables not stored in the state
   */
  const LOCKED = 'locked';
  const ACTIVE = 'active';
  const DELETED = 'deleted';
  const ARCHIVED = 'archived';
  const COPY = 'copy';

  const [showStaff, setShowStaff] = useState(false);
  const [staffDetail, setStaffDetail] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [showLock, setShowLock] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [sort, setSort] = useState({
    type: '',
    dir: ''
  });
  const [isJobNoSort, setIsJobNoSort] = useState(true);
  const [isStateSort, setIsStateSort] = useState(true);
  const [isGroupNameSort, setIsGroupNameSort] = useState(true);
  const [isNameSort, setIsNameSort] = useState(true);
  const [isStartDateSort, setIsStartDateSort] = useState(true);
  const [isDueDateSort, setIsDueDateSort] = useState(true);
  const [mainCheck, setMainCheck] = useState(false);
  const json_str = GetCookies('_jobData');
  const [jobItem, setJobItem] = useState(json_str ? JSON.parse(JSON.stringify(json_str)) : []);
  const [showUnlock, setShowUnlock] = useState(false);
  const [unlockJobData, setUnlockJobData] = useState({});

  /**
   *
   * All the button handlers
   *
   * @param activity
   */
  const handleButtonClick = (activity) => {
    if (selectedList.length > 0) {
      if (activity === DELETED) {
        setShowDelete(true);
      } else if (activity === ARCHIVED) {
        setShowArchive(true);
      } else if (activity === LOCKED) {
        setShowLock(true);
      } else if (activity === ACTIVE) {
        setShowRestore(true);
      } else if (activity === COPY) {
        setShowCopy(true);
      }
    }
  };

  const handleCancel = (activity) => {
    if (activity === DELETED) {
      setShowDelete(false);
    } else if (activity === ARCHIVED) {
      setShowArchive(false);
    } else if (activity === LOCKED) {
      setShowLock(false);
    } else if (activity === ACTIVE) {
      setShowRestore(false);
    } else if (activity === COPY) {
      setShowCopy(false);
    }
  };

  const handleUnlockCancle = () => {
    setShowUnlock(false);
  };

  const handleUnlock = (item) => {
    if (item.is_locked === 1) {
      setShowUnlock(true);
      setUnlockJobData(item);
    }
  };

  const unlockJob = (item) => {
    setShowUnlock(false);
    if (item.is_locked === 1) {
      item.is_locked = 0;
      dispatch(unlockFile(item.id)).then(() => {
        props.setItems(props.items);
      });
    }
  };

  const handleStateChange = (index, item) => {
    dispatch(updateState(item?.id, index.target.value));
  };

  const handleClickRow = (item) => {
    const data = [];
    const setData = { id: item.id, job_no: item.job_no, jobId: item.jobId, jobName: item.name };
    let jobList = jobItem;
    if (jobList.length === 0) {
      data.push(...jobList, setData);
      setJobItem(data);
      SetCookie('_jobData', JSON.stringify(data));
    } else {
      let jobIsAlreadyAdded = false;
      let jobAddedId = null;

      for (let i = 0; i < jobList.length; i++) {
        if (jobList[i].jobId === item.jobId) {
          jobIsAlreadyAdded = true;
          jobAddedId = i;
        }
        if (i === jobList.length - 1 && !jobIsAlreadyAdded) {
          data.push(...jobList, setData);
          setJobItem(data);
          SetCookie('_jobData', JSON.stringify(data));
        }
        if (i === jobList.length - 1 && jobIsAlreadyAdded) {
          jobList.splice(jobAddedId, 1);
          data.push(...jobList, setData);
          setJobItem(data);
          SetCookie('_jobData', JSON.stringify(data));
        }
      }
    }

    SetCookie('_jobHeader', setData);
    localStorage.setItem('jobId', item?.id);
    navigate('/job/' + item?.id + '/dashboard');
  };

  const handleSelectRow = (index, item) => {
    let tempList = props.items;
    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = index.target.checked;
      }
      return user;
    });

    const totalItems = props.items.filter((e) => e.is_locked === 0).length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State

    props.setItems(tempList);
    setSelectedList(props.items.filter((e) => e.selected));
    setMainCheck(totalItems === totalCheckedItems);
  };

  // TODO: Fix the issue with check all
  const handleAllSelect = (event) => {
    let tempList = props.items;
    tempList.map((user) => (user.is_locked === 0 ? (user.selected = event.target.checked) : ''));

    props.setItems(tempList);
    setSelectedList(props.items.filter((e) => e.selected));
    setMainCheck(event.target.checked);
  };
  const handleChangeStatus = (status) => {
    const finalArray = selectedList.map(function (obj) {
      return obj.id;
    });

    dispatch(changeStatus(finalArray, status)).then(() => {
      if (status === 'locked') {
        let data = props.items;
        if (selectedList.length !== 0) {
          for (let a = 0; a < selectedList?.length; a++) {
            for (let b = 0; b < data?.length; b++) {
              if (selectedList[a].id === data[b].id) {
                data[b] = { ...data[b], is_locked: 1, selected: false };
              }
              if (a === selectedList.length - 1) {
                props.setItems(data);
              }
            }
          }
        }
        setSelectedList([]);
      } else {
        dispatch(setReloadJob());
      }
      setSelectedList([]);
      setMainCheck(false);
    });
    setShowLock(false);
    setShowDelete(false);
    setShowArchive(false);
    setShowRestore(false);
  };

  const handleCopy = (values) => {
    const data = {
      ...values,
      due_date: moment(values.due_date).format('DD-MM-YYYY')
    };
    dispatch(copyFile(selectedList[0].id, data));
    setSelectedList([]);
    setShowCopy(false);
  };

  const onClickStaff = (item) => {
    setStaffDetail(item);
    setShowStaff(true);
  };

  const onhandleCancel = () => {
    setShowStaff(false);
  };

  const onClickRedirect = () => {
    navigate('/permission');
  };

  const onAscendingData = (type) => {
    setSort({ type: type, dir: 'asc' });
    switch (type) {
      case 'jobNo': {
        setIsStateSort(true);
        setIsJobNoSort(false);
        setIsGroupNameSort(true);
        setIsNameSort(true);
        setIsStartDateSort(true);
        setIsDueDateSort(true);
        const jobNoSort = props.items?.slice().sort((a, b) => (a?.jobId > b?.jobId ? 1 : -1));
        props.setItems(jobNoSort);
        break;
      }
      case 'groupName': {
        setIsGroupNameSort(false);
        setIsJobNoSort(true);
        setIsNameSort(true);
        setIsStateSort(true);
        setIsStartDateSort(true);
        setIsDueDateSort(true);
        const groupName = props.items
          ?.slice()
          .sort((a, b) =>
            a?.groups?.name.toLocaleLowerCase() > b?.groups?.name.toLocaleLowerCase() ? 1 : -1
          );
        props.setItems(groupName);
        break;
      }
      case 'name': {
        setIsGroupNameSort(true);
        setIsJobNoSort(true);
        setIsStateSort(true);
        setIsNameSort(false);
        setIsStartDateSort(true);
        setIsDueDateSort(true);
        const jobName = props.items
          ?.slice()
          .sort((a, b) => (a?.name.toLocaleLowerCase() > b?.name.toLocaleLowerCase() ? 1 : -1));
        props.setItems(jobName);
        break;
      }
      case 'state': {
        setIsStateSort(false);
        setIsJobNoSort(true);
        setIsGroupNameSort(true);
        setIsNameSort(true);
        setIsStartDateSort(true);
        setIsDueDateSort(true);
        const state = props.items
          ?.slice()
          .sort((a, b) => (a?.state.toLocaleLowerCase() > b?.state.toLocaleLowerCase() ? 1 : -1));
        props.setItems(state);
        break;
      }
      case 'start_date': {
        setIsJobNoSort(true);
        setIsStartDateSort(false);
        setIsStateSort(true);
        setIsGroupNameSort(true);
        setIsNameSort(true);
        setIsDueDateSort(true);
        const startDate = props.items
          ?.slice()
          .sort((a, b) => (new Date(a?.created_at) > new Date(b?.created_at) ? 1 : -1));
        props.setItems(startDate);
        break;
      }
      case 'due_date': {
        setIsJobNoSort(true);
        setIsDueDateSort(false);
        setIsStartDateSort(true);
        setIsGroupNameSort(true);
        setIsNameSort(true);
        setIsStateSort(true);
        const startDate = props.items
          ?.slice()
          .sort((a, b) => (new Date(a?.due_date) > new Date(b?.due_date) ? 1 : -1));
        props.setItems(startDate);
        break;
      }
      default:
        return null;
    }
  };

  const onDescendingData = (type) => {
    switch (type) {
      case 'jobNo': {
        setIsJobNoSort(false);
        setSort({ type: type, dir: 'desc' });
        const jobNoSort = props.items?.slice().sort((a, b) => (b?.jobId > a?.jobId ? 1 : -1));
        props.setItems(jobNoSort);
        break;
      }
      case 'groupName': {
        setIsGroupNameSort(false);
        setSort({ type: type, dir: 'desc' });
        const groupName = props.items
          ?.slice()
          .sort((a, b) =>
            b?.groups?.name.toLocaleLowerCase() > a?.groups?.name.toLocaleLowerCase() ? 1 : -1
          );
        props.setItems(groupName);
        break;
      }
      case 'name': {
        setIsNameSort(false);
        setSort({ type: type, dir: 'desc' });
        const jobName = props.items
          ?.slice()
          .sort((a, b) => (b?.name.toLocaleLowerCase() > a?.name.toLocaleLowerCase() ? 1 : -1));
        props.setItems(jobName);
        break;
      }
      case 'state': {
        setIsStateSort(false);
        setSort({ type: type, dir: 'desc' });
        const state = props.items
          ?.slice()
          .sort((a, b) => (b?.state.toLocaleLowerCase() > a?.state.toLocaleLowerCase() ? 1 : -1));
        props.setItems(state);
        break;
      }
      case 'start_date': {
        setSort({ type: type, dir: 'desc' });
        const startDate = props.items
          ?.slice()
          .sort((a, b) => (new Date(b?.created_at) > new Date(a?.created_at) ? 1 : -1));
        props.setItems(startDate);
        break;
      }
      case 'due_date': {
        setSort({ type: type, dir: 'desc' });
        const startDate = props.items
          ?.slice()
          .sort((a, b) => (new Date(b?.due_date) > new Date(a?.due_date) ? 1 : -1));
        props.setItems(startDate);
        break;
      }
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <form className="row needs-validation" noValidate>
          <div className="table-btn">
            <button
              type="button"
              data-toggle="modal"
              disabled={selectedList.length > 0 ? false : true}
              className={selectedList.length == 0 ? 'disable-btn' : ''}
              onClick={() => handleButtonClick(LOCKED)}
            >
              Lock
            </button>
            {(props.status === DELETED || props.status === ARCHIVED) && (
              <button
                type="button"
                data-toggle="modal"
                disabled={selectedList.length > 0 ? false : true}
                className={selectedList.length == 0 ? 'disable-btn' : ''}
                onClick={() => handleButtonClick(ACTIVE)}
              >
                Restore
              </button>
            )}
            {props.status !== ARCHIVED && (
              <button
                type="button"
                data-toggle="modal"
                disabled={selectedList.length > 0 ? false : true}
                className={selectedList.length == 0 ? 'disable-btn' : ''}
                onClick={() => handleButtonClick(ARCHIVED)}
              >
                Archive
              </button>
            )}
            {props.status !== DELETED && (
              <button
                type="button"
                data-toggle="modal"
                disabled={selectedList.length > 0 ? false : true}
                className={selectedList.length == 0 ? 'disable-btn' : ''}
                onClick={() => handleButtonClick(DELETED)}
              >
                Delete
              </button>
            )}

            <button
              type="button"
              data-toggle="modal"
              onClick={() => handleButtonClick(COPY)}
              disabled={selectedList.length == 1 ? false : true}
              className={selectedList.length != 1 ? 'disable-btn' : ''}
            >
              Copy
            </button>
          </div>
          <div className="innertab">
            <Table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      defaultChecked={mainCheck}
                      type="checkbox"
                      onClick={(e) => handleAllSelect(e)}
                    />
                  </th>
                  <th scope="col" className="align-left">
                    Job No.
                    {isJobNoSort && (
                      <i
                        className="fa fa-sort"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('jobNo')}
                      />
                    )}
                    {sort.dir === 'desc' && sort.type === 'jobNo' && (
                      <i
                        className="fa fa-sort-up"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('jobNo')}
                      />
                    )}
                    {sort.dir === 'asc' && sort.type === 'jobNo' && (
                      <i
                        className="fa fa-sort-down"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onDescendingData('jobNo')}
                      />
                    )}
                  </th>
                  <th scope="col" className="align-left">
                    Group Name
                    {isGroupNameSort && (
                      <i
                        className="fa fa-sort"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('groupName', false)}
                      />
                    )}
                    {sort.dir === 'desc' && sort.type === 'groupName' && (
                      <i
                        className="fa fa-sort-up"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('groupName', false)}
                      />
                    )}
                    {sort.dir === 'asc' && sort.type === 'groupName' && (
                      <i
                        className="fa fa-sort-down"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onDescendingData('groupName')}
                      />
                    )}
                  </th>
                  <th scope="col" className="align-left">
                    Job Name
                    {isNameSort && (
                      <i
                        className="fa fa-sort"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('name')}
                      />
                    )}
                    {sort.dir === 'desc' && sort.type === 'name' && (
                      <i
                        className="fa fa-sort-up"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('name')}
                      />
                    )}
                    {sort.dir === 'asc' && sort.type === 'name' && (
                      <i
                        className="fa fa-sort-down"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onDescendingData('name')}
                      />
                    )}
                  </th>
                  <th scope="col" className="align-left">
                    State
                    {isStateSort && (
                      <i
                        className="fa fa-sort"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('state')}
                      />
                    )}
                    {sort.dir === 'desc' && sort.type === 'state' && (
                      <i
                        className="fa fa-sort-up"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('state')}
                      />
                    )}
                    {sort.dir === 'asc' && sort.type === 'state' && (
                      <i
                        className="fa fa-sort-down"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onDescendingData('state')}
                      />
                    )}
                  </th>
                  {props.items?.[0]?.users_count && <th scope="col">Staff Member</th>}

                  <th scope="col">
                    Start
                    {isStartDateSort && (
                      <i
                        className="fa fa-sort"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('start_date')}
                      />
                    )}
                    {sort.dir === 'desc' && sort.type === 'start_date' && (
                      <i
                        className="fa fa-sort-up"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('start_date')}
                      />
                    )}
                    {sort.dir === 'asc' && sort.type === 'start_date' && (
                      <i
                        className="fa fa-sort-down"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onDescendingData('start_date')}
                      />
                    )}
                  </th>
                  <th scope="col">
                    Due
                    {isDueDateSort && (
                      <i
                        className="fa fa-sort"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('due_date')}
                      />
                    )}
                    {sort.dir === 'desc' && sort.type === 'due_date' && (
                      <i
                        className="fa fa-sort-up"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onAscendingData('due_date')}
                      />
                    )}
                    {sort.dir === 'asc' && sort.type === 'due_date' && (
                      <i
                        className="fa fa-sort-down"
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => onDescendingData('due_date')}
                      />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody
                style={{
                  counterReset: `Serial ${sort.dir === 'desc' ? props.items.length + 1 : ''}`
                }}
              >
                {props.items && props.items ? (
                  props.items?.map((item, index) => {
                    return (
                      <tr key={index} className={item?.is_locked === 1 ? 'lock' : null}>
                        <th scope="row">
                          <input
                            // checked={selection.some((el) => el === item.id) && checkValue}
                            checked={item.selected}
                            type="checkbox"
                            onChange={(index) => handleSelectRow(index, item)}
                          />
                        </th>
                        <td
                          className="align-left jobId pointer-event"
                          onClick={(index) => handleUnlock(item, index)}
                        >
                          <div style={{ display: 'flex' }}>
                            <span>
                              <h6 style={{ width: '10px' }}>
                                {item.is_locked === 1 && (
                                  <i
                                    className="fa fa-lock-keyhole"
                                    style={{
                                      color: '#2BB673',
                                      fontSize: '20px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                )}
                              </h6>
                            </span>
                            <span>
                              <h6 style={{ marginLeft: '20px', marginTop: '2px' }}>
                                {item?.job_no}
                              </h6>
                            </span>
                          </div>
                        </td>

                        <td onClick={() => handleClickRow(item)}>
                          <p className="align-left-ellipse">{item?.groups?.name}</p>
                        </td>
                        <td className="align-left" onClick={() => handleClickRow(item)}>
                          <p className="align-left-ellipse">{item?.name}</p>
                        </td>
                        <td className="align-left">
                          <select
                            value={item?.state}
                            onChange={(index) => handleStateChange(index, item)}
                          >
                            {props?.stateJobDataResponse?.data
                              ? props?.stateJobDataResponse.data.map((state, i) => {
                                  return (
                                    <option key={i} value={state.key}>
                                      {state.value}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </td>
                        {props.items?.[0]?.users_count && (
                          <td onClick={() => onClickStaff(item)}>{item?.users_count} Staff</td>
                        )}

                        <td className="align-left nobreakword" onClick={() => handleClickRow(item)}>
                          {moment(item?.created_at).format("DD MMM 'YY")}
                        </td>
                        <td className="align-left nobreakword" onClick={() => handleClickRow(item)}>
                          {moment(item?.due_date).format("DD MMM 'YY")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7">You don&rsquo;t have any job</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </form>
        <Pagination
          total={props.total}
          limit={parseInt(props.limit)}
          currentPage={props.currentPage}
          updateLimit={props.handleUpdateLimit}
          updatePage={props.handleChangePage}
        />
      </div>
      {/* Lock Job */}
      <CustomModal
        value="lock"
        show={showLock}
        title="Lock Job?"
        text="Are you sure you want to lock this job?"
        cancel={handleCancel}
        reference={LOCKED}
        success={handleChangeStatus}
      />
      {/* Archive Job */}
      <CustomModal
        value="archive"
        show={showArchive}
        title="Archive Job?"
        text="Are you sure you want to archive these job/s?"
        cancel={handleCancel}
        reference={ARCHIVED}
        success={handleChangeStatus}
      />
      {/* Delete Job? */}
      <CustomModal
        value="delete"
        show={showDelete}
        title="Delete Job?"
        text="Are you sure you want to delete this job permanently? The job will be permanently deleted after 30 days."
        cancel={handleCancel}
        reference={DELETED}
        success={handleChangeStatus}
      />
      {/* Delete Job? */}
      <CustomModal
        value="restore"
        show={showRestore}
        title="Restore Job?"
        text="Are you sure you want to restore these job/s?"
        cancel={handleCancel}
        reference={ACTIVE}
        success={handleChangeStatus}
      />
      {/* staff Modal */}
      <Modal
        show={showStaff}
        onHide={onhandleCancel}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ justifyContent: 'center' }}>
          <h2>Staff Access</h2>
        </Modal.Header>
        <div className="staff-list">
          <h5>
            {staffDetail?.users_count} Staff with access to {staffDetail?.groups?.name}
          </h5>
          {staffDetail?.accountants?.map((item, index) => {
            return (
              <>
                <Link
                  key={index}
                  to={'/staff-management/' + item?.users?.id + '/staff/' + item?.id}
                >
                  {item?.users?.name} {item?.users?.surname}
                </Link>
                <br />
              </>
            );
          })}
        </div>
        <div className="modal-action modal-footer" style={{ justifyContent: 'space-between' }}>
          <Button type="button" className="btn btn-secondary" onClick={onhandleCancel}>
            Cancel
          </Button>
          <Button type="submit" className="btn btn-primary" onClick={onClickRedirect}>
            Manage Access
          </Button>
        </div>
      </Modal>
      {/* Copy Job */}
      <Modal
        show={showCopy}
        onHide={handleCancel}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Copy Job</h2>
        </Modal.Header>
        <Modal.Body className="create-job">
          <div className="createjob_v2">
            <Formik
              initialValues={{
                // group_id: selectedList[0]?.groups?.id ? selectedList[0]?.groups?.id : '',
                group_id: '',
                group_name: selectedList[0]?.groups?.name ? selectedList[0]?.groups?.name : '',
                name: selectedList[0]?.name ? selectedList[0]?.name : null,
                due_date: selectedList[0]?.due_date ? selectedList[0]?.due_date : null
              }}
              validationSchema={CopyJobSchema}
              onSubmit={handleCopy}
            >
              {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <fieldset>
                    <div className="field-box">
                      <Label>Group Name</Label>
                      <GroupInput
                        name="group_name"
                        value={selectedList[0]?.groups?.name}
                        setGroupName={(e) => {
                          // setValues({
                          //   ...values,
                          //   group_name: e
                          // });
                          setFieldValue('group_name', e);
                        }}
                        setGroupId={() => {
                          // setValues({
                          //   ...values,
                          //   group_id: e
                          // });
                          // setFieldValue('group_id', e);
                        }}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {/* {errors.group_id || errors.group_name} */}
                        {errors.group_name}
                      </span>
                      {/* <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.group_name}
                      name="group_name"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.group_name}
                    </span> */}
                    </div>
                    <div className="field-box">
                      <Label>Job Name</Label>
                      <Input
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.name ? values?.name : ''}
                        name="name"
                      />
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors.name}
                      </span>
                    </div>
                    <div className="field-box date">
                      <Label>Due Date</Label>
                      <DatePicker
                        selected={new Date(values?.due_date)}
                        onChange={(val) => {
                          setFieldValue('due_date', val);
                        }}
                        customInput={
                          <InputMask className="form-control" mask="99-99-9999" maskChar={null} />
                        }
                        name="due_date"
                        dateFormat="dd-MM-yyyy"
                        value={new Date(values?.due_date)}
                        placeholderText="DD-MM-YYYY"
                        minDate={new Date()}
                        id="datepicker"
                      />
                      {/* <Input
                      type="date"
                      pattern="\d{2}-\d{2}-\d{4}"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.due_date}
                      id="datepicker"
                      name="due_date"
                      className="hasDatepicker"
                      min={moment(new Date()).format('Y-MM-DD')}
                    /> */}

                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors.due_date}
                      </span>
                    </div>
                  </fieldset>
                  <div className="modal-action modal-footer">
                    <Button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleCancel(COPY)}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" className="btn btn-primary">
                      Create
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      {/* unlock job */}
      <Modal
        show={showUnlock}
        onHide={handleUnlockCancle}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Unlock Job?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to unlock this job?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button className="btn btn-secondary" onClick={handleUnlockCancle}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              unlockJob(unlockJobData);
            }}
          >
            Unlock
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default JobTable;
