import UserHeader from '../dashboard/UserHeader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../components/CustomModal';
import { staffState, getmemberData, deleteMember } from '../../slices/slices/StaffManagementSlice';
import { Link, useNavigate } from 'react-router-dom';
import AddStaffModal from '../../components/AddStaffModal';
import Pagination from '../common/Pagination';
const StaffManagement = () => {
  const DELETED = 'deleted';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const { getMemberDataResponse } = useSelector(staffState);
  const [showDelete, setShowDelete] = useState(false);
  const [isopen, setOpen] = useState(false);
  const [deletemember, setDeleteMember] = useState();
  const [items, setItems] = useState([]);

  const [colummName, setColummName] = useState({ type: '', columm: '' });

  useEffect(() => {
    memberData();
  }, []);

  const memberData = () => {
    dispatch(getmemberData(0, limit));
  };

  useEffect(() => {
    let newData;
    if (getMemberDataResponse && getMemberDataResponse?.data?.length > 0) {
      newData = getMemberDataResponse?.data.map((item, index) => {
        return {
          ...item,
          role: item?.roles?.name,
          jobCount: item?.accountant?.job_count,
          jobId: index + 1,
          selected: false
        };
      });
    }
    setItems(newData);
    setTotal(getMemberDataResponse?.total);
    setLimit(getMemberDataResponse?.per_page);
    setCurrentPage(getMemberDataResponse?.current_page);
  }, [getMemberDataResponse]);

  const handleUpdateLimit = (element) => {
    dispatch(getmemberData(currentPage, element.target.value));
    setLimit(element.target.value);
    setCurrentPage(currentPage);
  };
  const handleChangePage = (page) => {
    dispatch(getmemberData(page, limit));
    setCurrentPage(page);
  };

  const onhandleDelete = (id) => {
    setDeleteMember(id);
    setShowDelete(true);
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
  };

  const handledeleteMember = () => {
    if (deletemember)
      dispatch(deleteMember(deletemember)).then(() => {
        setShowDelete(false);
        dispatch(getmemberData(currentPage, limit));
      });
  };

  const onRedirect = (memeber, accountantId) => {
    navigate('/staff-management/' + memeber + '/staff/' + accountantId);
  };

  const onhandleClick = () => {
    setOpen(true);
  };

  const onSortingData = (columm, type) => {
    setColummName({ type: type, columm: columm });
    const jobDataSort = items?.slice().sort((a, b) => {
      if (type == 'asc') {
        return (
          typeof a?.[columm] == 'string'
            ? String(a?.[columm]).toLocaleLowerCase() > String(b?.[columm]).toLocaleLowerCase()
            : a?.[columm] > b?.[columm]
        )
          ? 1
          : -1;
      }
      return (
        typeof a?.[columm] == 'string'
          ? String(b?.[columm]).toLocaleLowerCase() > String(a?.[columm]).toLocaleLowerCase()
          : b?.[columm] > a?.[columm]
      )
        ? 1
        : -1;
    });
    setItems(jobDataSort);
  };

  return (
    <>
      <div className="setting toggle-sidebar">
        <UserHeader />
        <main id="main" className="main">
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <Link to={'/dashboard'}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to={'/staff-management'}>Staff Management</Link>
                </li>
              </ul>
              <h1>Staff Management</h1>
            </div>
            <div className="breadcrumb-right">
              <button type="button" className="button green" onClick={onhandleClick}>
                <i className="icon-plus"></i>Add Staff Member
              </button>
            </div>
          </div>
          <div className="pagetitle groupanalysispro">
            <div className="title_pro overall_title d-flex justify-content-between align-items-center">
              <div className="addmemberarea"></div>
            </div>
          </div>
          <div>
            <div className="white-card">
              <table className="table table-hover" style={{ marginBottom: '20px' }}>
                <thead>
                  <tr>
                    <th scope="col" className="align-centre">
                      Sr No.
                      <i
                        className={`fa ${
                          colummName.type === 'desc' && colummName.columm === 'jobId'
                            ? 'fa-sort-down'
                            : colummName.type === 'asc' && colummName.columm === 'jobId'
                            ? 'fa-sort-up'
                            : 'fa-sort'
                        }`}
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() =>
                          onSortingData(
                            'jobId',
                            colummName.type === 'asc' && colummName.columm === 'jobId'
                              ? 'desc'
                              : 'asc'
                          )
                        }
                      />
                    </th>
                    <th scope="col" className="align-centre">
                      User Name
                      <i
                        className={`fa ${
                          colummName.type === 'desc' && colummName.columm === 'name'
                            ? 'fa-sort-down'
                            : colummName.type === 'asc' && colummName.columm === 'name'
                            ? 'fa-sort-up'
                            : 'fa-sort'
                        }`}
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() =>
                          onSortingData(
                            'name',
                            colummName.type === 'asc' && colummName.columm === 'name'
                              ? 'desc'
                              : 'asc'
                          )
                        }
                      />
                    </th>
                    <th scope="col" className="align-left">
                      Email Address
                      <i
                        className={`fa ${
                          colummName.type === 'desc' && colummName.columm === 'email'
                            ? 'fa-sort-down'
                            : colummName.type === 'asc' && colummName.columm === 'email'
                            ? 'fa-sort-up'
                            : 'fa-sort'
                        }`}
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() =>
                          onSortingData(
                            'email',
                            colummName.type === 'asc' && colummName.columm === 'email'
                              ? 'desc'
                              : 'asc'
                          )
                        }
                      />
                    </th>
                    <th scope="col" className="align-left">
                      Phone No
                      <i
                        className={`fa ${
                          colummName.type === 'desc' && colummName.columm === 'phone'
                            ? 'fa-sort-down'
                            : colummName.type === 'asc' && colummName.columm === 'phone'
                            ? 'fa-sort-up'
                            : 'fa-sort'
                        }`}
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() =>
                          onSortingData(
                            'phone',
                            colummName.type === 'asc' && colummName.columm === 'phone'
                              ? 'desc'
                              : 'asc'
                          )
                        }
                      />
                    </th>
                    <th scope="col">
                      Role
                      <i
                        className={`fa ${
                          colummName.type === 'desc' && colummName.columm === 'role'
                            ? 'fa-sort-down'
                            : colummName.type === 'asc' && colummName.columm === 'role'
                            ? 'fa-sort-up'
                            : 'fa-sort'
                        }`}
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() =>
                          onSortingData(
                            'role',
                            colummName.type === 'asc' && colummName.columm === 'role'
                              ? 'desc'
                              : 'asc'
                          )
                        }
                      />
                    </th>
                    <th scope="col">
                      No. of Jobs
                      <i
                        className={`fa ${
                          colummName.type === 'desc' && colummName.columm === 'jobCount'
                            ? 'fa-sort-down'
                            : colummName.type === 'asc' && colummName.columm === 'jobCount'
                            ? 'fa-sort-up'
                            : 'fa-sort'
                        }`}
                        aria-hidden="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() =>
                          onSortingData(
                            'jobCount',
                            colummName.type === 'asc' && colummName.columm === 'jobCount'
                              ? 'desc'
                              : 'asc'
                          )
                        }
                      />
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items &&
                    items.length > 0 &&
                    items.map((item) => {
                      return (
                        <>
                          <tr>
                            <td className="align-centre">{item.jobId}</td>
                            <td className="align-centre">{item.name}</td>
                            <td className="align-left">{item.email}</td>
                            <td className="align-left">{item.phone}</td>
                            <td style={{ textTransform: 'capitalize' }}>{item?.role}</td>
                            <td>{item?.jobCount}</td>
                            <td className="staff-btn">
                              <button
                                className=""
                                onClick={() => onRedirect(item.id, item?.accountant?.id)}
                              >
                                <i className="fa fa-eye"></i>
                              </button>
                              <button className="remove" onClick={() => onhandleDelete(item.id)}>
                                <i className="fa fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={currentPage}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
              />
            </div>
          </div>
        </main>
      </div>

      {/* Delete Staff Member? */}
      <CustomModal
        value="delete"
        show={showDelete}
        title="Delete Staff Member?"
        text="Are you sure you want to delete this staff Member ?"
        cancel={handleDeleteCancel}
        reference={DELETED}
        success={handledeleteMember}
      />

      <AddStaffModal isopen={isopen} setOpen={setOpen} handleSubmit={memberData} type={true} />
    </>
  );
};

export default StaffManagement;
