import React, { useState, useEffect } from 'react';
import UserHeader from '../dashboard/UserHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  staffState,
  getPermission,
  updatePermissions,
  clearPermission
} from '../../slices/slices/StaffManagementSlice';
import Button from '../../components/Button';

export default function Permission() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState(false);
  const [permissionData, setPermissionData] = useState([]);
  const [submissionData, setSubmissionData] = useState(null);
  const { permissionResponse } = useSelector(staffState);

  useEffect(() => {
    dispatch(clearPermission());
    dispatch(getPermission());
  }, []);

  useEffect(() => {
    let submitObj = {};
    let userRole = [];
    let permission = JSON.parse(JSON.stringify(permissionResponse));
    permission?.user_roles?.map((item) => {
      submitObj[item.name] = [];
      if (item.name === 'administrator') {
        userRole[0] = item;
      } else if (item.name === 'manager') {
        userRole[1] = item;
      } else {
        if (userRole.length > 2) {
          userRole.push(item);
        } else {
          userRole[2] = item;
        }
      }
    });
    permission['user_roles'] = userRole;
    permission?.permission?.map((item) => {
      item?.roles.map((el) => {
        submitObj[el.name].push(item.id);
      });
    });
    setSubmissionData(submitObj);
    setPermissionData(permission);
  }, [permissionResponse]);

  const handleSubmit = ({ setErrors }) => {
    dispatch(updatePermissions(submissionData, setErrors)).then(() => {
      setRedirectUrl(true);
    });
  };

  const handleCancel = () => {
    setRedirectUrl(true);
  };

  useEffect(() => {
    if (redirectUrl) navigate('/dashboard');
  }, [redirectUrl]);

  const handlePermissionCheck = (e, roleData, permissionData) => {
    let submit = JSON.parse(JSON.stringify(submissionData));
    if (e.target.checked) {
      submit[roleData.name].push(permissionData.id);
    } else {
      let index = submit[roleData.name].findIndex((item) => item === permissionData.id);
      submit[roleData.name].splice(index, 1);
    }
    setSubmissionData(submit);
  };

  return (
    <>
      <div className="setting toggle-sidebar">
        <UserHeader />
        <main id="main" className="main">
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <a href="#">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Role Permission</strong>
                </li>
              </ul>
              <h1>Role Permission</h1>
            </div>
          </div>
          <div className="white-card mt-30">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" className="align-left">
                    Permission
                  </th>
                  {permissionData?.user_roles?.map((role) => (
                    <th
                      scope="col"
                      className="align-center"
                      key={role.id}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {role.name}
                    </th>
                  ))}
                  {/* <th scope="col" className="align-center">
                      Practice Administrator
                    </th>
                    <th scope="col" className="align-center">
                      Staff
                    </th>
                    <th scope="col" className="align-center">
                      Accountant
                    </th> */}
                </tr>
              </thead>
              <tbody>
                {permissionData?.permission?.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="align-left">
                          <b>{item?.value}</b>
                        </td>
                        {permissionData?.user_roles?.map((role) => (
                          <td key={role?.id}>
                            <input
                              defaultChecked={item?.roles.find((el) => el?.id === role?.id)}
                              type="checkbox"
                              disabled={role.name === 'administrator' ? true : false}
                              onChange={(e) => {
                                handlePermissionCheck(e, role, item);
                              }}
                            />
                          </td>
                        ))}
                        {/* <td>
                            <input defaultChecked={true} type="checkbox" />
                          </td>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>
                            <input type="checkbox" />
                          </td> */}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="btn_area text-end">
            <Button className="btn btn-outline-secondary" type="reset" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="btn btn-success" type="submit" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </main>
      </div>
    </>
  );
}
