import React from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getDownloadToolsAndTemplates } from '../../../slices/slices/JobSlice';
// import { saveAs } from 'file-saver';

const allItems = [
  {
    key: 1,
    no: 1,
    name: 'New Client Initial Contact Form',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/1.+New+Client+Initial+Contact+Form.docx'
  },
  {
    key: 2,
    no: 2,
    name: 'Introduction Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/2.+Introduction+Email.docx'
  },
  {
    key: 3,
    no: 3,
    name: 'Initial Client Meeting Form',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/3.+Initial+Client+Meeting+Form.docx'
  },
  {
    key: 4,
    no: 4,
    name: 'NULL',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/1.+New+Client+Initial+Contact+Form.docx'
  },
  {
    key: 5,
    no: 5,
    name: 'Engagement Agreement',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/5.+Engagement+Agreement.docx'
  },
  {
    key: 6,
    no: 6,
    name: 'Post Engagement Information Request Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/6.+Post+Engagement+Information+Request+Email.docx'
  },
  {
    key: 7,
    no: 7,
    name: 'Projection Request Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/7.+Projection+Request+Email.docx'
  },
  {
    key: 8,
    no: 8,
    name: 'Statement of Assets and Liabilities Form',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/8.+Statement+of+Assets+and+Liabilities+Form.docx'
  },
  {
    key: 9,
    no: 9,
    name: 'Consolidated Group Position Form',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/9.+Consolidated+Group+Position+Form.docx'
  },
  {
    key: 10,
    no: 10,
    name: 'Understanding Your Business Questionnaire',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/10.+Understanding+Your+Business+Questionnaire.docx'
  },
  {
    key: 11,
    no: 11,
    name: 'Commercial Property Investment Questionnaire',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/11.+Commercial+Property+Investment+Questionnaire.docx'
  },
  {
    key: 12,
    no: 12,
    name: 'Property Development Assessemnt Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/12.+Property+Development+Assessemnt+Email.docx'
  },
  {
    key: 13,
    no: 13,
    name: 'Property Development Questionnaire',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/13.+Property+Development+Questionnaire.docx'
  },
  {
    key: 14,
    no: 14,
    name: 'Work In Progess Schedule',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/14.+Work+In+Progess+Schedule.docx'
  },
  {
    key: 15,
    no: 15,
    name: 'Progress Update - Bankability Assessemnt Commencement Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/15.+Progress+Update+-+Bankability+Assessemnt+Commencement+Email+%5BNot+Edited%5D.docx'
  },
  {
    key: 16,
    no: 16,
    name: 'Information and Clarification Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/16.+Information+and+Clarification+Email.docx'
  },
  {
    key: 17,
    no: 17,
    name: 'Client Not Providing Required Information Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/17.+Client+Not+Providing+Required+Information+Email.docx'
  },
  {
    key: 18,
    no: 18,
    name: 'Progress Update – Bankability Assessment Completion Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/18.+Progress+Update+%E2%80%93+Bankability+Assessment+Completion+Email.docx'
  },
  {
    key: 19,
    no: 19,
    name: 'Bankability Invoice Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/19.+Bankability+Invoice+Email+%5BNot+Edited%5D.docx'
  },
  {
    key: 20,
    no: 20,
    name: 'Quantity Surveyor Fee Quote and Instructions Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/20.+Quantity+Surveyor+Fee+Quote+and+Instructions+Email.docx'
  },
  {
    key: 21,
    no: 21,
    name: 'Valuation Fee Quote and Instructions Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/21.+Valuation+Fee+Quote+and+Instructions+Email.docx'
  },
  {
    key: 22,
    no: 22,
    name: 'Proposal Submission - Existing Lender Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/22.+Proposal+Submission+-+Existing+Lender+Email.docx'
  },

  {
    key: 23,
    no: 23,
    name: 'Proposal Submission - New Lender(s) Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/23.+Proposal+Submission+-+New+Lender(s)+Email.docx'
  },
  {
    key: 24,
    no: 24,
    name: 'Progress Update - Lender Submission Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/24.+Progress+Update+-+Lender+Submission+Email+%5BNot+Edited%5D.docx'
  },
  {
    key: 25,
    no: 25,
    name: 'Indicative Terms from Lender Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/25.+Indicative+Terms+from+Lender+Email.docx'
  },
  {
    key: 26,
    no: 26,
    name: 'Commission Challenge Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/26.+Commission+Challenge+Email.docx'
  },
  {
    key: 27,
    no: 27,
    name: 'Financial Covenants, Undertakings and Negative Pledge Examples',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/27.+Financial+Covenants%2C+Undertakings+and+Negative+Pledge+Examples+%5BNot+Edited%7D.docx'
  },
  {
    key: 28,
    no: 28,
    name: 'Lender Recommendation Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/28.+Lender+Recommendation+Email.docx'
  },
  {
    key: 29,
    no: 29,
    name: 'Partly Completed Fact Find Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/29.+Partly+Completed+Fact+Find+Email.docx'
  },
  {
    key: 30,
    no: 30,
    name: 'Progress Update - Formal Approval Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/30.+Progress+Update+-+Formal+Approval+Email.docx'
  },
  {
    key: 31,
    no: 31,
    name: 'Lender Lost Client Notification Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/31.+Lender+Lost+Client+Notification+Email.docx'
  },
  {
    key: 32,
    no: 32,
    name: 'Progress Update - Settlement Booked Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/32.+Progress+Update+-+Settlement+Booked+Email+%5BNot+Edited%5D.docx'
  },
  {
    key: 33,
    no: 33,
    name: 'Settlement and Google Review Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/33.+Settlement+and+Google+Review+Email.docx'
  },
  {
    key: 34,
    no: 34,
    name: 'Unpaid Commission Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/34.+Unpaid+Commission+Email+%5BNot+Edited%5D.docx'
  },
  {
    key: 35,
    no: 35,
    name: 'Referrer Payment Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/35.+Referrer+Payment+Email.docx'
  },
  {
    key: 36,
    no: 36,
    name: 'Bankability Assessment Refund Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/36.+Bankability+Assessment+Refund+Email+%5BNot+Edited%5D.docx'
  },
  {
    key: 37,
    no: 37,
    name: 'Post Settlement Email',
    file: 'https://influence-storage-bucket.s3.ap-southeast-2.amazonaws.com/public/ToolsTemplates/37.+Post+Settlement+Email+%5BNot+Edited%5D.docx'
  }
];

const arrayRemove = (arr, value) => {
  return arr.filter(function (ele) {
    return ele !== value;
  });
};

const allRowIds = allItems?.map((data) => {
  return data?.key;
});

const allFiles = allItems?.map((data) => {
  return data?.file;
});

const ProcessTable = () => {
  const dispatch = useDispatch();

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleAllSelect = (event) => {
    if (event.target.checked === true) {
      setSelectedRowIds([...selectedRowIds, ...allRowIds]);
      setSelectedFiles([...selectedFiles, ...allFiles]);
    } else {
      setSelectedRowIds([]);
      setSelectedFiles([]);
    }
  };

  const handleSelectRow = (event, item) => {
    if (event.target.checked === true) {
      if (selectedRowIds?.length > 0) {
        setSelectedRowIds(selectedRowIds.concat(item?.key));
        setSelectedFiles(selectedFiles.concat(item?.file));
      } else {
        setSelectedRowIds([item?.key]);
        setSelectedFiles([item?.file]);
      }
    } else {
      setSelectedRowIds(arrayRemove(selectedRowIds, item?.key));
      setSelectedFiles(arrayRemove(selectedFiles, item?.file));
    }
  };

  const handleDownload = async () => {
    if (selectedFiles && selectedFiles?.length > 0) {
      selectedFiles?.map((item, index) => {
        setTimeout(() => {
          if (item) {
            dispatch(getDownloadToolsAndTemplates(item)).then((response) => {
              const fileData = response.data;
              const url = window.URL.createObjectURL(new Blob([fileData]));
              var a = document.createElement('a');
              a.href = url;
              a.setAttribute('download', `${allItems[selectedRowIds[index] - 1]?.name}.docx`);
              document.body.appendChild(a);
              a.click();
              a.remove();
            });
          }
        }, index * 500);
      });
    }
  };

  return (
    <>
      <div>
        <form className="row needs-validation" noValidate>
          <div className="table-btn">
            <button
              type="button"
              onClick={() => {
                handleDownload();
              }}
            >
              Download
            </button>
          </div>
          <div className="innertab process-table">
            <Table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">
                    <input type="checkbox" onClick={(e) => handleAllSelect(e)} />
                  </th>
                  <th scope="col">No.</th>
                  <th scope="col" className="align-left">
                    Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {allItems && allItems?.length > 0 ? (
                  allItems?.map((item, index) => {
                    return (
                      <tr key={index} className={item?.is_locked === 1 ? 'lock' : null}>
                        <th scope="row">
                          <input
                            checked={
                              item?.key === selectedRowIds.find((data) => data === item?.key)
                                ? true
                                : false
                            }
                            type="checkbox"
                            onChange={(event) => handleSelectRow(event, item)}
                          />
                        </th>
                        <td>
                          <div style={{ display: 'flex' }}>
                            <span>{item?.no}</span>
                          </div>
                        </td>

                        <td>
                          <p className="align-left-ellipse">{item?.name}</p>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3">You don&rsquo;t have any records</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProcessTable;
