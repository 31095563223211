import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const transactionInitialState = {
  loading: false,
  transactionName: {},
  howSourceTypeList: {},
  setSuccessResponse: {},
  transactionList: {},
  deleteTransactionResponse: {},
  editTransactionResponse: {},
  getfundsData: {}
};

export const ProposedTransaction = createSlice({
  name: 'proposedtransaction',
  initialState: transactionInitialState,
  reducers: {
    setTransactionname: (state, action) => {
      state.transactionName = action.payload;
    },
    setAllTransactionList: (state, action) => {
      state.transactionList = action.payload;
    },
    howSourceType: (state, action) => {
      state.howSourceTypeList = action.payload;
    },
    setSuccessResponse: (state, action) => {
      state.successResponse = action.payload;
    },
    setAssociatedResponse: (state, action) => {
      state.addAssociateData = action.payload;
    },
    seteditAssociatedData: (state, action) => {
      state.getfundsData = action.payload;
    },
    setupdateAssociatedData: (state, action) => {
      state.updateAssociatedFunds = action.payload;
    },
    setFundsData: (state, action) => {
      state.getfundsData = action.payload;
    },
    setupdateFundsData: (state, action) => {
      state.updatefundsData = action.payload;
    },
    setdetetedFunds: (state, action) => {
      state.deleteFunds = action.payload;
    },
    setdeleteAssociatedFund: (state, action) => {
      state.deleteAsosciatedFunds = action.payload;
    },
    setupdateAssociatedCommentData: (state, action) => {
      state.associatedcommentData = action.payload;
    },
    clearFundsData: (state) => {
      state.getfundsData = null;
    },
    setdetetedTransaction: (state, action) => {
      state.deleteTransactionResponse = action.payload;
    },
    setEditTransaction: (state, action) => {
      state.editTransactionResponse = action.payload;
    }
  }
});

export const AddProposedTransaction = (jobId, data, setErrors) => async (dispatch) => {
  data.name = data.values.transaction_name;
  data.associate_name = data.values.associate_name;
  const response = await clientService.post(
    'jobs/' + jobId + '/group-analysis/proposed-transactions',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setTransactionname(response.data));
  }
  return response;
};

export const getTransactionList =
  ({ jobId, page = 1, showing = 1000000, setErrors }) =>
  async (dispatch) => {
    let body = '&limit=' + showing + '&page=' + page;

    const response = await clientService.get(
      'jobs/' + jobId + '/group-analysis/proposed-transactions?' + body,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setAllTransactionList(response.data));
    }
  };

export const addFunds = (jobId, transactionId, Type, data, setErrors) => async (dispatch) => {
  data.type = Type;
  const response = await clientService.post(
    'jobs/' + jobId + '/group-analysis/proposed-transactions/' + transactionId + '/funds',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSuccessResponse(response.data));
  }
};

export const addassociateFunds =
  (jobId, transactionId, Type, data, setErrors) => async (dispatch) => {
    data.type = Type;
    const response = await clientService.post(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-transactions/' +
        transactionId +
        '/associated-transactions',
      data,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setAssociatedResponse(response.data));
    }
  };

export const editFunds =
  (jobId, transactionId, Type, fundsId, data, setErrors) => async (dispatch) => {
    data.type = Type;
    const response = await clientService.put(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-transactions/' +
        transactionId +
        '/funds/' +
        fundsId,
      data,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setupdateFundsData(response.data));
    }
  };

export const updateassociatedfunds =
  (jobId, transactionId, fundsId, data, setErrors) => async (dispatch) => {
    const response = await clientService.put(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-transactions/' +
        transactionId +
        '/associated-transactions/' +
        fundsId,
      data,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setupdateAssociatedData(response.data));
    }
  };

export const editFunds_data =
  (jobId, transactionId, proposed_transaction_fund_id, setErrors) => async (dispatch) => {
    const response = await clientService.get(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-transactions/' +
        transactionId +
        '/funds/' +
        proposed_transaction_fund_id,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setFundsData(response.data));
    }
  };

export const getassociateddata =
  (jobId, transactionId, proposed_transaction_fund_id, setErrors) => async (dispatch) => {
    const response = await clientService.get(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-transactions/' +
        transactionId +
        '/associated-transactions/' +
        proposed_transaction_fund_id,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(seteditAssociatedData(response.data));
    }
  };

export const gethowSourceType = (selectvaluetype, setErrors) => async (dispatch) => {
  const metaData = new URLSearchParams({
    'keys[]': selectvaluetype
  }).toString();
  const response = await clientService.get('config?' + metaData, setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(howSourceType(response.data));
  }
};

export const deleteFunds = (jobId, transactionId, fundId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/group-analysis/proposed-transactions/' + transactionId + '/funds/' + fundId,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setdetetedFunds(response.data));
  }
};

export const deleteassociatedFunds =
  (jobId, transactionId, fundId, setErrors) => async (dispatch) => {
    const response = await clientService.remove(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-transactions/' +
        transactionId +
        '/associated-transactions/' +
        fundId,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setdeleteAssociatedFund(response.data));
    }
  };

export const updateProposedTransaction = (jobId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + jobId + '/group-analysis/proposed-transactions/update-all',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(getTransactionList({ jobId }));
    dispatch(setupdateAssociatedCommentData(response.data));
  }
};

export const setFundTransactionListPosition =
  (jobId, transactionId, fundId, data, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-transactions/' +
        transactionId +
        '/funds/' +
        fundId +
        '/position',
      data,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setSuccessResponse(response.data));
    }
  };

export const setAssociatedTransactionListPosition =
  (jobId, transactionId, fundId, data, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-transactions/' +
        transactionId +
        '/associated-transactions/' +
        fundId +
        '/position',
      data,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setSuccessResponse(response.data));
    }
  };

export const deleteTransaction = (jobId, transactionId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/group-analysis/proposed-transactions/delete-transaction/' + transactionId,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setdetetedTransaction(response.data));
  }
};

export const editTransaction = (jobId, transactionId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + jobId + '/group-analysis/proposed-transactions/edit-transaction/' + transactionId,
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setEditTransaction(response.data));
  }
};

export const {
  setTransactionname,
  howSourceType,
  setSuccessResponse,
  setAllTransactionList,
  setFundsData,
  clearFundsData,
  setupdateFundsData,
  setAssociatedResponse,
  seteditAssociatedData,
  setupdateAssociatedData,
  setdetetedFunds,
  setdeleteAssociatedFund,
  setupdateAssociatedCommentData,
  setdetetedTransaction,
  setEditTransaction
} = ProposedTransaction.actions;

export const ProposedTransactionState = (state) => {
  return state.proposedtransaction;
};

export default ProposedTransaction.reducer;
