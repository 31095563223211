import React from 'react';
import { Plock } from 'react-plock';
import OverallAssessmentItem from './OverallAssessmentItem';

export default function Admin(props) {
  const {
    tbodyData,
    handleOverallAssessment,
    handleEditOverallAssessment,
    showAddOverall,
    showEditOverall,
    handleDeleteCategory,
    showDeleteCategory
  } = props;
  const breakpoints = [
    { size: 640, columns: 1 },
    { size: 768, columns: 2 },
    { size: 1024, columns: 3 }
  ];
  return (
    <>
      <div
        key={1}
        className="tab-pane fade show active overall-setting"
        id="commercial-justified"
        role="tabpanel"
        aria-labelledby="commercial-tab"
      >
        <Plock nColumns={breakpoints} className="overall-admin">
          {tbodyData.map((item, index) => {
            return (
              <div className="item" key={index}>
                <div className="corporate-structure-blocks company">
                  <h4>{item.name}</h4>
                  <ul className="admin-setting">
                    <OverallAssessmentItem
                      itemData={item?.category_items}
                      mainIndex={index}
                      id={item?.id}
                      handleOverallAssessment={handleOverallAssessment}
                      handleEditOverallAssessment={handleEditOverallAssessment}
                      showAddOverall={showAddOverall}
                      showEditOverall={showEditOverall}
                      handleDeleteCategory={handleDeleteCategory}
                      showDeleteCategory={showDeleteCategory}
                    />{' '}
                  </ul>
                </div>
              </div>
            );
          })}
        </Plock>
      </div>
    </>
  );
}
