import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import { displayNameFromType, formatNumber } from '../../../common/Misc';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import FormActions from '../../../../components/FormActions';
import DialogModal from '../../../../components/modal/DialogModal';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../../../constant';
import {
  getgroupservicing,
  groupServiceabillityState,
  updateComment
  // updateComment
} from '../../../../slices/slices/GroupServiceabilitySlice';
import {
  Link,
  useParams
  // useNavigate
} from 'react-router-dom';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

export default function GroupServiceability() {
  const dispatch = useDispatch();
  //   const navigate = useNavigate();
  const { jobId } = useParams();
  const [internalNotes, setInternalNotes] = useState();
  const [reportComments, setReportComments] = useState();
  const [businessData, setBusinessData] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const { groupservicingList, personalgroupservicingList } = useSelector(groupServiceabillityState);

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  let Business = [];
  let Personal = [];

  const initialValue = {
    report_comments: reportComments || '',
    internal_notes: internalNotes || '',
    is_completed: 1
  };

  useEffect(() => {
    dispatch(getgroupservicing(jobId, 'business'));
    dispatch(getgroupservicing(jobId, 'personal'));
  }, []);

  useEffect(() => {
    groupservicingList &&
      groupservicingList?.data?.data?.length > 0 &&
      groupservicingList?.data?.data?.map((item) => {
        Business.push({
          name: displayNameFromType(item),
          position: item.position,
          type: item.type,
          job_id: item.job_id,
          activity: item.activity,
          notes: item.notes,
          directors: item.directors,
          shareholders: item.shareholders,
          additional_info: item.additional_info,
          tax_calculation: item['tax-calculation'],
          taxes: item['taxes']
        });

        Business.years = groupservicingList?.years;

        // common value for both
        Business.proposed_debt_servicing_commitments =
          groupservicingList?.data?.proposed_debt_servicing_commitments;
        Business.sensitised_debt_servicing_commitments =
          groupservicingList?.data?.sensitised_debt_servicing_commitments;
        Business.number_of_adult = groupservicingList?.data?.number_of_adult;
        Business.number_of_dependant = groupservicingList?.data?.number_of_dependant;
        Business.total_estimated_living_expenses =
          groupservicingList?.data?.total_estimated_living_expenses;
        setBusinessData(Business);
      });
    if (groupservicingList) {
      setInternalNotes(groupservicingList?.internal_notes);
      setReportComments(groupservicingList?.report_comments);
    }
  }, [groupservicingList]);

  useEffect(() => {
    personalgroupservicingList &&
      personalgroupservicingList?.data?.data?.length > 0 &&
      personalgroupservicingList?.data?.data?.map((item) => {
        if (item.type == 'individual') {
          Personal.push({
            name: displayNameFromType(item),
            position: item.position,
            type: item.type,
            job_id: item.job_id,
            activity: item.activity,
            notes: item.notes,
            directors: item.directors,
            shareholders: item.shareholders,
            additional_info: item.additional_info,
            tax_calculation: item['tax-calculation'],
            taxes: item['taxes']
          });
        }

        Personal.years = personalgroupservicingList?.years;
        // common value for both
        Personal.proposed_debt_servicing_commitments =
          personalgroupservicingList?.data?.proposed_debt_servicing_commitments;
        Personal.sensitised_debt_servicing_commitments =
          personalgroupservicingList?.data?.sensitised_debt_servicing_commitments;
        Personal.number_of_adult = personalgroupservicingList?.data?.number_of_adult;
        Personal.number_of_dependant = personalgroupservicingList?.data?.number_of_dependant;
        Personal.total_estimated_living_expenses =
          personalgroupservicingList?.data?.total_estimated_living_expenses;

        // setInternalNotes(personalgroupservicingList?.internal_notes);
        // setReportComments(personalgroupservicingList?.report_comments);
        setPersonalData(Personal);
      });
  }, [personalgroupservicingList]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    values.type = 'business';
    dispatch(updateComment(jobId, values, setErrors));
  };

  return (
    <>
      <div className="dashboard toggle-sidebar">
        <Header />
        <main id="main" className="main">
          <DialogLeavingPage
            showDialog={showDialogLeavingPage}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <Link to={'/job/' + jobId + '/dashboard/'}>Dashboard</Link>
                </li>
                <li>
                  <a href="#">Group Analysis</a>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Group Serviceability</strong>
                </li>
              </ul>
            </div>
            <div className="breadcrumb-right">
              <DialogModal title={'Group Serviceability'} p1={constants.groupserviceability} />
            </div>
          </div>
          <div className="pagetitle groupanalysispro">
            <div className="title_pro d-flex justify-content-between align-items-center">
              <h1>Group Serviceability</h1>
            </div>
          </div>
          <div className="facility-form form-data">
            <div className="white-card">
              <div className="rentalview">
                <div className="row justify-content-start">
                  <div className="col-12">
                    <table className="table group_serviceabiliting">
                      <thead>
                        <tr>
                          <th className="text-start">Consolidated</th>
                          <th className="text-center">Proposed</th>
                          <th className="text-center">Sensitised</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="3" className="darkgary">
                            <table className="table bus_consolidated">
                              <thead>
                                <tr>
                                  <th className="text-start skyblue name_pro">Business</th>
                                  <th className="text-center skyblue">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          {/* <th className="skyblue skyopt">2019</th> */}
                                          {businessData?.years?.map((value, index) => {
                                            return (
                                              <th key={index} className="skyblue">
                                                {value}
                                              </th>
                                            );
                                          })}
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                  <th className="text-center skyblue">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          {businessData?.years?.map((value, index) => {
                                            return (
                                              <th key={index} className="skyblue">
                                                {value}
                                              </th>
                                            );
                                          })}
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {businessData?.map((item) => {
                                  let isDisplay = false;
                                  businessData?.years &&
                                    businessData?.years?.length > 0 &&
                                    businessData?.years?.map((val) => {
                                      if (
                                        (item?.['tax_calculation']?.proposed?.['debt-servicing'][
                                          val
                                        ] != undefined &&
                                          item?.['tax_calculation']?.proposed?.['debt-servicing'][
                                            val
                                          ] != 0) ||
                                        (item?.['tax_calculation']?.sensitised?.['debt-servicing'][
                                          val
                                        ] != 0 &&
                                          item?.['tax_calculation']?.sensitised?.['debt-servicing'][
                                            val
                                          ] != undefined)
                                      ) {
                                        isDisplay = true;
                                      }
                                    });

                                  return (
                                    <>
                                      {isDisplay === true ? (
                                        <tr className="yearsof">
                                          <td className="text-start name">{item.name}</td>
                                          <td className="text-center">
                                            <table className="table mb-0">
                                              <tbody>
                                                <tr className="years_of">
                                                  {businessData?.years?.map((value, index) => {
                                                    return (
                                                      <td key={index}>
                                                        {item?.['tax_calculation']?.['proposed']?.[
                                                          'debt-servicing'
                                                        ][value]
                                                          ? formatNumber(
                                                              item?.['tax_calculation']?.[
                                                                'proposed'
                                                              ]?.['debt-servicing'][value]
                                                            )
                                                          : 0}
                                                      </td>
                                                    );
                                                  })}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                          <td className="text-center">
                                            <table className="table mb-0">
                                              <tbody>
                                                <tr className="years_of sky_opt">
                                                  {businessData?.years?.map((value, index) => {
                                                    return (
                                                      <td key={index} className="skyopt">
                                                        {item?.['tax_calculation']?.[
                                                          'sensitised'
                                                        ]?.['debt-servicing'][value]
                                                          ? formatNumber(
                                                              item?.['tax_calculation']?.[
                                                                'sensitised'
                                                              ]?.['debt-servicing'][value]
                                                            )
                                                          : 0}
                                                      </td>
                                                    );
                                                  })}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })}
                                <tr className="yearsof">
                                  <td className="text-start name">
                                    <strong>Total Funds Available for Debt Servicing</strong>
                                  </td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of">
                                          {businessData?.years && businessData?.years?.length
                                            ? businessData?.years?.map((year) => {
                                                let total = 0;
                                                return (
                                                  <td key={year} className="debtfunds">
                                                    {businessData && businessData?.length > 0
                                                      ? businessData?.map((item) => {
                                                          total += item?.['tax_calculation']?.[
                                                            'proposed'
                                                          ]?.['debt-servicing'][year]
                                                            ? item?.['tax_calculation']?.[
                                                                'proposed'
                                                              ]?.['debt-servicing'][year]
                                                            : 0;
                                                        })
                                                      : null}

                                                    {formatNumber(total) === '0'
                                                      ? 0
                                                      : formatNumber(total)}
                                                  </td>
                                                );
                                              })
                                            : null}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of sky_opt">
                                          {businessData?.years && businessData?.years?.length
                                            ? businessData?.years?.map((year) => {
                                                let total = 0;
                                                return (
                                                  <td key={year} className="debtfunds skyopt">
                                                    {businessData && businessData?.length > 0
                                                      ? businessData?.map((item) => {
                                                          total += item?.['tax_calculation']?.[
                                                            'sensitised'
                                                          ]?.['debt-servicing'][year]
                                                            ? item?.['tax_calculation']?.[
                                                                'sensitised'
                                                              ]?.['debt-servicing'][year]
                                                            : 0;
                                                        })
                                                      : null}

                                                    {formatNumber(total) === '0'
                                                      ? 0
                                                      : formatNumber(total)}
                                                  </td>
                                                );
                                              })
                                            : null}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr className="yearsof">
                                  <td className="text-start name">Debt Servicing Commitments</td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of">
                                          {businessData?.years?.map((index) => {
                                            return (
                                              <td key={index}>
                                                {formatNumber(
                                                  businessData?.proposed_debt_servicing_commitments *
                                                    -1
                                                )}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of sky_opt">
                                          {businessData?.years?.map((index) => {
                                            return (
                                              <td key={index} className="skyopt">
                                                {formatNumber(
                                                  businessData?.sensitised_debt_servicing_commitments *
                                                    -1
                                                )}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr className="sky_opt">
                                  <td className="text-start rm_mb-0 namepro">
                                    Surplus / (Deficit)
                                  </td>
                                  <td className="rm_mb-0 fst_child">
                                    <table className="table mb-0">
                                      {/* <tfoot>
                                        <td className="surplush">1,000,000</td>
                                        <td>1,000,000</td>
                                        <td>1,000,000</td>
                                        <td className="surplushright">1,000,000</td>
                                      </tfoot> */}
                                      <tfoot>
                                        {businessData?.years && businessData?.years?.length
                                          ? businessData?.years?.map((year, index) => {
                                              let total = 0;

                                              return (
                                                <td
                                                  key={year}
                                                  className={index == 0 ? 'surplush' : ''}
                                                >
                                                  {businessData && businessData?.length > 0
                                                    ? businessData?.map((item) => {
                                                        total += item?.['tax_calculation']?.[
                                                          'proposed'
                                                        ]?.['debt-servicing'][year]
                                                          ? item?.['tax_calculation']?.[
                                                              'proposed'
                                                            ]?.['debt-servicing'][year]
                                                          : 0;
                                                      })
                                                    : ''}

                                                  {formatNumber(
                                                    total -
                                                      businessData?.proposed_debt_servicing_commitments
                                                  ) === '0'
                                                    ? ''
                                                    : formatNumber(
                                                        total -
                                                          businessData?.proposed_debt_servicing_commitments
                                                      )}
                                                </td>
                                              );
                                            })
                                          : null}
                                      </tfoot>
                                    </table>
                                  </td>
                                  <td className="rm_mb-0">
                                    <table className="table mb-0">
                                      <tfoot>
                                        {businessData?.years && businessData?.years?.length
                                          ? businessData?.years?.map((year) => {
                                              let total = 0;

                                              return (
                                                <td key={year} className="debtfunds skyopt">
                                                  {businessData && businessData?.length > 0
                                                    ? businessData?.map((item) => {
                                                        total += item?.['tax_calculation']?.[
                                                          'sensitised'
                                                        ]?.['debt-servicing'][year]
                                                          ? item?.['tax_calculation']?.[
                                                              'sensitised'
                                                            ]?.['debt-servicing'][year]
                                                          : 0;
                                                      })
                                                    : ''}

                                                  {formatNumber(
                                                    total -
                                                      businessData?.sensitised_debt_servicing_commitments
                                                  ) === '0'
                                                    ? ''
                                                    : formatNumber(
                                                        total -
                                                          businessData?.sensitised_debt_servicing_commitments
                                                      )}
                                                </td>
                                              );
                                            })
                                          : null}
                                      </tfoot>
                                    </table>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            <table className="table bus_consolidated">
                              <thead>
                                <tr>
                                  <th className="text-start skyblue name_pro">Personal</th>
                                  <th className="text-center skyblue">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          {personalData?.years?.map((value, index) => {
                                            return (
                                              <th key={index} className="skyblue">
                                                {value}
                                              </th>
                                            );
                                          })}
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                  <th className="text-center skyblue">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          {personalData?.years?.map((value, index) => {
                                            return (
                                              <th key={index} className="skyblue">
                                                {value}
                                              </th>
                                            );
                                          })}
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {personalData?.map((item) => {
                                  let isDisplay = false;
                                  personalData?.years &&
                                    personalData?.years?.length > 0 &&
                                    personalData?.years?.map((val) => {
                                      if (
                                        (item?.['tax_calculation']?.proposed?.['debt-servicing'][
                                          val
                                        ] != undefined &&
                                          item?.['tax_calculation']?.proposed?.['debt-servicing'][
                                            val
                                          ] != 0) ||
                                        (item?.['tax_calculation']?.sensitised?.['debt-servicing'][
                                          val
                                        ] != 0 &&
                                          item?.['tax_calculation']?.sensitised?.['debt-servicing'][
                                            val
                                          ] != undefined)
                                      ) {
                                        isDisplay = true;
                                      }
                                    });
                                  return (
                                    <>
                                      {isDisplay ? (
                                        <tr className="yearsof">
                                          <td className="text-start name">{item.name}</td>
                                          <td className="text-center">
                                            <table className="table mb-0">
                                              <tbody>
                                                <tr className="years_of">
                                                  {personalData?.years?.map((value, index) => {
                                                    return (
                                                      <td key={index}>
                                                        {item?.['tax_calculation']?.['proposed']?.[
                                                          'debt-servicing'
                                                        ][value]
                                                          ? formatNumber(
                                                              item?.['tax_calculation']?.[
                                                                'proposed'
                                                              ]?.['debt-servicing'][value]
                                                            )
                                                          : 0}
                                                      </td>
                                                    );
                                                  })}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                          <td className="text-center">
                                            <table className="table mb-0">
                                              <tbody>
                                                <tr className="years_of sky_opt">
                                                  {personalData?.years?.map((value, index) => {
                                                    return (
                                                      <td key={index} className="skyopt">
                                                        {item?.['tax_calculation']?.[
                                                          'sensitised'
                                                        ]?.['debt-servicing'][value]
                                                          ? formatNumber(
                                                              item?.['tax_calculation']?.[
                                                                'sensitised'
                                                              ]?.['debt-servicing'][value]
                                                            )
                                                          : 0}
                                                      </td>
                                                    );
                                                  })}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })}

                                <tr className="yearsof">
                                  <td className="text-start name">
                                    <strong>Total Funds Available for Debt Servicing</strong>
                                  </td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of">
                                          {personalData?.years && personalData?.years?.length
                                            ? personalData?.years?.map((year) => {
                                                let total = 0;
                                                return (
                                                  <td key={year} className="debtfunds">
                                                    {personalData && personalData?.length > 0
                                                      ? personalData?.map((item) => {
                                                          total += item?.['tax_calculation']?.[
                                                            'proposed'
                                                          ]?.['debt-servicing'][year]
                                                            ? item?.['tax_calculation']?.[
                                                                'proposed'
                                                              ]?.['debt-servicing'][year]
                                                            : 0;
                                                        })
                                                      : 0}
                                                    {formatNumber(total) === '0'
                                                      ? 0
                                                      : formatNumber(total)}
                                                  </td>
                                                );
                                              })
                                            : null}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of sky_opt">
                                          {personalData?.years && personalData?.years?.length
                                            ? personalData?.years?.map((year) => {
                                                let total = 0;
                                                return (
                                                  <td key={year} className="debtfunds skyopt">
                                                    {personalData && personalData?.length > 0
                                                      ? personalData?.map((item) => {
                                                          total += item?.['tax_calculation']?.[
                                                            'sensitised'
                                                          ]?.['debt-servicing'][year]
                                                            ? item?.['tax_calculation']?.[
                                                                'sensitised'
                                                              ]?.['debt-servicing'][year]
                                                            : 0;
                                                        })
                                                      : null}
                                                    {formatNumber(total) === '0'
                                                      ? 0
                                                      : formatNumber(total)}
                                                  </td>
                                                );
                                              })
                                            : null}
                                          {/*<td className="debtfunds skyopt">10,000,000</td>
                                          <td className="debtfunds skyopt">10,000,000</td>
                                          <td className="debtfunds skyopt">10,000,000</td>
                                          <td className="debtfunds skyopt">10,000,000</td>*/}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr className="yearsof">
                                  <td className="text-start name">Debt Servicing Commitments</td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of">
                                          {personalData?.years?.map((index) => {
                                            return (
                                              <td key={index}>
                                                (
                                                {formatNumber(
                                                  personalData?.proposed_debt_servicing_commitments
                                                )}
                                                )
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of sky_opt">
                                          {personalData?.years?.map((index) => {
                                            return (
                                              <td key={index} className="skyopt">
                                                (
                                                {formatNumber(
                                                  personalData?.sensitised_debt_servicing_commitments
                                                )}
                                                )
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr className="yearsof ">
                                  <td className="text-start name">
                                    {`Est. Living Exp: ${personalData?.number_of_adult} Adults ${personalData?.number_of_dependant} Dependents`}
                                  </td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of">
                                          {personalData?.years?.map((index) => {
                                            return (
                                              <td key={index}>
                                                {formatNumber(
                                                  -personalData?.total_estimated_living_expenses
                                                )}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="text-center">
                                    <table className="table mb-0">
                                      <tbody>
                                        <tr className="years_of sky_opt">
                                          {personalData?.years?.map((index) => {
                                            return (
                                              <td key={index} className="skyopt">
                                                {formatNumber(
                                                  -personalData?.total_estimated_living_expenses
                                                )}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr className="sky_opt">
                                  <td className="text-start foot_t rm_mb-0 namepro">
                                    Surplus / (Deficit)
                                  </td>
                                  <td className="rm_mb-0 foot_t fst_child">
                                    <table className="table mb-0">
                                      <tfoot>
                                        {personalData?.years && personalData?.years?.length
                                          ? personalData?.years?.map((year, index) => {
                                              let total = 0;

                                              return (
                                                <td
                                                  key={year}
                                                  className={index == 0 ? 'surplush' : ''}
                                                >
                                                  {personalData && personalData?.length > 0
                                                    ? personalData?.map((item) => {
                                                        total += item?.['tax_calculation']?.[
                                                          'proposed'
                                                        ]?.['debt-servicing'][year]
                                                          ? item?.['tax_calculation']?.[
                                                              'proposed'
                                                            ]?.['debt-servicing'][year]
                                                          : 0;
                                                      })
                                                    : null}

                                                  {formatNumber(
                                                    total -
                                                      personalData?.proposed_debt_servicing_commitments -
                                                      personalData?.total_estimated_living_expenses
                                                  ) === '0'
                                                    ? ''
                                                    : formatNumber(
                                                        total -
                                                          personalData?.proposed_debt_servicing_commitments -
                                                          personalData?.total_estimated_living_expenses
                                                      )}
                                                </td>
                                              );
                                            })
                                          : null}
                                      </tfoot>
                                    </table>
                                  </td>
                                  <td className="rm_mb-0 foot_t">
                                    <table className="table mb-0">
                                      <tfoot>
                                        {personalData?.years && personalData?.years?.length
                                          ? personalData?.years?.map((year) => {
                                              let total = 0;

                                              return (
                                                <td key={year} className="debtfunds skyopt">
                                                  {personalData && personalData?.length > 0
                                                    ? personalData?.map((item) => {
                                                        total += item?.['tax_calculation']?.[
                                                          'sensitised'
                                                        ]?.['debt-servicing'][year]
                                                          ? item?.['tax_calculation']?.[
                                                              'sensitised'
                                                            ]?.['debt-servicing'][year]
                                                          : 0;
                                                      })
                                                    : null}

                                                  {formatNumber(
                                                    total -
                                                      personalData?.sensitised_debt_servicing_commitments -
                                                      personalData?.total_estimated_living_expenses
                                                  ) === '0'
                                                    ? ''
                                                    : formatNumber(
                                                        total -
                                                          personalData?.sensitised_debt_servicing_commitments -
                                                          personalData?.total_estimated_living_expenses
                                                      )}
                                                </td>
                                              );
                                            })
                                          : null}
                                      </tfoot>
                                    </table>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            <table className="table lasttotal">
                              <thead>
                                <tr>
                                  <th className="text-start">Total Surplus / (Deficit)</th>
                                  <th style={{ padding: '0' }} className="nopaddingtotal">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          {businessData?.years && businessData?.years?.length
                                            ? businessData?.years?.map((year) => {
                                                let total = 0;
                                                let final = 0;

                                                return (
                                                  <th key={year}>
                                                    {businessData && businessData?.length > 0
                                                      ? businessData?.map((item) => {
                                                          total += item?.['tax_calculation']?.[
                                                            'proposed'
                                                          ]?.['debt-servicing'][year]
                                                            ? item?.['tax_calculation']?.[
                                                                'proposed'
                                                              ]?.['debt-servicing'][year]
                                                            : 0;
                                                        })
                                                      : ''}
                                                    {personalData && personalData?.length > 0
                                                      ? personalData?.map((item) => {
                                                          final += item?.['tax_calculation']?.[
                                                            'proposed'
                                                          ]?.['debt-servicing'][year]
                                                            ? item?.['tax_calculation']?.[
                                                                'proposed'
                                                              ]?.['debt-servicing'][year]
                                                            : 0;
                                                        })
                                                      : ''}

                                                    {formatNumber(
                                                      total -
                                                        businessData?.proposed_debt_servicing_commitments +
                                                        final -
                                                        (personalData?.proposed_debt_servicing_commitments
                                                          ? personalData?.proposed_debt_servicing_commitments
                                                          : 0) -
                                                        (personalData?.total_estimated_living_expenses
                                                          ? personalData?.total_estimated_living_expenses
                                                          : 0)
                                                        ? total -
                                                            businessData?.proposed_debt_servicing_commitments +
                                                            final -
                                                            (personalData?.proposed_debt_servicing_commitments
                                                              ? personalData?.proposed_debt_servicing_commitments
                                                              : 0) -
                                                            (personalData?.total_estimated_living_expenses
                                                              ? personalData?.total_estimated_living_expenses
                                                              : 0)
                                                        : 0
                                                    )}
                                                  </th>
                                                );
                                              })
                                            : null}
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                  <th style={{ padding: '0;' }} className="nopaddingtotal">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          {businessData?.years && businessData?.years?.length
                                            ? businessData?.years?.map((year) => {
                                                let total = 0;
                                                let final = 0;

                                                return (
                                                  <th key={year}>
                                                    {businessData && businessData?.length > 0
                                                      ? businessData?.map((item) => {
                                                          total += item?.['tax_calculation']?.[
                                                            'sensitised'
                                                          ]?.['debt-servicing'][year]
                                                            ? item?.['tax_calculation']?.[
                                                                'sensitised'
                                                              ]?.['debt-servicing'][year]
                                                            : 0;
                                                        })
                                                      : ''}
                                                    {personalData && personalData?.length > 0
                                                      ? personalData?.map((item) => {
                                                          final += item?.['tax_calculation']?.[
                                                            'sensitised'
                                                          ]?.['debt-servicing'][year]
                                                            ? item?.['tax_calculation']?.[
                                                                'sensitised'
                                                              ]?.['debt-servicing'][year]
                                                            : 0;
                                                        })
                                                      : ''}

                                                    {formatNumber(
                                                      total -
                                                        businessData?.sensitised_debt_servicing_commitments +
                                                        final -
                                                        (personalData?.sensitised_debt_servicing_commitments
                                                          ? personalData?.sensitised_debt_servicing_commitments
                                                          : 0) -
                                                        (personalData?.total_estimated_living_expenses
                                                          ? personalData?.total_estimated_living_expenses
                                                          : 0)
                                                        ? total -
                                                            businessData?.sensitised_debt_servicing_commitments +
                                                            final -
                                                            (personalData?.sensitised_debt_servicing_commitments
                                                              ? personalData?.sensitised_debt_servicing_commitments
                                                              : 0) -
                                                            (personalData?.total_estimated_living_expenses
                                                              ? personalData?.total_estimated_living_expenses
                                                              : 0)
                                                        : 0
                                                    )}
                                                  </th>
                                                );
                                              })
                                            : null}
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
              {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                return (
                  <Form onSubmit={handleSubmit} className="facility-form form-data">
                    <CommentsNotes
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleInputChange={handleInputChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                    <div className="action-box full">
                      <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </main>
      </div>
    </>
  );
}
