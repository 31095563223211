import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MemberModel from '../../../../MemberModel';
import { jobSetupState } from '../../../../../slices/slices/JobSetupSlice';
import { displayNameFromType } from '../../../../../pages/common/Misc';
export default function Button(props) {
  const { Type } = props;
  const [showMemberForm, setshowMemberForm] = useState(false);
  const [options, setOptions] = useState([]);
  const { membersData } = useSelector(jobSetupState);
  const handleMemberForm = () => {
    setshowMemberForm(false);
  };

  useEffect(() => {
    let optArray = [];
    membersData?.data?.data.map((items) => {
      return optArray.push({
        value: items.id,
        label: displayNameFromType(items)
      });
    });
    setOptions(optArray);
  }, [membersData]);

  return (
    <>
      <button
        type="button"
        className="btn btn-new"
        onClick={() => {
          setshowMemberForm(true);
        }}
      >
        <i className="icon-plus"></i>
        {Type == 'Facility' ? 'Security' : 'Facility'}
      </button>

      <MemberModel
        showMemberForm={showMemberForm}
        options={options}
        handleMemberForm={handleMemberForm}
        type={Type == 'Facility' ? 'securities' : 'facilities'}
      />
    </>
  );
}
