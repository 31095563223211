import Label from '../../../../../../components/Label';
import CommentsNotes from '../../../../../../components/fields/CommentsNotes';
import Autocomplete from 'react-google-autocomplete';
import FormActions from '../../../../../../components/FormActions';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, FieldArray } from 'formik';
import Button from '../../../../../../components/Button';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
// import moment from 'moment';
import LabelTooltip from '../../../../../../components/tooltip/LabelTooltip';
import label from '../../../../../../LabelConstant';
import InputMask from 'react-input-mask';
import React, { useEffect } from 'react';
import Select from 'react-select';
import $ from 'jquery';
import ErrorFocus from '../../../../../common/ErrorFocus';
import { formatAddress } from '../../../../../../utils/helper';
import CurrencyInput from 'react-currency-input-field';

const Form = ({
  values,
  errors,
  jobId,
  memberId,
  memberListResponse,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  handleInputChange,
  MemberValue
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    jobId: PropTypes.any,
    memberId: PropTypes.any,
    memberListResponse: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    handleSubmit: PropTypes.any,
    setFieldValue: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    handleInputChange: PropTypes.any,
    MemberValue: PropTypes.object
  };

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  useEffect(() => {
    rowHeight();
  }, []);

  const rowHeight = () => {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 115) {
        $('.stickydiv').addClass('darkHeader');
      } else {
        $('.stickydiv').removeClass('darkHeader');
      }
    });
    setTimeout(function () {
      var height = $('.form-white').height();
      $('.form-white > .rentalview > .row').css('height', height);
    }, 100);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="facility-form form-data mt-30 rental-block">
      <div className="white-card form-white" style={{ paddingTop: 0 }}>
        <div className="rentalview">
          <div className="row">
            <FieldArray
              name="tenant"
              render={(arrayHelpers) => (
                <>
                  <div
                    style={{
                      position: 'sticky',
                      top: '115px',
                      background: '#FFF',
                      float: 'left',
                      zIndex: '1',
                      padding: '30px 30px 0',
                      maxWidth: 'calc(100% + 30px)',
                      width: 'calc(100% + 30px)',
                      margin: '0 -15px'
                    }}
                    className="stickydiv"
                  >
                    <div className="col-md-6" style={{ width: 'calc(50% - 15px)' }}>
                      <table className="table half-table">
                        <thead>
                          <tr>
                            <th className="text-start" style={{ width: '42%' }}>
                              Property Owner
                            </th>
                            <th className="text-start" style={{ width: '40%' }}>
                              Property Address
                            </th>
                            <th className="text-start">
                              <LabelTooltip
                                labelId={label.rental.commercialShadingTip.key}
                                label={label.rental.commercialShadingTip.label}
                                hover_text={label.rental.commercialShadingTip.hover_text}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-start" name="owners" style={{ FontWeight: 'bold' }}>
                              <FieldArray
                                name="owners"
                                render={(arrayHelpers) => (
                                  <div>
                                    <div className="shareholder">
                                      {values?.owners && values?.owners?.length > 0
                                        ? values?.owners?.map((shareholder, index) => {
                                            return (
                                              <div key={index}>
                                                <Select
                                                  className="form-control"
                                                  name={`owners.${index}.member_id`}
                                                  aria-label="Default select example"
                                                  value={
                                                    values?.owners[index]?.member_id?.label &&
                                                    values?.owners[index]?.member_id?.value
                                                      ? values?.owners[index]?.member_id
                                                      : !values?.owners[index]?.member_id?.label &&
                                                        values?.owners[index]?.member_id?.value ===
                                                          memberId
                                                      ? MemberValue
                                                      : ''
                                                  }
                                                  onBlur={handleBlur}
                                                  onChange={(val) => {
                                                    setFieldValue(`owners.${index}.member_id`, val);
                                                    handleInputChange(val);
                                                  }}
                                                  options={newColumnsForAll}
                                                  classNamePrefix="rentalname"
                                                ></Select>
                                                <CurrencyInput
                                                  className="form-control"
                                                  name={`owners.${index}.holding`}
                                                  value={
                                                    values?.owners[index]?.holding > 100
                                                      ? 0
                                                      : values?.owners[index]?.holding
                                                  }
                                                  decimalsLimit={2}
                                                  onValueChange={(value, name) => {
                                                    setFieldValue(name, value);
                                                    handleInputChange(value);
                                                    // handleChange(e);
                                                  }}
                                                  onBlur={handleBlur}
                                                  style={{ width: '20%', textAlign: 'center' }}
                                                  placeholder={'0'}
                                                />
                                                {/* <NumberFormat
                                                  decimalScale={0}
                                                  placeholder={'0'}
                                                  allowNegative={false}
                                                  name={`owners.${index}.holding`}
                                                  value={
                                                    values?.owners[index]?.holding > 100
                                                      ? 0
                                                      : values?.owners[index]?.holding
                                                  }
                                                  className="form-control"
                                                  onChange={(e) => {
                                                    handleInputChange(e.target.value);
                                                    handleChange(e);
                                                  }}
                                                  onBlur={handleBlur}
                                                  style={{ width: '20%', textAlign: 'center' }}
                                                /> */}
                                                {/* <Input
                                              type="number"
                                              name={`owners.${index}.holding`}
                                              value={formatNumber(values?.owners[index]?.holding)}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              className="form-control"
                                              placeholder="0"
                                            /> */}
                                                {index !== 0 && (
                                                  <i
                                                    className="icon-delete"
                                                    onClick={() =>
                                                      index !== 0 && arrayHelpers.remove(index)
                                                    }
                                                  />
                                                )}
                                                <span
                                                  className="error"
                                                  style={{ color: 'red', fontSize: '20px' }}
                                                >
                                                  {errors?.['owners.' + index + '.member_id']
                                                    ? 'This field is required'
                                                    : ''}

                                                  {errors?.['owners.' + index + '.holding']
                                                    ? 'percentage  is required'
                                                    : ''}
                                                </span>
                                              </div>
                                            );
                                          })
                                        : null}
                                    </div>
                                    <span
                                      className="error"
                                      style={{ color: 'red', fontSize: '20px' }}
                                    >
                                      {errors?.owner_holding}
                                    </span>

                                    <div className="add-btn">
                                      <i
                                        className="icon-plus"
                                        onClick={() => {
                                          var isEmpty = false;
                                          for (var c = 0; c < values?.owners?.length; c++) {
                                            if (
                                              !values?.owners[c]?.member_id ||
                                              !values?.owners[c]?.holding
                                            ) {
                                              isEmpty = true;
                                              return;
                                            }
                                          }
                                          if (!isEmpty) {
                                            arrayHelpers.push({
                                              member_id: '',
                                              holding: ''
                                            });
                                          }
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                )}
                              />
                            </td>
                            <td className="text-start">
                              <Autocomplete
                                autoComplete="off"
                                apiKey={process.env.REACT_APP_API_KEY}
                                style={{ width: '100%' }}
                                name="address"
                                placeholder=""
                                className="form-control"
                                value={values?.address}
                                onChange={(val) => {
                                  setFieldValue('address', val?.target?.value);
                                }}
                                onPlaceSelected={(place) => {
                                  let formattedAddress = formatAddress(place);
                                  setFieldValue('address', formattedAddress);
                                  let newAddress = formattedAddress.replace(
                                    [', New Zealand', ', Australia'],
                                    ''
                                  );

                                  handleInputChange(newAddress);
                                }}
                                options={{
                                  types: 'street_address', //street_address
                                  componentRestrictions: { country: ['au', 'nz'] }
                                }}
                              />
                              {/* <Input
                              type="text"
                              name="address"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.address}
                            /> */}
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors?.address}
                              </span>
                            </td>
                            <td>
                              <NumberFormat
                                decimalScale={0}
                                allowNegative={false}
                                name="shading"
                                className="form-control pac-target-input"
                                onChange={(e) => {
                                  handleInputChange(e.target.value);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values?.shading ? values?.shading : ''}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors?.shading}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 text-end" style={{ width: 'calc(50% + 15px)' }}>
                      <Button
                        className="button green"
                        style={{ height: '55px', width: '156px' }}
                        type="button"
                        onClick={() => {
                          arrayHelpers.push({
                            tenant_name: '',
                            tenant_activity: '',
                            lease_start_date: '',
                            lease_end_date: '',
                            option: '',
                            rental_bond: '',
                            current_rental_income_pa: '',
                            shading: '',
                            annual_rent_review: ''
                          });
                          rowHeight();
                        }}
                      >
                        <i className="icon-plus"></i> Tenant
                      </Button>
                    </div>
                  </div>
                  {values.tenant && values.tenant.length > 0
                    ? values.tenant.map((item, index) => (
                        <>
                          <div className="col-md-6 tenantarea">
                            <div className="group_areaof row">
                              <div className="col-6">
                                <Label>Tenant Name</Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={`tenant.${index}.tenant_name`}
                                  value={
                                    values?.tenant[index]?.tenant_name
                                      ? values?.tenant[index]?.tenant_name
                                      : ''
                                  }
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleInputChange(e.target.value);
                                    handleChange(e);
                                  }}
                                  required
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`tenant.${index}.tenant_name`} />
                                </span>
                              </div>
                              <div className="col-6">
                                <Label>Tenant Activity</Label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={`tenant.${index}.tenant_activity`}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleInputChange(e.target.value);
                                    handleChange(e);
                                  }}
                                  value={
                                    values?.tenant[index]?.tenant_activity
                                      ? values?.tenant[index]?.tenant_activity
                                      : ''
                                  }
                                />
                                {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`tenant.${index}.tenant_activity`} />
                                </span> */}
                              </div>
                              <div className="col-6">
                                <Label>Lease Start</Label>
                                <DatePicker
                                  selected={
                                    values?.tenant[index]?.lease_start_date
                                      ? new Date(values?.tenant[index]?.lease_start_date)
                                      : null
                                  }
                                  onChange={(val) => {
                                    setFieldValue(`tenant.${index}.lease_start_date`, val);
                                    handleInputChange(val);
                                  }}
                                  customInput={
                                    <InputMask
                                      className="form-control"
                                      mask="99-99-9999"
                                      maskChar={null}
                                    />
                                  }
                                  name={`tenant.${index}.lease_start_date`}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                />
                              </div>
                              <div className="col-6">
                                <Label>Lease End</Label>
                                <DatePicker
                                  selected={
                                    values?.tenant[index]?.lease_end_date
                                      ? new Date(values?.tenant[index]?.lease_end_date)
                                      : null
                                  }
                                  onChange={(val) => {
                                    setFieldValue(`tenant.${index}.lease_end_date`, val);
                                    handleInputChange(val);
                                  }}
                                  customInput={
                                    <InputMask
                                      className="form-control"
                                      mask="99-99-9999"
                                      maskChar={null}
                                    />
                                  }
                                  name={`tenant.${index}.lease_end_date`}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                />
                                {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`tenant.${index}.lease_end_date`} />
                                  {values?.tenant[index]?.lease_start_date
                                    ? moment(values?.tenant[index]?.lease_end_date).format(
                                        'YYYY-MM-DD'
                                      ) ===
                                      moment(values?.tenant[index]?.lease_start_date).format(
                                        'YYYY-MM-DD'
                                      )
                                      ? 'Lease End must be grater than Lease Start.'
                                      : null
                                    : null}
                                </span> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 incomearea">
                            <div className="group_areaof row" style={{ position: 'relative' }}>
                              <div className="row" style={{ position: 'absolute', right: 0 }}>
                                <a>
                                  {index !== 0 && (
                                    <i
                                      className="fa fa-trash-alt"
                                      style={{ float: 'right' }}
                                      onClick={() => index !== 0 && arrayHelpers.remove(index)}
                                    ></i>
                                  )}
                                </a>
                              </div>
                              <div className="col-6">
                                <div
                                  className=""
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div className="col-5">
                                    <LabelTooltip
                                      labelId={label.rental.optionTip.key}
                                      label={label.rental.optionTip.label}
                                      hover_text={label.rental.optionTip.hover_text}
                                    />
                                    <NumberFormat
                                      style={{ color: '#7c8484' }}
                                      decimalScale={0}
                                      allowNegative={false}
                                      name={`tenant.${index}.option1`}
                                      value={
                                        values?.tenant[index]?.option1
                                          ? values?.tenant[index]?.option1
                                          : ''
                                      }
                                      className="form-control"
                                      onChange={(e) => {
                                        handleInputChange(e.target.value);
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      maxLength="1"
                                    />
                                  </div>
                                  <span
                                    style={{
                                      margin: '30px 10px 0',
                                      fontFamily: 'Swis721 BT Bold',
                                      color: '#000'
                                    }}
                                  >
                                    X
                                  </span>
                                  <div className="col-5">
                                    <Label>Years</Label>
                                    <NumberFormat
                                      style={{ color: '#7c8484' }}
                                      decimalScale={0}
                                      allowNegative={false}
                                      name={`tenant.${index}.option2`}
                                      value={
                                        values?.tenant[index]?.option2
                                          ? values?.tenant[index]?.option2
                                          : ''
                                      }
                                      className="form-control"
                                      onChange={(e) => {
                                        handleInputChange(e.target.value);
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      maxLength="2"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <Label>Rental Bond</Label>
                                <NumberFormat
                                  decimalScale={0}
                                  allowNegative={false}
                                  name={`tenant.${index}.rental_bond`}
                                  value={
                                    values?.tenant[index]?.rental_bond
                                      ? values?.tenant[index]?.rental_bond
                                      : ''
                                  }
                                  className="form-control"
                                  thousandSeparator={true}
                                  onChange={(e) => {
                                    handleInputChange(e.target.value);
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                              <div className="col-6">
                                <LabelTooltip
                                  labelId={label.rental.currentRentalTip.key}
                                  label={label.rental.currentRentalTip.label}
                                  hover_text={label.rental.currentRentalTip.hover_text}
                                />
                                <NumberFormat
                                  decimalScale={0}
                                  allowNegative={false}
                                  name={`tenant.${index}.current_rental_income_pa`}
                                  value={
                                    values?.tenant[index]?.current_rental_income_pa
                                      ? values?.tenant[index]?.current_rental_income_pa
                                      : ''
                                  }
                                  className="form-control"
                                  thousandSeparator={true}
                                  onChange={(e) => {
                                    handleInputChange(e.target.value);
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                                {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`tenant.${index}.current_rental_income_pa`} />
                                </span> */}
                              </div>

                              <div className="col-6">
                                <Label>Annual Rent Review</Label>
                                <select
                                  className="form-select"
                                  name={`tenant.${index}.annual_rent_review`}
                                  aria-label="Default select example"
                                  value={
                                    values.tenant[index].annual_rent_review
                                      ? values.tenant[index].annual_rent_review
                                      : ''
                                  }
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleInputChange(e.target.value);
                                    handleChange(e);
                                  }}
                                >
                                  <option value={null}>Select</option>
                                  <option value="cpi">CPI</option>
                                  <option value="fixed">Fixed %</option>
                                  <option value="market">Market</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <ErrorFocus />
                        </>
                      ))
                    : null}
                  <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                    {errors?.anticipated_settlement_date}
                  </span>
                </>
              )}
            />
          </div>
        </div>
      </div>
      <CommentsNotes
        values={values ? values : ''}
        errors={errors}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <FormActions
        cancel={'/job/' + jobId + '/data-entry/' + memberId + '/rentals'}
        values={values}
      />
      <ErrorFocus />
    </form>
  );
};

export default Form;
