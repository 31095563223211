import React, { useState } from 'react';
import TitleDropDown from '../groupAssessment/TitleDropDown';
import Heading from './Heading';
import Title from './Title';
import PieSegmentModel from '../../PieSegmentModel';

export default function PieSegment(props) {
  const { subItem, heading, itemData, setItemData, mainIndex, handleInputChange } = props;

  const [showModal, setshowModal] = useState(false);

  const onHandleCreate = () => {
    // setseletcedIndex(index);
    setshowModal(true);
  };
  const onHandleDelete = (index) => {
    let temp = itemData;
    temp[mainIndex].subcategory.splice(index, 1);
    setItemData(JSON.parse(JSON.stringify(temp)));
  };
  const onhandleClose = () => {
    setshowModal(false);
  };

  const handleFormSubmit = (value) => {
    handleInputChange(value);
    value.value = 1;
    value.is_external = 1;
    let temp = itemData;
    temp[mainIndex].subcategory.push(value);
    setItemData(JSON.parse(JSON.stringify(temp)));
    setshowModal(false);
  };

  return (
    <>
      <div className="mag_areaof ">
        <div className="mag_titlepro d-flex justify-content-start align-items-center">
          <Heading heading={heading} />
        </div>
        {subItem.map((titleData, index) => {
          return (
            <div
              key={index}
              className="infosys mag_listof d-flex justify-content-between align-items-center"
            >
              <Title title={titleData.name} />
              <TitleDropDown
                option={titleData.options}
                color={titleData.value}
                itemData={itemData}
                setItemData={setItemData}
                mainIndex={mainIndex}
                subIndex={index}
                handleInputChange={handleInputChange}
              />
              {subItem.length - 1 == index && (
                <a onClick={() => onHandleCreate(index)} className="addlist">
                  <i className="icon-plus"></i>
                </a>
              )}
              {titleData.is_external == 1 && (
                <a
                  onClick={(e) => {
                    handleInputChange(e.target.value);
                    onHandleDelete(index);
                  }}
                  className="deletelist"
                >
                  <i className="icon-delete"></i>
                </a>
              )}
            </div>
          );
        })}
      </div>
      <PieSegmentModel
        show={showModal}
        mainIndex={mainIndex}
        setItemData={setItemData}
        itemData={itemData}
        close={onhandleClose}
        onClickSubmit={handleFormSubmit}
        handleInputChange={handleInputChange}
      />
    </>
  );
}
