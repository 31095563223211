import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const unencumberedPropertyInitialState = {
  loading: false,
  memberDataResponse: {},
  unencumberedPropertyListDataResponse: {},
  addUnencumberedPropertyResponse: {},
  deletePropertyDataResponse: {},
  updateUnencumberedPropertyResponse: {},
  unencumberedPropertyDataResponse: {},
  editUnencumberedPropertyResponse: {},
  totalUnencumberedPropertyListDataResponse: {},
  totalUpdateUnencumberedPropertyResponse: {}
};

export const UnencumberedPropertySlice = createSlice({
  name: 'unencumberedProperty',
  initialState: unencumberedPropertyInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setMemberDataResponse: (state, action) => {
      state.memberDataResponse = action.payload.data;
      state.loader = false;
    },
    setUnencumberedPropertyListDataResponse: (state, action) => {
      state.unencumberedPropertyListDataResponse = action.payload.data;
      state.loader = false;
    },
    setUnencumberedPropertyResponse: (state, action) => {
      state.addUnencumberedPropertyResponse = action.payload;
      state.loader = false;
    },
    setDeletePropertyDataResponse: (state, action) => {
      state.deletePropertyDataResponse = action.payload;
      state.loader = false;
    },
    setUpdatedUnencumberedPropertyResponse: (state, action) => {
      state.updateUnencumberedPropertyResponse = action.payload;
      state.loader = false;
    },
    setUnencumberedPropertyDataResponse: (state, action) => {
      state.unencumberedPropertyDataResponse = action.payload;
      state.loader = false;
    },
    setEditUnencumberedPropertyResponse: (state, action) => {
      state.editUnencumberedPropertyResponse = action.payload;
      state.loader = false;
    },
    setTotalUnencumberedPropertyListDataResponse: (state, action) => {
      state.totalUnencumberedPropertyListDataResponse = action.payload;
      state.loader = false;
    },
    setUpdatedTotalUnencumberedPropertyResponse: (state, action) => {
      state.totalUpdateUnencumberedPropertyResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getMemberData = (id, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setMemberDataResponse(response.data));
  }
};

export const getUnencumberedPropertyListData = (id, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId + '/unencumbered-properties?limit=500',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUnencumberedPropertyListDataResponse(response.data));
  }
};

export const addUnencumberedProperty = (id, memberId, data, setErrors) => async (dispatch) => {
  // data.asset_owner = memberId;
  const response = await clientService.post(
    'jobs/' + id + '/members/' + memberId + '/unencumbered-properties',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUnencumberedPropertyResponse(response.data));
  }
};

export const deleteProperty = (jobId, memberId, propertyId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/members/' + memberId + '/unencumbered-properties/' + propertyId,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeletePropertyDataResponse(response.data));
  }
};

export const updateUnencumberedProperty = (id, memberId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/members/' + memberId + '/unencumbered-properties',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedUnencumberedPropertyResponse(response.data));
  }
};

export const getUnencumberedPropertyData =
  (id, memberId, propertyId, setErrors) => async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/members/' + memberId + '/unencumbered-properties/' + propertyId,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setUnencumberedPropertyDataResponse(response.data));
    }
  };

export const editUnencumberedProperty =
  (id, memberId, propertyId, data, setErrors) => async (dispatch) => {
    const response = await clientService.put(
      'jobs/' + id + '/members/' + memberId + '/unencumbered-properties/' + propertyId,
      data,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setEditUnencumberedPropertyResponse(response.data));
    }
  };

export const getTotalUnencumberedPropertyListData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/group-analysis/unencumbered-property?limit=500',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setTotalUnencumberedPropertyListDataResponse(response.data));
  }
};

export const updateTotalUnencumberedProperty = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/group-analysis/unencumbered-property',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedTotalUnencumberedPropertyResponse(response.data));
  }
};

export const {
  setLoader,
  setMemberDataResponse,
  setUnencumberedPropertyListDataResponse,
  setUnencumberedPropertyResponse,
  setDeletePropertyDataResponse,
  setUpdatedUnencumberedPropertyResponse,
  setUnencumberedPropertyDataResponse,
  setEditUnencumberedPropertyResponse,
  setTotalUnencumberedPropertyListDataResponse,
  setUpdatedTotalUnencumberedPropertyResponse
} = UnencumberedPropertySlice.actions;

export const unencumberedPropertyState = (state) => {
  return state.unencumberedProperty;
};

export default UnencumberedPropertySlice.reducer;
