import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import Tableinfo from '../../../../components/job/groupAnalysis/debtServicing/Tableinfo';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import FormActions from '../../../../components/FormActions';
import {
  displayOwnerNameFromType,
  formatNumber,
  roundOff,
  unformatNumber
  // sumOfNumbers
} from '../../../../pages/common/Misc';
import Proposed from '../../../../components/job/groupAnalysis/debtServicing/Proposed';
import { commonState, getConfig } from '../../../../slices/slices/CommonSlice';
import moment from 'moment';
import {
  getproposedDebtServicing,
  updateComment,
  proposedDebtServicingState
} from '../../../../slices/slices/ProposedDebtServicingSlice';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

export default function ProposedDebtServicing() {
  const { configList } = useSelector(commonState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [internalNotes, setInternalNotes] = useState();
  const [reportComments, setReportComments] = useState();
  // const [proposedservice, setProposedService] = useState([]);
  const [personaltotalValue, setPersonalTotal] = useState({});
  const [businesstotalValue, setBusinessTotal] = useState({});
  const [personalData, setPersonalData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  // const getProposedDebtServicingResponse = useSelector(proposedDebtServicingState);
  const { proposeddebtservicingList } = useSelector(proposedDebtServicingState);
  const [record_type, setRecordtype] = useState();
  // let proposedDebtArray = {
  //   business: [],
  //   personal: []
  // };

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    setInternalNotes(proposeddebtservicingList?.additional_info?.internal_notes);
  }, [proposeddebtservicingList]);

  useEffect(() => {
    setReportComments(proposeddebtservicingList?.additional_info?.report_comments);
  }, [proposeddebtservicingList]);

  const initialValue = {
    report_comments:
      reportComments ||
      'For all facilities the interest rate adopted is either actual or where the interest rate is unknown, a best estimate of the likely rate',
    internal_notes: internalNotes || '',
    is_completed: 1
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    if (record_type) {
      values.is_refresh = 1;
      values.record_type = record_type;
    }
    dispatch(updateComment(jobId, values, setErrors)).then(() => {
      dispatch(getproposedDebtServicing(jobId));
      setRecordtype('');
    });
  };

  useEffect(() => {
    dispatch(getproposedDebtServicing(jobId));
  }, []);

  useEffect(() => {
    let businesstotal = {
      limit: 0,
      balloon: 0,
      repaytype: 0,
      interestpa: 0,
      repaypa: 0
    };
    let personaltotal = {
      limit: 0,
      balloon: 0,
      repaytype: 0,
      interestpa: 0,
      repaypa: 0
    };

    let serviceList = JSON.parse(JSON.stringify(proposeddebtservicingList));

    let businessRecord = [];
    serviceList?.data?.business?.length > 0 &&
      serviceList.data.business.map((item) => {
        businesstotal.limit +=
          //Math.round(unformatNumber(item.existing_limit));
          roundOff(unformatNumber(item.existing_limit));
        businesstotal.balloon +=
          //Math.round(unformatNumber(item.balloon));
          roundOff(unformatNumber(item.balloon));
        businesstotal.repaytype +=
          //Math.round(unformatNumber(item.repayment_amount));
          roundOff(unformatNumber(item.repayment_amount));
        let matched = businessRecord.findIndex(
          (el) => el.id == item.facility_owners.map((el) => el.id).join('|')
        );
        // let memberList =
        //   item?.facility_owners?.length > 0
        //     ? item.facility_owners.filter((el) => {
        //         if (el.type != 'individual') return el;
        //       })
        //     : 0;
        // let interest_pa =
        //   memberList?.length > 0
        //     ? sumOfNumbers(
        //         memberList.map((el) => {
        //           if (el?.interest_pa) return el.interest_pa;
        //           return 0;
        //         })
        //       )
        //     : 0;
        // let repayment_pa =
        //   memberList.length > 0
        //     ? sumOfNumbers(
        //         memberList.map((el) => {
        //           if (el?.repayment_pa) return el.repayment_pa;
        //           return 0;
        //         })
        //       )
        //     : 0;
        businesstotal.interestpa +=
          //Math.round(unformatNumber(item.interest_pa));
          roundOff(unformatNumber(item.interest_pa));
        businesstotal.repaypa +=
          //Math.round(unformatNumber(item.repayment_pa));
          roundOff(unformatNumber(item.repayment_pa));
        // item.interest_pa = interest_pa;
        // item.repayment_pa = repayment_pa;
        item.member_id = item?.facility_owners?.[0]?.id ? item?.facility_owners?.[0]?.id : '';
        item.frequency =
          item.frequency == '52'
            ? 'W'
            : item.frequency == 26
            ? 'F'
            : item.frequency == 12
            ? 'M'
            : item.frequency == 4
            ? 'Q'
            : item.frequency == 2
            ? 'H'
            : item.frequency == 1
            ? 'A'
            : '';
        item.existing_limit = item.existing_limit == 0 ? '' : item.existing_limit;

        item.repayment_type = item?.repayment_type
          ? Object.keys(configList).length > 0 &&
            configList['facilities_repayment_types'].find((el) => el.key == item?.repayment_type)
              ?.value
          : '';
        item.interest_rate_pa =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.interest_rate_pa;
        item.term_remaining_months =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.term_remaining_months;
        item.balloon =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.balloon;
        item.interest_pa =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.interest_pa;
        item.facility_expiry_date =
          item?.product_facility !== 'credit-card' &&
          item?.product_facility !== 'store-card' &&
          item?.facility_expiry_date !== null &&
          item?.facility_expiry_date !== undefined
            ? moment(item?.facility_expiry_date, 'YYYY-MM-DD', true).format('DD/MM/YY')
            : '';
        item.product_facility = item?.product_facility
          ? Object.keys(configList).length > 0 &&
            configList['facilities_products'].find((el) => el.key == item?.product_facility)?.value
          : '';
        item.lender =
          item.facility_status == 'new'
            ? ''
            : // : item.facility_status == 'refinance'
              // ? ' '
              Object.keys(configList).length > 0 &&
              configList['facilities_lenders'].find((el) => el.key == item?.lender)?.value;
        item.edit = '';
        // item.key_star_1 = item?.interest_rate_identifier.charAt(0).toUpperCase();
        // item.key_star_2 = item?.repayment_term_identifier.charAt(0).toUpperCase();
        if (matched > -1) {
          businessRecord[matched].facilities.push(item);
        } else {
          businessRecord.push({
            id: item.facility_owners.map((el) => el.id).join('|'),
            name: displayOwnerNameFromType(item.facility_owners),
            facilities: [item]
          });
        }
      });

    let personalRecord = [];
    serviceList?.data?.individual?.length > 0 &&
      serviceList.data.individual.map((item) => {
        personaltotal.limit +=
          // Math.round(unformatNumber(item.existing_limit));
          roundOff(unformatNumber(item.existing_limit));
        personaltotal.balloon +=
          // Math.round(unformatNumber(item.balloon));
          roundOff(unformatNumber(item.balloon));
        personaltotal.repaytype +=
          // Math.round(unformatNumber(item.repayment_amount));
          roundOff(unformatNumber(item.repayment_amount));

        let matched = personalRecord.findIndex(
          (el) => el.id == item.facility_owners.map((el) => el.id).join('|')
        );
        // let memberList =
        //   item?.facility_owners.length > 0
        //     ? item.facility_owners.filter((el) => {
        //         if (el.type == 'individual') return el;
        //       })
        //     : [];
        // let interest_pa =
        //   memberList.length > 0
        //     ? sumOfNumbers(
        //         memberList.map((el) => {
        //           if (el?.interest_pa) return el.interest_pa;
        //           return 0;
        //         })
        //       )
        //     : 0;
        // let repayment_pa =
        //   memberList.length > 0
        //     ? sumOfNumbers(
        //         memberList.map((el) => {
        //           if (el?.repayment_pa) return el.repayment_pa;
        //           return 0;
        //         })
        //       )
        //     : 0;
        personaltotal.interestpa +=
          // Math.round(unformatNumber(item.interest_pa));
          roundOff(unformatNumber(item.interest_pa));
        personaltotal.repaypa +=
          // Math.round(unformatNumber(item.repayment_pa));
          roundOff(unformatNumber(item.repayment_pa));
        // item.interest_pa = interest_pa;
        // item.repayment_pa = repayment_pa;
        item.member_id = item?.facility_owners?.[0]?.id ? item?.facility_owners?.[0]?.id : '';
        item.frequency =
          item.frequency == '52'
            ? 'W'
            : item.frequency == 26
            ? 'F'
            : item.frequency == 12
            ? 'M'
            : item.frequency == 4
            ? 'Q'
            : item.frequency == 2
            ? 'H'
            : item.frequency == 1
            ? 'A'
            : '';
        item.repayment_type = item?.repayment_type
          ? Object.keys(configList).length > 0 &&
            configList['facilities_repayment_types'].find((el) => el.key == item?.repayment_type)
              ?.value
          : '';
        item.interest_rate_pa =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.interest_rate_pa;
        item.term_remaining_months =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.term_remaining_months;
        item.balloon =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.balloon;
        item.interest_pa =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.interest_pa;
        item.facility_expiry_date =
          item?.product_facility !== 'credit-card' &&
          item?.product_facility !== 'store-card' &&
          item?.facility_expiry_date !== null &&
          item?.facility_expiry_date !== undefined
            ? moment(item?.facility_expiry_date, 'YYYY-MM-DD', true).format('DD/MM/YY')
            : '';
        item.product_facility = item?.product_facility
          ? Object.keys(configList).length > 0 &&
            configList['facilities_products'].find((el) => el.key == item?.product_facility)?.value
          : '';
        item.lender =
          item.facility_status == 'new'
            ? ''
            : item.facility_status == 'refinance'
            ? ' '
            : Object.keys(configList).length > 0 &&
              configList['facilities_lenders'].find((el) => el.key == item?.lender)?.value;
        item.interest_pa =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.interest_pa;
        item.edit = '';
        // item.key_star_1 = item?.interest_rate_identifier
        //   ? item?.interest_rate_identifier.charAt(0).toUpperCase()
        //   : '';
        // item.key_star_2 = item?.repayment_term_identifier
        //   ? item?.repayment_term_identifier.charAt(0).toUpperCase()
        //   : '';

        if (matched > -1) {
          personalRecord[matched].facilities.push(item);
        } else {
          personalRecord.push({
            id: item.facility_owners.map((el) => el.id).join('|'),
            name: displayOwnerNameFromType(item.facility_owners),
            facilities: [item]
          });
        }
      });

    setBusinessTotal(businesstotal);
    setPersonalTotal(personaltotal);
    setBusinessData(businessRecord);
    setPersonalData(personalRecord);
  }, [proposeddebtservicingList]);

  useEffect(() => {
    dispatch(getConfig(configList));
  }, []);

  const headData = [
    {
      key: 'purpose',
      headClassName: 'skyblue text-start',
      className: 'text-start purposename',
      title: 'Business',
      colSpan: 14
    },
    {
      key: 'repayment_type',
      className: 'text-center rt',
      colSpan: 0,
      title: 'Repay Type',
      style: { width: '116px', padding: '0' }
    },
    {
      key: 'product_facility',
      className: 'text-center fp',
      title: 'Facility / Product',
      style: { width: '110px' }
    },
    {
      key: 'lender',
      className: 'text-center ldr',
      title: 'Existing Lender',
      style: { width: '90px' }
    },
    {
      key: 'existing_limit',
      className: 'text-center lmt',
      title: 'Limit',
      style: { width: '89px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'interest_rate_pa',
      className: 'text-center irpa',
      title: 'Interest Rate p.a.',
      style: { width: '100px' },
      // render: (item) => parseFloat(item).toFixed(2)
      render: (item, record) => {
        if (record.product_facility == 'Credit Card' || record.product_facility == 'Store Card') {
          return '';
        } else {
          if (record.facility_fee !== null)
            return parseFloat(item + record.facility_fee).toFixed(2);
          else return parseFloat(item).toFixed(2);
        }
      }
      // render: (item) => formatNumber(item)
    },
    // {
    //   key: 'key_star_1',
    //   className: 'text-center start',
    //   title: '*',
    //   style: { width: '53px' }
    //   // render: (item) => formatNumber(item)
    // },
    {
      key: 'term_remaining_months',
      className: 'text-center tm',
      colSpan: 0,
      title: 'Terms Mths',
      style: { width: '60px' }
      // render: (item) => formatNumber(item)
    },
    // {
    //   key: 'key_star_2',
    //   className: 'text-center start',
    //   title: '*',
    //   style: { width: '53px' }
    //   // render: (item) => formatNumber(item)
    // },
    {
      key: 'balloon',
      className: 'text-center bkn',
      title: 'Balloon',
      style: { width: '89px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'facility_expiry_date',
      className: 'text-center le',
      title: 'Facility Expiry',
      style: { width: '93px' }
      // render: (item) => formatNumber(item)
    },
    {
      key: 'repayment_amount',
      className: 'text-center rm',
      title: 'Repay Amt',
      style: { width: '80px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'frequency',
      className: 'text-center Frq',
      title: 'Freq',
      style: { width: '60px' }
      // render: (item) => formatNumber(item)
    },
    {
      key: 'interest_pa',
      className: 'text-center ipa',
      title: 'Interest p.a.',
      style: { width: '76px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'repayment_pa',
      className: 'text-center rplpa',
      title: 'Repay p.a.',
      style: { width: '60px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'edit',
      className: '',
      title: '',
      style: { width: '60px' },
      render: (item, record) => {
        return (
          <a
            className="edit"
            onClick={() => {
              navigate(
                `/job/${jobId}/data-entry/${record.member_id}/facilities/${record.facility_id}`
              );
            }}
          >
            <i className="icon-edit"></i>
          </a>
        );
      }
    }
  ];

  const getMainHeadData = () => {
    return [
      {
        key: 'purpose',
        className: 'text-start purposename',
        headClassName: 'text-start purposename',
        title: 'Purpose',
        style: { width: '17%' }
      },
      ...headData.slice(1, headData.length)
    ];
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <main id="main" className="main">
        <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard/'}>Dashboard</Link>
              </li>
              <li>
                <a href="#">Group Analysis</a>
              </li>
              <li className="breadcrumb-item active">
                <strong>Proposed Debt Servicing Calculations</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="pagetitle groupanalysispro">
          <Tableinfo title="Proposed Debt Servicing Calculations" />
        </div>
        <div className="facility-form form-data">
          <div className="white-card">
            <div className="rentalview">
              <div className="row justify-content-start">
                <div className="col-12">
                  <Proposed
                    mainheadData={getMainHeadData()}
                    headData={headData}
                    tbodyData={businessData}
                    pbodyData={personalData}
                    handleInputChange={handleInputChange}
                    businesstotalValue={businesstotalValue}
                    personaltotalValue={personaltotalValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
              {({
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                resetForm
              }) => {
                return (
                  <Form onSubmit={handleSubmit} className="facility-form form-data">
                    <CommentsNotes
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleInputChange={handleInputChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      isRefresh={true}
                      setRecordtype={setRecordtype}
                      resetForm={resetForm}
                    />
                    <div className="action-box full">
                      <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </main>
    </div>
  );
}
