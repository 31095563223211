import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const corporateStructureInitialState = {
  loading: false,
  corporateStructureDataResponse: {},
  editUnencumberedPropertyResponse: {}
};

export const CorporateStructureSlice = createSlice({
  name: 'corporateStructure',
  initialState: corporateStructureInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setUpdatedCorporateStructureResponse: (state, action) => {
      state.updateCorporateStructureResponse = action.payload;
      state.loader = false;
    },
    setCorporateStructureDataResponse: (state, action) => {
      state.corporateStructureDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const updateCorporateStructure = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/business-assessment/corporate-structure',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedCorporateStructureResponse(response.data));
  }
};

export const getCorporateStructureData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/business-assessment/corporate-structure',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setCorporateStructureDataResponse(response.data));
  }
};

export const {
  setLoader,
  setUpdatedCorporateStructureResponse,
  setCorporateStructureDataResponse
} = CorporateStructureSlice.actions;

export const corporateStructureState = (state) => {
  return state.corporateStructure;
};

export default CorporateStructureSlice.reducer;
