import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Label from './Label';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Input from './Input';
import { addStaffSchema } from '../pages/common/Validation';
import {
  staffState,
  addStaff,
  getroleData,
  updateUserDetails
} from '../slices/slices/StaffManagementSlice';

export default function AddStaffModal(props) {
  const { isopen, setOpen, handleSubmit, type, userData, getUserDetails } = props;
  const dispatch = useDispatch();
  const [role, setRole] = useState([]);
  const { getRoleDataResponse } = useSelector(staffState);

  const handleCancel = () => {
    setOpen(false);
  };

  //   const onhandleClick = () => {
  //     setOpen(true);
  //   };

  useEffect(() => {
    setRole(getRoleDataResponse);
  }, [getRoleDataResponse]);

  useEffect(() => {
    dispatch(getroleData());
  }, []);

  const handleAddstaff = (values, { setErrors }) => {
    if (type == true) {
      dispatch(addStaff(values, setErrors)).then((res) => {
        if (res) {
          setOpen(false);
          handleSubmit();
        }
      });
    } else {
      dispatch(updateUserDetails(userData?.id, values, setErrors)).then((res) => {
        if (res) {
          setOpen(false);
          getUserDetails();
        }
      });
    }
  };

  return (
    <>
      <Modal
        show={isopen}
        onHide={handleCancel}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>{type ? 'ADD' : 'EDIT'} STAFF MEMBER</h2>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              first_name: type != true ? userData?.name : '',
              last_name: type != true ? userData?.surname : '',
              email: type != true ? userData?.email : '',
              phone: type != true ? userData?.phone : '',
              user_role_id: type != true ? userData?.user_role_id : ''
            }}
            validationSchema={addStaffSchema}
            onSubmit={handleAddstaff}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} noValidate>
                <fieldset>
                  <div className="field-box">
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      name="first_name"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.first_name}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      name="last_name"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.last_name}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>Email Address</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.email}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>Phone Number</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      name="phone"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.phone}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>Role</Label>
                    <select
                      className="form-select"
                      name="user_role_id"
                      onChange={(e) => {
                        setFieldValue('user_role_id', e.target.value);
                      }}
                      onBlur={handleBlur}
                      aria-label="Default select example"
                      value={values.user_role_id}
                    >
                      <option>Select Role Type</option>
                      {role.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item.id}
                            selected={values?.user_role_id == item.id ? 'selected' : ''}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {item.name.replace('-', ' ')}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                    {errors.user_role_id}
                  </span>
                </fieldset>
                <div className="modal-action modal-footer" style={{ padding: '20px 0' }}>
                  <Button type="button" className="btn btn-secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" className="btn btn-primary">
                    {type ? 'Create' : 'Update'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
