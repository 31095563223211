import React, { useEffect, useState } from 'react';
import FormActions from '../../../../components/FormActions';
import { useParams } from 'react-router-dom';
import GroupTable from './Grouptable/GroupTable';
import FSTable from './FStable/FSTable';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import {
  ProposedTransactionState,
  fsgroupList,
  securityGroupList,
  getGroupList,
  deleteReliance,
  updateComment
} from '../../../../slices/slices/ProposedFacilitiesSecuritySlice';
import { useDispatch, useSelector } from 'react-redux';

export default function ProposedFacilitiesTab(props) {
  let { activeTab, handleOnclick, handleInputChange, setCanShowDialogLeavingPage } = props;
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const [groupComment, setGroupComment] = useState([]);
  // const [tableGroupData, setTableGroupData] = useState({
  //   table1: [],
  //   table2: [],
  //   existinglimit: 0,
  //   proposedlimit: 0,
  //   marketvalue: 0,
  //   landingvalue: 0,
  //   finaltotal: 0
  // });
  const facilitiesGroupData = useSelector(ProposedTransactionState);

  useEffect(() => {
    if (jobId) dispatch(fsgroupList({ jobId }));
    dispatch(securityGroupList({ jobId }));
    dispatch(getGroupList({ jobId }));
  }, []);

  useEffect(() => {
    let commentarray = [];

    // let tableData = [];

    if (
      facilitiesGroupData?.groupList?.data?.data &&
      facilitiesGroupData?.groupList?.data?.data.length > 0
    ) {
      facilitiesGroupData?.groupList?.data?.data.map((item) => {
        commentarray.push({
          id: item.id,
          report_comments: item?.additional_info?.report_comments
            ? item?.additional_info?.report_comments
            : getRepocomment(item),
          internal_notes: item?.additional_info?.internal_notes
            ? item?.additional_info?.internal_notes
            : getIncomment(item)
        });

        // tableData.push(obj);
      });
      // setGroupComment();
      // setTableGroupData([...tableData]);
      setGroupComment([...commentarray]);
    }
  }, [facilitiesGroupData]);

  const getRepocomment = (item) => {
    if (
      item?.additional_info?.report_comments == null &&
      item?.additional_info?.internal_notes == null
    ) {
      let commentName;
      commentName = '';
      item?.group_data?.facilities?.map((items) => {
        if (items?.additional_info?.report_comments) {
          if (items?.additional_info?.report_comments.length > 0) {
            commentName +=
              '<p></p>' +
              items.purpose +
              ' : ' +
              items?.additional_info?.report_comments +
              '<p></p>';
          }
        }
      });
      return commentName;
    }
  };

  const getIncomment = (item) => {
    if (
      item?.additional_info?.report_comments == null &&
      item?.additional_info?.internal_notes == null
    ) {
      let commentName;
      commentName = '';
      item?.group_data?.facilities?.map((items) => {
        if (items?.additional_info?.internal_notes) {
          if (items?.additional_info?.internal_notes?.length > 0) {
            commentName +=
              '<p></p>' +
              items.purpose +
              ' : ' +
              items?.additional_info?.internal_notes +
              '<p></p>';
          }
        }
      });

      return commentName;
    }
  };

  const getsecuritycomment = () => {
    if (facilitiesGroupData?.securityList?.data) {
      return facilitiesGroupData?.securityList?.data.map((item) => {
        return {
          id: item.id,
          internal_notes: item?.proposed_security?.additional_info?.internal_notes
            ? item?.proposed_security?.additional_info?.internal_notes
            : item?.additional_info?.internal_notes
            ? item?.additional_info?.internal_notes
            : '',
          report_comments: item?.proposed_security?.additional_info?.report_comments
            ? item?.proposed_security?.additional_info?.report_comments
            : item?.additional_info?.report_comments
            ? item?.additional_info?.report_comments
            : ''
        };
      });
    }
  };

  const getbusinesscomment = () => {
    // return [];
    if (
      facilitiesGroupData?.fsGroupList?.data &&
      facilitiesGroupData?.fsGroupList?.data.length > 0
    ) {
      let businesscommentarray = new Array();
      facilitiesGroupData?.fsGroupList?.data.map((item) => {
        // if (!item.facility_owners.find((item) => item.type == 'individual')) {
        // if (item.member.type != 'individual') {
        businesscommentarray.push({
          id: item.id,
          internal_notes: item?.proposed_facility?.additional_info?.internal_notes
            ? item?.proposed_facility?.additional_info?.internal_notes
            : item?.additional_info?.internal_notes
            ? item?.additional_info?.internal_notes
            : '',
          report_comments: item?.proposed_facility?.additional_info?.report_comments
            ? item?.proposed_facility?.additional_info?.report_comments
            : item?.additional_info?.report_comments
            ? item?.additional_info?.report_comments
            : ''
        });
      });

      return businesscommentarray;
    }
  };

  const getpersonalcomment = () => {
    if (
      facilitiesGroupData?.fsGroupList?.data &&
      facilitiesGroupData?.fsGroupList?.data.length > 0
    ) {
      let personalcommentarray = new Array();
      facilitiesGroupData?.fsGroupList?.data.map((item) => {
        if (item.facility_owners.find((item) => item.type == 'individual')) {
          personalcommentarray.push({
            id: item.id,
            internal_notes: item?.proposed_facility?.additional_info?.internal_notes
              ? item?.proposed_facility?.additional_info?.internal_notes
              : item?.additional_info?.internal_notes
              ? item?.additional_info?.internal_notes
              : '',
            report_comments: item?.proposed_facility?.additional_info?.report_comments
              ? item?.proposed_facility?.additional_info?.report_comments
              : item?.additional_info?.report_comments
              ? item?.additional_info?.report_comments
              : ''
          });
        }
      });
      return personalcommentarray;
    }
  };

  const initialValue = {
    // comment: [],
    comment: getsecuritycomment(),
    // business: [],
    business: getbusinesscomment(),
    // personal: [],
    personal: getpersonalcomment(),
    group: groupComment,
    is_completed: 1
  };

  const deleteReliancedata = (relianceId, group_id, handleCancel) => {
    dispatch(deleteReliance(jobId, relianceId, group_id)).then(() => {
      dispatch(getGroupList({ jobId }));
    });
    handleCancel();
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    let comment = {
      groups: values.group,
      facilities: [...values.business, ...values.personal],
      securities: values.comment,
      is_completed: values.is_completed
    };

    dispatch(updateComment(jobId, comment, setErrors));
  };

  return (
    <>
      <div className="facility-form form-data">
        <Formik initialValues={initialValue} enableReinitialize onSubmit={handleFormSubmit}>
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="facility-form form-data">
                <div className="white-card">
                  <div className="rentalview">
                    <ul className="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                      <li className="nav-item " role="presentation">
                        <button
                          className={`nav-link ${activeTab == 1 && 'active'}`}
                          id="commercial-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#commercial-justified"
                          type="button"
                          role="tab"
                          aria-controls="commercial"
                          aria-selected="true"
                          onClick={() => handleOnclick(1)}
                        >
                          Group F&amp;S
                        </button>
                      </li>
                      <li className="nav-item " role="presentation">
                        <button
                          className={`nav-link ${activeTab == 2 && 'active'}`}
                          id="residential-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#residential-justified"
                          type="button"
                          role="tab"
                          aria-controls="residential"
                          aria-selected="false"
                          onClick={() => handleOnclick(2)}
                        >
                          Facilities
                        </button>
                      </li>
                      <li className="nav-item " role="presentation">
                        <button
                          className={`nav-link ${activeTab == 3 && ' active'}`}
                          id="securities-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#securities-justified"
                          type="button"
                          role="tab"
                          aria-controls="securities"
                          aria-selected="false"
                          onClick={() => handleOnclick(3)}
                        >
                          Securities
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content pt-2" id="myTabjustifiedContent">
                      {activeTab == '1' ? (
                        <GroupTable
                          groupData={facilitiesGroupData?.groupList}
                          errors={errors}
                          values={values}
                          handleChange={handleChange}
                          handleInputChange={handleInputChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          deleteReliancedata={deleteReliancedata}
                          resetForm={resetForm}
                          // setGroupComment={setGroupComment}
                        />
                      ) : activeTab == '2' ? (
                        <FSTable
                          facilitiesGroupData={facilitiesGroupData?.fsGroupList}
                          activeTab={activeTab}
                          errors={errors}
                          values={values}
                          handleChange={handleChange}
                          handleInputChange={handleInputChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                        />
                      ) : (
                        <FSTable
                          facilitiesGroupData={facilitiesGroupData.securityList}
                          activeTab={activeTab}
                          errors={errors}
                          values={values}
                          handleChange={handleChange}
                          handleInputChange={handleInputChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
