import { createSlice } from '@reduxjs/toolkit';

export const appInitialState = {
  errorMessage: '',
  successMessage: '',
  loader: false
};

export const AppSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setHandleResponse: (state, action) => {
      if (
        action.payload.response &&
        action.payload.response.status === 200 &&
        action.payload.response.data.message &&
        action.payload.show
      ) {
        state.successMessage = action.payload.response.data.message;
      } else if (
        action.payload.response &&
        action.payload.response.status === 200 &&
        action.payload.response.message &&
        action.payload.show
      ) {
        state.successMessage = action.payload.response.message;
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setInitial: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
      state.loader = false;
    }
  }
});

export const { setErrorMessage, setHandleResponse, setLoader, setInitial } = AppSlice.actions;

export const appState = (state) => {
  return state.app;
};

export default AppSlice.reducer;
