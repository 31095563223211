import React from 'react';

export default function Taxitem(props) {
  const { itemData } = props;
  let value = JSON.parse(itemData?.value);

  let itemList = value.map((Title, index) => {
    return (
      <li key={index}>
        <label>Value</label>
        <p>
          {Title.value} {Title.is_default == 1 ? '  - (Default)' : ''}
        </p>
      </li>
    );
  });
  // itemList.push(
  //   <li>
  //     <label>Comment</label>
  //     <p>{itemData.comment}</p>
  //   </li>
  // );

  return itemList;
}
