import { FieldArray, Formik } from 'formik';
import {
  getUpdateMember,
  jobSetupState,
  updateMember,
  getMemberList
} from '../../../../slices/slices/JobSetupSlice';
import { Link, useParams, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  addBareSchema,
  AddCompanySchema,
  addSelectSchema,
  addDiscretionarySchema,
  addFixedUnitSchema,
  AddIndividualSchema,
  AddOtherSchema,
  AddSoleTraderSchema,
  addSuperannuationSchema
} from '../../../common/Validation';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import Header from '../../Header';
import React, { useEffect, useState } from 'react';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import CreatableSelect from 'react-select/creatable';
import { formatDecimal } from '../../../common/Misc';
import NumberFormat from 'react-number-format';
import ErrorFocus from '../../../common/ErrorFocus';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const selectTrustOfTypes = [
  { key: 'Select', value: 'select' },
  { key: 'Discretionary', value: 'discretionary' },
  { key: 'Fixed / Unit', value: 'fixed-unit' },
  { key: 'Hybrid', value: 'hybrid' },
  { key: 'Bare', value: 'bare' },
  { key: 'Superannuation', value: 'superannuation' }
];

const TrustTypes = {
  select: [
    { name: 'trustee_name', title: 'Trustee Name', value: '', type: 'string' },
    { name: 'acn', title: 'ACN', value: '', type: 'string' },
    { name: 'directors', title: 'Director', value: [], type: 'array' },
    { name: 'shareholders', title: 'Shareholder', value: [], type: 'array' },
    { name: 'trust_name', title: 'Trust Name', value: '', type: 'string' },
    { name: 'abn', title: 'ABN', value: '', type: 'string' },
    { name: 'trading_name', title: 'Trading Name', value: '', type: 'string' },
    { name: 'activity', title: 'Activity', value: '', type: 'string' },
    { name: 'trust_type', title: 'Type of Trust', value: '', type: 'dropdown' },
    { name: 'beneficiaries', title: 'Beneficiary', value: [], type: 'array' }
  ],
  discretionary: [
    { name: 'trustee_name', title: 'Trustee Name', value: '', type: 'string' },
    { name: 'acn', title: 'ACN', value: '', type: 'string' },
    { name: 'directors', title: 'Director', value: [], type: 'array' },
    { name: 'shareholders', title: 'Shareholder', value: [], type: 'array' },
    { name: 'trust_name', title: 'Trust Name', value: '', type: 'string' },
    { name: 'abn', title: 'ABN', value: '', type: 'string' },
    { name: 'trading_name', title: 'Trading Name', value: '', type: 'string' },
    { name: 'activity', title: 'Activity', value: '', type: 'string' },
    { name: 'trust_type', title: 'Type of Trust', value: '', type: 'dropdown' },
    { name: 'beneficiaries', title: 'Beneficiary', value: [], type: 'array' }
  ],
  'fixed-unit': [
    { name: 'trustee_name', title: 'Trustee Name', value: '', type: 'string' },
    { name: 'acn', title: 'ACN', value: '', type: 'string' },
    { name: 'directors', title: 'Director', value: [], type: 'array' },
    { name: 'shareholders', title: 'Shareholder', value: [], type: 'array' },
    { name: 'trust_name', title: 'Trust Name', value: '', type: 'string' },
    { name: 'abn', title: 'ABN', value: '', type: 'string' },
    { name: 'trading_name', title: 'Trading Name', value: '', type: 'string' },
    { name: 'activity', title: 'Activity', value: '', type: 'string' },
    { name: 'trust_type', title: 'Type of Trust', value: '', type: 'dropdown' },
    { name: 'unitholders', title: 'Unitholder / Fixed', value: '', type: 'string' }
  ],
  hybrid: [
    { name: 'trustee_name', title: 'Trustee Name', value: '', type: 'string' },
    { name: 'acn', title: 'ACN', value: '', type: 'string' },
    { name: 'directors', title: 'Director', value: [], type: 'array' },
    { name: 'shareholders', title: 'Shareholder', value: [], type: 'array' },
    { name: 'trust_name', title: 'Trust Name', value: '', type: 'string' },
    { name: 'abn', title: 'ABN', value: '', type: 'string' },
    { name: 'trading_name', title: 'Trading Name', value: '', type: 'string' },
    { name: 'activity', title: 'Activity', value: '', type: 'string' },
    { name: 'trust_type', title: 'Type of Trust', value: '', type: 'dropdown' },
    { name: 'unitholders', title: 'Unitholder / Fixed', value: '', type: 'string' }
  ],
  bare: [
    { name: 'trustee_name', title: 'Trustee Name', value: '', type: 'string' },
    { name: 'acn', title: 'ACN', value: '', type: 'string' },
    { name: 'directors', title: 'Director', value: [], type: 'array' },
    { name: 'shareholders', title: 'Shareholder', value: [], type: 'array' },
    { name: 'trust_name', title: 'Trust Name', value: '', type: 'string' },
    { name: 'abn', title: 'ABN', value: '', type: 'string' },
    { name: 'trading_name', title: 'Trading Name', value: '', type: 'string' },
    { name: 'activity', title: 'Activity', value: '', type: 'string' },
    { name: 'trust_type', title: 'Type of Trust', value: '', type: 'dropdown' },
    { name: 'beneficiary', title: 'Beneficiary', value: '', type: 'string' }
  ],
  superannuation: [
    { name: 'trustee_name', title: 'Trustee Name', value: '', type: 'string' },
    { name: 'acn', title: 'ACN', value: '', type: 'string' },
    { name: 'directors', title: 'Director', value: [], type: 'array' },
    { name: 'shareholders', title: 'Shareholder', value: [], type: 'array' },
    { name: 'trust_name', title: 'Trust Name', value: '', type: 'string' },
    { name: 'abn', title: 'ABN', value: '', type: 'string' },
    { name: 'trading_name', title: 'Trading Name', value: '', type: 'string' },
    { name: 'activity', title: 'Activity', value: '', type: 'string' },
    { name: 'trust_type', title: 'Type of Trust', value: '', type: 'dropdown' },
    { name: 'members', title: 'Member', value: '', type: 'string' }
  ]
};

const Edit = () => {
  const { GetUpdateMemberResponse, updateMemberDataResponse, memberListResponse } =
    useSelector(jobSetupState);
  const { jobId, memberId } = useParams();
  const back = '/job/' + jobId + '/group-setup/members';
  const dispatch = useDispatch();
  const { data } = GetUpdateMemberResponse;
  const [change, setChange] = useState(data?.type);
  const [isError, setIsError] = useState(false);
  const [trustType, setTrustType] = useState(data?.additional_info?.trust_type || 'select');
  const [onSaveDraft, setOnSaveDraft] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(false);
  const navigate = useNavigate();

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    values.abn = values.abn.replace(/\D+/g, '');
    values.acn = values.acn.replace(/\D+/g, '');
    setOnSaveDraft(false);
    if (onSaveDraft) {
      values.is_completed = 0;
    } else {
      values.is_completed = 1;
    }
    dispatch(updateMember(jobId, memberId, values, setErrors, navigate)).then((response) => {
      if (change === 'individual') {
        dispatch(getUpdateMember(jobId, memberId));
      }
      (response?.data?.message || response?.status === 200) && setRedirectUrl(true);
    });
  };

  useEffect(() => {
    if (redirectUrl) navigate('/job/' + jobId + '/group-setup/members');
  }, [redirectUrl]);

  const initialValues = {
    type: data?.type || '',
    legal_name: data?.name || '',
    acn: data?.additional_info?.acn || '',
    abn: data?.additional_info?.abn || '',
    trading_name: data?.additional_info?.trading_name || '',
    activity: data?.activity || '',
    website: data?.additional_info?.website || '',
    last_name: data?.additional_info?.last_name || '',
    first_name: data?.additional_info?.first_name || '',
    partners:
      data?.additional_info?.partners?.length > 0
        ? data?.additional_info?.partners?.map((share) => ({
            name: share.name,
            holding: share.holding
          }))
        : [{ name: '', holding: '' }],
    trust_name: data?.name || '',
    trustee_name: data?.additional_info?.trustee_name || '',
    occupation: data?.activity || '',
    other_names: data?.additional_info?.other_names || '',
    preferred_first_name: data?.additional_info?.preferred_first_name || '',
    date_of_birth_day: data?.additional_info?.date_of_birth || '',
    title_name: data?.title_name || '',
    title: data?.name || '',
    beneficiary: data?.additional_info?.beneficiary || '',
    trust_type: (data?.additional_info && data?.additional_info?.trust_type) || 'select',
    directors: data?.directors?.length > 0 ? data?.directors?.map((dir) => dir) : [''],
    is_completed: 0,
    shareholders:
      data?.shareholders?.length > 0
        ? data?.shareholders?.map((share) => ({
            name: share.name,
            holding: share.holding
          }))
        : [{ name: '', holding: '' }],
    beneficiaries:
      data?.additional_info?.beneficiaries?.length > 0
        ? data?.additional_info?.beneficiaries?.map((dir) => dir)
        : [''],
    members:
      data?.additional_info?.members?.length > 0
        ? data?.additional_info?.members?.map((dir) => dir)
        : [''],
    unitholders:
      data?.additional_info?.unitholders?.length > 0
        ? data?.additional_info?.unitholders?.map((share) => ({
            name: share.name,
            holding: share.holding
          }))
        : [{ name: '', holding: '' }],
    internal_notes: data?.additional_info?.internal_notes || '',
    report_comments: data?.additional_info?.report_comments || ''
  };

  useEffect(() => {
    if (data && data !== 'null') {
      setChange(data?.type);
      if (data?.additional_info && data?.additional_info?.trust_type) {
        setTrustType(data?.additional_info?.trust_type);
      }
    }
  }, [data]);
  function handleFormChange(e) {
    setChange(e.currentTarget.value);
    setIsError(false);
  }

  async function handleSelectType(e) {
    let a = e.target.value;
    setTrustType(a);
    setIsError(false);
  }

  const validate = () => {
    if (change === 'company') {
      return AddCompanySchema;
    } else if (change === 'sole-trader' || change === 'partnership') {
      return AddSoleTraderSchema;
    } else if (change === 'individual') {
      return AddIndividualSchema;
    } else if (change === 'other') {
      return AddOtherSchema;
    } else {
      if (trustType === 'select') {
        return addSelectSchema;
      } else if (trustType === 'discretionary') {
        return addDiscretionarySchema;
      } else if (trustType === 'fixed-unit' || trustType === 'hybrid') {
        return addFixedUnitSchema;
      } else if (trustType === 'bare') {
        return addBareSchema;
      } else if (trustType === 'superannuation') {
        return addSuperannuationSchema;
      }
    }
  };

  useEffect(() => {
    setChange(data?.type);
    if (data?.additional_info && data?.additional_info?.trust_type) {
      setTrustType(data?.additional_info?.trust_type);
    }
    dispatch(getMemberList(jobId));
    dispatch(getUpdateMember(jobId, memberId));
    if (updateMemberDataResponse?.message) {
      navigate(back);
    }
    return () => {};
  }, [updateMemberDataResponse?.message]);

  const isCheckError = () => {
    setOnSaveDraft(true);
    setIsError(false);
    switch (change) {
      case 'company':
        return setIsError(true);
      case 'trust':
        if (trustType === 'select') {
          return setIsError(true);
        } else if (trustType === 'discretionary') {
          return setIsError(true);
        } else if (trustType === 'fixed-unit' || trustType === 'hybrid') {
          return setIsError(true);
        } else if (trustType === 'bare') {
          return setIsError(true);
        } else if (trustType === 'superannuation') {
          return setIsError(true);
        }
        return setIsError(true);
      case 'partnership':
        return setIsError(true);
      case 'individual':
        return setIsError(true);
      case 'other':
        return setIsError(true);
      case 'sole-trader':
        return setIsError(true);
      default:
        return false;
    }
  };

  const newColumnsForDirector = memberListResponse?.data?.director?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/group-setup/members'}>Group Setup</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>Members</strong>
              </li>
            </ul>
          </div>
          <div className="breadcrumb-right"></div>
        </div>
        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-center align-items-center">
            <h1>Edit Member</h1>
          </div>
        </div>
        <section className="new_addmember">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validate()}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <>
                  {' '}
                  <DialogLeavingPage
                    showDialog={showDialogLeavingPage}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                  />
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="addmember">
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <Label>Type</Label>
                            <select
                              className="form-select"
                              name="type"
                              value={values?.type}
                              onChange={(e) => {
                                handleFormChange(e);
                                handleChange(e);
                                handleInputChange(e.target.value);
                              }}
                              onBlur={handleBlur}
                              aria-label="Default select example"
                            >
                              <option>Select Type</option>
                              <option value="individual">Individual</option>
                              <option value="sole-trader">Sole Trader</option>
                              <option value="partnership">Partnership</option>
                              <option value="trust">Trust</option>
                              <option value="company">Company</option>
                              <option value="other">Other</option>
                            </select>

                            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                              {errors.type}
                            </span>
                          </div>
                          {change && change === 'company' ? (
                            <>
                              <div className="col-6">
                                <Label>Legal Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.legal_name}
                                  name="legal_name"
                                  className="form-control"
                                />
                                {isError && (
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.legal_name}
                                  </span>
                                )}
                              </div>
                              <div className="col-6">
                                <Label>ACN</Label>
                                <InputMask
                                  className="form-control"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  name="acn"
                                  value={values.acn}
                                  mask="999 999 999"
                                  maskChar={null}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.acn}
                                </span>
                              </div>
                              <div className="col-6 mt-4">
                                <Label>ABN</Label>
                                <InputMask
                                  className="form-control"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  name="abn"
                                  value={values.abn}
                                  mask="99 999 999 999"
                                  maskChar={null}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.abn}
                                </span>
                              </div>
                              <div className="col-6 mt-4">
                                <Label>Trading Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.trading_name}
                                  name="trading_name"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.trading_name}
                                </span>
                              </div>
                              <div className="col-6 mt-4">
                                <Label>Activity</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.activity}
                                  name="activity"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.activity}
                                </span>
                              </div>
                              <div className="col-6 holders mt-4">
                                <div className="holders_area d-flex align-items-center justify-content-between">
                                  <Label>Director </Label>
                                </div>
                                <FieldArray
                                  name="directors"
                                  render={(arrayHelpers) => (
                                    <div>
                                      <div className="director noborder">
                                        {values.directors && values.directors.length > 0
                                          ? values.directors.map((director, index) => (
                                              <>
                                                <div key={index}>
                                                  <CreatableSelect
                                                    type="select"
                                                    classNamePrefix="select"
                                                    className="custom-select"
                                                    name={`directors.${index}`}
                                                    aria-label="Default select example"
                                                    onBlur={handleBlur}
                                                    onChange={(val) => {
                                                      setFieldValue(`directors.${index}`, val);
                                                      handleInputChange(val);
                                                    }}
                                                    isClearable
                                                    value={values?.directors[index]}
                                                    options={newColumnsForDirector}
                                                  ></CreatableSelect>
                                                  {index !== 0 && (
                                                    <i
                                                      className="icon-delete"
                                                      onClick={() =>
                                                        index !== 0 && arrayHelpers.remove(index)
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </>
                                            ))
                                          : []}
                                      </div>
                                      <div className="add-btn">
                                        <i
                                          className="icon-plus"
                                          onClick={() => {
                                            var isEmpty = false;
                                            for (var c = 0; c < values.directors.length; c++) {
                                              if (values.directors[c] == '') {
                                                isEmpty = true;
                                                return;
                                              }
                                            }
                                            if (!isEmpty) {
                                              arrayHelpers.push('');
                                            }
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  )}
                                />
                              </div>

                              <div className="col-6 holders mt-4">
                                <div className="holders_area d-flex align-items-center justify-content-between">
                                  <Label>Shareholder </Label>{' '}
                                </div>
                                <div className="persenteg d-flex justify-content-between">
                                  <div className="pro_persenteg">
                                    <FieldArray
                                      name="shareholders"
                                      render={(arrayHelpers) => (
                                        <div>
                                          <div className="shareholder noborder">
                                            {values.shareholders && values.shareholders.length > 0
                                              ? values.shareholders.map((shareholder, index) => {
                                                  return (
                                                    <div key={index}>
                                                      <CreatableSelect
                                                        className="custom-select"
                                                        classNamePrefix="select"
                                                        name={`shareholders.${index}.name`}
                                                        aria-label="Default select example"
                                                        value={values?.shareholders[index]?.name}
                                                        onBlur={handleBlur}
                                                        onChange={(val) => {
                                                          setFieldValue(
                                                            `shareholders.${index}.name`,
                                                            val
                                                          );
                                                          handleInputChange(val);
                                                        }}
                                                        isClearable
                                                        options={newColumnsForAll}
                                                      ></CreatableSelect>
                                                      <NumberFormat
                                                        placeholder="0"
                                                        allowNegative={false}
                                                        name={`shareholders.${index}.holding`}
                                                        value={formatDecimal(
                                                          values?.shareholders[index]?.holding > 100
                                                            ? 0
                                                            : values?.shareholders[index]?.holding
                                                        )}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          handleInputChange(e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                      />
                                                      {index !== 0 && (
                                                        <i
                                                          className="icon-delete"
                                                          onClick={() => arrayHelpers.remove(index)}
                                                        />
                                                      )}
                                                    </div>
                                                  );
                                                })
                                              : null}
                                          </div>
                                          <span
                                            className="error"
                                            style={{ color: 'red', fontSize: '20px' }}
                                          >
                                            {errors?.shareholders}
                                          </span>
                                          <div className="add-btn">
                                            <i
                                              className="icon-plus"
                                              onClick={() => {
                                                var isEmpty = false;
                                                for (
                                                  var c = 0;
                                                  c < values.shareholders.length;
                                                  c++
                                                ) {
                                                  if (!values.shareholders[c].name) {
                                                    isEmpty = true;
                                                    return;
                                                  }
                                                }
                                                if (!isEmpty) {
                                                  arrayHelpers.push({ name: '', holding: '' });
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : change && change === 'trust' ? (
                            <>
                              {trustType &&
                                TrustTypes[trustType].map((item, index) => {
                                  return (
                                    <>
                                      {item.name === 'directors' && (
                                        <div className="col-6 mt-4 holders">
                                          <div className="holders_area d-flex align-items-center justify-content-between">
                                            <Label>Director </Label>
                                          </div>
                                          <FieldArray
                                            name="directors"
                                            render={(arrayHelpers) => (
                                              <div>
                                                <div className="director noborder">
                                                  {values.directors && values.directors.length > 0
                                                    ? values.directors.map((director, index) => (
                                                        <div key={index}>
                                                          <CreatableSelect
                                                            type="select"
                                                            className="custom-select"
                                                            classNamePrefix="select"
                                                            name={`directors.${index}`}
                                                            aria-label="Default select example"
                                                            onBlur={handleBlur}
                                                            onChange={(val) => {
                                                              setFieldValue(
                                                                `directors.${index}`,
                                                                val
                                                              );
                                                              handleInputChange(val);
                                                            }}
                                                            isClearable
                                                            value={values?.directors[index]}
                                                            options={newColumnsForDirector}
                                                          ></CreatableSelect>
                                                          {index !== 0 && (
                                                            <i
                                                              className="icon-delete"
                                                              onClick={() =>
                                                                index !== 0 &&
                                                                arrayHelpers.remove(index)
                                                              }
                                                            />
                                                          )}
                                                        </div>
                                                      ))
                                                    : null}
                                                </div>
                                                <div className="add-btn">
                                                  <i
                                                    className="icon-plus"
                                                    onClick={() => {
                                                      var isEmpty = false;
                                                      var c;
                                                      for (
                                                        c = 0;
                                                        c < values.directors.length;
                                                        c++
                                                      ) {
                                                        if (values.directors[c] == '') {
                                                          isEmpty = true;
                                                          return;
                                                        }
                                                      }
                                                      if (!isEmpty) {
                                                        arrayHelpers.push('');
                                                      }
                                                    }}
                                                  ></i>
                                                </div>
                                              </div>
                                            )}
                                          />
                                        </div>
                                      )}
                                      {item.name === 'members' && (
                                        <div className="col-6 mt-4 holders">
                                          <div className="holders_area d-flex align-items-center justify-content-between">
                                            <Label>Member</Label>
                                          </div>
                                          <FieldArray
                                            name="members"
                                            render={(arrayHelpers) => (
                                              <div>
                                                <div className="director noborder">
                                                  {values.members && values.members.length > 0
                                                    ? values.members.map((director, index) => (
                                                        <div key={index}>
                                                          <CreatableSelect
                                                            className="custom-select"
                                                            classNamePrefix="select"
                                                            name={`members.${index}`}
                                                            aria-label="Default select example"
                                                            onBlur={handleBlur}
                                                            onChange={(val) => {
                                                              setFieldValue(
                                                                `members.${index}`,
                                                                val
                                                              );
                                                              handleInputChange(val);
                                                            }}
                                                            isClearable
                                                            value={values?.members[index]}
                                                            options={newColumnsForAll}
                                                          ></CreatableSelect>
                                                          {index !== 0 && (
                                                            <i
                                                              className="icon-delete"
                                                              onClick={() =>
                                                                index !== 0 &&
                                                                arrayHelpers.remove(index)
                                                              }
                                                            />
                                                          )}
                                                        </div>
                                                      ))
                                                    : null}
                                                </div>
                                                {errors.members && isError && (
                                                  <span
                                                    className="error"
                                                    style={{ color: 'red', fontSize: '20px' }}
                                                  >
                                                    {errors[item.name]}
                                                  </span>
                                                )}
                                                <div className="add-btn">
                                                  <i
                                                    className="icon-plus"
                                                    onClick={() => {
                                                      var isEmpty = false;
                                                      var c;
                                                      for (c = 0; c < values.members.length; c++) {
                                                        if (values.members[c] == '') {
                                                          isEmpty = true;
                                                          return;
                                                        }
                                                      }
                                                      if (!isEmpty) {
                                                        arrayHelpers.push('');
                                                      }
                                                    }}
                                                  ></i>
                                                </div>
                                              </div>
                                            )}
                                          />
                                        </div>
                                      )}
                                      {item.name === 'unitholders' && (
                                        <div className="col-6 mt-4 holders">
                                          <div className="holders_area d-flex align-items-center justify-content-between">
                                            <Label>Unitholder / Fixed </Label>
                                          </div>
                                          <div className="persenteg d-flex justify-content-between">
                                            <div className="pro_persenteg">
                                              <FieldArray
                                                name="unitholders"
                                                render={(arrayHelpers) => (
                                                  <div>
                                                    <div className="shareholder noborder">
                                                      {values.unitholders &&
                                                      values.unitholders.length > 0
                                                        ? values.unitholders.map(
                                                            (shareholder, index) => {
                                                              return (
                                                                <div key={index}>
                                                                  <CreatableSelect
                                                                    className="custom-select"
                                                                    classNamePrefix="select"
                                                                    name={`unitholders.${index}.name`}
                                                                    aria-label="Default select example"
                                                                    onBlur={handleBlur}
                                                                    onChange={(val) => {
                                                                      setFieldValue(
                                                                        `unitholders.${index}.name`,
                                                                        val
                                                                      );
                                                                      handleInputChange(val);
                                                                    }}
                                                                    isClearable
                                                                    value={
                                                                      values?.unitholders[index]
                                                                        ?.name
                                                                    }
                                                                    options={newColumnsForAll}
                                                                  ></CreatableSelect>
                                                                  <NumberFormat
                                                                    placeholder="0"
                                                                    allowNegative={false}
                                                                    name={`unitholders.${index}.holding`}
                                                                    value={formatDecimal(
                                                                      values?.unitholders[index]
                                                                        ?.holding > 100
                                                                        ? 0
                                                                        : values?.unitholders[index]
                                                                            ?.holding
                                                                    )}
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                      handleChange(e);
                                                                      handleInputChange(
                                                                        e.target.value
                                                                      );
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                  />
                                                                  {index !== 0 && (
                                                                    <i
                                                                      className="icon-delete"
                                                                      onClick={() =>
                                                                        index !== 0 &&
                                                                        arrayHelpers.remove(index)
                                                                      }
                                                                    />
                                                                  )}
                                                                </div>
                                                              );
                                                            }
                                                          )
                                                        : null}
                                                    </div>
                                                    <span
                                                      className="error"
                                                      style={{ color: 'red', fontSize: '20px' }}
                                                    >
                                                      {errors?.unitholders}
                                                    </span>

                                                    <div className="add-btn">
                                                      <i
                                                        className="icon-plus"
                                                        onClick={() => {
                                                          var isEmpty = false;
                                                          for (
                                                            var c = 0;
                                                            c < values.unitholders.length;
                                                            c++
                                                          ) {
                                                            if (!values.unitholders[c].name) {
                                                              isEmpty = true;
                                                              return;
                                                            }
                                                          }
                                                          if (!isEmpty) {
                                                            arrayHelpers.push({
                                                              name: '',
                                                              holding: ''
                                                            });
                                                          }
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                )}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {item.name === 'beneficiaries' && (
                                        <div className="col-6 mt-4 holders">
                                          <div className="holders_area d-flex align-items-center justify-content-between">
                                            <Label>Beneficiary</Label>
                                          </div>
                                          <FieldArray
                                            name="beneficiaries"
                                            render={(arrayHelpers) => (
                                              <div>
                                                <div className="director noborder">
                                                  {values.beneficiaries &&
                                                  values.beneficiaries.length > 0
                                                    ? values.beneficiaries.map((row, index) => (
                                                        <div key={index}>
                                                          <CreatableSelect
                                                            className="custom-select"
                                                            classNamePrefix="select"
                                                            name={`beneficiaries.${index}`}
                                                            aria-label="Default select example"
                                                            onBlur={handleBlur}
                                                            value={values?.beneficiaries[index]}
                                                            onChange={(val) => {
                                                              setFieldValue(
                                                                `beneficiaries.${index}`,
                                                                val
                                                              );
                                                              handleInputChange(val);
                                                            }}
                                                            isClearable
                                                            options={newColumnsForAll}
                                                          ></CreatableSelect>

                                                          {index !== 0 && (
                                                            <i
                                                              className="icon-delete"
                                                              onClick={() =>
                                                                index !== 0 &&
                                                                arrayHelpers.remove(index)
                                                              }
                                                            />
                                                          )}
                                                        </div>
                                                      ))
                                                    : null}
                                                </div>
                                                {errors.beneficiaries && isError && (
                                                  <span
                                                    className="error"
                                                    style={{ color: 'red', fontSize: '20px' }}
                                                  >
                                                    {errors[item.name]}
                                                  </span>
                                                )}
                                                <div className="add-btn">
                                                  <i
                                                    className="icon-plus"
                                                    onClick={() => {
                                                      var isEmpty = false;
                                                      var c;
                                                      for (
                                                        c = 0;
                                                        c < values.beneficiaries.length;
                                                        c++
                                                      ) {
                                                        if (values.beneficiaries[c] == '') {
                                                          isEmpty = true;
                                                          return;
                                                        }
                                                      }
                                                      if (!isEmpty) {
                                                        arrayHelpers.push('');
                                                      }
                                                    }}
                                                  ></i>
                                                </div>
                                              </div>
                                            )}
                                          />
                                        </div>
                                      )}
                                      {item.name === 'shareholders' && (
                                        <div className="col-6 mt-4 holders">
                                          <div className="holders_area d-flex align-items-center justify-content-between">
                                            <Label>Shareholder </Label>
                                          </div>
                                          <div className="persenteg d-flex justify-content-between">
                                            <div className="pro_persenteg">
                                              <FieldArray
                                                name="shareholders"
                                                render={(arrayHelpers) => (
                                                  <div>
                                                    <div className="shareholder noborder">
                                                      {values.shareholders &&
                                                      values.shareholders.length > 0
                                                        ? values.shareholders.map(
                                                            (shareholder, index) => (
                                                              <div key={index}>
                                                                <CreatableSelect
                                                                  className="custom-select"
                                                                  classNamePrefix="select"
                                                                  name={`shareholders.${index}.name`}
                                                                  aria-label="Default select example"
                                                                  value={
                                                                    values?.shareholders[index]
                                                                      ?.name
                                                                  }
                                                                  onBlur={handleBlur}
                                                                  onChange={(val) => {
                                                                    setFieldValue(
                                                                      `shareholders.${index}.name`,
                                                                      val
                                                                    );
                                                                    handleInputChange(val);
                                                                  }}
                                                                  isClearable
                                                                  options={newColumnsForAll}
                                                                ></CreatableSelect>
                                                                <NumberFormat
                                                                  placeholder="0"
                                                                  allowNegative={false}
                                                                  name={`shareholders.${index}.holding`}
                                                                  value={formatDecimal(
                                                                    values?.shareholders[index]
                                                                      ?.holding > 100
                                                                      ? 0
                                                                      : values?.shareholders[index]
                                                                          ?.holding
                                                                  )}
                                                                  className="form-control"
                                                                  onChange={(e) => {
                                                                    handleChange(e);
                                                                    handleInputChange(
                                                                      e.target.value
                                                                    );
                                                                  }}
                                                                  onBlur={handleBlur}
                                                                />
                                                                {index !== 0 && (
                                                                  <i
                                                                    className="icon-delete"
                                                                    onClick={() =>
                                                                      index !== 0 &&
                                                                      arrayHelpers.remove(index)
                                                                    }
                                                                  />
                                                                )}
                                                              </div>
                                                            )
                                                          )
                                                        : null}
                                                    </div>
                                                    <span
                                                      className="error"
                                                      style={{ color: 'red', fontSize: '20px' }}
                                                    >
                                                      {errors?.shareholders}
                                                    </span>

                                                    <div className="add-btn">
                                                      <i
                                                        className="icon-plus"
                                                        onClick={() => {
                                                          var isEmpty = false;
                                                          for (
                                                            var c = 0;
                                                            c < values.shareholders.length;
                                                            c++
                                                          ) {
                                                            if (
                                                              !values.shareholders[c].name ||
                                                              !values.shareholders[c].holding
                                                            ) {
                                                              isEmpty = true;
                                                              return;
                                                            }
                                                          }
                                                          if (!isEmpty) {
                                                            arrayHelpers.push({
                                                              name: '',
                                                              holding: ''
                                                            });
                                                          }
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                )}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {item.name === 'acn' && (
                                        <div className="col-6 mt-4">
                                          <Label>ACN</Label>
                                          <InputMask
                                            className="form-control"
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleInputChange(e.target.value);
                                            }}
                                            name="acn"
                                            value={values.acn}
                                            mask="999 999 999"
                                            maskChar={null}
                                          />
                                          <span
                                            className="error"
                                            style={{ color: 'red', fontSize: '20px' }}
                                          >
                                            {errors.acn}
                                          </span>
                                        </div>
                                      )}

                                      {item.name === 'abn' && (
                                        <div className="col-6 mt-4">
                                          <Label>ABN</Label>
                                          <InputMask
                                            className="form-control"
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleInputChange(e.target.value);
                                            }}
                                            name="abn"
                                            value={values.abn}
                                            mask="99 999 999 999"
                                            maskChar={null}
                                          />
                                          <span
                                            className="error"
                                            style={{ color: 'red', fontSize: '20px' }}
                                          >
                                            {errors.abn}
                                          </span>
                                        </div>
                                      )}
                                      {item.name !== 'directors' &&
                                        item.name !== 'shareholders' &&
                                        item.name !== 'members' &&
                                        item.name !== 'unitholders' &&
                                        item.name !== 'beneficiaries' &&
                                        item.name !== 'acn' &&
                                        item.name !== 'abn' && (
                                          <div className="col-6 mt-4" key={index}>
                                            {item.type !== 'dropdown' ? (
                                              <>
                                                <Label>{item.title}</Label>
                                                {item?.title === 'Beneficiary' ? (
                                                  <div className="noborder">
                                                    <CreatableSelect
                                                      className="custom-select"
                                                      classNamePrefix="select"
                                                      name={`beneficiary`}
                                                      aria-label="Default select example"
                                                      onBlur={handleBlur}
                                                      value={values?.beneficiary}
                                                      onChange={(val) => {
                                                        setFieldValue(`beneficiary`, val);
                                                        handleInputChange(val);
                                                      }}
                                                      isClearable
                                                      options={newColumnsForAll}
                                                    ></CreatableSelect>
                                                  </div>
                                                ) : (
                                                  <Input
                                                    type="text"
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      handleInputChange(e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values[item.name]}
                                                    name={item.name}
                                                    className="form-control"
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <Label>Type of Trust</Label>
                                                <select
                                                  className="form-select w-100"
                                                  name="trust_type"
                                                  defaultValue="select"
                                                  onChange={(e) => {
                                                    handleSelectType(e);
                                                    handleChange(e);
                                                  }}
                                                  onBlur={handleBlur}
                                                  aria-label="Default select example"
                                                  value={trustType}
                                                >
                                                  {selectTrustOfTypes.map((item, index) => {
                                                    return (
                                                      <option key={index} value={item.value}>
                                                        {item.key}
                                                      </option>
                                                    );
                                                  })}
                                                </select>
                                              </>
                                            )}
                                            {isError !== false && (
                                              <span
                                                className="error"
                                                style={{ color: 'red', fontSize: '20px' }}
                                              >
                                                {errors[item.name]}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                    </>
                                  );
                                })}
                            </>
                          ) : change && change === 'partnership' ? (
                            <>
                              <div className="col-6">
                                <Label>Legal Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.legal_name}
                                  name="legal_name"
                                  className="form-control"
                                />
                                {isError && (
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.legal_name}
                                  </span>
                                )}
                              </div>
                              <div className="col-6">
                                <Label>ABN</Label>
                                <InputMask
                                  className="form-control"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  name="abn"
                                  value={values.abn}
                                  mask="99 999 999 999"
                                  maskChar={null}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.abn}
                                </span>
                              </div>
                              <div className="col-6 mt-4">
                                <Label>Trading Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.trading_name}
                                  name="trading_name"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.trading_name}
                                </span>
                              </div>
                              <div className="col-6 mt-4">
                                <Label>Activity</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.activity}
                                  name="activity"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.activity}
                                </span>
                              </div>
                              <div className="col-6 mt-4 holders">
                                <div className="holders_area d-flex align-items-center justify-content-between">
                                  <Label>Partner</Label>
                                </div>
                                <div className="persenteg d-flex justify-content-between">
                                  <div className="pro_persenteg">
                                    <FieldArray
                                      name="partners"
                                      render={(arrayHelpers) => (
                                        <div>
                                          <div className="shareholder noborder">
                                            {values.partners && values.partners.length > 0
                                              ? values.partners.map((shareholder, index) => {
                                                  return (
                                                    <div key={index}>
                                                      <CreatableSelect
                                                        className="custom-select"
                                                        classNamePrefix="select"
                                                        name={`partners.${index}.name`}
                                                        aria-label="Default select example"
                                                        onBlur={handleBlur}
                                                        onChange={(val) => {
                                                          setFieldValue(
                                                            `partners.${index}.name`,
                                                            val
                                                          );
                                                          handleInputChange(val);
                                                        }}
                                                        isClearable
                                                        value={values?.partners[index]?.name}
                                                        options={newColumnsForAll}
                                                      ></CreatableSelect>
                                                      <NumberFormat
                                                        placeholder="0"
                                                        allowNegative={false}
                                                        name={`partners.${index}.holding`}
                                                        value={formatDecimal(
                                                          values?.partners[index]?.holding > 100
                                                            ? 0
                                                            : values?.partners[index]?.holding
                                                        )}
                                                        className="form-control"
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          handleInputChange(e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                      />
                                                      {index !== 0 && (
                                                        <i
                                                          className="icon-delete"
                                                          onClick={() => arrayHelpers.remove(index)}
                                                        />
                                                      )}
                                                    </div>
                                                  );
                                                })
                                              : null}
                                          </div>
                                          <span
                                            className="error"
                                            style={{ color: 'red', fontSize: '20px' }}
                                          >
                                            {errors?.partners}
                                            {errors?.partnerholders}
                                          </span>
                                          <div className="add-btn">
                                            <i
                                              className="icon-plus"
                                              onClick={() => {
                                                var isEmpty = false;
                                                for (var c = 0; c < values.partners.length; c++) {
                                                  if (!values.partners[c].name) {
                                                    isEmpty = true;
                                                    return;
                                                  }
                                                }
                                                if (!isEmpty) {
                                                  arrayHelpers.push({ name: '', holding: '' });
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : change && change === 'sole-trader' ? (
                            <>
                              {' '}
                              <div className="col-6">
                                <Label>Legal Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.legal_name}
                                  name="legal_name"
                                  className="form-control"
                                />
                                {isError && (
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.legal_name}
                                  </span>
                                )}
                              </div>
                              <div className="col-6">
                                <Label>ABN</Label>
                                <InputMask
                                  className="form-control"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  name="abn"
                                  value={values.abn}
                                  mask="99 999 999 999"
                                  maskChar={null}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.abn}
                                </span>
                              </div>
                              <div className="col-6 mt-4">
                                <Label>Trading Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.trading_name}
                                  name="trading_name"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.trading_name}
                                </span>
                              </div>
                              <div className="col-6 mt-4">
                                <Label>Activity</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.activity}
                                  name="activity"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.activity}
                                </span>
                              </div>
                            </>
                          ) : change && change === 'individual' ? (
                            <>
                              <div className="col-4">
                                <Label>Title</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.title}
                                  name="title"
                                  className="form-control"
                                />
                                {isError && (
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.title}
                                  </span>
                                )}
                              </div>
                              <div className="col-4">
                                <Label>First Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.first_name}
                                  name="first_name"
                                  className="form-control"
                                />
                                {isError && (
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.first_name}
                                  </span>
                                )}
                              </div>
                              <div className="col-4">
                                <Label>Last Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.last_name}
                                  name="last_name"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.last_name}
                                </span>
                              </div>
                              <div className="col-4">
                                <Label>Other Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.other_names}
                                  name="other_names"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.other_names}
                                </span>
                              </div>
                              <div className="col-4">
                                <Label>Preferred First Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.preferred_first_name}
                                  name="preferred_first_name"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.preferred_first_name}
                                </span>
                              </div>
                              <div className="col-4">
                                <Label>Date of Birth</Label>
                                <DatePicker
                                  selected={
                                    values?.date_of_birth_day
                                      ? new Date(values?.date_of_birth_day)
                                      : null
                                  }
                                  onChange={(val) => {
                                    setFieldValue('date_of_birth_day', val);
                                    handleInputChange(val);
                                  }}
                                  customInput={
                                    <InputMask
                                      className="form-control"
                                      mask="99-99-9999"
                                      maskChar={null}
                                    />
                                  }
                                  name="date_of_birth_day"
                                  dateFormat="dd-MM-yyyy"
                                  value={new Date(values?.date_of_birth_day)}
                                  placeholderText="DD-MM-YYYY"
                                  maxDate={new Date()}
                                  id="datepicker"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.date_of_birth}
                                </span>
                              </div>
                              <div className="col-4">
                                <Label>Occupation</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.occupation}
                                  name="occupation"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.occupation}
                                </span>
                              </div>
                            </>
                          ) : change && change === 'other' ? (
                            <>
                              <div className="col-4">
                                <Label>Legal Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.legal_name}
                                  name="legal_name"
                                  className="form-control"
                                />
                                {isError && (
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors.legal_name}
                                  </span>
                                )}
                              </div>
                              <div className="col-4">
                                <Label>ACN</Label>
                                <InputMask
                                  className="form-control"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  name="acn"
                                  value={values.acn}
                                  mask="999 999 999"
                                  maskChar={null}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.acn}
                                </span>
                              </div>
                              <div className="col-4">
                                <Label>ABN</Label>
                                <InputMask
                                  className="form-control"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  name="abn"
                                  value={values.abn}
                                  mask="99 999 999 999"
                                  maskChar={null}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.abn}
                                </span>
                              </div>
                              <div className="col-4">
                                <Label>Trading Name</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.trading_name}
                                  name="trading_name"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.trading_name}
                                </span>
                              </div>
                              <div className="col-4">
                                <Label>Activity</Label>
                                <Input
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.activity}
                                  name="activity"
                                  className="form-control"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors.activity}
                                </span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <CommentsNotes
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div className="btn_area text-end">
                      <Link to={'/job/' + jobId + '/group-setup/members'}>
                        <Button className="btn btn-outline-secondary" type="reset">
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        onClick={() => {
                          isCheckError();
                          values.is_completed = 0;
                          setIsError(true);
                        }}
                        className="btn btn-primary"
                      >
                        save as draft
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => {
                          values.is_completed = 1;
                          setIsError(true);
                        }}
                      >
                        Completed
                      </Button>
                    </div>
                    <ErrorFocus />
                  </form>
                </>
              );
            }}
          </Formik>
        </section>
      </div>
    </div>
  );
};

export default Edit;
