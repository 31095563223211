import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const commonInitialState = {
  configList: {},
  addConfigList: {},
  adminConfigList: [],
  adminCreditCardConfigList: [],
  getpracticeManagerList: [],
  addPracticeManagerList: [],
  deletePracticeManagerList: [],
  updatePracticeManagerList: [],
  detailPracticeManagerList: [],
  deletetitle: {},
  updateConfigTitle: {},
  updateTaxRateConfig: {},
  updateIndividualTaxRateConfig: {},
  settaxrateList: [],
  setindividualtaxrateList: [],
  setOverallAssesmentList: [],
  setCategoryList: {},
  setEditCategoryList: {},
  setdeleteCategResponse: {}
};

export const CommonSlice = createSlice({
  name: 'common',
  initialState: commonInitialState,
  reducers: {
    setConfig: (state, action) => {
      state.configList = action.payload.data;
    },
    addConfigTitle: (state, action) => {
      state.addConfigList = action.payload.data;
    },
    setadminConfigList: (state, action) => {
      state.adminConfigList = action.payload.data;
    },
    setadminCreditCardConfigList: (state, action) => {
      state.adminCreditCardConfigList = action.payload.data;
    },
    addPracticeManagerList: (state, action) => {
      state.addPracticeManagerList = action.payload.data;
    },
    setpracticeManagerList: (state, action) => {
      state.getpracticeManagerList = action.payload.data;
    },
    setupdatepracticeManagerList: (state, action) => {
      state.updatePracticeManagerList = action.payload.data;
    },
    setdetailpracticeManagerList: (state, action) => {
      state.detailPracticeManagerList = action.payload.data;
    },
    setdeletepracticeManagerList: (state, action) => {
      state.deletePracticeManagerList = action.payload.data;
    },
    setdeleteTitle: (state, action) => {
      state.deletetitle = action.payload.data;
    },
    setupdateConfigTitle: (state, action) => {
      state.updateConfigTitle = action.payload.data;
    },
    setupdateTaxRateConfig: (state, action) => {
      state.updateTaxRateConfig = action.payload.data;
    },
    setupdateIndividualTaxRateConfig: (state, action) => {
      state.updateIndividualTaxRateConfig = action.payload.data;
    },
    settaxrate: (state, action) => {
      state.settaxrateList = action.payload.data;
    },
    setindividualtaxrate: (state, action) => {
      state.setindividualtaxrateList = action.payload.data;
    },
    setOverallAssessment: (state, action) => {
      state.setOverallAssesmentList = action.payload.data;
    },
    setCategoryData: (state, action) => {
      state.setCategoryList = action.payload;
    },
    setEditCategoryData: (state, action) => {
      state.setEditCategoryList = action.payload;
    },
    setdeleteCateg: (state, action) => {
      state.setdeleteCategResponse = action.payload;
    }
  }
});

export const adminGetConfig =
  (configList, config_type, loader = true, setErrors) =>
  async (dispatch) => {
    if (Object.keys(configList).length == 0) {
      const response = await clientService.get(
        'config?type=list&config_type=' + config_type,
        setErrors,
        dispatch,
        false,
        loader
      );

      if (typeof response === 'string') {
        dispatch(setErrorMessage(response));
      } else {
        if (config_type == 2) {
          dispatch(setadminCreditCardConfigList(response.data));
        } else {
          dispatch(setadminConfigList(response.data));
        }
      }
    }
  };

export const getpracticeManager =
  (limit, page, loader = true, setErrors) =>
  async (dispatch) => {
    // if (Object.keys(configList).length == 0) {
    const response = await clientService.get(
      'practice-manager?limit=' + limit + '&page=' + page + '&order_by=name&sort=asc',
      setErrors,
      dispatch,
      false,
      loader
    );

    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setpracticeManagerList(response.data));
    }
    // }
  };

export const addPracticeManager = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('practice-manager', data, setErrors, dispatch);

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(addPracticeManagerList(response.data));
  }
  return response;
};

export const deletePracticeManager = (managerID, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'practice-manager/' + managerID,
    setErrors,
    dispatch,
    true,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setdeletepracticeManagerList(response.data));
  }
  return response;
};

export const getdetailPracticeManager = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'practice-manager/' + id,
    setErrors,
    dispatch,
    false,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setdetailpracticeManagerList(response.data));
  }
};

export const updatePracticeManager = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put('practice-manager/' + id, data, setErrors, dispatch);

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdatepracticeManagerList(response.data));
  }
  return response;
};

export const getConfig = (configList, setErrors) => async (dispatch) => {
  if (Object.keys(configList).length == 0) {
    const response = await clientService.get('config', setErrors, dispatch, false, false);

    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setConfig(response.data));
    }
  }
};

export const addConfig = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('config', data, setErrors, dispatch, true, false);

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(addConfigTitle(response.data));
  }
};

export const updateConfig = (configId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'config/' + configId,
    data,
    setErrors,
    dispatch,
    true,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdateConfigTitle(response.data));
  }
};

export const updateTaxRateConfig = (taxBracketId, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'tax-rate/' + taxBracketId,
    data,
    setErrors,
    dispatch,
    true,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdateTaxRateConfig(response.data));
  }
};

export const updateIndividualTaxRateConfig = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'update-individual-tax-rates',
    data,
    setErrors,
    dispatch,
    true,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdateIndividualTaxRateConfig(response.data));
  }
};

export const deleteConfig = (configId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'config/' + configId,
    setErrors,
    dispatch,
    true,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setdeleteTitle(response.data));
  }
};

export const gettaxrate = (setErrors) => async (dispatch) => {
  const response = await clientService.get('tax-rate', setErrors, dispatch, false, false);

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(settaxrate(response.data));
  }
};

export const getindividualtaxrate = (setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'individual-tax-rates',
    setErrors,
    dispatch,
    false,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setindividualtaxrate(response.data));
  }
};

export const getOverallAssessment = (setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'overall-assessment-admin',
    setErrors,
    dispatch,
    false,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setOverallAssessment(response.data));
  }
};

export const addCategory = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    id + '/overall-assessment-admin',
    data,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setCategoryData(response.data));
  }
};

export const editCategory = (id, categoryItemId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    id + '/' + categoryItemId + '/overall-assessment-admin',
    data,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setEditCategoryData(response.data));
  }
};

export const deleteCateg = (id, categId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    id + '/' + categId + '/overall-assessment-admin',
    setErrors,
    dispatch,
    true,
    false
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setdeleteCateg(response.data));
  }
};

export const {
  setConfig,
  addConfigTitle,
  setadminConfigList,
  setadminCreditCardConfigList,
  setpracticeManagerList,
  addPracticeManagerList,
  setdetailpracticeManagerList,
  setupdatepracticeManagerList,
  setdeletepracticeManagerList,
  setdeleteTitle,
  setupdateConfigTitle,
  settaxrate,
  setindividualtaxrate,
  setupdateTaxRateConfig,
  setupdateIndividualTaxRateConfig,
  setOverallAssessment,
  setCategoryData,
  setEditCategoryData,
  setdeleteCateg
} = CommonSlice.actions;

export const commonState = (state) => {
  return state.common;
};

export default CommonSlice.reducer;
