export const sortingType = ['NORMAL', 'ASC', 'DESC'];

export const transactionType = {
  'application-funds': 'Application of Funds',
  'source-funds': 'Source of Funds',
  associated_funds: 'Associated Transaction'
};

export const transactionSelected = {
  'application-funds': 'funds_use',
  'source-funds': 'how_sourced',
  associated_funds: 'item'
};

export const yearStatus = [
  {
    value: 'Actual'
  },
  {
    value: 'MGT'
  },
  {
    value: 'PROJ'
  },
  {
    value: 'Draft'
  }
];
