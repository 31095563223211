import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { PasswordManagementSchema } from '../common/Validation';
import UsePasswordToggle from '../common/UsePasswordToggle';
import {
  getchangePasswordData,
  settingState,
  setchangePasswordResponse
} from '../../slices/slices/SettingSlice';
import { useNavigate } from 'react-router';
import Label from '../../components/Label';

const PasswordManagement = () => {
  const dispatch = useDispatch();

  const { changePasswordResponse } = useSelector(settingState);
  const handleFormSubmit = (values, { setErrors }) => {
    dispatch(getchangePasswordData(values, setErrors));
  };
  useEffect(() => {
    if (changePasswordResponse?.message) {
      if (changePasswordResponse) {
        dispatch(setchangePasswordResponse({}));
      }
    }
  }, [changePasswordResponse?.message]);

  const [PasswordInputType, IconToggle] = UsePasswordToggle();
  const [PasswordInputType1, IconToggle1] = UsePasswordToggle();
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const navigate = useNavigate();
  const onClickCancle = () => {
    navigate('/dashboard');
  };

  return (
    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <Formik
        initialValues={{
          current_password: '',
          password: '',
          password_confirmation: ''
        }}
        enableReinitialize
        validationSchema={PasswordManagementSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="row needs-validation" noValidate>
            <div className="innertab">
              <div className="container">
                <div className="textarea">
                  <div className="passwordarea">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group flex-column has-validation">
                          <Label>Old Password</Label>
                          <Input
                            type="text"
                            name="current_password"
                            placeholder=""
                            className="form-control"
                            id="current_password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.current_password}
                            onKeyDown={handleKeyDown}
                            required
                          />
                          <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                            {errors.current_password}
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group flex-column has-validation">
                          <Label>New Password</Label>
                          <Input
                            type={PasswordInputType}
                            name="password"
                            placeholder=""
                            className="form-control"
                            id="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            onKeyDown={handleKeyDown}
                            required
                          />
                          <span className="icon">{IconToggle}</span>
                          <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                            {errors.password}
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group flex-column has-validation">
                          <Label>Confirm Password</Label>
                          <Input
                            type={PasswordInputType1}
                            name="password_confirmation"
                            placeholder=""
                            className="form-control"
                            id="password_confirmation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password_confirmation}
                            onKeyDown={handleKeyDown}
                            required
                          />
                          <span className="icon">{IconToggle1}</span>
                          <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                            {errors.password_confirmation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_area text-end">
              <div className="container">
                <Button className="btn btn-outline-secondary" type="reset" onClick={onClickCancle}>
                  Cancel
                </Button>
                <Button className="btn btn-success" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordManagement;
