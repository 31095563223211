import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const fsInitialState = {
  loading: false,
  fsGroupList: [],
  securityList: [],
  unlinksecurity: '',
  groupList: [],
  addReliance: '',
  deleteReliance: '',
  editReliance: '',
  updateReliance: '',
  commentData: [],
  refreshcommentData: []
};

export const ProposedFacilitiesSecurity = createSlice({
  name: 'proposedFacilitiesSecurity',
  initialState: fsInitialState,
  reducers: {
    setupdateCommentData: (state, action) => {
      state.commentData = action.payload;
    },
    setrefreshCommentData: (state, action) => {
      state.refreshcommentData = action.payload;
    },
    setGroupList: (state, action) => {
      state.groupList = action.payload;
    },
    setFsGroupList: (state, action) => {
      state.fsGroupList = action.payload;
    },
    setsecurityGroupList: (state, action) => {
      state.securityList = action.payload;
    },
    setUnlinkSecurity: (state, action) => {
      state.unlinksecurity = action.payload;
    },
    setaddReliance: (state, action) => {
      state.addReliance = action.payload;
      let GroupArray = JSON.parse(JSON.stringify(state.groupList));
      GroupArray?.data?.data.length > 0 &&
        GroupArray.data.data.map((item) => {
          let obj = {
            created_at: new Date(),
            fns_group_id: action.payload.id,
            selected_group: action.payload.selected_group,
            type: action.payload.type,
            updated_at: new Date(),
            value: Number(action.payload.value)
          };

          if (action.payload.id == item.id) {
            obj.comment = action.payload.selected_group
              ? `${
                  action.payload.type == 'add' ? 'Add reliance on' : 'Less reliance provided to'
                } ${action.payload.comment}`
              : `${
                  action.payload.type == 'add' ? 'Add reliance on' : 'Less reliance provided to'
                } (External) - ${action.payload.comment}`;
            obj.id = action.payload.reliance_id;
            item.reliance.push(obj);
          } else if (action.payload.selected_group == item.id) {
            obj.comment = action.payload.selected_group
              ? `${
                  action.payload.type == 'add' ? 'Less reliance provided to' : 'Add reliance on'
                } ${action.payload.comment}`
              : `${
                  action.payload.type == 'add' ? 'Less reliance provided to' : 'Add reliance on'
                } (External) - ${action.payload.comment}`;
            obj.type = action.payload.type == 'add' ? 'less' : 'add';
            obj.id = action.payload.related_id;
            item.reliance.push(obj);
          }
        });
      state.groupList = GroupArray;
    },
    setEditReliaceData: (state, action) => {
      state.editReliance = action.payload;
    },
    setupdateReliance: (state, action) => {
      state.updateReliance = action.payload;
    },
    setdetetedReliance: (state, action) => {
      state.deleteReliance = action.payload;
      let GroupArray = JSON.parse(JSON.stringify(state.groupList));
      GroupArray?.data?.data.length > 0 &&
        GroupArray.data.data.map((item) => {
          if (action.payload.groupId == item.id) {
            item.reliance.map((value, index) => {
              if (action.payload.relianceId == value.id) {
                item.reliance.splice(index, 1);
              }
            });
          }
        });
      state.groupList = GroupArray;
    }
  }
});

export const getGroupList =
  ({ jobId, setErrors }) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + jobId + '/group-analysis/proposed-facilities-securities/groups',
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setGroupList(response.data));
    }
  };

export const addReliance = (jobId, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' +
      jobId +
      '/group-analysis/proposed-facilities-securities/groups/' +
      data.id +
      '/reliance',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(
      setaddReliance({
        ...data,
        reliance_id: response.data?.data?.id,
        related_id: response.data?.data?.related_id
      })
    );
    return response;
  }
};

export const deleteReliance = (jobId, relianceId, groupId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' +
      jobId +
      '/group-analysis/proposed-facilities-securities/groups/' +
      groupId +
      '/reliance/' +
      relianceId,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    const obj = { data: response.data, groupId: groupId, relianceId: relianceId };
    dispatch(setdetetedReliance(obj));
    return obj;
  }
};

export const unlinkSecurity =
  (jobId, facilityId, securityId, memberId, activeTab, setErrors) => async (dispatch) => {
    if (activeTab == 2) {
      const response = await clientService.post(
        'jobs/' +
          jobId +
          '/members/' +
          memberId +
          '/securities/' +
          securityId +
          '/facilities/' +
          facilityId,
        {},
        setErrors,
        dispatch
      );
      if (typeof response === 'string') {
        dispatch(setErrorMessage(response));
      } else {
        dispatch(setUnlinkSecurity(response.data));
        return response;
      }
    } else {
      const response = await clientService.post(
        'jobs/' +
          jobId +
          '/members/' +
          memberId +
          '/facilities/' +
          facilityId +
          '/securities/' +
          securityId,
        {},
        setErrors,
        dispatch
      );
      if (typeof response === 'string') {
        dispatch(setErrorMessage(response));
      } else {
        dispatch(setUnlinkSecurity(response.data));
        return response;
      }
    }
  };

export const fsgroupList =
  ({ jobId, setErrors }) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + jobId + '/group-analysis/proposed-facilities-securities/facilities',
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setFsGroupList(response.data));
    }
  };

export const securityGroupList =
  ({ jobId, setErrors }) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + jobId + '/group-analysis/proposed-facilities-securities/securities',
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setsecurityGroupList(response.data));
    }
  };

export const updateComment = (jobId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + jobId + '/group-analysis/proposed-facilities-securities/update-all',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdateCommentData(response.data));
  }
};

export const refreshComment = (jobId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + jobId + '/group-analysis/proposed-facilities-securities/refresh-comments-notes',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setrefreshCommentData(response.data));
  }
};

export const getreliance =
  ({ jobId, fns_group_id, relianceId, setErrors }) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-facilities-securities/groups/' +
        fns_group_id +
        '/reliance/' +
        relianceId,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setEditReliaceData(response.data));
    }
  };

export const editReliancedata =
  (jobId, fns_group_id, relianceId, data, setErrors) => async (dispatch) => {
    const response = await clientService.put(
      'jobs/' +
        jobId +
        '/group-analysis/proposed-facilities-securities/groups/' +
        fns_group_id +
        '/reliance/' +
        relianceId,
      data,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setupdateReliance(response.data));
    }
  };

export const {
  setFsGroupList,
  setsecurityGroupList,
  setUnlinkSecurity,
  setGroupList,
  setaddReliance,
  setdetetedReliance,
  setEditReliaceData,
  setupdateReliance,
  setupdateCommentData,
  setrefreshCommentData
} = ProposedFacilitiesSecurity.actions;

export const ProposedTransactionState = (state) => {
  return state.proposedFacilitiesSecurity;
};

export default ProposedFacilitiesSecurity.reducer;
