import Autocomplete from 'react-google-autocomplete';
import Label from '../../../../../../components/Label';
import CommentsNotes from '../../../../../../components/fields/CommentsNotes';
import FormActions from '../../../../../../components/FormActions';
import PropTypes from 'prop-types';
import { ErrorMessage, FieldArray } from 'formik';
import NumberFormat from 'react-number-format';
import LabelTooltip from '../../../../../../components/tooltip/LabelTooltip';
import label from '../../../../../../LabelConstant';
import Select from 'react-select';
import { formatAddress } from '../../../../../../utils/helper';
import CurrencyInput from 'react-currency-input-field';

const Form = ({
  values,
  errors,
  jobId,
  memberId,
  memberListResponse,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  handleInputChange,
  MemberValue
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    jobId: PropTypes.any,
    memberId: PropTypes.any,
    memberListResponse: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    handleSubmit: PropTypes.any,
    setFieldValue: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    handleInputChange: PropTypes.any,
    MemberValue: PropTypes.object
  };

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  return (
    <form onSubmit={handleSubmit} noValidate className="facility-form form-data mt-30">
      <div className="white-card">
        <div className="rentalview">
          <div className="row">
            <FieldArray
              name="tenant"
              render={() => (
                <>
                  <div className="col-md-6 resi-table">
                    <table className="table half-table">
                      <thead>
                        <tr>
                          <th className="text-start">Property Owner</th>
                          <th className="text-start" style={{ width: '50%' }}>
                            Property Address
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-start" name="owners" style={{ FontWeight: 'bold' }}>
                            <FieldArray
                              name="owners"
                              render={(arrayHelpers) => (
                                <div>
                                  <div className="shareholder">
                                    {values?.owners && values?.owners?.length > 0
                                      ? values?.owners?.map((shareholder, index) => (
                                          <div key={index}>
                                            <Select
                                              className="form-control"
                                              name={`owners.${index}.member_id`}
                                              aria-label="Default select example"
                                              value={
                                                values?.owners[index]?.member_id?.label &&
                                                values?.owners[index]?.member_id?.value
                                                  ? values?.owners[index]?.member_id
                                                  : !values?.owners[index]?.member_id?.label &&
                                                    values?.owners[index]?.member_id?.value ===
                                                      memberId
                                                  ? MemberValue
                                                  : ''
                                              }
                                              onBlur={handleBlur}
                                              classNamePrefix="rentalname"
                                              onChange={(val) => {
                                                setFieldValue(`owners.${index}.member_id`, val);
                                                handleInputChange(val);
                                              }}
                                              options={newColumnsForAll}
                                            ></Select>
                                            <CurrencyInput
                                              className="form-control"
                                              allowNegative={false}
                                              name={`owners.${index}.holding`}
                                              value={
                                                values?.owners[index]?.holding > 100
                                                  ? 0
                                                  : values?.owners[index]?.holding
                                              }
                                              decimalsLimit={2}
                                              onValueChange={(value, name) => {
                                                setFieldValue(name, value);
                                                handleInputChange(value);
                                                // handleChange(e);
                                              }}
                                              onBlur={handleBlur}
                                              style={{ width: '20%', textAlign: 'center' }}
                                              placeholder={'0'}
                                            />
                                            {/* <NumberFormat
                                              decimalScale={0}
                                              placeholder={'0'}
                                              allowNegative={false}
                                              name={`owners.${index}.holding`}
                                              value={
                                                values?.owners[index]?.holding > 100
                                                  ? 0
                                                  : values?.owners[index]?.holding
                                              }
                                              className="form-control"
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(e.target.value);
                                              }}
                                              onBlur={handleBlur}
                                              style={{ width: '20%', textAlign: 'center' }}
                                            /> */}
                                            {/* <Input
                                              type="number"
                                              name={`owners.${index}.holding`}
                                              value={formatNumber(values?.owners[index]?.holding)}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              className="form-control"
                                              placeholder="0"
                                            /> */}
                                            {index !== 0 && (
                                              <i
                                                className="icon-delete"
                                                onClick={() =>
                                                  index !== 0 && arrayHelpers.remove(index)
                                                }
                                              />
                                            )}
                                            <span
                                              className="error"
                                              style={{ color: 'red', fontSize: '20px' }}
                                            >
                                              {errors?.['owners.' + index + '.member_id']
                                                ? 'This field is required'
                                                : ''}

                                              {errors?.['owners.' + index + '.holding']
                                                ? 'percentage  is required'
                                                : ''}
                                            </span>
                                          </div>
                                        ))
                                      : null}
                                  </div>
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '20px' }}
                                  >
                                    {errors?.owner_holding}
                                  </span>
                                  <div className="add-btn">
                                    <i
                                      className="icon-plus"
                                      onClick={() => {
                                        var isEmpty = false;
                                        for (var c = 0; c < values?.owners?.length; c++) {
                                          if (
                                            !values?.owners[c]?.member_id ||
                                            !values?.owners[c]?.holding
                                          ) {
                                            isEmpty = true;
                                            return;
                                          }
                                        }
                                        if (!isEmpty) {
                                          arrayHelpers.push({ member_id: '', holding: '' });
                                        }
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              )}
                            />
                          </td>
                          <td className="text-start">
                            <Autocomplete
                              autoComplete="off"
                              apiKey={process.env.REACT_APP_API_KEY}
                              style={{ width: '100%' }}
                              name="address"
                              className="form-control"
                              placeholder=""
                              Value={values?.address}
                              onChange={(val) => {
                                setFieldValue('address', val?.target?.value);
                              }}
                              onPlaceSelected={(place) => {
                                let formattedAddress = formatAddress(place);
                                setFieldValue('address', formattedAddress);
                                handleInputChange(formattedAddress);
                              }}
                              options={{
                                types: 'street_address',
                                componentRestrictions: { country: ['au', 'nz'] }
                              }}
                            />
                            {/* <Input
                              type="text"
                              name="address"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.address}
                            /> */}
                            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                              {errors?.address}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-6"></div>
                  {values?.tenant && values?.tenant.length > 0
                    ? values?.tenant.map((item, index) => (
                        <>
                          <div className="col-md-6">
                            <div className="group_areaof row" style={{ margin: '0 0 30px' }}>
                              <div className="col-6">
                                <Label>Current Rental Income (Ex GST) $ p.a.</Label>
                                <NumberFormat
                                  decimalScale={0}
                                  allowNegative={false}
                                  name={`tenant.${index}.current_rental_income_pa`}
                                  value={
                                    values?.tenant[index]?.current_rental_income_pa
                                      ? values?.tenant[index]?.current_rental_income_pa
                                      : ''
                                  }
                                  className="form-control"
                                  thousandSeparator={true}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`tenant.${index}.current_rental_income_pa`} />
                                </span>
                              </div>
                              <div className="col-6">
                                <LabelTooltip
                                  labelId={label.rental.residentialShadingTip.key}
                                  label={label.rental.residentialShadingTip.label}
                                  hover_text={label.rental.residentialShadingTip.hover_text}
                                />

                                <NumberFormat
                                  decimalScale={0}
                                  allowNegative={false}
                                  name={`shading`}
                                  value={values?.shading ? values?.shading : ''}
                                  className="form-control"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors?.shading}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    : null}
                </>
              )}
            />
          </div>
        </div>
      </div>
      <CommentsNotes
        values={values ? values : ''}
        errors={errors}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <FormActions
        cancel={'/job/' + jobId + '/data-entry/' + memberId + '/rentals?tab=2'}
        values={values}
      />
    </form>
  );
};

export default Form;
