import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import EditTableRows from './EditTableRows';
import { useDispatch } from 'react-redux';
import { taxState, updateTaxDate } from '../../../../../slices/slices/TaxSlice';

const FixedColumnTableRows = ({
  category,
  data,
  years,
  fixedName,
  type,
  negative,
  handleInputChange,
  handleRemove,
  itemIndex
}) => {
  FixedColumnTableRows.propTypes = {
    category: PropTypes.string,
    data: PropTypes.object,
    years: PropTypes.array,
    fixedName: PropTypes.string,
    type: PropTypes.any,
    negative: PropTypes.bool,
    taxtotal: PropTypes.any,
    handleInputChange: PropTypes.any,
    handleRemove: PropTypes.any,
    itemIndex: PropTypes.number
  };
  const { taxDataTotal } = useSelector(taxState);
  const dispatch = useDispatch();
  const [items, setItems] = useState(
    data?.items
      ? Object.entries(data.items).filter((value) => {
          return value[1].category === category && !Object.keys(value[1]).includes('to_id');
        })
      : []
  );

  // Show new row
  const handleAddRow = () => {
    let emptyDate = {};
    years.forEach((year) => {
      emptyDate[year] = '';
    });
    // let tempItems = JSON.parse(JSON.stringify(items));
    // let index = Object.entries(data.items).length;
    let obj = {};
    obj = {
      title: '',
      data: emptyDate,
      category: category,
      deletable: true
    };
    // tempItems.push(Object.entries(obj)[0]);
    handleUpdate(
      itemIndex +
        data.items.filter(
          (item) => item.category === category && !Object.keys(item).includes('to_id')
        ).length,
      obj
    );
    // setItems(tempItems);
  };

  const handleUpdate = (index, taxData, year = '') => {
    let updates = {};
    updates['key'] = data.key;
    updates['index'] = index;
    updates['data'] = taxData;
    if (year !== '') {
      updates['year'] = year;
    }

    if (taxData !== null) {
      dispatch(updateTaxDate({ ...updates }));
    }
  };

  useEffect(() => {
    let response = Object.entries(data.items).filter((value) => {
      let item = value;
      if (item[1].category === category && !Object.keys(item[1]).includes('to_id')) {
        return item;
      }
    });
    setItems(response);
  }, [data]);

  return (
    <>
      <Table.Row key={category}>
        <Table.Cell
          className="align-left"
          rowSpan={items.length + 1}
          style={{ verticalAlign: 'top' }}
        >
          <i
            className="add"
            onClick={(e) => {
              handleAddRow();
              handleInputChange(e.target.value);
            }}
          >
            +
          </i>
          <strong>{fixedName}</strong>
        </Table.Cell>
      </Table.Row>
      {type ? (
        <EditTableRows
          data_key={data.key}
          items={Object.values(items)}
          years={years}
          colspan="1"
          type={type}
          negative={negative}
          handleInputChange={handleInputChange}
          handleRemove={handleRemove}
          category={category}
          itemIndex={itemIndex}
        />
      ) : (
        <>
          <EditTableRows
            taxtotal={taxDataTotal}
            data_key={data.key}
            items={Object.values(items)}
            years={years}
            colspan="1"
            negative={negative}
            handleInputChange={handleInputChange}
            handleRemove={handleRemove}
            category={category}
            itemIndex={itemIndex}
          />
        </>
      )}
    </>
  );
};

export default FixedColumnTableRows;
