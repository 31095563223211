import React from 'react';
import { uniqkey } from '../../../../../../utils/helper';

const TableRow = ({ theadData, item }) => {
  return (
    <>
      <tr key={item.key}>
        {theadData.map((el) => {
          return (
            <td key={uniqkey()} className={el.className}>
              {item.render && (!item?.renderKey || item?.renderKey.includes(el.key))
                ? item.render(item)
                : el.render
                ? el.render(item[el.key], {
                    ...item
                  })
                : item[el.key]}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default TableRow;
