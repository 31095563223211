import React from 'react';

const Info = () => {
  return (
    <div className="dashboard toggle-sidebar">
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-right">
            <div className="information" style={{ display: 'none' }}>
              <h4>
                Information about
                <br />
                relative screen
              </h4>
              <a href="/business-overview" className="close">
                <i className="icon-close"></i>
              </a>
              <p>
                Lorem ipsum dolor sit amet, consecte tur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
              <p>
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
