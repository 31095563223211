import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FieldArray } from 'formik';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';
import label from '../../../../../LabelConstant';
import ReactTooltip from 'react-tooltip';
import InputMask from 'react-input-mask';
import Input from '../../../../../components/Input';
import Label from '../../../../../components/Label';
import AutomatedSelect from '../../../../../components/fields/AutomatedSelect';
import CommentsNotes from '../../../../../components/fields/CommentsNotes';
import FormActions from '../../../../../components/FormActions';
import { checkExpDate, displayOwnerNameFromType } from '../../../../common/Misc';
import { unformatNumber } from '../../../../common/Misc';
import Button from '../../../../../components/Button';
import LabelTooltip from '../../../../../components/tooltip/LabelTooltip';
import Calculations from '../../../../../utils/calculations';
import {
  getSecuritiesData,
  facilityState,
  manageSecurityRelation
} from '../../../../../slices/slices/FacilitySlice';
import ErrorFocus from '../../../../common/ErrorFocus';
import { commonState, getConfig } from '../../../../../slices/slices/CommonSlice';
import { isEmptyObject } from 'jquery';

const Form = ({
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  sensitiseFacility,
  setSensitiseFacility,
  jobId,
  memberId,
  facilityId,
  securityId,
  jobSettingsDataResponse,
  metaDataResponse,
  memberListResponse,
  handleChange,
  handleBlur,
  handleSubmit,
  formValues,
  setIsDraftPopup,
  handleInputChange,
  MemberValue,
  arrayDisable,
  setArrayDisable,
  // resetForm,
  ...props
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    errors: PropTypes.any,
    sensitiseFacility: PropTypes.any,
    setSensitiseFacility: PropTypes.any,
    jobId: PropTypes.any,
    memberId: PropTypes.any,
    facilityId: PropTypes.any,
    securityId: PropTypes.any,
    jobSettingsDataResponse: PropTypes.any,
    // memberDataResponse: PropTypes.any,
    memberListResponse: PropTypes.any,
    metaDataResponse: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    handleSubmit: PropTypes.any,
    formValues: PropTypes.object,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    setIsDraftPopup: PropTypes.any,
    handleInputChange: PropTypes.any,
    MemberValue: PropTypes.object,
    disabledFields: PropTypes.array,
    arrayDisable: PropTypes.array,
    setArrayDisable: PropTypes.any
    // resetForm: PropTypes.any
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { securitiesDataResponse } = useSelector(facilityState);
  // const [checked, setChecked] = useState();
  //const calculatedFields = Calculations.getFields();
  const [showDraft, setShowDraft] = useState(false);
  const [existOriginal, setExistOriginal] = useState(label.facilities.existingTip);
  const [existOriginalRTM, setExistOriginalRTM] = useState(label.facilities.repayTermTip);
  const calculatedFieldsList = [
    'proposed_limit',
    'interest_rate_pa',
    'repayment_amount',
    'repayment_term_months'
  ];
  const [fieldToCalculate, setFieldToCalculate] = useState('');
  const [creditCardRate, setCreditCardRate] = useState('3.8');

  const { configList } = useSelector(commonState);

  useEffect(() => {
    dispatch(getConfig(configList));
  }, [configList]);

  useEffect(() => {
    if (configList != undefined && !isEmptyObject(configList))
      configList?.credit_card_rate?.forEach((element) => {
        setCreditCardRate(element.report_value);
      });
  }, [configList]);

  useEffect(() => {
    if (
      values?.product === 'asset-finance-chp' ||
      values?.product === 'asset-finance-chattel-mortgage' ||
      values?.product === 'asset-finance-lease'
    ) {
      if (values.facility_status === 'variation') {
        setFieldValue('facility_status', '0');
      }
      switch (values.facility_status) {
        case 'new':
          document.getElementsByName('proposed_limit')[0].disabled = false;
          if (values?.disabledFields.indexOf('proposed_limit') > -1) {
            values.disabledFields = values?.disabledFields
              ?.slice(0, values?.disabledFields.indexOf('proposed_limit') - 1)
              .concat(
                values?.disabledFields?.slice(
                  values?.disabledFields.indexOf('proposed_limit'),
                  values?.disabledFields.length
                )
              );
          }
          // values.disabledFields = values?.disabledFields.filter((e) => e !== 'proposed_limit');
          break;
        default:
          document.getElementsByName('proposed_limit')[0].disabled = true;
          // values.disabledFields = values?.disabledFields.filter((e) => e !== 'proposed_limit');
          break;
      }
      document.getElementsByName('term_remaining_months')[0].disabled = false;
      setExistOriginal(label.facilities.originalTip);
      setExistOriginalRTM(label.facilities.originalRepayTermTip);
    } else if (values?.product === 'credit-card' || values?.product === 'store-card') {
      setExistOriginal(label.facilities.originalTip);
      setExistOriginalRTM(label.facilities.originalRepayTermTip);
    } else if (values?.product === 'business-loan') {
      switch (values.facility_status) {
        case 'existing':
          document.getElementsByName('proposed_limit')[0].disabled = true;
          setExistOriginalRTM(label.facilities.repayTermTip);
          break;
        case 'new':
          document.getElementsByName('proposed_limit')[0].disabled = false;
          setExistOriginalRTM(label.facilities.proposedTermTip);
          break;
        case 'variation':
        case 'refinance':
          document.getElementsByName('proposed_limit')[0].disabled = false;
          setExistOriginalRTM(label.facilities.proposedTermTip);
          break;
      }
    } else {
      setExistOriginal(label.facilities.existingTip);
      setExistOriginalRTM(label.facilities.repayTermTip);
    }
    handleSensitisedRepaymentType(values?.repayment_type);
    disableFields();
  }, [values]);

  //Add all disabled fields in the ArrayDisable based on change of the values.
  function disableFields(fieldToDisable) {
    let array = new Array();
    if (fieldToDisable != null && fieldToDisable != undefined) {
      if (
        document.getElementsByName(fieldToDisable)[0] !== undefined &&
        document.getElementsByName(fieldToDisable)[0].disabled !== undefined &&
        document.getElementsByName(fieldToDisable)[0].disabled
      ) {
        array.push(fieldToDisable);
      } else if (
        document.getElementsByName(fieldToDisable)[0] !== undefined &&
        document.getElementsByName(fieldToDisable)[0].isOptionDisabled !== undefined &&
        document.getElementsByName(fieldToDisable)[0].isOptionDisabled
      ) {
        array.push(fieldToDisable);
      }
    } else {
      Object.keys(values).forEach((element) => {
        if (
          document.getElementsByName(element)[0] !== undefined &&
          document.getElementsByName(element)[0].disabled !== undefined &&
          document.getElementsByName(element)[0].disabled
        ) {
          array.push(element);
        } else if (
          document.getElementsByName(element)[0] !== undefined &&
          document.getElementsByName(element)[0].isOptionDisabled !== undefined &&
          document.getElementsByName(element)[0].isOptionDisabled
        ) {
          array.push(element);
        }
      });
    }

    array.length > 0
      ? setArrayDisable(Array.from(new Set(arrayDisable.concat(array))))
      : setArrayDisable(new Array());

    calculatedFieldsList.forEach((element) => {
      if (
        values?.product === 'credit-card' ||
        values?.product === 'store-card' ||
        (![
          'bank-guarantee',
          'floor-plan-finance',
          'import-finance',
          'line-of-credit',
          'overdraft',
          'trade-finance'
        ].includes(values.product) &&
          values.repayment_type !== 'interest-only')
      )
        document.getElementsByName(element)[0].disabled = false;
    });
    if (values?.disabledFields?.length > 0) {
      values?.disabledFields.forEach((fieldName) => {
        // document.getElementsByName(fieldName)[0].disabled = true; resolved crash by adding below code.
        if (
          document.getElementsByName(fieldName)[0] !== undefined &&
          document.getElementsByName(fieldName)[0].disabled !== undefined &&
          document.getElementsByName(fieldName)[0].disabled
        ) {
          document.getElementsByName(fieldName)[0].disabled = true;
        } else if (
          document.getElementsByName(fieldName)[0] !== undefined &&
          document.getElementsByName(fieldName)[0].isOptionDisabled !== undefined &&
          document.getElementsByName(fieldName)[0].isOptionDisabled
        ) {
          document.getElementsByName(fieldName)[0].isOptionDisabled = true;
        }
      });
    }
  }

  useEffect(() => {
    if (
      fieldToCalculate != undefined &&
      fieldToCalculate != null &&
      values?.disableFields != undefined &&
      values?.disableFields.filter((x) => x === fieldToCalculate) < 1
    )
      disableFields(fieldToCalculate);
  }, [fieldToCalculate]);

  useEffect(() => {
    if (facilityId !== undefined) {
      dispatch(getSecuritiesData(jobId, memberId, facilityId));
    }
  }, []);

  const creditCardDisabled = [
    'repayment_type',
    'balloon',
    'interest_rate_pa',
    'facility_fee',
    'interest_rate_type',
    'fixed_rate_expiry_date_value_init',
    'repayment_term_months',
    'repayment_amount',
    'repayment_frequency',
    'facility_expiry_date_value_init',
    'term_remaining_months',
    'other_fees',
    'amount',
    'frequency'
  ];

  function getSensitisedRepaymentTypeValues() {
    let returnArray = [];
    returnArray = metaDataResponse?.data?.facilities_sensitise_repayment_types;
    switch (values.repayment_type) {
      case 'interest-only':
        return (
          returnArray &&
          returnArray.length > 0 &&
          returnArray.filter(
            (n) =>
              !returnArray
                .slice()
                .splice(
                  returnArray.findIndex((object) => {
                    return object.key === 'simple-interest';
                  }),
                  1
                )
                .includes(n)
          )
        );
      case 'simple-interest':
        return (
          returnArray &&
          returnArray.length > 0 &&
          returnArray.filter(
            (n) =>
              !returnArray
                .slice()
                .splice(
                  returnArray.findIndex((object) => {
                    return object.key === 'interest-only';
                  }),
                  1
                )
                .includes(n)
          )
        );
      case 'interest-capitalisation':
        return (
          returnArray &&
          returnArray.length > 0 &&
          returnArray.filter(
            (n) =>
              !returnArray
                .slice()
                .splice(
                  returnArray.findIndex((object) => {
                    return object.key === 'simple-interest';
                  }),
                  1
                )
                .includes(n)
          )
        );
      default:
        return (returnArray =
          returnArray && returnArray.length == 0
            ? []
            : returnArray &&
              returnArray.length > 0 &&
              returnArray.filter(
                (n) =>
                  !returnArray
                    .slice()
                    .splice(
                      returnArray.findIndex((object) => {
                        return object.key === 'interest-only' || object.key === 'simple-interest';
                      }),
                      1
                    )
                    .includes(n)
              ));
    }
  }

  const handleSensitisedRepaymentType = (type) => {
    if (type) {
      if (type === 'p-i-in-arrears' || type === 'p-i-in-advance') {
        if (document.getElementsByName('sensitise_repayment_type')[0]) {
          document.getElementsByName('sensitise_repayment_type')[0].disabled = true;
        }
      } else {
        if (document.getElementsByName('sensitise_repayment_type')[0]) {
          document.getElementsByName('sensitise_repayment_type')[0].disabled = false;
        }
      }
    }
  };

  function handleExpiryDateByMonth(fieldValue, date) {
    var exMonth =
      fieldValue !== undefined && fieldValue !== null && !isNaN(fieldValue) && fieldValue !== ''
        ? date.getMonth()
        : 0;
    var newMonths =
      fieldValue !== undefined && fieldValue !== null && !isNaN(fieldValue) && fieldValue !== ''
        ? fieldValue
        : 0;
    date.setMonth(parseInt(exMonth) + parseInt(newMonths));
    return date;
  }

  const handleTermRemainingMonths = (name, value, setFunction) => {
    let settlementDate =
      values.facility_status === 'existing' || values.facility_status === 'variation'
        ? new Date()
        : new Date(jobSettingsDataResponse?.anticipated_settlement_date);
    if (name === 'term_remaining_months') {
      handleCurrentBalance(value, 'term_remaining_months', setFunction);
      setFieldValue(
        'facility_expiry_date_value_init',
        handleExpiryDateByMonth(value, settlementDate)
      );
    } else if (name === 'facility_expiry_date_value_init') {
      let facilityDate = value;
      setFunction('facility_expiry_date_value_init', value);
      let years = facilityDate.getFullYear() - settlementDate.getFullYear();

      let months = 1;

      if (years >= 1) {
        // Accurate calculation of months based on days
        months = Math.floor(
          (facilityDate.getDate() - settlementDate.getDate()) / 30 +
            facilityDate.getMonth() -
            settlementDate.getMonth() +
            12 * (facilityDate.getFullYear() - settlementDate.getFullYear())
        );
        // months =
        //   facilityDate.getMonth() -
        //   settlementDate.getMonth() +
        //   12 * (facilityDate.getFullYear() - settlementDate.getFullYear());
        setFieldValue('sensitise_repayment_term_months', months);
        values.facility_status === 'existing' || values.facility_status === 'variation'
          ? setFieldValue(
              'sensitise_facility_expiry_date_value_init',
              handleExpiryDateByMonth(months, settlementDate)
            )
          : setFieldValue(
              'sensitise_facility_expiry_date_value_init',
              handleExpiryDateByMonth(
                months,
                new Date(jobSettingsDataResponse?.anticipated_settlement_date)
              )
            );
      } else if (years < 0) {
        months = 0;
      } else {
        months =
          facilityDate.getMonth() - settlementDate.getMonth() < 0
            ? 0
            : facilityDate.getMonth() - settlementDate.getMonth();
      }
      handleCurrentBalance(months, 'term_remaining_months', setFunction);
    } else if (name === 'sensitise_repayment_term_months') {
      values.facility_status === 'existing' || values.facility_status === 'variation'
        ? setFieldValue(
            'sensitise_facility_expiry_date_value_init',
            handleExpiryDateByMonth(value, settlementDate)
          )
        : setFieldValue(
            'sensitise_facility_expiry_date_value_init',
            handleExpiryDateByMonth(
              value,
              new Date(jobSettingsDataResponse?.anticipated_settlement_date)
            )
          );
    }
  };

  const handleProductChange = (e, setFunction, product = null) => {
    if (!product) {
      product = values.product;
    }
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setFunction(fieldName, fieldValue);
    switch (e.target.value) {
      case 'asset-finance-chp':
      case 'asset-finance-chattel-mortgage':
      case 'asset-finance-lease':
        setFunction('loan', 100);
        // if (document.getElementsByName('proposed_limit')[0].value === '')
        creditCardDisabled.forEach((value) => {
          document.getElementsByName(value)[0].disabled = false;
        });
        document.getElementsByName('term_remaining_months')[0].disabled = false;
        document.getElementsByName('proposed_limit')[0].disabled = true;
        setFunction('interest_rate_type', 'fixed');
        document.getElementsByName('interest_rate_type')[0].disabled = true;
        setFunction('facility_fee', '');
        document.getElementsByName('facility_fee')[0].disabled = true;
        setFunction('other_fees', '');
        document.getElementsByName('other_fees')[0].disabled = true;
        setFunction('amount', '');
        document.getElementsByName('amount')[0].disabled = true;
        setFunction('frequency', '');
        document.getElementsByName('frequency')[0].disabled = true;
        break;

      case 'credit-card':
      case 'store-card':
        setFunction('loan', 0);
        document.getElementsByName('interest_rate_type')[0].disabled = false;
        setFunction('repayment_type', 'sensitised-p-i-at-380-pm');
        setFunction('balloon', '');
        setFunction('interest_rate_pa', '');
        setFunction('fixed_rate_expiry_date_value_init', '');
        setFunction('interest_rate_type', '');
        // setFunction('repayment_amount', '');
        setFunction('repayment_frequency', 12);
        setFunction('repayment_term_months', '');
        setFunction('facility_expiry_date_value_init', null);
        setFunction('sensitise_facility_expiry_date_value_init', null);
        setFunction('term_remaining_months', '');
        setFunction('other_fees', '');
        setFunction('amount', '');
        setFunction('frequency', '');
        creditCardDisabled.forEach((value) => {
          document.getElementsByName(value)[0].disabled = true;
        });
        document.getElementsByName('proposed_limit')[0].disabled = false;
        if (document.getElementsByClassName('sensitised-btn')[0] !== undefined)
          document.getElementsByClassName('sensitised-btn')[0].style.visibility = 'hidden';
        break;

      case 'asset-finance-rental-new-facility-only':
        setFunction('loan', 100);
        setFieldValue('facility_status', 'new');
        creditCardDisabled.forEach((value) => {
          document.getElementsByName(value)[0].disabled = false;
        });
        document.getElementsByName('facility_status')[0].disabled = true;
        break;

      case 'business-loan':
        setFunction('loan', 100);
        creditCardDisabled.forEach((value) => {
          document.getElementsByName(value)[0].disabled = false;
        });
        if (document.getElementsByName('facility_status')[0].value !== 'new') {
          setFunction(
            'proposed_limit',
            unformatNumber(document.getElementsByName('existing_limit')[0].value)
          );
        }
        break;
      case 'home-loan':
      case 'personal-loan':
      case 'hecs':
      case 'buy-now-pay-later':
        creditCardDisabled.forEach((value) => {
          document.getElementsByName(value)[0].disabled = false;
        });
        setFunction('loan', 0);
        break;
      case 'bank-guarantee':
      case 'floor-plan-finance':
      case 'import-finance':
      case 'line-of-credit':
      case 'overdraft':
      case 'trade-finance':
        setFunction('loan', 100);
        creditCardDisabled.forEach((value) => {
          document.getElementsByName(value)[0].disabled = false;
        });
        document.getElementsByName('proposed_limit')[0].disabled = false;
        document.getElementsByName('facility_status')[0].disabled = false;
        if (values.repayment_type === 'interest-only') {
          document.getElementsByName('term_remaining_months')[0].disabled = true;
          document.getElementsByName('repayment_term_months')[0].disabled = true;
          document.getElementsByName('facility_expiry_date_value_init')[0].disabled = true;
        } else {
          document.getElementsByName('term_remaining_months')[0].disabled = true;
          document.getElementsByName('repayment_term_months')[0].disabled = false;
          document.getElementsByName('facility_expiry_date_value_init')[0].disabled = false;
        }
        break;

      default:
        setFunction('loan', 100);
        creditCardDisabled.forEach((value) => {
          document.getElementsByName(value)[0].disabled = false;
        });
        document.getElementsByName('proposed_limit')[0].disabled = false;
        document.getElementsByName('facility_status')[0].disabled = false;
        break;
    }
    if (fieldToCalculate != '') document.getElementsByName(fieldToCalculate)[0].disabled = true;
  };

  const handleCalculatedFields = (value, name, setFunction, repaymnetType = null) => {
    if (!repaymnetType) {
      repaymnetType = formValues.current.values['repayment_type'];
    }
    // default values
    let frequency = 0;
    let type = '';
    let balloon = 0;
    let facility_fee = 0;
    let existing_limit = 0;
    let current_balance = 0;
    let status = '';
    let calculateField = fieldToCalculate;

    // Setting the value
    setFunction(name, value);
    if (name === 'facility_status') {
      status = value;
    } else {
      status = formValues.current.values['facility_status'];
    }
    if (
      (formValues.current.values['product'] === 'asset-finance-chp' ||
        formValues.current.values['product'] === 'asset-finance-chattel-mortgage' ||
        formValues.current.values['product'] === 'asset-finance-lease') &&
      status !== 'new'
    ) {
      if (name === 'existing_limit') {
        existing_limit = value;
      } else {
        existing_limit = formValues.current.values['existing_limit'];
      }
    }

    if (repaymnetType === 'interest-capitalisation') {
      if (formValues.current.values['current_balance'] > 0) {
        current_balance = formValues.current.values['current_balance'];
      } else {
        current_balance = formValues.current.values['proposed_limit'];
      }
    }

    if (name === 'repayment_frequency') {
      frequency = value;
      type = repaymnetType;
      balloon = formValues.current.values['balloon'];
      facility_fee = formValues.current.values['facility_fee'];
    } else if (name === 'repayment_type') {
      setFieldValue('sensitise_repayment_type', type);
      type = value;
      frequency = formValues.current.values['repayment_frequency'];
      balloon = formValues.current.values['balloon'];
      facility_fee = formValues.current.values['facility_fee'];
      props.existingValues['type'] = 'repayment_type';
    } else if (name === 'balloon') {
      balloon = value;
      frequency = formValues.current.values['repayment_frequency'];
      type = repaymnetType;
      facility_fee = formValues.current.values['facility_fee'];
    } else if (name === 'facility_fee') {
      facility_fee = value;
      frequency = formValues.current.values['repayment_frequency'];
      type = repaymnetType;
      balloon = formValues.current.values['balloon'];
    } else {
      frequency = formValues.current.values['repayment_frequency'];
      type = repaymnetType;
      balloon = formValues.current.values['balloon'];
      facility_fee = formValues.current.values['facility_fee'];
    }

    let array = [];
    if (calculatedFieldsList.includes(name)) {
      array = [name];
      props.existingValues[name] = value;
    }
    calculatedFieldsList.forEach((field) => {
      if (!array.includes(field)) {
        if (
          field === 'proposed_limit' &&
          (formValues.current.values['product'] === 'asset-finance-chp' ||
            formValues.current.values['product'] === 'asset-finance-chattel-mortgage' ||
            formValues.current.values['product'] === 'asset-finance-lease') &&
          status !== 'new'
        ) {
          array.push('proposed_limit');
          props.existingValues['proposed_limit'] = existing_limit;
        } else if (formValues.current.values[field] !== '') {
          array.push(field);
          if (repaymnetType === 'interest-capitalisation') {
            props.existingValues['proposed_limit'] = current_balance;
          } else props.existingValues[field] = formValues.current.values[field];
        } else if (repaymnetType === 'interest-capitalisation') {
          props.existingValues['proposed_limit'] = current_balance;
        }
      }
    });
    // calculatedFieldsList.forEach((field) => {
    //   if (!array.includes(field)) {
    //     if (
    //       field === 'proposed_limit' &&
    //       (formValues.current.values['product'] === 'asset-finance-chp' ||
    //         formValues.current.values['product'] === 'asset-finance-chattel-mortgage' ||
    //         formValues.current.values['product'] === 'asset-finance-lease') &&
    //       status !== 'new'
    //     ) {
    //       array.push('proposed_limit');
    //       props.existingValues['proposed_limit'] = existing_limit;
    //     } else if (repaymnetType === 'interest-capitalisation') {
    //       array.push('proposed_limit');
    //       props.existingValues['proposed_limit'] = current_balance;
    //     } else if (formValues.current.values[field] !== '') {
    //       array.push(field);
    //       props.existingValues[field] = formValues.current.values[field];
    //     }
    //   }
    // });
    if (
      array.length === 2 &&
      formValues.current.values['repayment_type'] === 'interest-only' &&
      [
        'bank-guarantee',
        'floor-plan-finance',
        'import-finance',
        'line-of-credit',
        'overdraft',
        'trade-finance'
      ].includes(formValues.current.values['product'])
    ) {
      calculateField = 'repayment_amount';
      setFieldToCalculate('repayment_amount');
    } else if (array.length === 3) {
      let toCalculate = calculatedFieldsList.filter((x) => array.indexOf(x) === -1);
      document.getElementsByName(toCalculate[0])[0].disabled = true;
      if (calculateField === '') {
        calculateField = toCalculate[0];
        setFieldToCalculate(calculateField);
      }
    } else if (type === 'sensitised-p-i-at-380-pm' && array.length === 1) {
      if (calculateField === '') {
        calculateField = 'repayment_amount';
        setFieldToCalculate('repayment_amount');
      }
    }

    if (calculateField !== '') {
      props.existingValues['interest_rate_pa'] = formValues.current.values['interest_rate_pa']
        ? formValues.current.values['interest_rate_pa']
        : 0;
      let newValue = Calculations.calculateValues(
        calculateField,
        props.existingValues,
        type,
        frequency,
        balloon,
        facility_fee,
        creditCardRate
      );
      setFunction(calculateField, newValue);
      props.existingValues[calculateField] = newValue;
      props.existingValues['type'] = type;
      props.existingValues['balloon'] = balloon;
      props.existingValues['facility_fee'] = facility_fee;

      if (
        (formValues.current.values['product'] === 'asset-finance-chp' ||
          formValues.current.values['product'] === 'asset-finance-chattel-mortgage' ||
          formValues.current.values['product'] === 'asset-finance-lease') &&
        formValues.current.values['facility_status'] !== 'new'
      ) {
        handleCurrentBalance(value, name, setFunction, props.existingValues);
      }
    }
    if (
      [
        'bank-guarantee',
        'floor-plan-finance',
        'import-finance',
        'line-of-credit',
        'overdraft',
        'trade-finance'
      ].includes(formValues.current.values['product']) &&
      repaymnetType === 'interest-only'
    ) {
      document.getElementsByName('term_remaining_months')[0].disabled = true;
    } else if (values?.product === 'credit-card' || values?.product === 'store-card') {
      creditCardDisabled.forEach((value) => {
        document.getElementsByName(value)[0].disabled = true;
      });
    } else {
      document.getElementsByName('term_remaining_months')[0].disabled = false;
    }
  };

  const handleCurrentBalance = (value, name, setFunction, existingValues = []) => {
    let remaining = '';
    if (name === 'term_remaining_months') {
      remaining = value;
      setFunction(name, value);
    } else {
      remaining = formValues.current.values['term_remaining_months'];
    }
    if (
      (formValues.current.values['product'] === 'asset-finance-chp' ||
        formValues.current.values['product'] === 'asset-finance-chattel-mortgage' ||
        formValues.current.values['product'] === 'asset-finance-lease') &&
      formValues.current.values['facility_status'] !== 'new' &&
      remaining !== ''
    ) {
      let current_balance = 0;
      if (Object.entries(existingValues).length > 0) {
        current_balance = Calculations.calculateCurrentBalance(
          existingValues['proposed_limit'],
          existingValues['balloon'],
          existingValues['interest_rate_pa'],
          existingValues['facility_fee'],
          existingValues['repayment_amount'],
          existingValues['repayment_term_months'],
          remaining,
          existingValues['type']
        );
      } else {
        current_balance = Calculations.calculateCurrentBalance(
          formValues.current.values['existing_limit'],
          formValues.current.values['balloon'],
          formValues.current.values['interest_rate_pa'],
          formValues.current.values['facility_fee'],
          formValues.current.values['repayment_amount'],
          formValues.current.values['repayment_term_months'],
          remaining,
          formValues.current.values['repayment_type']
        );
      }
      setFunction('current_balance', current_balance);
      setFunction('proposed_limit', current_balance);
    }
  };

  const handleOnChange = (name, value) => {
    if (name === 'interest_rate_pa') {
      const facility_fee =
        formValues.current.values['facility_fee'] !== null
          ? formValues.current.values['facility_fee']
          : 0;
      // if (formValues.current.values['sensitise_interest_rate'] === '') {
      setFieldValue('sensitise_interest_rate', Number(value) + Number(facility_fee));
      // }
    } else if (name === 'facility_fee') {
      const interest_rate_value =
        formValues.current.values['interest_rate_pa'] !== null
          ? formValues.current.values['interest_rate_pa']
          : 0;
      // if (formValues.current.values['sensitise_interest_rate'] === '') {

      setFieldValue('sensitise_interest_rate', Number(value) + Number(interest_rate_value));
      // }
    } else if (name === 'repayment_term_months') {
      setFieldValue('sensitise_repayment_term_months', value);
      if (
        formValues.current.values['facility_status'] === 'refinance' ||
        formValues.current.values['facility_status'] === 'variation' ||
        formValues.current.values['facility_status'] === 'new'
      ) {
        // if (formValues.current.values['term_remaining_months'] === '') {
        setFieldValue(
          'facility_expiry_date_value_init',
          handleExpiryDateByMonth(
            value,
            new Date(jobSettingsDataResponse?.anticipated_settlement_date)
          )
        );
        // }
        // document.getElementsByName('term_remaining_months')[0].disabled = false;
        setFieldValue('term_remaining_months', value);
        // document.getElementsByName('term_remaining_months')[0].disabled = true;
      }
      if (formValues.current.values['product'] === 'business-loan') {
        setFieldValue('term_remaining_months', value);
      }
    } else if (
      name === 'existing_limit' &&
      formValues.current.values['product'] === 'business-loan' &&
      formValues.current.values['facility_status'] !== 'new'
    ) {
      setFieldValue('proposed_limit', value);
    } else if (name === 'facility_status') {
      setFieldValue('term_remaining_months', null);
    }
  };

  const handleSecurityLinking = (e) => {
    let securityId = e.target.value;
    dispatch(manageSecurityRelation(jobId, memberId, facilityId, securityId));
  };

  const handleDraft = () => {
    setShowDraft(false);
  };

  const shouldDisabledByProduct = (values) => {
    if (
      values.product === 'asset-finance-chp' ||
      values.product === 'asset-finance-chattel-mortgage' ||
      values.product === 'asset-finance-lease'
    ) {
      return true;
    } else if (values?.product === 'credit-card' || values?.product === 'store-card') {
      return true;
    } else {
      return false;
    }
  };

  const getClassNameByProduct = (values, opposite = false) => {
    if (
      values.product === 'asset-finance-chp' ||
      values.product === 'asset-finance-chattel-mortgage' ||
      values.product === 'asset-finance-lease'
    ) {
      return opposite ? 'form-field' : 'form-field blue-border';
    } else {
      return opposite ? 'form-field blue-border' : 'form-field';
    }
  };

  const sensitised_tooltip_data =
    'Most lenders require that loan facilities be\n' +
    'sensitised as a buffer when calculating\n' +
    'borrowing capacity.\n\n' +
    'For risk management, lenders typically:\n\n' +
    '- Add a margin to the interest rate to\n' +
    'establish whether borrowers can repay\n' +
    'their loans in the event of future interest\n' +
    'rate rises. The margin will vary between\n' +
    'lenders.\n\n' +
    '- Calculate interest-only facility\n' +
    'repayments on a principal and interest\n' +
    'basis over a notional term to establish\n' +
    'whether borrowers can repay the\n' +
    'principal (not just the interest cost).\n\n' +
    '- Do not sensitise equipment and motor\n' +
    'vehicle facilities, as the interest rate and\n' +
    'repayments are typically fixed.';

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const clearCalculations = () => {
    // resetForm();
    // disabledFieldsTrue();
    calculatedFieldsList.forEach((field) => {
      setFieldValue(field, '');
      setFieldTouched(field, false);
    });
    setFieldValue('existing_limit', '');
    setFieldValue('facility_fee', '');
    setFieldValue('current_balance', '');
    setFieldValue('balloon', '');
    setFieldValue('term_remaining_months', '');
    setFieldValue('facility_expiry_date_value_init', null);
    if (fieldToCalculate) {
      document.getElementsByName(fieldToCalculate)[0].disabled = false;
    }
    props.existingValues = [];
    setFieldToCalculate('');
    // if disabledFields Array has any of the calculatedFieldsList then enable it
    if (values?.disabledFields != undefined && values?.disabledFields != null) {
      calculatedFieldsList.forEach((field) => {
        document.getElementsByName(field)[0].disabled = false;
      });
      let myArray = new Set(calculatedFieldsList);
      values['disabledFields'] = values?.disabledFields.filter((x) => !myArray.has(x));
    }
  };

  // const disabledFieldsTrue = () => {
  //   document.getElementsByName('repayment_type')[0].disabled = false;
  //   document.getElementsByName('lender')[0].disabled = false;
  //   document.getElementsByName('existing_limit')[0].disabled = false;
  //   document.getElementsByName('repayment_type')[0].disabled = false;

  //   document.getElementsByName('current_balance')[0].disabled = false;
  //   document.getElementsByName('balloon')[0].disabled = false;
  //   document.getElementsByName('facility_fee')[0].disabled = false;
  //   document.getElementsByName('interest_rate_type')[0].disabled = false;
  //   document.getElementsByName('fixed_rate_expiry_date_value_init')[0].disabled = false;
  //   document.getElementsByName('repayment_frequency')[0].disabled = false;
  //   document.getElementsByName('facility_expiry_date_value_init')[0].disabled = false;
  //   document.getElementsByName('repayment_amount')[0].disabled = false;
  //   document.getElementsByName('repayment_term_months')[0].disabled = false;
  //   document.getElementsByName('term_remaining_months')[0].disabled = false;
  //   document.getElementsByName('other_fees')[0].disabled = false;
  //   document.getElementsByName('amount')[0].disabled = false;
  //   document.getElementsByName('frequency')[0].disabled = false;
  //   document.getElementsByName('interest_rate_pa')[0].disabled = false;
  // };

  return (
    <form onSubmit={handleSubmit} noValidate className="facility-form form-data mt-30">
      <div className="white-card">
        <table className="table add-facilities">
          <thead>
            <tr>
              <th scope="col" className="align-left" style={{ width: '50%' }}>
                Borrower
              </th>
              <th scope="col" className="align-left" style={{ width: '40%' }}>
                <LabelTooltip
                  labelId={label.security.facilityPurposeTip.key}
                  label={label.security.facilityPurposeTip.label}
                  hover_text={label.security.facilityPurposeTip.hover_text}
                />
              </th>
              <th
                scope="col"
                className="align-left"
                style={{ width: '10%', textAlign: 'center !important' }}
              >
                Loan Deductibility
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-start" name="owners" style={{ FontWeight: 'bold' }}>
                <FieldArray
                  name="owners"
                  render={(arrayHelpers) => (
                    <div>
                      <div className="shareholder">
                        {values?.owners && values?.owners?.length > 0
                          ? values?.owners?.map((shareholder, index) => (
                              <div key={index}>
                                <Select
                                  className="form-control"
                                  classNamePrefix="form-control"
                                  name={`owners.${index}.member_id`}
                                  aria-label="Default select example"
                                  // value={shareholder?.member_id}
                                  value={
                                    values?.owners[index]?.member_id?.label &&
                                    values?.owners[index]?.member_id?.value
                                      ? values?.owners[index]?.member_id
                                      : !values?.owners[index]?.member_id?.label &&
                                        values?.owners[index]?.member_id?.value === memberId
                                      ? MemberValue
                                      : ''
                                  }
                                  onBlur={handleBlur}
                                  onChange={(val) => {
                                    setFieldValue(`owners.${index}.member_id`, val);
                                    handleInputChange(val);
                                  }}
                                  options={newColumnsForAll}
                                ></Select>
                                <CurrencyInput
                                  className="form-control"
                                  name={`owners.${index}.holding`}
                                  value={shareholder?.holding}
                                  // value={values?.balloon ? values?.balloon : ''}
                                  decimalsLimit={2}
                                  onValueChange={(value, name) => {
                                    setFieldValue(name, value);
                                    handleInputChange(value);
                                    // handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />

                                {/* <Input
                                  type="number"
                                  name={`owners.${index}.holding`}
                                  value={formatNumber(shareholder?.holding)}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleInputChange(e.target.value);
                                    handleChange(e);
                                  }}
                                  className="form-control"
                                  placeholder="0"
                                /> */}
                                {index !== 0 && (
                                  <i
                                    className="icon-delete"
                                    onClick={() => index !== 0 && arrayHelpers.remove(index)}
                                  />
                                )}
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors?.['owners.' + index + '.member_id']
                                    ? 'This field is required'
                                    : ''}

                                  {errors?.['owners.' + index + '.holding']
                                    ? 'percentage  is required'
                                    : ''}
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors?.owners}
                        {errors?.owner_holding}
                      </span>
                      <div className="add-btn">
                        <i
                          className="icon-plus"
                          onClick={() => {
                            // var isEmpty = false;
                            // for (var c = 0; c < values?.owners?.length; c++) {
                            //   if (values?.owners[c]?.holding === 0) {
                            //     setErrors({ ...errors, owners: 'enter percentage' });
                            //   } else {
                            //     setErrors({ ...errors, owners: 'select member' });
                            //   }
                            //   if (!values?.owners[c]?.member_id || !values?.owners[c]?.holding) {
                            //     isEmpty = true;
                            //     return;
                            //   }
                            // }
                            // if (!isEmpty) {
                            arrayHelpers.push({
                              member_id: '',
                              holding: ''
                            });
                            // }
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                />
              </td>
              {/* <td scope="row" className="align-left"> */}
              {/*<strong>{displayNameFromType(memberDataResponse)}</strong> */}
              {/*</td> */}
              <td className="align-left">
                <Input
                  type="text"
                  className="form-control"
                  name="purpose"
                  value={values?.purpose ? values?.purpose : ''}
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  style={{ width: '100%' }}
                />
                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                  {errors?.purpose}
                </span>
              </td>
              <td className="align-left loan-block">
                <Input
                  type="text"
                  className="form-control"
                  name="loan"
                  value={values?.loan ? values?.loan : '0'}
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                  {errors.loan}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row d-flex justify-content-end ">
          <a onClick={clearCalculations} className="clear-btn btn">
            Clear Calculations
          </a>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-box">
              <div className="row">
                <div className="col-6">
                  <div className="form-field">
                    <Label>Facility/Product</Label>
                    <AutomatedSelect
                      name="product"
                      value={values?.product ? values?.product : ''}
                      data={metaDataResponse?.data?.facilities_products}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleProductChange(e, setFieldValue, e.target.value);
                        handleInputChange(e.target.value);
                      }}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.product}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <LabelTooltip
                      labelId={label.security.facilityStatusTip.key}
                      label={label.security.facilityStatusTip.label}
                      hover_text={label.security.facilityStatusTip.hover_text}
                    />
                    <select
                      className="form-select"
                      name="facility_status"
                      value={values?.facility_status ? values?.facility_status : ''}
                      onChange={(e) => {
                        handleCalculatedFields(e.target.value, e.target.name, setFieldValue);
                        handleOnChange(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                      aria-label="Default select example"
                    >
                      <option value="0">Select</option>
                      <option value="existing">Existing Facility (no change)</option>
                      {values?.product !== 'asset-finance-chp' &&
                        values?.product !== 'asset-finance-chattel-mortgage' &&
                        values?.product !== 'asset-finance-lease' && (
                          <option value="variation">Existing Facility (variation)</option>
                        )}
                      <option value="refinance">Existing Facility (refinance)</option>
                      <option value="new">New Facility</option>
                    </select>
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.facility_status}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field blue-border">
                    <Label>Repayment Type</Label>
                    <AutomatedSelect
                      name="repayment_type"
                      value={values?.repayment_type ? values?.repayment_type : ''}
                      data={
                        values?.product === 'credit-card' || values?.product === 'store-card'
                          ? metaDataResponse?.data?.facilities_repayment_types
                          : values?.product === 'asset-finance-chp' ||
                            values?.product === 'asset-finance-chattel-mortgage' ||
                            values?.product === 'asset-finance-lease' ||
                            values?.product === 'asset-finance-rental-new-facility-only'
                          ? metaDataResponse?.data?.facilities_repayment_types.filter((el) => {
                              if (el.key === 'p-i-in-arrears' || el.key === 'p-i-in-advance')
                                return el;
                            })
                          : values?.product === 'credit-card' || values?.product === 'store-card'
                          ? metaDataResponse?.data?.facilities_repayment_types
                          : metaDataResponse?.data?.facilities_repayment_types?.filter(
                              (el) => el.key !== 'sensitised-p-i-at-380-pm'
                            )
                        // metaDataResponse?.data?.facilities_repayment_types?.filter(
                        //     (el) => el.key !== 'sensitised-p-i-at-380-pm'
                        //   )
                      }
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleCalculatedFields(
                          e.target.value,
                          e.target.name,
                          setFieldValue,
                          e.target.value
                        );
                        handleInputChange(e.target.value);
                        clearCalculations();
                      }}
                      isOptionDisabled={
                        values?.product === 'credit-card' || values?.product === 'store-card'
                          ? true
                          : false
                      }
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.repayment_type}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>Existing Lender</Label>
                    <AutomatedSelect
                      name="lender"
                      // value={values?.lender ? values?.lender : ''}
                      value={
                        values?.facility_status === 'new'
                          ? ''
                          : values?.lender
                          ? values?.lender
                          : ''
                      }
                      data={metaDataResponse?.data?.facilities_lenders}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleInputChange(e.target.value);
                        handleChange(e);
                      }}
                      isOptionDisabled={values?.facility_status === 'new' ? true : false}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.lender}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={
                      (values.product === 'asset-finance-lease' ||
                        values.product === 'asset-finance-chattel-mortgage' ||
                        values.product === 'asset-finance-chp') &&
                      values.facility_status !== 'new'
                        ? 'form-field blue-border'
                        : 'form-field'
                    }
                  >
                    <LabelTooltip
                      labelId={existOriginal.key}
                      label={existOriginal.label}
                      hover_text={existOriginal.hover_text}
                    />
                    <CurrencyInput
                      className="form-control"
                      name="existing_limit"
                      value={
                        values?.facility_status === 'new'
                          ? 0
                          : values?.existing_limit
                          ? values?.existing_limit
                          : ''
                      }
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        handleCalculatedFields(value, name, setFieldValue);
                        handleInputChange(value);
                        handleOnChange(name, value);
                      }}
                      onBlur={handleBlur}
                      disabled={values?.facility_status === 'new' ? true : false}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.existing_limit}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    {values?.repayment_type === 'interest-capitalisation' ? (
                      <Label>Average drawn balance for next 12 months</Label>
                    ) : (
                      <LabelTooltip
                        labelId={label.facilities.currentBalanceTip.key}
                        label={label.facilities.currentBalanceTip.label}
                        hover_text={label.facilities.currentBalanceTip.hover_text}
                      />
                    )}

                    <CurrencyInput
                      className="form-control"
                      name="current_balance"
                      value={
                        values?.facility_status === 'new' &&
                        values?.repayment_type !== 'interest-capitalisation'
                          ? 0
                          : values?.current_balance && !isNaN(values?.current_balance)
                          ? values?.current_balance
                          : ''
                      }
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        handleCalculatedFields(value, name, setFieldValue);
                        handleInputChange(value);
                      }}
                      onBlur={handleBlur}
                      disabled={
                        values.product === 'asset-finance-lease' ||
                        values.product === 'asset-finance-chattel-mortgage' ||
                        values.product === 'asset-finance-chp' ||
                        (values?.facility_status === 'new' &&
                          values?.repayment_type !== 'interest-capitalisation')
                          ? true
                          : false
                      }
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.current_balance}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={
                      (values.product === 'asset-finance-lease' ||
                        values.product === 'asset-finance-chattel-mortgage' ||
                        values.product === 'asset-finance-chp') &&
                      values.facility_status !== 'new'
                        ? 'form-field'
                        : 'form-field blue-border'
                    }
                  >
                    <LabelTooltip
                      labelId={label.facilities.proposedLimitTip.key}
                      label={label.facilities.proposedLimitTip.label}
                      hover_text={label.facilities.proposedLimitTip.hover_text}
                    />
                    <CurrencyInput
                      // className={
                      //   values.product === 'asset-finance-lease' ||
                      //   values.product === 'asset-finance-chattel-mortgage' ||
                      //   values.product === 'asset-finance-chp'
                      //     ? 'form-field'
                      //     : 'form-field blue-border'
                      // }
                      className="form-control"
                      name="proposed_limit"
                      value={
                        values?.proposed_limit && !isNaN(values?.proposed_limit)
                          ? values?.proposed_limit
                          : ''
                      }
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        handleCalculatedFields(value, name, setFieldValue);
                        handleInputChange(value);
                      }}
                      onBlur={handleBlur}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.proposed_limit}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={
                      values?.repayment_type === 'interest-capitalisation'
                        ? 'form-field'
                        : 'form-field blue-border'
                    }
                  >
                    <Label>Balloon $ (if applicable)</Label>
                    <CurrencyInput
                      className="form-control"
                      name="balloon"
                      value={
                        values?.product === 'credit-card' || values?.product === 'store-card'
                          ? ''
                          : values?.balloon
                          ? values?.balloon
                          : ''
                      }
                      // value={values?.balloon ? values?.balloon : ''}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        handleCalculatedFields(value, name, setFieldValue);
                        handleInputChange(value);
                      }}
                      onBlur={handleBlur}
                      disabled={
                        values?.product === 'credit-card' ||
                        values?.product === 'store-card' ||
                        values?.repayment_type === 'interest-capitalisation'
                          ? true
                          : false
                      }
                    />
                    {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.balloon}
                    </span> */}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field blue-border">
                    <Label>Interest Rate % p.a.</Label>
                    <Input
                      type="text"
                      onChange={(e) => {
                        handleCalculatedFields(e.target.value, e.target.name, setFieldValue);
                        handleInputChange(e.target.value);
                        handleOnChange(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                      name="interest_rate_pa"
                      // value={values?.interest_rate_pa ? values?.interest_rate_pa : ''}
                      value={
                        values?.product === 'credit-card' || values?.product === 'store-card'
                          ? ''
                          : values?.interest_rate_pa && !isNaN(values?.interest_rate_pa)
                          ? values?.interest_rate_pa
                          : ''
                      }
                      className="form-control"
                      disabled={
                        values?.product === 'credit-card' || values?.product === 'store-card'
                          ? true
                          : false
                      }
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.interest_rate_pa}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className={getClassNameByProduct(values, true)}>
                    <LabelTooltip
                      labelId={label.facilities.lineTip.key}
                      label={label.facilities.lineTip.label}
                      hover_text={label.facilities.lineTip.hover_text}
                    />
                    <Input
                      type="text"
                      onChange={(e) => {
                        handleCalculatedFields(e.target.value, e.target.name, setFieldValue);
                        handleInputChange(e.target.value);
                        handleOnChange(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                      name="facility_fee"
                      // value={values?.facility_fee ? values?.facility_fee : ''}
                      value={
                        values?.product === 'credit-card' || values?.product === 'store-card'
                          ? ''
                          : values?.facility_fee
                          ? values?.facility_fee
                          : ''
                      }
                      className="form-control"
                      disabled={shouldDisabledByProduct(values)}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.facility_fee}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>Interest Rate Type</Label>
                    <select
                      className="form-select"
                      name="interest_rate_type"
                      value={
                        values?.product === 'credit-card' || values?.product === 'store-card'
                          ? ''
                          : values?.interest_rate_type
                          ? values?.interest_rate_type
                          : ''
                      }
                      onChange={(e) => {
                        handleChange(e);
                        handleInputChange(e.target.value);
                      }}
                      onBlur={handleBlur}
                      aria-label="Default select example"
                      disabled={shouldDisabledByProduct(values)}
                    >
                      <option value="0">Select</option>
                      <option value="fixed">Fixed</option>
                      <option value="variable">Variable</option>
                    </select>
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.interest_rate_type}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <LabelTooltip
                      labelId={label.facilities.expiryTip.key}
                      label={label.facilities.expiryTip.label}
                      hover_text={label.facilities.expiryTip.hover_text}
                    />
                    <DatePicker
                      selected={
                        values?.fixed_rate_expiry_date_value_init
                          ? new Date(values?.fixed_rate_expiry_date_value_init)
                          : null
                      }
                      onChange={(val) => {
                        setFieldValue('fixed_rate_expiry_date_value_init', val);
                        handleInputChange(val);
                      }}
                      customInput={
                        <InputMask className="form-control" mask="99-99-9999" maskChar={null} />
                      }
                      name="fixed_rate_expiry_date_value_init"
                      dateFormat="dd-MM-yyyy"
                      value={
                        values?.fixed_rate_expiry_date_value_init !== '' &&
                        values?.fixed_rate_expiry_date_value_init !== null
                          ? new Date(values?.fixed_rate_expiry_date_value_init)
                          : null
                      }
                      placeholderText="DD-MM-YYYY"
                      minDate={checkExpDate()}
                      disabled={shouldDisabledByProduct(values)}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.fixed_rate_expiry_date}
                    </span>
                  </div>
                </div>
                {/* <div className="col-6">
                  <div className="form-field">
                    <Label>Interest Rate Identifier</Label>
                    <AutomatedSelect
                      name="interest_rate_identifier"
                      value={
                        values?.interest_rate_identifier ? values?.interest_rate_identifier : ''
                      }
                      data={metaDataResponse?.data?.facilities_interest_rate_identifier}
                      handleBlur={handleBlur}
                      handleChange={(e) => handleChange(e)}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.interest_rate_identifier}
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-box">
              <div className="row">
                <div className="col-6">
                  <div
                    className={
                      values?.repayment_type === 'interest-capitalisation'
                        ? 'form-field'
                        : 'form-field blue-border'
                    }
                  >
                    <Label>
                      {values?.repayment_type === 'interest-capitalisation'
                        ? 'Annual Capitalised interest Amount'
                        : 'Repayment Amount $'}
                    </Label>
                    <CurrencyInput
                      className="form-control"
                      name="repayment_amount"
                      value={
                        values?.repayment_amount && !isNaN(values?.repayment_amount)
                          ? values?.repayment_amount
                          : ''
                      }
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        handleCalculatedFields(value, name, setFieldValue);
                        handleInputChange(value);
                      }}
                      onBlur={handleBlur}
                      disabled={
                        values?.product === 'credit-card' ||
                        values?.product === 'store-card' ||
                        values?.repayment_type === 'interest-capitalisation'
                          ? true
                          : false
                      }
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.repayment_amount}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field blue-border">
                    <Label>
                      {values?.repayment_type === 'interest-capitalisation'
                        ? 'Capitalisation Frequency'
                        : 'Repayment Frequency'}
                    </Label>
                    <AutomatedSelect
                      name="repayment_frequency"
                      value={values?.repayment_frequency ? values?.repayment_frequency : ''}
                      data={metaDataResponse?.data?.payment_frequency}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleCalculatedFields(e.target.value, e.target.name, setFieldValue);
                        handleInputChange(e.target.value);
                      }}
                      isOptionDisabled={
                        values?.product === 'credit-card' || values?.product === 'store-card'
                          ? true
                          : false
                      }
                    />
                    {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.repayment_frequency}
                    </span> */}
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className={
                      (values.product === 'business-loan' &&
                        values.facility_status === 'existing') ||
                      ([
                        'bank-guarantee',
                        'floor-plan-finance',
                        'import-finance',
                        'line-of-credit',
                        'overdraft',
                        'trade-finance'
                      ].includes(values.product) &&
                        values.repayment_type === 'interest-only')
                        ? 'form-field'
                        : 'form-field blue-border'
                    }
                  >
                    <LabelTooltip
                      labelId={existOriginalRTM.key}
                      label={existOriginalRTM.label}
                      hover_text={existOriginalRTM.hover_text}
                    />
                    <Input
                      type="text"
                      onChange={(e) => {
                        handleCalculatedFields(e.target.value, e.target.name, setFieldValue);
                        handleInputChange(e.target.value);
                        handleOnChange(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                      name="repayment_term_months"
                      value={
                        (values.product === 'credit-card' || values?.product === 'store-card') &&
                        values?.repayment_term_months === 1
                          ? ''
                          : values?.repayment_term_months
                          ? values?.repayment_term_months
                          : ''
                      }
                      className="form-control"
                      disabled={
                        values?.product === 'credit-card' ||
                        values?.product === 'store-card' ||
                        ([
                          'bank-guarantee',
                          'floor-plan-finance',
                          'import-finance',
                          'line-of-credit',
                          'overdraft',
                          'trade-finance'
                        ].includes(values.product) &&
                          values.repayment_type === 'interest-only')
                          ? true
                          : false
                      }
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.repayment_term_months}
                    </span>
                  </div>
                </div>
                {/* <div className="col-6">
                  <div className="form-field">
                    <Label>Repayment Term Identifier </Label>
                    <AutomatedSelect
                      name="repayment_term_identifier"
                      value={
                        values?.repayment_term_identifier ? values?.repayment_term_identifier : ''
                      }
                      data={metaDataResponse?.data?.facilities_repayment_term_identifier}
                      handleBlur={handleBlur}
                      handleChange={(e) => handleChange(e)}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.repayment_term_identifier}
                    </span>
                  </div>
                </div> */}

                <div className="col-6">
                  <div className={getClassNameByProduct(values)}>
                    <Label>Facility Expiry Date</Label>
                    <DatePicker
                      selected={
                        values?.facility_expiry_date_value_init
                          ? new Date(values?.facility_expiry_date_value_init)
                          : null
                      }
                      onChange={(e) => {
                        handleTermRemainingMonths(
                          'facility_expiry_date_value_init',
                          e,
                          setFieldValue
                        );
                      }}
                      customInput={
                        <InputMask className="form-control" mask="99-99-9999" maskChar={null} />
                      }
                      name="facility_expiry_date_value_init"
                      dateFormat="dd-MM-yyyy"
                      value={
                        values?.facility_expiry_date_value_init !== '' &&
                        values?.facility_expiry_date_value_init !== null
                          ? new Date(values?.facility_expiry_date_value_init)
                          : null
                      }
                      placeholderText="DD-MM-YYYY"
                      minDate={checkExpDate()}
                      disabled={
                        values?.product === 'credit-card' ||
                        values?.product === 'store-card' ||
                        ([
                          'bank-guarantee',
                          'floor-plan-finance',
                          'import-finance',
                          'line-of-credit',
                          'overdraft',
                          'trade-finance'
                        ].includes(values.product) &&
                          values.repayment_type === 'interest-only')
                          ? true
                          : false
                      }
                    />

                    {/* <Input
                      type="date"
                      pattern="\d{2}-\d{2}-\d{4}"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="datepicker"
                      name="facility_expiry_date_init"
                      value={values?.facility_expiry_date_init}
                      className="form-control hasDatepicker"
                      min={disablePastDate()}
                    /> */}
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.facility_expiry_date}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className={getClassNameByProduct(values)}>
                    <LabelTooltip
                      labelId={label.facilities.remainingTip.key}
                      label={label.facilities.remainingTip.label}
                      hover_text={label.facilities.remainingTip.hover_text}
                    />

                    <Input
                      type="text"
                      onChange={(e) => {
                        handleTermRemainingMonths(e.target.name, e.target.value, setFieldValue);
                        handleChange(e);
                        handleInputChange(e.target.value);
                      }}
                      onBlur={handleBlur}
                      name="term_remaining_months"
                      value={values?.term_remaining_months ? values?.term_remaining_months : ''}
                      className="form-control"
                      disabled={
                        [
                          'bank-guarantee',
                          'floor-plan-finance',
                          'import-finance',
                          'line-of-credit',
                          'overdraft',
                          'trade-finance'
                        ].includes(values.product) && values.repayment_type === 'interest-only'
                          ? true
                          : false
                      }
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.term_remaining_months}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <LabelTooltip
                      labelId={label.facilities.otherFeesTip.key}
                      label={label.facilities.otherFeesTip.label}
                      hover_text={label.facilities.otherFeesTip.hover_text}
                    />
                    <AutomatedSelect
                      name="other_fees"
                      value={values?.other_fees ? values?.other_fees : ''}
                      data={metaDataResponse?.data?.facilities_other_fees}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleChange(e);
                        handleInputChange(e.target.value);
                      }}
                      isOptionDisabled={shouldDisabledByProduct(values)}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.other_fees}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>Amount $</Label>
                    <CurrencyInput
                      className="form-control"
                      name="amount"
                      value={values?.amount ? values?.amount : ''}
                      onValueChange={(value, name) => {
                        handleCalculatedFields(value, name, setFieldValue);
                        handleInputChange(value);
                      }}
                      onBlur={handleBlur}
                      disabled={shouldDisabledByProduct(values)}
                    />
                    {/* <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="amount"
                      value={values?.amount}
                      className="form-control"
                    /> */}
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.amount}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>Frequency</Label>
                    <AutomatedSelect
                      name="frequency"
                      value={values?.frequency ? values?.frequency : ''}
                      data={metaDataResponse?.data?.payment_frequency}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleChange(e);
                        handleInputChange(e.target.value);
                      }}
                      isOptionDisabled={shouldDisabledByProduct(values)}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.frequency}
                    </span>
                  </div>
                </div>
                {values?.product !== 'credit-card' &&
                values?.product !== 'store-card' &&
                values?.product !== 'asset-finance-chp' &&
                values?.product !== 'asset-finance-chattel-mortgage' &&
                values?.product !== 'asset-finance-lease' &&
                values?.product !== 'asset-finance-rental-new-facility-only' &&
                values?.product !== 'insurance-premium-funding' ? (
                  <div className="sensitised-btn">
                    <ReactTooltip id="sensitisedBtn" place="top" effect="solid">
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          fontSize: '14px'
                        }}
                      >
                        {sensitised_tooltip_data}
                      </p>
                    </ReactTooltip>
                    <button
                      data-tip
                      data-for="sensitisedBtn"
                      type="button"
                      className="btn green"
                      onClick={(e) => {
                        handleInputChange(e.target.value);
                        if (sensitiseFacility === 1) {
                          setSensitiseFacility(0);
                          values.sensitise_facility = 0;

                          values.facility_status === 'existing' ||
                          values.facility_status === 'variation'
                            ? setFieldValue(
                                'sensitise_facility_expiry_date_value_init',
                                handleExpiryDateByMonth(values?.term_remaining_months, new Date())
                              )
                            : values?.sensitise_repayment_term_months != null ||
                              values?.sensitise_repayment_term_months != undefined
                            ? setFieldValue(
                                'sensitise_facility_expiry_date_value_init',
                                handleExpiryDateByMonth(
                                  values?.term_remaining_months,
                                  new Date(jobSettingsDataResponse?.anticipated_settlement_date)
                                )
                              )
                            : setFieldValue('sensitise_facility_expiry_date_value_init', null);
                        } else {
                          values.sensitise_interest_rate = values?.sensitise_interest_rate
                            ? values?.sensitise_interest_rate
                            : values?.interest_rate_pa;
                          values.sensitise_repayment_term_months =
                            values?.sensitise_repayment_term_months
                              ? values?.sensitise_repayment_term_months
                              : values?.term_remaining_months;
                          setSensitiseFacility(1);
                          values.sensitise_facility = 1;

                          setFieldValue('sensitise_facility_expiry_date_value_init', null);

                          handleSensitisedRepaymentType(values?.repayment_type);
                          setFieldValue('sensitise_repayment_type', values?.repayment_type);
                        }

                        /*let settlementDate =
                          values.facility_status === 'existing' ||
                          values.facility_status === 'variation'
                            ? new Date()
                            : new Date(jobSettingsDataResponse?.anticipated_settlement_date);
                        values.facility_status === 'existing' ||
                        values.facility_status === 'variation'
                          ? setFieldValue(
                              'sensitise_facility_expiry_date_value_init',
                              handleExpiryDateByMonth(values?.term_remaining_months, settlementDate)
                            )
                          : setFieldValue(
                              'sensitise_facility_expiry_date_value_init',
                              handleExpiryDateByMonth(
                                values?.term_remaining_months,
                                new Date(jobSettingsDataResponse?.anticipated_settlement_date)
                              )
                            );*/
                      }}
                    >
                      Sensitise Facility
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {values?.product !== 'credit-card' &&
          values?.product !== 'store-card' &&
          values?.product !== 'asset-finance-chp' &&
          values?.product !== 'asset-finance-chattel-mortgage' &&
          values?.product !== 'asset-finance-lease' &&
          values?.product !== 'asset-finance-rental-new-facility-only' &&
          values?.product !== 'insurance-premium-funding' &&
          sensitiseFacility === 1 && (
            <div className="row">
              <div className="col-md-9 offset-3 sentised-form">
                <div className="w-100">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-4">
                        <div className="form-field">
                          <Label>Repayment Type</Label>
                          <AutomatedSelect
                            name="sensitise_repayment_type"
                            value={
                              values?.sensitise_repayment_type
                                ? values?.sensitise_repayment_type
                                : values?.repayment_type
                                ? values?.repayment_type
                                : ''
                            }
                            // data={metaDataResponse?.data?.facilities_sensitise_repayment_types}
                            data={getSensitisedRepaymentTypeValues().filter(
                              (item) => item.key !== 'sensitised-p-i-at-380-pm'
                            )}
                            handleBlur={handleBlur}
                            handleChange={(e) => {
                              handleChange(e);
                              handleInputChange(e.target.value);
                            }}
                          />
                          <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {errors.sensitise_repayment_type}
                          </span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-field">
                          <Label>Interest Rate (incl. Line / Facility Fee) % p.a.</Label>
                          <NumberFormat
                            decimalScale={2}
                            allowNegative={false}
                            name="sensitise_interest_rate"
                            value={values?.sensitise_interest_rate}
                            className="form-control"
                            thousandSeparator={false}
                            onChange={(e) => {
                              handleChange(e);
                              handleInputChange(e.target.value);
                            }}
                            onBlur={handleBlur}
                          />
                          {/* <Input
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                            handleInputChange(e.target.value);
                          }}
                          onBlur={handleBlur}
                          name="sensitise_interest_rate"
                          value={
                            values?.sensitise_interest_rate ? values?.sensitise_interest_rate : ''
                          }
                          className="form-control"
                        /> */}
                          <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {errors.sensitise_interest_rate}
                          </span>
                        </div>
                      </div>
                      {/* <div className="col-4">
                      <div className="form-field">
                        <Label>Interest Rate Assumption</Label>
                        <AutomatedSelect
                          name="sensitise_interest_rate_assumption"
                          value={
                            values?.sensitise_interest_rate_assumption
                              ? values?.sensitise_interest_rate_assumption
                              : ''
                          }
                          data={
                            metaDataResponse?.data?.facilities_sensitise_interest_rate_assumptions
                          }
                          handleBlur={handleBlur}
                          handleChange={(e) => handleChange(e)}
                        />
                        <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                          {errors.sensitise_interest_rate_assumption}
                        </span>
                      </div>
                    </div> */}
                      <div className="col-4">
                        <div className="form-field ">
                          <Label>Repayment Term in Months</Label>
                          <Input
                            type="text"
                            name="sensitise_repayment_term_months"
                            value={values?.sensitise_repayment_term_months}
                            onChange={(e) => {
                              handleTermRemainingMonths(
                                e.target.name,
                                e.target.value,
                                setFieldValue
                              );
                              handleChange(e);
                              handleInputChange(e.target.value);
                            }}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                          <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {errors.sensitise_repayment_term_months}
                          </span>
                        </div>
                      </div>
                      {/* <div className="col-4">
                      <div className="form-field">
                        <Label>Repayment Term Assumption</Label>
                        <AutomatedSelect
                          name="sensitise_repayment_term_assumption"
                          value={
                            values?.sensitise_repayment_term_assumption
                              ? values?.sensitise_repayment_term_assumption
                              : ''
                          }
                          data={
                            metaDataResponse?.data?.facilities_sensitise_repayment_term_identifier
                          }
                          handleBlur={handleBlur}
                          handleChange={(e) => handleChange(e)}
                        />
                        <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                          {errors.sensitise_repayment_term_assumption}
                        </span>
                      </div>
                    </div> */}
                      {/* <div className="col-4">
                      <div className="form-field">
                        <Label>Facility Expiry Date</Label>
                        <DatePicker
                          selected={
                            values?.sensitise_facility_expiry_date_value_init !== '' &&
                            values?.sensitise_facility_expiry_date_value_init !== null
                              ? new Date(values?.sensitise_facility_expiry_date_value_init)
                              : null
                          }
                          onChange={(val) => {
                            setFieldValue('sensitise_facility_expiry_date_value_init', val);
                          }}
                          customInput={
                            <InputMask className="form-control" mask="99-99-9999" maskChar={null} />
                          }
                          name="sensitise_facility_expiry_date_value_init"
                          dateFormat="dd-MM-yyyy"
                          value={new Date(values?.sensitise_facility_expiry_date_value_init)}
                          placeholderText="DD-MM-YYYY"
                          minDate={checkExpDate()}
                        />

                        <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                          {errors.sensitise_facility_expiry_date}
                        </span>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {securityId === undefined && facilityId === undefined ? (
          <div className="attach-table">
            <div className="attach-title">
              {/* <h4>Attach Security</h4> */}
              <a
                onClick={() => {
                  setShowDraft(true);
                }}
              >
                <button className="button btn small-btn" type="button">
                  <i className="icon-plus"></i> Security
                </button>
              </a>
            </div>
          </div>
        ) : null}
        {facilityId !== undefined ? (
          <div className="attach-table">
            <div className="attach-title">
              <h4>Attach Security</h4>
              <a
                onClick={() => {
                  navigate(
                    '/job/' +
                      jobId +
                      '/data-entry/' +
                      memberId +
                      '/securities/return/facility/' +
                      facilityId
                  );
                }}
              >
                <button className="button btn small-btn" type="button">
                  <i className="icon-plus"></i> Security
                </button>
              </a>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"> </th>
                  <th scope="col" className="align-left">
                    Security Owner
                  </th>
                  <th scope="col" className="align-left">
                    Address
                  </th>
                  <th scope="col">Description</th>
                  <th scope="col">Market Value</th>
                  <th scope="col">Shading</th>
                  <th scope="col">Lending Value</th>
                </tr>
              </thead>
              <tbody>
                {securitiesDataResponse?.data?.related?.length === 0 &&
                  securitiesDataResponse?.data?.not_related?.length === 0 && (
                    <tr>
                      <td colSpan="11">No Data Found</td>
                    </tr>
                  )}

                {securitiesDataResponse?.data?.related
                  ? securitiesDataResponse?.data?.related.map((item) => {
                      return (
                        <tr key={item?.id}>
                          <td scope="row">
                            <Input
                              type="checkbox"
                              className="text-success"
                              defaultChecked="true"
                              value={item?.id}
                              onChange={(e) => {
                                handleInputChange(e.target.value);
                                handleSecurityLinking(e);
                              }}
                            ></Input>
                          </td>
                          <td className="align-left">
                            {displayOwnerNameFromType(item?.security_owners)}
                          </td>
                          <td className="align-left">{item?.address}</td>
                          <td>{item?.asset_description_val}</td>
                          <td className="align-right">
                            <NumberFormat
                              value={item?.market_value}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              displayType="text"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={item?.shading}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              suffix="%"
                              displayType="text"
                            />
                          </td>
                          <td className="align-right">
                            <NumberFormat
                              value={item?.lending_value}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              displayType="text"
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
                {securitiesDataResponse?.data?.not_related
                  ? securitiesDataResponse?.data?.not_related.map((item) => {
                      return (
                        <tr key={item?.id}>
                          <td scope="row">
                            <Input
                              type="checkbox"
                              className="text-success"
                              value={item?.id}
                              onChange={(e) => {
                                // handleInputChange(e.target.value);
                                handleSecurityLinking(e);
                              }}
                            ></Input>
                          </td>
                          <td className="align-left">
                            {displayOwnerNameFromType(item?.security_owners)}
                          </td>
                          <td className="align-left">{item?.address}</td>
                          <td>{item?.asset_description_val}</td>
                          <td className="align-right">
                            <NumberFormat
                              value={item?.market_value}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              displayType="text"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={item?.shading}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              suffix="%"
                              displayType="text"
                            />
                          </td>
                          <td className="align-right">
                            <NumberFormat
                              value={item?.lending_value}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              displayType="text"
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
      <CommentsNotes
        values={values ? values : ''}
        errors={errors}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <FormActions
        cancel={'/job/' + jobId + '/data-entry/' + memberId + '/facilities'}
        values={values}
      />
      <ErrorFocus />
      <Modal
        show={showDraft}
        onHide={handleDraft}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Add Security?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Do you you want to save facility as a draft?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button
            type="submit"
            name="is_completed"
            className="btn btn-primary"
            onClick={() => {
              values.is_completed = 0;
              setIsDraftPopup(1);
              handleSubmit();
              setShowDraft(false);
            }}
          >
            Yes
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setIsDraftPopup(0);
              setShowDraft(false);
            }}
          >
            No
          </Button>
        </div>
      </Modal>
    </form>
  );
};

export default Form;
