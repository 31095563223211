import React, { useState, useEffect } from 'react';
import TableRow from './TableRow';
import { useParams, useNavigate } from 'react-router-dom';
import TableHeadItem from './TableHead';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../../../../pages/common/Misc';
import AddRelianceModel from '../../AddReliance';
import CustomModal from '../../../../../CustomModal';
import LabelTooltip from '../../../../../tooltip/LabelTooltip';
import label from '../../../../../../LabelConstant';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProposedTransactionState,
  getreliance
} from '../../../../../../slices/slices/ProposedFacilitiesSecuritySlice';
const GroupTables = (props) => {
  GroupTables.propTypes = {
    tbodyData: PropTypes.any,
    customClass: PropTypes.any,
    detailComponent: PropTypes.any,
    groupData: PropTypes.any,
    deleteReliancedata: PropTypes.any
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  let { tbodyData, customClass, groupData, deleteReliancedata } = props;
  const [showModel, setShowModel] = useState(false);
  const [editReliance, setEditReliance] = useState();
  const [relianceId, setRelianceId] = useState();
  const [showRelienceModel, setshowRelienceModel] = useState(false);
  const [tableData, setTableData] = useState(tbodyData.table1);
  const [tableData2, setTableData2] = useState(tbodyData.table2);
  let theadData = {};

  const facilitiesGroupData = useSelector(ProposedTransactionState);

  const showAddRelienceModel = () => {
    setEditReliance([]);
    setRelianceId('');
    setshowRelienceModel(true);
  };

  const handleCancel = () => {
    setShowModel(false);
  };

  const handleOnEdit = (relianceId, fns_group_id) => {
    dispatch(getreliance({ jobId, fns_group_id, relianceId })).then(() => {
      setRelianceId(relianceId);
      setshowRelienceModel(true);
    });
  };

  useEffect(() => {
    setEditReliance(facilitiesGroupData?.editReliance);
  }, [facilitiesGroupData]);

  const handleOnClick = (relianceId) => {
    setRelianceId(relianceId);
    setShowModel(true);
  };

  const onhandleCancel = () => {
    setshowRelienceModel(false);
  };

  theadData.table1 = [
    {
      key: 'borrower',
      className: 'text-start',
      title: 'Borrower'
    },
    {
      key: 'purpose',
      className: 'text-start',
      title: 'Purpose'
    },
    {
      key: 'lender',
      className: 'text-center',
      title: 'Existing Lender '
    },
    {
      key: 'status',
      className: 'text-center',
      title: 'Status'
    },
    {
      key: 'existinglimit',
      className: 'text-end',
      title: 'Existing Limit',
      render: (item) => formatNumber(item)
    },
    {
      key: 'change',
      className: 'text-end',
      title: 'Change +/-',
      render: (item) => formatNumber(item)
    },
    {
      key: 'proposedlimit',
      className: 'text-end',
      title: 'Proposed Limit',
      render: (item) => formatNumber(item)
    },
    {
      key: 'action',
      className: 'text-center',
      title: '',
      render: (item, record) => {
        return (
          <>
            <i
              className={
                record?.is_completed == 1 ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'
              }
              aria-hidden="true"
              style={{
                marginRight: '16px',
                color: `${record?.is_completed == 1 ? '#42ba96' : '#eed202'}`
              }}
            ></i>
            <a className="edit" style={{ marginRight: 0 }}>
              <i
                className="icon-edit"
                onClick={() => onedithandleClick(record.key, record.memberId, 'facilities')}
              ></i>
            </a>
          </>
        );
      }
    }
  ];

  theadData.table2 = [
    {
      key: 'securityowner',
      className: 'text-start',
      title: 'Security Owner'
    },
    {
      key: 'address',
      className: 'text-start adress',
      title: 'Address'
    },
    {
      key: 'assetclass',
      className: 'text-center',
      title: 'Class'
    },
    {
      key: 'assetusages',
      className: 'text-center',
      title: 'Usage'
    },
    {
      key: 'assetDescription',
      className: 'text-center',
      title: '  Description'
    },
    {
      key: 'marketvalue',
      className: 'text-end',
      title: 'Market Value',
      render: (item) => formatNumber(item)
    },
    {
      key: 'shading',
      className: 'text-center',
      title: 'Shading'
    },
    {
      key: 'landingvalue',
      className: 'text-end',
      title: 'Lending Value',
      render: (item) => formatNumber(item)
    },
    {
      key: 'action',
      className: 'act_area',
      title: '',
      render: (item, record) => {
        return (
          <>
            <i
              className={
                record?.is_completed == 1 ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'
              }
              aria-hidden="true"
              style={{
                marginRight: '10px',
                color: `${record?.is_completed == 1 ? '#42ba96' : '#eed202'}`
              }}
            />
            <a className="edit" style={{ marginRight: '0' }}>
              <i
                className="icon-edit"
                onClick={() => onedithandleClick(record.key, record.memberId, 'securities')}
              />
            </a>
          </>
        );
      }
    }
  ];

  const onedithandleClick = (Id, memberId, Type) => {
    navigate('/job/' + jobId + '/data-entry/' + memberId + '/' + Type + '/' + Id);
  };

  useEffect(() => {
    const table1 = tbodyData.table1.sort((a, b) => a.position - b.position);
    setTableData(table1);
    const table2 = tbodyData.table2.sort((a, b) => a.position - b.position);
    setTableData2(table2);
  }, [tbodyData]);

  return (
    <>
      <table className={customClass} key={tbodyData.position}>
        <thead>
          <tr>
            {theadData?.table1.map((h) => {
              return <TableHeadItem key={h.key} keyvalue={h.key} item={h.title} />;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 ? (
            tableData.map((item) => {
              return (
                <>
                  <TableRow
                    key={item.key}
                    theadData={theadData.table1}
                    setTableData={setTableData}
                    item={item}
                  />
                </>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Found
              </td>
            </tr>
          )}
          <tr className="total">
            <td colSpan="4" className="text-start">
              Total
            </td>
            <td className="text-end">{formatNumber(tbodyData?.existinglimit)}</td>
            <td className="text-end">{formatNumber(tbodyData?.changevalue)}</td>
            <td className="text-end">{formatNumber(tbodyData?.proposedlimit)}</td>
            <td></td>
          </tr>
          <tr>
            <td className="gray groupfs" colSpan="10">
              <table className="table innerowner">
                <thead>
                  <tr>
                    {theadData.table2.map((h) => {
                      return (
                        <TableHeadItem
                          key={h.key}
                          keyvalue={h.key}
                          sorting={h.sort}
                          item={h.title}
                        />
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData2.length > 0 ? (
                    <>
                      {tableData2.map((item) => {
                        return (
                          <>
                            <TableRow
                              key={item.key}
                              theadData={theadData.table2}
                              tableData={tableData2}
                              setTableData={setTableData2}
                              item={item}
                            />
                          </>
                        );
                      })}

                      {tbodyData?.reliance.map((val) => {
                        return (
                          <tr key={val.id}>
                            <td colSpan="5" className="text-start">
                              {val?.comment}
                            </td>
                            <td className="text-end">
                              {val.type == 'add'
                                ? formatNumber(val.value)
                                : `(${formatNumber(val.value)})`}
                            </td>
                            <td className="text-center"></td>
                            <td className="text-end">
                              {val.type == 'add'
                                ? formatNumber(val.value)
                                : `(${formatNumber(val.value)})`}
                            </td>
                            <td className="text-center">
                              {val.selected_group ? (
                                <a className="edit" style={{ 'margin-right': '10px' }}>
                                  <i
                                    className="icon-edit"
                                    onClick={() => handleOnEdit(val.id, val.fns_group_id)}
                                  ></i>
                                </a>
                              ) : (
                                ''
                              )}
                              <a>
                                <i
                                  className="icon-delete"
                                  style={{ color: 'gray' }}
                                  onClick={() => handleOnClick(val.id)}
                                ></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td className="text-start" colSpan="6">
                          <a className="link" onClick={() => showAddRelienceModel()}>
                            Insert Reliance
                          </a>
                          <LabelTooltip
                            className="link"
                            labelId={label.groupFS.reliance.key}
                            // label={label.groupFS.reliance.label}
                            hover_text={label.groupFS.reliance.hover_text}
                          />
                        </td>
                        <td className="text-end"></td>
                        <td className="text-end"></td>
                        <td className="text-center"></td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="9">No Data Found</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-start">
                      Total
                    </td>
                    <td colSpan="3" className="text-end">
                      {formatNumber(tbodyData?.marketvalue)}
                    </td>
                    <td></td>

                    <td className="text-end">{formatNumber(tbodyData?.landingvalue)}</td>

                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="6" className="text-start">
              Surplus / (Deficit) Equity
            </td>
            <td className="text-end">{formatNumber(tbodyData?.finaltotal)}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      <AddRelianceModel
        show={showRelienceModel}
        groupData={groupData}
        currentGroupId={tbodyData.position}
        selectedGroup={tbodyData.group_id}
        onCancel={onhandleCancel}
        editReliance={editReliance}
        relianceId={relianceId}
      />
      <CustomModal
        value="Delete"
        show={showModel}
        title="Delete Reliance?"
        text="Are you sure you want to Delete this Reliance?"
        cancel={handleCancel}
        success={() => deleteReliancedata(relianceId, tbodyData.group_id, handleCancel)}
      />
    </>
  );
};

export default GroupTables;
