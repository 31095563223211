import React from 'react';
import PropTypes from 'prop-types';
import FixedColumnTableRows from './FixedColumnTableRows';

const EBIT = ({ data, years, categoryList, handleInputChange }) => {
  EBIT.propTypes = {
    data: PropTypes.object,
    years: PropTypes.array,
    categoryList: PropTypes.array,
    percentageCalculation: PropTypes.func,
    handleInputChange: PropTypes.any
  };

  return (
    <>
      {categoryList.map((item, index) => {
        return (
          <FixedColumnTableRows
            key={index}
            category={item.key}
            data={data}
            years={years}
            handleInputChange={handleInputChange}
            fixedName={item.name}
            noAddition={true}
          />
        );
      })}
    </>
  );
};

export default EBIT;
