import React from 'react';
import { Plock } from 'react-plock';
import Item from '../../components/job/groupAnalysis/debtServicing/Item';
export default function Admin(props) {
  const { tbodyData, handleOneditClick, handleOnClick } = props;
  const breakpoints = [
    { size: 640, columns: 1 },
    { size: 768, columns: 2 },
    { size: 1024, columns: 3 }
  ];

  return (
    <>
      <div
        key={1}
        className="tab-pane fade show active admin-setting"
        id="commercial-justified"
        role="tabpanel"
        aria-labelledby="commercial-tab"
      >
        <Plock nColumns={breakpoints}>
          {tbodyData.map((item, index) => {
            return (
              <div className="item" key={index}>
                <div className="corporate-structure-blocks company">
                  <h4>
                    {item.title}
                    <button type="button">
                      <i className="fa fa-edit" onClick={() => handleOneditClick(item)}></i>
                    </button>
                    <button type="button" style={{ marginLeft: '10px' }}>
                      <i className="fa fa-trash-alt" onClick={() => handleOnClick(item.id)}></i>
                    </button>
                  </h4>
                  <ul className="admin-setting" key={index}>
                    <Item itemData={item?.detailData} />
                  </ul>
                </div>
              </div>
            );
          })}
        </Plock>
      </div>
    </>
  );
}
