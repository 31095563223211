import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import { Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import FormActions from '../../../../components/FormActions';
import TableData from '../../../../components/job/groupAnalysis/taxCalculation/TableData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  taxCalculationState,
  getTaxCalculationData,
  getPersonalTaxCalculationData,
  updateTaxCalculation,
  deleteTax,
  setDeleteTaxDataResponse
} from '../../../../slices/slices/TaxCalculationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { displayNameFromType } from '../../../common/Misc';
import moment from 'moment';
import Button from '../../../../components/Button';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';

export default function TaxCalculations() {
  const dispatch = useDispatch();
  const {
    taxCalculationDataResponse,
    personalTaxCalculationDataResponse,
    deleteTaxDataResponse,
    taxCalculationResponse
  } = useSelector(taxCalculationState);
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const [memberId, setMemberid] = useState();
  const [activeTab, setactiveTab] = useState(1);
  const [internalNotes, setInternalNotes] = useState();
  const [reportComments, setReportComments] = useState();
  const [internalNotesForPersonal, setInternalNotesForPersonal] = useState();
  const [reportCommentsForPersonal, setReportCommentsForPersonal] = useState();
  const [record_type, setRecordtype] = useState();

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    setInternalNotes(
      taxCalculationDataResponse?.internal_notes ? taxCalculationDataResponse?.internal_notes : ''
    );
  }, [taxCalculationDataResponse?.internal_notes]);

  useEffect(() => {
    setReportComments(
      taxCalculationDataResponse?.report_comments ? taxCalculationDataResponse?.report_comments : ''
    );
  }, [taxCalculationDataResponse?.report_comments]);

  useEffect(() => {
    setInternalNotesForPersonal(
      personalTaxCalculationDataResponse?.internal_notes
        ? personalTaxCalculationDataResponse?.internal_notes
        : ''
    );
  }, [personalTaxCalculationDataResponse?.internal_notes]);

  useEffect(() => {
    setReportCommentsForPersonal(
      personalTaxCalculationDataResponse?.report_comments
        ? personalTaxCalculationDataResponse?.report_comments
        : ''
    );
  }, [personalTaxCalculationDataResponse?.report_comments]);

  useEffect(() => {
    activeTab === 1
      ? dispatch(getTaxCalculationData(jobId))
      : dispatch(getPersonalTaxCalculationData(jobId));
  }, [taxCalculationResponse]);

  const handlesetActiveTab = (tab) => {
    setactiveTab(tab);
  };

  const delTax = () => {
    setShowDelete(false);
    dispatch(deleteTax(jobId, memberId));
    dispatch(getTaxCalculationData(jobId));
    dispatch(getPersonalTaxCalculationData(jobId));
  };

  const handleDelete = () => {
    setShowDelete(false);
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    values.type = activeTab === 1 ? 'business' : 'personal';
    if (record_type) {
      values.is_refresh = 1;
      values.record_type = record_type;
    }
    dispatch(updateTaxCalculation(jobId, values, setErrors));
    setRecordtype('');
  };

  const businessHead = [
    {
      key: 'sr_no',
      headClassName: 'text-start',
      className: 'text-start',
      title: 'Sr No.'
    },
    {
      key: 'member_name',
      headClassName: 'text-start',
      className: 'text-start',
      title: 'Member Name'
    },
    {
      key: 'created_date',
      headClassName: 'text-center',
      className: 'text-center',
      title: 'Created Date'
    },
    {
      key: 'action',
      headClassName: 'text-center',
      className: 'text-center',
      title: 'Action',
      render: (item, record) => {
        return (
          <>
            {record?.record?.taxes?.length > 0 ? (
              <>
                <a>
                  <i
                    className={
                      record?.record?.taxes[0]?.is_completed === 1
                        ? 'fa fa-check-circle'
                        : 'fa fa-exclamation-triangle'
                    }
                    aria-hidden="true"
                    style={{
                      color: `${
                        record?.record?.taxes[0]?.is_completed === 1 ? '#42ba96' : '#eed202'
                      }`
                    }}
                  ></i>
                </a>
                <a
                  className="edit"
                  onClick={() => {
                    navigate('/job/' + jobId + '/data-entry/' + record?.record?.id + '/tax', {
                      state: { type: record?.type }
                    });
                  }}
                >
                  <i className="icon-edit"></i>
                </a>
                <a
                  onClick={() => {
                    setShowDelete(true);
                    setMemberid(record?.record?.id);
                  }}
                  className="delete"
                >
                  <i className="icon-delete"></i>
                </a>
              </>
            ) : (
              <a
                className="notstarted"
                onClick={() => {
                  navigate('/job/' + jobId + '/data-entry/' + record?.record?.id + '/tax', {
                    state: { type: record?.type }
                  });
                }}
              >
                <i className="fas fa-folder-plus"></i>
              </a>
            )}
          </>
        );
      }
    }
  ];

  const business = () => {
    let temp;
    if (taxCalculationDataResponse && taxCalculationDataResponse?.data?.length > 0) {
      temp = taxCalculationDataResponse?.data?.map(
        (share, index) =>
          share.type != 'sole-trader' &&
          share.type != 'partnership' && {
            sr_no: index + 1,
            member_name: displayNameFromType(share),
            created_date: moment(share?.created_at).format('DD/MM/YYYY'),
            action: '',
            record: share
          }
      );
    } else {
      temp = [];
    }
    return temp;
  };

  const businessTax = () => {
    let temp;
    if (taxCalculationDataResponse && taxCalculationDataResponse?.data?.length > 0) {
      temp = taxCalculationDataResponse?.data?.map(
        (share) =>
          share.type != 'sole-trader' &&
          share.type != 'partnership' && {
            member_name: displayNameFromType(share),
            record: share
          }
      );
    } else {
      temp = [
        {
          member_name: '',
          record: ''
        }
      ];
    }
    return temp;
  };

  // personal table
  const personalHead = [
    {
      key: 'sr_no',
      headClassName: 'text-start',
      className: 'text-start',
      title: 'Sr No.'
    },
    {
      key: 'member_name',
      headClassName: 'text-start',
      className: 'text-start',
      title: 'Member Name'
    },
    {
      key: 'created_date',
      headClassName: 'text-center',
      className: 'text-center',
      title: 'Created Date'
    },
    {
      key: 'action',
      headClassName: 'text-center',
      className: 'text-center',
      title: 'Action',
      render: (item, record) => {
        return (
          <>
            {record?.record?.taxes?.length > 0 ? (
              <>
                <a>
                  <i
                    className={
                      record?.record?.taxes[0]?.is_completed === 1
                        ? 'fa fa-check-circle'
                        : 'fa fa-exclamation-triangle'
                    }
                    aria-hidden="true"
                    style={{
                      color: `${
                        record?.record?.taxes[0]?.is_completed === 1 ? '#42ba96' : '#eed202'
                      }`
                    }}
                  ></i>
                </a>
                <a
                  className="edit"
                  onClick={() => {
                    navigate('/job/' + jobId + '/data-entry/' + record?.record?.id + '/tax', {
                      state: { type: record?.type }
                    });
                  }}
                >
                  <i className="icon-edit"></i>
                </a>
                <a
                  onClick={() => {
                    setShowDelete(true);
                    setMemberid(record?.record?.id);
                  }}
                  className="delete"
                >
                  <i className="icon-delete"></i>
                </a>
              </>
            ) : (
              <a
                className="notstarted"
                onClick={() => {
                  navigate('/job/' + jobId + '/data-entry/' + record?.record?.id + '/tax', {
                    state: { type: record?.type }
                  });
                }}
              >
                <i className="fas fa-folder-plus"></i>
              </a>
            )}
          </>
        );
      }
    }
  ];

  const personal = () => {
    let temp;
    if (
      personalTaxCalculationDataResponse &&
      personalTaxCalculationDataResponse?.data?.length > 0
    ) {
      temp = personalTaxCalculationDataResponse?.data?.map(
        (share, index) =>
          share.type != 'sole-trader' &&
          share.type != 'partnership' && {
            sr_no: index + 1,
            member_name: displayNameFromType(share),
            created_date: moment(share?.created_at).format('DD/MM/YYYY'),
            action: '',
            record: share
          }
      );
    } else {
      temp = [];
    }
    return temp;
  };
  const personalTax = () => {
    let temp;
    if (
      personalTaxCalculationDataResponse &&
      personalTaxCalculationDataResponse?.data?.length > 0
    ) {
      temp = personalTaxCalculationDataResponse?.data?.map(
        (share) =>
          share.type != 'sole-trader' &&
          share.type != 'partnership' && {
            member_name: displayNameFromType(share),
            record: share
          }
      );
    } else {
      temp = [
        {
          member_name: '',
          record: ''
        }
      ];
    }
    return temp;
  };

  useEffect(() => {
    if (deleteTaxDataResponse?.message) {
      setShowDelete(false);
      dispatch(getTaxCalculationData(jobId));
      dispatch(getPersonalTaxCalculationData(jobId));
      dispatch(setDeleteTaxDataResponse({}));
    }
  }, [deleteTaxDataResponse?.message]);

  useEffect(() => {
    dispatch(getTaxCalculationData(jobId));
    dispatch(getPersonalTaxCalculationData(jobId));
  }, [showDelete]);

  const initialValue = {
    report_comments: activeTab === 1 ? reportComments || '' : reportCommentsForPersonal || '',
    internal_notes: activeTab === 1 ? internalNotes || '' : internalNotesForPersonal || '',
    is_completed: 0
  };

  return (
    <>
      <div className="dashboard toggle-sidebar">
        <Header />
        <main id="main" className="main">
          <DialogLeavingPage
            showDialog={showDialogLeavingPage}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <Link to={'/job/' + jobId + '/dashboard/'}>Dashboard</Link>
                </li>
                <li>
                  <a href="#">Group Analysis</a>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Tax Calculations</strong>
                </li>
              </ul>
            </div>
            <div className="breadcrumb-right"></div>
          </div>
          <div className="pagetitle groupanalysispro">
            <div className="title_pro d-flex justify-content-between align-items-center">
              {activeTab === 1 ? (
                <h1>Business Tax Calculations</h1>
              ) : (
                <h1>Personal Tax Calculations</h1>
              )}
            </div>
          </div>
          <Form className="facility-form form-data">
            <div className="white-card">
              <div className="rentalview">
                <ul className="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 1 ? ' active' : ''}`}
                      id="commercial-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#commercial-justified"
                      type="button"
                      role="tab"
                      aria-controls="commercial"
                      aria-selected="true"
                      onClick={() => handlesetActiveTab(1)}
                    >
                      Business
                    </button>
                  </li>
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 2 ? ' active' : ''}`}
                      id="residential-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#residential-justified"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="false"
                      onClick={() => handlesetActiveTab(2)}
                    >
                      Personal
                    </button>
                  </li>
                </ul>
                <div className="tab-content pt-2" id="myTabjustifiedContent">
                  {activeTab == 1 ? (
                    <TableData
                      theadData={businessHead}
                      tbodyData={business()}
                      taxData={businessTax()}
                      years={taxCalculationDataResponse?.years}
                    />
                  ) : (
                    <TableData
                      theadData={personalHead}
                      tbodyData={personal()}
                      taxData={personalTax()}
                      years={personalTaxCalculationDataResponse?.years}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
          <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              setFieldValue
            }) => {
              return (
                <Form onSubmit={handleSubmit} className="facility-form form-data">
                  <CommentsNotes
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange}
                    handleBlur={handleBlur}
                    isRefresh={true}
                    setRecordtype={setRecordtype}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                  />
                  <div className="action-box full">
                    <FormActions
                      cancel={'/job/' + jobId + '/group-analysis/groupserviceability'}
                      values={values}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </main>
        <Modal
          show={showDelete}
          onHide={handleDelete}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <h2>Delete Tax Calculation?</h2>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this tax calculation permanently?</p>
          </Modal.Body>
          <div className="modal-action modal-footer">
            <Button className="btn btn-secondary" onClick={handleDelete}>
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                delTax();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}
