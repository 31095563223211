import React from 'react';

export default function Tableinfo(props) {
  const { title } = props;
  return (
    <>
      <div className="title_pro d-flex justify-content-between align-items-center">
        <h1> {title}</h1>
        {/* <table className="table">
          <thead>
            <tr className="offgray">
              <th className="text-center">*</th>
              <th className="text-center">Actual</th>
              <th className="text-center">Estimated</th>
              <th className="text-center">Sensitised</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">Abbreviation</td>
              <td className="text-center">A</td>
              <td className="text-center">E</td>
              <td className="text-center">S</td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </>
  );
}
