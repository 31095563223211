import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CommonLayout from './CommonLayout';

const AdjustedEBITDA = ({ data, years, categoryList, handleRemove, handleInputChange }) => {
  AdjustedEBITDA.propTypes = {
    data: PropTypes.object,
    years: PropTypes.array,
    categoryList: PropTypes.array,
    percentageCalculation: PropTypes.func,
    handleRemove: PropTypes.any,
    handleInputChange: PropTypes.func
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    let temp = [];
    let obj = {};
    const setArr = () => {
      for (let item of Object.keys(obj)) {
        temp.push({ [item]: obj[item] });
      }
    };
    for (let item of data.items) {
      if (!item?.category) {
        obj[item.category ? item.category : null] = [item];
        setArr();
        obj = {};
        // temp.push({ [item.category]: [item] });
      } else if (Object.keys(obj).includes(item.category)) {
        obj[item.category ? item.category : null].push(item);
      } else {
        obj[item.category ? item.category : null] = [item];
      }
    }
    setArr();
    setItems(temp);
  }, [data]);

  return (
    <>
      {/* {categoryList &&
        categoryList.map((item, index) => {
          return (
            <FixedColumnTableRows
              key={index}
              category={item.key}
              data={data}
              years={years}
              fixedName={item.name}
              type={item?.option}
              handleRemove={handleRemove}
              handleInputChange={handleInputChange}
            />
          );
        })} */}
      <CommonLayout
        items={items}
        data={data}
        years={years}
        handleInputChange={handleInputChange}
        handleRemove={handleRemove}
        noAddition={false}
        categoryList={categoryList}
      />
      {/* {items.map((item, index) => {
        if (Object.keys(item).length > 0 && Object.keys(item)[0] !== 'null') {
          return (
            <FixedColumnTableRows
              key={1}
              category={Object.keys(item)[0]}
              data={data}
              years={years}
              handleInputChange={handleInputChange}
              fixedName={Object.keys(item)[0]}
              noAddition={true}
            />
          );
        } else {
          if (
            item &&
            item[Object.keys(item)[0]] &&
            Object.values(item[Object.keys(item)[0]]).length > 0
          ) {
            return (
              <Table.Row key="rows" id={data?.key} className={child_class}>
                <Table.Cell
                  colSpan={
                    years.length == 4
                      ? '10'
                      : years.length == 3
                      ? '8'
                      : years.length == 2
                      ? '6'
                      : '4'
                  }
                >
                  <Table celled className="table">
                    <Table.Body>
                      <EditTableRows
                        negative={negative}
                        negativeList={['Rental Expenses', 'Trading Income']}
                        handleRemove={handleRemove}
                        handleInputChange={handleInputChange}
                        data_key={data?.key}
                        items={Object.entries(item[Object.keys(item)[0]])}
                        itemIndex={items
                          .slice(0, index)
                          .map((item) => Object.values(item)[0]?.length)
                          .reduce((partialSum, a) => partialSum + a, 0)}
                        years={years}
                        colspan="2"
                      />
                    </Table.Body>
                  </Table>
                </Table.Cell>
              </Table.Row>
            );
          }
        }
      })} */}
    </>
  );
};

export default AdjustedEBITDA;
