import React from 'react';

export default function Info() {
  return (
    <div className="row offset-12" style={{ padding: '0 15px', textAlign: 'right' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="report-legend">
        <ul>
          <li className="completed">Completed</li>
          <li className="incompleted">Incomplete</li>
        </ul>
        {/* <i className="fa fa-check-circle" style={{ color: '#42ba96', margin: '3px' }}></i>
        <p style={{ color: '#000', fontSize: '14px', margin: '3px' }}>COMPLETED</p>
        <i className="fa fa-exclamation-triangle" style={{ color: '#eed202', margin: '2px' }}></i>
        <p style={{ color: '#000', fontSize: '14px', margin: '2px' }}>INCOMPLETE</p> */}
      </div>
    </div>
  );
}
