import PropTypes from 'prop-types';
import { Table, TableCell } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import EditTableRows from './EditTableRows';
import { formatNumber } from '../../../../common/Misc';
import NumberFormat from 'react-number-format';
import TableHeadTooltip from '../../../../../components/tooltip/TableHeadTooltip';
import tableHead from '../../../../../TableHeadConstant';
import { useDispatch, useSelector } from 'react-redux';
import { updateFinancialDate } from '../../../../../slices/slices/FinancialsSlice';
import { dataEntryState } from '../../../../../slices/slices/DataEntrySlice';
import FixedColumnTableRows from '../components/FixedColumnTableRows';

const Category = ({
  data,
  years,
  totalPercentage,
  negative,
  handleRemove,
  handleInputChange,
  manageExtrap
}) => {
  Category.propTypes = {
    data: PropTypes.object,
    years: PropTypes.array,
    totalPercentage: PropTypes.func,
    negative: PropTypes.bool,
    handleRemove: PropTypes.func,
    handleInputChange: PropTypes.any,
    manageExtrap: PropTypes.func
  };
  const dispatch = useDispatch();
  const { selectedMember } = useSelector(dataEntryState);
  // const [items, setItems] = useState(Object.entries(data?.items ? data?.items : ''));
  const [items1, setItems1] = useState([]);
  const [totals, setTotals] = useState(data?.totals);

  let child_class = 'collapse in';
  let collapse_parent = {};
  if (data?.is_expandable === true) {
    collapse_parent = {
      'data-toggle': 'collapse',
      'data-target': '.' + data.key,
      className: 'clickable'
    };
  }
  if (data?.is_total === true) {
    collapse_parent = {
      className: 'total_row'
    };
  }

  const getFields = () => {
    let content = [];
    for (let i = 0; i < years.length * 2; i++) {
      content.push(<Table.Cell key={i} />);
    }
    return content;
  };

  // Show new row
  const handleAddRow = (title) => {
    let emptyDate = {};
    years.forEach((year) => {
      emptyDate[year] = '';
    });
    // let tempItems = JSON.parse(JSON.stringify(items));
    // let index = Object.entries(data.items).length;
    let obj = {
      title: '',
      data: emptyDate,
      key: title,
      deletable: true
    };
    handleUpdate(data.items.length, obj);

    // tempItems.push(Object.entries(obj)[0]);
    // setItems(tempItems);
  };

  const handleUpdate = (index, financialData, year = '') => {
    let updates = {};
    updates['key'] = data.key;
    updates['index'] = index;
    updates['data'] = financialData;
    if (year !== '') {
      updates['year'] = year;
    }
    if (financialData !== null) {
      dispatch(updateFinancialDate({ ...updates, type: selectedMember.type }));
    }
  };

  useEffect(() => {
    setTotals(data?.totals);
    let financialData = JSON.parse(JSON.stringify(data));
    let temp = [];
    let obj = {};
    const setArr = () => {
      for (let item of Object.keys(obj)) {
        temp.push({ [item]: obj[item] });
      }
    };
    for (let item of financialData.items) {
      if (!item?.category && item?.bundleName) {
        item.category = item?.bundleName;
      }
    }
    for (let item of financialData.items) {
      if (!item?.category) {
        obj[item.category ? item.category : null] = [item];
        setArr();
        obj = {};
        // temp.push({ [item.category]: [item] });
      } else if (Object.keys(obj).includes(item.category)) {
        obj[item.category ? item.category : null].push(item);
      } else {
        obj[item.category ? item.category : null] = [item];
      }
    }
    setArr();
    setItems1(temp);
  }, [data]);

  const capitalize = (s) => {
    return s
      .split(' ')
      .map((item) => item[0].toUpperCase() + item.slice(1))
      .join(' ');
  };

  return (
    <>
      {data?.title !== null &&
      ['trading-income', 'cost-of-sales', 'other-income', 'operating-expenses'].includes(
        data?.key
      ) ? (
        <Table.Row key={data?.key} {...collapse_parent}>
          <Table.Cell colSpan="2">
            <div className="d-flex justify-content-between">
              {data?.key === 'trading-income' && (
                <>
                  <TableHeadTooltip
                    labelId={tableHead.businessFinancial.tradingIncomeTip.key}
                    label={tableHead.businessFinancial.tradingIncomeTip.label}
                    hover_text={tableHead.businessFinancial.tradingIncomeTip.hover_text}
                  />
                  {data.is_expandable === true ? <i className="fa fa-caret-down" /> : null}
                </>
              )}
            </div>
            <div className="d-flex justify-content-between">
              {data?.key === 'cost-of-sales' && (
                <>
                  <TableHeadTooltip
                    labelId={tableHead.businessFinancial.costSaleTip.key}
                    label={tableHead.businessFinancial.costSaleTip.label}
                    hover_text={tableHead.businessFinancial.costSaleTip.hover_text}
                  />
                  {data.is_expandable === true ? <i className="fa fa-caret-down" /> : null}
                </>
              )}
            </div>
            <div className="d-flex justify-content-between">
              {data?.key === 'other-income' && (
                <>
                  <TableHeadTooltip
                    labelId={tableHead.businessFinancial.otherIncomeTip.key}
                    label={tableHead.businessFinancial.otherIncomeTip.label}
                    hover_text={tableHead.businessFinancial.otherIncomeTip.hover_text}
                  />
                  {data.is_expandable === true ? <i className="fa fa-caret-down" /> : null}
                </>
              )}
            </div>
            <div className="d-flex justify-content-between">
              {data?.key === 'operating-expenses' && (
                <>
                  <TableHeadTooltip
                    labelId={tableHead.businessFinancial.operatingExpensesTip.key}
                    label={tableHead.businessFinancial.operatingExpensesTip.label}
                    hover_text={tableHead.businessFinancial.operatingExpensesTip.hover_text}
                  />
                  {data.is_expandable === true ? <i className="fa fa-caret-down" /> : null}
                </>
              )}
            </div>
          </Table.Cell>
        </Table.Row>
      ) : null}
      {getFields()}
      {items1 &&
        items1.length > 0 &&
        items1.map((item, index) => {
          if (Object.keys(item).length > 0 && Object.keys(item)[0] !== 'null') {
            return (
              <FixedColumnTableRows
                key={index}
                category={Object.keys(item)[0]}
                data={data}
                years={years}
                handleInputChange={handleInputChange}
                fixedName={capitalize(Object.keys(item)[0])}
                noAddition={true}
                itemIndex={
                  items1
                    .slice(0, index)
                    .map((item) => Object.values(item)[0]?.length)
                    .reduce((partialSum, a) => partialSum + a, 0)
                    ? items1
                        .slice(0, index)
                        .map((item) => Object.values(item)[0]?.length)
                        .reduce((partialSum, a) => partialSum + a, 0)
                    : 0
                }
                manageExtrap={manageExtrap}
              />
            );
          } else {
            if (
              item &&
              item[Object.keys(item)[0]] &&
              Object.values(item[Object.keys(item)[0]]).length > 0
            ) {
              return (
                <Table.Row key={index} id={data?.key} className={data?.key + ' ' + child_class}>
                  <Table.Cell
                    colSpan={
                      years.length == 4
                        ? '10'
                        : years.length == 3
                        ? '8'
                        : years.length == 2
                        ? '6'
                        : '4'
                    }
                  >
                    <Table celled className="table">
                      <Table.Body>
                        <EditTableRows
                          manageExtrap={manageExtrap}
                          negative={negative}
                          negativeList={['rental-expenses']}
                          handleRemove={handleRemove}
                          handleInputChange={handleInputChange}
                          data_key={data?.key}
                          items={Object.entries(item[Object.keys(item)[0]])}
                          itemIndex={
                            items1
                              .slice(0, index)
                              .map((item) => Object.values(item)[0]?.length)
                              .reduce((partialSum, a) => partialSum + a, 0)
                              ? items1
                                  .slice(0, index)
                                  .map((item) => Object.values(item)[0]?.length)
                                  .reduce((partialSum, a) => partialSum + a, 0)
                              : 0
                          }
                          years={years}
                          colspan="2"
                        />
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>
              );
            }
          }
        })}

      {totals !== undefined ? (
        <Table.Row key={totals.key} className="total_row">
          <Table.Cell colSpan="2">
            <i
              className="add"
              onClick={(e) => {
                handleAddRow(data?.title);
                handleInputChange(e.target.value);
              }}
            >
              +
            </i>

            {totals.title}
          </Table.Cell>
          {years.map((year) => {
            return totals.data?.[year] !== undefined ? (
              <>
                <TableCell key={year} className="align-right">
                  <span>{formatNumber(totals.data[year])}</span>
                </TableCell>
                <Table.Cell key="year">
                  {totalPercentage ? (
                    <span className="blue_text">{totalPercentage(year, totals.data[year])}</span>
                  ) : (
                    <span></span>
                  )}
                </Table.Cell>
              </>
            ) : (
              <>
                <TableCell key={year} className="align-right">
                  <NumberFormat
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    onChange={(e) => {
                      handleInputChange(e.target.value);
                      //TODO: Check if data is not updating.
                      // handleChange(e);
                    }}
                    decimalScale={0}
                    displayType="text"
                  />
                </TableCell>
                <Table.Cell />
              </>
            );
          })}
        </Table.Row>
      ) : null}
    </>
  );
};

export default Category;
