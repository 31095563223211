import { Formik } from 'formik';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setSettingSendEmailResponse,
  settingState,
  settingSendEmailOTP
} from '../../../slices/slices/SettingSlice';
import { BackUpSchema } from '../../common/Validation';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

const AddBackupEmail = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { settingEmailOTPResponse } = useSelector(settingState);

  const handleFormSubmit = (values, { setErrors }) => {
    dispatch(settingSendEmailOTP(values, setErrors));
  };

  useEffect(() => {
    if (settingEmailOTPResponse.message) {
      if (settingEmailOTPResponse) {
        navigate('/confirm-email');
        dispatch(setSettingSendEmailResponse({}));
      }
    }
  }, [settingEmailOTPResponse.message]);

  return (
    <div className="setting toggle-sidebar">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <p onClick={() => navigate(-1)} className="backlink">
            Back
          </p>
          <div className="container two-factor-screen">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="text-center">
                  <h4>Add a backup email</h4>
                  <p>
                    This email is only used to log into your account if youre locked out or dont
                    have your phone.
                  </p>
                  <p>It must be different to the email you log in with.</p>
                </div>
                <Formik
                  initialValues={{
                    backup_email: ''
                  }}
                  validationSchema={BackUpSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="row needs-validation" noValidate>
                      <div className="col-12">
                        <div className="input-group has-validation">
                          <Input
                            type="text"
                            name="backup_email"
                            placeholder="Backup email address"
                            className="form-control"
                            id="yourUsername"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.backup_email}
                            required
                          />
                          <div className="invalid-feedback">Please enter your username.</div>
                        </div>
                        <span className="error" style={{ color: 'red', fontSize: '16px' }}>
                          {errors.backup_email}
                        </span>
                      </div>
                      <div className="col-12 topgap btnarea justify-content-center">
                        <Button className="btn btn-primary w-100" type="submit">
                          Continue
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default AddBackupEmail;
