import { roundOff } from '../pages/common/Misc';
import { financialKeyValuePair } from '../slices/slices/FinancialsSlice';

export const calculateGrossProfit = (year, financialData, isDisplay = false) => {
  let grossProfit = 0;
  if (financialData['trading-income']) {
    if (financialData['trading-income'].totals?.data?.[year]) {
      grossProfit += Number(financialData['trading-income'].totals.data[year]);
    }
  }
  if (financialData['cost-of-sales']) {
    if (financialData['cost-of-sales'].totals?.data?.[year]) {
      grossProfit += Number(financialData['cost-of-sales'].totals.data[year]);
    }
  }
  return isDisplay
    ? // grossProfit > 0
      //   ? Math.floor(grossProfit)
      //   : Math.ceil(grossProfit)
      roundOff(grossProfit)
    : grossProfit;
};

export const calculateNetProfit = (year, financialData, isDisplay = false) => {
  let netProfit = calculateGrossProfit(year, financialData, isDisplay);
  if (financialData['other-income']) {
    if (financialData['other-income']?.totals?.data?.[year]) {
      netProfit += Number(financialData['other-income'].totals.data[year]);
    }
  }
  if (financialData['operating-expenses']) {
    if (financialData['operating-expenses']?.totals?.data?.[year]) {
      netProfit += Number(financialData['operating-expenses'].totals.data[year]);
    }
  }
  return isDisplay
    ? // (netProfit > 0 ? Math.floor(netProfit) : Math.ceil(netProfit))
      roundOff(netProfit)
    : netProfit;
};

export const calculateEbit = (year, financialData, isDisplay = false) => {
  let ebit = calculateNetProfit(year, financialData, isDisplay);
  if (financialData['ebit']) {
    if (financialData['ebit']?.totals?.data?.[year]) {
      ebit += Number(financialData['ebit'].totals.data[year]);
    }
  }
  return isDisplay
    ? // (ebit > 0 ? Math.floor(ebit) : Math.ceil(ebit))
      roundOff(ebit)
    : ebit;
};

export const calculateEbitda = (year, financialData, isDisplay = false) => {
  let ebitda = calculateEbit(year, financialData, isDisplay);
  if (financialData['ebitda']) {
    if (financialData['ebitda']?.totals && financialData['ebitda']?.totals?.data?.[year] != null) {
      ebitda += Number(financialData['ebitda'].totals.data[year]);
    }
  }
  return isDisplay
    ? // (ebitda > 0 ? Math.floor(ebitda) : Math.ceil(ebitda))
      roundOff(ebitda)
    : ebitda;
};

export const calculateAdjustedEbitda = (year, financialData, isDisplay = false) => {
  let value = calculateEbitda(year, financialData, isDisplay);
  if (financialData['adjusted-ebitda']) {
    if (financialData['adjusted-ebitda']?.totals?.data?.[year]) {
      value += Number(financialData['adjusted-ebitda'].totals.data[year]);
    }
  }
  return isDisplay
    ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
      roundOff(value)
    : value;
};

export const calculateAdjustedEbitdaTax = (year, financialData) => {
  let value = calculateEbitda(year, financialData);
  if (financialData['adjusted-ebitda']) {
    if (financialData['adjusted-ebitda'].totals.data?.[year]) {
      value += Number(financialData['adjusted-ebitda'].totals.data[year]);
    }
  }
  return value;
};

export const calculateFundsAfterTax = (year, tax, financialData) => {
  tax = { ...tax, interest_pa: financialData.interest_pa };
  let value = calculateAdjustedEbitda(year, financialData);
  value += -calculatePayableTaxBusiness(year, tax, financialData);
  return value;
};

export const calculateDebtServicing = (year, tax, financialData) => {
  let value = calculateFundsAfterTax(year, tax, financialData);
  if (financialData['dividend']) {
    if (financialData['dividend'].totals.data?.[year]) {
      value += Number(financialData['dividend'].totals.data[year]);
    }
  }
  if (financialData['surplus_profit'] && financialData['surplus_profit']?.items?.length > 0) {
    financialData['surplus_profit']?.items.map((item) => {
      if (item?.data?.profit && item?.data?.profit?.[year]) {
        // let totalSurplus = item?.data?.profit?.[year];
        value += Number(item?.data?.profit?.[year]);
      }
    });
  }
  // if (financialData['surplus_profit']) {
  //   if (financialData['surplus_profit'].totals.data?.[year]) {
  //     value += Number(financialData['surplus_profit'].totals.data[year]);
  //   }
  // }
  return value;
};

export const calculateDebtServicingForTrust = (year, tax, financialData, isDisplay = false) => {
  let value = 0;
  if (financialData['dividend']) {
    if (financialData['dividend'].totals.data?.[year]) {
      value += Number(financialData['dividend'].totals.data[year]);
    }
  }
  if (financialData['surplus_profit'] && financialData['surplus_profit']?.items?.length > 0) {
    financialData['surplus_profit']?.items.map((item) => {
      if (item?.data?.profit && item?.data?.profit?.[year]) {
        // let totalSurplus = item?.data?.profit?.[year];
        value += Number(item?.data?.profit?.[year]);
      }
    });
  }
  // if (financialData['surplus_profit']) {
  //   if (financialData['surplus_profit'].totals.data?.[year]) {
  //     value += Number(financialData['surplus_profit'].totals.data[year]);
  //   }
  // }
  value += calculateAdjustedEbitda(year, financialData, isDisplay);
  return isDisplay
    ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
      roundOff(value)
    : value;
};

export const calculateAdjustedTaxableIncome = (year, tax, financialData, isDisplay = false) => {
  let value = 0;
  if (
    financialData.type === 'other' ||
    financialData.type === 'trust' ||
    financialData.type === 'partnership' ||
    financialData.type === 'sole-trader'
  ) {
    value = Number(calculateDebtServicingForTrust(year, tax, financialData, isDisplay));
  } else {
    value = Number(calculateAdjustedEbitda(year, financialData, isDisplay));
  }
  let amortisation = 0;
  let rentalDepreciation = 0;
  let otherDepreciation = 0;
  let frankingCredits = 0;

  if (financialData['ebitda'] && financialData['ebitda'].items) {
    let aArray = financialData['ebitda'].items.filter(function (item) {
      return item.key === 'amortisation';
    });
    let rpdArray = financialData['ebitda'].items.filter(function (item) {
      return item.key === 'rental-property-depreciation';
    });
    let odArray = financialData['ebitda'].items.filter(function (item) {
      return item.key === 'other-depreciation';
    });

    if (aArray.length > 0 && aArray[0].data[year]) {
      amortisation = aArray[0].data[year];
    }
    if (rpdArray.length > 0 && rpdArray[0].data[year]) {
      rentalDepreciation = rpdArray[0].data[year];
    }
    if (odArray.length > 0 && odArray[0].data[year]) {
      otherDepreciation = odArray[0].data[year];
    }
  }

  if (financialData['adjusted-ebitda'] && financialData['ebitda'].items) {
    let fArray = financialData['adjusted-ebitda'].items.filter(function (item) {
      return Object.keys(item).includes('key')
        ? ['franking-credits', 'franking-credits-received'].includes(item.key)
        : financialKeyValuePair[item.title] === 'franking-credits';
    });
    if (fArray.length > 0 && fArray[0].data[year]) {
      frankingCredits = fArray[0].data[year];
    }
  }

  if (tax['adjusted-tax-income']) {
    if (tax['adjusted-tax-income'].items) {
      let toIdValues = [];
      Object.values(tax['adjusted-tax-income'].items).forEach((item) => {
        if (
          Object.keys(item).includes('key')
            ? ['franking-credits', 'franking-credits-received'].includes(item.key)
            : financialKeyValuePair[item.title] === 'franking-credits'
        ) {
          value -= Number(frankingCredits);
          value = isDisplay
            ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
              roundOff(value)
            : value;
        } else if (
          Object.keys(item).includes('key')
            ? item.key === 'amortisation-added-back'
            : financialKeyValuePair[item.title] === 'amortisation-added-back'
        ) {
          value -= Number(amortisation);
          value = isDisplay
            ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
              roundOff(value)
            : value;
        } else if (
          Object.keys(item).includes('key')
            ? item.key === 'deductible-depreciation'
            : financialKeyValuePair[item.title] === 'deductible-depreciation'
        ) {
          value = value - Number(rentalDepreciation) - Number(otherDepreciation);
          value = isDisplay
            ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
              roundOff(value)
            : value;
        } else if (
          Object.keys(item).includes('key')
            ? item.key === 'tax-deductible-interest'
            : financialKeyValuePair[item.title] === 'tax-deductible-interest'
        ) {
          if (tax?.interest_pa && tax?.interest_pa != null) {
            let interest_pa =
              // tax.interest_pa > 0 ? Math.floor(tax.interest_pa) : Math.ceil(tax.interest_pa);
              roundOff(tax.interest_pa);
            value -= interest_pa;
          } else if (item.data[year]) {
            value -= Math.abs(Number(item.data[year]));
          } else {
            value -= 0;
          }
          // value =
          //   value -
          //   (tax?.interest_pa
          //     ? Number(tax.interest_pa)
          //     : item.data[year]
          //     ? Math.abs(Number(item.data[year]))
          //     : 0);
          value = isDisplay
            ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
              roundOff(value)
            : value;
        } else {
          if (
            Object.keys(item).includes('key') &&
            item.key === 'surplus_profit' &&
            Object.keys(item).includes('to_id')
          ) {
            toIdValues.push(item);
          } else {
            if (item.data[year]) {
              value += Number(item.data[year]);
              value = isDisplay
                ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
                  roundOff(value)
                : value;
            }
          }
        }
      });
      let toIdDeduction = isDisplay
        ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
          roundOff(value)
        : value;
      if (financialData['surplus_profit'] && financialData['surplus_profit']?.items?.length > 0) {
        financialData['surplus_profit']?.items.map((item) => {
          if (item?.data?.percentage && item?.data?.percentage?.[year]) {
            let totalSurplus = toIdDeduction * (item?.data?.percentage?.[year] / 100);
            value -= isDisplay
              ? // totalSurplus > 0
                //   ? Math.floor(totalSurplus)
                //   : Math.ceil(totalSurplus)
                roundOff(totalSurplus)
              : totalSurplus;
            value = isDisplay
              ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
                roundOff(value)
              : value;
          }
        });
      }
      // if (toIdValues.length > 0) {
      //   toIdValues.map((item) => {
      //     value += toIdDeduction * (item.percentage[year] / 100);
      //   });
      // }
    }
  } else {
    value =
      value -
      Number(frankingCredits) -
      Number(amortisation) -
      Number(rentalDepreciation) -
      Number(otherDepreciation) -
      Number(financialData?.interest_pa);
  }
  return value;
};

export const calculateTaxOnIncome = (year, tax, financialData, percentage, isDisplay = false) => {
  let value = calculateAdjustedTaxableIncome(year, tax, financialData, isDisplay);
  value = isDisplay
    ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
      roundOff(value)
    : value;

  let tempValue = value * (percentage / 100); // This needs to be dynamically selection
  value = isDisplay
    ? // (tempValue > 0 ? Math.floor(tempValue) : Math.ceil(tempValue))
      roundOff(tempValue)
    : tempValue;
  // if (financialData.type == 'trust' || financialData.type == 'other') {
  if (
    financialData.type == 'trust' ||
    financialData.type == 'other' ||
    financialData.type == 'partnership' ||
    financialData.type == 'sole-trader'
  ) {
    return value
      ? // (value > 0 ? Math.floor(value) : Math.ceil(value))
        roundOff(value)
      : 0;
  } else {
    return value > 0 ? value : 0;
  }
};

export const calculatePayableAdjustedTax = (
  year,
  tax,
  financialData,
  percentage,
  isDisplay = false
) => {
  let value = calculateTaxOnIncome(year, tax, financialData, percentage, isDisplay);
  let frankingCredits = 0;
  if (financialData['adjusted-ebitda'] && financialData['ebitda'].items) {
    let fArray = financialData['adjusted-ebitda'].items.filter(function (item) {
      return Object.keys(item).includes('key')
        ? ['franking-credits', 'franking-credits-received'].includes(item.key)
        : financialKeyValuePair[item.title] === 'franking-credits';
    });
    if (fArray.length > 0 && fArray[0].data[year]) {
      let tempValue = isDisplay
        ? // fArray[0].data[year] > 0
          //   ? Math.floor(fArray[0].data[year])
          //   : Math.ceil(fArray[0].data[year])
          roundOff(fArray[0].data[year])
        : fArray[0].data[year];
      frankingCredits = tempValue;
    }
  }

  // remove section of less deductions for type trust and other.
  if (financialData?.type !== 'trust' && financialData?.type !== 'other') {
    if (tax['deductions']) {
      if (tax['deductions'].items) {
        tax['deductions'].items.forEach((item) => {
          if (['franking-credits', 'franking-credits-received'].includes(item.key)) {
            let tempValue = isDisplay
              ? // frankingCredits > 0
                //   ? Math.floor(frankingCredits)
                //   : Math.ceil(frankingCredits)
                roundOff(frankingCredits)
              : frankingCredits;
            value += Number(tempValue);
          } else {
            if (item.data[year]) {
              let tempValue = isDisplay
                ? // item.data[year] > 0
                  //   ? Math.floor(item.data[year])
                  //   : Math.ceil(item.data[year])
                  roundOff(item.data[year])
                : item.data[year];
              value += Number(tempValue);
            }
          }
        });
      }
    } else {
      let tempValue = isDisplay
        ? // frankingCredits > 0
          //   ? Math.floor(frankingCredits)
          //   : Math.ceil(frankingCredits)
          roundOff(frankingCredits)
        : frankingCredits;
      value += Number(tempValue);
    }
  }
  // return value > 0 ? Math.floor(value) : Math.ceil(value);
  return roundOff(value);
};

export const calculatePayableTaxBusiness = (
  year,
  tax,
  financialData,
  percentage = 0,
  isDisplay = false
) => {
  if (financialData['tax']['tax_rate']) {
    percentage = financialData['tax']['tax_rate'];
  }
  let value = calculatePayableAdjustedTax(year, tax, financialData, percentage, isDisplay);
  return value;
};
