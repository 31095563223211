import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Field } from 'formik';
import Label from '../Label';
import Button from '../Button';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

const CommentsNotes = (props) => {
  CommentsNotes.propTypes = {
    isRefresh: PropTypes.any,
    setRecordtype: PropTypes.any,
    values: PropTypes.any,
    errors: PropTypes.any,
    handleChange: PropTypes.func,
    handleInputChange: PropTypes.any,
    handleBlur: PropTypes.func,
    resetForm: PropTypes.func,
    setFieldValue: PropTypes.any
  };
  const [reportcomment, setReportcomment] = useState(EditorState.createEmpty());
  const [internalnotes, setInternalnotes] = useState(EditorState.createEmpty());

  const settype = (type) => {
    props.setRecordtype(type);
    props.resetForm();
  };

  useEffect(() => {
    getComment(props.values?.report_comments);
    setReportcomment(getComment(props.values?.report_comments));
  }, [props?.values?.report_comments]);

  useEffect(() => {
    getComment(props.values?.internal_notes);
    setInternalnotes(getComment(props.values?.internal_notes));
  }, [props?.values?.internal_notes]);

  const getComment = (val) => {
    // const contentBlock = htmlToDraft(val ? '<p></p>' + val : '');
    const contentBlock = htmlToDraft(val ? val : '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return EditorState.createEmpty();
  };

  return (
    <div className="white-card">
      <div className="row">
        <div className="col-md-6">
          <div className="form-field">
            <div className="lablearea">
              <Label>Report Comments</Label>
              {props.isRefresh && (
                <Button
                  type="submit"
                  name="is_completed"
                  title="Refresh Comments"
                  className="btn btn-primary"
                  onClick={() => settype('report_comments')}
                >
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </Button>
              )}
            </div>
            <Field name={`report_comments`}>
              {({ field }) => (
                <Editor
                  editorState={reportcomment}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onBlur={() => {
                    props.setFieldValue(
                      `report_comments`,
                      draftToHtml(convertToRaw(reportcomment.getCurrentContent())).slice(0, -1)
                    );
                  }}
                  onEditorStateChange={(rawDraftContentState) => {
                    setReportcomment(rawDraftContentState);
                    props.handleInputChange(rawDraftContentState);
                  }}
                  toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    },
                    list: {
                      options: ['unordered', 'ordered']
                    }
                  }}
                  name={`report_comments`}
                  id={field.name}
                  values={getComment(
                    props.values?.report_comments ? props.values?.report_comments : ''
                  )}
                  textareaName={`report_comments`}
                  editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                  stripPastedStyles={true}
                />
              )}
            </Field>
            {/* <textarea
              className="form-control"
              name="report_comments"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values?.report_comments ? props.values?.report_comments : ''}
            ></textarea> */}
            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
              {props.errors.report_comments}
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <div className="lablearea">
              <label>Internal Notes</label>
              {props.isRefresh ? (
                <Button
                  type="submit"
                  name="is_completed"
                  title="Refresh Notes"
                  className="btn btn-primary"
                  onClick={() => settype('internal_notes')}
                >
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </Button>
              ) : (
                ''
              )}
            </div>
            <Field name={`internal_notes`}>
              {({ field }) => (
                <Editor
                  editorState={internalnotes}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onBlur={() => {
                    props.setFieldValue(
                      `internal_notes`,
                      draftToHtml(convertToRaw(internalnotes.getCurrentContent())).slice(0, -1)
                    );
                  }}
                  onEditorStateChange={(rawDraftContentState) => {
                    setInternalnotes(rawDraftContentState);
                    props.handleInputChange(rawDraftContentState);
                  }}
                  toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    },
                    list: {
                      options: ['unordered', 'ordered']
                    }
                  }}
                  name={`internal_notes`}
                  id={field.name}
                  values={getComment(
                    props.values?.internal_notes ? props.values?.internal_notes : ''
                  )}
                  textareaName={`internal_notes`}
                  editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                  stripPastedStyles={true}
                />
              )}
            </Field>
            {/* <textarea
              className="form-control"
              name="internal_notes"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values?.internal_notes ? props.values?.internal_notes : ''}
            ></textarea> */}
            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
              {props.errors.internal_notes}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsNotes;
