import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const SlicebusinessOverviewInitialState = {
  loading: false,
  businessOverviewResponse: {},
  updatebusinessOverviewResponse: {}
};

export const BusinessOverviewSlice = createSlice({
  name: 'businessOverview',
  initialState: SlicebusinessOverviewInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setbusinessOverviewResponse: (state, action) => {
      state.businessOverviewResponse = action.payload.data;
      state.loader = false;
    },
    setUpdatedbusinessOverviewResponse: (state, action) => {
      state.updatebusinessOverviewResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getBusinessOverview = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/business-overview',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setbusinessOverviewResponse(response.data));
  }
};

export const updateBusinessOverview = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/business-overview',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedbusinessOverviewResponse(response.data));
  }
};

export const { setLoader, setbusinessOverviewResponse, setUpdatedbusinessOverviewResponse } =
  BusinessOverviewSlice.actions;

export const businessOverviewState = (state) => {
  return state.businessOverview;
};

// export const settingState = (state) => {
//   return state.setting;
// };

export default BusinessOverviewSlice.reducer;
