import React, { useState, useEffect } from 'react';
import TableRow from './TableRow';
import TableHeadItem from './TableHead';
import PropTypes from 'prop-types';

const FSTables = (props) => {
  FSTables.propTypes = {
    theadData: PropTypes.any,
    tbodyData: PropTypes.any,
    customClass: PropTypes.any,
    detailComponent: PropTypes.any,
    values: PropTypes.any,
    Type: PropTypes.any,
    theadColor: PropTypes.any
  };

  let {
    theadData,
    tbodyData,
    customClass,
    detailComponent,
    values,
    rowSelection,
    headSlice,
    theadColor,
    tfootData,
    Type
  } = props;

  const [tableData, setTableData] = useState(tbodyData);

  useEffect(() => {
    setTableData(tbodyData);
  }, [tbodyData]);

  return (
    <>
      <table className={customClass}>
        <thead>
          <tr>
            {theadData &&
              theadData.length > 0 &&
              theadData.slice(0, headSlice ? headSlice : theadData.length).map((h) => {
                return (
                  <TableHeadItem
                    key={h.key}
                    keyvalue={h.key}
                    sorting={h.sort}
                    item={h.title}
                    colSpan={h.colSpan}
                    theadColor={theadColor}
                    style={h.style}
                    headClassName={h.headClassName}
                  />
                );
              })}
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.length > 0 ? (
            tableData.map((item, index) => {
              return (
                <>
                  <TableRow
                    theadData={theadData}
                    tableData={tableData}
                    values={values}
                    setTableData={setTableData}
                    selection={rowSelection}
                    item={item}
                    index={index}
                    Type={Type ? Type : ''}
                    detailComponent={detailComponent}
                  />
                </>
              );
            })
          ) : (
            <tr>
              <td colSpan={theadData.length + 1} style={{ textAlign: 'center' }}>
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
        {tfootData && tfootData}
      </table>
    </>
  );
};

export default FSTables;
