import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const taxCalcualtionInitialState = {
  loading: false,
  taxCalculationDataResponse: {},
  taxCalculationResponse: {},
  personalTaxCalculationDataResponse: {},
  deleteTaxDataResponse: {}
};

export const TaxCalculationSlice = createSlice({
  name: 'taxCalculation',
  initialState: taxCalcualtionInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setTaxCalculationDataResponse: (state, action) => {
      state.taxCalculationDataResponse = action.payload;
      state.loader = false;
    },
    setTaxCalculationResponse: (state, action) => {
      state.taxCalculationResponse = action.payload;
      state.loader = false;
    },
    setPersonalTaxCalculationDataResponse: (state, action) => {
      state.personalTaxCalculationDataResponse = action.payload;
      state.loader = false;
    },
    setDeleteTaxDataResponse: (state, action) => {
      state.deleteTaxDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getTaxCalculationData =
  (id, type = 'business', setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/group-analysis/tax-calculation-business?type=' + type,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setTaxCalculationDataResponse(response.data));
    }
  };

export const updateTaxCalculation = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/group-analysis/tax-calculation-business',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setTaxCalculationResponse(response.data));
  }
};

export const getPersonalTaxCalculationData =
  (id, type = 'personal', setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/group-analysis/tax-calculation-business?type=' + type,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setPersonalTaxCalculationDataResponse(response.data));
    }
  };

export const deleteTax = (jobId, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/members/' + memberId + '/tax',
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteTaxDataResponse(response.data));
  }
};

export const {
  setLoader,
  setTaxCalculationDataResponse,
  setTaxCalculationResponse,
  setPersonalTaxCalculationDataResponse,
  setDeleteTaxDataResponse
} = TaxCalculationSlice.actions;

export const taxCalculationState = (state) => {
  return state.taxCalculation;
};

export default TaxCalculationSlice.reducer;
