import React from 'react';
import ReactTooltip from 'react-tooltip';
import Label from '../Label';

const LabelTooltip = ({ labelId, label, hover_text }) => {
  return (
    <>
      <ReactTooltip id={labelId} place="top" effect="solid">
        <p
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: '14px'
          }}
        >
          {hover_text}
        </p>
      </ReactTooltip>

      <Label
        className={`${
          // (labelId === 'securityTip' || labelId === 'securityAddressTip') && 'common-label'
          labelId === 'rentalTip'
            ? 'common-label-financial'
            : (labelId === 'securityTip' ||
                labelId === 'securityAddressTip' ||
                labelId === 'commercialShadingTip' ||
                labelId == 'facilityPurposeTip') &&
              'common-label'
        }`}
        // style={`${(!bold_text && '')}`}
      >
        {label}

        <span className="set-tooltip-margin" data-tip data-for={labelId}>
          <i className="icon-info" />
        </span>
      </Label>
    </>
  );
};

export default LabelTooltip;
