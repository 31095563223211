const tableHead = {
  // ------------- Data Entry ----------- //

  dataEntry: {
    notesTip: {
      key: 'notesTip',
      label: 'Notes',
      hover_text: 'Internal notes on the status of any\noutstanding data entry.'
    }
  },

  // ----- Business Financial Analysis --------- //

  businessFinancial: {
    tradingIncomeTip: {
      key: 'tradingIncomeTip',
      label: 'Trading Income',
      hover_text:
        "Itemise the key trading revenues that\ndrive profitability. Keep the number of\nitems to a minimum to improve\nreadability\n\nExclude all non-trading income. These\nitems should instead be included under\n'Other Income'."
    },
    costSaleTip: {
      key: 'costSaleTip',
      label: 'Cost of Sales',
      hover_text:
        'Generally, consolidate cost of sales to\none line (unless there are multiple trading\nrevenue items with material gross profit\ndifferences and this understanding is\nimportant to the proposal).'
    },
    otherIncomeTip: {
      key: 'otherIncomeTip',
      label: 'Other Income',
      hover_text:
        'Include non-trading income items only.\n\nLimit the number of items to those\nspecified in the dropdown menu, and only\nadd more items if material.'
    },
    operatingExpensesTip: {
      key: 'operatingExpensesTip',
      label: 'Operating Expenses',
      hover_text: 'Consolidate all operating expenses to\none line.'
    },
    rentalTip: {
      key: 'rentalTip',
      label: 'Rental',
      hover_text:
        'The purpose of the rental adjustments is\nto calculate serviceability based on\nsensitised current rental rather than\non an historical basis.\n\nAccordingly, all historical rental\n income and expenses are excluded\nfor the purposes of calculating the\nfunds available for debt servicing.\n\nCurrent rental expenses and potential\nvacancy periods are allowed for, by virtue\nof the shading applied to the gross rental\nin the data input process.'
    }
  },

  // ------- Rental ------ //
  rentalList: {
    rentalListTip: {
      key: 'rentalListTip',
      label: 'WALE (Years)',
      hover_text:
        'The weighted average lease expiry\n(WALE) is used to indicate the average\nexpiry period of all the leases within a\ncommercial property, and an assessment\nof the current and future income that\nthe property will provide'
    }
  }
};

export default tableHead;
