import React, { useEffect } from 'react';
import AuthFooter from './Auth-Footer';
import { Formik } from 'formik';

import { forgotPassword, authState } from '../../slices/slices/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ForgotPasswordSchema } from '../common/Validation';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Label from '../../components/Label';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isUserLoggedIn } = useSelector(authState);

  const handleFormSubmit = (values, { setErrors }) => {
    dispatch(forgotPassword(values, setErrors));
  };
  const login = () => {
    // localStorage.clear();
    localStorage.setItem('token', '');
    navigate('/login');
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate('/dashboard');
    }
  }, [isUserLoggedIn]);

  return (
    <div className="influence-account-page">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="" className="logo d-flex align-items-center w-auto">
                    <img src="images/logo.png" alt="" />
                  </a>
                </div>
                <div className="card mb-3 loginarea">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        email: ''
                      }}
                      validationSchema={ForgotPasswordSchema}
                      validateOnBlur={false}
                      validateOnChange={false}
                      onSubmit={handleFormSubmit}
                    >
                      {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="row needs-validation" noValidate>
                          <div className="col-12 justify-content-center textintro btmgap">
                            <Label>
                              Enter email address to receive an email for reset password
                            </Label>
                          </div>
                          <div className="col-12">
                            <div className="input-group has-validation">
                              <Input
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                className="form-control"
                                id="yourUsername"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                required
                              />
                            </div>
                            <span className="error">{errors.email}</span>
                          </div>
                          <div className="col-12 topgap btnarea justify-content-center">
                            <Button className="btn btn-primary" type="submit">
                              Submit
                            </Button>
                          </div>
                          <div className="col-12 justify-content-center middletext">
                            <p className="topspace">
                              <Link
                                to="/login"
                                onClick={() => {
                                  login();
                                }}
                              >
                                Back to Login
                              </Link>
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AuthFooter />
    </div>
  );
};

export default ForgotPassword;
