import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
const pageLimitOptions = [25, 50, 75];
const Pagination = (props) => {
  Pagination.propTypes = {
    total: PropTypes.number,
    limit: PropTypes.number !== 'NaN' ? PropTypes.number : '',
    currentPage: PropTypes.number,
    updateLimit: PropTypes.func,
    updatePage: PropTypes.func
  };

  const [pages, setPages] = useState([1]);

  useEffect(() => {
    if (props.total) {
      let min = props.currentPage - 2;
      let max = Math.ceil(props.total / props.limit);
      if (min <= 0) {
        min = 1;
      }
      if (props.currentPage < max - 2) {
        max = props.currentPage + 2;
      }
      let page = [];
      for (let i = min; i <= max; i++) {
        page.push(i);
      }
      setPages(page);
    }
  }, [props.total, props.limit, props.currentPage]);

  return (
    <div className="table-pagination">
      <div className="limiter">
        Showing{' '}
        <select
          onChange={(element) => props.updateLimit(element)}
          // defaultValue={props.limit ? props.limit : 0}
          value={props.limit ? props.limit : 0}
        >
          {pageLimitOptions &&
            pageLimitOptions.map((opt) => {
              return <option key={opt}>{opt}</option>;
            })}
        </select>{' '}
        entries per page
      </div>
      <div className="pager">
        {props.currentPage != 1 ? (
          <>
            <button onClick={() => props.updatePage(1)}>
              <i className="fa fa-angle-double-left"></i> First
            </button>
            <button onClick={() => props.updatePage(props.currentPage - 1)}>
              <i className="fa fa-angle-left"></i> Prev
            </button>
          </>
        ) : null}
        <ul className="pages">
          {pages.map((page, index) => {
            return (
              <li
                onClick={() => props.updatePage(page)}
                key={'apge' + index}
                className={page === props.currentPage ? 'active' : ''}
              >
                <a href="#">{page}</a>
              </li>
            );
          })}
        </ul>
        {props.currentPage !== Math.ceil(props.total / props.limit) ? (
          <>
            <button onClick={() => props.updatePage(props.currentPage + 1)}>
              Next <i className="fa fa-angle-right"></i>
            </button>
            <button onClick={() => props.updatePage(Math.ceil(props.total / props.limit))}>
              End <i className="fa fa-angle-double-right"></i>
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Pagination;
