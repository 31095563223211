import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import Category from './components/Category';
import { financialsState } from '../../../../slices/slices/FinancialsSlice';
import {
  getJobSettingsData,
  jobSetupState,
  getMembers
} from '../../../../slices/slices/JobSetupSlice';
import { dataEntryState } from '../../../../slices/slices/DataEntrySlice';
import {
  formatNumber,
  formatPercentage,
  displayNameFromType,
  roundOff
} from '../../../common/Misc';
import EBIT from './components/EBIT';
import EBITDA from './components/EBITDA';
import { useDispatch, useSelector } from 'react-redux';
import AdjustedEBITDA from './components/AdjustedEBITDA';
import { useParams } from 'react-router-dom';
import Dividend from './components/Dividend';
import { yearStatus } from '../../../../utils/constant';
import {
  calculateAdjustedEbitdaTax,
  calculateDebtServicing,
  calculateEbit,
  calculateEbitda,
  calculateFundsAfterTax,
  calculateGrossProfit,
  calculateNetProfit,
  calculatePayableTaxBusiness,
  calculateDebtServicingForTrust,
  calculateAdjustedEbitda
} from '../../../../utils/businessDataEntryCalculations';
import Surplus from './components/Surplus';
// import ExtrapModal from '../../../../components/ExtrapModal';

const BusinessFinancials = (props) => {
  const {
    financialData,
    setFinancialData,
    handleRemove,
    handleInputChange,
    displayYear,
    setDisplayYears
  } = props;
  const dispatch = useDispatch();
  const { selectedMember } = useSelector(dataEntryState);
  const { tax, years } = useSelector(financialsState);
  const finacialItem = useSelector(financialsState);
  const { jobSettingsDataResponse, membersData } = useSelector(jobSetupState);
  const [memberOptions, setMemberData] = useState([]);
  const [memberWithFinData, setMemberWithFinData] = useState([
    { label: '', value: '', member_id: '' }
  ]);
  const [setting, setSettingData] = useState({});
  // const [showModal, setModalShow] = useState(false);
  // const [yearStatus, setYearStatus] = useState([]);
  const { jobId, memberId } = useParams();
  let defaultYears = {};
  years?.map((item) => (defaultYears[item] = false));
  const [changeYear, setChangeYear] = useState(defaultYears);

  // const [financialData, setfinancialData] = useState(finacialItem.financialData);

  const addOptions = [
    'Select if Applicable',
    'Accounting, Legal & Finance Costs',
    'Bad Debt',
    'Capital Expenditure',
    'Directors Voluntary Super > SGC',
    'Donations',
    'Employment Redundancy Expenses',
    'FX Loss',
    'Loss of Sale of Asset',
    'Partnership & Trust Loss',
    'Relocation Costs',
    'Other'
  ];
  const lessOptions = [
    'Select if Applicable',
    'Capital Gain',
    'FX Gain',
    'Partnership & Trust Profit',
    'Profit on Sale of Asset',
    'Other'
  ];

  const handleSalesGrowthPercentage = (year, value) => {
    let totals = financialData['trading-income'].totals.data;
    let previousValue = '';
    if (totals)
      for (const [key, values] of Object.entries(totals)) {
        if (Number(key) === Number(year) && previousValue !== '') {
          return formatPercentage((value - previousValue) / previousValue);
        }
        previousValue = values;
      }
    return '';
  };

  const handlePercentagePerTotal = (year, value) => {
    if (financialData['trading-income']) {
      let totals = financialData['trading-income'].totals?.data;
      return formatPercentage(value / totals?.[year]);
    }
    return 0;
  };

  const handlePercentagePerRemainingProfitLoss = (year, value) => {
    if (
      financialData['adjusted-ebitda'] &&
      financialData['dividend'] &&
      financialData['dividend'].totals &&
      financialData['dividend'].totals.data
    ) {
      let total = 0;
      let adjusted_ebitda = calculateAdjustedEbitda(year, financialData);
      if (selectedMember?.type == 'trust' && adjusted_ebitda < 0) return '0%';
      else {
        total = (value / (adjusted_ebitda + financialData['dividend'].totals.data[year])) * 100;
        return total ? formatNumber(total) + '%' : '0%';
      }
      /*if (financialData['dividend'].totals.data[year] < 0 && adjusted_ebitda < 0) {
        total =
          (value /
            (Math.abs(adjusted_ebitda) + Math.abs(financialData['dividend'].totals.data[year]))) *
          100;
      } else if (financialData['dividend'].totals.data[year] > 0 && adjusted_ebitda > 0) {
        total =
          (value /
            (Math.abs(adjusted_ebitda) + Math.abs(financialData['dividend'].totals.data[year]))) *
          100;
      } else if (financialData['dividend'].totals.data[year] < 0 && adjusted_ebitda > 0) {
        total =
          (value /
            (Math.abs(adjusted_ebitda) - Math.abs(financialData['dividend'].totals.data[year]))) *
          100;
      } else {
        total =
          (value / (Math.abs(adjusted_ebitda) - financialData['dividend'].totals.data[year])) * 100;
      }*/
    } else return '0%';
  };

  useEffect(() => {
    setFinancialData(finacialItem.financialData);
    // setYearStatus(finacialItem?.year_status);
    if (
      !displayYear &&
      (!finacialItem?.year_status || Object.keys(finacialItem?.year_status).length == 0)
    ) {
      let financialYears = {};
      years?.map((value) => {
        if (
          Number(setting?.current_financial_year) === Number(value)
          // &&setting?.select_following_option === 'annualise-management-reports'
        ) {
          financialYears[value] = 'Extrap';
        } else {
          financialYears[value] = 'Actual';
        }
      });
      setDisplayYears(financialYears);
    } else if (!Object.values(changeYear)?.includes(true) && finacialItem?.year_status) {
      setDisplayYears(finacialItem?.year_status);
    }
  }, [finacialItem]);

  useEffect(() => {
    dispatch(getJobSettingsData(jobId));
    dispatch(getMembers(jobId));
  }, []);

  let member = ['Select if Applicable'];
  let memberWithFinance = [
    { label: 'Select if Applicable', value: 'Select if Applicable', member_id: '' }
  ];

  useEffect(() => {
    membersData?.data?.data.map((val) => {
      val.type != 'sole-trader' &&
        val.type != 'partnership' &&
        member.push(displayNameFromType(val));
      val.financial_val != 'not-applicable' &&
        val.financial_val != 'not-started' &&
        val.tax_val != 'not-applicable' &&
        val.tax_val != 'not-started' &&
        val.id != memberId &&
        memberWithFinance.push({
          label: displayNameFromType(val),
          value: displayNameFromType(val),
          member_id: val.id
        });
    });
    setMemberData(member);
    setMemberWithFinData(memberWithFinance);
  }, [membersData]);

  useEffect(() => {
    setSettingData(jobSettingsDataResponse);
  }, [jobSettingsDataResponse]);

  useEffect(() => {}, [years]);

  const handleYearChange = (e, year) => {
    let yearStatus = JSON.parse(JSON.stringify(displayYear));
    let changeYearData = { ...changeYear };

    years?.map((item) => {
      if (item === year) {
        yearStatus[item] = e.target.value;
        changeYearData[item] = true;
      } else if (yearStatus[item] == null) {
        changeYearData[item] = false;
        if (
          Number(setting?.current_financial_year) === Number(item)
          // &&setting?.select_following_option === 'annualise-management-reports'
        ) {
          yearStatus[item] = 'Extrap';
        } else {
          yearStatus[item] = 'Actual';
        }
      }
    });

    setChangeYear(changeYearData);
    setDisplayYears(yearStatus);
  };

  // const manageExtrap = (event, year) => {
  //   if (
  //     Number(setting?.include_current_financial_year) === 1 &&
  //     Number(setting?.current_financial_year) === year
  //   ) {
  //     setModalShow(true);
  //   }
  // };

  // const onHide = () => {
  //   setModalShow(false);
  // };

  return (
    <>
      <div className="financial-table">
        {Object.entries(financialData).length > 0 ? (
          <Table cellPadding="0" cellSpacing="0" border="0" width="100%" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">Business Financial Analysis</Table.HeaderCell>
                {years.map((value, index) => {
                  return (
                    <>
                      <Table.HeaderCell key={index}>
                        {value} <br />
                        <select
                          value={displayYear[value]}
                          onChange={(e) => handleYearChange(e, value)}
                        >
                          {Number(setting?.include_current_financial_year) === 1 &&
                            Number(setting?.current_financial_year) === Number(value) && (
                              <option key={'Extrap'} value="Extrap" selected="selected">
                                Extrap
                              </option>
                            )}
                          {yearStatus.map((yearStatus) => {
                            return (
                              <option key={yearStatus.value} value={yearStatus.value}>
                                {yearStatus.value}
                              </option>
                            );
                          })}
                        </select>
                      </Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(financialData).length > 0 ? (
                <>
                  <Category
                    data={financialData['trading-income']}
                    handleRemove={handleRemove}
                    handleInputChange={handleInputChange}
                    years={years}
                    totalPercentage={handleSalesGrowthPercentage}
                    // negative={true}
                  />
                  <Category
                    data={financialData['cost-of-sales']}
                    handleRemove={handleRemove}
                    handleInputChange={handleInputChange}
                    years={years}
                    negative={true}
                  />
                  <Table.Row key="gross-profit">
                    <Table.Cell colSpan="2">{financialData['gross-profit']?.title}</Table.Cell>
                    {years.map((value, index) => {
                      let grossProfit = calculateGrossProfit(value, financialData);
                      return (
                        <>
                          <Table.Cell key={index} className="align-right">
                            <span>{formatNumber(grossProfit)}</span>
                          </Table.Cell>
                          <Table.Cell>
                            <span className="blue_text">
                              {handlePercentagePerTotal(value, grossProfit)}
                            </span>
                          </Table.Cell>
                        </>
                      );
                    })}
                  </Table.Row>
                  <Category
                    data={financialData['other-income']}
                    handleRemove={handleRemove}
                    handleInputChange={handleInputChange}
                    years={years}
                    totalPercentage={handlePercentagePerTotal}
                  />
                  <Category
                    data={financialData['operating-expenses']}
                    handleInputChange={handleInputChange}
                    years={years}
                    handleRemove={handleRemove}
                    totalPercentage={handlePercentagePerTotal}
                    negative={true}
                  />
                  <Table.Row className="total_row" key="net-profit-before-tax">
                    <Table.Cell colSpan="2">
                      {financialData['net-profit-before-tax']?.title
                        ? financialData['net-profit-before-tax']?.title
                        : 'Net Profit Before Tax'}
                    </Table.Cell>
                    {years.map((value, index) => {
                      let netProfitBeforeTax = calculateNetProfit(value, financialData);
                      return (
                        <>
                          <Table.Cell key={index} className="align-right">
                            <span>{formatNumber(netProfitBeforeTax)}</span>
                          </Table.Cell>
                          <Table.Cell>
                            <span className="blue_text">
                              {handlePercentagePerTotal(value, netProfitBeforeTax)}
                            </span>
                          </Table.Cell>
                        </>
                      );
                    })}
                  </Table.Row>
                  <EBIT
                    data={financialData['ebit']}
                    years={years}
                    handleInputChange={handleInputChange}
                    categoryList={[
                      {
                        key: 'add',
                        name: 'Add Back',
                        option: null
                      }
                    ]}
                  />
                  <Table.Row className="total_row" key="ebit-total">
                    <Table.Cell colSpan="2">
                      <strong>{financialData['ebit']?.title}</strong>
                    </Table.Cell>
                    {years.map((value, index) => {
                      let ebit = calculateEbit(value, financialData);
                      return (
                        <>
                          <Table.Cell key={index} className="align-right">
                            <span>{formatNumber(ebit)}</span>
                          </Table.Cell>
                          <Table.Cell>
                            <span className="blue_text">
                              {handlePercentagePerTotal(value, ebit)}
                            </span>
                          </Table.Cell>
                        </>
                      );
                    })}
                  </Table.Row>
                  <EBITDA
                    data={financialData['ebitda']}
                    years={years}
                    handleRemove={handleRemove}
                    handleInputChange={handleInputChange}
                    categoryList={[
                      {
                        key: 'add',
                        name: 'Add Back',
                        option: null,
                        negative: false
                      }
                    ]}
                  />
                  <Table.Row className="total_row" key="ebitda-total">
                    <Table.Cell colSpan="2">
                      <strong>{financialData['ebitda']?.title}</strong>
                    </Table.Cell>
                    {years.map((value, index) => {
                      let ebitda = calculateEbitda(value, financialData);
                      return (
                        <>
                          <Table.Cell key={index} className="align-right">
                            <span>{formatNumber(ebitda)}</span>
                          </Table.Cell>
                          <Table.Cell>
                            <span className="blue_text">
                              {handlePercentagePerTotal(value, ebitda)}
                            </span>
                          </Table.Cell>
                        </>
                      );
                    })}
                  </Table.Row>
                  <AdjustedEBITDA
                    data={financialData['adjusted-ebitda']}
                    years={years}
                    handleInputChange={handleInputChange}
                    handleRemove={handleRemove}
                    categoryList={[
                      {
                        key: 'add',
                        name: 'Add Back',
                        option: addOptions,
                        negative: false
                      },
                      {
                        key: 'less',
                        name: 'Less',
                        option: lessOptions,
                        negative: true
                      },
                      {
                        key: 'rental',
                        name: 'Rental',
                        option: null,
                        negative: false
                      }
                    ]}
                  />
                  <Table.Row className="total_row" key="adjusted-ebitda-total">
                    <Table.Cell colSpan="2">
                      <strong>{financialData['adjusted-ebitda']?.title}</strong>
                    </Table.Cell>
                    {years.map((value, index) => {
                      let adjusted_ebitda = calculateAdjustedEbitdaTax(value, financialData);

                      return (
                        <>
                          <Table.Cell key={index} className="align-right">
                            <span>{formatNumber(adjusted_ebitda)}</span>
                          </Table.Cell>
                          <Table.Cell>
                            <span className="blue_text">
                              {handlePercentagePerTotal(value, adjusted_ebitda)}
                            </span>
                          </Table.Cell>
                        </>
                      );
                    })}
                  </Table.Row>

                  {selectedMember?.type != 'trust' &&
                  selectedMember?.type != 'sole-trader' &&
                  selectedMember?.type != 'partnership' &&
                  selectedMember?.type != 'other' ? (
                    <>
                      <Table.Row key="total-income">
                        <Table.Cell colSpan="2">
                          <a
                            className="link"
                            href={'/job/' + jobId + '/data-entry/' + memberId + '/tax'}
                            rel="noreferrer"
                          >
                            {financialData['tax']?.title}
                          </a>
                        </Table.Cell>
                        {years.map((value, index) => {
                          let taxValue = calculatePayableTaxBusiness(
                            value,
                            { ...tax, interest_pa: financialData.interest_pa },
                            financialData
                          );
                          return (
                            <>
                              <Table.Cell key={index} className="align-right">
                                <span>
                                  {/* {taxValue > 0
                                    ? formatNumber(Math.floor(taxValue) * -1)
                                    : formatNumber(Math.ceil(taxValue) * -1)} */}
                                  {formatNumber(roundOff(taxValue) * -1)}
                                </span>
                              </Table.Cell>
                              <Table.Cell></Table.Cell>
                            </>
                          );
                        })}
                      </Table.Row>
                      <Table.Row className="total_row" key="funds-total">
                        <Table.Cell colSpan="2">
                          <strong>{financialData['funds-after-tax']?.title}</strong>
                        </Table.Cell>
                        {years.map((value, index) => {
                          let funds_after_tax = calculateFundsAfterTax(value, tax, financialData);
                          return (
                            <>
                              <Table.Cell key={index} className="align-right">
                                <span>{formatNumber(funds_after_tax)}</span>
                              </Table.Cell>
                              <Table.Cell></Table.Cell>
                            </>
                          );
                        })}
                      </Table.Row>
                    </>
                  ) : (
                    ''
                  )}

                  <Dividend
                    data={financialData['dividend']}
                    years={years}
                    memberOptions={memberOptions}
                    handleRemove={handleRemove}
                    handleInputChange={handleInputChange}
                  />
                  {selectedMember?.type == 'trust' ||
                  selectedMember?.type == 'partnership' ||
                  selectedMember?.type == 'sole-trader' ||
                  selectedMember?.type == 'other' ? (
                    <Surplus
                      data={financialData['surplus_profit']}
                      years={years}
                      memberOptions={memberWithFinData}
                      handleRemove={handleRemove}
                      handleInputChange={handleInputChange}
                    />
                  ) : (
                    ''
                  )}
                  {selectedMember?.type == 'trust' ||
                  selectedMember?.type == 'other' ||
                  selectedMember?.type == 'partnership' ||
                  selectedMember?.type == 'sole-trader' ? (
                    <>
                      <Table.Row className="total_row" key="funds-total">
                        <Table.Cell colSpan="2">
                          <strong>{financialData['funds-after-tax']?.title}</strong>
                        </Table.Cell>
                        {years.map((value, index) => {
                          let funds_after_tax = calculateDebtServicingForTrust(
                            value,
                            tax,
                            financialData
                          );
                          let profitPercentage = handlePercentagePerRemainingProfitLoss(
                            value,
                            funds_after_tax
                          );
                          return (
                            <>
                              <Table.Cell key={index} className="align-right">
                                <span>{formatNumber(funds_after_tax)}</span>
                              </Table.Cell>
                              <Table.Cell>
                                <span
                                  className={
                                    profitPercentage === '0%' || profitPercentage === '100%'
                                      ? 'blue_text'
                                      : 'red_text'
                                  }
                                >
                                  {profitPercentage}
                                </span>
                              </Table.Cell>
                            </>
                          );
                        })}
                      </Table.Row>
                      <Table.Row key="total-income">
                        <Table.Cell colSpan="2">
                          <a
                            className="link"
                            href={'/job/' + jobId + '/data-entry/' + memberId + '/tax'}
                            rel="noreferrer"
                          >
                            {financialData['tax']?.title}
                          </a>
                        </Table.Cell>
                        {years.map((value, index) => {
                          let taxValue = calculatePayableTaxBusiness(
                            value,
                            { ...tax, interest_pa: financialData.interest_pa },
                            financialData,
                            0,
                            true
                          );
                          return (
                            <>
                              <Table.Cell key={index} className="align-right">
                                <span>
                                  {/* {taxValue > 0
                                    ? formatNumber(Math.floor(taxValue) * -1)
                                    : formatNumber(Math.ceil(taxValue) * -1)} */}
                                  {formatNumber(roundOff(taxValue) * -1)}
                                </span>
                              </Table.Cell>
                              <Table.Cell></Table.Cell>
                            </>
                          );
                        })}
                      </Table.Row>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : null}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan="2">
                  <strong>{financialData['funds-available-years']?.title}</strong>
                </Table.Cell>
                {years.map((value, index) => {
                  let debtServicing = 0;
                  if (
                    selectedMember?.type == 'sole-trader' ||
                    selectedMember?.type == 'partnership'
                  ) {
                    debtServicing = calculateDebtServicingForTrust(value, tax, financialData);
                  } else {
                    debtServicing = calculateDebtServicing(value, tax, financialData);
                  }

                  return (
                    <>
                      <Table.Cell key={index} className="align-right">
                        <span>{formatNumber(debtServicing)}</span>
                      </Table.Cell>
                      <Table.Cell />
                    </>
                  );
                })}
              </Table.Row>
            </Table.Footer>
          </Table>
        ) : null}
      </div>
      {/* <ExtrapModal show={showModal} hide={onHide} /> */}
    </>
  );
};

export default BusinessFinancials;
