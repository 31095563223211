import React, { useState } from 'react';
import TitleDropDown from './TitleDropDown';
import AddOverallAssessmentModal from './AddOverallAssesmentModal';
import EditOverallAssessmentModal from './EditOverallAssessmentModal';
import { Modal } from 'react-bootstrap';
import Button from '../Button';

export default function OverallAssessmentItem(props) {
  const {
    itemData,
    mainIndex,
    id,
    handleOverallAssessment,
    handleEditOverallAssessment,
    showAddOverall,
    showEditOverall,
    handleDeleteCategory,
    showDeleteCategory
  } = props;
  const [showModal, setshowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [name, setName] = useState(null);
  const [option, setOption] = useState([]);
  const [categoryItemId, setCategoryItemId] = useState();

  const onHandleCreate = () => {
    setshowModal(true);
  };
  const onhandleClose = () => {
    setshowModal(false);
  };

  const onHandleEdit = (index) => {
    setOption(JSON.parse(itemData[index]?.options));
    setCategoryItemId(itemData[index]?.id);
    setName(itemData[index]?.name);
    setEditShowModal(true);
  };
  const editOnhandleClose = () => {
    setEditShowModal(false);
  };

  const handleDelete = () => {
    setShowDeleteModal(false);
  };

  const [categItemId, setCategItemId] = useState();
  const [categId, setCategId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteCategoy = () => {
    handleDeleteCategory(categId, categItemId);
    setShowDeleteModal(showDeleteCategory);
  };

  const handleDeleteCateg = (id, category_id) => {
    setCategItemId(id);
    setCategId(category_id);

    setShowDeleteModal(true);
  };

  const handleFormSubmit = (value) => {
    handleOverallAssessment(value, id);
    setshowModal(showAddOverall);
  };

  const editHandleFormSubmit = (value) => {
    handleEditOverallAssessment(value, categoryItemId, id);
    setEditShowModal(showEditOverall);
  };
  let itemList = itemData.map((titleData, index) => {
    return (
      <>
        <li
          key={index}
          className="infosys mag_listof d-flex justify-content-between align-items-center"
        >
          <p>
            {titleData.name}
            <button type="button">
              <i className="fa fa-edit" onClick={() => onHandleEdit(index)}></i>
            </button>
            <button type="button" style={{ marginLeft: '10px' }}>
              <i
                className="fa fa-trash-alt"
                onClick={() => handleDeleteCateg(titleData?.id, titleData?.category_id)}
              ></i>
            </button>
            {/* <a href="javascript:void(0)" onClick={() => onHandleEdit(index)} className="addlist">
              <i className="fa fa-edit"></i>
            </a> */}
          </p>
          <TitleDropDown
            option={JSON.parse(titleData.options)}
            color={JSON.parse(titleData.options)}
            mainIndex={mainIndex}
            subIndex={index}
          />

          {itemData.length - 1 == index && (
            <a href="#" onClick={() => onHandleCreate(index)} className="addlist">
              <i className="icon-plus"></i>
            </a>
          )}
        </li>
      </>
    );
  });

  return (
    <>
      {itemList}
      {showModal && (
        <AddOverallAssessmentModal
          show={showModal}
          mainIndex={mainIndex}
          close={onhandleClose}
          onClickSubmit={handleFormSubmit}
        />
      )}

      {editShowModal && (
        <EditOverallAssessmentModal
          name={name}
          show={editShowModal}
          mainIndex={mainIndex}
          optionArray={option}
          close={editOnhandleClose}
          onClickSubmit={editHandleFormSubmit}
        />
      )}

      {showDeleteModal && (
        <Modal
          show={showDeleteModal}
          onHide={handleDelete}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <h2>Delete Category?</h2>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this category?</p>
          </Modal.Body>
          <div className="modal-action modal-footer">
            <Button className="btn btn-secondary" onClick={handleDelete}>
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                deleteCategoy();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
