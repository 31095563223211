import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const settingInitialState = {
  loading: false,
  generalSettingResponse: {},
  googleResponse: {},
  generalSettingChangeResponse: {},
  accountSettingResponse: {},
  settingEmailOTPResponse: {},
  settingEmailVerifyResponse: {},
  settingCodeVerifyResponse: {},
  accountSettingChangeResponse: {},
  xpmIntFieldsSettingResponse: {},
  xpmIntFieldsSettingChangeResponse: {},
  changePasswordResponse: {},
  remove2faResponse: {},
  disclaimerSettingResponse: {}
};

export const SettingSlice = createSlice({
  name: 'setting',
  initialState: settingInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setGeneralSettingResponse: (state, action) => {
      state.generalSettingResponse = action.payload.data;
      state.loader = false;
    },
    setGoogleResponse: (state, action) => {
      state.googleResponse = action.payload;
      state.loader = false;
    },
    setChangeGeneralSettingResponse: (state, action) => {
      state.generalSettingChangeResponse = action.payload;
      state.loader = false;
    },
    setAccountSettingResponse: (state, action) => {
      state.accountSettingResponse = action.payload.data;
      state.loader = false;
    },
    setSettingSendEmailResponse: (state, action) => {
      state.settingEmailOTPResponse = action.payload;
      state.loader = false;
    },
    setSettingVerifyEmailResponse: (state, action) => {
      state.settingEmailVerifyResponse = action.payload;
      state.loader = false;
    },
    setSettingVerifyNumberResponse: (state, action) => {
      state.settingCodeVerifyResponse = action.payload;
      state.loader = false;
    },
    setChangeAccountSettingResponse: (state, action) => {
      state.accountSettingChangeResponse = action.payload;
      state.loader = false;
    },
    setXpmIntFieldsSettingResponse: (state, action) => {
      state.xpmIntFieldsSettingResponse = action.payload.data;
      state.loader = false;
    },
    setChangeXpmIntFieldsSettingResponse: (state, action) => {
      state.xpmIntFieldsSettingChangeResponse = action.payload;
      state.loader = false;
    },
    setchangePasswordResponse: (state, action) => {
      state.changePasswordResponse = action.payload;
      state.loader = false;
    },
    setRemove2faResponse: (state, action) => {
      state.remove2faResponse = action.payload;
    },
    setDisclaimerSettingResponse: (state, action) => {
      state.disclaimerSettingResponse = action.payload.data;
      state.loader = false;
    }
  }
});

export const getGeneralSettingData = (setErrors) => async (dispatch) => {
  const response = await clientService.get('settings/general', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGeneralSettingResponse(response.data));
  }
};

export const getGoogleRegister = (setErrors) => async (dispatch) => {
  const response = await clientService.get('settings/2fa/google', setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGoogleResponse(response.data));
  }
};

export const changeGeneralSettingData = (data, setErrors) => async (dispatch) => {
  const response = await clientService.put('settings/general', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setChangeGeneralSettingResponse(response.data));
  }
};

export const getAccountSettingData = (setErrors) => async (dispatch) => {
  const response = await clientService.get('settings/account', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAccountSettingResponse(response.data));
  }
};

export const settingSendEmailOTP = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('settings/2fa/email', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSettingSendEmailResponse(response.data));
  }
};

export const settingVerifyEmailOTP = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('settings/2fa/email/verify', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSettingVerifyEmailResponse(response.data));
  }
};

export const settingVerifyNumberOTP = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('settings/2fa/google', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSettingVerifyNumberResponse(response.data));
  }
};

export const changeAccountSettingData = (data, setErrors) => async (dispatch) => {
  const response = await clientService.put('settings/account', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setChangeAccountSettingResponse(response.data));
  }
};

export const getXpmIntFieldsSettingData = (setErrors) => async (dispatch) => {
  const response = await clientService.get('settings/xpm', setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setXpmIntFieldsSettingResponse(response.data));
  }
};

export const changeXpmIntFieldsSettingData = (data, setErrors) => async (dispatch) => {
  const response = await clientService.put('settings/xpm', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setChangeXpmIntFieldsSettingResponse(response.data));
  }
};

export const getchangePasswordData = (data, setErrors) => async (dispatch) => {
  const response = await clientService.put('settings/password', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setchangePasswordResponse(response.data));
  }
};

export const remove2fa = (setErrors) => async (dispatch) => {
  const response = await clientService.remove('settings/2fa', setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setRemove2faResponse(response.data));
  }
};
export const getDisclaimerSettingData = (setErrors) => async (dispatch) => {
  const response = await clientService.get('pages/disclaimer', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDisclaimerSettingResponse(response.data));
  }
};

export const {
  setLoader,
  setGeneralSettingResponse,
  setChangeGeneralSettingResponse,
  setGoogleResponse,
  setAccountSettingResponse,
  setSettingSendEmailResponse,
  setSettingVerifyEmailResponse,
  setSettingVerifyNumberResponse,
  setChangeAccountSettingResponse,
  setXpmIntFieldsSettingResponse,
  setChangeXpmIntFieldsSettingResponse,
  setchangePasswordResponse,
  setRemove2faResponse,
  setDisclaimerSettingResponse
} = SettingSlice.actions;

export const settingState = (state) => {
  return state.setting;
};

export default SettingSlice.reducer;
