import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import FormActions from '../../../../components/FormActions';
import Header from '../../Header';
import { displayNameFromType, formatNumber } from '../../../common/Misc';
import {
  groupFinancialState,
  getBusinessFinancialData,
  updateBusinessFinancial,
  deleteFinancial,
  setDeleteFinancialDataResponse
} from '../../../../slices/slices/GroupFinancialSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Table } from 'semantic-ui-react';
import Info from '../../../../components/Info';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const BusinessAnalysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { businessFinancialDataResponse, businessFinancialResponse } =
    useSelector(groupFinancialState);
  const deleteFinancialDataResponse = useSelector(groupFinancialState);
  const [memberName, setMemberName] = useState();
  const [memberId, setMemberid] = useState();
  const [member, setMember] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showFinancialAnalysisPopup, setShowFinancialAnalysisPopup] = useState(false);
  const [yearsData, setYearsData] = useState(businessFinancialDataResponse?.years);
  const [financialsData, setFianacialsData] = useState(businessFinancialDataResponse?.data);
  const [record_type, setRecordtype] = useState();

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFinancialAnalysisPopup = () => {
    setShowFinancialAnalysisPopup(false);
  };

  const delFinancial = () => {
    setShowDelete(false);
    dispatch(deleteFinancial(jobId, memberId));
    dispatch(getBusinessFinancialData(jobId));
  };

  const handleDelete = () => {
    setShowDelete(false);
  };

  const handleShowFinancialAnalysisPopup = (item) => {
    setShowFinancialAnalysisPopup(true);
    setMember(item);
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    if (record_type) {
      values.is_refresh = 1;
      values.record_type = record_type;
    }
    dispatch(updateBusinessFinancial(jobId, values, setErrors));
    setRecordtype('');
  };

  const handleManualFinancialAnalysis = (item) => {
    navigate('/job/' + jobId + '/data-entry/' + item.id + '/financials', {
      state: { type: item?.type }
    });
  };

  useEffect(() => {
    setYearsData(businessFinancialDataResponse?.years);
  }, [businessFinancialDataResponse?.years]);

  useEffect(() => {
    setFianacialsData(businessFinancialDataResponse?.data);
  }, [businessFinancialDataResponse?.data]);

  useEffect(() => {
    dispatch(getBusinessFinancialData(jobId));
  }, [businessFinancialResponse]);

  useEffect(() => {
    if (deleteFinancialDataResponse?.message) {
      setShowDelete(false);
      dispatch(getBusinessFinancialData(jobId));
      dispatch(setDeleteFinancialDataResponse({}));
    }
  }, [deleteFinancialDataResponse?.message]);

  useEffect(() => {
    dispatch(getBusinessFinancialData(jobId));
  }, [showDelete]);

  const years =
    businessFinancialDataResponse && yearsData?.length > 0
      ? yearsData?.map((item) => {
          return item;
        })
      : null;

  const financialData =
    businessFinancialDataResponse && financialsData?.length > 0
      ? financialsData?.map((item) => {
          return item;
        })
      : null;

  let initialValue = {
    type: 'business',
    report_comments: businessFinancialDataResponse?.report_comments
      ? businessFinancialDataResponse?.report_comments
      : '',
    internal_notes: businessFinancialDataResponse?.internal_notes
      ? businessFinancialDataResponse?.internal_notes
      : '',
    is_completed: 0
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard/'}>Dashboard</Link>
              </li>
              <li>
                <a href="#">Group Analysis</a>
              </li>
              <li>
                <strong>Business Financial Analysis</strong>
              </li>
            </ul>
          </div>
        </div>

        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-between align-items-center">
            <h1>
              <strong>Business Financial Analysis</strong>
            </h1>
            <div style={{ marginLeft: 'auto', marginRight: '-15px' }}>
              <Info />
            </div>
          </div>
        </div>
        <Form className="facility-form form-data">
          <div className="gray-card mr-0 mt-0">
            <div className="rentalview">
              <div className="row justify-content-start">
                <div className="col-6">
                  <table className="table financialtable">
                    <thead>
                      <tr>
                        <th className="text-start">Member Name</th>
                        <th className="text-center">Date Created</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {financialData && financialData?.length > 0 ? (
                        financialData?.map((item) => {
                          return (
                            <tr key={item?.id}>
                              <td className="text-start">{displayNameFromType(item)}</td>
                              <td className="text-center">
                                {moment(item?.created_at).format('DD/MM/YYYY')}
                              </td>

                              <td className="text-center">
                                {item?.financials !== null ? (
                                  <>
                                    <i
                                      className={
                                        item?.financial_val === 'completed'
                                          ? 'fa fa-check-circle'
                                          : 'fa fa-exclamation-triangle'
                                      }
                                      aria-hidden="true"
                                      style={{
                                        color: `${
                                          item?.financial_val === 'completed'
                                            ? '#42ba96'
                                            : '#eed202'
                                        }`
                                      }}
                                    ></i>
                                    <a
                                      className="edit"
                                      onClick={() => {
                                        handleManualFinancialAnalysis(item);
                                      }}
                                    >
                                      <i className="icon-edit"></i>
                                    </a>

                                    <a
                                      onClick={() => {
                                        setShowDelete(true);
                                        setMemberid(item?.id);
                                      }}
                                      className="delete"
                                    >
                                      <i className="icon-delete"></i>
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <i
                                      className={
                                        item?.financial_val === 'completed'
                                          ? 'fa fa-check-circle'
                                          : 'fa fa-exclamation-triangle'
                                      }
                                      aria-hidden="true"
                                      style={{
                                        color: `${
                                          item?.financial_val === 'completed'
                                            ? '#42ba96'
                                            : '#eed202'
                                        }`
                                      }}
                                    ></i>
                                    <a
                                      className="notstarted"
                                      onClick={() => {
                                        handleShowFinancialAnalysisPopup(item);
                                        setMemberName(displayNameFromType(item));
                                      }}
                                    >
                                      <i className="fas fa-folder-plus"></i>
                                    </a>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5">No Records Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <Table className="table consolidated">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="text-start">Consolidated</Table.HeaderCell>
                    {years?.map((value, index) => {
                      return (
                        <>
                          <Table.HeaderCell className="text-center t_head" key={index}>
                            {value}
                          </Table.HeaderCell>
                        </>
                      );
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <>
                    <Table.Row key="trading-income" className="accordion-toggle clickable">
                      <Table.Cell
                        className="text-start"
                        data-toggle="collapse"
                        data-target="#trading-income"
                      >
                        Trading Income<i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let tradingIncomeTotal = 0;
                            return (
                              <Table.Cell key={index} className="text-center">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((val) => {
                                      tradingIncomeTotal += val?.financials?.financial_analysis[0]
                                        ?.data['trading-income']?.totals?.data?.[value]
                                        ? val?.financials?.financial_analysis[0]?.data[
                                            'trading-income'
                                          ]?.totals?.data[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(tradingIncomeTotal) !== '0'
                                  ? formatNumber(tradingIncomeTotal)
                                  : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row
                      key="trading-income"
                      className="accordion-body collapse"
                      id="trading-income"
                    >
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;
                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.[
                                      'trading-income'
                                    ]?.totals?.data
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.[
                                        'trading-income'
                                      ]?.totals?.data
                                    ).forEach((item) => {
                                      if (item != null && item != 0) {
                                        result = true;
                                      }
                                    });

                                  return (
                                    <>
                                      {result == true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years?.map((value, index) => {
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  {formatNumber(
                                                    data?.financials?.financial_analysis[0]?.data[
                                                      'trading-income'
                                                    ]?.totals?.data[value]
                                                  )
                                                    ? formatNumber(
                                                        data?.financials?.financial_analysis[0]
                                                          ?.data['trading-income']?.totals?.data[
                                                          value
                                                        ]
                                                      )
                                                    : ''}
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="cost-of-sales" className="accordion-toggle clickable">
                      <Table.Cell
                        className="text-start"
                        data-toggle="collapse"
                        data-target="#cost-of-sales"
                      >
                        Gross Profit<i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let costOfSalesTotal = 0;
                            return (
                              <Table.Cell key={index} className="text-center">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((data) => {
                                      costOfSalesTotal += data?.financials?.financial_analysis[0]
                                        ?.data?.['gross-profit']?.items?.[value]
                                        ? data?.financials?.financial_analysis[0]?.data?.[
                                            'gross-profit'
                                          ]?.items?.[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(costOfSalesTotal) !== '0'
                                  ? formatNumber(costOfSalesTotal)
                                  : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row
                      key="cost-of-sales"
                      className="accordion-body collapse"
                      id="cost-of-sales"
                    >
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;
                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.['gross-profit']
                                      ?.items
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.[
                                        'gross-profit'
                                      ]?.items
                                    ).forEach((item) => {
                                      if (item != null && item != 0) {
                                        result = true;
                                      }
                                    });

                                  return (
                                    <>
                                      {result === true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            let grossProfit = data?.financials
                                              ?.financial_analysis[0]?.data?.['gross-profit']
                                              ?.items?.[value]
                                              ? data?.financials?.financial_analysis[0]?.data?.[
                                                  'gross-profit'
                                                ]?.items?.[value]
                                              : null;
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>
                                                    {grossProfit ? formatNumber(grossProfit) : '0'}
                                                  </span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="other-income" className="accordion-toggle clickable">
                      <Table.Cell
                        className="text-start"
                        data-toggle="collapse"
                        data-target="#other-income"
                      >
                        Total Other Income<i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let otherIncomeTotal = 0;
                            return (
                              <Table.Cell key={index} className="text-center">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((val) => {
                                      otherIncomeTotal += val?.financials?.financial_analysis[0]
                                        ?.data['other-income']?.totals?.data[value]
                                        ? val?.financials?.financial_analysis[0]?.data[
                                            'other-income'
                                          ]?.totals?.data[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(otherIncomeTotal) !== '0'
                                  ? formatNumber(otherIncomeTotal)
                                  : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row
                      key="other-income"
                      className="accordion-body collapse"
                      id="other-income"
                    >
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;

                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.['other-income']
                                      ?.totals?.data
                                  ) {
                                    if (
                                      Object.values(
                                        data?.financials?.financial_analysis[0]?.data?.[
                                          'other-income'
                                        ]?.totals?.data
                                      ).find((el) => el)
                                    ) {
                                      result = true;
                                    }
                                  }

                                  {
                                    /* if (
                                    data?.financials?.financial_analysis[0]?.data?.['other-income']
                                      ?.items
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.[
                                        'other-income'
                                      ]?.items
                                    ).forEach((item) => {
                                      if (item?.data) {
                                        if (Object.values(item?.data).find((el) => el)) {
                                          result = true;
                                        }
                                      }
                                    }); */
                                  }

                                  return (
                                    <>
                                      {result == true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>
                                                    {formatNumber(
                                                      data?.financials?.financial_analysis[0]?.data[
                                                        'other-income'
                                                      ]?.totals?.data[value]
                                                    ) !== '0'
                                                      ? formatNumber(
                                                          data?.financials?.financial_analysis[0]
                                                            ?.data['other-income']?.totals?.data[
                                                            value
                                                          ]
                                                        )
                                                      : ''}
                                                  </span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="operating-expenses" className="accordion-toggle clickable">
                      <Table.Cell
                        className="text-start"
                        data-toggle="collapse"
                        data-target="#operating-expenses"
                      >
                        Operating Expenses<i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let operatingExpensesTotal = 0;
                            return (
                              <Table.Cell key={index} className="text-center">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((val) => {
                                      operatingExpensesTotal += val?.financials
                                        ?.financial_analysis[0]?.data['operating-expenses']?.totals
                                        ?.data[value]
                                        ? val?.financials?.financial_analysis[0]?.data[
                                            'operating-expenses'
                                          ]?.totals?.data[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(operatingExpensesTotal) !== '0'
                                  ? formatNumber(operatingExpensesTotal)
                                  : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row
                      key="operating-expenses"
                      className="accordion-body collapse"
                      id="operating-expenses"
                    >
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;

                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.[
                                      'operating-expenses'
                                    ]?.totals?.data
                                  ) {
                                    if (
                                      Object.values(
                                        data?.financials?.financial_analysis[0]?.data?.[
                                          'operating-expenses'
                                        ]?.totals?.data
                                      ).find((el) => el)
                                    ) {
                                      result = true;
                                    }
                                  }

                                  {
                                    /* if (
                                    data?.financials?.financial_analysis[0]?.data?.[
                                      'operating-expenses'
                                    ]?.items
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.[
                                        'operating-expenses'
                                      ]?.items
                                    ).forEach((item) => {
                                      if (item?.data) {
                                        if (Object.values(item?.data).find((el) => el)) {
                                          result = true;
                                        }
                                      }
                                    }); */
                                  }
                                  // Object.values(
                                  //   data?.financials?.financial_analysis[0]?.data?.[
                                  //     'operating-expenses'
                                  //   ]?.items
                                  // ).forEach((item) => {
                                  //   Object.values(item?.data).forEach((el) =>
                                  //     el != null && el != 0 ? result === true : result === false
                                  //   );
                                  // });
                                  return (
                                    <>
                                      {result === true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>
                                                    {formatNumber(
                                                      data?.financials?.financial_analysis[0]?.data[
                                                        'operating-expenses'
                                                      ]?.totals?.data[value]
                                                    ) !== '0'
                                                      ? formatNumber(
                                                          data?.financials?.financial_analysis[0]
                                                            ?.data['operating-expenses']?.totals
                                                            ?.data[value]
                                                        )
                                                      : ''}
                                                  </span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="net-profit-before-tax" className="accordion-toggle clickable">
                      <Table.Cell
                        className="text-start"
                        data-toggle="collapse"
                        data-target="#net-profit-before-tax"
                      >
                        Net Profit Before Tax<i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let netProfitBeforeTax = 0;
                            return (
                              <Table.Cell key={index} className="text-center">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((data) => {
                                      netProfitBeforeTax += data?.financials?.financial_analysis[0]
                                        ?.data?.['net-profit-before-tax']?.items?.[value]
                                        ? data?.financials?.financial_analysis[0]?.data?.[
                                            'net-profit-before-tax'
                                          ]?.items?.[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(netProfitBeforeTax) !== '0'
                                  ? formatNumber(netProfitBeforeTax)
                                  : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row
                      key="net-profit-before-tax"
                      className="accordion-body collapse"
                      id="net-profit-before-tax"
                    >
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;

                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.[
                                      'net-profit-before-tax'
                                    ]?.items
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.[
                                        'net-profit-before-tax'
                                      ]?.items
                                    ).forEach((item) => {
                                      if (item != null && item != 0) {
                                        result = true;
                                      }
                                    });
                                  // Object.values(
                                  //   data?.financials?.financial_analysis[0]?.data?.[
                                  //     'net-profit-before-tax'
                                  //   ]?.items
                                  // ).forEach((item) => {
                                  //   if (item != null && item != 0) {
                                  //     result = true;
                                  //   }
                                  // });

                                  return (
                                    <>
                                      {result === true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            let netProfitBeforeTax = data?.financials
                                              ?.financial_analysis[0]?.data?.[
                                              'net-profit-before-tax'
                                            ]?.items?.[value]
                                              ? data?.financials?.financial_analysis[0]?.data?.[
                                                  'net-profit-before-tax'
                                                ]?.items?.[value]
                                              : null;
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>
                                                    {netProfitBeforeTax
                                                      ? formatNumber(netProfitBeforeTax)
                                                      : ''}
                                                  </span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="ebit" className="accordion-toggle clickable">
                      <Table.Cell className="text-start" data-toggle="collapse" data-target="#ebit">
                        EBIT<i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let ebit = 0;
                            return (
                              <Table.Cell key={index} className="text-center">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((data) => {
                                      ebit += data?.financials?.financial_analysis[0]?.data?.[
                                        'ebit'
                                      ]?.['final-totals']?.data?.[value]
                                        ? data?.financials?.financial_analysis[0]?.data?.['ebit']?.[
                                            'final-totals'
                                          ]?.data?.[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(ebit) !== '0' ? formatNumber(ebit) : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row key="ebit" className="accordion-body collapse" id="ebit">
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;

                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.['ebit']?.[
                                      'final-totals'
                                    ]?.data
                                  ) {
                                    if (
                                      Object.values(
                                        data?.financials?.financial_analysis[0]?.data?.['ebit']?.[
                                          'final-totals'
                                        ]?.data
                                      ).find((el) => el)
                                    ) {
                                      result = true;
                                    }
                                  }

                                  return (
                                    <>
                                      {result === true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            let ebit = data?.financials?.financial_analysis[0]
                                              ?.data?.['ebit']?.['final-totals']?.data?.[value]
                                              ? data?.financials?.financial_analysis[0]?.data?.[
                                                  'ebit'
                                                ]?.['final-totals']?.data?.[value]
                                              : null;
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>{ebit ? formatNumber(ebit) : ''}</span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="ebitda" className="accordion-toggle clickable">
                      <Table.Cell
                        className="text-start"
                        data-toggle="collapse"
                        data-target="#ebitda"
                      >
                        EBITDA<i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let ebitda = 0;
                            return (
                              <Table.Cell key={index} className="text-center">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((data) => {
                                      ebitda += data?.financials?.financial_analysis[0]?.data?.[
                                        'ebitda'
                                      ]?.['final-totals']?.data?.[value]
                                        ? data?.financials?.financial_analysis[0]?.data?.[
                                            'ebitda'
                                          ]?.['final-totals']?.data?.[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(ebitda) !== '0' ? formatNumber(ebitda) : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row key="ebitda" className="accordion-body collapse" id="ebitda">
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;

                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.['ebitda']?.[
                                      'final-totals'
                                    ]?.data
                                  ) {
                                    if (
                                      Object.values(
                                        data?.financials?.financial_analysis[0]?.data?.['ebitda']?.[
                                          'final-totals'
                                        ]?.data
                                      ).find((el) => el)
                                    ) {
                                      result = true;
                                    }
                                  }

                                  {
                                    /* if (
                                    data?.financials?.financial_analysis[0]?.data?.['ebitda']?.items
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.['ebitda']
                                        ?.items
                                    ).forEach((item) => {
                                      if (item?.data) {
                                        if (Object.values(item?.data).find((el) => el)) {
                                          result = true;
                                        }
                                      }
                                    }); */
                                  }
                                  // Object.values(
                                  //   data?.financials?.financial_analysis[0]?.data?.['ebitda']
                                  //     ?.items
                                  // ).forEach((item) => {
                                  //   Object.values(item?.data).forEach((el) =>
                                  //     el != null && el != 0 ? result === true : result === false
                                  //   );
                                  // });
                                  return (
                                    <>
                                      {result === true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            let ebitda = data?.financials?.financial_analysis[0]
                                              ?.data?.['ebitda']?.['final-totals']?.data?.[value]
                                              ? data?.financials?.financial_analysis[0]?.data?.[
                                                  'ebitda'
                                                ]?.['final-totals']?.data?.[value]
                                              : null;
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>{ebitda ? formatNumber(ebitda) : ''}</span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row
                      key="adjusted-ebitda"
                      className="skycolor accordion-toggle clickable"
                    >
                      <Table.Cell
                        className="text-start tex_funds"
                        data-toggle="collapse"
                        data-target="#adjusted-ebitda"
                        style={{ borderBottomColor: '#c0bebe40', borderBottomWidth: '2px' }}
                      >
                        Adjusted EBITDA<i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let adjusted_ebitda = 0;
                            return (
                              <Table.Cell
                                key={index}
                                className="text-center"
                                style={{
                                  borderBottomColor: '#c0bebe40',
                                  borderBottomWidth: '2px'
                                }}
                              >
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((data) => {
                                      adjusted_ebitda += data?.financials?.financial_analysis[0]
                                        ?.data?.['adjusted-ebitda']?.['final-totals']?.data?.[value]
                                        ? data?.financials?.financial_analysis[0]?.data?.[
                                            'adjusted-ebitda'
                                          ]?.['final-totals']?.data?.[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(adjusted_ebitda) !== '0'
                                  ? formatNumber(adjusted_ebitda)
                                  : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row
                      key="adjusted-ebitda"
                      className="accordion-body collapse"
                      id="adjusted-ebitda"
                    >
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;

                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.[
                                      'adjusted-ebitda'
                                    ]?.['final-totals']?.data
                                  ) {
                                    if (
                                      Object.values(
                                        data?.financials?.financial_analysis[0]?.data?.[
                                          'adjusted-ebitda'
                                        ]?.['final-totals']?.data
                                      ).find((el) => el)
                                    ) {
                                      result = true;
                                    }
                                  }

                                  {
                                    /* if (
                                    data?.financials?.financial_analysis[0]?.data?.[
                                      'adjusted-ebitda'
                                    ]?.items
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.[
                                        'adjusted-ebitda'
                                      ]?.items
                                    ).forEach((item) => {
                                      if (item?.data) {
                                        if (Object.values(item?.data).find((el) => el)) {
                                          result = true;
                                        }
                                      }
                                    }); */
                                  }
                                  // Object.values(
                                  //   data?.financials?.financial_analysis[0]?.data?.[
                                  //     'adjusted-ebitda'
                                  //   ]?.items
                                  // ).forEach((item) => {
                                  //   Object.values(item?.data).forEach((el) =>
                                  //     el != null && el != 0 ? result === true : result === false
                                  //   );
                                  // });
                                  return (
                                    <>
                                      {result === true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            let adjusted_ebitda = data?.financials
                                              ?.financial_analysis[0]?.data?.['adjusted-ebitda']?.[
                                              'final-totals'
                                            ]?.data?.[value]
                                              ? data?.financials?.financial_analysis[0]?.data?.[
                                                  'adjusted-ebitda'
                                                ]?.['final-totals']?.data?.[value]
                                              : null;
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>
                                                    {adjusted_ebitda
                                                      ? formatNumber(adjusted_ebitda)
                                                      : ''}
                                                  </span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="funds-total" className="skycolor accordion-toggle clickable">
                      <Table.Cell
                        className="text-start tex_funds"
                        data-toggle="collapse"
                        data-target="#funds-total"
                      >
                        Funds available for Debt Servicing (after Tax)
                        <i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let funds_after_tax = 0;
                            return (
                              <Table.Cell key={index} className="text-center end_tex_funds">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((data) => {
                                      funds_after_tax += data?.financials?.financial_analysis[0]
                                        ?.data?.['funds-after-tax']?.items?.[value]
                                        ? data?.financials?.financial_analysis[0]?.data?.[
                                            'funds-after-tax'
                                          ]?.items?.[value]
                                        : 0;
                                    })
                                  : null}
                                {formatNumber(funds_after_tax) !== '0'
                                  ? formatNumber(funds_after_tax)
                                  : ''}
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row
                      key="funds-total"
                      className="accordion-body collapse"
                      id="funds-total"
                    >
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;

                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.[
                                      'funds-after-tax'
                                    ]?.items
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.[
                                        'funds-after-tax'
                                      ]?.items
                                    ).forEach((item) => {
                                      if (item != null && item != 0) {
                                        result = true;
                                      }
                                    });
                                  return (
                                    <>
                                      {result === true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            let funds_after_tax = data?.financials
                                              ?.financial_analysis[0]?.data?.['funds-after-tax']
                                              ?.items?.[value]
                                              ? data?.financials?.financial_analysis[0]?.data?.[
                                                  'funds-after-tax'
                                                ]?.items?.[value]
                                              : null;
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>
                                                    {funds_after_tax
                                                      ? formatNumber(funds_after_tax)
                                                      : ''}
                                                  </span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="debt-servicing" className="skycolor accordion-toggle clickable">
                      <Table.Cell
                        className="text-start tex_funds"
                        data-toggle="collapse"
                        data-target="#debt-servicing"
                      >
                        Funds available for Debt Servicing (after Tax, Dividends &amp;
                        Distributions)
                        <i className="fa fa-caret-up"></i>
                      </Table.Cell>
                      {years && years?.length > 0
                        ? years?.map((value, index) => {
                            let debtServicing = 0;
                            return (
                              <Table.Cell key={index} className="text-center end_tex_funds">
                                {financialData && financialData?.length > 0
                                  ? financialData?.map((data) => {
                                      debtServicing += data?.financials?.financial_analysis[0]
                                        ?.data?.['funds-available-years']?.items?.[value]
                                        ? data?.financials?.financial_analysis[0]?.data?.[
                                            'funds-available-years'
                                          ]?.items?.[value]
                                        : 0;
                                    })
                                  : null}
                                <strong>
                                  {formatNumber(debtServicing) !== '0'
                                    ? formatNumber(debtServicing)
                                    : ''}
                                </strong>
                              </Table.Cell>
                            );
                          })
                        : null}
                    </Table.Row>
                    <Table.Row
                      key="debt-servicing"
                      className="accordion-body collapse"
                      id="debt-servicing"
                    >
                      <Table.Cell className="text-center gray brd-left" colSpan="5">
                        <Table className="table memberarea">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="text-start">
                                Member Name
                              </Table.HeaderCell>
                              {years?.map((value, index) => {
                                return (
                                  <>
                                    <Table.HeaderCell key={index}></Table.HeaderCell>
                                  </>
                                );
                              })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {financialData && financialData?.length > 0
                              ? financialData?.map((data) => {
                                  let result = false;

                                  if (
                                    data?.financials?.financial_analysis[0]?.data?.[
                                      'funds-available-years'
                                    ]?.items
                                  )
                                    Object.values(
                                      data?.financials?.financial_analysis[0]?.data?.[
                                        'funds-available-years'
                                      ]?.items
                                    ).forEach((item) => {
                                      if (item != null && item != 0) {
                                        result = true;
                                      }
                                    });
                                  return (
                                    <>
                                      {result === true ? (
                                        <Table.Row key={data?.id}>
                                          <Table.Cell className="text-start namehere">
                                            {displayNameFromType(data)}
                                          </Table.Cell>
                                          {years.map((value, index) => {
                                            let debtServicing = data?.financials
                                              ?.financial_analysis[0]?.data?.[
                                              'funds-available-years'
                                            ]?.items?.[value]
                                              ? data?.financials?.financial_analysis[0]?.data?.[
                                                  'funds-available-years'
                                                ]?.items?.[value]
                                              : null;
                                            return (
                                              <>
                                                <Table.Cell key={index} className="text-center cmn">
                                                  <span>
                                                    {debtServicing
                                                      ? formatNumber(debtServicing)
                                                      : ''}
                                                  </span>
                                                </Table.Cell>
                                              </>
                                            );
                                          })}
                                        </Table.Row>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                  </>
                </Table.Body>
              </Table>
            </div>
          </div>
        </Form>
        <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="facility-form form-data">
                <CommentsNotes
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleInputChange={handleInputChange}
                  handleBlur={handleBlur}
                  isRefresh={true}
                  setRecordtype={setRecordtype}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                />
                <div className="action-box full">
                  <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Modal
        show={showFinancialAnalysisPopup}
        onHide={handleFinancialAnalysisPopup}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="create-job">
          <h4>Create Financial Analysis</h4>
          <p>Member Name: {memberName}</p>
          <div className="create-btn">
            <Button type="button" className="btn btn-primary">
              Import from XERO
            </Button>
            or
            <Button
              type="button"
              className="btn btn-secondary"
              id="manualjobbtn"
              onClick={() => {
                handleManualFinancialAnalysis(member);
              }}
            >
              CREATE NEW
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDelete}
        onHide={handleDelete}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Delete Financial Analysis?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this financial analysis permanently?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button className="btn btn-secondary" onClick={handleDelete}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              delFinancial();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default BusinessAnalysis;
