import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGeneralSettingData,
  settingState,
  changeGeneralSettingData,
  setChangeGeneralSettingResponse
} from '../../slices/slices/SettingSlice';
import { Formik } from 'formik';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { SettingBasicSchema } from '../common/Validation';

import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Label from '../../components/Label';

const SettingBasic = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { generalSettingResponse, generalSettingChangeResponse } = useSelector(settingState);

  const [defalutValues, setDefaultValues] = useState({});

  useEffect(() => {
    dispatch(getGeneralSettingData());
  }, []);

  const handleFormSubmit = (values, { setErrors }) => {
    dispatch(changeGeneralSettingData(values, setErrors));
  };

  useEffect(() => {
    setDefaultValues({
      ...generalSettingResponse,
      tfa_email: generalSettingResponse.tfa_email ? generalSettingResponse.tfa_email : ''
    });
  }, [generalSettingResponse]);
  useEffect(() => {
    if (generalSettingChangeResponse.message) {
      if (generalSettingChangeResponse) {
        dispatch(setChangeGeneralSettingResponse({}));
      }
    }
  }, [generalSettingChangeResponse]);

  const onClickCancle = () => {
    navigate('/dashboard');
  };

  return (
    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <Formik
        initialValues={{
          name: defalutValues.name,
          surname: defalutValues.surname,
          phone_number: defalutValues.phone_number,
          position: defalutValues.position,
          credit_rep_number: defalutValues.credit_rep_number,
          email: defalutValues.email,
          tfa_email: defalutValues.tfa_email,
          is_tfa_enable: defalutValues.is_tfa_enable
        }}
        enableReinitialize
        validationSchema={SettingBasicSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="row needs-validation" noValidate>
            <div className="innertab">
              <div className="container">
                <div className="textarea">
                  <fieldset>
                    <h2>Personal Information</h2>
                  </fieldset>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group flex-column has-validation">
                        <Label title="Last Name">First Name</Label>
                        <Input
                          type="text"
                          name="name"
                          placeholder=""
                          className="form-control"
                          id="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          required
                        />
                        <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                          {errors.name}
                        </span>
                        {/* <span>
                          <a href="#">Change</a>
                        </span> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group flex-column has-validation">
                        <Label title="First Name">Last Name</Label>
                        <Input
                          type="text"
                          name="surname"
                          placeholder=""
                          className="form-control"
                          id="surname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.surname}
                          required
                        />
                        <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                          {errors.surname}
                        </span>
                        {/* <span>
                          <a href="#">Change</a>
                        </span> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group flex-column has-validation">
                        <Label title="First Name">Phone Number</Label>
                        <Input
                          type="text"
                          name="phone_number"
                          placeholder=""
                          maxLength="15"
                          className="form-control"
                          id="phone_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone_number}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group flex-column has-validation">
                        <Label title="First Name">Position</Label>
                        <Input
                          type="text"
                          name="position"
                          placeholder=""
                          className="form-control"
                          id="position"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.position}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group flex-column has-validation">
                        <Label title="First Name">Credit Representative Number</Label>
                        <Input
                          type="number"
                          name="credit_rep_number"
                          placeholder=""
                          className="form-control"
                          id="credit_rep_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.credit_rep_number}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group flex-column has-validation">
                        <Label>Email Address</Label>
                        <Input
                          type="text"
                          name="email"
                          placeholder=""
                          className="form-control"
                          id="emailaddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          disabled
                        />
                        {/* <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                          {errors.email}
                        </span> */}
                        {/* <span>
                          <a href="#">Change</a>
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="row switch-title">
                    <div className="col-md-6">
                      <h3 className="innertitle">Two-Factor Authentication</h3>
                    </div>
                    <div className="col-md-6">
                      <Form.Check
                        custom
                        defaultChecked={values.is_tfa_enable}
                        type="switch"
                        name="is_tfa_enable"
                        id="is_tfa_enable"
                        label="On/Off"
                        onClick={(e) => {
                          if (e.target.checked === false) {
                            navigate('/two-factor-option');
                          } else if (e.target.checked === true) {
                            navigate('/two-step');
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* {generalSettingResponse.is_tfa_enable === true ? ( */}

                  <div className="twofactor">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group flex-column has-validation">
                          <div className="row">
                            <div>
                              <label>Backup Email Address</label>
                            </div>
                          </div>

                          <Input
                            type="text"
                            name="tfa_email"
                            placeholder=""
                            className="form-control"
                            id="tfa_email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={defalutValues.tfa_email}
                            disabled
                          />
                          <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                            {errors.tfa_email}
                          </span>

                          {/* <span>
                            <a href="#">Change</a>
                          </span> */}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group flex-column has-validation">
                          <Label>Authentication Method</Label>
                          <span className="codarea">GOOGLE AUTHENTICATOR APP</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ) : ( */}
                  {/* <p>
                      Two factor authentication adds a second layer of security to your account to
                      help prevent unauthorised access.
                    </p>
                  )} */}
                </div>
              </div>
            </div>
            <div className="btn_area text-end">
              <div className="container">
                <Button className="btn btn-outline-secondary" type="reset" onClick={onClickCancle}>
                  Cancel
                </Button>
                <Button className="btn btn-success" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SettingBasic;
