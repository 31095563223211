import React from 'react';
import { Table } from 'semantic-ui-react';
import EditTableRows from './EditTableRows';
import PropTypes from 'prop-types';
import FixedColumnTableRows from './FixedColumnTableRows';

const CommonLayout = ({
  items,
  data,
  categoryList,
  years,
  handleInputChange,
  handleRemove,
  noAddition
}) => {
  CommonLayout.propTypes = {
    items: PropTypes.any,
    data: PropTypes.any,
    years: PropTypes.any,
    categoryList: PropTypes.any,
    handleInputChange: PropTypes.func,
    handleRemove: PropTypes.func,
    noAddition: PropTypes.any
  };

  let child_class = 'collapse in';
  return (
    <>
      {items.map((item, index) => {
        if (Object.keys(item).length > 0 && Object.keys(item)[0] !== 'null') {
          return (
            <FixedColumnTableRows
              key={index}
              category={Object.keys(item)[0]}
              data={data}
              years={years}
              handleInputChange={handleInputChange}
              fixedName={categoryList.find((el) => el.key === Object.keys(item)[0])?.name}
              noAddition={noAddition}
              handleRemove={handleRemove}
              type={categoryList.find((el) => el.key === Object.keys(item)[0])?.option}
              itemIndex={items
                .slice(0, index)
                .map((item) => Object.values(item)[0]?.length)
                .reduce((partialSum, a) => partialSum + a, 0)}
              // itemIndex={index}
              negative={categoryList.find((el) => el.key === Object.keys(item)[0])?.negative}
            />
            // key={index}
            // category={item.key}
            // data={data}
            // years={years}
            // fixedName={item.name}
            // type={item?.option}
            // handleRemove={handleRemove}
            // handleInputChange={handleInputChange}
          );
        } else {
          if (
            item &&
            item[Object.keys(item)[0]] &&
            Object.values(item[Object.keys(item)[0]]).length > 0
          ) {
            return (
              <Table.Row key="rows" id={data?.key} className={child_class}>
                <Table.Cell
                  colSpan={
                    years.length == 4
                      ? '10'
                      : years.length == 3
                      ? '8'
                      : years.length == 2
                      ? '6'
                      : '4'
                  }
                >
                  <Table celled className="table">
                    <Table.Body>
                      <EditTableRows
                        negative={
                          categoryList.find((el) => el.key === Object.keys(item)[0])?.negative
                        }
                        negativeList={['rental-expenses', 'trading-income']}
                        handleRemove={handleRemove}
                        handleInputChange={handleInputChange}
                        data_key={data?.key}
                        items={Object.entries(item[Object.keys(item)[0]])}
                        itemIndex={items
                          .slice(0, index)
                          .map((item) => Object.values(item)[0]?.length)
                          .reduce((partialSum, a) => partialSum + a, 0)}
                        years={years}
                        colspan="2"
                        type={categoryList.find((el) => el.key === Object.keys(item)[0])?.option}
                      />
                      {/* negative={negative}
                        negativeList={['Rental Expenses', 'Trading Income']}
                        handleRemove={handleRemove}
                        handleInputChange={handleInputChange}
                        data_key={data?.key}
                        items={Object.entries(item[Object.keys(item)[0]])}
                        itemIndex={items
                          .slice(0, index)
                          .map((item) => Object.values(item)[0]?.length)
                          .reduce((partialSum, a) => partialSum + a, 0)}
                        years={years}
                        colspan="2" */}
                    </Table.Body>
                  </Table>
                </Table.Cell>
              </Table.Row>
            );
          }
        }
      })}
    </>
  );
};

export default CommonLayout;
