import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { NavDropdown } from 'react-bootstrap';
import DataEntryHeader from '../../../../../components/DataEntryHeader';
import { addResidentialRentalSchema } from '../../../../common/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  rentalState,
  getRentalData,
  editRental,
  setEditRentalResponse
} from '../../../../../slices/slices/RentalSlice';
import { getMemberData, facilityState } from '../../../../../slices/slices/FacilitySlice';
import { getMemberList, jobSetupState } from '../../../../../slices/slices/JobSetupSlice';
import Form from './components/Form';
import { displayNameFromType } from '../../../../common/Misc';
import { useNavigatingAway } from '../../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../../common/components/DialogLeavingPage';

const EditResidentialRental = () => {
  const dispatch = useDispatch();
  const { jobId, memberId, rentalId } = useParams();
  const rentalDataResponse = useSelector(rentalState);
  const editRentalResponse = useSelector(rentalState);
  const memberDataResponse = useSelector(facilityState);
  const { memberListResponse } = useSelector(jobSetupState);
  const navigate = useNavigate();
  let existingValues = [];
  let overwrittenFields = [];
  const formValues = useRef();

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const getOwners = () => {
    let temp;
    if (
      rentalDataResponse?.rentalDataResponse?.data?.length > 0 &&
      Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]?.rental_owners?.length > 0
    ) {
      temp = Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]?.rental_owners?.map(
        (share) => ({
          member_id: newColumnsForAll?.find((item) => item?.value === share?.pivot?.member_id),
          holding: share?.pivot?.holding
        })
      );
    } else {
      temp = [
        {
          member_id: '',
          holding: 0
        }
      ];
    }
    return temp;
  };

  const handleFormSubmit = async (values, { setErrors }) => {
    let members = [];
    for (let item of JSON.parse(JSON.stringify(values?.owners))) {
      members.push({
        holding: item?.holding,
        member_id: item?.member_id?.value
      });
    }

    setCanShowDialogLeavingPage(false);
    {
      if (values?.is_completed === 0) {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += parseInt(values.owners[index].holding);
        });
        total <= 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      } else {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += parseInt(values.owners[index].holding);
        });
        total === 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      }
    }

    values.tenant[0].current_rental_income_pa = parseInt(
      `${values.tenant[0].current_rental_income_pa}`.replace(/,/g, '')
    );
    values.owners = members;
    dispatch(editRental(jobId, memberId, rentalId, values, setErrors));
  };

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getRentalData(jobId, memberId, rentalId));
    if (editRentalResponse?.editRentalResponse?.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setEditRentalResponse({}));
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/rentals?tab=2');
    }
  }, [editRentalResponse?.editRentalResponse?.message]);

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getMemberList(jobId));
    dispatch(getRentalData(jobId, memberId, rentalId));
  }, []);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/rentals'}>Rental</Link>
              </li>
            </ul>
            <h1>Rentals - {displayNameFromType(memberDataResponse?.memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <NavDropdown
              title=""
              id="nav-dropdown"
              style={{ marginLeft: 10 + 'px' }}
              className="d-flex align-items-center icon-info"
            >
              <NavDropdown.Item className="dropdown-item information">
                <h4>
                  Information about
                  <br />
                  relative screen
                </h4>
                <a href="/facilities" className="close">
                  <i className="icon-close"></i>
                </a>
                <p>
                  Lorem ipsum dolor sit amet, consecte tur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                  fugiat nulla pariatur.
                </p>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <Formik
          initialValues={{
            type: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]?.type,
            owners: getOwners(),

            address: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]?.address,
            report_comments: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]
              ?.additional_info?.report_comments,
            internal_notes: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]
              ?.additional_info?.internal_notes,
            is_completed: '0',
            shading: rentalDataResponse?.rentalDataResponse?.data?.[0]?.additional_info?.tenant
              ?.shading
              ? rentalDataResponse?.rentalDataResponse?.data?.[0]?.additional_info?.tenant?.shading
              : 0,
            tenant: [
              {
                sensitised_rental_pa: '50',
                current_rental_income_pa: Object.assign(
                  {},
                  rentalDataResponse?.rentalDataResponse?.data
                )[0]?.additional_info?.tenant?.current_rental_income_pa,
                shading: Object.assign({}, rentalDataResponse?.rentalDataResponse?.data)[0]
                  ?.additional_info?.tenant?.shading
              }
            ]
          }}
          enableReinitialize
          validationSchema={addResidentialRentalSchema}
          onSubmit={handleFormSubmit}
          innerRef={formValues}
        >
          {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                handleInputChange={handleInputChange}
                memberDataResponse={memberDataResponse}
                memberListResponse={memberListResponse}
                memberId={memberId}
                jobId={jobId}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditResidentialRental;
