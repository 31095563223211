import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const Partnership = (props) => {
  Partnership.propTypes = {
    data: PropTypes.any
  };

  const { jobId } = useParams();

  return (
    <div className="corporate-structure-blocks partnership">
      <h4>
        Partnership
        <Link to={'/job/' + jobId + '/group-setup/members/edit/' + props.data?.id}>
          <button type="button" className="fa fa-edit"></button>
        </Link>
      </h4>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <tr>
          <td width="34%">Legal Name</td>
          <td colSpan="2">{props.data.name}</td>
        </tr>
        <tr>
          <td>ABN</td>
          <td colSpan="2">{props.data?.additional_info?.abn}</td>
        </tr>
        <tr>
          <td>Trading Name</td>
          <td colSpan="2">{props.data?.additional_info?.trading_name}</td>
        </tr>
        <tr>
          <td>Activity</td>
          <td colSpan="2">{props.data?.activity}</td>
        </tr>
        {props.data?.additional_info?.partners?.map((item, index) => {
          return (
            <tr key={index}>
              <td>Partner</td>
              <td>{item?.name?.label}</td>
              <td width="18%">
                {item?.holding}
                {item?.holding ? '%' : ''}
              </td>
            </tr>
          );
        })}
      </table>
      {/* <ul>
        <li>
          <label>Legal Name</label>
          <p>{props.data.name}</p>
        </li>
        <li>
          <label>ABN</label>
          <p>{props.data?.additional_info?.abn}</p>
        </li>
        <li>
          <label>Trading Name</label>
          <p>{props.data?.additional_info?.trading_name}</p>
        </li>
        <li>
          <label>Activity</label>
          <p>{props.data?.activity}</p>
        </li>
        {props.data?.additional_info?.partners?.map((item, index) => {
          return (
            <li key={index}>
              <label htmlFor="">Partner</label>
              <p>{item?.name?.label}</p>
              <p>
                {item?.holding}
                {item?.holding ? '%' : ''}
              </p>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
};

export default Partnership;
