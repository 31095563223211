import { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import {
  deleteFacility,
  getFacilityListData,
  facilityState,
  setDeleteFacilityDataResponse,
  getMemberData,
  getMetaData,
  manageSecurityRelation
} from '../../../../slices/slices/FacilitySlice';
import Button from '../../../../components/Button';
import { Link, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType, displayOwnerNameFromType } from '../../../common/Misc';
import Info from '../../../../components/Info';
import { updateMarkAll } from '../../../../slices/slices/JobSetupSlice';
import { formatNumber } from '../../../../pages/common/Misc';

const Facilities = () => {
  const dispatch = useDispatch();
  const {
    memberDataResponse,
    metaDataResponse,
    facilityListDataResponse,
    deleteFacilityDataResponse,
    manageSecurityDataResponse
    //setUpdatedMarkAllResponse
  } = useSelector(facilityState);
  const [showDelete, setShowDelete] = useState(false);
  const [facilityId, setFacilityId] = useState(0);
  const { jobId, memberId } = useParams();
  //const navigate = useNavigate();

  let existing_limit_total = 0,
    change_total = 0,
    proposed_limit_total = 0;

  const handleDelete = () => {
    setShowDelete(false);
  };

  const delFacility = () => {
    dispatch(deleteFacility(jobId, memberId, facilityId));
  };

  useEffect(() => {
    if (deleteFacilityDataResponse?.message) {
      setShowDelete(false);
      dispatch(getFacilityListData(jobId, memberId));
      dispatch(setDeleteFacilityDataResponse({}));
    }
  }, [deleteFacilityDataResponse?.message]);

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getFacilityListData(jobId, memberId));
    dispatch(getMetaData());
  }, []);

  // useEffect(() => {
  //   navigate('/job/' + jobId + '/data-entry/');
  // }, [setUpdatedMarkAllResponse]);

  useEffect(() => {
    dispatch(getFacilityListData(jobId, memberId));
  }, [manageSecurityDataResponse]);

  const findValueFromKey = (object, key) => {
    if (typeof object === 'object') {
      let result = object.find((obj) => {
        return obj.key === key;
      });
      return result?.value;
    }
  };

  const handleSecurityLinking = (e) => {
    let facilityId, securityId;
    facilityId = e.target.dataset.facilityid;
    securityId = e.target.dataset.securityid;
    dispatch(manageSecurityRelation(jobId, memberId, facilityId, securityId));
  };

  const handleMarkAllCompleted = (setErrors) => {
    let formData = { module: 'facility' };
    // formData.append('module', 'facility');
    dispatch(updateMarkAll(jobId, memberId, formData, setErrors));
  };

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <strong>Facilities</strong>
              </li>
            </ul>
            <h1>Facilities - {displayNameFromType(memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/facilities/add'}>
              <button type="button" className="green topbtn">
                <i className="icon-plus"></i> Facility
              </button>
            </Link>
            <DialogModal
              title="Facility"
              p1={constants.facilities1}
              p2={constants.facilities2}
              p3={constants.facilities3}
            />
          </div>
        </div>
        <div className="mt-30" style={{ marginRight: '-15px' }}>
          <Info />
        </div>
        <div className="white-card mt-30">
          <section className="Facilitylist member-data-entry facility-table">
            <Table className="table" cellPadding="0" cellSpacing="0" border="0">
              <thead>
                <tr>
                  <th className="drag"></th>
                  <th className="purpose align-left">Purpose</th>
                  <th className="product-facility">Product/Facility</th>
                  <th className="lender">Lender</th>
                  <th className="existing-limit">Existing Limit</th>
                  <th className="change">Change + / -</th>
                  <th className="proposed-limit">Proposed Limit</th>
                  <th className="action"></th>
                </tr>
              </thead>
              <tbody>
                {facilityListDataResponse?.data?.data?.length > 0 ? (
                  facilityListDataResponse?.data?.data.map((item) => {
                    let market_value_total = 0,
                      lending_value_total = 0,
                      change = 0;
                    let existing_limit = Number(item?.existing_limit);
                    if (
                      (item?.product_facility === 'asset-finance-chp' ||
                        item?.product_facility === 'asset-finance-chattel-mortgage' ||
                        item?.product_facility === 'asset-finance-lease') &&
                      item?.additional_info?.facility_status === 'existing'
                    ) {
                      existing_limit = Number(item?.additional_info?.current_balance);
                    }
                    change = Number(item?.additional_info?.proposed_limit) - Number(existing_limit);
                    existing_limit_total += Number(existing_limit);
                    change_total += Number(change);
                    proposed_limit_total += Number(item?.additional_info?.proposed_limit);

                    return (
                      <>
                        <tr key={item?.id}>
                          <td data-toggle="collapse" data-target={'#' + item?.id}>
                            <button className="nested-btn active">
                              <i className="fa fa-caret-up"></i>
                            </button>
                          </td>
                          <td className="align-left">
                            {/* <Link
                              to={
                                '/job/' +
                                jobId +
                                '/data-entry/' +
                                memberId +
                                '/facilities/' +
                                item?.id
                              }
                              >
                                <span>{item?.purpose}</span>
                              </Link> */}
                            {item?.purpose}
                          </td>
                          <td>
                            {findValueFromKey(
                              metaDataResponse?.data?.facilities_products,
                              item?.product_facility
                            )}
                          </td>
                          <td>
                            {findValueFromKey(
                              metaDataResponse?.data?.facilities_lenders,
                              item?.lender
                            )}
                          </td>
                          <td className="align-right">
                            <NumberFormat
                              value={formatNumber(existing_limit)}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              // decimalScale={2}
                              displayType="text"
                            />
                          </td>
                          <td className="align-right">
                            <NumberFormat
                              value={formatNumber(Math.abs(change))}
                              prefix={change < 0 ? '(' : null}
                              suffix={change < 0 ? ')' : null}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              // decimalScale={2}
                              displayType="text"
                            />
                          </td>
                          <td className="align-right">
                            <NumberFormat
                              value={formatNumber(item?.additional_info?.proposed_limit)}
                              // value={proposed_limit}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              // decimalScale={2}
                              displayType="text"
                            />
                          </td>
                          <td className="actionarea" width="10%">
                            <a>
                              <i
                                className={
                                  item?.is_completed === 1
                                    ? 'fa fa-check-circle'
                                    : 'fa fa-exclamation-triangle'
                                }
                                aria-hidden="true"
                                style={{
                                  color: `${item?.is_completed === 1 ? '#42ba96' : '#eed202'}`
                                }}
                              ></i>
                            </a>
                            <Link
                              to={
                                '/job/' +
                                jobId +
                                '/data-entry/' +
                                memberId +
                                '/facilities/' +
                                item?.id
                              }
                            >
                              {/* <a className="edit"> */}
                              <i className="icon-edit"></i>
                              {/* </a> */}
                            </Link>
                            <a
                              onClick={() => {
                                setFacilityId(item?.id);
                                setShowDelete(true);
                              }}
                              className="delete"
                            >
                              <i className="icon-delete"></i>
                            </a>
                          </td>
                        </tr>
                        <tr id={item?.id} className="collapse">
                          <td colSpan="8" className="nested-block">
                            <h3>Linked Securities</h3>
                            <Link
                              to={
                                '/job/' +
                                jobId +
                                '/data-entry/' +
                                memberId +
                                '/securities/return/facilityList/' +
                                item?.id
                              }
                            >
                              <button type="button">
                                <i className="icon-plus"></i> Security
                              </button>
                            </Link>
                            <table className="table" cellPadding="0" cellSpacing="0" border="0">
                              <thead>
                                <tr>
                                  <th scope="col" className="align-left">
                                    Security Owner
                                  </th>
                                  <th scope="col" className="align-left">
                                    Address
                                  </th>
                                  <th scope="col">Class</th>
                                  <th scope="col">Market Value</th>
                                  <th scope="col">Shading</th>
                                  <th scope="col">Lending Value</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.securities && item.securities.length > 0 ? (
                                  item.securities.map((security) => {
                                    market_value_total += security?.market_value;
                                    lending_value_total += security?.lending_value;
                                    return (
                                      <tr key={security?.id}>
                                        <td className="align-left">
                                          {displayOwnerNameFromType(security?.security_owners)}
                                        </td>
                                        <td className="align-left">{security?.address}</td>
                                        <td>{security?.asset_class_val}</td>
                                        <td className="align-right">
                                          <NumberFormat
                                            value={security?.market_value}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={0}
                                            // decimalScale={2}
                                            displayType="text"
                                          />
                                        </td>
                                        <td>
                                          <NumberFormat
                                            value={security?.shading}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={0}
                                            suffix="%"
                                            displayType="text"
                                          />
                                        </td>
                                        <td className="align-right">
                                          <NumberFormat
                                            value={security?.lending_value}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={0}
                                            // decimalScale={2}
                                            displayType="text"
                                          />
                                        </td>
                                        <td>
                                          <a
                                            onClick={(e) => handleSecurityLinking(e)}
                                            data-facilityid={item?.id}
                                            data-securityid={security?.id}
                                            href="#"
                                          >
                                            Unlink
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="7">No Records Found</td>
                                  </tr>
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td className="align-left">Total</td>
                                  <td className="align-left"></td>
                                  <td></td>
                                  <td className="align-right">
                                    <NumberFormat
                                      value={market_value_total}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      // decimalScale={2}
                                      displayType="text"
                                    />
                                  </td>
                                  <td></td>
                                  <td className="align-right">
                                    <NumberFormat
                                      value={lending_value_total}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      // decimalScale={2}
                                      displayType="text"
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                              </tfoot>
                            </table>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} style={{ textAlign: 'center' }}>
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td className="align-left"></td>
                  <td className="align-left">Total</td>
                  <td></td>
                  <td></td>
                  <td className="align-right">
                    <NumberFormat
                      value={formatNumber(existing_limit_total)}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      // decimalScale={2}
                      displayType="text"
                    />
                  </td>
                  <td className="align-right">
                    <NumberFormat
                      value={formatNumber(Math.abs(change_total))}
                      prefix={change_total < 0 ? '(' : null}
                      suffix={change_total < 0 ? ')' : null}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      // decimalScale={2}
                      displayType="text"
                    />
                  </td>
                  <td className="align-right">
                    <NumberFormat
                      value={formatNumber(proposed_limit_total)}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      // decimalScale={2}
                      displayType="text"
                    />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          </section>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '30px',
            marginBottom: '20px'
          }}
        >
          <button
            className="btn green"
            onClick={() => {
              handleMarkAllCompleted();
            }}
          >
            Section Completed
          </button>
        </div>
        <Modal
          show={showDelete}
          onHide={handleDelete}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <h2>Delete Facility?</h2>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this facility permanently?</p>
          </Modal.Body>
          <div className="modal-action modal-footer">
            <Button className="btn btn-secondary" onClick={handleDelete}>
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                delFacility();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Facilities;
