import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Header';
import { Form } from 'react-bootstrap';
import { Field, FieldArray, Formik } from 'formik';
import FormActions from '../../../components/FormActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  recommendationState,
  getRecommendationData,
  updateRecommendation
} from '../../../slices/slices/RecommendationSlice';
import DialogModal from '../../../components/modal/DialogModal';
import constants from '../../../constant';
import { DialogLeavingPage } from '../../common/components/DialogLeavingPage';
import { useNavigatingAway } from '../../common/hooks/useNavigatingAway';

const Recommendation = () => {
  const recommendationDataResponse = useSelector(recommendationState);
  const hold_Off = recommendationDataResponse?.RecommendationDataResponse?.data?.hold_off;
  const corrective_action =
    recommendationDataResponse?.RecommendationDataResponse?.data?.corrective_action;
  const proceedData = recommendationDataResponse?.RecommendationDataResponse?.data?.proceed;
  const status = recommendationDataResponse?.RecommendationDataResponse?.data?.status;
  const [holdOff, setHoldOff] = useState(hold_Off);
  const [correctiveAction, setCorrectiveAction] = useState(corrective_action);
  const [proceed, setProceed] = useState(proceedData);
  const [selectedStatus, setselectedStatus] = useState(status);
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const [inputCheck, setInputCheck] = useState({
    hold_Off: false,
    corrective_action: false,
    proceed: false
  });
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    switch (recommendationDataResponse?.RecommendationDataResponse?.data?.status) {
      case 'hold_off':
        setInputCheck({ hold_Off: true });
        break;
      case 'corrective_action':
        setInputCheck({ corrective_action: true });
        break;
      case 'proceed':
        setInputCheck({ proceed: true });
        break;
      default:
        setInputCheck({ hold_Off: false });
        setInputCheck({ corrective_action: false });
        setInputCheck({ proceed: false });
        break;
    }
    setselectedStatus(recommendationDataResponse?.RecommendationDataResponse?.data?.status);
    setHoldOff(recommendationDataResponse?.RecommendationDataResponse?.data?.hold_off);
    setCorrectiveAction(
      recommendationDataResponse?.RecommendationDataResponse?.data?.corrective_action
    );
    setProceed(recommendationDataResponse?.RecommendationDataResponse?.data?.proceed);
  }, [recommendationDataResponse?.RecommendationDataResponse?.data]);

  useEffect(() => {
    dispatch(getRecommendationData(jobId));
  }, []);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    dispatch(updateRecommendation(jobId, values, setErrors));
  };

  const deleteText = (Array, key, name) => {
    Array.splice(key, 1);
    if (name == 'hold_off') {
      setHoldOff(Array);
    } else if (name == 'corrective_action') {
      setCorrectiveAction(Array);
    } else {
      setProceed(Array);
    }
  };

  const initialValue = {
    status: selectedStatus,
    hold_off: holdOff?.length > 0 ? holdOff?.map((dir) => dir) : [''],
    corrective_action: correctiveAction?.length > 0 ? correctiveAction?.map((dir) => dir) : [''],
    proceed: proceed?.length > 0 ? proceed?.map((dir) => dir) : ['']
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="#">Group Assessment</a>
              </li>
              <li>
                <a href="#">Bankability Assessment</a>
              </li>
              <li className="breadcrumb-item active">
                <strong>Recommendation</strong>
              </li>
            </ul>
          </div>
          <div className="breadcrumb-right d-flex">
            <DialogModal
              title="Recommendation"
              p1={constants.recommandation1}
              p2={constants.recommandation2}
            />
          </div>
        </div>
        <div>
          <Formik
            initialValues={initialValue}
            onChange
            onSubmit={handleFormSubmit}
            enableReinitialize
          >
            {({ values, handleChange, handleSubmit }) => {
              return (
                <>
                  <DialogLeavingPage
                    showDialog={showDialogLeavingPage}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                  />
                  <Form onSubmit={handleSubmit} className="facility-form form-data">
                    <div className="pagetitle proversion">
                      <div className="title_pro d-flex justify-content-between align-items-center">
                        <h1>Recommendation</h1>
                      </div>
                    </div>

                    <div className="gray-card" style={{ marginTop: '0' }}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="recommendationpro">
                            <div className="cpt_title offred d-flex justify-content-center align-items-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="gridCheck1"
                                checked={selectedStatus === 'hold_off'}
                                value={inputCheck.hold_Off}
                                // disabled={inputCheck.corrective_action || inputCheck.proceed}
                                onChange={(e) => {
                                  selectedStatus === 'hold_off'
                                    ? setselectedStatus('')
                                    : setselectedStatus('hold_off');
                                  setInputCheck({ hold_Off: e.target.checked });
                                  handleInputChange(e.target.value);
                                }}
                              />
                              <h2 className="rec_title mt-2 text-light">HOLD OFF</h2>
                            </div>
                            <div className="nextsteps_area off_red">
                              <label>Next Steps</label>
                              <FieldArray
                                name="hold_off"
                                index="1"
                                render={(arrayHelpers) => (
                                  <div>
                                    <div className="director">
                                      {values.hold_off && values.hold_off.length > 0
                                        ? values.hold_off.map((director, index) => (
                                            <>
                                              <div key={index} className="recommend-div">
                                                <Field
                                                  component="textarea"
                                                  disabled={
                                                    selectedStatus === 'hold_off' ? false : true
                                                  }
                                                  rows="4"
                                                  name={`hold_off.${index}`}
                                                  className="form-control"
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleInputChange(e.target.value);
                                                  }}
                                                />
                                                <i
                                                  className="fa fa-trash-alt"
                                                  onClick={() =>
                                                    deleteText(values.hold_off, index, 'hold_off')
                                                  }
                                                ></i>
                                              </div>
                                            </>
                                          ))
                                        : null}
                                    </div>
                                    <div className="add-btn">
                                      <i
                                        className="icon-plus"
                                        onClick={() => {
                                          var isEmpty = false;
                                          var c;
                                          for (c = 0; c < values.hold_off.length; c++) {
                                            if (values.hold_off[c] == '') {
                                              isEmpty = true;
                                              return;
                                            }
                                          }
                                          if (!isEmpty) {
                                            arrayHelpers.push('');
                                          }
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="recommendationpro">
                            <div className="cpt_title offyellow d-flex justify-content-center align-items-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="gridCheck2"
                                checked={selectedStatus === 'corrective_action'}
                                value={inputCheck.corrective_action}
                                // disabled={inputCheck.hold_Off || inputCheck.proceed}
                                onChange={(e) => {
                                  selectedStatus === 'corrective_action'
                                    ? setselectedStatus('')
                                    : setselectedStatus('corrective_action');
                                  handleInputChange(e.target.value);
                                  setInputCheck({ corrective_action: e.target.checked });
                                }}
                              />
                              <h2 className="rec_title mt-2 text-light">CORRECTIVE ACTION</h2>
                            </div>
                            <div className="nextsteps_area off_yellow">
                              <label>Next Steps</label>
                              <FieldArray
                                name="corrective_action"
                                render={(arrayHelpers) => (
                                  <div>
                                    <div className="director">
                                      {values.corrective_action &&
                                      values.corrective_action.length > 0
                                        ? values.corrective_action.map((director, index) => (
                                            <>
                                              <div key={index} className="recommend-div">
                                                <Field
                                                  component="textarea"
                                                  rows="4"
                                                  disabled={
                                                    selectedStatus === 'corrective_action'
                                                      ? false
                                                      : true
                                                  }
                                                  name={`corrective_action.${index}`}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleInputChange(e.target.value);
                                                  }}
                                                  className="form-control"
                                                />
                                                <i
                                                  className="fa fa-trash-alt"
                                                  onClick={() =>
                                                    deleteText(
                                                      values.corrective_action,
                                                      index,
                                                      'corrective_action'
                                                    )
                                                  }
                                                ></i>
                                              </div>
                                            </>
                                          ))
                                        : null}
                                    </div>
                                    <div className="add-btn">
                                      <i
                                        className="icon-plus"
                                        onClick={() => {
                                          var isEmpty = false;
                                          var c;
                                          for (c = 0; c < values.corrective_action.length; c++) {
                                            if (values.corrective_action[c] == '') {
                                              isEmpty = true;
                                              return;
                                            }
                                          }
                                          if (!isEmpty) {
                                            arrayHelpers.push('');
                                          }
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="recommendationpro">
                            <div className="cpt_title offgreen d-flex justify-content-center align-items-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="gridCheck3"
                                checked={selectedStatus === 'proceed'}
                                value={inputCheck.proceed}
                                // disabled={inputCheck.hold_Off || inputCheck.corrective_action}
                                onChange={(e) => {
                                  selectedStatus === 'proceed'
                                    ? setselectedStatus('')
                                    : setselectedStatus('proceed');
                                  handleInputChange(e.target.value);
                                  setInputCheck({ proceed: e.target.checked });
                                }}
                              />
                              <h2 className="rec_title mt-2 text-light">PROCEED</h2>
                            </div>
                            <div className="nextsteps_area off_green">
                              <label>Next Steps</label>
                              <FieldArray
                                name="proceed"
                                render={(arrayHelpers) => (
                                  <div>
                                    <div className="director">
                                      {values.proceed && values.proceed.length > 0
                                        ? values.proceed.map((director, index) => (
                                            <>
                                              <div key={index} className="recommend-div">
                                                <Field
                                                  component="textarea"
                                                  rows="4"
                                                  disabled={
                                                    selectedStatus === 'proceed' ? false : true
                                                  }
                                                  name={`proceed.${index}`}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleInputChange(e.target.value);
                                                  }}
                                                  className="form-control"
                                                />
                                                <i
                                                  className="fa fa-trash-alt"
                                                  onClick={() =>
                                                    deleteText(values.proceed, index, 'proceed')
                                                  }
                                                ></i>
                                              </div>
                                            </>
                                          ))
                                        : null}
                                    </div>
                                    <div className="add-btn">
                                      <i
                                        className="icon-plus"
                                        onClick={() => {
                                          var isEmpty = false;
                                          var c;
                                          for (c = 0; c < values.proceed.length; c++) {
                                            if (values.proceed[c] == '') {
                                              isEmpty = true;
                                              return;
                                            }
                                          }
                                          if (!isEmpty) {
                                            arrayHelpers.push('');
                                          }
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="action-box full">
                      <FormActions cancel={'/job/' + jobId + '/dashboard'} values={values} />
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Recommendation;
