import React, { useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { authState, doLogout, setLogoutResponse } from '../slices/slices/AuthSlice';
import { getSelectedMemberData } from '../slices/slices/DataEntrySlice';
// import { dataEntryState, getSelectedMemberData } from '../slices/slices/DataEntrySlice';

import { getJobByStatus } from '../slices/slices/JobSlice';
import { Link, useParams } from 'react-router-dom';
import { GetCookies, SetCookie } from '../services/cookies';

const DataEntryHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission_str = GetCookies('permission');
  const permission = permission_str ? JSON.parse(JSON.stringify(permission_str)) : [];

  const { logoutResponse } = useSelector(authState);
  // const { jobDataResponse } = useSelector(jobState);
  // const { selectedMember } = useSelector(dataEntryState);
  const { jobId, memberId } = useParams();
  const localData = localStorage.getItem('data');
  const getLastItem = window.location.pathname.split('/').pop();
  const json_str = GetCookies('_jobData');
  const jobItem = json_str ? JSON.parse(JSON.stringify(json_str)) : [];
  const jobHeader = GetCookies('_jobHeader');
  const jobItem1 = jobHeader && JSON.parse(JSON.stringify(jobHeader));
  const size = 5;
  const itemsData = jobItem.reverse();
  const getJobItem = itemsData.slice(0, size);

  const signOut = () => {
    dispatch(doLogout());
  };

  const onClickRow = (item) => {
    const data = {
      jobId: item.jobId ? item.jobId : jobItem?.jobId,
      job_no: item.job_no ? item.job_no : jobItem?.job_no,
      jobName: item.jobName
    };

    SetCookie('_jobHeader', data);

    navigate('/job/' + item?.id + '/dashboard');
  };

  useEffect(() => {
    dispatch(getJobByStatus({ status: 'active', showing: 5 }));
    if (logoutResponse.message) {
      navigate('/login');
      dispatch(setLogoutResponse({}));
    }
  }, [logoutResponse.message]);

  useEffect(() => {
    dispatch(
      getSelectedMemberData(
        jobId ? jobId : JSON.parse(localData).jobId,
        memberId ? memberId : JSON.parse(localData).member
      )
    );
  }, []);

  const getJobId = () => {
    return jobId ? jobId : JSON.parse(localData).jobId;
  };
  const getMemberId = () => {
    return memberId ? memberId : JSON.parse(localData).member;
  };

  return (
    <>
      <div
        id="header"
        className="header fixed-top d-flex align-items-center justify-content-between"
      >
        <div className="d-flex align-items-center justify-content-between joblistdropdown">
          <NavDropdown
            title={`Job ${jobItem1?.job_no} - ${jobItem1?.jobName}`}
            id="nav-dropdown"
            className="d-flex align-items-center job-title"
          >
            {getJobItem
              ? getJobItem?.map((item, index) => {
                  return (
                    <NavDropdown.Item
                      key={index}
                      onClick={() => {
                        onClickRow(item);
                      }}
                    >
                      <span>
                        Job {item?.job_no} - {item?.jobName}
                      </span>
                    </NavDropdown.Item>
                  );
                })
              : null}
            <p>
              <a href="/dashboard">View All Jobs</a>
            </p>
          </NavDropdown>
        </div>

        <div className="top-navbar">
          <ul>
            {permission.includes('data-entry') && (
              <li>
                {/* <Link to={'/job/' + jobId + '/data-entry'}>
                <i className="fa fa-solid fa-arrow-left"></i> Dashboard
              </Link> */}
                <Link to={'/job/' + getJobId() + '/data-entry'}>
                  <i className="fa fa-solid fa-arrow-left"></i> Data Entry
                </Link>
              </li>
            )}
            <li className="dropdown">
              <Link
                to={'/job/' + getJobId() + '/data-entry/' + getMemberId() + '/facilities'}
                className={getLastItem === 'facilities' ? 'active' : ''}
              >
                Facilities
              </Link>
            </li>
            <li className="dropdown">
              <Link
                to={'/job/' + getJobId() + '/data-entry/' + getMemberId() + '/securities'}
                className={getLastItem === 'securities' ? 'active' : ''}
              >
                Securities
              </Link>
            </li>
            <li className="dropdown">
              <Link
                to={'/job/' + getJobId() + '/data-entry/' + getMemberId() + '/financials'}
                className={getLastItem === 'financials' ? 'active' : ''}
              >
                Financials
              </Link>
            </li>
            <li className="dropdown">
              <Link
                to={'/job/' + getJobId() + '/data-entry/' + getMemberId() + '/rentals'}
                className={getLastItem === 'rentals' ? 'active' : ''}
              >
                Rentals
              </Link>
            </li>
            <li className="dropdown">
              <Link
                to={'/job/' + getJobId() + '/data-entry/' + getMemberId() + '/tax'}
                className={getLastItem === 'tax' ? 'active' : ''}
              >
                Tax
              </Link>
              {/* {selectedMember?.type == 'sole-trader' || selectedMember?.type == 'partnership' ? (
                ''
              ) : (
                <Link
                  to={'/job/' + getJobId() + '/data-entry/' + getMemberId() + '/tax'}
                  className={getLastItem === 'tax' ? 'active' : ''}
                >
                  Tax
                </Link>
              )} */}
            </li>
          </ul>
        </div>

        <div className="header-nav">
          <ul className="d-flex align-items-center">
            <li className="nav-item">
              <a
                className="nav-link nav-tool d-flex align-items-center pe-0"
                href="/bankability-tools"
              >
                <i className="icon-tools"></i>
              </a>
            </li>
            <NavDropdown
              title=""
              id="nav-dropdown"
              className="d-flex align-items-center icon-user-profile"
            >
              <NavDropdown.Item href="/setting" className="dropdown-item d-flex align-items-center">
                Settings
              </NavDropdown.Item>
              {permission.includes('staff-management') && (
                <NavDropdown.Item
                  href="/staff-management"
                  className="dropdown-item d-flex align-items-center"
                >
                  Staff Management
                </NavDropdown.Item>
              )}

              {/* {permission.includes('practice-setting') && (
                <NavDropdown.Item
                  href="/practice-setting"
                  className="dropdown-item d-flex align-items-center"
                >
                  Practice Settings
                </NavDropdown.Item>
              )} */}
              {permission.includes('administrator') && (
                <NavDropdown.Item
                  href="/admin-setting"
                  className="dropdown-item d-flex align-items-center"
                >
                  Admin Settings
                </NavDropdown.Item>
              )}
              <NavDropdown.Item
                onClick={() => {
                  signOut();
                }}
                className="dropdown-item d-flex align-items-center"
              >
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          </ul>
        </div>
      </div>
    </>
  );
};

export default DataEntryHeader;
