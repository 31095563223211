import { useSelector } from 'react-redux';
import { appState } from '../../slices/slices/AppSlice';

const Loader = () => {
  const { loader } = useSelector(appState);

  return (
    <>
      {loader ? (
        <div id="loader">
          <div className="loader"></div>
        </div>
      ) : null}
    </>
  );
};
export default Loader;
