import { useEffect } from 'react';
import { /* useDispatch, */ useSelector } from 'react-redux';
import { authState } from '../../slices/slices/AuthSlice';
import Authentication from '../../pages/authentication/Authentication';
import ForgotPassword from '../../pages/authentication/ForgotPassword';
import ResetPassword from '../../pages/authentication/ResetPassword';
import Login from '../../pages/authentication/Login';
import Profile from '../../pages/dashboard/Profile';
import Privacy from '../../pages/cms/Privacy';
import Terms from '../../pages/cms/Terms';
import Securities from '../../pages/job/dataEntry/securities/Securities';
import AddSecurity from '../../pages/job/dataEntry/securities/AddSecurity';
import HelpCenter from '../../pages/cms/HelpCenter';
import AuthenticationEmail from '../../pages/authentication/AuthenticationEmail';
import Index from '../../pages/dashboard';
import Setting from '../../pages/settings/Setting';
import TwoStepAuthentication from '../../pages/settings/twoFactor/TwoFactorAuthentication';
import AuthenticationCode from '../../pages/settings/twoFactor/AuthenticationCode';
import AddBackupEmail from '../../pages/settings/twoFactor/AddBackupEmail';
import ConfirmBackupEmail from '../../pages/settings/twoFactor/ConfirmBackupEmail';
import TwoFactorOption from '../../pages/settings/twoFactor/TwoFactorOption';
import JobSettings from '../../pages/job/groupSetup/JobSettings';
import BusinessOverview from '../../pages/job/groupSetup/BusinessOverview';
import Info from '../../pages/job/groupSetup/Info';
import Annualiase from '../../pages/job/groupSetup/Annualiase';
import BankabilityTools from '../../pages/cms/BankabilityTools';
import Permission from '../../pages/cms/Permission';
import StaffManagement from '../../pages/cms/StaffManagement';
import StaffManagmentDetail from '../../pages/cms/StaffManagementDetail';
import PractiseSetting from '../../pages/cms/PracticeSetting';
import AdminSetting from '../../pages/cms/AdminSetting';
import SubscriptionBilling from '../../pages/cms/SubscriptionBilling';
import Members from '../../pages/job/groupSetup/Members';
import Add from '../../pages/job/groupSetup/Member/Add';
import LearningTools from '../../pages/cms/ToolsTemplates/LearningTools';
import Accountants from '../../pages/cms/ToolsTemplates/Accountants';
import Brokers from '../../pages/cms/ToolsTemplates/Brokers';
import LivingExpense from '../../pages/job/groupSetup/LivingExpense';
import AddFacility from '../../pages/job/dataEntry/facilities/AddFacility';
import DataEntryMembers from '../../pages/job/dataEntry/DataEntryMembers';
import Facilities from '../../pages/job/dataEntry/facilities/Facilities';
import Edit from '../../pages/job/groupSetup/Member/Edit';
import JobDashboard from '../../pages/job';
import EditFacility from '../../pages/job/dataEntry/facilities/EditFacility';
import Rentals from '../../pages/job/dataEntry/rentals/Rentals';
import AddCommercialRental from '../../pages/job/dataEntry/rentals/commercial/AddCommercialRental';
import AddResidentialRental from '../../pages/job/dataEntry/rentals/residential/AddResidentialRental';
import EditResidentialRental from '../../pages/job/dataEntry/rentals/residential/EditResidentialRental';
import EditCommercialRental from '../../pages/job/dataEntry/rentals/commercial/EditCommercialRental';
import UnencumberedProperty from '../../pages/job/dataEntry/unencumberedProperty/UnencumberedProperty';
import AddProperty from '../../pages/job/dataEntry/unencumberedProperty/AddProperty';
import EditProperty from '../../pages/job/dataEntry/unencumberedProperty/EditProperty';
import EditSecurity from '../../pages/job/dataEntry/securities/EditSecurity';
import Corporate from '../../pages/job/groupAssessment/Corporate';
import Guidance from '../../pages/job/groupAssessment/Guidance';
import Recommendation from '../../pages/job/groupAssessment/Recommendation';
import BusinessAnalysis from '../../pages/job/groupAnalysis/businessAnalysis/Business';
import ProposedTransaction from '../../pages/job/groupAnalysis/proposedTransaction/ProposedTransaction';
import Financials from '../../pages/job/dataEntry/financials/Financials';
import EditLayout from '../../pages/job/dataEntry/financials/EditLayout';
import Tax from '../../pages/job/dataEntry/tax/Tax';
import AddFund from '../../components/job/groupAnalysis/proposedTransaction/AddFund';
import UnencumberedProperties from '../../pages/job/groupAnalysis/unencumberedProperty/UnencumberedProperties';
import NewReport from '../../pages/job/groupReports/NewReport';
import PublishedReport from '../../pages/job/groupReports/PublishedReport';
import ArchiveReport from '../../pages/job/groupReports/ArchiveReport';
import ProposedFacilities from '../../pages/job/groupAnalysis/proposedFacilities/ProposedFacilities';
import PersonalAnalysis from '../../pages/job/groupAnalysis/personalAnalysis/Personal';
import CurrentRentalIncome from '../../pages/job/groupAnalysis/currentRentalIncome/CurrentRentalIncome';
import ProposedDebtServicing from '../../pages/job/groupAnalysis/DebtServicing/ProposedDebtServicing';
import SensitisedDebtServicing from '../../pages/job/groupAnalysis/DebtServicing/SensitisedDebtServicing';
import OverallAssessment from '../../pages/job/groupAssessment/OverallAssessment';
import GroupServiceability from '../../pages/job/groupAnalysis/groupServiceability/GroupServiceability';
import TaxCalculations from '../../pages/job/groupAnalysis/taxCalculations/TaxCalculations';
import Importjob from '../../pages/dashboard/Importjob';
import Importmember from '../../pages/dashboard/Importmember';
import Importfinancial from '../../pages/job/dataEntry/financials/Importfinancial';
import Pagenotfound from '../../pages/authentication/Pagenotfound';
// const Login = lazy(() => import('../../pages/authentication/Login'));
import { GetCookies } from '../../services/cookies';
// const dispatch = useDispatch();

const useGetRoutes = () => {
  const json_str = GetCookies('permission');
  let userPermission = json_str ? JSON.parse(JSON.stringify(json_str)) : [];
  const { permission } = useSelector(authState);

  useEffect(() => {
    userPermission = permission;
  }, [permission]);

  return [
    // ----- Authentication -----
    {
      path: '/login',
      component: Login,
      type: 'public',
      authenticated: false,
      otpVerified: false,
      app: false,
      exact: true
    },

    {
      path: '/client/xeroXPM/sso/oauthredirct',
      component: Login,
      type: 'public',
      authenticated: false,
      otpVerified: false,
      app: false,
      exact: true
    },
    {
      path: '/',
      component: Login,
      type: 'private',
      authenticated: false,
      otpVerified: false,
      app: false,
      exact: true
    },
    {
      path: '/authentication',
      component: Authentication,
      type: 'public',
      authenticated: true,
      otpVerified: false,
      app: false,
      exact: true
    },
    {
      path: '/authentication-email',
      component: AuthenticationEmail,
      type: 'public',
      authenticated: true,
      otpVerified: false,
      app: false,
      exact: true
    },
    {
      path: '/forgot-password',
      component: ForgotPassword,
      type: 'public',
      authenticated: false,
      app: false,
      exact: true
    },
    {
      path: '/reset-password',
      component: ResetPassword,
      type: 'public',
      authenticated: false,
      otpVerified: false,
      app: false,
      exact: true
    },
    {
      path: '/dashboard',
      component: Index,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- 2FA Settings -----
    {
      path: '/two-step',
      component: TwoStepAuthentication,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/two-factor-option',
      component: TwoFactorOption,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/authentication-code-setting',
      component: AuthenticationCode,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/add-backup-email',
      component: AddBackupEmail,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/confirm-email',
      component: ConfirmBackupEmail,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/profile',
      component: Profile,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/setting',
      component: Setting,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Job Settings -----
    {
      path: '/job/:jobId/group-setup/settings',
      component: userPermission.includes('group-setup') ? JobSettings : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-setup/living-expense',
      component: userPermission.includes('group-setup') ? LivingExpense : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-setup/business-overview',
      component: userPermission.includes('group-setup') ? BusinessOverview : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/assessment/business-overview',
      component: userPermission.includes('group-assessment') ? BusinessOverview : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Job Listing -----
    {
      path: '/job/:jobId/dashboard',
      component: JobDashboard,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Member CRUD -----
    {
      path: '/job/:jobId/group-setup/members',
      component: userPermission.includes('group-setup') ? Members : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-setup/members/add',
      component: userPermission.includes('group-setup') ? Add : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-setup/members/edit/:memberId',
      component: userPermission.includes('group-setup') ? Edit : Pagenotfound,
      type: 'public',
      exact: true
    },
    {
      path: '/client/xeroXPM/xpm/oauthredirct',
      component: userPermission.includes('data-entry') ? Importjob : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/client/xeroXPM/xpm/importmember',
      component: userPermission.includes('data-entry') ? Importmember : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Data Entry -----
    {
      path: '/job/:jobId/data-entry',
      component: userPermission.includes('data-entry') ? DataEntryMembers : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Facilities - CRUD -----
    {
      path: '/job/:jobId/data-entry/:memberId/facilities',
      component: userPermission.includes('data-entry') ? Facilities : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/facilities/add',
      component: userPermission.includes('data-entry') ? AddFacility : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/facilities/:facilityId',
      component: userPermission.includes('data-entry') ? EditFacility : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/facilities/return/:securityType/:securityId',
      component: userPermission.includes('data-entry') ? AddFacility : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Securities - CRUD -----
    {
      path: '/job/:jobId/data-entry/:memberId/securities',
      component: userPermission.includes('data-entry') ? Securities : Pagenotfound,
      type: 'public',
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/securities/add',
      component: userPermission.includes('data-entry') ? AddSecurity : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/securities/return/:facilityType/:facilityId',
      component: userPermission.includes('data-entry') ? AddSecurity : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/securities/:securityId',
      component: userPermission.includes('data-entry') ? EditSecurity : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Rentals - CRUD -----
    {
      path: '/job/:jobId/data-entry/:memberId/rentals',
      component: userPermission.includes('data-entry') ? Rentals : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/add-commercial-rental',
      component: userPermission.includes('data-entry') ? AddCommercialRental : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/edit-commercial-rental/:rentalId',
      component: userPermission.includes('data-entry') ? EditCommercialRental : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/add-residential-rental',
      component: userPermission.includes('data-entry') ? AddResidentialRental : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/edit-residential-rental/:rentalId',
      component: userPermission.includes('data-entry') ? EditResidentialRental : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Unencumbered Properties - CRUD -----
    {
      path: '/job/:jobId/data-entry/:memberId/unencumbered-properties',
      component: userPermission.includes('data-entry') ? UnencumberedProperty : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/add-property',
      component: userPermission.includes('data-entry') ? AddProperty : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/edit-property/:propertyId',
      component: userPermission.includes('data-entry') ? EditProperty : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- BusinessTax - CRUD -----
    {
      path: '/job/:jobId/data-entry/:memberId/financials',
      component: userPermission.includes('data-entry') ? Financials : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/financials/import-financial',
      component: userPermission.includes('data-entry') ? Importfinancial : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/client/xeroXPM/xero/oauthredirct',
      component: userPermission.includes('data-entry') ? Importfinancial : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/edit-layout-financials',
      component: userPermission.includes('data-entry') ? EditLayout : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    // -------------- Group Analysis ---------------------
    {
      path: '/job/:jobId/group-analysis/unencumbered-properties', // Unencumbered Properties of every Members
      component: userPermission.includes('group-analysis') ? UnencumberedProperties : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/proposed-transaction', // Proposed Transaction
      component: userPermission.includes('group-analysis') ? ProposedTransaction : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/add-fund', // Add funds Proposed Transaction
      component: userPermission.includes('group-analysis') ? AddFund : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/proposed-transaction/:transactionId/funds/:fundsId', // Edit funds Proposed Transaction
      component: userPermission.includes('group-analysis') ? AddFund : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/proposed-facilities-security', // business financial analysis
      component: userPermission.includes('group-analysis') ? ProposedFacilities : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/business-analysis', // business financial analysis for group analysis
      component: userPermission.includes('group-analysis') ? BusinessAnalysis : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/personal-analysis', // personal financial analysis for group analysis
      component: userPermission.includes('group-analysis') ? PersonalAnalysis : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/current-rental-income', // current rental income for group analysis
      component: userPermission.includes('group-analysis') ? CurrentRentalIncome : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/proposed-debt-servicing', // proposed debt servicing for group analysis
      component: userPermission.includes('group-analysis') ? ProposedDebtServicing : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/sensitised-debt-servicing', // proposed debt servicing for group analysis
      component: userPermission.includes('group-analysis') ? SensitisedDebtServicing : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/groupserviceability', // GroupServiceability for group analysis
      component: userPermission.includes('group-analysis') ? GroupServiceability : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/group-analysis/taxcalculations', // TaxCalculations for group analysis
      component: userPermission.includes('group-analysis') ? TaxCalculations : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/data-entry/:memberId/tax',
      component: Tax,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },

    // ----- Other Routes -----
    {
      path: '/info',
      component: Info,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/annualiase',
      component: Annualiase,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/privacy',
      component: Privacy,
      type: 'public',
      exact: true
    },
    {
      path: '/terms',
      component: Terms,
      type: 'public',
      exact: true
    },

    // ----- Group Assessment -----
    {
      path: '/job/:jobId/assessment/overallassessment',
      component: userPermission.includes('group-assessment') ? OverallAssessment : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/assessment/corporate',
      component: userPermission.includes('group-assessment') ? Corporate : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/assessment/guidance',
      component: userPermission.includes('group-assessment') ? Guidance : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/assessment/recommendation',
      component: userPermission.includes('group-assessment') ? Recommendation : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/help-center',
      component: HelpCenter,
      type: 'public',
      exact: true
    },
    {
      path: '/bankability-tools',
      component: BankabilityTools,
      type: 'public',
      exact: true
    },
    {
      path: '/staff-management',
      component: userPermission.includes('staff-management') ? StaffManagement : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/permission',
      component: Permission,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/staff-management/:userId/staff/:accountantId',
      component: userPermission.includes('staff-management') ? StaffManagmentDetail : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/subscription-billing',
      component: SubscriptionBilling,
      type: 'public',
      exact: true
    },
    {
      path: '/practice-setting',
      component: userPermission.includes('practice-setting') ? PractiseSetting : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/admin-setting',
      component: userPermission.includes('administrator') ? AdminSetting : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/tools-templates',
      component: LearningTools,
      type: 'public',
      exact: true
    },
    {
      path: '/tools-templates/accountants',
      component:
        userPermission.includes('disp-templates') && userPermission.includes('disp-accountants')
          ? Accountants
          : Pagenotfound,
      type: 'public'
    },
    {
      path: '/tools-templates/brokers',
      component:
        userPermission.includes('disp-templates') && userPermission.includes('disp-brokers')
          ? Brokers
          : Pagenotfound,
      type: 'public'
    },

    // -------------- Group Reports ---------------------
    {
      path: '/job/:jobId/report/new',
      component: userPermission.includes('group-reports') ? NewReport : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/report/published',
      component: userPermission.includes('group-reports') ? PublishedReport : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    },
    {
      path: '/job/:jobId/report/archive',
      component: userPermission.includes('group-reports') ? ArchiveReport : Pagenotfound,
      type: 'public',
      authenticated: true,
      otpVerified: true,
      app: false,
      exact: true
    }
  ];
};

export default useGetRoutes;
