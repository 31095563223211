import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import {
  deleteProperty,
  unencumberedPropertyState,
  setDeletePropertyDataResponse,
  getTotalUnencumberedPropertyListData,
  updateTotalUnencumberedProperty
} from '../../../../slices/slices/UnencumberedPropertySlice';
import Button from '../../../../components/Button';
import FormActions from '../../../../components/FormActions';
import Header from '../../Header';
import { displayNameFromType, displayOwnerNameFromType, formatNumber } from '../../../common/Misc';
import Info from '../../../../components/Info';
import MemberModel from '../../../../components/MemberModel';
import { getMembers, jobSetupState } from '../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const UnencumberedProperties = () => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const [memberId, setMemberId] = useState(0);
  const [propertyId, setPropertyId] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const {
    totalUnencumberedPropertyListDataResponse,
    deletePropertyDataResponse,
    totalUpdateUnencumberedPropertyResponse
  } = useSelector(unencumberedPropertyState);
  const [totalUcProperty, setTotalUcProperty] = useState([
    totalUnencumberedPropertyListDataResponse?.data
  ]);
  const { membersData } = useSelector(jobSetupState);
  const [showMemberForm, setshowMemberForm] = useState(false);
  const [options, setOptions] = useState([]);
  const [type, setType] = useState();
  const [record_type, setRecordtype] = useState();
  let market_value_total = 0;
  const navigate = useNavigate();

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleMemberForm = () => {
    setshowMemberForm(false);
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    if (record_type) {
      values.is_refresh = 1;
      values.record_type = record_type;
    }
    dispatch(updateTotalUnencumberedProperty(jobId, values, setErrors));
    setRecordtype('');
  };

  const handleDelete = () => {
    setShowDelete(false);
  };

  const EditProperty = (item) => {
    navigate(
      '/job/' + jobId + '/data-entry/' + item?.uc_owners[0]?.id + '/edit-property/' + item?.id
    );
  };

  const delProperty = () => {
    setShowDelete(false);
    dispatch(deleteProperty(jobId, memberId, propertyId));
    dispatch(getTotalUnencumberedPropertyListData(jobId));
  };

  useEffect(() => {
    setTotalUcProperty(totalUnencumberedPropertyListDataResponse?.data);
  }, [totalUnencumberedPropertyListDataResponse?.data]);

  useEffect(() => {
    dispatch(getTotalUnencumberedPropertyListData(jobId));
  }, []);

  useEffect(() => {
    if (deletePropertyDataResponse?.message) {
      setShowDelete(false);
      dispatch(getTotalUnencumberedPropertyListData(jobId));
      dispatch(setDeletePropertyDataResponse({}));
    }
  }, [deletePropertyDataResponse?.message]);

  useEffect(() => {
    dispatch(getTotalUnencumberedPropertyListData(jobId));
  }, [totalUpdateUnencumberedPropertyResponse]);

  useEffect(() => {
    dispatch(getMembers(jobId));
  }, []);

  useEffect(() => {
    let optArray = [];
    membersData?.data?.data.map((items) => {
      return optArray.push({
        value: items.id,
        label: displayNameFromType(items)
      });
    });
    setOptions(optArray);
  }, [membersData]);

  const initialValue = {
    report_comments: totalUnencumberedPropertyListDataResponse?.report_comments
      ? totalUnencumberedPropertyListDataResponse?.report_comments
      : '',
    internal_notes: totalUnencumberedPropertyListDataResponse?.internal_notes
      ? totalUnencumberedPropertyListDataResponse?.internal_notes
      : '',
    is_completed: 0
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard/'}>Dashboard</Link>
              </li>
              <li>
                <a href="#">Group Analysis</a>
              </li>
              {/* <li>
                <a href="#">Group Facilities & Security</a>
              </li> */}
              <li>
                <strong>Unencumbered Property</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-between align-items-center">
            <h1>
              <strong>Unencumbered Property</strong>
            </h1>
            <div style={{ marginLeft: 'auto' }}>
              <Info />
            </div>
            {/* <Link to={'/job/' + jobId + '/data-entry'}> */}
            <button
              type="button"
              className="green topbtn"
              onClick={() => {
                setshowMemberForm(true);
                setType('add-property');
              }}
            >
              + Property
            </button>
            {/* </Link> */}
          </div>
        </div>
        <div className="facility-form form-data">
          <div className="gray-card" style={{ marginTop: '0' }}>
            <div className="rentalview">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-start" width="35%">
                      Member Name
                    </th>
                    <th className="text-start" width="55%">
                      Address
                    </th>
                    <th className="text-center" width="15%">
                      Market Value
                    </th>
                    <th width="120"></th>
                  </tr>
                </thead>
                <tbody>
                  {totalUcProperty && totalUcProperty?.length > 0 ? (
                    totalUcProperty?.map((item) => {
                      market_value_total += item?.additional_info?.market_value;
                      return (
                        <>
                          <tr key={item?.id}>
                            <td className="text-start">
                              {displayOwnerNameFromType(item?.uc_owners)}
                            </td>
                            <td className="text-start">{item?.address}</td>

                            <td>{formatNumber(item?.additional_info?.market_value)}</td>
                            <td className="actionarea" width="10%">
                              <i
                                className={
                                  item?.is_completed === 1
                                    ? 'fa fa-check-circle'
                                    : 'fa fa-exclamation-triangle'
                                }
                                aria-hidden="true"
                                style={{
                                  color: `${item?.is_completed === 1 ? '#42ba96' : '#eed202'}`
                                }}
                              ></i>
                              <a
                                onClick={() => {
                                  EditProperty(item);
                                }}
                                className="edit"
                              >
                                <i className="icon-edit"></i>
                              </a>
                              {/* <Link
                                className="edit"
                                to={
                                  '/job/' + jobId + '/data-entry/' + item?.uc_owners &&
                                  item?.uc_owners?.length > 0 &&
                                  item?.uc_owners[0]?.id + '/edit-property/' + item?.id
                                }
                              >
                                <i className="icon-edit"></i>
                              </Link> */}
                              <a
                                onClick={() => {
                                  setMemberId(
                                    item?.uc_owners &&
                                      item?.uc_owners?.length > 0 &&
                                      item?.uc_owners[0]?.id
                                  );
                                  setPropertyId(item?.id);
                                  setShowDelete(true);
                                }}
                                className="delete"
                              >
                                <i className="icon-delete"></i>
                              </a>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7">No Records Found</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td></td>
                    <td className="text-center">{formatNumber(market_value_total)}</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue
          }) => {
            return (
              <>
                <DialogLeavingPage
                  showDialog={showDialogLeavingPage}
                  confirmNavigation={confirmNavigation}
                  cancelNavigation={cancelNavigation}
                />
                <Form onSubmit={handleSubmit} className="facility-form form-data">
                  <CommentsNotes
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange}
                    handleBlur={handleBlur}
                    isRefresh={true}
                    setRecordtype={setRecordtype}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                  />
                  <div className="action-box full">
                    <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
      <Modal
        show={showDelete}
        onHide={handleDelete}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Delete Property?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this property permanently?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button className="btn btn-secondary" onClick={handleDelete}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              delProperty();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
      <MemberModel
        showMemberForm={showMemberForm}
        options={options}
        handleMemberForm={handleMemberForm}
        type={type}
      />
    </div>
  );
};

export default UnencumberedProperties;
