import React from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field, FieldArray } from 'formik';
// import { addConfigSchema } from '../pages/common/Validation';

export default function TaxRateModal(props) {
  const { show, close, itemData, setItemTax, TaxId, updateTaxRate } = props;

  const changeDefault = (mainIndex, setFieldValue) => {
    let temp = JSON.parse(JSON.stringify(itemData));
    temp.options.map((item, index) => {
      if (mainIndex == index) {
        setFieldValue(`options.${index}.is_default`, '1');
      } else {
        setFieldValue(`options.${index}.is_default`, '0');
      }
    });
    setItemTax(temp);
  };

  const handleFormSubmit = (values, { setErrors }) => {
    let temp = {
      comment: values.comment,
      options: JSON.stringify(values.options)
    };
    if (itemData?.type == 'add') {
      //   dispatch(addConfig(temp, setErrors)).then(() => {
      //     dispatch(adminGetConfig([]));
      //   });
    } else {
      temp.status = 1;
      updateTaxRate(TaxId, temp, setErrors);
    }

    close();
  };
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <h2>Edit Tax</h2>
        </div>
        <Formik
          initialValues={itemData}
          onSubmit={handleFormSubmit}
          //   validationSchema={addConfigSchema}
          validateOnBlur={false}
          validateOnChange={false}
          render={({ values, setFieldValue }) => (
            <Form>
              <Modal.Body>
                <div>
                  <Field
                    name="maintitle"
                    placeholder="Main Title"
                    style={{ padding: '0.375rem 0.75rem' }}
                    disabled={true}
                  />
                  {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                    {errors?.maintitle}
                  </span> */}
                </div>
                <FieldArray
                  name="options"
                  render={(arrayHelpers) => {
                    return (
                      <div className="shareholder">
                        {values.options && values.options.length > 0 ? (
                          values.options.map((item, index) => (
                            <div key={index} className="row mt-3">
                              <div className="col-md-10">
                                <Field
                                  name={`options.${index}.value`}
                                  placeholder="Title"
                                  style={{ padding: '0.375rem 0.75rem' }}
                                />
                                <Field
                                  type="radio"
                                  name={`options.${index}.is_default`}
                                  //   value={String(index)}
                                  checked={item.is_default == '1' ? true : false}
                                  onChange={() => changeDefault(index, setFieldValue)}
                                />
                                {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`title.${index}`} />
                                </span> */}
                              </div>

                              <div className="col-md-2 mt-1" style={{ padding: 0 }}>
                                <button
                                  type="button"
                                  style={{
                                    margin: '1px',
                                    height: '35px',
                                    width: '35px',
                                    padding: 0,
                                    lineHeight: '33px',
                                    borderRadius: '50%'
                                  }}
                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                  -
                                </button>
                                <button
                                  type="button"
                                  style={{
                                    margin: '1px',
                                    height: '35px',
                                    width: '35px',
                                    padding: 0,
                                    lineHeight: '33px',
                                    borderRadius: '50%'
                                  }}
                                  onClick={() =>
                                    arrayHelpers.insert(index + 1, { value: '', is_default: 0 })
                                  } // insert an empty string at a position
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <button
                            type="button btn-secondary"
                            onClick={() => arrayHelpers.push({ value: '', is_default: 0 })}
                          >
                            {/* show this when user has removed all friends from the list */}
                            Add a config
                          </button>
                        )}
                        {/*
                        <div className="col-md-12">
                          <textarea
                            name="comment"
                            className="form-control mt-2"
                            placeholder="Comment"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={values?.comment}
                          ></textarea> */}
                        {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {errors?.comment}
                          </span> */}
                        {/* </div> */}
                      </div>
                    );
                  }}
                />
              </Modal.Body>
              <div className="modal-action modal-footer">
                <button type="button" className="btn btn-secondary" onClick={close}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Update
                </button>
              </div>
            </Form>
          )}
        />
      </Modal>
    </>
  );
}
