import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getMembers, jobSetupState } from '../../../slices/slices/JobSetupSlice';
import Header from '../Header';
import Company from './corporateStructure/Company';
import Individual from './corporateStructure/Individual';
import Other from './corporateStructure/Other';
import SoleTrader from './corporateStructure/SoleTrader';
import Partnership from './corporateStructure/Partnership';
import Trust from './corporateStructure/Trust';
import { Form } from 'react-bootstrap';
import CommentsNotes from '../../../components/fields/CommentsNotes';
import { Formik } from 'formik';
import FormActions from '../../../components/FormActions';
import {
  getCorporateStructureData,
  corporateStructureState,
  updateCorporateStructure
} from '../../../slices/slices/CorporateStuctureSlice';
import { Plock } from 'react-plock';
import { useNavigatingAway } from '../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../common/components/DialogLeavingPage';

const Corporate = () => {
  const { corporateStructureDataResponse, updateCorporateStructureResponse } =
    useSelector(corporateStructureState);
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { membersData } = useSelector(jobSetupState);

  const [record_type, setRecordtype] = useState();

  const [initialValue, setInternalValues] = useState();

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    dispatch(getMembers(jobId));
    dispatch(getCorporateStructureData(jobId));
  }, []);

  useEffect(() => {
    setInternalValues({
      report_comments: corporateStructureDataResponse?.data?.report_comments || '',
      internal_notes: corporateStructureDataResponse?.data?.internal_notes || '',
      is_completed: 1
    });
  }, [corporateStructureDataResponse]);

  useEffect(() => {
    dispatch(getMembers(jobId));
    dispatch(getCorporateStructureData(jobId));
  }, [updateCorporateStructureResponse]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    if (record_type) {
      values.is_refresh = 1;
      values.record_type = record_type;
    }
    dispatch(updateCorporateStructure(jobId, values, setErrors));
    setRecordtype('');
  };

  const breakpoints = [
    { size: 640, columns: 1 },
    { size: 768, columns: 2 },
    { size: 1024, columns: 3 },
    { size: 1280, columns: 3 }
  ];

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="#">Group Assessment</a>
              </li>
              <li>
                <a href="#">Bankability Assessment</a>
              </li>
              <li className="breadcrumb-item active">
                <strong>Group Structure</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-between align-items-center">
            <h1>Group Structure</h1>
            <div className="addmemberarea corporate-btn">
              <Link
                className="btn btn-primary green"
                to={'/job/' + jobId + '/group-setup/members/add'}
              >
                <i className="icon-plus"></i>Member
              </Link>
              {/*<Link className="btn btn-primary ms-2" to="/edit-member">
                <i className="icon-plus"></i>Edit Member
              </Link> */}
            </div>
          </div>
        </div>
        <div className="white-card">
          <div className="corporate-structure">
            <Plock nColumns={breakpoints}>
              {membersData?.data?.data.map((row, index) => {
                return (
                  <div key={index} className="item">
                    {row.type === 'company' && <Company data={row} />}
                    {row.type === 'individual' && <Individual data={row} />}
                    {row.type === 'other' && <Other data={row} />}
                    {row.type === 'partnership' && <Partnership data={row} />}
                    {row.type === 'sole-trader' && <SoleTrader data={row} />}
                    {row.type === 'trust' && <Trust data={row} />}
                  </div>
                );
              })}
            </Plock>
          </div>
        </div>
        <div>
          <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              setFieldValue
            }) => {
              return (
                <Form onSubmit={handleSubmit} className="facility-form form-data">
                  <CommentsNotes
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange}
                    handleBlur={handleBlur}
                    isRefresh={true}
                    setRecordtype={setRecordtype}
                    resetForm={resetForm}
                    setFieldValue={setFieldValue}
                  />
                  <div className="action-box full">
                    <FormActions cancel={'/job/' + jobId + '/dashboard'} values={values} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Corporate;
