import { useState } from 'react';
import clientService from '../services/service';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const GroupInput = (props) => {
  const dispatch = useDispatch();
  const [oldValue, setOldValue] = useState('');
  const [inputValues, setInputValues] = useState(props.value ? props.value : '');
  const [options, setOptions] = useState([]);
  const handleOnChange = async (e) => {
    props.setGroupId('');
    setInputValues(e.target.value);
    if (e.target.value) {
      var res = await clientService.get(
        `groups?search=${e.target.value}`,
        () => {},
        dispatch,
        false
      );
      setOptions(res.data.data);
      props.setGroupName(e.target.value);
    } else {
      setOptions([]);
    }
  };

  return (
    <>
      <input
        {...props}
        onChange={handleOnChange}
        value={inputValues}
        onFocus={(e) => {
          setOldValue(e.target.value);
        }}
        onBlur={(e) => {
          if (oldValue != e.target.value) {
            props.setGroupName(e.target.value);

            // props.setGroupName(e.target.value);
          }
        }}
      />
      {options.map((item) => {
        return (
          <li
            key={item.id}
            onClick={() => {
              setInputValues(item.name);
              setOptions([]);
              props.setGroupId(item.id);
              props.setGroupName(item.name);
            }}
          >
            {item.name}
          </li>
        );
      })}
    </>
  );
};

GroupInput.propTypes = {
  value: PropTypes.string,
  setGroupName: PropTypes.func.isRequired,
  setGroupId: PropTypes.func.isRequired
};

export default GroupInput;
