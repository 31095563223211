import React from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { addOverallAssessmentSchema } from '../../pages/common/Validation';
import Select from 'react-select';

export default function EditOverallAssessmentModal(props) {
  const { show, close, onClickSubmit, optionArray, name } = props;

  const handleFormSubmit = (values) => {
    values?.option && values?.option?.length > 0
      ? values?.option?.map((item, i) => {
          values.option[i].value = item?.value?.value;
          values.option[i].title = item?.title;
        })
      : null;

    onClickSubmit(values);
  };

  const DefaultOptionArray = [
    { label: 'Green', value: 1 },
    { label: 'Amber', value: 2 },
    { label: 'Red', value: 3 }
  ];

  const getOptions = () => {
    let temp;
    if (optionArray?.length > 0) {
      temp = optionArray?.map((val) => ({
        title: val?.title,
        value: DefaultOptionArray?.find((item) => parseInt(item?.value) === parseInt(val?.value))
      }));
    } else {
      temp = [
        {
          title: '',
          value: [{ title: '', value: { label: 'Green', value: 1 } }]
        }
      ];
    }
    return temp;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={close}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <h2>Edit Categories</h2>
        </div>
        <Formik
          initialValues={{
            name: name,
            option: getOptions()
          }}
          onSubmit={handleFormSubmit}
          validationSchema={addOverallAssessmentSchema}
          validateOnBlur={false}
          // validateOnChange={false}
        >
          {({ values, setFieldValue, handleBlur }) => (
            <Form>
              <Modal.Body>
                <div>
                  <Field
                    name="name"
                    placeholder="Category Name"
                    style={{ padding: '0.375rem 0.75rem' }}
                    disabled={false}
                  />
                  <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                    <ErrorMessage name={`name`} />
                  </span>
                </div>
                <FieldArray
                  name="option"
                  render={(arrayHelpers) => {
                    return (
                      <div className="shareholder">
                        {values?.option && values?.option?.length > 0 ? (
                          values?.option?.map((item, index) => (
                            <div key={index} className="row mt-3">
                              <div className="col-md-4">
                                {/* <div
                                  style={{
                                    margin: '1px',
                                    height: '35px',
                                    width: '35px',
                                    padding: 0,
                                    lineHeight: '33px',
                                    borderRadius: '50%',
                                    backgroundColor:
                                      index === 0 ? '#2bb673' : index === 1 ? '#ffc107' : '#dc3545'
                                  }}
                                ></div> */}
                                <Select
                                  className="form-control"
                                  name={`option.${index}.value`}
                                  value={values?.option[index]?.value}
                                  onBlur={handleBlur}
                                  onChange={(val) => {
                                    setFieldValue(`option.${index}.value`, val);
                                  }}
                                  options={DefaultOptionArray}
                                  classNamePrefix="categories"
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`option.${index}.value`} />
                                </span>
                              </div>
                              <div className="col-md-6">
                                <Field
                                  name={`option.${index}.title`}
                                  placeholder="Title"
                                  style={{ padding: '0.375rem 0.75rem' }}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`option.${index}.title`} />
                                </span>
                              </div>

                              <div className="col-md-2 mt-1" style={{ padding: 0 }}>
                                {index > 0 && (
                                  <button
                                    type="button"
                                    style={{
                                      margin: '1px',
                                      height: '35px',
                                      width: '35px',
                                      padding: 0,
                                      lineHeight: '33px',
                                      borderRadius: '50%'
                                    }}
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                  >
                                    -
                                  </button>
                                )}

                                <button
                                  type="button"
                                  style={{
                                    margin: '1px',
                                    height: '35px',
                                    width: '35px',
                                    padding: 0,
                                    lineHeight: '33px',
                                    borderRadius: '50%'
                                  }}
                                  onClick={() =>
                                    arrayHelpers.insert(index + 1, {
                                      title: '',
                                      value: {
                                        label:
                                          index === 0 ? 'Amber' : index === 1 ? 'Red' : 'Green',
                                        value: index === 0 ? 2 : index === 1 ? 3 : 1
                                      }
                                    })
                                  } // insert an empty string at a position
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <button
                            type="button btn-secondary"
                            onClick={() =>
                              arrayHelpers.push({ title: '', value: { label: 'Green', value: 1 } })
                            }
                          >
                            Add a option
                          </button>
                        )}
                      </div>
                    );
                  }}
                />
              </Modal.Body>
              <div className="modal-action modal-footer">
                <button type="button" className="btn btn-secondary" onClick={close}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
