import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import Label from '../components/Label';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

export default function MemberModel(props) {
  const { showMemberForm, handleMemberForm, options, type } = props;
  const { jobId } = useParams();
  const navigate = useNavigate();
  //const [memberList, setmemberList] = useState([]);
  const [error, setError] = useState([]);
  const [memberId, setMemberId] = useState();

  const handleonChange = (value) => {
    setMemberId(value);
  };

  const handleMemberFormSubmit = () => {
    if (memberId?.value) {
      setError('');

      // For UC property
      if (type === 'add-property')
        navigate('/job/' + jobId + '/data-entry/' + memberId.value + '/' + type);
      // For Facility & Security
      else navigate('/job/' + jobId + '/data-entry/' + memberId.value + '/' + type + '/add');
    } else {
      setError('Please select member first');
    }
  };

  return (
    <Modal
      show={showMemberForm}
      onHide={handleMemberForm}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="create-job">
        <h3>Select Member</h3>
        <div className="createjob_v2">
          <fieldset>
            <div className="field-box">
              <Label>Member</Label>
              <Select
                id="color"
                options={options}
                value={memberId}
                multi={false}
                // onInputChange={handleonkeyDown}
                onChange={handleonChange}
                placeholder={'Search member'}
              />
              <div className="title_pro">
                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                  {error}
                </span>
              </div>
            </div>
          </fieldset>
          <div className="modal-action">
            <Button
              type="button"
              className="btn btn-secondary"
              id="createbtnjob"
              onClick={handleMemberForm}
            >
              Cancel
            </Button>
            <Button type="submit" className="btn btn-primary" onClick={handleMemberFormSubmit}>
              Ok
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
