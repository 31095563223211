import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import NumberFormat from 'react-number-format';
import { ReportPath } from '../job/groupReports/common/ReportPath';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPracticeSetting,
  getPracticeSettingData,
  practiceSettingState
} from '../../slices/slices/PracticeSettingSlice';
import Autocomplete from 'react-google-autocomplete';
import { GetCookies } from '../../services/cookies';
import Label from '../../components/Label';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { PracticeSettingBasicSchema } from '../common/Validation';
import { useNavigatingAway } from '../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../common/components/DialogLeavingPage';
import { formatAddress } from '../../utils/helper';

const PractiseSetting = () => {
  const dispatch = useDispatch();
  const { getPracticeSettingDataResponse } = useSelector(practiceSettingState);
  const defaultValue = getPracticeSettingDataResponse?.data;
  const json_str = GetCookies('_jobData');
  const jobItem = json_str ? JSON.parse(JSON.stringify(json_str)) : [];
  const [selectedFile, setSelectedFile] = useState('');
  const imageInputRef = React.useRef();
  const navigate = useNavigate();
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    dispatch(getPracticeSettingData(jobItem[0]?.id));
  }, []);

  const initialValues = {
    name: defaultValue?.name || '',
    email: defaultValue?.email || '',
    phone: defaultValue?.phone || '',
    logo: defaultValue?.logo || '',
    address: defaultValue?.address || '',
    acn: defaultValue?.acn || '',
    _method: 'put',
    first_adult: defaultValue?.living_expenses?.first_adult || '',
    subsequent_adult: defaultValue?.living_expenses?.subsequent_adult || '',
    first_dependant: defaultValue?.living_expenses?.first_dependant || '',
    subsequent_dependant: defaultValue?.living_expenses?.subsequent_dependant || ''
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('phone', values.phone);

    // if (typeof values.logo === 'string') {
    formData.append('logo', values.logo);
    // } else {
    //   formData.append('logo_image', values.logo);
    // }
    formData.append('address', values.address);
    formData.append('acn', values.acn.replace(/\D+/g, ''));
    formData.append('_method', 'put');
    formData.append('first_adult', parseInt(`${values?.first_adult}`.replace(/,/g, '')));
    formData.append('subsequent_adult', parseInt(`${values?.subsequent_adult}`.replace(/,/g, '')));
    formData.append('first_dependant', parseInt(`${values?.first_dependant}`.replace(/,/g, '')));
    formData.append(
      'subsequent_dependant',
      parseInt(`${values?.subsequent_dependant}`.replace(/,/g, ''))
    );

    dispatch(addPracticeSetting(formData, setErrors));
  };

  const onClickCancel = () => {
    navigate('/');
  };

  const handleRemoveIcon = (setFieldValue) => {
    setSelectedFile('');
    setFieldValue('logo', '');
    imageInputRef.current.value = '';
  };

  useEffect(() => {
    if (getPracticeSettingDataResponse?.data?.logo)
      setSelectedFile(ReportPath + getPracticeSettingDataResponse?.data?.logo);
  }, [getPracticeSettingDataResponse]);

  return (
    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <Formik
        initialValues={initialValues}
        validationSchema={PracticeSettingBasicSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <form
                encType="multipart/form-data"
                onSubmit={handleSubmit}
                className="row needs-validation"
                noValidate
              >
                <div className="innertab">
                  <div className="row average">
                    <div className="col-md-4">
                      <table className="table">
                        <thead>
                          <tr>
                            <th colSpan="2">Average Living Expense Rates $ p.a.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>First Adult</td>
                            <td>
                              <NumberFormat
                                decimalScale={0}
                                allowNegative={false}
                                name="first_adult"
                                value={values?.first_adult}
                                className="form-control"
                                thousandSeparator={true}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                                required
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.first_adult}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Subsequent Adult</td>
                            <td>
                              <NumberFormat
                                decimalScale={0}
                                allowNegative={false}
                                name="subsequent_adult"
                                value={values.subsequent_adult}
                                className="form-control"
                                thousandSeparator={true}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.subsequent_adult}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>First Dependant</td>
                            <td>
                              <NumberFormat
                                decimalScale={0}
                                allowNegative={false}
                                name="first_dependant"
                                value={values.first_dependant}
                                className="form-control"
                                thousandSeparator={true}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors?.first_dependant}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Subsequent Dependant</td>
                            <td>
                              <NumberFormat
                                decimalScale={0}
                                allowNegative={false}
                                name="subsequent_dependant"
                                value={values.subsequent_dependant}
                                className="form-control"
                                thousandSeparator={true}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.subsequent_dependant}
                              </span>
                            </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8 expenses_clac">
                      <div className="group_areaof row">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <Label title="Name">Name</Label>
                              <Input
                                type="text"
                                name="name"
                                placeholder=""
                                className="form-control"
                                id="name"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.name}
                                required
                              />
                              <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                                {errors.name}
                              </span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <Label>Email</Label>
                              <Input
                                type="email"
                                name="email"
                                placeholder=""
                                className="form-control"
                                id="emailaddress"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.email}
                                required
                              />
                              <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                                {errors.email}
                              </span>
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="form-group">
                              <Label>Phone</Label>
                              <Input
                                type="text"
                                name="phone"
                                placeholder=""
                                maxLength="15"
                                className="form-control"
                                id="phone"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                                {errors.phone}
                              </span>
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="form-group">
                              <Label>Address</Label>
                              <Autocomplete
                                autoComplete="off"
                                apiKey={process.env.REACT_APP_API_KEY}
                                name="address"
                                placeholder=""
                                className="form-control"
                                Value={values.address}
                                onChange={(val) => {
                                  setFieldValue('address', val?.target?.value);
                                }}
                                onPlaceSelected={(place) => {
                                  let formattedAddress = formatAddress(place);
                                  setFieldValue('address', formattedAddress);
                                  handleInputChange(formattedAddress);
                                }}
                                options={{
                                  types: 'street_address',
                                  componentRestrictions: { country: ['au', 'nz'] }
                                }}
                              />

                              {/* <Input
                                    type="text"
                                    name="address"
                                    placeholder=""
                                    className="form-control"
                                    id="address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address}
                                  /> */}
                              {/* <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                          {errors.address}
                        </span> */}
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="form-group">
                              <Label>Upload Logo</Label>
                              <input
                                accept="image/*"
                                type="file"
                                name="logo"
                                className="form-control"
                                id="file"
                                ref={imageInputRef}
                                onChange={(e) => {
                                  handleInputChange(e.target.value);
                                  setFieldValue('logo', e.currentTarget.files[0]);
                                  setSelectedFile(URL.createObjectURL(e.currentTarget.files[0]));
                                }}
                                onBlur={handleBlur}
                                required
                              />
                              <span
                                style={{
                                  fontSize: '14px'
                                }}
                              >
                                * Only accept jpeg, png, jpg and Ideal size of logo is 650 W x 300
                                H.
                              </span>
                              {selectedFile && (
                                <div className="previewImage d-flex">
                                  <img
                                    className="display-image"
                                    src={selectedFile}
                                    alt="selectedFile"
                                  />

                                  <span
                                    title="Remove logo"
                                    className="remove-image"
                                    onClick={() => handleRemoveIcon(setFieldValue)}
                                  >
                                    <i className="icon-delete" aria-hidden="true" />
                                  </span>
                                </div>
                              )}
                              <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                                {errors.logo}
                              </span>
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="form-group">
                              <Label>ABN</Label>
                              <InputMask
                                className="form-control"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                                name="acn"
                                value={values.acn}
                                mask="99 999 999 999"
                                maskChar={null}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                                {errors.acn}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn_area text-end">
                  <div className="container">
                    <Button
                      className="btn btn-outline-secondary"
                      type="reset"
                      onClick={onClickCancel}
                    >
                      Cancel
                    </Button>
                    <Button className="btn btn-success" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default PractiseSetting;
