import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from './Button';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { addOverAllTitle } from '../pages/common/Validation';
import { v4 as uuidv4 } from 'uuid';

export default function PieSegmentModel(props) {
  const { show, close, itemData, setItemData, mainIndex, handleInputChange } = props;
  // const [formData, setformData] = useState({
  //   title: '',
  //   option: [
  //     { value: 1, title: '' },
  //     { value: 2, title: '' },
  //     { value: 3, title: '' }
  //   ]
  // });

  let options = [
    {
      label: 'Green',
      title: 'Green',
      value: '1',
      key: '1'
    },
    {
      label: 'Amber',
      title: 'Amber',
      value: '2',
      key: '2'
    },
    {
      label: 'Red',
      title: 'Red',
      value: '3',
      key: '3'
    }
  ];

  const handleFormSubmit = (value) => {
    handleInputChange(value);
    let obj = {};
    obj.id = uuidv4();
    obj.value = value.color.key;
    obj.options = options;
    obj.name = value.name;
    obj.is_external = 1;
    let temp = itemData;

    temp[mainIndex].subcategory.push(obj);
    setItemData(JSON.parse(JSON.stringify(temp)));
    // subItem[seletcedindex] = value;
    // subItem[seletcedindex] = value;
    close();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={close}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="create-job">
          <Formik
            initialValues={{
              color: ''
            }}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleFormSubmit}
            validationSchema={addOverAllTitle}
          >
            {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    <h3>Add Title</h3>
                    <input
                      name="name"
                      className="mt-2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Title"
                      type="text"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.name}
                    </span>
                    <div className="row color-select" style={{ margin: '30px 0' }}>
                      {/* <div className="col-md-12">
                        <label>Select Value : </label>
                      </div> */}
                      <div className="col-md-12" style={{ padding: 0 }}>
                        <Select
                          className="form-control"
                          name="color"
                          aria-label="Default select example"
                          placeholder="Select color"
                          // value={{ value: 1, title: 'test', label: 'test label' }}
                          value={values.color}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue('color', val);
                          }}
                          options={options}
                        ></Select>
                        <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                          {errors.color?.key}
                        </span>
                      </div>
                    </div>
                    {/* <div className="row mt-2">
                      <div className="col-md-3 ">
                        <div className="square-red"></div>
                      </div>
                      <div className="col-md-6">
                        <Select
                          className="form-control"
                          name="option.0.title"
                          aria-label="Default select example"
                          // value={{ value: 1, title: 'test', label: 'test label' }}
                          value={values.option[0]}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue('option.0', val);
                          }}
                          options={options}
                        ></Select>
                        <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                          {errors.option?.[0]?.value}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-3 ">
                        <div className="square-yellow"></div>
                      </div>
                      <div className="col-md-6">
                        <Select
                          className="form-control"
                          name="option.1.title"
                          value={values.option[1]}
                          aria-label="Default select example"
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue('option.1', val);
                          }}
                          options={options}
                        ></Select>
                        <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                          {errors.option?.[1]?.value}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-3 ">
                        <div className="square-green"></div>
                      </div>
                      <div className="col-md-6">
                        <Select
                          className="form-control"
                          name="option.2.title"
                          value={values.option[2]}
                          aria-label="Default select example"
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue(`option.2`, val);
                          }}
                          options={options}
                        ></Select>
                        <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                          {errors.option?.[2]?.value}
                        </span>
                      </div>
                    </div> */}
                    <div className="createjob_v2">
                      <div className="modal-action">
                        <Button
                          type="button"
                          className="btn btn-secondary"
                          id="createbtnjob"
                          onClick={close}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          // onClick={() => onClickSubmit(formData)}
                        >
                          Ok
                        </Button>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
