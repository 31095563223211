import { Formik } from 'formik';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  authState,
  sendEmailOTP,
  setSendEmailResponse,
  setVerifyNumberResponse,
  verifyEmailOTP
} from '../../../slices/slices/AuthSlice';
import {
  // getGeneralSettingData,
  remove2fa,
  setRemove2faResponse,
  setSettingVerifyEmailResponse,
  settingState,
  settingVerifyEmailOTP
} from '../../../slices/slices/SettingSlice';
import { AuthenticationSchema } from '../../common/Validation';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

const ConfirmBackupEmail = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const search = window.location.search;
  const tokenString = new URLSearchParams(search);
  const { settingEmailVerifyResponse, remove2faResponse } = useSelector(settingState);
  const { emailVerifyResponse, emailOTPResponse } = useSelector(authState);

  const handleFormSubmit = (values, { setErrors }) => {
    if (tokenString.get('remove') === 'true') {
      dispatch(verifyEmailOTP(values, setErrors));
    } else {
      dispatch(settingVerifyEmailOTP(values, setErrors));
    }
  };

  useEffect(() => {
    if (tokenString.get('remove') === 'true') {
      dispatch(sendEmailOTP());
    }
  }, []);

  useEffect(() => {
    if (emailOTPResponse.message) {
      dispatch(setSendEmailResponse({}));
    }
  }, [emailOTPResponse.message]);

  useEffect(() => {
    if (emailVerifyResponse.message) {
      if (tokenString.get('remove') === 'true') {
        dispatch(remove2fa());
      }
    }
    dispatch(setVerifyNumberResponse({}));
  }, [emailVerifyResponse.message]);

  useEffect(() => {
    if (remove2faResponse.message) {
      if (remove2faResponse) {
        navigate('/setting');
      }
      dispatch(setRemove2faResponse({}));
    }
  }, [remove2faResponse.message]);

  useEffect(() => {
    if (settingEmailVerifyResponse.message) {
      if (settingEmailVerifyResponse) {
        navigate('/setting');
        dispatch(setSettingVerifyEmailResponse({}));
      }
    }
  }, [settingEmailVerifyResponse.message]);

  return (
    <div className="setting toggle-sidebar">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <p onClick={() => navigate(-1)} className="backlink">
            Back
          </p>
          <div className="container two-factor-screen">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="text-center">
                  <h4>Check your backup email and enter the code</h4>
                  <p className="text-center">Enter the code to confirm your email address.</p>
                </div>
                <Formik
                  initialValues={{
                    token: ''
                  }}
                  validationSchema={AuthenticationSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="row needs-validation" noValidate>
                      <div className="col-12">
                        <div className="input-group has-validation">
                          <Input
                            type="text"
                            name="token"
                            placeholder="Confirmation Code"
                            className="form-control"
                            id="yourUsername"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.token}
                            required
                          />
                          <div className="invalid-feedback">Please enter your username.</div>
                        </div>
                        <span className="error" style={{ color: 'red', fontSize: '16px' }}>
                          {errors.token}
                        </span>
                      </div>
                      <div className="col-12 topgap btnarea justify-content-center">
                        <Button className="btn btn-primary w-100" type="submit">
                          Confirm email
                        </Button>
                      </div>
                      {tokenString.get('remove') === 'true' ? null : (
                        <div className="col-12 justify-content-center middletext">
                          <p className="topspace">
                            <Link to="/add-backup-email">Entered the wrong email address ?</Link>
                          </p>
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default ConfirmBackupEmail;
