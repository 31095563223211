import * as Yup from 'yup';
import moment from 'moment';

const PASSWORD_VALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_])(?=.{8,})/;

const required = (field) => Yup.string().required('The field ' + field + ' is required.');

const addMember = ['company', 'trust', 'partnership', 'sole-trader', 'individual', 'other'];

const email = required('Email address').email(
  'Please enter a valid email address. For eg. example@example.com'
);

// const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
// const TAX = /(^(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;

export const LoginSchema = Yup.object().shape({
  email: email,
  password: required('Password')
});

export const AuthenticationEmailSchema = Yup.object().shape({
  token: required('Authentication Code')
});

export const AuthenticationSchema = Yup.object().shape({
  token: required('Authentication Code')
});

export const BackUpSchema = Yup.object().shape({
  backup_email: required('Back up email')
});

export const CopyJobSchema = Yup.object().shape({
  name: required('Job name'),
  due_date: Yup.date()
    .min(moment().subtract(1, 'days').toDate(), 'Date must be a future date')
    .typeError('Please required valid Job Due Date')
    .required('The Job Due Date is required.'),
  group_name: required('Group name')
  // group_name: Yup.string().when('group_id', {
  //   is: (value) => !value,
  //   then: required('Group name')
  // })
});

export const CreateImportJob = Yup.object().shape({
  group_name: required('Group name')
});

export const AddJobSchema = Yup.object().shape({
  group_name: Yup.string().when('group_id', {
    is: (value) => !value,
    then: required('Group name')
  }),
  name: required('Job name'),
  due_date: Yup.date()
    .min(moment().subtract(1, 'days').toDate(), 'Date must be a future date')
    .typeError('Please required valid Job Due Date')
    .required('The Job Due Date is required.')
});

export const AddXpmJobSchema = Yup.object().shape({
  from_date: Yup.string().required('The Job From Date is required.'),
  to_date: Yup.string().required('The Job To Date is required.')
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: email
});

export const ResetPasswordSchema = Yup.object().shape({
  password: required('Password').matches(
    PASSWORD_VALIDATOR,
    'Must contain minimum 8 characters with One Uppercase, One Lowercase, One Number and One Special Case Character (!@#$%^&*-_)'
  ),
  password_confirmation: Yup.string()
    .required('Confirm Password is required.')
    .when('password', {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both password and confirm Password need to be the same.'
      )
    })
});

export const AccountManagementSchema = Yup.object().shape({
  // tax: Yup.number()
  //   .typeError('String value is not required.')
  //   .min(0, 'Negative value is not required.')
  //   .max(100, 'Tax must be less than or equal to 100.')
  //   .required('Tax is required.'),
  creditcardrate: Yup.number()
    .typeError('Please enter valid rate of credit card.')
    .min(0, 'Negative value is not required.')
    .max(100, 'Rate Of Credit Card must be less than or equal to 100.')
    .required('Rate Of Credit Card is required.')
  // shading: Yup.number()
  //   .typeError('String value is not required.')
  //   .min(0, 'Negative value is not required.')
  //   .max(100, 'Shading must be less than or equal to 100.')
  //   .required('Shading is required.')
  // rate_of_credit_card: Yup.string()
  //   .required('Rate Of Credit Card is required.')
  //   .matches(TAX, 'Invalid Rate Of Credit Card Value.'),
  // shading: Yup.string().required('Shading % is required.').matches(TAX, 'Invalid Shading Value.'),
  // logo: Yup.mixed().required('Upload Logo is required.')
});

export const SettingBasicSchema = Yup.object().shape({
  name: required('First Name'),
  surname: required('Last Name'),
  email: email
  // tfa_email: email
});

export const XpmIntFieldsSchema = Yup.object().shape({
  xero_api_key: Yup.string().required('Xero API Key is required.').nullable(),
  xero_api_secret: Yup.string().required('Xero API Secret is required.').nullable()
});

export const PasswordManagementSchema = Yup.object().shape({
  current_password: required('Old Password'),
  password: required('New Password')
    .when('current_password', {
      is: (val) => !!val,
      then: Yup.string().notOneOf(
        [Yup.ref('current_password')],
        'Old and New Password should not be the same.'
      )
    })
    .matches(
      PASSWORD_VALIDATOR,
      'Must contain minimum 8 characters with One Uppercase, One Lowercase, One Number and One Special Case Character (!@#$%^&*-_)'
    ),
  password_confirmation: required('Confirm Password').when('password', {
    is: (val) => val && val.length > 0,
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Both New Password and Confirm Password need to be the same.'
    )
  })
});

export const JobSettingsSchema = Yup.object().shape({
  group_name: Yup.string().required('Group Name is required.'),
  job_name: Yup.string().required('Job Name is required.'),
  anticipated_settlement_date: Yup.date()
    .required('Anticipated Settlement Date is required.')
    .nullable(),
  due_date: Yup.date().required('Due Date is required.').nullable(),
  financial_year_end: Yup.string().required('Financial Year End is required.').nullable(),
  current_financial_year: Yup.string()
    .max(4, 'maximum 4 digit required.')
    .required('Financial Year is required.')
});

export const DataEntrySchema = Yup.object().shape({
  purpose: Yup.string().required('Purpose is required'),
  faci_status: Yup.string().required('Facility Status is required'),
  faci_product: Yup.string().required('Facility Product is required'),
  repayment: Yup.string().required('Repayment is required'),
  lender: Yup.string().required('Lender is required'),
  prop_limit: Yup.number()
    .required('Proposed Limit is required')
    .typeError('String value is not required.'),
  inter_rate: Yup.number()
    .required('Interest rate is required')
    .typeError('String value is not required.'),
  inter_type: Yup.string().required('Interest rate type is required'),
  int_rate_ident: Yup.string().required('Interest rate identifire is required'),
  repay_term: Yup.number()
    .required('Repayment term in months is required')
    .typeError('String value is not required.'),
  repay_term_ident: Yup.string()
    .required('Repayment term identifire  is required')
    .typeError('String value is not required.'),
  repay_amt: Yup.number()
    .required('Repayment amount  is required')
    .typeError('String value is not required.'),
  repay_freq: Yup.string().required('Repayment frequency  is required'),
  faci_exp_date: Yup.string().required('facility  expiry  is required'),
  term_rem_mon: Yup.number()
    .required('Terms remaining is required')
    .typeError('String value is not required.'),
  amt: Yup.number().required('Amount is required').typeError('String value is not required.'),
  freq: Yup.string().required('Frequency is required')
});

export const AddMemberSchema = Yup.object().shape({
  legal_name: Yup.string().when('type', {
    is: (value) =>
      value === addMember[0] ||
      value === addMember[2] ||
      value === addMember[3] ||
      value === addMember[5],
    then: required('Legal Name'),
    otherwise: Yup.string().nullable()
  }),
  acn: Yup.string().when('type', {
    is: (value) => value !== addMember[2] && value !== addMember[3] && value !== addMember[4],
    then: Yup.string()
      // .typeError('String value is not required.')
      .min(0, 'acn must be min 0')
      .max(9, 'acn must be equal to 9.')
      .matches(/^[0-9]*$/, 'Only numbers are allowed for acn. '),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string()
      .min(0, 'abn must be min 0.')
      .max(11, 'abn must be less than or equal to 11.')
      .matches(/^[0-9]*$/, 'Only numbers are allowed for abn. '),
    // .typeError('String value is not required.')

    otherwise: Yup.string().nullable()
  }),
  title_name: Yup.string().when('type', {
    is: (value) => value === addMember[4] && value !== addMember[0],
    then: required('Title'),
    otherwise: Yup.string().nullable()
  }),
  trust_name: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Trust name'),
    otherwise: Yup.string().nullable()
  })
  // -----------***-------------
  // acn: Yup.string().required('max length 9 is required.'),
  // .test('Must be exactly 9 characters', (val) => val.length === 9),
  // .test('Must be exactly 11 characters', (val) => val.length === 11),
  // trading_name: Yup.string().required('Trading Name is required.'),
  // activity: Yup.string().required('Activity is required.'),
  // website: Yup.string().required('Website is required.'),
  // trustee_name: Yup.string().required('Trustee name is required.'),
  // directors: Yup.string().required('director name is required.')
});

export const averageLivingSchema = Yup.object().shape({
  first_adult: Yup.string().required('First Adult is required.'),
  first_dependant: Yup.string().required('First Dependant is required.'),
  subsequent_adult: Yup.string().required('Subsequent Adult is required.'),
  subsequent_dependant: Yup.string().required('Subsequent Dependant is required.')
});

export const businessOverviewSchema = Yup.object().shape({
  business_name: Yup.string().required('Business Name is required.').nullable()
  // website_address: Yup.string().url('Please Enter Valid Website Url.')
});

// export const houseHoldSchema = Yup.object().shape({
//   household: Yup.array(
//     Yup.object({
//       number_of_adult: required('Number of Adult'),
//       average_living_exp: required('Average of Living Expense'),
//       number_of_dependant: required('Number of Dependant'),
//       adjustments_based_on_lifestyle: required('Adjustments based on lifestyle')
//     })
//   )
// });

export const houseHoldSchema = Yup.object().shape({
  household: Yup.array().of(
    Yup.object().shape({
      number_of_adult: Yup.number()
        .required('Number of Adults is required.')
        .min(1, 'Number of Adults must be greater than 0.'),
      number_of_dependant: Yup.number().required('Number of Dependents is required.')
      // adjustments_based_on_lifestyle: Yup.string().required(
      //   'Adjustments Based on Lifestyle is required.'
      // )
      // Rest of your amenities object properties
    })
  )
});

export const addResidentialRentalSchema = Yup.object().shape({
  address: Yup.string().required('Property Address is required.'),
  shading: Yup.number().max(100, 'Shading must be less than or equal to 100.')
  // owners: Yup.array()
  //   .required()
  //   .test('sum', 'Total Holdings must be less than or equal to 100%.', (owners = []) => {
  //     const total = owners.reduce((total, question) => {
  //       return total + (question.holding || 0);
  //     }, 0);
  //     return total <= 100;
  //   }),
  // .required('Shading is required.'),
  // tenant: Yup.array().of(
  //   Yup.object().shape({
  //     current_rental_income_pa: Yup.string().required('Current Rental Income is required.')
  //   })
  // )
});

export const addCommercialRentalSchema = Yup.object().shape({
  // owners: Yup.array()
  //   .required()
  //   .test('sum', 'Total Holdings must be less than or equal to 100%.', (owners = []) => {
  //     const total = owners.reduce((total, question) => {
  //       return total + (question.holding || 0);
  //     }, 0);
  //     return total <= 100;
  //   }),
  address: Yup.string().required('Property Address is required.'),
  shading: Yup.number().max(100, 'Shading must be less than or equal to 100.'),
  // .required('Shading is required.'),
  tenant: Yup.array().of(
    Yup.object().shape({
      tenant_name: Yup.string().required('Tenant Name is required.')
      // tenant_activity: Yup.string().required('Tenant Activity is required.'),
      // lease_end_date: Yup.date()
      //   .required('Lease End Date is required.')
      //   .when('lease_start_date', (lease_start_date) => {
      //     if (lease_start_date) {
      //       return Yup.date()
      //         .min(lease_start_date, 'Lease End must be greater than Lease Start.')
      //         .typeError('Lease End Date is required.');
      //     }
      //   }),
      // current_rental_income_pa: Yup.string()
      //   .typeError('String value is not required.')
      //   .min(0, 'Negative value is not required.')
      //   .required('Current Rental Income is required.'),
      // shading: Yup.number()
      //   .typeError('String value is not required.')
      //   .min(0, 'Negative value is not required.')
      //   .max(100, 'Shading must be less than or equal to 100.')
      //   .required('Shading is required.')
    })
  )
});

export const addFacilitySchema = Yup.object().shape({
  owners: Yup.array()
    .required('Title value is required.')
    .test('sum', 'Total Holdings must be less than or equal to 100%.', (owners = []) => {
      const total = owners.reduce((total, question) => {
        return Number(total) + Number(question.holding || 0);
      }, 0);

      return total <= 100;
    }),
  interest_rate_pa: Yup.number()
    .nullable()
    .typeError('String value is not required.')
    .min(0, 'Negative value is not required.')
    .max(100, 'Interest Rate must be less than or equal to 100.'),
  // .required('Interest Rate is required.'),
  facility_fee: Yup.number()
    .nullable()
    .typeError('String value is not required.')
    .min(0, 'Negative value is not required.')
    .max(100, 'Facility Fee must be less than or equal to 100.')
  // .required('Facility Fee is required.')
});

export const addSecuritySchema = Yup.object().shape({
  owners: Yup.array()
    .required('Title value is required.')
    .test('sum', 'Total Holdings must be less than or equal to 100%.', (owners = []) => {
      const total = owners.reduce((total, question) => {
        return Number(total) + Number(question.holding || 0);
      }, 0);
      return total <= 100;
    })
});

export const addPropertySchema = Yup.object().shape({
  address: Yup.string().required('Address is required.')
  // asset_owner: Yup.string().required('Asset Owner is required.'),
  // asset_class: Yup.string().required('Asset Class is required.'),
  // market_value: Yup.string().required('Market Value is required.')
});

export const addSelectSchema = Yup.object().shape({
  trust_name: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Trust name'),
    otherwise: Yup.string().nullable()
  }),
  acn: Yup.string().when('type', {
    is: (value) => value !== addMember[2] && value !== addMember[3] && value !== addMember[4],
    then: Yup.string().min(0, 'acn must be min 0'),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string().min(0, 'abn must be min 0.'),
    otherwise: Yup.string().nullable()
  })
});

export const addDiscretionarySchema = Yup.object().shape({
  trust_name: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Trust name'),
    otherwise: Yup.string().nullable()
  }),
  // trustee_name: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Trustee name'),
  //   otherwise: Yup.string().nullable()
  // }),
  acn: Yup.string().when('type', {
    is: (value) => value !== addMember[2] && value !== addMember[3] && value !== addMember[4],
    then: Yup.string()
      // .typeError('String value is not required.')
      .min(0, 'acn must be min 0'),
    // .max(9, 'acn must be equal to 9.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for acn. '),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string().min(0, 'abn must be min 0.'),
    // .max(11, 'abn must be less than or equal to 11.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for abn. '),
    // .typeError('String value is not required.')

    otherwise: Yup.string().nullable()
  })
  // beneficiaries: Yup.array().of(Yup.object().required('The field Beneficiaries name is required.'))
  // beneficiary: Yup.object().required('The field Beneficiaries name is required.'),
  // beneficiaries: Yup.array().of(Yup.object().required('The field Beneficiaries name is required.')),
  // activity: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Activity name'),
  //   otherwise: Yup.string().nullable()
  // })
});
export const addFixedUnitSchema = Yup.object().shape({
  trust_name: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Trust name'),
    otherwise: Yup.string().nullable()
  }),
  // trustee_name: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Trustee name'),
  //   otherwise: Yup.string().nullable()
  // }),
  acn: Yup.string().when('type', {
    is: (value) => value !== addMember[2] && value !== addMember[3] && value !== addMember[4],
    then: Yup.string()
      // .typeError('String value is not required.')
      .min(0, 'acn must be min 0'),
    // .max(9, 'acn must be equal to 9.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for acn. '),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string().min(0, 'abn must be min 0.'),
    // .max(11, 'abn must be less than or equal to 11.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for abn. '),
    // .typeError('String value is not required.')

    otherwise: Yup.string().nullable()
  })
  // activity: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Activity name'),
  //   otherwise: Yup.string().nullable()
  // })
});

export const addBareSchema = Yup.object().shape({
  trust_name: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Trust name'),
    otherwise: Yup.string().nullable()
  }),
  // trustee_name: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Trustee name'),
  //   otherwise: Yup.string().nullable()
  // }),
  acn: Yup.string().when('type', {
    is: (value) => value !== addMember[2] && value !== addMember[3] && value !== addMember[4],
    then: Yup.string()
      // .typeError('String value is not required.')
      .min(0, 'acn must be min 0'),
    // .max(9, 'acn must be equal to 9.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for acn. '),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string().min(0, 'abn must be min 0.'),
    // .max(11, 'abn must be less than or equal to 11.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for abn. '),
    // .typeError('String value is not required.')

    otherwise: Yup.string().nullable()
  })
  // activity: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Activity name'),
  //   otherwise: Yup.string().nullable()
  // }),
  // beneficiary: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Beneficiary name'),
  //   otherwise: Yup.string().nullable()
  // })
  // beneficiary: Yup.object().required('The field Beneficiaries name is required.')
});
export const addSuperannuationSchema = Yup.object().shape({
  trust_name: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Trust name'),
    otherwise: Yup.string().nullable()
  }),
  // trustee_name: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Trustee name'),
  //   otherwise: Yup.string().nullable()
  // }),
  acn: Yup.string().when('type', {
    is: (value) => value !== addMember[2] && value !== addMember[3] && value !== addMember[4],
    then: Yup.string()
      // .typeError('String value is not required.')
      .min(0, 'acn must be min 0'),
    // .max(9, 'acn must be equal to 9.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for acn. '),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string().min(0, 'abn must be min 0.'),
    // .max(11, 'abn must be less than or equal to 11.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for abn. '),
    // .typeError('String value is not required.')

    otherwise: Yup.string().nullable()
  })
  // activity: Yup.string().when('type', {
  //   is: (value) => Boolean(value === addMember[1]),
  //   then: required('Activity name'),
  //   otherwise: Yup.string().nullable()
  // }),
  // members: Yup.array().of(Yup.object().required('The field Members name is required.'))
});

export const AddCompanySchema = Yup.object().shape({
  legal_name: Yup.string().when('type', {
    is: (value) =>
      value === addMember[0] ||
      value === addMember[2] ||
      value === addMember[3] ||
      value === addMember[5],
    then: required('Legal Name'),
    otherwise: Yup.string().nullable()
  }),
  acn: Yup.string().when('type', {
    is: (value) => value !== addMember[2] && value !== addMember[3] && value !== addMember[4],
    then: Yup.string()
      // .typeError('String value is not required.')
      .min(0, 'acn must be min 0'),
    // .max(9, 'acn must be equal to 9.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for acn. '),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string().min(0, 'abn must be min 0.'),
    // .max(11, 'abn must be less than or equal to 11.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for abn. '),
    // .typeError('String value is not required.')

    otherwise: Yup.string().nullable()
  }),
  activity: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Activity name'),
    otherwise: Yup.string().nullable()
  })
});

export const AddSoleTraderSchema = Yup.object().shape({
  legal_name: Yup.string().when('type', {
    is: (value) =>
      value === addMember[0] ||
      value === addMember[2] ||
      value === addMember[3] ||
      value === addMember[5],
    then: required('Legal Name'),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string().min(0, 'abn must be min 0.'),
    // .max(11, 'abn must be less than or equal to 11.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for abn. '),
    // .typeError('String value is not required.')

    otherwise: Yup.string().nullable()
  }),
  activity: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Activity name'),
    otherwise: Yup.string().nullable()
  })
});

export const AddIndividualSchema = Yup.object().shape({
  // title: Yup.string().when('type', {
  //   is: (value) => value === addMember[4],
  //   then: Yup.string().required('Title is required.'),
  //   otherwise: Yup.string().nullable()
  // }),
  first_name: Yup.string().when('type', {
    is: (value) => value === addMember[4],
    then: Yup.string().required('First Name is required.'),
    otherwise: Yup.string().nullable()
  }),
  last_name: Yup.string().when('type', {
    is: (value) => value === addMember[4],
    then: Yup.string().required('Last Name is required.'),
    otherwise: Yup.string().nullable()
  })
});

export const AddOtherSchema = Yup.object().shape({
  legal_name: Yup.string().when('type', {
    is: (value) =>
      value === addMember[0] ||
      value === addMember[2] ||
      value === addMember[3] ||
      value === addMember[5],
    then: required('Legal Name'),
    otherwise: Yup.string().nullable()
  }),
  abn: Yup.string().when('type', {
    is: (value) => Boolean(value !== addMember[4]),
    then: Yup.string().min(0, 'abn must be min 0.'),
    // .max(11, 'abn must be less than or equal to 11.')
    // .matches(/^[0-9]*$/, 'Only numbers are allowed for abn. '),
    // .typeError('String value is not required.')

    otherwise: Yup.string().nullable()
  }),
  activity: Yup.string().when('type', {
    is: (value) => Boolean(value === addMember[1]),
    then: required('Activity name'),

    otherwise: Yup.string().nullable()
  })
});
//  add proposed Transaction schema
export const Addtranscaction = Yup.object().shape({
  transaction_name: Yup.string().required('Transaction name is required.')
  // associate_name: Yup.string().required('Associate Transaction name is required.')
});
// add funds
export const AddFundValidation = Yup.object().shape({
  funds_use: Yup.object().required('Source is required.'),
  amount: Yup.string().required('Amount  is required.')
  // comment: Yup.string().required('Comment is required.')
});

export const PracticeSettingBasicSchema = Yup.object().shape({
  name: required('Name'),
  email: email,
  phone: Yup.string().required('Phone number is required'),
  acn: required('ACN')
});

export const AddRelianceValidation = Yup.object().shape({
  selected_group: Yup.string().required('Group is required.'),
  comment: Yup.string().required('Comment is required.'),
  value: Yup.string().required('Reliance value is required.')
});

export const addConfigSchema = Yup.object().shape({
  maintitle: Yup.string().required('Title value is required.'),
  comment: Yup.string().required('Comment value is required.'),
  // title: Yup.array().of(Yup.string().required('Title value is required.'))
  title: Yup.array().of(
    Yup.object().shape({ title: Yup.string().required('Title value is required.') })
  )
  // title: Yup.array().of(
  //   Yup.object().shape({
  //     title: Yup.string().required('Title value is required.')
  //   })
  // )
});

export const addIndividualTaxSchema = Yup.object().shape({
  title: Yup.array()
    .transform((field) => field.slice(0, -1))
    .of(
      Yup.object().shape({
        maxincome: Yup.number().when('minincome', (minincome) => {
          if (minincome) {
            return Yup.number()
              .min(minincome + 1, 'Max income should be greater than Min Income')
              .typeError('Max income is required');
          }
        })
      })
    )
});

export const addOverAllTitle = Yup.object().shape({
  name: Yup.string().required('Title is required.'),
  color: Yup.object().shape({ key: Yup.string().required('Please select color.') })
  // option: Yup.array().of(
  //   Yup.object().shape({ value: Yup.string().required('This Field required') })
  // )
});

export const addOverallAssessmentSchema = Yup.object().shape({
  name: Yup.string().required('Category Name is required.'),
  option: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Option is required.')
    })
  )
});

export const addStaffSchema = Yup.object().shape({
  first_name: Yup.string().required('This field is required.'),
  last_name: Yup.string().required('This field is required.'),
  email: Yup.string().required('This field is required.'),
  phone: Yup.number()
    .typeError('String value is not required.')
    .required('This field is required.'),
  user_role_id: required('This field is required.')
});

export const addPracticeSchema = Yup.object().shape({
  practice_name: Yup.string().required('This field is required.'),
  name: Yup.string().required('This field is required.'),
  surname: Yup.string().required('This field is required.'),
  abn: Yup.number().required('This field is required.'),
  email: Yup.string().required('This field is required.'),
  phone: Yup.number()
    .typeError('String value is not required.')
    .required('This field is required.'),
  user_limit: Yup.number()
    .typeError('String value is not required.')
    .required('This field is required.')
});
