import UserHeader from '../../dashboard/UserHeader';

const LearningTools = () => {
  return (
    <div className="setting toggle-sidebar">
      <UserHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="/dashboard">Dashboard</a>
              </li>
              <li>
                <strong>Tools &amp; Templates</strong>
              </li>
            </ul>
            <h1>Tools &amp; Templates</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningTools;
