import UserHeader from '../dashboard/UserHeader';

const BankabilityTools = () => {
  return (
    <div className="setting toggle-sidebar">
      <UserHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="/dashboard">Dashboard</a>
              </li>
              <li>
                <strong>Bankability Tools</strong>
              </li>
            </ul>
            <h1>Bankability Tools</h1>
          </div>
        </div>
        <div className="bankability-fields">
          <ul>
            <li>
              <a href="https://abr.business.gov.au" target="_blank" rel="noreferrer">
                <img src="images/binoculars-icon.svg" />
                <h5>ABN</h5>
                <h6>Lookup</h6>
              </a>
            </li>
            {/* <li>
              <a href="#" target="_blank" rel="noreferrer">
                <img src="images/bank-icon.svg" />
                <h5>Bank</h5>
                <h6>Statements</h6>
              </a>
            </li> */}
            <li>
              <a href="https://www.equifax.com.au/personal/" target="_blank" rel="noreferrer">
                <img src="images/business-icon.svg" />
                <h5>Business &amp; Personal</h5>
                <h6>Credit Reports</h6>
              </a>
            </li>
            <li>
              <a href="https://www.corelogic.com.au" target="_blank" rel="noreferrer">
                <img src="images/core-icon.svg" />
                <h5>Core Logic</h5>
                <h6>Property Report</h6>
              </a>
            </li>
            {/* <li>
              <a href="#" target="_blank" rel="noreferrer">
                <img src="images/calendar-icon.svg" />
                <h5>Days Between Dates</h5>
                <h6>Lookup</h6>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noreferrer">
                <img src="images/vehicle-icon.svg" />
                <h5>Leasing</h5>
                <h6>Calculator</h6>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noreferrer">
                <img src="images/umbrella-icon.svg" />
                <h5>LMI</h5>
                <h6>Calculator</h6>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noreferrer">
                <img src="images/currency-icon.svg" />
                <h5>Loan Repayments</h5>
                <h6>Calculator</h6>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noreferrer">
                <img src="images/pay-icon.svg" />
                <h5>Pay</h5>
                <h6>Calculator</h6>
              </a>
            </li>
            <li>
              <a
                href="https://www.the500group.com.au/stamp-duty-calculator/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/house-icon.svg" />
                <h5>Stamp Duty</h5>
                <h6>Calculator</h6>
              </a>
            </li> */}
            <li>
              <a
                href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/asic-icon.svg" />
                <h5>ASIC</h5>
                <h6>Search</h6>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BankabilityTools;
