import React, { useEffect } from 'react';
// import Button from '../components/Button';
import { Formik } from 'formik';
import { getDisclaimerSettingData, settingState } from '../../slices/slices/SettingSlice';
import { useDispatch, useSelector } from 'react-redux';

const Disclaimer = () => {
  const dispatch = useDispatch();
  const { disclaimerSettingResponse } = useSelector(settingState);

  useEffect(() => {
    dispatch(getDisclaimerSettingData());
  }, []);

  return (
    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <Formik
        initialValues={{
          name: disclaimerSettingResponse?.name,
          description: disclaimerSettingResponse?.description
        }}
        enableReinitialize
      >
        {({ values }) => (
          <form className="row needs-validation" noValidate>
            <div className="innertab">
              <div className="container">
                <div className="textarea">
                  <h6>{values.name}</h6>
                  <div className="intro_content">
                    <div dangerouslySetInnerHTML={{ __html: values.description }} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="btn_area text-end">
              <div className="container">
                <Button className="btn btn-outline-secondary" type="reset">
                  Cancel
                </Button>
                <Button className="btn btn-success" type="submit">
                  Save
                </Button>
              </div>
            </div> */}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Disclaimer;
