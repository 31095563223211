import config from '../config';
import axios from 'axios';
import { setLoader, setErrorMessage, setHandleResponse } from '../slices/slices/AppSlice';
import { GetCookies } from './cookies';
import { setDialogOn432Error } from '../slices/slices/FinancialsSlice';

const clientService = {
  remove: async (endPoint, setErrors, dispatch, show = true) => {
    dispatch(setLoader(true));
    let header = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + GetCookies('token'),
        'Access-Control-Allow-Origin': '*'
      }
    };
    try {
      let res = await axios.delete(config.apiUrl + endPoint, header);
      let payload = [];
      payload['response'] = res;
      payload['show'] = show;
      dispatch(setHandleResponse(payload));
      dispatch(setLoader(false));
      return res;
    } catch (e) {
      dispatch(setLoader(false));
      if (e.response.data.errors) {
        setErrors(e.response.data.errors);
        return '';
      } else {
        dispatch(setErrorMessage(e.response.data.message));
        return e.response.data.message;
      }
    }
  },
  get: async (
    endPoint,
    setErrors,
    dispatch,
    show = true,
    loader = true,
    tenantObj = {},
    fullUrl = null
  ) => {
    dispatch(setLoader(loader));
    let header = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + GetCookies('token'),
        'Access-Control-Allow-Origin': '*',
        mode: 'cors',
        ...tenantObj
      }
    };
    if (!fullUrl) {
      fullUrl = config.apiUrl + endPoint;
    }
    try {
      let res = await axios.get(fullUrl, header);
      let payload = [];
      payload['response'] = res;
      payload['show'] = show;
      dispatch(setHandleResponse(payload));
      dispatch(setLoader(false));
      return res;
    } catch (e) {
      dispatch(setLoader(false));
      try {
        if (typeof e?.response?.data?.errors === 'object') {
          dispatch(setErrorMessage(Object.values(e?.response?.data?.errors)?.[0]?.[0]));
        } else if (e?.response?.data?.errors) {
          setErrors(e.response.data.errors);
          return '';
        } else {
          dispatch(setErrorMessage(e.response.data.message));
          return e.response.data.message;
        }
      } catch (error) {
        dispatch(setErrorMessage(error.message));
      }
    }
  },
  post: async (endPoint, data, setErrors, dispatch, show = true, loader = true, tenantObj = {}) => {
    dispatch(setLoader(loader));

    let header = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + GetCookies('token'),
        'Access-Control-Allow-Origin': '*',
        ...tenantObj
      }
    };
    try {
      let res = await axios.post(config.apiUrl + endPoint, data, header);
      let payload = [];
      payload['response'] = res;
      payload['show'] = show;
      dispatch(setHandleResponse(payload));
      dispatch(setLoader(false));
      return res;
    } catch (e) {
      dispatch(setLoader(false));
      if (endPoint == 'login/xero') {
        if (e.response.data.errors.user_exists == 'no') {
          window.location.href = 'http://139.59.85.161/~influencepartner/contact-us/';
        }
        return e.response.data.message;
      }
      if (typeof e?.response?.data?.errors === 'object') {
        dispatch(setErrorMessage(Object.values(e?.response?.data?.errors)?.[0]?.[0]));
      } else if (e.response.data.errors) {
        setErrors(e.response.data.errors);
        return '';
      } else {
        dispatch(setErrorMessage(e.response.data.message));
        return e.response.data.message;
      }
    }
  },
  put: async (endPoint, data, setErrors, dispatch, show = true, errCb) => {
    dispatch(setLoader(true));
    let header = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + GetCookies('token'),
        'Access-Control-Allow-Origin': '*'
      }
    };
    try {
      let res = await axios.put(config.apiUrl + endPoint, data, header);
      let payload = [];
      payload['response'] = res;
      payload['show'] = show;
      dispatch(setHandleResponse(payload));
      dispatch(setLoader(false));
      dispatch(setDialogOn432Error(''));
      return res;
    } catch (e) {
      dispatch(setLoader(false));
      if (e.response.data.errors) {
        setErrors(e.response.data.errors);
        dispatch(setDialogOn432Error(''));
        errCb && errCb(e.response.data.errors);
        return '';
      } else {
        if (e.response.status == 432) {
          dispatch(setDialogOn432Error(e.response.data.message));
        } else {
          dispatch(setDialogOn432Error(''));
          dispatch(setErrorMessage(e.response.data.message));
        }
        return e.response.data.message;
      }
    }
  },
  fileUpload: async (endPoint, data, files, setErrors, dispatch) => {
    dispatch(setLoader(true));
    const formData = new FormData();
    const key = Object.keys(data);
    // debugger;
    for (let i = 0; i < key.length; i++) {
      formData.append(key[i], data[key[i]]);
    }
    const filesKey = Object.keys(files);
    for (let i = 0; i < filesKey.length; i++) {
      formData.append(filesKey[i], files[filesKey[i]], files[filesKey[i]].name);
    }
    return this.post(endPoint, data, setErrors, dispatch);
  }
};

export default clientService;
