import React, { useState } from 'react';

export default function TitleDropDown(props) {
  const { option, color, itemData, setItemData, mainIndex, subIndex, handleInputChange } = props;
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);
  const handleOnchange = (value) => {
    handleInputChange(value);
    let data = itemData;
    data[mainIndex].subcategory[subIndex].value = value;
    setItemData(JSON.parse(JSON.stringify(data)));
    setOpen(!isOpen);
  };
  return (
    <>
      <a
        className={`drp dropdown d-flex justify-content-center align-items-center ${
          isOpen && 'show'
        }`}
        style={{ backgroundColor: color == 1 ? '#2bb673' : color == 2 ? '#f5c520' : '#ff4e4e' }}
        data-bs-toggle="dropdown"
        aria-expanded={isOpen}
        onClick={toggleDropdown}
      >
        <i className="icon-chevron-down"></i>
      </a>
      <ul
        className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${
          isOpen && 'show'
        }`}
        style={{
          position: 'absolute',
          inset: '0px 0px auto auto',
          margin: '0px',
          transform: 'translate(-16px, 60px)'
        }}
        data-popper-placement="bottom-end"
      >
        {option?.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => handleOnchange(item.value)}
              style={{ cursor: 'pointer' }}
            >
              <a>{item.title}</a>
            </li>
          );
        })}
      </ul>
    </>
  );
}
