import Header from '../Header';
import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import FormActions from '../../../components/FormActions';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGuidanceData,
  guidanceState,
  updateGuidance
} from '../../../slices/slices/GuidanceSlice';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import '/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DialogModal from '../../../components/modal/DialogModal';
import constants from '../../../constant';
import { useNavigatingAway } from '../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../common/components/DialogLeavingPage';

const Guidance = () => {
  const guidanceDataResponse = useSelector(guidanceState);
  const [direction, setDirection] = useState(EditorState.createEmpty());

  const { jobId } = useParams();
  const dispatch = useDispatch();
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    dispatch(getGuidanceData(jobId));
  }, []);

  useEffect(() => {
    if (
      guidanceDataResponse.GuidanceDataResponse &&
      guidanceDataResponse.GuidanceDataResponse.data
    ) {
      const data = guidanceDataResponse?.GuidanceDataResponse?.data;
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setDirection(editorState);
      }
    }
  }, [guidanceDataResponse.GuidanceDataResponse?.data]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    values.guidance_direction = draftToHtml(
      convertToRaw(values.guidance_direction.getCurrentContent())
    );
    dispatch(updateGuidance(jobId, values, setErrors));
  };

  const initialValue = {
    guidance_direction: direction || ''
  };

  const handleChange = (rawDraftContentState) => {
    setDirection(rawDraftContentState);
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="#">Group Assessment</a>
              </li>
              <li>
                <a href="#">Bankability Assessment</a>
              </li>
              <li className="breadcrumb-item active">
                <strong>Guidance &amp; Direction</strong>
              </li>
            </ul>
          </div>
          <div className="breadcrumb-right">
            <DialogModal
              title="Guidance & Direction"
              p1={constants.guidance1}
              p2={constants.guidance2}
              p3={constants.guidance3}
            />
          </div>
        </div>
        <div>
          <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
            {({ values, handleSubmit }) => {
              return (
                <>
                  <DialogLeavingPage
                    showDialog={showDialogLeavingPage}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                  />
                  <Form onSubmit={handleSubmit} className="facility-form form-data">
                    <div className="pagetitle proversion">
                      <div className="title_pro d-flex justify-content-between align-items-center">
                        <h1>Guidance & Direction</h1>
                      </div>
                    </div>

                    <div className="white-card">
                      <Field name="guidance_direction">
                        {({ field }) => (
                          <Editor
                            editorState={direction}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={handleChange}
                            toolbar={{
                              options: ['inline', 'list'],
                              inline: {
                                options: ['bold', 'italic', 'underline']
                              },
                              list: {
                                options: ['unordered', 'ordered']
                              }
                            }}
                            name={field.name}
                            id={field.name}
                            values={values.guidance_direction}
                            textareaName="guidance_direction"
                            editorStyle={{ border: '1px solid #F1F1F1', height: 400 }}
                            stripPastedStyles={true}
                            onContentStateChange={(e) => {
                              handleInputChange(e.blocks);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="action-box full">
                      <FormActions cancel={'/job/' + jobId + '/dashboard'} values={values} />
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Guidance;
