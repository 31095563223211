import React, { useState } from 'react';
import { sortingType } from '../../../../../../utils/constant';

const TableHeadItem = ({
  keyvalue,
  item,
  sorting,
  handleSort,
  colSpan,
  theadColor,
  style,
  headClassName
}) => {
  const [sortOrder, setSortOrder] = useState(0);

  const onhandleSort = (value, sorting) => {
    if (sorting) {
      setSortOrder((value + 1) % sortingType.length);
      handleSort(value, keyvalue);
    }
  };

  return (
    <>
      <th
        key={keyvalue}
        colSpan={colSpan ? colSpan : 0}
        onClick={() => onhandleSort(sortOrder, sorting)}
        style={Object.assign({ backgroundColor: theadColor }, style)}
        className={headClassName}
      >
        {item}
        {sorting ? (
          <i
            className={
              sortingType[sortOrder] == 'ASC'
                ? 'fa fa-caret-up'
                : sortingType[sortOrder] == 'DESC'
                ? 'fa fa-caret-down'
                : null
            }
          ></i>
        ) : null}
      </th>
    </>
  );
};

export default TableHeadItem;
