import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const livingExpenseInitialState = {
  loading: false,
  averageLivingResponse: {},
  updateAverageLivingResponse: {},
  houseHoldResponse: {},
  updatedHouseHoldResponse: {}
};

export const LivingExpenseSlice = createSlice({
  name: 'livingExpense',
  initialState: livingExpenseInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setAverageLivingResponse: (state, action) => {
      state.averageLivingResponse = action.payload.data;
      state.loader = false;
    },
    setUpdatedAverageLivingResponse: (state, action) => {
      state.updateAverageLivingResponse = action.payload;
      state.loader = false;
    },
    setHouseHoldResponse: (state, action) => {
      state.houseHoldResponse = action.payload;
      state.loader = false;
    },
    setUpdatedHouseHoldResponse: (state, action) => {
      state.updatedHouseHoldResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getAverageLiving = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/living-expenses/average-living',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAverageLivingResponse(response.data));
  }
};

export const getHouseHold = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/living-expenses/household',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setHouseHoldResponse(response.data));
  }
};

export const updateAverageLiving = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/living-expenses/average-living',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedAverageLivingResponse(response.data));
  }
};

export const updateHouseHold = (id, data, setErrors) => async (dispatch) => {
  data?.household && data?.household.length > 0
    ? data?.household.map((item, index) => {
        data.household[index].number_of_dependant !== ' '
          ? data.household[index].number_of_dependant
          : 0,
          data.household[index].adjustments_based_on_lifestyle !== ' '
            ? data.household[index].adjustments_based_on_lifestyle
            : 0;
      })
    : null;
  const response = await clientService.put(
    'jobs/' + id + '/living-expenses/household',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedHouseHoldResponse(response.data));
  }
};

export const {
  setLoader,
  setAverageLivingResponse,
  setUpdatedAverageLivingResponse,
  setHouseHoldResponse,
  setUpdatedHouseHoldResponse
} = LivingExpenseSlice.actions;

export const livingExpenseState = (state) => {
  return state.livingExpense;
};

export default LivingExpenseSlice.reducer;
