import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';
import { GetCookies, SetCookie, RemoveCookie } from '../../services/cookies';

export const authInitialState = {
  loading: false,
  isUserLoggedIn: !!GetCookies('token'),
  isOTPVerified: localStorage.getItem('isOTPVerified') === 'true',
  loginResponse: {},
  logoutResponse: {},
  refreshTokenResponse: {},
  forgotPasswordResponse: {},
  resetPasswordResponse: {},
  emailOTPResponse: {},
  emailVerifyResponse: {},
  numberVerifyResponse: {},
  permission: {}
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isUserLoggedIn = action.payload;
    },
    setIsOtpVerified: (state, action) => {
      state.isOTPVerified = action.payload;
    },
    setLoginResponse: (state, action) => {
      state.loginResponse = action.payload;
      state.loader = false;
    },
    setLogoutResponse: (state, action) => {
      state.logoutResponse = action.payload;
      state.loader = false;
    },
    setRefreshTokenResponse: (state, action) => {
      state.refreshTokenResponse = action.payload;
      state.loader = false;
    },
    setforgotPasswordResponse: (state, action) => {
      state.forgotPasswordResponse = action.payload;
      state.loader = false;
    },
    setResetPasswordResponse: (state, action) => {
      state.resetPasswordResponse = action.payload;
      state.loader = false;
    },
    setSendEmailResponse: (state, action) => {
      state.emailOTPResponse = action.payload;
      state.loader = false;
    },
    setVerifyEmailResponse: (state, action) => {
      state.emailVerifyResponse = action.payload;
      state.loader = false;
    },
    setVerifyNumberResponse: (state, action) => {
      state.numberVerifyResponse = action.payload;
      state.loader = false;
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    }
  }
});

export const refreshToken = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('refresh', data, setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setRefreshTokenResponse(response.data));
    dispatch(setIsLoggedIn(true));
    SetCookie('token', response.data.data.access_token);
    SetCookie('tokenExpire', new Date().getTime());
  }
};

export const doLogin = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('login', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    let mypermission = [];
    response?.data?.data?.user_permission?.map((item) => {
      mypermission.push(item?.key);
    });
    mypermission.push(response.data.data.user_role.name);
    const templateObj = JSON.parse(response?.data?.data?.practice_settings?.disp_templates);
    if (templateObj?.accountants == 1 || templateObj?.brokers == 1)
      mypermission.push('disp-templates');

    if (templateObj?.brokers == 1) mypermission.push('disp-brokers');
    if (templateObj?.accountants == 1) mypermission.push('disp-accountants');

    SetCookie('permission', mypermission);
    dispatch(setPermission(mypermission));

    // delete
    // SetCookie('role', {id:});
    SetCookie('token', response.data.data.access_token, response.data.data.expires_in);
    SetCookie(
      'tokenExpire',
      new Date().getTime() + response.data.data.expires_in * 1000,
      response.data.data.expires_in
    );
    dispatch(setLoginResponse(response.data));
    dispatch(setIsLoggedIn(true));
  }
};

export const xeroLogin = (code, setErrors) => async (dispatch) => {
  let data = { code: code };
  const response = await clientService.post('login/xero', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    let mypermission = [];
    response?.data?.data?.user_permission?.map((item) => {
      mypermission.push(item?.key);
    });
    mypermission.push(response.data.data.user_role.name);
    SetCookie('permission', mypermission);
    dispatch(setPermission(mypermission));

    // delete
    // SetCookie('role', {id:});
    SetCookie('token', response.data.data.access_token, response.data.data.expires_in);
    SetCookie(
      'tokenExpire',
      new Date().getTime() + response.data.data.expires_in * 1000,
      response.data.data.expires_in
    );
    dispatch(setLoginResponse(response.data));
    dispatch(setIsLoggedIn(true));
  }
};

export const doLogout = (data, setErrors) => async (dispatch) => {
  localStorage.setItem('isOTPVerified', 'false');
  dispatch(setLoader(false));
  const response = await clientService.post('logout', data, setErrors, dispatch);
  dispatch(setLoader(false));
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
    localStorage.setItem('isOTPVerified', 'true');
  } else {
    SetCookie('token', '');
    SetCookie('tokenExpire', '');
    SetCookie('_jobData', '');
    SetCookie('permission', '');
    SetCookie('_jobHeader', '');
    SetCookie('_xsrf', '');
    await RemoveCookie('token');
    await RemoveCookie('tokenExpire');
    await RemoveCookie('_jobData');
    await RemoveCookie('permission');
    await RemoveCookie('_jobHeader');
    await RemoveCookie('_xsrf');
    dispatch(setLogoutResponse(response.data));
    dispatch(setIsLoggedIn(false));
    dispatch(setIsOtpVerified(false));
    localStorage.clear();
    window.location.href = '/login';
  }
};

export const forgotPassword = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('forgot-password', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setforgotPasswordResponse(response.data));
  }
};

export const resetPassword = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('password/reset', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setResetPasswordResponse(response.data));
  }
};

export const sendEmailOTP = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('2fa/email', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSendEmailResponse(response.data));
  }
};

export const verifyEmailOTP = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('2fa/email/verify', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setVerifyEmailResponse(response.data));
    dispatch(setIsOtpVerified(true));
    localStorage.setItem('isOTPVerified', 'true');
  }
};

export const verifyNumberOTP = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('2fa/google', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setVerifyNumberResponse(response.data));
    dispatch(setIsOtpVerified(true));
    localStorage.setItem('isOTPVerified', 'true');
  }
};

export const {
  setLoader,
  setLoginResponse,
  setLogoutResponse,
  setRefreshTokenResponse,
  setforgotPasswordResponse,
  setResetPasswordResponse,
  setSendEmailResponse,
  setVerifyEmailResponse,
  setVerifyNumberResponse,
  setIsLoggedIn,
  setIsOtpVerified,
  setPermission
} = AuthSlice.actions;

export const authState = (state) => {
  return state.auth;
};

export default AuthSlice.reducer;
