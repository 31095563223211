import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const SlicedataEntryInitialState = {
  loading: false,
  dataEntryResponse: {},
  selectedMember: {}
};

export const DataEntrySlice = createSlice({
  name: 'dataEntry',
  initialState: SlicedataEntryInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setdataEntryResponse: (state, action) => {
      state.dataEntryResponse = action.payload.data;
      state.loader = false;
    },
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload;
    }
  }
});

export const getDataEntry = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members?limit=500',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setdataEntryResponse(response.data));
  }
};

export const getSelectedMemberData = (id, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSelectedMember(response.data.data));
  }
};

export const setEmptySelectedMember = () => async (dispatch) => {
  dispatch(setSelectedMember(null));
};

export const { setSelectedMember, setLoader, setdataEntryResponse, emptySelectedMember } =
  DataEntrySlice.actions;

export const dataEntryState = (state) => {
  return state.dataEntry;
};

export default DataEntrySlice.reducer;
