import { Link, useParams } from 'react-router-dom';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import { Form } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import BusinessTax from './BusinessTax';
import PersonalTax from './PersonalTax';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import FormActions from '../../../../components/FormActions';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  getTaxData,
  getTaxRateByMember,
  taxState,
  updateTax,
  deleteRecord
} from '../../../../slices/slices/TaxSlice';
import {
  commonState,
  getConfig,
  getindividualtaxrate
} from '../../../../slices/slices/CommonSlice';
import { dataEntryState, getSelectedMemberData } from '../../../../slices/slices/DataEntrySlice';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType } from '../../../common/Misc';
import { useNavigate } from 'react-router-dom';
import {
  calculatePayableAdjustedTax,
  calculateAdjustedTaxableIncome
} from '../../../../utils/businessDataEntryCalculations';
import {
  calculateAdjustedTaxableIncomePersonal,
  calculatePayableTaxPersonal
} from '../../../../utils/personalDataEntryCalculations';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const Tax = () => {
  const navigate = useNavigate();
  const { jobId, memberId } = useParams();
  const { selectedMember } = useSelector(dataEntryState);
  const formValues = useRef();
  const dispatch = useDispatch();
  const [type, setType] = useState(null);
  const { taxData, taxDataResponse, taxRateByMember, financials, years } = useSelector(taxState);
  const { configList, setindividualtaxrateList } = useSelector(commonState);
  const internal_notes = taxDataResponse?.data?.data?.internal_notes;
  const report_comments = taxDataResponse?.data?.data?.report_comments;
  const [internalNotes, setInternalNotes] = useState(internal_notes);
  const [reportComments, setReportComments] = useState(report_comments);
  const [finalYears, setFinalYears] = useState(years);
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    if (selectedMember) {
      if (selectedMember.type === 'individual') {
        setType('individual');
      } else {
        setType('business');
      }
    }
  }, [selectedMember]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    const tax_rate = values.tax_rate;
    delete values.tax_rate;
    let calculateTax = {};
    let adjusted_taxable_income = {};

    finalYears.map((value) => {
      if (type === 'business') {
        calculateTax[value] = calculatePayableAdjustedTax(value, taxData, financials, tax_rate);
        adjusted_taxable_income[value] = calculateAdjustedTaxableIncome(value, taxData, financials);
      } else {
        calculateTax[value] = calculatePayableTaxPersonal(value, taxData, financials);
        adjusted_taxable_income[value] = calculateAdjustedTaxableIncomePersonal(
          value,
          taxData,
          financials
        );
      }
    });
    values.report_comments = values?.report_comments
      ? values?.report_comments.replace(/<[^>]+>/g, '').length === 0
        ? ''
        : values.report_comments
      : '';
    values.internal_notes = values?.internal_notes
      ? values.internal_notes.replace(/<[^>]+>/g, '').length === 0
        ? ''
        : values.internal_notes
      : '';
    dispatch(
      updateTax(
        jobId,
        memberId,
        {
          ...taxData,
          ['adjusted-tax-income']: {
            ...taxData['adjusted-tax-income'],
            ['tax-payable-on-adjusted-taxable-income']: calculateTax,
            ['adjusted-taxable-income']: adjusted_taxable_income,
            tax_rate: tax_rate
          }
        },
        values,
        setErrors
      )
    );
  };

  useEffect(() => {
    setFinalYears(years);
  }, [years]);

  useEffect(() => {
    setInternalNotes(taxDataResponse?.data?.data?.internal_notes);
  }, [taxDataResponse?.data?.data?.internal_notes]);

  useEffect(() => {
    setReportComments(taxDataResponse?.data?.data?.report_comments);
  }, [taxDataResponse?.data?.data?.report_comments]);

  useEffect(() => {
    dispatch(getSelectedMemberData(jobId, memberId));
    dispatch(getConfig(configList));
    dispatch(getindividualtaxrate());
    dispatch(getTaxRateByMember(jobId, memberId));
  }, []);

  useEffect(() => {
    dispatch(getTaxData(jobId, memberId, selectedMember?.type));
  }, [selectedMember]);

  const handleonClick = () => {
    navigate('/job/' + jobId + '/group-setup/settings');
  };

  const handleRemove = (index, data_key) => {
    let data = JSON.parse(JSON.stringify(taxData));
    data[data_key]['items'].splice(index, 1);
    // setFinancialData({ ...data });
    dispatch(deleteRecord(data, years, financials));
  };

  const initialValue = {
    report_comments: reportComments,
    internal_notes: internalNotes,
    is_completed: 0,
    tax_rate:
      taxData['adjusted-tax-income']?.tax_rate &&
      taxRateByMember?.data?.options != undefined &&
      JSON.parse(taxRateByMember?.data?.options)?.find(
        (el) => el?.value == taxData['adjusted-tax-income']?.tax_rate
      ) != undefined
        ? taxData['adjusted-tax-income']?.tax_rate
        : taxRateByMember?.data?.options &&
          JSON.parse(taxRateByMember?.data?.options).find((item) => item.is_default == 1).value
  };

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <strong>Tax</strong>
              </li>
            </ul>
            <h1>Tax - {displayNameFromType(selectedMember)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <DialogModal title="Tax" p1={constants.tax} />
          </div>
        </div>
        <Formik
          initialValues={initialValue}
          onSubmit={handleFormSubmit}
          innerRef={formValues}
          enableReinitialize
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm
          }) => (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form
                key="financials"
                onSubmit={handleSubmit}
                noValidate
                className="security-form form-data mt-30"
              >
                {type === 'business' ? (
                  <>
                    <BusinessTax
                      taxRateByMember={taxRateByMember}
                      setFieldValue={setFieldValue}
                      handleRemove={handleRemove}
                      taxDataResponse={taxDataResponse}
                      handleInputChange={handleInputChange}
                      selectedMember={selectedMember}
                    />
                  </>
                ) : (
                  <PersonalTax
                    handleInputChange={handleInputChange}
                    handleRemove={handleRemove}
                    selectedMember={selectedMember}
                    individualTaxRateList={setindividualtaxrateList}
                  />
                )}

                {Object.keys(taxData).length == 0 ? (
                  <>
                    <div className="white-card">
                      <p style={{ textAlign: 'center', margin: 0 }}>
                        Please setup
                        <a style={{ color: '#3278c2' }} onClick={handleonClick}>
                          {' Financial Year Structure '}
                        </a>{' '}
                        first.
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <CommentsNotes
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleInputChange={handleInputChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  resetForm={resetForm}
                />
                <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Tax;
