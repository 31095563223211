import { Link, /* useLocation, */ useParams } from 'react-router-dom';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import { Form } from 'react-bootstrap';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import BusinessFinancials from './BusinessFinancials';
import PersonalFinancials from './PersonalFinancials';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import FormActions from '../../../../components/FormActions';
import { getxero, setDialogOn432Error } from '../../../../slices/slices/FinancialsSlice';
import CustomModal from '../../../../components/CustomModal';
import {
  calculateDebtServicing,
  calculateAdjustedEbitdaTax,
  calculateGrossProfit,
  calculateNetProfit,
  calculateFundsAfterTax,
  calculateEbit,
  calculateEbitda,
  calculatePayableTaxBusiness,
  calculateDebtServicingForTrust
} from '../../../../utils/businessDataEntryCalculations';
import {
  calculateDebtServicings,
  personalcalculateEbitda,
  calculateTaxableIncome,
  calculateTotalIncome,
  calculateAdjustedEbitda,
  calculatePayableTaxPersonal
} from '../../../../utils/personalDataEntryCalculations';

import {
  flushFinancialData,
  financialsState,
  getFinancialData,
  updateFinancials,
  deleteRecord,
  getTaxRateByMember,
  deletefinancial
} from '../../../../slices/slices/FinancialsSlice';

import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { dataEntryState, getSelectedMemberData } from '../../../../slices/slices/DataEntrySlice';
import constants from '../../../../constant';
import DialogModal from '../../../../components/modal/DialogModal';
import { displayNameFromType, formatPercentage } from '../../../common/Misc';
import { useNavigate } from 'react-router-dom';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';
import XeroimportModal from '../../../../components/XeroimportModal';
import { commonState, getindividualtaxrate } from '../../../../slices/slices/CommonSlice';
import Dialog432Modal from '../../../../components/Dialog432Modal';

const Financials = () => {
  // const { state } = useLocation();
  const navigate = useNavigate();
  const { jobId, memberId } = useParams();
  const { selectedMember } = useSelector(dataEntryState);
  const formValues = useRef();
  const dispatch = useDispatch();
  const { financialDataResponse, tax, years, dialogOn432Error } = useSelector(financialsState);
  const [displayYear, setDisplayYears] = useState([]);
  const financialItem = useSelector(financialsState);
  const internal_notes = financialDataResponse?.data?.data?.internal_notes;
  const report_comments = financialDataResponse?.data?.data?.report_comments;
  const [type, setType] = useState(null);
  const [internalNotes, setInternalNotes] = useState(internal_notes);
  const [reportComments, setReportComments] = useState(report_comments);
  const [financialData, setFinancialData] = useState({});
  const [redirectUrl, setRedirectUrl] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [show432Dialog, setShow432Dialog] = useState(false);
  // const [, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showFinancialAnalysisPopup, setShowFinancialAnalysisPopup] = useState(null);
  const { setindividualtaxrateList } = useSelector(commonState);

  var monthNames = [
    { label: 'Jan', value: '01' },
    { label: 'Feb', value: '02' },
    { label: 'Mar', value: '03' },
    { label: 'Apr', value: '04' },
    { label: 'May', value: '05' },
    { label: 'Jun', value: '06' },
    { label: 'July', value: '07' },
    { label: 'Aug', value: '08' },
    { label: 'Sep', value: '09' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' }
  ];

  const handleFinancialAnalysisPopup = () => {
    setShowFinancialAnalysisPopup(false);
  };
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    if (
      (selectedMember && selectedMember.type === 'individual') ||
      (selectedMember?.financial_val && selectedMember?.financial_val !== 'not-started')
    ) {
      setShowFinancialAnalysisPopup(false);
    } else if (
      showFinancialAnalysisPopup === null &&
      selectedMember?.financial_val &&
      selectedMember?.financial_val === 'not-started'
    ) {
      setShowFinancialAnalysisPopup(true);
    }
  }, [financialDataResponse, selectedMember]);

  useMemo(() => {
    setShowFinancialAnalysisPopup(null);
    dispatch(flushFinancialData());
    dispatch(getindividualtaxrate());
  }, []);

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    if (selectedMember) {
      if (selectedMember.type === 'individual') {
        setType('individual');
      } else {
        setType('business');
      }
    }
  }, [selectedMember]);

  useEffect(() => {
    setFinancialData(financialItem.financialData);
  }, [financialItem]);

  useEffect(() => {
    setInternalNotes(financialDataResponse?.data?.data?.internal_notes);
  }, [financialDataResponse?.data?.data?.internal_notes]);

  useEffect(() => {
    setReportComments(financialDataResponse?.data?.data?.report_comments);
  }, [financialDataResponse?.data?.data?.report_comments]);

  useEffect(() => {
    dispatch(getSelectedMemberData(jobId, memberId));
    if (!showFinancialAnalysisPopup) {
      dispatch(getTaxRateByMember(jobId, memberId));
    }
  }, []);

  useEffect(() => {
    if (dialogOn432Error) {
      toggle432Dialog();
    }
  }, [dialogOn432Error]);

  useEffect(() => {
    if (
      (selectedMember && selectedMember.type === 'individual') ||
      showFinancialAnalysisPopup == false
    ) {
      dispatch(getFinancialData(jobId, memberId, selectedMember?.type));
      localStorage.removeItem('month');
    }
  }, [selectedMember, showFinancialAnalysisPopup]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    let other_income_total = {};
    let trending_income_total = {};
    let cost_of_sales_total = {};
    let operating_expeense = {};
    let operating_expeense_percentage = {};
    let other_income_percentage = {};
    let ebitda_total_val = {};
    let funds_available = {};
    let undistributed_profit_loss = {};
    let adjusted_ebitda_total = {};
    let adjusted_ebitda = {};
    let adjusted_percentage = {};
    let ebit_total = {};
    let ebit_percentage = {};
    let grossProfitData = {};
    let grossProfitData_percentage = {};
    let netProfitBeforeTaxData = {};
    let netProfitBeforeTaxData_percentage = {};
    let tax_payable = {};
    let tax_payable_percentage = {};
    let funds_after_tax = {};
    let funds_after_tax_percentage = {};
    let ebitda_percentage = {};

    let total_income = {};
    let taxableIncome = {};
    let trending_income_percentage = {};

    years.map((value) => {
      other_income_total[value] = financialData?.['other-income']?.totals?.data[value]
        ? financialData?.['other-income']?.totals?.data[value]
        : 0;
      cost_of_sales_total[value] = financialData?.['cost-of-sales']?.totals?.data[value]
        ? financialData?.['cost-of-sales']?.totals?.data[value]
        : 0;

      operating_expeense[value] = financialData?.['operating-expenses']?.totals?.data[value]
        ? financialData?.['operating-expenses']?.totals?.data[value]
        : 0;

      other_income_percentage[value] = handlePercentagePerTotal(value, other_income_total[value]);

      operating_expeense_percentage[value] = handlePercentagePerTotal(
        value,
        operating_expeense[value]
      );

      if (type == 'individual') {
        adjusted_ebitda_total[value] = calculateAdjustedEbitda(value, financialData);
        total_income[value] = calculateTotalIncome(value, financialData);
        taxableIncome[value] = calculateTaxableIncome(value, financialData);
        funds_available[value] = calculateDebtServicings(value, tax, financialData);
        tax_payable[value] = calculatePayableTaxPersonal(
          value,
          tax,
          financialData,
          setindividualtaxrateList
        );
        tax_payable_percentage[value] = handlePersonalPercentagePerTotal(value, tax_payable[value]);
        ebitda_total_val[value] = personalcalculateEbitda(value, financialData);
        ebitda_percentage[value] = handlePercentagePerTotal(value, financialData);
      } else {
        trending_income_total[value] = financialData?.['trading-income']?.totals?.data[value]
          ? financialData?.['trading-income']?.totals?.data[value]
          : 0;
        trending_income_percentage[value] = handleSalesGrowthPercentage(
          value,
          trending_income_total[value]
        );
        adjusted_ebitda_total[value] = calculateAdjustedEbitdaTax(value, financialData);
        if (
          selectedMember?.type == 'sole-trader' ||
          selectedMember?.type == 'partnership' ||
          selectedMember?.type == 'trust' ||
          selectedMember?.type == 'other'
        ) {
          funds_available[value] = calculateDebtServicingForTrust(value, tax, financialData);
        } else {
          funds_available[value] = calculateDebtServicing(
            value,
            tax,
            financialData,
            setindividualtaxrateList
          );
        }
        tax_payable[value] = calculatePayableTaxBusiness(value, tax, financialData);
        tax_payable_percentage[value] = handlePercentagePerTotal(value, tax_payable[value]);
        ebitda_total_val[value] = calculateEbitda(value, financialData);
        ebitda_percentage[value] = handlePercentagePerTotal(value, ebitda_total_val[value]);
        if (
          selectedMember.type == 'other' ||
          selectedMember.type == 'trust' ||
          selectedMember.type == 'sole-trader' ||
          selectedMember.type == 'partnership'
        ) {
          undistributed_profit_loss[value] = calculateDebtServicingForTrust(
            value,
            tax,
            financialData
          );
        }
      }
      ebit_total[value] = calculateEbit(value, financialData);
      ebit_percentage[value] = handlePercentagePerTotal(value, ebit_total[value]);
      adjusted_ebitda[value] = calculateAdjustedEbitdaTax(value, financialData);
      adjusted_percentage[value] = handlePercentagePerTotal(value, adjusted_ebitda[value]);
      grossProfitData[value] = calculateGrossProfit(value, financialData);
      grossProfitData_percentage[value] = handlePercentagePerTotal(value, grossProfitData[value]);
      netProfitBeforeTaxData[value] = calculateNetProfit(value, financialData);
      netProfitBeforeTaxData_percentage[value] = handlePercentagePerTotal(
        value,
        netProfitBeforeTaxData[value]
      );

      funds_after_tax[value] =
        financialData.type == 'trust' ||
        financialData.type == 'partnership' ||
        financialData.type == 'sole-trader' ||
        financialData.type == 'other'
          ? calculateDebtServicingForTrust(value, tax, financialData)
          : calculateFundsAfterTax(value, tax, financialData);
      funds_after_tax_percentage[value] = handlePercentagePerTotal(value, funds_after_tax[value]);
    });

    let trending_income_total_val = {
      key: 'total-trading-income-growth',
      title: 'Total Trading Income / % Growth',
      data: trending_income_total
    };

    let trending_income_percentage_val = {
      key: 'total-trading-income-growth',
      title: 'Total Trading Income / % Growth',
      data: trending_income_percentage
    };

    let cost_of_sales_total_val = {
      key: 'cost-of-sales',
      title: 'Cost of Sales',
      data: cost_of_sales_total
    };

    let other_income_total_val = {
      key: 'other-income',
      title: 'Other Income',
      data: other_income_total
    };

    let other_income_percentage_val = {
      key: 'total-other-income-percentage',
      title: 'Other Income',
      data: other_income_percentage
    };

    let operating_expense_percentage_val = {
      key: 'total-operating-expenses-percentage',
      title: 'Operating Expenses',
      data: operating_expeense_percentage
    };

    let operating_expense_total = {
      key: 'operating-expenses',
      title: 'Operating Expenses',
      data: operating_expeense
    };

    let ebitda_percentage_val = {
      title: 'EBITDA',
      key: 'total-ebitda-percentage',
      data: ebitda_percentage
    };

    let ebitda_total = {
      title: 'EBITDA',
      key: 'total-ebitda',
      data: ebitda_total_val
    };

    let funds_after_tax_val = {
      title: 'Funds Available for Debt Servicing (after Tax)',
      key: 'funds-after-tax',
      items: funds_after_tax
    };

    let funds_after_tax_percentage_val = {
      title: 'Funds Available for Debt Servicing (after Tax)',
      key: 'funds-after-tax-percentage',
      items: funds_after_tax_percentage
    };

    let tax_payable_val = {
      title: 'Tax Payable on Adjusted Taxable Income',
      key: 'tax-payable',
      items: tax_payable
    };

    let tax_payable_percentage_val = {
      title: 'Tax Payable on Adjusted Taxable Income',
      key: 'tax-payable-percentage',
      items: tax_payable_percentage
    };

    let netProfitBeforeTaxData_val = {
      title: 'Net Profit Before Tax',
      key: 'net-profit-before-tax',
      items: netProfitBeforeTaxData
    };

    let netProfitBeforeTaxDatapercentage_val = {
      title: 'Net Profit Before Tax',
      key: 'net-profit-before-tax',
      items: netProfitBeforeTaxData_percentage
    };

    let grossprofit = {
      title: 'Gross Profit',
      key: 'gross-profit',
      items: grossProfitData
    };
    let grossprofit_percentage = {
      title: 'Gross Profit',
      key: 'gross-profit-percentage',
      items: grossProfitData_percentage
    };
    let funds = {
      title: 'Funds available for Debt Servicing (after Tax, Dividends & Distributions)',
      key: 'funds-available-years',
      items: funds_available
    };
    let undistributed_profit = {
      title: 'Remaining Undistributed Profit/Loss',
      key: 'remaining-undistributed-profit-loss',
      items: undistributed_profit_loss
    };
    let adjustedEbitda = {
      title: 'Adjusted EBITDA',
      key: 'adjusted-ebitda-total',
      data: adjusted_ebitda_total
    };

    let adjusted_ebitda_val = {
      title: 'Adjusted EBITDA',
      key: 'adjusted-ebitda',
      items: adjusted_ebitda
    };

    let ebit_total_val = {
      title: 'EBIT',
      key: 'total-ebit',
      data: ebit_total
    };

    let ebit_percentage_val = {
      title: 'EBIT',
      key: 'ebit-percentage',
      data: ebit_percentage
    };

    let adjusted_percentage_val = {
      title: '',
      key: 'adjusted-percentage',
      items: adjusted_percentage
    };

    let total_income_val = {
      title: 'Total Income',
      key: 'total-income',
      items: total_income
    };

    let taxableIncome_val = {
      title: 'Taxable Income as per Tax Return',
      key: 'taxable-income-as-per-tax-return',
      items: taxableIncome
    };

    let finaldata = {
      ...financialData,
      ['tax']: {
        ...financialData['tax'],
        ['items']: tax_payable_val,
        ['tax-payable-percentage']: tax_payable_percentage_val
      },
      ['funds-available-years']: funds,
      ['undistributed_profit_loss']: undistributed_profit,
      ['funds-after-tax']: {
        ...funds_after_tax_val,
        ['funds-after-tax-percentage']: funds_after_tax_percentage_val
      },
      ['net-profit-before-tax']: {
        ...netProfitBeforeTaxData_val,
        ['net-profit-before-tax-percentage']: netProfitBeforeTaxDatapercentage_val
      },
      ['gross-profit']: {
        ...grossprofit,
        ['grossprofit-percentage']: grossprofit_percentage
      },
      ['adjusted-ebitda']: {
        ...financialData['adjusted-ebitda'],
        ['final-totals']: adjustedEbitda,
        ['adjusted-ebitda']: adjusted_ebitda_val,
        ['adjusted-ebitda-percentage']: adjusted_percentage_val
      },
      ['ebit']: {
        ...financialData['ebit'],
        ['ebit-percentage']: ebit_percentage_val,
        ['final-totals']: ebit_total_val
      },
      ['ebitda']: {
        ...financialData['ebitda'],
        ['final-totals']: ebitda_total,
        ['ebitda-percentage']: ebitda_percentage_val
      },
      ['trading-income']: {
        ...financialData['trading-income'],
        ['final-totals']: trending_income_total_val,
        ['percentage']: trending_income_percentage_val
      },
      ['cost-of-sales']: {
        ...financialData['cost-of-sales'],
        ['final-totals']: cost_of_sales_total_val
      },
      ['operating-expenses']: {
        ...financialData['operating-expenses'],
        ['final-totals']: operating_expense_total,
        ['percentage']: operating_expense_percentage_val
      },
      ['other-income']: {
        ...financialData['other-income'],
        ['final-totals']: other_income_total_val,
        ['percentage']: other_income_percentage_val
      },
      ['total-income']: total_income_val,
      ['taxable-income-as-per-tax-return']: taxableIncome_val
    };

    values.year_status = displayYear;
    delete finaldata.interest_pa;
    if (
      finaldata.type == 'trust' ||
      finaldata.type == 'partnership' ||
      finaldata.type == 'sole-trader' ||
      finaldata.type == 'other'
    ) {
      // Do not allow negative profit surplus allocation for Trust.
      let totalRam = false;
      years.map((value) => {
        if (
          finaldata.type == 'trust' &&
          finaldata['adjusted-ebitda']['final-totals'].data[value] < 0 &&
          finaldata['surplus_profit']['totals'].data[value] != 0
        )
          totalRam = true;
      });
      if (totalRam) {
        alert("Can't allocate surplus in loss for Trust.");
        return false;
      }

      // let fArray = finaldata['adjusted-ebitda']['final-totals'].filter(function (item) {
      //   return Object.keys(item).includes('key')
      //     ? ['franking-credits', 'franking-credits-received'].includes(item.key)
      //     : financialKeyValuePair[item.title] === 'franking-credits';
      // });
      // if (fArray.length > 0 && fArray[0].data[year]) {
      //   frankingCredits = fArray[0].data[year];
      // }
      if (finaldata?.surplus_profit?.items?.length === 1) {
        let valueSum = 0;
        years.map((value) => {
          valueSum += parseInt(finaldata.surplus_profit.items[0].data.percentage[value]);
        });

        if (
          finaldata.surplus_profit.items[0].title === 'Select if applicable' ||
          finaldata.surplus_profit.items[0].title === 'Select if Applicable'
        ) {
          let tempPercentage = finaldata.surplus_profit.items[0].data.percentage;
          let percentageValues = Object?.values(tempPercentage)?.filter((i) => Number(i) <= 0);
          if (percentageValues.length !== Object?.values(tempPercentage).length) {
            alert('Member is not assign to the surplus profit.');
            return false;
          }
        } else if (valueSum <= 0) {
          alert('Please add surplus values to the selected member.');
          return false;
        }
      } else {
        let unassignedItems = finaldata?.surplus_profit?.items?.filter(
          (i) => i.title === 'Select if applicable' || i.title === 'Select if Applicable'
        );

        if (finaldata?.surplus_profit && unassignedItems.length > 0) {
          alert('Member is not assign to the surplus profit.');
          return false;
        } else {
          let countEmptyPercentage = 0;

          finaldata.surplus_profit.items.map((item) => {
            let valueSum = 0;

            years.map((value) => {
              valueSum += parseInt(item.data.percentage[value] ? item.data.percentage[value] : 0);
            });

            if (valueSum <= 0) {
              countEmptyPercentage += 1;
            }
          });

          if (countEmptyPercentage > 0) {
            alert('Please add surplus values to the selected member.');
            return false;
          }
        }
      }
    }

    // Object.defineProperty(finaldata, 'finaldata.surplus_profit', {
    //   writable: true
    // });
    // finaldata.surplus_profit = {
    //   ...finaldata.surplus_profit,
    //   items: finaldata.surplus_profit.items.filter((i) => i.title !== 'Select if applicable')
    // };

    values.report_comments = values?.report_comments
      ? values?.report_comments.replace(/<[^>]+>/g, '').length === 0
        ? ''
        : values.report_comments
      : '';
    values.internal_notes = values?.internal_notes
      ? values.internal_notes.replace(/<[^>]+>/g, '').length === 0
        ? ''
        : values.internal_notes
      : '';
    // check validation
    // if (checkvalidation(finaldata)) {
    dispatch(updateFinancials(jobId, memberId, { ...finaldata }, values, setErrors));
    // }
  };

  // const checkvalidation = (finaldata) => {
  //   let checkvalidation = true;

  //   finaldata?.['adjusted-ebitda']?.items.map((val) => {
  //     if (!val.title) {
  //       checkvalidation = false;
  //     }
  //   });
  //   // if (type != 'individual') {
  //   //   finaldata?.['dividend']?.items.map((val) => {
  //   //     if (!val.title) {
  //   //       checkvalidation = false;
  //   //     }
  //   //   });
  //   // }

  //   return checkvalidation;
  // };

  const handlePercentagePerTotal = (year, value) => {
    if (financialData['trading-income']) {
      let totals = financialData['trading-income'].totals.data;
      return formatPercentage(value / totals[year]);
    }
    return 0;
  };
  const handleSalesGrowthPercentage = (year, value) => {
    let totals = financialData['trading-income'].totals.data;
    let previousValue = '';
    for (const [key, values] of Object.entries(totals)) {
      if (Number(key) === Number(year) && previousValue !== '') {
        return formatPercentage((value - previousValue) / previousValue);
      }
      previousValue = values;
    }
    return '';
  };

  const handlePersonalPercentagePerTotal = (year, value) => {
    let totals = financialData?.['related-party-income']?.totals?.data;
    return totals ? formatPercentage(value / totals[year]) : 0;
  };

  const handleRemoveRow = (index, data_key) => {
    let data = JSON.parse(JSON.stringify(financialData));
    data[data_key]['items'].splice(index, 1);
    if (data_key === 'other-income' && Object.keys(data_key).includes('items1'))
      data[data_key]['items1'].splice(index, 1);
    setFinancialData({ ...data });
    dispatch(deleteRecord(data));
  };

  const initialValue = {
    report_comments: reportComments,
    internal_notes: internalNotes,
    is_completed: 0
  };

  const handleonClick = () => {
    navigate('/job/' + jobId + '/group-setup/settings');
  };

  const xeroLogin = () => {
    localStorage.setItem('reimport', true);
    localStorage.setItem('month', monthNames[new Date().getMonth()].value);
    dispatch(getxero()).then((response) => {
      localStorage.setItem(
        'data',
        JSON.stringify({
          jobId: jobId,
          member: memberId
        })
      );
      openInNewTab(response?.data?.data);
    });
  };
  const openInNewTab = (url) => {
    window.open(url, '_self');
  };

  const editLayout = () => {
    navigate('/job/' + jobId + '/data-entry/' + memberId + '/edit-layout-financials');
  };

  const showModal = () => {
    setShowDelete(true);
  };

  const toggle432Dialog = () => {
    setShow432Dialog(!show432Dialog);
  };

  const dialog432Ok = () => {
    dispatch(setDialogOn432Error(''));
    toggle432Dialog();
  };

  const deleteFinancial = () => {
    setDisplayYears([]);
    dispatch(deletefinancial(jobId, memberId, {})).then(() => {
      setRedirectUrl(true);
    });
  };

  const handleCancel = () => {
    setShowDelete(false);
  };

  useEffect(() => {
    if (redirectUrl) navigate('/job/' + jobId + '/data-entry');
  }, [redirectUrl]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <strong>Financials</strong>
              </li>
            </ul>
            <h1>Financials - {displayNameFromType(selectedMember)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <button className="btn btn-primary" onClick={showModal}>
              Delete
            </button>
            {type !== 'individual' && (
              <button className="btn btn-primary" onClick={xeroLogin}>
                Xero - Reimport
              </button>
            )}
            {type === 'business' && (
              <button className="btn btn-primary" onClick={editLayout}>
                Edit Layout
              </button>
            )}
            <DialogModal
              title="Financials"
              p1={constants.fHeader}
              p2={constants.fTag}
              p3={constants.fOption1}
              p4={constants.fOption2}
              p5={constants.fOption3}
              p6={constants.fTag2}
            />
          </div>
        </div>
        <Formik
          initialValues={initialValue}
          onSubmit={handleFormSubmit}
          innerRef={formValues}
          enableReinitialize
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm
          }) => (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form
                key="financials"
                onSubmit={handleSubmit}
                noValidate
                className="security-form form-data mt-30"
              >
                {type === 'business' ? (
                  <BusinessFinancials
                    financialData={financialData}
                    setFinancialData={setFinancialData}
                    handleInputChange={handleInputChange}
                    handleRemove={handleRemoveRow}
                    displayYear={displayYear}
                    setDisplayYears={setDisplayYears}
                  />
                ) : (
                  <PersonalFinancials
                    financialData={financialData}
                    setFinancialData={setFinancialData}
                    handleInputChange={handleInputChange}
                    handleRemove={handleRemoveRow}
                    displayYear={displayYear}
                    setDisplayYears={setDisplayYears}
                    individualTaxRateList={setindividualtaxrateList}
                  />
                )}

                {financialData && Object.keys(financialData).length == 0 ? (
                  <>
                    <div className="white-card">
                      <p style={{ textAlign: 'center', margin: 0 }}>
                        Please setup
                        <a style={{ color: '#3278c2' }} onClick={handleonClick}>
                          {' Financial Year Structure '}
                        </a>
                        first.
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <CommentsNotes
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleInputChange={handleInputChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  resetForm={resetForm}
                />
                <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
              </Form>
            </>
          )}
        </Formik>
        <XeroimportModal
          show={showFinancialAnalysisPopup}
          onHide={handleFinancialAnalysisPopup}
          member={selectedMember}
        />
        <CustomModal
          value="delete"
          show={showDelete}
          title="Delete Financial?"
          text="<p>Deleting this member’s financials will remove any distributions, share of profits/losses and taxable amounts made to this member from the member(s) where they originated from.</p><p>It will also delete this member’s tax and you will need to delete this member’s facilities, securities, unencumbered property & rental (if applicable).</p><p>Are you sure you want to delete this member’s financials?</p>"
          cancel={handleCancel}
          success={deleteFinancial}
          isHtml={true}
          // reference={memberId}
        />
        {show432Dialog && (
          <Dialog432Modal
            value="ok"
            show={toggle432Dialog}
            title="Circular Allocation!"
            text={dialogOn432Error}
            success={dialog432Ok}
            isHtml={false}
            // reference={memberId}
          />
        )}
      </div>
    </div>
  );
};

export default Financials;
