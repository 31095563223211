import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';

import { combineReducers } from 'redux';
import throttle from 'lodash/throttle';
import appReducer from './slices/AppSlice';
import authReducer from './slices/AuthSlice';
import settingReducer from './slices/SettingSlice';
import jobReducer from './slices/JobSlice';
import jobSetupReducer from './slices/JobSetupSlice';
import facilityReducer from './slices/FacilitySlice';
import securityReducer from './slices/SecuritySlice';
import livingExpenseReducer from './slices/LivingExpenseSlice';
import businessOverviewReducer from './slices/BusinessOverviewSlice';
import RentalReducer from './slices/RentalSlice';
import unencumberedPropertyReducer from './slices/UnencumberedPropertySlice';
import corporateStructureReducer from './slices/CorporateStuctureSlice';
import recommendationReducer from './slices/RecommendationSlice';
import dataEntryReducer from './slices/DataEntrySlice';
import financialsReducer from './slices/FinancialsSlice';
import taxReducer from './slices/TaxSlice';
import guidanceReducer from './slices/GuidanceSlice';
import ProposedTransactionReducer from './slices/ProposedTransactionSlice';
import CommonReducer from './slices/CommonSlice';
import GroupFinancialReducer from './slices/GroupFinancialSlice';
import PracticeSettingReducer from './slices/PracticeSettingSlice';
import ProposedFacilitiesSecurityReducer from './slices/ProposedFacilitiesSecuritySlice';
import ProposedDebtServicingReducer from './slices/ProposedDebtServicingSlice';
import SensitisedDebtServicingReducer from './slices/SensitisedDebtServicingSlice';
import OverallAssessmentReducer from './slices/OverallAssessmentSlice';
import ReportReducer from './slices/ReportSlice';
import TaxCalculationReducer from './slices/TaxCalculationSlice';
import GroupServiceability from './slices/GroupServiceabilitySlice';
import StaffManagement from './slices/StaffManagementSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  setting: settingReducer,
  job: jobReducer,
  jobSetup: jobSetupReducer,
  facility: facilityReducer,
  security: securityReducer,
  livingExpense: livingExpenseReducer,
  businessOverview: businessOverviewReducer,
  rental: RentalReducer,
  unencumberedProperty: unencumberedPropertyReducer,
  corporateStructure: corporateStructureReducer,
  recommendation: recommendationReducer,
  dataEntry: dataEntryReducer,
  financials: financialsReducer,
  tax: taxReducer,
  guidance: guidanceReducer,
  proposedtransaction: ProposedTransactionReducer,
  common: CommonReducer,
  groupFinancial: GroupFinancialReducer,
  practiceSetting: PracticeSettingReducer,
  proposedFacilitiesSecurity: ProposedFacilitiesSecurityReducer,
  proposedDebtServicing: ProposedDebtServicingReducer,
  sensitisedDebtServicing: SensitisedDebtServicingReducer,
  overallassessment: OverallAssessmentReducer,
  report: ReportReducer,
  taxCalculation: TaxCalculationReducer,
  groupServiceability: GroupServiceability,
  staffManagement: StaffManagement
});

const preloadedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: ['app/setHandleResponse']
        // Ignore these paths in the state
      }
    })
});

store.subscribe(
  throttle(() => {
    saveState({
      app: store.getState().app,
      auth: store.getState().auth,
      setting: store.getState().setting,
      job: store.getState().job,
      jobSetup: store.getState().jobSetup,
      facility: store.getState().facility,
      security: store.getState().security,
      livingExpense: store.getState().livingExpense,
      businessOverview: store.getState().businessOverview,
      rental: store.getState().rental,
      unencumberedProperty: store.getState().unencumberedProperty,
      dataEntry: store.getState().dataEntry,
      financials: store.getState().financials,
      tax: store.getState().tax,
      proposedtransaction: store.getState().proposedtransaction,
      groupFinancial: store.getState().groupFinancial,
      proposedFacilitiesSecurity: store.getState().proposedFacilitiesSecurity,
      proposedDebtServicing: store.getState().proposedDebtServicing,
      sensitisedDebtServicing: store.getState().sensitisedDebtServicing,
      overallassessment: store.getState().overallassessment,
      report: store.getState().report,
      taxCalculation: store.getState().report,
      groupServiceability: store.getState().groupServiceability,
      staffManagement: store.getState().staffManagement
    });
  }, 5000)
);

export default store;
