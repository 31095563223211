import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import Button from '../../../components/Button';
import {
  reportState,
  getReportData,
  addReportData,
  setAddReportResponse
} from '../../../slices/slices/ReportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const bankAbilityData = [
  {
    name: 'Cover',
    value: 'cover'
    // isCompleted: null
  },
  {
    name: 'Table of Contents',
    value: 'table_contents'
    // isCompleted: null
  },
  {
    name: 'Executive Summary',
    value: 'executive_summary'
    // isCompleted: null
  },
  {
    name: 'Overall Assessment',
    value: 'overall_assessment'
    // isCompleted: true
  },
  {
    name: 'Recommendation',
    value: 'recommendation'
    // isCompleted: false
  },
  {
    name: 'Pathway to Approval',
    value: 'pathway_to_approval'
    // isCompleted: true
  },
  {
    name: 'Group Structure',
    value: 'corporate_structure'
    // isCompleted: true
  },
  {
    name: 'Proposed Transaction',
    value: 'proposed_transaction'
    // isCompleted: false
  },
  {
    name: 'Proposed Facilities & Securities',
    value: 'proposed_facilities_securities'
    // isCompleted: true
  },
  {
    name: 'Unencumbered Property',
    value: 'unencumbered_property'
    // isCompleted: true
  },
  {
    name: 'Current Rental Income',
    value: 'current_rental_income'
    // isCompleted: true
  },
  {
    name: 'Business Financial Analysis',
    value: 'business_financial_analysis'
    // isCompleted: false
  },
  {
    name: 'Personal Financial Analysis',
    value: 'personal_financial_analysis'
    // isCompleted: true
  },
  {
    name: 'Proposed Debt Servicing Calculations',
    value: 'proposed_debt_servicing_calculations'
    // isCompleted: true
  },
  {
    name: 'Sensitised Debt Servicing Calculations',
    value: 'sensitised_debt_servicing_calculations'
    // isCompleted: false
  },
  {
    name: 'Group Serviceability',
    value: 'group_serviceability'
    // isCompleted: true
  }
];

const discussionData = [
  {
    name: 'Cover',
    value: 'cover'
    // isCompleted: null
  },
  {
    name: 'Table of Contents',
    value: 'table_contents'
    // isCompleted: null
  },
  {
    name: 'Executive Summary',
    value: 'executive_summary'
    // isCompleted: null
  },
  {
    name: 'Guidance and Direction',
    value: 'guidance_direction'
    // isCompleted: true
  },
  {
    name: 'Business Overview',
    value: 'business_overview'
    // isCompleted: true
  },
  {
    name: 'Group Structure',
    value: 'corporate_structure'
    // isCompleted: false
  },
  {
    name: 'Proposed Transaction',
    value: 'proposed_transaction'
    // isCompleted: false
  },
  {
    name: 'Proposed Facilities & Securities',
    value: 'proposed_facilities_securities'
    // isCompleted: true
  },
  {
    name: 'Unencumbered Property',
    value: 'unencumbered_property'
    // isCompleted: true
  },
  {
    name: 'Current Rental Income',
    value: 'current_rental_income'
    // isCompleted: true
  },
  {
    name: 'Business Financial Analysis',
    value: 'business_financial_analysis'
    // isCompleted: false
  },
  {
    name: 'Personal Financial Analysis',
    value: 'personal_financial_analysis'
    // isCompleted: true
  },
  {
    name: 'Proposed Debt Servicing Calculations',
    value: 'proposed_debt_servicing_calculations'
    // isCompleted: true
  },
  {
    name: 'Sensitised Debt Servicing Calculations',
    value: 'sensitised_debt_servicing_calculations'
    // isCompleted: false
  },
  {
    name: 'Group Serviceability',
    value: 'group_serviceability'
    // isCompleted: true
  }
];

const financialData = [
  {
    name: 'Cover',
    value: 'cover'
    // isCompleted: null
  },
  {
    name: 'Table of Contents',
    value: 'table_contents'
    // isCompleted: null
  },
  {
    name: 'Executive Summary',
    value: 'executive_summary'
    // isCompleted: null
  },
  {
    name: 'Business Overview',
    value: 'business_overview'
    // isCompleted: true
  },
  {
    name: 'Group Structure',
    value: 'corporate_structure'
    // isCompleted: false
  },
  {
    name: 'Proposed Transaction',
    value: 'proposed_transaction'
    // isCompleted: false
  },
  {
    name: 'Proposed Facilities & Securities',
    value: 'proposed_facilities_securities'
    // isCompleted: true
  },
  {
    name: 'Unencumbered Property',
    value: 'unencumbered_property'
    // isCompleted: true
  },
  {
    name: 'Current Rental Income',
    value: 'current_rental_income'
    // isCompleted: true
  },
  {
    name: 'Business Financial Analysis',
    value: 'business_financial_analysis'
    // isCompleted: false
  },
  {
    name: 'Personal Financial Analysis',
    value: 'personal_financial_analysis'
    // isCompleted: true
  },
  {
    name: 'Proposed Debt Servicing Calculations',
    value: 'proposed_debt_servicing_calculations'
    // isCompleted: true
  },
  {
    name: 'Sensitised Debt Servicing Calculations',
    value: 'sensitised_debt_servicing_calculations'
    // isCompleted: false
  },
  {
    name: 'Group Serviceability',
    value: 'group_serviceability'
    // isCompleted: true
  }
];

const appendices = [
  {
    name: 'Tax Calculations',
    value: 'tax_calculation'
    // isCompleted: true
  }
];

const NewReport = () => {
  const { reportDataResponse, addReportResponse } = useSelector(reportState);
  const { jobId } = useParams();
  const dispatch = useDispatch();

  const [checkedBankAbilityData, setCheckedBankAbilityData] = useState(
    new Array(bankAbilityData.length).fill(true)
  );
  const [checkedAppendix, setCheckedAppendix] = useState(new Array(appendices.length).fill(true));

  const [checkedDiscussionData, setCheckedDiscussionData] = useState(
    new Array(discussionData.length).fill(true)
  );
  const [checkedAppendixForDiscussion, setCheckedAppendixForDiscussion] = useState(
    new Array(appendices.length).fill(true)
  );

  const [checkedFinancialData, setCheckedFinancialData] = useState(
    new Array(financialData.length).fill(true)
  );
  const [checkedAppendixForFinacial, setCheckedAppendixForFinancial] = useState(
    new Array(appendices.length).fill(true)
  );

  useEffect(() => {
    if (reportDataResponse?.length > 0) {
      let checkedAppendixArray = JSON.parse(JSON.stringify(checkedBankAbilityData));
      let checkedAppendicesArray = JSON.parse(JSON.stringify(checkedAppendix));
      bankAbilityData.map((item, index) => {
        item.is_disabled = reportDataResponse.find((el) => el.key == item.value)?.is_disabled
          ? true
          : false;
        checkedAppendixArray[index] = reportDataResponse.find((el) => el.key == item.value)
          ?.is_disabled
          ? false
          : true;
      });
      setCheckedBankAbilityData([...checkedAppendixArray]);
      appendices.map((item, index) => {
        item.is_disabled = reportDataResponse.find((el) => el.key == item.value)?.is_disabled
          ? true
          : false;
        checkedAppendicesArray[index] = reportDataResponse.find((el) => el.key == item.value)
          ?.is_disabled
          ? false
          : true;
      });
      setCheckedAppendix([...checkedAppendicesArray]);
      setCheckedAppendixForDiscussion([...checkedAppendicesArray]);
      setCheckedAppendixForFinancial([...checkedAppendicesArray]);

      let checkedDiscussionDataArray = JSON.parse(JSON.stringify(checkedDiscussionData));
      discussionData.map((item, index) => {
        item.is_disabled = reportDataResponse.find((el) => el.key == item.value)?.is_disabled
          ? true
          : false;
        checkedDiscussionDataArray[index] = reportDataResponse.find((el) => el.key == item.value)
          ?.is_disabled
          ? false
          : true;
      });
      setCheckedDiscussionData([...checkedDiscussionDataArray]);
      let checkedFinancialDataArray = JSON.parse(JSON.stringify(checkedFinancialData));
      financialData.map((item, index) => {
        item.is_disabled = reportDataResponse.find((el) => el.key == item.value)?.is_disabled
          ? true
          : false;
        checkedFinancialDataArray[index] = reportDataResponse.find((el) => el.key == item.value)
          ?.is_disabled
          ? false
          : true;
      });
      setCheckedFinancialData([...checkedFinancialDataArray]);
    }
  }, [reportDataResponse]);

  const handleFormSubmit = (values, { setErrors }) => {
    const data = checkedBankAbilityData.map((value, index) => {
      return value === true ? bankAbilityData[index]?.value : 'false';
    });
    const data1 = checkedAppendix.map((value, index) => {
      return value === true ? appendices[index]?.value : 'false';
    });

    values.report_module = [
      ...data.filter((item) => item !== 'false'),
      ...data1.filter((item) => item !== 'false')
    ];

    let status = false;
    values.report_module &&
      values.report_module.map((item) => {
        reportDataResponse &&
          reportDataResponse?.length > 0 &&
          reportDataResponse?.map((report) => {
            if (report?.key.toLowerCase() == item.toLowerCase()) {
              if (report.is_completed == 0) {
                status = true;
              }
            }
          });
      });
    values.is_draft = status;
    values.report_type = 'bankability_assessment';
    dispatch(addReportData(jobId, values, setErrors));
  };

  const handleFormSubmitFroDiscussion = (values, { setErrors }) => {
    const data = checkedDiscussionData.map((value, index) => {
      return value === true ? discussionData[index]?.value : 'false';
    });
    const data1 = checkedAppendixForDiscussion.map((value, index) => {
      return value === true ? appendices[index]?.value : 'false';
    });

    values.report_module = [
      ...data.filter((item) => item !== 'false'),
      ...data1.filter((item) => item !== 'false')
    ];

    let status = false;
    values.report_module &&
      values.report_module.map((item) => {
        reportDataResponse &&
          reportDataResponse?.length > 0 &&
          reportDataResponse?.map((report) => {
            if (report?.key.toLowerCase() == item.toLowerCase()) {
              if (report.is_completed == 0) {
                status = true;
              }
            }
          });
      });
    values.is_draft = status;
    values.report_type = 'discussion_paper';
    dispatch(addReportData(jobId, values, setErrors));
  };

  const handleFormSubmitFroFinancial = (values, { setErrors }) => {
    const data = checkedFinancialData.map((value, index) => {
      return value === true ? financialData[index]?.value : 'false';
    });
    const data1 = checkedAppendixForFinacial.map((value, index) => {
      return value === true ? appendices[index]?.value : 'false';
    });
    values.report_module = [
      ...data.filter((item) => item !== 'false'),
      ...data1.filter((item) => item !== 'false')
    ];
    let status = false;
    values.report_module &&
      values.report_module.map((item) => {
        reportDataResponse &&
          reportDataResponse?.length > 0 &&
          reportDataResponse?.map((report) => {
            if (report?.key.toLowerCase() == item.toLowerCase()) {
              if (report.is_completed == 0) {
                status = true;
              }
            }
          });
      });
    values.is_draft = status;
    values.report_type = 'finance_proposal';
    dispatch(addReportData(jobId, values, setErrors));
  };

  useEffect(() => {
    // dispatch(getReportData(jobId));
    if (addReportResponse?.message) {
      dispatch(setAddReportResponse({}));
      // navigate('/job/' + jobId + '/data-entry/' + memberId + '/rentals');
    }
  }, [addReportResponse?.message]);

  useEffect(() => {
    dispatch(getReportData(jobId));
  }, []);

  const handleOnChange = (position) => {
    const updatedBankAbilityDataCheckedState = checkedBankAbilityData.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedBankAbilityData(updatedBankAbilityDataCheckedState);
  };

  const handleOnChangeForAppendix = (position) => {
    const updatedAppendixCheckedState = checkedAppendix.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedAppendix(updatedAppendixCheckedState);
  };

  const handleChangeForDiscussion = (position) => {
    const updatedDiscussionCheckedState = checkedDiscussionData.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedDiscussionData(updatedDiscussionCheckedState);
  };

  const handleChangeForDiscussionAppendix = (position) => {
    const updatedAppendixCheckedState = checkedAppendixForDiscussion.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedAppendixForDiscussion(updatedAppendixCheckedState);
  };

  const handleChangeForFinancial = (position) => {
    const updatedDiscussionCheckedState = checkedFinancialData.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedFinancialData(updatedDiscussionCheckedState);
  };

  const handleChangeForFinancialAppendix = (position) => {
    const updatedAppendixCheckedState = checkedAppendixForFinacial.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedAppendixForFinancial(updatedAppendixCheckedState);
  };

  const initialValue = {
    report_module: [''],
    report_type: ''
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="#">Group Reports</a>
              </li>
              <li>
                <strong>New</strong>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="facility-form form-data">
            <div className="pagetitle proversion">
              <div className="title_pro d-flex justify-content-between align-items-center">
                <h1>Reports</h1>
                <div className="report-legend">
                  <ul>
                    <li className="include">Include in Report</li>
                    <li className="completed">Completed</li>
                    <li className="incompleted">Incomplete</li>
                    <li className="not-applicable">Not Applicable</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="white-card">
              <div className="row">
                <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
                  {({ handleSubmit }) => {
                    return (
                      <div className="col-md-4">
                        <Form onSubmit={handleSubmit}>
                          <div className="report-block">
                            <h4 className="secondary_color">Bankability Assessment</h4>
                            <ul>
                              {bankAbilityData?.map((item, index) => {
                                let status = 0;
                                return (
                                  <>
                                    {reportDataResponse &&
                                      reportDataResponse?.length > 0 &&
                                      reportDataResponse?.map((report) => {
                                        if (
                                          report?.key.toLowerCase() == item?.value.toLowerCase()
                                        ) {
                                          status = report.is_completed;
                                        }
                                      })}
                                    <li
                                      key={index}
                                      className={
                                        status === 1
                                          ? 'completed'
                                          : status === -2
                                          ? 'not-applicable'
                                          : 'incompleted'
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        id={item?.name + 'b'}
                                        checked={checkedBankAbilityData[index]}
                                        disabled={item?.is_disabled}
                                        value={item?.value}
                                        onChange={() => {
                                          handleOnChange(index);
                                        }}
                                      />
                                      <label htmlFor={item?.name + 'b'}>{item?.name}</label>
                                    </li>
                                  </>
                                );
                              })}
                              <h6>Appendix</h6>
                              {appendices.map((item, index) => {
                                let status = 0;
                                return (
                                  <>
                                    {reportDataResponse && reportDataResponse?.length > 0
                                      ? reportDataResponse?.map((report) => {
                                          report?.key === item?.value &&
                                            (status = report?.is_completed);
                                        })
                                      : null}
                                    <li
                                      key={index}
                                      className={
                                        status === 1
                                          ? 'completed'
                                          : status === -2
                                          ? 'not-applicable'
                                          : 'incompleted'
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        id={item.name + 'b'}
                                        checked={checkedAppendix[index]}
                                        disabled={item?.is_disabled}
                                        value={item?.value}
                                        onChange={() => {
                                          handleOnChangeForAppendix(index);
                                        }}
                                      />
                                      <label
                                        style={{ paddingLeft: '10px' }}
                                        htmlFor={item.name + 'b'}
                                      >
                                        {item.name}
                                      </label>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                            <Button type="submit" className="secondary_color">
                              Generate Report
                            </Button>
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
                <Formik
                  initialValues={initialValue}
                  onSubmit={handleFormSubmitFroDiscussion}
                  enableReinitialize
                >
                  {({ handleSubmit }) => {
                    return (
                      <div className="col-md-4">
                        <Form onSubmit={handleSubmit}>
                          <div className="report-block">
                            <h4 className="default_color">Discussion Paper</h4>
                            <ul>
                              {discussionData.map((item, index) => {
                                let status = 0;
                                return (
                                  <>
                                    {reportDataResponse &&
                                      reportDataResponse?.length > 0 &&
                                      reportDataResponse?.map((report) => {
                                        if (
                                          report?.key.toLowerCase() == item?.value.toLowerCase()
                                        ) {
                                          status = report.is_completed;
                                        }
                                      })}
                                    <li
                                      className={
                                        status === 1
                                          ? 'completed'
                                          : status === -2
                                          ? 'not-applicable'
                                          : 'incompleted'
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        id={item.name + 'd'}
                                        checked={checkedDiscussionData[index]}
                                        disabled={item?.is_disabled}
                                        value={item?.value}
                                        onChange={() => {
                                          handleChangeForDiscussion(index);
                                        }}
                                      />
                                      <label htmlFor={item.name + 'd'}>{item.name}</label>
                                    </li>
                                  </>
                                );
                              })}
                              <h6>Appendix</h6>
                              {appendices.map((item, index) => {
                                let status = 0;
                                return (
                                  <>
                                    {reportDataResponse && reportDataResponse?.length > 0
                                      ? reportDataResponse?.map((report) => {
                                          report?.key === item?.value &&
                                            (status = report?.is_completed);
                                        })
                                      : null}
                                    <li
                                      className={
                                        status === 1
                                          ? 'completed'
                                          : status === -2
                                          ? 'not-applicable'
                                          : 'incompleted'
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        id={item.name + 'd'}
                                        checked={checkedAppendixForDiscussion[index]}
                                        disabled={item?.is_disabled}
                                        value={item?.value}
                                        onChange={() => {
                                          handleChangeForDiscussionAppendix(index);
                                        }}
                                      />

                                      <label
                                        style={{ marginLeft: '10px' }}
                                        htmlFor={item.name + 'd'}
                                      >
                                        {item.name}
                                      </label>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>

                            <div className="p-2 d-flex justify-content-center">
                              <Button
                                type="submit"
                                className="btn px-4 default_color"
                                style={{ color: '#FFF' }}
                              >
                                Generate Report
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
                <Formik
                  initialValues={initialValue}
                  onSubmit={handleFormSubmitFroFinancial}
                  enableReinitialize
                >
                  {({ handleSubmit }) => {
                    return (
                      <div className="col-md-4">
                        <Form onSubmit={handleSubmit}>
                          <div className="report-block">
                            <h4 style={{ background: '#2BB673', color: '#ffffff' }}>
                              Financial Proposal
                            </h4>
                            <ul>
                              {financialData.map((item, index) => {
                                let status = 0;
                                return (
                                  <>
                                    {reportDataResponse &&
                                      reportDataResponse?.length > 0 &&
                                      reportDataResponse?.map((report) => {
                                        if (
                                          report?.key.toLowerCase() == item?.value.toLowerCase()
                                        ) {
                                          status = report.is_completed;
                                        }
                                      })}

                                    <li
                                      className={
                                        status === 1
                                          ? 'completed'
                                          : status === -2
                                          ? 'not-applicable'
                                          : 'incompleted'
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        id={item.name + 'f'}
                                        checked={checkedFinancialData[index]}
                                        disabled={item?.is_disabled}
                                        value={item?.value}
                                        onChange={() => {
                                          handleChangeForFinancial(index);
                                        }}
                                      />
                                      <label htmlFor={item.name + 'f'}>{item.name}</label>
                                    </li>
                                  </>
                                );
                              })}
                              <h6>Appendix</h6>
                              {appendices.map((item, index) => {
                                let status = 0;
                                return (
                                  <>
                                    {reportDataResponse && reportDataResponse?.length > 0
                                      ? reportDataResponse?.map((report) => {
                                          report?.key === item?.value &&
                                            (status = report?.is_completed);
                                        })
                                      : null}
                                    <li
                                      className={
                                        status === 1
                                          ? 'completed'
                                          : status === -2
                                          ? 'not-applicable'
                                          : 'incompleted'
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        id={item.name + 'f'}
                                        checked={checkedAppendixForFinacial[index]}
                                        disabled={item?.is_disabled}
                                        value={item?.value}
                                        onChange={() => {
                                          handleChangeForFinancialAppendix(index);
                                        }}
                                      />
                                      <label
                                        key={index}
                                        style={{ marginLeft: '10px' }}
                                        htmlFor={item.name + 'f'}
                                      >
                                        {item.name}
                                      </label>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>

                            <div className="p-2 d-flex justify-content-center">
                              <Button type="submit" className="btn px-4 green">
                                Generate Report
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewReport;
