import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import Category from './components/Category';
import { financialsState } from '../../../../slices/slices/FinancialsSlice';
import { getJobSettingsData, jobSetupState } from '../../../../slices/slices/JobSetupSlice';
import EBITDA from './components/EBITDA';
import AdjustedEBITDA from './components/AdjustedEBITDA';
import {
  formatNumber
  //  formatPercentage
} from '../../../common/Misc';
import { yearStatus } from '../../../../utils/constant';
import {
  /* calculateDebtServicing, */
  calculateDebtServicings,
  calculateEbitda,
  calculateTaxableIncome,
  calculateTotalIncome,
  calculateAdjustedEbitda,
  calculatePayableTaxPersonal
} from '../../../../utils/personalDataEntryCalculations';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const PersonalFinancials = (props) => {
  const {
    setFinancialData,
    financialData,
    handleRemove,
    handleInputChange,
    setDisplayYears,
    displayYear,
    individualTaxRateList
  } = props;
  const dispatch = useDispatch();
  const { tax, years } = useSelector(financialsState);
  const finacialItem = useSelector(financialsState);
  const [setting, setSettingData] = useState({});
  const { jobId, memberId } = useParams();
  let defaultYears = {};
  years?.map((item) => (defaultYears[item] = false));
  const [changeYear, setChangeYear] = useState(defaultYears);

  const { jobSettingsDataResponse } = useSelector(jobSetupState);

  const addOptions = [
    'Select if Applicable',
    'Donations',
    'Partnership & Trust Loss',
    'Voluntary Superannuation',
    'Other'
  ];
  const lessOptions = [
    'Select if Applicable',
    'Capital Gain',
    'Distribution / Dividend Received',
    'ETP',
    'Partnership & Trust Profit',
    'Profit on Sale of Asset',
    'Other'
  ];

  // const handlePercentagePerTotal = (year, value) => {
  //   let totals = financialData?.['related-party-income']?.totals?.data;
  //   return totals ? formatPercentage(value / totals[year]) : 0;
  // };

  // const handlePercentagePerTotal = (year, value) => {
  //   let totals = financialData['related-party-income'].totals.data;
  //   return formatPercentage(value / totals[year]);
  // };

  useEffect(() => {
    setSettingData(jobSettingsDataResponse);
  }, [jobSettingsDataResponse]);

  useEffect(() => {
    setFinancialData(finacialItem.financialData);
    if (
      !displayYear &&
      (!finacialItem?.year_status || Object.keys(finacialItem?.year_status).length == 0)
    ) {
      let financialYears = {};
      years?.map((value) => {
        if (
          Number(setting?.current_financial_year) === Number(value)
          // &&setting?.select_following_option === 'annualise-management-reports'
        ) {
          financialYears[value] = 'Extrap';
        } else {
          financialYears[value] = 'Actual';
        }
      });
      setDisplayYears(financialYears);
    } else if (!Object.values(changeYear)?.includes(true) && finacialItem?.year_status) {
      setDisplayYears(finacialItem?.year_status);
    }
  }, [finacialItem, setting]);

  useEffect(() => {
    dispatch(getJobSettingsData(jobId));
  }, []);
  const handleYearChange = (e, year) => {
    let yearStatus = JSON.parse(JSON.stringify(displayYear));
    let changeYearData = { ...changeYear };

    years?.map((item) => {
      if (item === year) {
        yearStatus[item] = e.target.value;
        changeYearData[item] = true;
      } else if (yearStatus[item] == null) {
        changeYearData[item] = false;
        if (
          Number(setting?.current_financial_year) === Number(item)
          // &&setting?.select_following_option === 'annualise-management-reports'
        ) {
          yearStatus[item] = 'Extrap';
        } else {
          yearStatus[item] = 'Actual';
        }
      }
    });
    setChangeYear(changeYearData);
    setDisplayYears(yearStatus);
  };

  return (
    <div className="financial-table" key={1}>
      {Object.entries(financialData).length > 0 ? (
        <Table cellPadding="0" cellSpacing="0" border="0" width="100%" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">Personal Financial Analysis</Table.HeaderCell>
              {years.map((value, index) => {
                return (
                  <>
                    <Table.HeaderCell key={index}>
                      {value} <br />
                      <select
                        value={displayYear[value]}
                        onChange={(e) => handleYearChange(e, value)}
                      >
                        {Number(setting?.include_current_financial_year) === 1 &&
                          Number(setting?.current_financial_year) === Number(value) && (
                            <option key={'Extrap'} value="Extrap" selected="selected">
                              Extrap
                            </option>
                          )}
                        {yearStatus.map((yearStatus) => {
                          return (
                            <option key={yearStatus.value} value={yearStatus.value}>
                              {yearStatus.value}
                            </option>
                          );
                        })}
                      </select>
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.entries(financialData).length > 0 ? (
              <>
                <Category
                  key="related-party-income"
                  data={financialData['related-party-income']}
                  years={years}
                  handleRemove={handleRemove}
                  handleInputChange={handleInputChange}
                />
                <Category
                  key="other-income"
                  data={financialData['other-income']}
                  years={years}
                  handleRemove={handleRemove}
                  handleInputChange={handleInputChange}
                />
                <Table.Row key="total-income" className="total_row">
                  <Table.Cell colSpan="2">
                    <strong>Total Income</strong>
                  </Table.Cell>
                  {years.map((value, index) => {
                    let totalIncome = calculateTotalIncome(value, financialData);
                    return (
                      <>
                        <Table.Cell key={index} className="align-right">
                          <span>
                            <strong>{formatNumber(totalIncome)}</strong>
                          </span>
                        </Table.Cell>
                        <Table.Cell />
                      </>
                    );
                  })}
                </Table.Row>
                <Category
                  key="deductions"
                  data={financialData['deductions']}
                  years={years}
                  negative={true}
                  handleRemove={handleRemove}
                  handleInputChange={handleInputChange}
                />
                <Table.Row key="taxable-income-total" className="total_row">
                  <Table.Cell colSpan="2">
                    <strong>Taxable Income as per Tax Return</strong>
                  </Table.Cell>
                  {years.map((value, index) => {
                    let taxableIncome = calculateTaxableIncome(value, financialData);
                    return (
                      <>
                        <Table.Cell key={index} className="align-right">
                          <span>
                            <strong>{formatNumber(taxableIncome)}</strong>
                          </span>
                        </Table.Cell>
                        <Table.Cell>
                          <span className="blue_text">
                            {/* {handlePercentagePerTotal(value, taxableIncome)} */}
                          </span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                <EBITDA
                  data={financialData['ebitda']}
                  years={years}
                  handleRemove={handleRemove}
                  handleInputChange={handleInputChange}
                  categoryList={[
                    {
                      key: 'add',
                      name: 'Add Back',
                      option: null,
                      negative: false
                    }
                  ]}
                />
                <Table.Row key="ebitda-total" className="total_row">
                  <Table.Cell colSpan="2">
                    <strong>EBITDA</strong>
                  </Table.Cell>
                  {years.map((value, index) => {
                    let ebitda = calculateEbitda(value, financialData);
                    return (
                      <>
                        <Table.Cell key={index} className="align-right">
                          <span>
                            <strong>{formatNumber(ebitda)}</strong>
                          </span>
                        </Table.Cell>
                        <Table.Cell>
                          <span className="blue_text">
                            {/* {handlePercentagePerTotal(value, ebitda)} */}
                          </span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                <AdjustedEBITDA
                  data={financialData['adjusted-ebitda']}
                  years={years}
                  handleRemove={handleRemove}
                  handleInputChange={handleInputChange}
                  categoryList={[
                    {
                      key: 'add',
                      name: 'Add Back',
                      option: addOptions,
                      negative: false
                    },
                    {
                      key: 'less',
                      name: 'Less',
                      option: lessOptions,
                      negative: true
                    },
                    {
                      key: 'rental',
                      name: 'Rental',
                      option: null,
                      negative: false
                    }
                  ]}
                />
                <Table.Row key="adjusted-ebitda-total" className="total_row">
                  <Table.Cell colSpan="2">
                    <strong>Adjusted EBITDA</strong>
                  </Table.Cell>
                  {years.map((value, index) => {
                    let adjusted = calculateAdjustedEbitda(value, financialData);
                    return (
                      <>
                        <Table.Cell key={index} className="align-right">
                          <span>
                            <strong>{formatNumber(adjusted)}</strong>
                          </span>
                        </Table.Cell>
                        <Table.Cell>
                          <span className="blue_text">
                            {/* {handlePercentagePerTotal(value, adjusted)} */}
                          </span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                <Table.Row key="tax-payable-total">
                  <Table.Cell colSpan="2">
                    <a
                      className="link"
                      href={'/job/' + jobId + '/data-entry/' + memberId + '/tax'}
                      rel="noreferrer"
                    >
                      Tax Payable on Adjusted Taxable Income
                    </a>
                  </Table.Cell>
                  {years.map((value, index) => {
                    let taxValue = calculatePayableTaxPersonal(
                      value,
                      tax,
                      financialData,
                      individualTaxRateList
                    );
                    return (
                      <>
                        <Table.Cell key={index} className="align-right">
                          <span>{formatNumber(-taxValue)}</span>
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
              </>
            ) : null}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="2">
                <strong>
                  Funds available for Debt Servicing (after Tax, Dividends &amp; Distributions)
                </strong>
              </Table.Cell>
              {years.map((value, index) => {
                let debtServicing = calculateDebtServicings(
                  value,
                  tax,
                  financialData,
                  individualTaxRateList
                );
                return (
                  <>
                    <Table.Cell key={index} className="align-right">
                      <span>{formatNumber(debtServicing)}</span>
                    </Table.Cell>
                    <Table.Cell />
                  </>
                );
              })}
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : null}
    </div>
  );
};

export default PersonalFinancials;
