import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Header';
import ProposedFacilitiesTab from '../../../../components/job/groupAnalysis/proposedFacilities/ProposedFacilitiesTab';
import { Link, useParams } from 'react-router-dom';
import Info from '../../../../components/Info';
import { getMembers, jobSetupState } from '../../../../slices/slices/JobSetupSlice';
import MemberModel from '../../../../components/MemberModel';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType } from '../../../common/Misc';
import { useNavigatingAway } from '../../../../pages/common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../../pages/common/components/DialogLeavingPage';

export default function ProposedFacilities() {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showMemberForm, setshowMemberForm] = useState(false);
  const [type, setType] = useState();
  const { membersData } = useSelector(jobSetupState);

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleOnclick = (tab) => {
    setActiveTab(tab);
  };

  const handleMemberForm = () => {
    setshowMemberForm(false);
  };

  useEffect(() => {
    dispatch(getMembers(jobId));
  }, []);

  useEffect(() => {
    let optArray = [];
    membersData?.data?.data.map((items) => {
      return optArray.push({
        value: items.id,
        label: displayNameFromType(items)
      });
    });
    setOptions(optArray);
  }, [membersData]);

  return (
    <>
      <div className="dashboard toggle-sidebar">
        <Header />
        <main id="main" className="main">
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <Link to={'/job/' + jobId + '/dashboard/'}>Dashboard</Link>
                </li>
                <li>
                  <a href="#">Group Analysis</a>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Proposed Facilities & Securities</strong>
                </li>
              </ul>
            </div>
            {activeTab == 1 && (
              <div className="breadcrumb-right">
                <DialogModal
                  title={'Proposed Facilities & Securities'}
                  p1={constants.group_fands}
                />
              </div>
            )}
          </div>
          <div className="pagetitle groupanalysispro">
            <DialogLeavingPage
              showDialog={showDialogLeavingPage}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
            <div className="title_pro d-flex justify-content-between align-items-center">
              <h1>Proposed Facilities & Securities</h1>
              <div className="addmemberarea">
                <Info />
                {activeTab == 2 ? (
                  <button
                    type="button"
                    className="button green"
                    onClick={(e) => {
                      setshowMemberForm(true);
                      setType('facilities');
                      handleInputChange(e.target.value);
                    }}
                  >
                    <i
                      className="icon-plus"
                      onClick={(e) => {
                        handleInputChange(e.target.value);
                      }}
                    ></i>
                    Facility
                  </button>
                ) : activeTab == 3 ? (
                  <button
                    type="button"
                    className="button green"
                    onClick={(e) => {
                      setshowMemberForm(true);
                      setType('securities');
                      handleInputChange(e.target.value);
                    }}
                  >
                    <i
                      className="icon-plus"
                      onClick={(e) => {
                        handleInputChange(e.target.value);
                      }}
                    ></i>
                    Security
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <ProposedFacilitiesTab
            activeTab={activeTab}
            handleOnclick={handleOnclick}
            handleInputChange={handleInputChange}
            setCanShowDialogLeavingPage={setCanShowDialogLeavingPage}
          />
          <MemberModel
            showMemberForm={showMemberForm}
            options={options}
            handleMemberForm={handleMemberForm}
            type={type}
          />
        </main>
      </div>
    </>
  );
}
