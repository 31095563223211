import { Formik } from 'formik';
import React, { useEffect } from 'react';
import AuthFooter from './Auth-Footer';
import { ResetPasswordSchema } from '../common/Validation';
import { useNavigate } from 'react-router';
import { authState, resetPassword, setResetPasswordResponse } from '../../slices/slices/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import Input from '../../components/Input';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { resetPasswordResponse, isUserLoggedIn } = useSelector(authState);
  // const search = window.location.search;
  // // const c = new URLSearchParams(search);
  const [searchParams] = useSearchParams();

  const handleFormSubmit = (values, { setErrors }) => {
    const data = {
      ...values,
      token: searchParams.get('token'),
      email: searchParams.get('email').replaceAll(' ', '+')
    };
    dispatch(resetPassword(data, setErrors));
  };
  useEffect(() => {
    if (resetPasswordResponse.message) {
      if (resetPasswordResponse) {
        navigate('/login');
        dispatch(setResetPasswordResponse({}));
      }
    }
  }, [resetPasswordResponse.message]);

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate('/dashboard');
    }
  }, [isUserLoggedIn]);

  return (
    <div className="influence-account-page">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="" className="logo d-flex align-items-center w-auto">
                    <img src="images/logo.png" alt="" />
                  </a>
                </div>

                <div className="card mb-3 loginarea">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        password: '',
                        password_confirmation: ''
                      }}
                      validationSchema={ResetPasswordSchema}
                      onSubmit={handleFormSubmit}
                    >
                      {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="row needs-validation">
                          <div className="col-12">
                            <div className="input-group has-validation">
                              <Input
                                type="password"
                                name="password"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                                placeholder="New Password"
                                required
                              />
                            </div>
                            <span className="error-18">{errors.password}</span>
                          </div>

                          <div className="col-12 topspace">
                            <Input
                              type="password"
                              name="password_confirmation"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password_confirmation}
                              className="form-control"
                              placeholder="Confirm New Password"
                              required
                            />
                            <span className="error-18">{errors.password_confirmation}</span>
                          </div>

                          <div className="col-12 topgap btnarea justify-content-center">
                            <Button className="btn btn-primary" type="submit">
                              Submit
                            </Button>
                          </div>

                          <div className="col-12 justify-content-center middletext">
                            <p className="topspace">
                              <a href="/login">Back to Login</a>
                            </p>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AuthFooter />
    </div>
  );
};

export default ResetPassword;
