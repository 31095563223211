import React from 'react';
import FSTables from '../FStable/table';
import TableHeadItem from '../FStable/table/TableHead';
import { formatNumber } from '../../../../../pages/common/Misc';

export default function Facilities(props) {
  let { personalData, businessData, tMainheadData, detailComponent, values, theadData } = props;
  const getHeadData = (title) => {
    return [{ ...theadData[0], title: title }, ...theadData.slice(1, theadData.length)];
  };
  return (
    <>
      <table className="table fac-table">
        <thead>
          <tr>
            {tMainheadData.map((h) => {
              return (
                <TableHeadItem
                  key={h.key}
                  className={h.className}
                  keyvalue={h.key}
                  sorting={h.sort}
                  item={h.title}
                  colSpan={h.colSpan}
                  style={h.style}
                  headClassName={h.headClassName}
                />
              );
            })}
            {/* <th className="t_no"></th>
            <th className="text-start t_heads">Borrower</th>
            <th className="text-start t_heads">Purpose</th>
            <th className="text-center t_head">Lender</th>
            <th className="text-center t_head">
              Existing Limit
              <br />$
            </th>
            <th className="text-center t_head">
              Change +/-
              <br />$
            </th>
            <th className="text-center t_head">
              Proposed Limit
              <br />$
            </th>
            <th className="t_last"></th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="10" className="businessareaof">
              <FSTables
                detailComponent={detailComponent}
                values={values}
                theadData={getHeadData('Business')}
                tbodyData={businessData}
                customClass="table fac-sec-table"
                Type="Facility"
                headSlice={1}
                tfootData={
                  <tfoot>
                    <tr>
                      <td colSpan="4" className="text-start" style={{ borderTopColor: '#52B3E5' }}>
                        Total
                      </td>
                      <td className="text-end" style={{ borderTopColor: '#52B3E5', width: 'auto' }}>
                        {formatNumber(businessData[0]?.businessdataTotal?.existing_limit)}
                      </td>
                      <td style={{ borderTopColor: '#52B3E5' }} colSpan="2"></td>
                      <td className="text-end" style={{ borderTopColor: '#52B3E5' }}>
                        {formatNumber(businessData[0]?.businessdataTotal?.proposed_limit)}
                      </td>

                      <td style={{ borderTopColor: '#52B3E5' }} colSpan="2"></td>
                    </tr>
                  </tfoot>
                }
                theadColor={'#52B3E5'}
              />
              <FSTables
                detailComponent={detailComponent}
                theadData={getHeadData('Personal')}
                tbodyData={personalData}
                Type="Securities"
                customClass="table fac-sec-table"
                theadColor={'#52B3E5'}
                headSlice={1}
                tfootData={
                  <tfoot>
                    <tr>
                      <td colSpan="4" className="text-start" style={{ borderTopColor: '#52B3E5' }}>
                        Total
                      </td>
                      <td className="text-end" style={{ borderTopColor: '#52B3E5' }}>
                        {formatNumber(personalData[0]?.personalDataTotal?.existing_limit)}
                      </td>
                      <td style={{ borderTopColor: '#52B3E5' }} colSpan="2"></td>
                      <td className="text-end" style={{ borderTopColor: '#52B3E5' }}>
                        {formatNumber(personalData[0]?.personalDataTotal?.proposed_limit)}
                      </td>
                      <td style={{ borderTopColor: '#52B3E5' }} colSpan="2"></td>
                    </tr>
                  </tfoot>
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
