import FormActions from '../../../../../components/FormActions';
import Autocomplete from 'react-google-autocomplete';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import LabelTooltip from '../../../../../components/tooltip/LabelTooltip';
import label from '../../../../../LabelConstant';
import InputMask from 'react-input-mask';
import { FieldArray } from 'formik';
import Select from 'react-select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorFocus from '../../../../common/ErrorFocus';
import { formatAddress } from '../../../../../utils/helper';
import { commonState, getConfig } from '../../../../../slices/slices/CommonSlice';
import AutomatedSelect from '../../../../../components/fields/AutomatedSelect';
import CurrencyInput from 'react-currency-input-field';
import { useState } from 'react';
const Form = ({
  values,
  setFieldValue,
  errors,
  jobId,
  memberId,
  memberListResponse,
  // propertyId,
  handleChange,
  handleBlur,
  handleSubmit,
  handleInputChange,
  MemberValue
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    errors: PropTypes.any,
    jobId: PropTypes.any,
    memberId: PropTypes.any,
    memberListResponse: PropTypes.any,
    // propertyId: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    handleSubmit: PropTypes.any,
    formValues: PropTypes.object,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    MemberValue: PropTypes.object
  };

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const dispatch = useDispatch();
  const [configListing, setconfigList] = useState([]);
  const { configList } = useSelector(commonState);

  useEffect(() => {
    dispatch(getConfig(configList));
  }, [configList]);

  useEffect(() => {
    setconfigList(configList);
  }, [configList]);

  return (
    <form onSubmit={handleSubmit} className="facility-form form-data assetowner_frm">
      <div className="white-card">
        <div className="add_un_property">
          <div className="row">
            <div className="col-6 assetowner holders">
              {/* <div className="persenteg d-flex justify-content-between">
                <div className="pro_persenteg"> */}
              <FieldArray
                name="asset_owner"
                render={(arrayHelpers) => (
                  <>
                    <label>Asset Owner</label>
                    <div className="shareholder noborder">
                      {values.asset_owner && values.asset_owner.length > 0
                        ? values.asset_owner.map((shareholder, index) => (
                            <div key={index} className="member-select">
                              <Select
                                className="custom-select"
                                classNamePrefix="custom-select"
                                name={`asset_owner.${index}.member_id`}
                                aria-label="Default select example"
                                onBlur={handleBlur}
                                value={
                                  values?.asset_owner[index]?.member_id?.label &&
                                  values?.asset_owner[index]?.member_id?.value
                                    ? values?.asset_owner[index]?.member_id
                                    : !values?.asset_owner[index]?.member_id?.label &&
                                      values?.asset_owner[index]?.member_id?.value === memberId
                                    ? MemberValue
                                    : ''
                                }
                                onChange={(val) => {
                                  setFieldValue(`asset_owner.${index}.member_id`, val);
                                  handleInputChange(val);
                                }}
                                options={newColumnsForAll}
                              ></Select>
                              <CurrencyInput
                                className="form-control"
                                name={`asset_owner.${index}.holding`}
                                value={
                                  values?.asset_owner[index]?.holding > 100
                                    ? 0
                                    : values?.asset_owner[index]?.holding
                                }
                                decimalsLimit={2}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {/* <NumberFormat
                                decimalScale={0}
                                allowNegative={false}
                                placeholder={'0'}
                                name={`asset_owner.${index}.holding`}
                                value={
                                  values?.asset_owner[index]?.holding > 100
                                    ? 0
                                    : values?.asset_owner[index]?.holding
                                }
                                className="form-control"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                              /> */}
                              {index !== 0 && (
                                <i
                                  className="icon-delete"
                                  onClick={() => index !== 0 && arrayHelpers.remove(index)}
                                />
                              )}
                            </div>
                          ))
                        : []}
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors?.holding}
                      </span>
                      <div className="add-btn">
                        <i
                          className="icon-plus"
                          onClick={() => {
                            var isEmpty = false;
                            for (var c = 0; c < values.asset_owner.length; c++) {
                              if (!values.asset_owner[c].member_id) {
                                isEmpty = true;
                                return;
                              }
                            }
                            if (!isEmpty) {
                              arrayHelpers.push({ member_id: '', holding: '' });
                            }
                          }}
                        ></i>
                      </div>
                    </div>
                  </>
                )}
              />
              {/* </div>
              </div> */}
              {/* {propertyId ? (
                <Input
                  type="text"
                  name="asset_owner"
                  className="form-control"
                  value={displayNameFromType(memberDataResponse)}
                />
              ) : (
                <>
                  <select
                    className="form-select"
                    name="asset_owner"
                    aria-label="Default select example"
                    value={values?.asset_owner}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {data?.name?.map((x, i) => (
                      <option key={i} value={data?.id[i]}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                    {errors?.asset_owner}
                  </span>
                </>
              )} */}
            </div>
            <div className="col-6 assetowner">
              <label>Address</label>
              <Autocomplete
                autoComplete="off"
                apiKey={process.env.REACT_APP_API_KEY}
                name="address"
                placeholder=""
                className="form-control"
                Value={values?.address}
                onChange={(val) => {
                  setFieldValue('address', val?.target?.value);
                }}
                onPlaceSelected={(place) => {
                  let formattedAddress = formatAddress(place);
                  setFieldValue('address', formattedAddress);
                  handleInputChange(formattedAddress);
                }}
                options={{
                  types: 'street_address',
                  componentRestrictions: { country: ['au', 'nz'] }
                }}
              />
              {/* <Input
                type="text"
                name="address"
                className="form-control"
                value={values?.address}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              /> */}
              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                {errors?.address}
              </span>
            </div>
            <div className="col-6 assetowner">
              <label>Asset Class</label>
              <select
                className="form-select"
                name="asset_class"
                aria-label="Default select example"
                value={values?.asset_class ? values?.asset_class : ''}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  handleInputChange(e.target.value);
                }}
              >
                <option value="">Select</option>
                {configListing &&
                  configListing?.securities_asset_class?.map((item, index) => {
                    return (
                      <option key={index} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
                {/*
                <option value="commercial">Commercial</option>
                <option value="mixed">Mixed</option>
                <option value="residential">Residential</option>
                <option value="specialised">Specialised</option>
                <option value="business-assets">Business Assets</option> */}
              </select>
              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                {errors?.asset_class}
              </span>
            </div>
            <div className="col-6 assetowner">
              <label>Asset Type</label>
              <select
                className="form-select"
                name="asset_type"
                aria-label="Default select example"
                value={values?.asset_type ? values?.asset_type : ''}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  handleInputChange(e.target.value);
                }}
              >
                <option value="">Select</option>
                {configListing &&
                  configListing?.securities_asset_types?.map((item, index) => {
                    return (
                      <option key={index} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-6 assetowner">
              <label>Asset Usage</label>
              <select
                className="form-select"
                name="asset_usage"
                aria-label="Default select example"
                value={values?.asset_usage ? values?.asset_usage : ''}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  handleInputChange(e.target.value);
                }}
              >
                <option value="">Select if Applicable</option>
                {configListing &&
                  configListing?.securities_asset_usages?.map((item, index) => {
                    return (
                      <option key={index} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
                {/* <option value="accommodation">Accommodation</option>
                <option value="farming">Farming</option>
                <option value="industrial">Industrial</option>
                <option value="office">Office</option>
                <option value="retail">Retail</option>
                <option value="vacant-land">Vacant Land</option> */}
              </select>
            </div>
            <div className="col-6 assetowner">
              <label>Asset Description</label>
              <AutomatedSelect
                name="asset_description"
                aria-label="Default select example"
                value={values?.asset_description ? values?.asset_description : ''}
                data={configListing?.securities_asset_description}
                handleBlur={handleBlur}
                handleChange={(e) => {
                  handleInputChange(e.target.value);
                  handleChange(e);
                }}
                headerValue={['property', 'balance-sheet']}
              />
              {/* <select
                className="form-select"
                name="asset_description"
                aria-label="Default select example"
                value={values?.asset_description ? values?.asset_description : ''}
                onBlur={handleBlur}
                headerValue={['property', 'balance-sheet']}
                onChange={(e) => {
                  handleChange(e);
                  handleInputChange(e.target.value);
                }}
              >
                <option value="">Select</option>
                {configListing &&
                  configListing?.securities_asset_description?.map((item, index) => {
                    return (
                      <option key={index} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
              </select> */}
            </div>
            <div className="col-6 assetowner">
              <LabelTooltip
                labelId={label.unencumberedProperty.marketUnencumberedTip.key}
                label={label.unencumberedProperty.marketUnencumberedTip.label}
                hover_text={label.unencumberedProperty.marketUnencumberedTip.hover_text}
              />
              {/* <Input
                type="number"
                name="market_value"
                className="form-control"
                value={values?.market_value}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
                onWheel={(e) => e.target.blur()}
              /> */}
              <NumberFormat
                decimalScale={0}
                allowNegative={false}
                name="market_value"
                value={values?.market_value ? values?.market_value : ''}
                className="form-control"
                thousandSeparator={true}
                onChange={(e) => {
                  handleChange(e);
                  handleInputChange(e.target.value);
                }}
                onBlur={handleBlur}
              />
              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                {errors?.market_value}
              </span>
            </div>
            <div className="col-6 assetowner">
              <label>Confirmation</label>
              <select
                className="form-select"
                name="confirmation"
                aria-label="Default select example"
                value={values?.confirmation ? values?.confirmation : ''}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  handleInputChange(e.target.value);
                }}
              >
                <option value="">Select</option>
                {configListing &&
                  configListing?.securities_value_confirmed?.map((item, index) => {
                    return (
                      <option key={index} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-6 assetowner">
              <label>Date of Confirmation</label>
              <DatePicker
                selected={values?.date_value ? new Date(values?.date_value) : null}
                onChange={(val) => {
                  setFieldValue('date_value', val);
                  handleInputChange(val);
                }}
                customInput={<InputMask className="form-control" mask="99-99" maskChar={null} />}
                name="date_value"
                dateFormat="MM-yy"
                value={values?.date_value}
                showMonthYearPicker
                placeholderText="MM-YY"
              />
              {/* <Input
                type="month"
                onChange={handleChange}
                onBlur={handleBlur}
                id="datepicker"
                name="date_confirmation"
                value={values?.date_confirmation}
                className="form-control hasDatepicker"
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: '0 -30px' }}>
        <FormActions
          cancel={'/job/' + jobId + '/data-entry/' + memberId + '/unencumbered-properties'}
          values={values}
        />
      </div>
      <ErrorFocus />
    </form>
  );
};

export default Form;
