import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { Table, Modal, Button } from 'react-bootstrap';
import parse from 'html-react-parser';
import {
  dataEntryState,
  getDataEntry,
  setEmptySelectedMember
} from '../../../slices/slices/DataEntrySlice';
import {
  getJobSettingsData,
  jobSetupState,
  NotApplicable
} from '../../../slices/slices/JobSetupSlice';

// import { getDataEntry, businessOverviewState } from '../../../slices/slices/BusinessOverviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { displayNameFromType } from '../../common/Misc';
import XeroimportModal from '../../../components/XeroimportModal';

const DataEntryMembers = () => {
  // const currentMonth = new Date().getMonth();
  // var monthNames = [
  //   { label: 'Jan', value: '01' },
  //   { label: 'Feb', value: '02' },
  //   { label: 'Mar', value: '03' },
  //   { label: 'Apr', value: '04' },
  //   { label: 'May', value: '05' },
  //   { label: 'Jun', value: '06' },
  //   { label: 'July', value: '07' },
  //   { label: 'Aug', value: '08' },
  //   { label: 'Sep', value: '09' },
  //   { label: 'Oct', value: '10' },
  //   { label: 'Nov', value: '11' },
  //   { label: 'Dec', value: '12' }
  // ];
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const navigate = useNavigate();
  const dataEntryResponse = useSelector(dataEntryState);
  // const dataEntryResponse = useSelector(businessOverviewState);
  const [showFinancialAnalysisPopup, setShowFinancialAnalysisPopup] = useState(false);
  const [member /* , setMember */] = useState('');
  const [showNotApplicableForm, setShowNotApplicableForm] = useState(false);
  const [memberId, setMemberId] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');

  const handleFinancialAnalysisPopup = () => {
    setShowFinancialAnalysisPopup(false);
  };

  const handleTax = (member) => {
    navigate('/job/' + jobId + '/data-entry/' + member?.id + '/tax', {
      state: { type: member?.type }
    });
  };

  // const handleShowFinancialAnalysisPopup = (item) => {
  //   localStorage.setItem('month', monthNames[currentMonth].value);
  //   if (item.financial_val && item?.financial_val !== 'not-started') {
  //     handleManualFinancialAnalysis(item);
  //   } else {
  //     setShowFinancialAnalysisPopup(true);
  //     setMember(item);
  //   }
  // };

  const handleManualFinancialAnalysis = (item) => {
    navigate('/job/' + jobId + '/data-entry/' + item.id + '/financials', {
      state: { type: item?.type }
    });
  };

  useEffect(() => {
    dispatch(setEmptySelectedMember());
    dispatch(getDataEntry(jobId));
  }, []);

  const { jobSettingsDataResponse } = useSelector(jobSetupState);

  useEffect(() => {
    dispatch(getJobSettingsData(jobId));
  }, []);

  const manageApplicable = (e, member, type, status) => {
    e.preventDefault();
    setStatus(status);
    setType(type);
    setMemberId(member);
    setShowNotApplicableForm(true);
  };

  const handleSubmitApplicableStatus = () => {
    let data = {
      module: type
    };
    dispatch(NotApplicable(jobId, memberId, data)).then(() => {
      setShowNotApplicableForm(false);
      dispatch(getDataEntry(jobId));
    });
  };

  const handleHideModal = () => {
    setShowNotApplicableForm(false);
  };

  useEffect(() => {}, [jobSettingsDataResponse]);

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <ul>
            <li>
              <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
            </li>
            <li>
              <strong>Data Entry</strong>
            </li>
          </ul>
        </div>
        <div className="member-data-entry">
          <div className="table-legend">
            <ul>
              <li className="completed">Completed</li>
              <li className="in-progress">In Progress</li>
              <li className="not-started">Not Started</li>
              <li className="not-applicable">Not Applicable</li>
            </ul>
          </div>

          <Table className="table table-hover">
            <thead>
              <tr>
                <th scope="col" className="align-left" style={{ width: '25%' }}>
                  Member Name
                </th>
                <th scope="col" className="align-left" style={{ width: '25%' }}>
                  Activity / Occupation
                </th>
                <th scope="col" className="align-left" style={{ width: '25%' }}>
                  Notes
                </th>
                <th scope="col" className="align-left">
                  Data Entry Navigation & Status
                </th>
              </tr>
            </thead>
            <tbody>
              {dataEntryResponse?.dataEntryResponse?.data &&
              dataEntryResponse?.dataEntryResponse?.data?.length > 0 ? (
                dataEntryResponse?.dataEntryResponse?.data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-left">{displayNameFromType(item)}</td>
                      <td className="align-left">{item?.activity}</td>
                      <td className="align-left">
                        {item?.additional_info?.internal_notes
                          ? parse(item?.additional_info?.internal_notes)
                          : ''}
                      </td>
                      <td className="align-left" style={{ paddingBottom: '0' }}>
                        <Link
                          to={'/job/' + jobId + '/data-entry/' + item?.id + '/facilities'}
                          onContextMenu={(e) =>
                            manageApplicable(e, item?.id, 'facility', item?.facility_val)
                          }
                        >
                          <button className={item?.facility_val} style={{ marginBottom: '10px' }}>
                            Facilities
                          </button>
                        </Link>
                        <Link
                          to={'/job/' + jobId + '/data-entry/' + item?.id + '/securities'}
                          onContextMenu={(e) =>
                            manageApplicable(e, item?.id, 'security', item?.security_val)
                          }
                        >
                          <button className={item?.security_val} style={{ marginBottom: '10px' }}>
                            Securities
                          </button>
                        </Link>
                        <Link
                          to={
                            '/job/' + jobId + '/data-entry/' + item?.id + '/unencumbered-properties'
                          }
                          onContextMenu={(e) =>
                            manageApplicable(
                              e,
                              item?.id,
                              'unencumbered-property',
                              item?.unencumbered_property_val
                            )
                          }
                        >
                          <button
                            className={item?.unencumbered_property_val}
                            style={{ marginBottom: '10px' }}
                          >
                            Unencumbered Property
                          </button>
                        </Link>
                        {/*<Link to={'/job/' + jobId + '/data-entry/' + item?.id + '/financials'}>*/}
                        <button
                          className={item?.financial_val}
                          onContextMenu={(e) =>
                            manageApplicable(e, item?.id, 'financial', item?.financial_val)
                          }
                          onClick={() => {
                            // handleShowFinancialAnalysisPopup(item);
                            handleManualFinancialAnalysis(item);
                          }}
                          style={{ marginBottom: '10px' }}
                        >
                          Financials
                        </button>
                        <Link
                          to={'/job/' + jobId + '/data-entry/' + item?.id + '/rentals'}
                          onContextMenu={(e) =>
                            manageApplicable(e, item?.id, 'rental', item?.rental_val)
                          }
                        >
                          <button className={item?.rental_val} style={{ marginBottom: '10px' }}>
                            Rentals
                          </button>
                        </Link>
                        <button
                          onContextMenu={(e) => manageApplicable(e, item?.id, 'tax', item?.tax_val)}
                          className={
                            item?.tax_val
                            // item.type == 'sole-trader' || item.type == 'partnership'
                            //   ? 'not-applicable'
                            //   : item?.tax_val
                          }
                          onClick={() => {
                            handleTax(item);
                          }}
                          // disabled={
                          //   item.type == 'sole-trader' || item.type == 'partnership' ? true : false
                          // }
                          style={{ marginBottom: '10px' }}
                        >
                          Tax
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center' }}>
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Link
            to={'/job/' + jobId + '/group-analysis/proposed-transaction/'}
            style={{ float: 'right' }}
          >
            <button className="green" style={{ marginTop: '30px' }}>
              + Proposed Transaction
            </button>
          </Link>
        </div>
        <div className="modal" id="CreateBusinessFinancialModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body create-job">
                <h4>Create Financial Analysis</h4>
                <p>Member Name: {displayNameFromType(member)}</p>
                <div className="create-btn">
                  <button type="button" className="btn btn-primary">
                    Import from XERO
                  </button>
                  or
                  <button type="button" className="btn btn-secondary">
                    Manual Input
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <XeroimportModal
        show={showFinancialAnalysisPopup}
        onHide={handleFinancialAnalysisPopup}
        member={member}
      />
      {/* Right Click Modal */}
      <Modal
        show={showNotApplicableForm}
        onHide={handleHideModal}
        dialogClassName="modal-50w"
        className="modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="create-job">
          <h3>Change Status</h3>
          <div className="create-btn">
            <Button
              type="button"
              className="btn btn-secondary"
              id="manualjobbtn"
              onClick={handleSubmitApplicableStatus}
            >
              {status == 'not-applicable' ? 'Applicable' : 'Not Applicable'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DataEntryMembers;
