import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../components/Button';

export default function OrganizationModal(props) {
  const { show, organization, setTenantId, setShow, getjob } = props;
  const [onHide] = useState(false);
  const [tenantId, setdataTenantID] = useState();

  const onChangehandle = (val) => {
    setdataTenantID(val);
  };

  const handleSubmit = () => {
    if (tenantId) setShow(false);

    if (setTenantId) setTenantId(tenantId);
    if (getjob) {
      getjob(tenantId);
    }
  };

  useEffect(() => {
    setdataTenantID(organization?.[0]?.tenantId);
  }, [organization]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="create-job">
          <div className="create-btn">
            <h3>Select Organisation</h3>
            <select
              onChange={(e) => {
                onChangehandle(e.target.value);
              }}
              className="form-select"
            >
              {organization &&
                organization.length > 0 &&
                organization.map((item, key) => {
                  return (
                    <option key={key} value={item.tenantId}>
                      {item.tenantName}
                    </option>
                  );
                })}
            </select>

            <Button
              type="button"
              className="btn btn-secondary"
              id="manualjobbtn"
              onClick={handleSubmit}
            >
              Get data
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
