import React from 'react';
import { Modal } from 'react-bootstrap';

export default function SelectionModel(props) {
  const { show, close, onClickSubmit } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={close}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="create-job">
          <h3>Select Color</h3>
          <button
            type="button"
            onClick={() => onClickSubmit(2)}
            className="btn btn-warning m-2"
            style={{ color: '#FFF' }}
          >
            Yellow
          </button>
          <button type="button" onClick={() => onClickSubmit(1)} className="btn btn-success m-2">
            Green
          </button>
          <button type="button" onClick={() => onClickSubmit(3)} className="btn btn-danger m-2">
            Red
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
