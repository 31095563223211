import React from 'react';

const TableHeadItem = ({ keyvalue, item }) => {
  return (
    <>
      <th key={keyvalue}>{item}</th>
    </>
  );
};

export default TableHeadItem;
