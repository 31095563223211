const AuthFooter = () => {
  return (
    <>
      <ul>
        <li>
          <a href="/security">Security Noticeboard</a>
        </li>
        <li>
          <a href="/terms">Terms of Use</a>
        </li>
        <li>
          <a href="/privacy">Privacy</a>
        </li>
        <li>
          <a href="/help-center">Help Centre</a>
        </li>
      </ul>
    </>
  );
};

export default AuthFooter;
