import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useNavigationBlocker } from './NavigationBlocker.ts';

export function useNavigatingAway(canShowDialogPrompt) {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [showDialogPrompt, setShowDialogPrompt] = useState(false);
  const [wantToNavigateTo, setWantToNavigateTo] = useState(null);
  const [isNavigationConfirmed, setIsNavigationConfirmed] = useState(false);
  const [isParams, setIsParams] = useState();

  const handleNavigationBlocking = useCallback(
    (locationToNavigateTo) => {
      setIsParams(locationToNavigateTo);
      if (
        !isNavigationConfirmed &&
        locationToNavigateTo.location.pathname !== currentLocation.pathname
      ) {
        setShowDialogPrompt(true);
        setWantToNavigateTo(locationToNavigateTo);
        return false;
      }
      return true;
    },
    [isNavigationConfirmed]
  );

  const cancelNavigation = useCallback(() => {
    setIsNavigationConfirmed(false);
    setShowDialogPrompt(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setIsNavigationConfirmed(true);
    setShowDialogPrompt(false);
  }, []);

  useEffect(() => {
    if (isNavigationConfirmed && wantToNavigateTo) {
      navigate(wantToNavigateTo.location.pathname, {
        state: {
          name: isParams?.location?.state?.name ? isParams?.location?.state?.name : '',
          type: isParams?.location?.state?.type ? isParams?.location?.state?.type : '',
          transactionId: isParams?.location?.state?.transactionId
            ? isParams?.location?.state?.transactionId
            : ''
        }
      });
    }
  }, [isNavigationConfirmed, wantToNavigateTo]);

  useNavigationBlocker(handleNavigationBlocking, canShowDialogPrompt);

  return [showDialogPrompt, confirmNavigation, cancelNavigation, isParams];
}
