import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { JobSettingsSchema } from '../../common/Validation';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getJobSettingsData,
  jobSetupState,
  addJobSettingsData,
  setAddJobSettingsDataResponse
} from '../../../slices/slices/JobSetupSlice';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header';
import InputMask from 'react-input-mask';

import { Link, useParams } from 'react-router-dom';
import ErrorFocus from '../../common/ErrorFocus';
import { useNavigatingAway } from '../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../common/components/DialogLeavingPage';
// import DatePicker from 'react-date-picker';

const JobSettings = () => {
  const dispatch = useDispatch();
  // const today = new Date();
  // const Month = moment(today).format('MM');
  // const [year4, setYear4] = useState();
  const [inputstatus, setinputStatus] = useState(true);
  const { jobId } = useParams();

  const options = [
    { value: '0', label: '0', key: '0' },
    { value: '1', label: '1', key: '1' },
    { value: '2', label: '2', key: '2' },
    { value: '3', label: '3', key: '3' },
    { value: '4', label: '4', key: '4' }
  ];

  // const optionsForNoOfMonth = [
  //   { value: '1', label: '1', key: '1' },
  //   { value: '2', label: '2', key: '2' },
  //   { value: '3', label: '3', key: '3' },
  //   { value: '4', label: '4', key: '4' },
  //   { value: '5', label: '5', key: '5' },
  //   { value: '6', label: '6', key: '6' },
  //   { value: '7', label: '7', key: '7' },
  //   { value: '8', label: '8', key: '8' },
  //   { value: '9', label: '9', key: '9' },
  //   { value: '10', label: '10', key: '10' },
  //   { value: '11', label: '11', key: '11' }
  // ];

  const { jobSettingsDataResponse, addJobSettingsDataResponse } = useSelector(jobSetupState);
  // let total_financial_year = 0;
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  // useEffect(() => {
  //   if (Month <= 6) {
  //     setYear4(YYYY - 1);
  //   } else {
  //     setYear4(YYYY);
  //   }
  // });

  useEffect(() => {
    dispatch(getJobSettingsData(jobId));
  }, []);

  useEffect(() => {
    if (addJobSettingsDataResponse?.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setAddJobSettingsDataResponse({}));
    }
  }, [addJobSettingsDataResponse?.message]);

  const checkValue = (e) => {
    if (e.target.checked == true) {
      setinputStatus(false);
    } else {
      setinputStatus(true);
    }
  };

  const currentFinancialYear = () => {
    var month = moment().month(); // jan=0, dec=11
    var year = moment().year();
    if (5 < month) {
      return year + 1;
    } else {
      return year;
    }
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    const data = {
      ...values,
      anticipated_settlement_date: moment(values.anticipated_settlement_date).format('DD-MM-YYYY'),
      due_date: moment(values.due_date).format('DD-MM-YYYY'),
      financial_year_end: moment(values.financial_year_end, 'DD-MM').format('DD-MM'),
      no_of_months_of_mgmt_financial:
        values?.include_current_financial_year === '1' &&
        values?.select_following_option === 'annualise-management-reports'
          ? values.no_of_months_of_mgmt_financial
          : '',
      select_following_option:
        values?.include_current_financial_year === '1' ? values?.select_following_option : '',
      historical_financial_year:
        values.include_current_financial_year === '1'
          ? values?.historical_financial_year
          : values?.historical,
      future_year_projections:
        values.include_current_financial_year === '1'
          ? values?.future_year_projections
          : values?.future
    };
    dispatch(addJobSettingsData(jobId, data, setErrors, values));
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/group-setup/members'}>Group Setup</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>Settings</strong>
              </li>
            </ul>
          </div>
          <div className="breadcrumb-right"></div>
        </div>
        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-center align-items-center">
            <h1>Settings</h1>
          </div>
        </div>
        <Formik
          initialValues={{
            group_name: jobSettingsDataResponse?.group_name,
            job_name: jobSettingsDataResponse?.job_name,
            order_of_the_year: jobSettingsDataResponse?.order_of_the_year,
            anticipated_settlement_date:
              jobSettingsDataResponse?.anticipated_settlement_date == null
                ? null
                : moment(jobSettingsDataResponse?.anticipated_settlement_date).format('YYYY-MM-DD'),
            due_date: moment(jobSettingsDataResponse?.due_date).format('YYYY-MM-DD'),
            include_current_financial_year:
              jobSettingsDataResponse?.include_current_financial_year === null
                ? '1'
                : jobSettingsDataResponse?.include_current_financial_year,
            // current_financial_year: year4 + 1,
            current_financial_year:
              jobSettingsDataResponse.current_financial_year === null
                ? currentFinancialYear()
                : jobSettingsDataResponse.current_financial_year,
            financial_year_end: jobSettingsDataResponse?.financial_year_end,
            select_following_option: jobSettingsDataResponse?.select_following_option,
            historical_financial_year:
              jobSettingsDataResponse?.historical_financial_year === null
                ? '0'
                : jobSettingsDataResponse?.historical_financial_year,
            future_year_projections:
              jobSettingsDataResponse?.future_year_projections === null
                ? '0'
                : jobSettingsDataResponse?.future_year_projections,
            no_of_months_of_mgmt_financial:
              jobSettingsDataResponse?.no_of_months_of_mgmt_financial === null ||
              jobSettingsDataResponse?.no_of_months_of_mgmt_financial === ''
                ? '1'
                : jobSettingsDataResponse?.no_of_months_of_mgmt_financial,
            historical:
              (parseInt(jobSettingsDataResponse?.future_year_projections) > 0 &&
                parseInt(jobSettingsDataResponse?.historical_financial_year) > 0) ||
              jobSettingsDataResponse?.historical_financial_year === null
                ? '0'
                : jobSettingsDataResponse?.historical_financial_year,
            future:
              (parseInt(jobSettingsDataResponse?.future_year_projections) > 0 &&
                parseInt(jobSettingsDataResponse?.historical_financial_year) > 0) ||
              jobSettingsDataResponse?.future_year_projections === null
                ? '0'
                : jobSettingsDataResponse?.future_year_projections
          }}
          enableReinitialize
          validationSchema={JobSettingsSchema}
          onSubmit={handleFormSubmit}
        >
          {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form onSubmit={handleSubmit} className="row needs-validation" noValidate>
                <section className="settingsgroup">
                  <div className="addmember">
                    <div className="container">
                      <div className="setting_group">
                        <h2>Group</h2>
                        <div className="group_areaof row">
                          <div className="col-6">
                            <label>Group Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="group_name"
                              value={values.group_name}
                              // setGroupName={(e) => {
                              //   setValues({
                              //     group_name: e
                              //   });
                              // }}
                              // setGroupId={(e) => {
                              //   setValues({
                              //     group_id: e
                              //   });
                              // }}
                              onChange={(e) => {
                                handleChange(e);
                                handleInputChange(e.target.value);
                              }}
                              onBlur={handleBlur}
                              required
                            />
                            <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                              {errors.group_name}
                            </span>
                          </div>
                          <div className="col-6">
                            <label>Job Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="job_name"
                              value={values.job_name}
                              onChange={(e) => {
                                handleChange(e);
                                handleInputChange(e.target.value);
                              }}
                              onBlur={handleBlur}
                              required
                            />
                            <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                              {errors.job_name}
                            </span>
                          </div>
                          <div className="col-6">
                            <label>Due Date</label>
                            <DatePicker
                              selected={values?.due_date ? new Date(values?.due_date) : null}
                              onChange={(val) => {
                                setFieldValue('due_date', val);
                                handleInputChange(val);
                              }}
                              customInput={
                                <InputMask
                                  className="form-control"
                                  mask="99-99-9999"
                                  maskChar={null}
                                />
                              }
                              name="due_date"
                              dateFormat="dd-MM-yyyy"
                              value={new Date(values?.due_date)}
                              minDate={new Date()}
                              placeholderText="DD-MM-YYYY"
                            />
                            <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                              {errors.due_date}
                            </span>
                          </div>
                        </div>
                        <h2 className="grouptitlepro">Proposed Transaction</h2>
                        <div className="group_areaof row">
                          <div className="col-6">
                            <label>Anticipated Settlement Date</label>
                            <DatePicker
                              selected={
                                values?.anticipated_settlement_date
                                  ? new Date(values?.anticipated_settlement_date)
                                  : null
                              }
                              onChange={(val) => {
                                setFieldValue('anticipated_settlement_date', val);
                                handleInputChange(val);
                              }}
                              customInput={
                                <InputMask
                                  className="form-control"
                                  mask="99-99-9999"
                                  maskChar={null}
                                />
                              }
                              name="anticipated_settlement_date"
                              dateFormat="dd-MM-yyyy"
                              value={new Date(values?.anticipated_settlement_date)}
                              minDate={new Date()}
                              placeholderText="DD-MM-YYYY"
                            />
                            <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                              {errors.anticipated_settlement_date}
                            </span>
                          </div>
                        </div>
                        <h2 className="grouptitlepro">Financial Year Structure</h2>
                        <div className="group_areaof row">
                          <div className="col-6">
                            <label>Current Financial Year</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="current_financial_year"
                              value={values?.current_financial_year}
                              onChange={(e) => {
                                setFieldValue('current_financial_year', e.target.value);
                              }}
                              onBlur={handleBlur}
                              disabled={inputstatus}
                            />
                            <div className="change-fin-year">
                              <Input type="checkbox" id="change_fin_year" onChange={checkValue} />
                              <label htmlFor="change_fin_year">
                                Checkbox to change the financial year
                              </label>
                            </div>
                            <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                              {errors.current_financial_year}
                            </span>
                          </div>
                          <div className="col-6">
                            <label>Financial Year End</label>
                            <DatePicker
                              selected={
                                values.financial_year_end
                                  ? new Date(moment(values.financial_year_end, 'DD-MM'))
                                  : null
                              }
                              onChange={(val) => {
                                setFieldValue('financial_year_end', val);
                                handleInputChange(val);
                              }}
                              customInput={
                                <InputMask className="form-control" mask="99-99" maskChar={null} />
                              }
                              onBlur={handleBlur}
                              value={values.financial_year_end}
                              name="financial_year_end"
                              dateFormat="dd-MM"
                              placeholderText="DD-MM"
                            />
                            <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                              {errors.financial_year_end}
                            </span>
                          </div>
                          <div className="col-6">
                            <label>Order of the year</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="order_of_the_year"
                              value={values.order_of_the_year}
                              onChange={(e) => {
                                handleChange(e);
                                handleInputChange(e.target.value);
                              }}
                              onBlur={handleBlur}
                              // required
                            >
                              <option key="ascending" value="asc">
                                Ascending
                              </option>
                              <option key="descending" value="desc">
                                Descending
                              </option>
                            </select>
                          </div>
                          <div className="col-6"></div>
                          <div className="col-6">
                            <label>Include Current Financial Year?</label>
                            <div className="checkfrom d-flex justify-content-between align-items-center">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  name="include_current_financial_year"
                                  value="1"
                                  checked={values.include_current_financial_year === '1'}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  // required
                                />
                                <label className="form-check-label" id="year_to_date_mgmt">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="radio"
                                  name="include_current_financial_year"
                                  value="0"
                                  checked={values.include_current_financial_year === '0'}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  // required
                                />
                                <label className="form-check-label" id="year_to_date_mgmt">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* {values.include_current_financial_year === '1' ? (
                            <div className="col-6">
                              <label>Select one of the following options:</label>
                              <select
                                className="form-select"
                                name="select_following_option"
                                aria-label="Default select example"
                                value={values.select_following_option}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                              >
                                <option value="">Select</option>
                                <option value="annualise-management-reports">
                                  Annualise Management Reports
                                </option>
                                <option value="current-full-year-management-reports">
                                  Current Full Year Management Reports
                                </option>
                                <option value="current-full-year-projection">
                                  Current Full Year Projection
                                </option>
                              </select>
                              <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                                {errors.select_following_option}
                              </span>
                            </div>
                          ) : (
                            <div className="col-6 nopadding"></div>
                          )} */}
                          {/* {values.select_following_option === 'annualise-management-reports' &&
                          values.include_current_financial_year === '1' ? (
                            <div className="col-6">
                              <label>Number of Months of Management Financials</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="no_of_months_of_mgmt_financial"
                                value={
                                  values?.include_current_financial_year === '1' &&
                                  values?.select_following_option === 'annualise-management-reports'
                                    ? values.no_of_months_of_mgmt_financial
                                    : null
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInputChange(e.target.value);
                                }}
                                onBlur={handleBlur}
                                // required
                              >
                                {optionsForNoOfMonth.map(({ value, label }, key) => (
                                  <option key={key} value={value}>
                                    {label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <div className="col-6 nopadding"></div>
                          )} */}
                          <div className="col-6 nopadding"></div>
                          {values.include_current_financial_year === '1' ? (
                            <>
                              <div className="col-6">
                                <label>Number of Historical Financial Years</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="historical_financial_year"
                                  value={values.historical_financial_year}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  // required
                                >
                                  {options.map(({ value, label }, key) => (
                                    <option key={key} value={value}>
                                      {label}
                                    </option>
                                  ))}
                                </select>
                                {values.include_current_financial_year === '1' ? (
                                  parseInt(values.historical_financial_year) +
                                    parseInt(values.future_year_projections) +
                                    parseInt(values.include_current_financial_year) >
                                  4 ? (
                                    <span
                                      className="error"
                                      style={{ color: 'red', fontSize: '15px' }}
                                    >
                                      {
                                        (errors =
                                          'Total Financial Year must be less than or equal to 4.')
                                      }
                                    </span>
                                  ) : null
                                ) : parseInt(values.include_current_financial_year) +
                                    parseInt(values.future) +
                                    parseInt(values.historical) ===
                                  0 ? (
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '15px' }}
                                  >
                                    {(errors = 'Please select at list one financial year.')}
                                  </span>
                                ) : null}
                              </div>
                              <div className="col-6">
                                <label>Number of Future Year Projections</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="future_year_projections"
                                  value={values.future_year_projections}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  // required
                                >
                                  {options.map(({ value, label }, key) => (
                                    <option key={key} value={value}>
                                      {label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-6">
                                <label>Number of Historical Financial Years</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="historical"
                                  value={values.historical}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  disabled={values.future !== '0' ? true : false}
                                  // required
                                >
                                  {values.future !== '0' ? (
                                    <option value="0">0</option>
                                  ) : (
                                    options.map(({ value, label }, key) => (
                                      <option key={key} value={value}>
                                        {label}
                                      </option>
                                    ))
                                  )}
                                </select>
                                {values.include_current_financial_year === '1' ? (
                                  parseInt(values.historical_financial_year) +
                                    parseInt(values.future_year_projections) +
                                    parseInt(values.include_current_financial_year) ===
                                  0 ? (
                                    <span
                                      className="error"
                                      style={{ color: 'red', fontSize: '15px' }}
                                    >
                                      {(errors = 'Please select at list one financial year.')}
                                    </span>
                                  ) : null
                                ) : parseInt(values.include_current_financial_year) +
                                    parseInt(values.future) +
                                    parseInt(values.historical) ===
                                  0 ? (
                                  <span
                                    className="error"
                                    style={{ color: 'red', fontSize: '15px' }}
                                  >
                                    {(errors = 'Please select at list one financial year.')}
                                  </span>
                                ) : null}
                              </div>
                              <div className="col-6">
                                <label>Number of Future Year Projections</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="future"
                                  value={values.future}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  disabled={values.historical !== '0' ? true : false}
                                  // required
                                >
                                  {values.historical !== '0' ? (
                                    <option value="0">0</option>
                                  ) : (
                                    options.map(({ value, label }, key) => (
                                      <option key={key} value={value}>
                                        {label}
                                      </option>
                                    ))
                                  )}
                                </select>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_area text-end">
                    <div className="setting_group">
                      <Link
                        to={'/job/' + jobId + '/dashboard'}
                        className="btn btn-outline-secondary"
                      >
                        Cancel
                      </Link>
                      <Button type="submit" className="btn btn-success" onClick={() => {}}>
                        Save
                      </Button>
                    </div>
                  </div>
                </section>
                <ErrorFocus />
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default JobSettings;
