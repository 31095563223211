import React from 'react';
import { Formik } from 'formik';
import Label from './Label';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Input from './Input';
import { addPracticeSchema } from '../pages/common/Validation';
import {
  addPracticeManager,
  getpracticeManager,
  updatePracticeManager
} from '../slices/slices/CommonSlice';

export default function AddPracticeModal(props) {
  const dispatch = useDispatch();
  const { open, setOpen, type, userData, lastPage } = props;

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAddPractice = (values, { setErrors }) => {
    if (type) {
      dispatch(addPracticeManager(values, setErrors)).then((res) => {
        if (res) {
          setOpen(false);
          dispatch(getpracticeManager(25, lastPage));
        }
      });
    } else {
      dispatch(updatePracticeManager(userData.id, values, setErrors)).then((res) => {
        if (res) {
          setOpen(false);
          dispatch(getpracticeManager(25, lastPage));
        }
      });
    }
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleCancel}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>{type ? 'ADD New' : 'EDIT'} Practice</h2>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              practice_name: !type ? userData?.practice_settings?.name : '',
              name: !type ? userData?.name : '',
              surname: !type ? userData?.surname : '',
              abn: !type ? userData?.additional_info?.abn : '',
              email: !type ? userData?.email : '',
              phone: !type ? userData?.phone : '',
              user_limit: !type ? userData?.practice_settings?.user_limit : '',
              accountants:
                !type &&
                userData?.practice_settings?.disp_templates !== undefined &&
                JSON.parse(userData?.practice_settings?.disp_templates)?.accountants === 1
                  ? 1
                  : 0,
              brokers:
                !type &&
                userData?.practice_settings?.disp_templates !== undefined &&
                JSON.parse(userData?.practice_settings?.disp_templates)?.brokers === 1
                  ? 1
                  : 0
            }}
            validationSchema={addPracticeSchema}
            onSubmit={handleAddPractice}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} noValidate>
                <fieldset>
                  <div className="field-box">
                    <Label>Practice Name</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.practice_name}
                      name="practice_name"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.practice_name}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      name="name"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.name}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.surname}
                      name="surname"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.surname}
                    </span>
                  </div>

                  <div className="field-box">
                    <Label>ABN</Label>
                    <InputMask
                      className="form-control"
                      onChange={(e) => {
                        setFieldValue('abn', e.target.value.replace(/\D+/g, ''));
                      }}
                      onBlur={handleBlur}
                      name="abn"
                      value={values.abn}
                      mask="99 999 999 999"
                      maskChar={null}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                      {errors.abn}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>Email Address</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                      disabled={!type}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.email}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>Phone Number</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      name="phone"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.phone}
                    </span>
                  </div>
                  <div className="field-box">
                    <Label>No. Of Users</Label>
                    <Input
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_limit}
                      name="user_limit"
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.user_limit}
                    </span>
                  </div>

                  <div className="change-fin-year">
                    <Input
                      type="checkbox"
                      style={{ width: '18px', height: '18px', minHeight: '25px' }}
                      name="accountants"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue('accountants', e.target.checked ? 1 : 0);
                      }}
                      value={values?.accountants}
                      defaultChecked={values?.accountants}
                    />
                    <Label>Accountant Tools & Templates</Label>
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.accountants}
                    </span>
                  </div>
                  <div className="change-fin-year">
                    <Input
                      type="checkbox"
                      style={{ width: '18px', height: '18px', minHeight: '25px' }}
                      name="brokers"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue('brokers', e.target.checked ? 1 : 0);
                      }}
                      value={values?.brokers}
                      defaultChecked={values?.brokers}
                    />
                    <Label>Broker Tools & Templates</Label>
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.brokers}
                    </span>
                  </div>
                </fieldset>
                <div className="modal-action modal-footer" style={{ padding: '20px 0' }}>
                  <Button type="button" className="btn btn-secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" className="btn btn-primary">
                    {type ? 'Create' : 'Update'}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
