import { financialKeyValuePair } from '../slices/slices/FinancialsSlice';

export const calculateTotalIncome = (year, financialData) => {
  let value = 0;
  if (financialData['related-party-income']) {
    if (financialData['related-party-income']?.totals?.data[year]) {
      value += Number(financialData['related-party-income'].totals.data[year]);
    }
  }
  if (financialData['other-income']) {
    if (financialData['other-income'].totals.data[year]) {
      value += Number(financialData['other-income'].totals.data[year]);
    }
  }
  return value;
};

export const calculateTaxableIncome = (year, financialData) => {
  let value = calculateTotalIncome(year, financialData);
  if (financialData['deductions']) {
    if (financialData['deductions'].items[0].data[year]) {
      value += Number(financialData['deductions'].items[0].data[year]);
    }
  }
  return value;
};

export const calculateEbitda = (year, financialData) => {
  let value = calculateTaxableIncome(year, financialData);
  if (financialData['ebitda']) {
    if (financialData['ebitda'].totals.data?.[year]) {
      value += Number(financialData['ebitda'].totals.data[year]);
    }
  }
  return value;
};

export const personalcalculateEbitda = (year, financialData) => {
  let value = calculateEbitda(year, financialData);
  return value;
};

export const calculateAdjustedEbitda2 = (year, financialData) => {
  let value = 0;
  if (financialData['adjusted-ebitda']) {
    if (financialData['adjusted-ebitda']['final-totals'].data[year]) {
      value += Number(financialData['adjusted-ebitda']['final-totals'].data[year]);
    }
  }
  return value;
};

export const calculateAdjustedEbitda = (year, financialData) => {
  let value = calculateEbitda(year, financialData);
  if (financialData['adjusted-ebitda']) {
    if (financialData['adjusted-ebitda'].items.length > 0) {
      for (let el of financialData['adjusted-ebitda'].items) {
        value += el?.data?.[year] ? Number(el.data[year]) : 0;
      }
    }
  }
  return value;
};

export const calculateAdjustedEbitdaPersonal = (year, financialData) => {
  let value = calculateEbitda(year, financialData);
  if (financialData['adjusted-ebitda']) {
    if (financialData['adjusted-ebitda'].items.length > 0) {
      for (let el of financialData['adjusted-ebitda'].items) {
        value += el?.data?.[year] ? Number(el.data[year]) : 0;
      }
    }
  }
  return value;
};

export const calculateDebtServicing = (year, tax, financialData, taxBrackets) => {
  let value = calculateAdjustedEbitda(year, financialData);
  value += -calculatePayableTaxPersonal(year, tax, financialData, taxBrackets);
  return value;
};

// new calculated value as per formula(Funds available for Debt Servicing (after Tax, Dividends & Distributions))
export const calculateDebtServicings = (year, tax, financialData, taxBrackets) => {
  let value = calculateAdjustedEbitda(year, financialData);
  value += -calculatePayableTaxPersonal(year, tax, financialData, taxBrackets);
  return value;
};

export const calculateAdjustedTaxableIncomePersonal = (year, tax, financialData) => {
  let value = calculateAdjustedEbitda(year, financialData);
  let amortisation = 0;
  let rentalDepreciation = 0;
  let otherDepreciation = 0;
  let frankingCredits = 0;

  if (financialData['ebitda'] && financialData['ebitda'].items) {
    let aArray = financialData['ebitda'].items.filter(function (item) {
      return item.key === 'amortisation';
    });
    let rpdArray = financialData['ebitda'].items.filter(function (item) {
      return item.key === 'rental-property-depreciation';
    });
    let odArray = financialData['ebitda'].items.filter(function (item) {
      return item.key === 'other-depreciation';
    });
    if (aArray.length > 0 && aArray[0].data[year]) {
      amortisation = aArray[0].data[year];
    }
    if (rpdArray.length > 0 && rpdArray[0].data[year]) {
      rentalDepreciation = rpdArray[0].data[year];
    }
    if (odArray.length > 0 && odArray[0].data[year]) {
      otherDepreciation = odArray[0].data[year];
    }
  }

  if (financialData['adjusted-ebitda'] && financialData['ebitda'].items) {
    let fArray = financialData['adjusted-ebitda'].items.filter(function (item) {
      return ['franking-credits', 'franking-credits-received'].includes(item.key);
    });
    if (fArray.length > 0 && fArray[0].data[year]) {
      frankingCredits = fArray[0].data[year];
    }
  }

  if (tax['adjusted-tax-income']) {
    if (tax['adjusted-tax-income'].items) {
      tax['adjusted-tax-income'].items.forEach((item) => {
        if (
          item.key === Object.keys(item).includes('key')
            ? ['franking-credits', 'franking-credits-received'].includes(item.key)
            : financialKeyValuePair[item.title] === 'franking-credits'
        ) {
          value -= Number(frankingCredits);
        } else if (
          item.key === Object.keys(item).includes('key')
            ? item.key === 'amortisation-added-back'
            : financialKeyValuePair[item.title] === 'amortisation-added-back'
        ) {
          value -= Number(amortisation);
        } else if (
          item.key === Object.keys(item).includes('key')
            ? item.key === 'deductible-depreciation'
            : financialKeyValuePair[item.title] === 'deductible-depreciation'
        ) {
          value = value - Number(rentalDepreciation) - Number(otherDepreciation);
        } else {
          if (item.data[year]) {
            value += Number(item.data[year]);
          }
        }
      });
    }
  } else {
    value =
      value -
      Number(rentalDepreciation) -
      Number(otherDepreciation) -
      Number(frankingCredits) -
      Number(amortisation);
  }
  return value;
};

export const calculateTaxOnIncome = (year, tax, financialData, taxBrackets) => {
  let value = calculateAdjustedTaxableIncomePersonal(year, tax, financialData);
  // return calculateTaxLocal(value);
  return calculateIncomeTaxLocal(value, taxBrackets);
};

export const calculateTaxLocal = (income) => {
  if (income < 18200) {
    return 0;
  } else if (income <= 45000) {
    return (income - 18200) * 0.19;
  } else if (income <= 120000) {
    return parseFloat(((income - 45000) * 0.325 + 5092).toFixed(2));
  } else if (income <= 180000) {
    return parseFloat(((income - 120000) * 0.37 + 29467).toFixed(2));
  } else if (income > 180000) {
    return parseFloat(((income - 180000) * 0.45 + 51667).toFixed(2));
  } else {
    return 0;
  }
};
function calculateIncomeTaxLocal(income, taxBrackets) {
  let remainingIncome = income;
  let totalTax = 0;

  for (const bracket of taxBrackets) {
    if (remainingIncome <= 0) {
      break;
    }

    const taxableIncomeInBracket = Math.min(
      remainingIncome,
      (bracket.maxincome ? bracket.maxincome : Infinity) -
        (bracket.minincome ? bracket.minincome : 0)
    );
    const taxAmountInBracket = taxableIncomeInBracket * (bracket.rate / 100);

    totalTax += taxAmountInBracket;
    remainingIncome -= taxableIncomeInBracket;
  }
  return totalTax;
}
export const calculatePayableTaxPersonal = (year, tax, financialData, taxBrackets) => {
  let value = taxBrackets && calculateTaxOnIncome(year, tax, financialData, taxBrackets);
  let frankingCredits = 0;
  if (financialData['adjusted-ebitda'] && financialData['ebitda'].items) {
    let fArray = financialData['adjusted-ebitda'].items.filter(function (item) {
      return Object.keys(item).includes('key')
        ? ['franking-credits', 'franking-credits-received'].includes(item.key)
        : financialKeyValuePair[item.title] === 'franking-credits';
    });
    if (fArray.length > 0 && fArray[0].data[year]) {
      frankingCredits = fArray[0].data[year];
    }
  }

  if (tax['deductions']) {
    if (tax['deductions'].items) {
      tax['deductions'].items.forEach((item) => {
        if (['franking-credits', 'franking-credits-received'].includes(item.key)) {
          value += Number(frankingCredits);
        } else {
          if (item.data[year]) {
            value += Number(item.data[year]);
          }
        }
      });
    }
  } else {
    value += Number(frankingCredits);
  }
  return value;
};
