import React, { useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router';
import { authState, doLogout, setLogoutResponse } from '../../slices/slices/AuthSlice';
import { GetCookies } from '../../services/cookies';
import { Link } from 'react-router-dom';

const UserHeader = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const json_str = GetCookies('permission');
  const permission = json_str ? JSON.parse(JSON.stringify(json_str)) : [];

  const { logoutResponse } = useSelector(authState);

  const signOut = () => {
    dispatch(doLogout());
  };

  useEffect(() => {
    if (logoutResponse.message) {
      // navigate('/login');
      dispatch(setLogoutResponse({}));
    }
  }, [logoutResponse.message]);
  return (
    <div id="header" className="header fixed-top d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-between">
        <a href="/dashboard" className="logo d-flex align-items-center">
          <img src={`${window.location.origin}/images/logo.png`} alt="Influence partners" />
        </a>
        <i className="bi bi-list toggle-sidebar-btn"></i>
      </div>
      <div className="top-navbar">
        <ul>
          <li>
            <a href="/dashboard">Home</a>
          </li>
          {permission.includes('disp-templates') && (
            <li className="dropdown">
              <Link to="">Tools &amp; Templates</Link>
              <ul className="dropdownmenu">
                {permission.includes('disp-accountants') && (
                  <li>
                    <Link to={'/tools-templates/accountants'}>Accountants</Link>
                  </li>
                )}
                {permission.includes('disp-brokers') && (
                  <li>
                    <Link to={'/tools-templates/brokers'}>Brokers</Link>
                  </li>
                )}
              </ul>
            </li>
          )}
          {/* <li>
            <a href="/subscription-billing">Subscriptions &amp; Billing</a>
          </li>
          <li>
            <a href="/help-center">Help</a>
          </li> */}
        </ul>
      </div>

      <nav className="header-nav">
        <NavDropdown
          title=""
          id="nav-dropdown"
          className="d-flex align-items-center icon-user-profile"
        >
          <NavDropdown.Item href="/setting" className="dropdown-item d-flex align-items-center">
            Settings
          </NavDropdown.Item>
          {permission.includes('staff-management') && (
            <NavDropdown.Item
              href="/staff-management"
              className="dropdown-item d-flex align-items-center"
            >
              Staff Management
            </NavDropdown.Item>
          )}
          {/* {permission.includes('practice-setting') && (
            <NavDropdown.Item
              href="/practice-setting"
              className="dropdown-item d-flex align-items-center"
            >
              Practice Settings
            </NavDropdown.Item>
          )} */}
          {permission.includes('administrator') && (
            <NavDropdown.Item
              href="/admin-setting"
              className="dropdown-item d-flex align-items-center"
            >
              Admin Settings
            </NavDropdown.Item>
          )}

          <NavDropdown.Item
            // href="/login"
            onClick={() => {
              signOut();
            }}
            className="dropdown-item d-flex align-items-center"
          >
            Sign Out
          </NavDropdown.Item>
        </NavDropdown>
      </nav>
    </div>
  );
};

export default UserHeader;
