import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getGoogleRegister, settingState } from '../../../slices/slices/SettingSlice';
import Button from '../../../components/Button';

const TwoStepAuthentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { googleResponse } = useSelector(settingState);
  useEffect(() => {
    dispatch(getGoogleRegister());
  }, []);

  const setUp = () => {
    navigate('/authentication-code-setting');
  };

  return (
    <div className="setting toggle-sidebar">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <p onClick={() => navigate(-1)} className="backlink">
            Back
          </p>
          <div className="container two-factor-screen">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <h4>Follow these steps on phone</h4>
                <div className="auth-qr">
                  <p>
                    <strong>Step 1:</strong> Open your Google Authenticator app.
                  </p>
                  <p>
                    <strong>Step 2:</strong> Add your account to app by scanning this QR code.
                    <img src={'data:image/svg+xml;base64,' + googleResponse.data} alt="" />
                  </p>
                  <p>
                    <strong>Step 3:</strong> Enter the 6 digit code you found in your authenticator
                    app in next screen.
                  </p>
                  <div className="col-12 topgap btnarea justify-content-center">
                    <Button
                      className="btn btn-primary w-100"
                      type="submit"
                      onClick={() => {
                        setUp();
                      }}
                    >
                      Enter Code
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default TwoStepAuthentication;
