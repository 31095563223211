import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomModal from '../../../../../components/CustomModal';
import {
  rentalState,
  getCommercialRentalListData,
  deleteRental,
  setDeleteRentalDataResponse
} from '../../../../../slices/slices/RentalSlice';
import Button from '../../../../../components/Button';
import TableHeadTooltip from '../../../../../components/tooltip/TableHeadTooltip';
import tableHead from '../../../../../TableHeadConstant';
import { formatNumber } from '../../../../common/Misc';
import NumberFormat from 'react-number-format';

const Commercial = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [rentalId, setRentalId] = useState(0);
  const [tenantId, setTenantId] = useState(0);
  const deleteRentalDataResponse = useSelector(rentalState);
  const commercialRentalListDataResponse = useSelector(rentalState);
  const { jobId, memberId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let rental_total = 0;
  let rental_sensitised_total = 0;

  const handleDelete = () => {
    setShowDelete(false);
  };

  const delRental = () => {
    setShowDelete(false);
    dispatch(deleteRental(jobId, memberId, rentalId, tenantId));
    dispatch(getCommercialRentalListData(jobId, memberId));
  };

  const handleCancel = () => {
    setShowDelete(false);
  };

  useEffect(() => {
    if (deleteRentalDataResponse?.message) {
      setShowDelete(false);
      dispatch(setDeleteRentalDataResponse({}));
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/rentals');
    }
  }, [deleteRentalDataResponse?.message]);

  useEffect(() => {
    dispatch(getCommercialRentalListData(jobId, memberId));
  }, [showDelete]);

  return (
    <div id="myTabjustifiedContent">
      <div
        className="tab-pane fade show active"
        id="commercial-justified"
        role="tabpanel"
        aria-labelledby="commercial-tab"
      >
        <div className="commercialview" style={{ padding: '0' }}>
          <Table className="table">
            <thead>
              <tr>
                <th className="adr text-start" style={{ width: '19%' }}>
                  Address
                </th>
                <th className="tenname text-start" style={{ width: '15%' }}>
                  Tenant Name
                </th>
                <th style={{ width: '12%' }}>Tenant Activity</th>
                <th className="rentalex" style={{ width: '10%' }}>
                  Rental
                  <br />
                  (Ex. GST p.a.)
                </th>
                <th style={{ width: '6%' }}>Shading</th>
                <th style={{ width: '12%' }}>
                  Sensitised Rental
                  <br />
                  (Ex. GST p.a.)
                </th>
                <th style={{ width: '10%' }}>Income To Maturity</th>
                <th className="time">
                  Time To
                  <br /> Maturity (Mths)
                </th>
                <th style={{ width: '5%' }}>
                  <TableHeadTooltip
                    labelId={tableHead.rentalList.rentalListTip.key}
                    label={tableHead.rentalList.rentalListTip.label}
                    hover_text={tableHead.rentalList.rentalListTip.hover_text}
                  />
                </th>
                <th style={{ width: '120px' }}></th>
              </tr>
            </thead>
            <tbody>
              {commercialRentalListDataResponse?.commercialRentalListDataResponse &&
              commercialRentalListDataResponse?.commercialRentalListDataResponse?.data?.data
                ?.length > 0 ? (
                commercialRentalListDataResponse?.commercialRentalListDataResponse?.data?.data.map(
                  (item) => {
                    return (
                      <>
                        {item?.additional_info
                          ? item?.additional_info?.tenant.map((additionalItem, index) => {
                              rental_total +=
                                (additionalItem?.current_rental_income_pa *
                                  item?.rental_owners[0]?.pivot?.holding) /
                                100;
                              rental_sensitised_total +=
                                (additionalItem?.sensitised_rental *
                                  item?.rental_owners[0]?.pivot?.holding) /
                                100;
                              return (
                                <>
                                  <tr key={item?.id}>
                                    <td className="text-start">{item?.address}</td>
                                    <td className="text-start">{additionalItem?.tenant_name}</td>
                                    <td>{additionalItem?.tenant_activity}</td>
                                    <td className="text-end">
                                      {formatNumber(
                                        (additionalItem?.current_rental_income_pa *
                                          item?.rental_owners[0]?.pivot?.holding) /
                                          100
                                      )}
                                    </td>
                                    <td>{formatNumber(additionalItem?.shading)}</td>
                                    <td className="text-end">
                                      {formatNumber(
                                        (additionalItem?.sensitised_rental *
                                          item?.rental_owners[0]?.pivot?.holding) /
                                          100
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {formatNumber(
                                        (additionalItem?.income_to_maturity *
                                          item?.rental_owners[0]?.pivot?.holding) /
                                          100
                                      )}
                                    </td>
                                    <td className="time">
                                      {additionalItem?.time_to_maturity !== 0
                                        ? additionalItem?.time_to_maturity
                                        : '0 Months'}
                                    </td>
                                    <td>
                                      {parseFloat(
                                        Math.round(
                                          additionalItem?.wale *
                                            item?.rental_owners[0]?.pivot?.holding
                                        ) / 100
                                      ).toFixed(2)}
                                    </td>
                                    <td className="actionarea" width="10%">
                                      <i
                                        className={
                                          item?.is_completed == 1
                                            ? 'fa fa-check-circle'
                                            : 'fa fa-exclamation-triangle'
                                        }
                                        aria-hidden="true"
                                        style={{
                                          color: `${
                                            item?.is_completed == 1 ? '#42ba96' : '#eed202'
                                          }`
                                        }}
                                      ></i>
                                      <Link
                                        className="edit"
                                        to={
                                          '/job/' +
                                          jobId +
                                          '/data-entry/' +
                                          memberId +
                                          '/edit-commercial-rental/' +
                                          item?.id
                                        }
                                      >
                                        <i className="icon-edit"></i>
                                      </Link>
                                      <a
                                        onClick={() => {
                                          setRentalId(item?.id);
                                          setTenantId(index);
                                          setShowDelete(true);
                                        }}
                                        className="delete"
                                      >
                                        <i className="icon-delete"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : null}
                      </>
                    );
                  }
                )
              ) : (
                <tr>
                  <td colSpan={10} style={{ textAlign: 'center' }}>
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td className="text-end">
                  <NumberFormat
                    value={formatNumber(rental_total)}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    // decimalScale={2}
                    displayType="text"
                  />
                </td>
                <td></td>
                <td className="text-end">
                  <NumberFormat
                    value={formatNumber(rental_sensitised_total)}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    // decimalScale={2}
                    displayType="text"
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
      <CustomModal
        value="delete"
        show={showDelete}
        title="Delete Member?"
        text="Are you sure you want to delete this Member permanently?"
        cancel={handleCancel}
        success={handleDelete}
      />
      <Modal
        show={showDelete}
        onHide={handleDelete}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Delete Rental?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this rental permanently?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button className="btn btn-secondary" onClick={handleDelete}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              delRental();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Commercial;
