import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import TransactionModel from '../../../../components/modal/TransactionModel';
import TransactionItem from '../../../../components/job/groupAnalysis/proposedTransaction/TransactionItem';
import Header from '../../Header';
// import Pagination from '../../../common/Pagination';
import { Form } from 'react-bootstrap';
import FormActions from '../../../../components/FormActions';
import constants from '../../../../constant';
// import Button from '../../../../components/Button';
import { Formik } from 'formik';
import {
  ProposedTransactionState,
  getTransactionList,
  AddProposedTransaction,
  updateProposedTransaction
} from '../../../../slices/slices/ProposedTransactionSlice';
import { commonState, getConfig } from '../../../../slices/slices/CommonSlice';
import DialogModal from '../../../../components/modal/DialogModal';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const ProposedTransaction = () => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [showModal, setshowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') ? false : true);
  const [error, setEror] = useState(1);
  const { configList } = useSelector(commonState);

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation, isParams] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const getAllTransactionList = useSelector(ProposedTransactionState);

  const getcommentData = () => {
    if (getAllTransactionList?.transactionList?.data?.data) {
      return getAllTransactionList?.transactionList?.data?.data.map((item) => {
        return {
          transactionId: item.id,
          associated_internal_notes: item?.additional_info?.associated?.internal_notes
            ? item.additional_info.associated.internal_notes
            : '',
          associated_report_comments: item?.additional_info?.associated?.report_comments
            ? item.additional_info.associated.report_comments
            : '',
          funds_internal_notes: item?.additional_info?.funds?.internal_notes
            ? item.additional_info.funds.internal_notes
            : '',
          funds_report_comments: item?.additional_info?.funds?.report_comments
            ? item.additional_info.funds.report_comments
            : ''
        };
      });
    } else {
      return [];
    }
  };

  const initialValue = {
    comment: getcommentData(),
    is_completed: 1
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    let params = getdataformat(values);
    let data = {};
    data.is_completed = values.is_completed;
    data.transactions = [...params.associated, ...params.funds];
    dispatch(updateProposedTransaction(jobId, data, setErrors));
  };

  const getdataformat = (values) => {
    let fundarray = new Array();
    let associatedarray = new Array();
    values.comment.map((item) => {
      let funds = {
        id: item.transactionId,
        report_comments: item.funds_report_comments,
        internal_notes: item.funds_internal_notes,
        type: 'funds'
      };
      let associates = {
        report_comments: item.associated_report_comments,
        internal_notes: item.associated_internal_notes,
        type: 'associated-transaction'
      };
      fundarray.push(funds);
      associatedarray.push({ ...associates, id: funds.id });
    });

    return { funds: fundarray, associated: associatedarray };
  };

  const handleModalShow = () => {
    setEror(0);
    setshowModal(true);
  };

  const handleModalClose = () => {
    setshowModal(false);
  };

  useEffect(() => {
    if (jobId) dispatch(getTransactionList({ jobId }));
    dispatch(getConfig(configList));
  }, []);

  const handleCreateTransaction = (values, { setErrors }) => {
    if (checkError()) {
      const data = { values };
      dispatch(AddProposedTransaction(jobId, data, setErrors)).then((response) => {
        if (typeof response != 'string') {
          window.location.reload(true);
          // dispatch(getTransactionList({ jobId }));
          // handleModalClose();
        } else {
          setErrors({
            transaction_name: 'Transaction Name Alreay Exist'
          });
        }
      });
    } else {
      setErrors({
        associate_name:
          'Transaction with the empty data already exist, so new Transaction cannot be created'
      });
    }
  };

  const checkError = () => {
    if (getAllTransactionList?.transactionList?.data?.data?.length > 0) {
      for (let [, item] of Object.entries(getAllTransactionList?.transactionList?.data?.data)) {
        if (
          item?.additional_info == null &&
          item?.application_of_funds.length == 0 &&
          item?.associated_transactions.length == 0 &&
          item?.source_of_funds.length == 0
        ) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <>
      <div className="dashboard toggle-sidebar">
        <Header />
        <main id="main" className="main">
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
                </li>
                <li>
                  <a href="#">Group Facilities & Security</a>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Proposed Transaction</strong>
                </li>
              </ul>
            </div>
            <div className="breadcrumb-right">
              <DialogModal
                title="Proposed Transaction"
                {...(activeTab
                  ? {
                      title: 'Proposed Transaction',
                      p1: constants.proposed_transaction1,
                      p2: constants.proposed_transaction2,
                      p3: constants.proposed_transaction3,
                      p4: constants.proposed_transaction4,
                      p5: constants.proposed_transaction5
                    }
                  : {
                      title: 'Associated Transaction',
                      p1: constants.associated_transaction_info
                    })}
              />
            </div>
          </div>

          <div className="pagetitle groupanalysispro">
            <div className="title_pro d-flex justify-content-between align-items-center">
              <h1>Proposed Transaction</h1>
              <div className="addmemberarea">
                {getAllTransactionList?.transactionList?.data?.data.length > 0 && (
                  <button
                    type="button"
                    className="button green"
                    data-bs-toggle="modal"
                    data-bs-target="#verticalycentered"
                    onClick={() => handleModalShow()}
                  >
                    <i className="icon-plus"></i>Transaction
                  </button>
                )}

                <TransactionModel
                  showModal={showModal}
                  handleModalClose={handleModalClose}
                  handleFormSubmit={handleCreateTransaction}
                  error={error}
                />
              </div>
            </div>
          </div>
          <div className="propose-trans">
            <Formik initialValues={initialValue} enableReinitialize onSubmit={handleFormSubmit}>
              {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                return (
                  <>
                    {' '}
                    <DialogLeavingPage
                      showDialog={showDialogLeavingPage}
                      confirmNavigation={confirmNavigation}
                      cancelNavigation={cancelNavigation}
                      isParams={isParams}
                    />
                    <Form onSubmit={handleSubmit} className="facility-form form-data">
                      {getAllTransactionList &&
                        getAllTransactionList?.transactionList?.data?.data.length > 0 &&
                        getAllTransactionList.transactionList.data.data.map(
                          (transaction, index) => {
                            return (
                              <TransactionItem
                                key={index}
                                keyIndex={index}
                                transactionList={transaction}
                                errors={errors}
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                handleInputChange={handleInputChange}
                                handleBlur={handleBlur}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                configList={configList}
                              />
                            );
                          }
                        )}
                      {getAllTransactionList?.transactionList?.data?.data.length > 0 ? (
                        <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
                      ) : (
                        ''
                      )}
                    </Form>
                  </>
                );
              }}
            </Formik>
            {getAllTransactionList?.transactionList?.data?.data.length == 0 && (
              <div className="blank blank-household">
                <p>You have no Proposed Transactions. Add new from click the button below</p>
                <button
                  type="button"
                  className="button green"
                  data-bs-toggle="modal"
                  data-bs-target="#verticalycentered"
                  onClick={() => handleModalShow()}
                >
                  <i className="icon-plus"></i>Transaction
                </button>
              </div>
            )}
          </div>
          <div></div>
        </main>
      </div>
    </>
  );
};
export default ProposedTransaction;
