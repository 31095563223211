import { roundOff } from '../pages/common/Misc';

const Calculations = () => {};

// Common Formula
function interest(R, n) {
  let frequency = Number(n);
  let rate = Number(R);
  if (frequency === 52) {
    return (rate / 100) * (7 / 364);
  } else if (frequency === 26) {
    return (rate / 100) * (14 / 364);
  } else if (frequency === 12) {
    return rate / 100 / frequency;
  } else if (frequency === 4) {
    return rate / 100 / 4;
  } else if (frequency === 2) {
    return rate / 100 / 2;
  } else if (frequency === 1) {
    return rate / 100;
  }
}

function annualInterestRate(P, A, n, frequency, B, type) {
  if (P && A && n && frequency) {
    let interest = 5;
    let amount = 0;
    let lowest = 0;
    let highest = 5;
    let count = 0;
    do {
      if (type === 'p-i-in-arrears') {
        if (B > 0) {
          amount = Calculations.piArrearsAmountBalloon(P, interest, n, B, frequency);
        } else {
          amount = Calculations.piArrearsAmount(P, interest, n, frequency);
        }
      } else if (type === 'p-i-in-advance') {
        if (B > 0) {
          amount = Calculations.piAdvanceAmountBalloon(P, interest, n, B, frequency);
        } else {
          amount = Calculations.piAdvanceAmount(P, interest, n, frequency);
        }
      }
      if (amount > A) {
        highest = interest;
        interest = (highest - lowest) / 2 + lowest;
      } else {
        lowest = interest;
        interest = (lowest + highest) / 2;
        // if (Math.round(lowest) === highest) {
        //   highest = Math.round(lowest) + 5;
        // }
        if (roundOff(lowest) === highest) {
          highest = roundOff(lowest) + 5;
        }
      }
      count++;
    } while (amount.toFixed(2) !== parseFloat(A).toFixed(2) && count < 40);
    return interest;
  }
}

function getFields() {
  return ['proposed_limit', 'interest_rate_pa', 'repayment_amount', 'repayment_term_months'];
}

// Raw Formulas Arrears
function piArrearsAmount(P, R, n, frequency) {
  if (P && R && n) {
    let r = Calculations.interest(R, frequency);
    let period = (n / 12) * frequency;
    return P * ((r * (1 + r) ** period) / ((1 + r) ** period - 1));
  } else {
    return 0;
  }
}

function piArrearsPrincipal(A, R, n, frequency) {
  if (A && R && n) {
    let r = Calculations.interest(R, frequency);
    let period = (n / 12) * frequency;
    return (A * ((1 + r) ** period - 1)) / (r * (1 + r) ** period);
  } else {
    return false;
  }
}

function piArrearsRepaymentTerm(A, R, P, frequency) {
  if (A && R && P) {
    let r = Calculations.interest(R, frequency);
    let period = Math.log(A / (A - r * P)) / Math.log(1 + r);
    // return Math.round((period / frequency) * 12);
    return roundOff((period / frequency) * 12);
  } else {
    return false;
  }
}

function piArrearsAmountBalloon(P, R, n, B, frequency) {
  if (P && R && n && B) {
    let r = Calculations.interest(R, frequency);
    let period = (n / 12) * frequency;
    return (P - B / (1 + r) ** period) * (r / (1 - (1 + r) ** -period));
  } else {
    return false;
  }
}

function piArrearsPrincipalBalloon(A, R, n, B, frequency) {
  if (A && R && n && B) {
    let r = Calculations.interest(R, frequency);
    let period = (n / 12) * frequency;
    return (A * (1 - (1 + r) ** -period)) / r + B / (r + 1) ** period;
  } else {
    return false;
  }
}

function piArrearsRepaymentTermsBalloon(A, R, P, B, frequency) {
  if (A && R && P && B) {
    let r = Calculations.interest(R, frequency);
    // return Math.round(-(Math.log((r * P - A) / (-A + B * r)) / Math.log(1 + r)));
    return roundOff(-(Math.log((r * P - A) / (-A + B * r)) / Math.log(1 + r)));
  } else {
    return false;
  }
}

// Raw Formulas Advance
function piAdvanceAmount(P, R, n, frequency) {
  if (P && R && n && frequency) {
    let r = Calculations.interest(R, frequency);
    return (P * r * (1 + r) ** (n - 1)) / ((1 + r) ** (n - 1) - 1 + r * (1 + r) ** (n - 1));
  } else {
    return false;
  }
}

function piAdvancePrincipal(A, R, n, frequency) {
  if (A && R && n && frequency) {
    let r = Calculations.interest(R, frequency);
    let amount = Number(A);
    let period = (n / 12) * frequency;
    return amount * (((1 + r) ** period - 1) / (r * (1 + r) ** period)) + amount;
  } else {
    return false;
  }
}

function piAdvanceRepaymentTerm(A, R, P, frequency) {
  if (A && R && P && frequency) {
    let r = Calculations.interest(R, frequency);
    let period = Math.log(-A / (r * P - A * r - A)) / Math.log(1 + r);
    // return Math.round((period / frequency) * 12);
    return roundOff((period / frequency) * 12);
  } else {
    return false;
  }
}

function piAdvanceAmountBalloon(P, R, term, B, frequency) {
  if (B && P && R && term && frequency) {
    let n = term;
    return Calculations.PMT(Number(R / 100 / 12), Number(n), Number(-P), Number(B), 1);
  } else {
    return false;
  }
}

function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);
  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}

function piAdvancePrincipalBalloon(A, R, term, B, frequency) {
  if (B && A && R && term && frequency) {
    let n = term - 1;
    let r = Calculations.interest(R, frequency);
    let amount = Number(A);
    let period = (n / 12) * frequency;
    return amount * ((1 - (1 + r) ** -period) / r) + B / (1 + r) ** period + amount;
  } else {
    return false;
  }
}

function piAdvanceRepaymentTermBalloon(A, R, P, B, frequency) {
  if (A && R && P && frequency) {
    let r = Calculations.interest(R, frequency);
    let period = Math.log((r * P - A * r - A) / (-A + B * r)) / Math.log(1 + r);
    // if (-Math.round((period / frequency) * 12) > 0) {
    //   return Math.round(-Math.round((period / frequency) * 12) + 1);
    // }
    if (-roundOff((period / frequency) * 12) > 0) {
      return roundOff(-roundOff((period / frequency) * 12) + 1);
    }
    return 0;
  } else {
    return false;
  }
}

// Raw Formulas Simple Interest
function calculateAmount(monthly = true, amount, n, frequency) {
  if (monthly) {
    return amount / ((n / 12) * frequency);
  } else {
    return amount * (n / 12) * frequency;
  }
}

function simpleInterestAmount(P, R, n, frequency) {
  if (P && R && n && frequency) {
    let r = R / 100;
    let amount = P * (1 + r * (n / 12));
    return calculateAmount(true, amount, n, frequency);
  } else {
    return false;
  }
}

function simpleInterestPrincipal(A, R, n, frequency) {
  if (A && R && n && frequency) {
    let r = R / 100;
    let amount = calculateAmount(false, A, n, frequency);
    return amount / (1 + r * (n / 12));
  } else {
    return false;
  }
}

function simpleInterestAnnualRate(P, A, n, frequency) {
  if (P && A && n && frequency) {
    let amount = calculateAmount(false, A, n, frequency);
    return ((amount - P) / (P * (n / 12))) * 100;
  } else {
    return false;
  }
}

// We are finding n
// A = Repayment_Amount 250
// P = Proposed_Limit 10,000
// R = Interest_Rate 10
// f = Monthly
// n => 60
/**
 * @param {Repayment_Amount} P
 * @param {Proposed_Limit} A
 * @param {Interest_Rate} R
 * @param {Monthly} frequency
 * @returns
 */
function simpleInterestRepaymentTerm(P, A, R, frequency) {
  if (P && !isNaN(A) && R && frequency) {
    let r = R / 100;
    let amount = calculateAmount(false, A, 12, frequency);
    return (amount - P) / (r * P);
  } else {
    return false;
  }
}

// Raw Formulas Interest Only
function interestOnlyAmount(P, R, frequency) {
  if (P && R && frequency) {
    let r = Calculations.interest(R, frequency);
    return P * r;
  } else {
    return false;
  }
}

function interestOnlyAnnualRate(P, A, frequency) {
  if (P && A && frequency) {
    return (100 * A * frequency) / P;
  } else {
    return false;
  }
}

function interestOnlyPrincipal(A, R, frequency) {
  if (A && R && frequency) {
    return (frequency * A) / (R / 100);
  } else {
    return false;
  }
}

// Raw Formulas for Interest Capitalisation
function interestCapitalisationAmount(P, R, n, frequency) {
  if (P && R && n && frequency) {
    let r = R / 100;
    let t = n / 12;
    let amount = P * (1 + r / frequency) ** (frequency * t);
    return (amount - P) / ((364 / (frequency * 30.417)) * t);
  } else {
    return false;
  }
}

function interestCapitalisationAnnualRate(P, A, n, frequency) {
  if (P && A && n && frequency) {
    let t = n / 12;
    let power = (Math.log(A) - Math.log(P)) / (frequency * t);
    return (frequency * Math.exp(power) - frequency) * 100;
  } else {
    return false;
  }
}

function interestCapitalisationPrincipal(A, R, n, frequency) {
  if (A && R && n && frequency) {
    let r = R / 100;
    let t = n / 12;
    return (A * frequency ** (frequency * t)) / (frequency + r) ** (frequency * t);
  } else {
    return false;
  }
}

function interestCapitalisationRepaymentTerm(P, A, R, frequency) {
  if (P && !isNaN(A) && R && frequency) {
    let r = R / 100;
    let n =
      (Math.log(A) - Math.log(P)) / (frequency * (Math.log(r + frequency) - Math.log(frequency)));
    return n * 12;
  } else {
    return false;
  }
}

function notional(P, creditCardRate) {
  if (P) {
    return (P * creditCardRate) / 100;
  }
  return 0;
}

// Calculation Loops
function calculateValues(
  field,
  existingValues,
  type,
  frequency,
  balloon,
  facility,
  creditCardRate
) {
  let facility_fee = 0;
  let value = 0;
  let fields = Calculations.getFields();
  if (!isNaN(Number(facility))) {
    facility_fee = facility;
  }

  if (field === fields[0]) {
    if (type === 'p-i-in-arrears') {
      if (balloon > 0) {
        value = parseFloat(
          Calculations.piArrearsPrincipalBalloon(
            existingValues[fields[2]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[3]]),
            balloon,
            frequency
          )
        ).toFixed(2);
      } else {
        value = parseFloat(
          Calculations.piArrearsPrincipal(
            existingValues[fields[2]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[3]]),
            frequency
          )
        ).toFixed(2);
      }
    } else if (type === 'p-i-in-advance') {
      if (balloon > 0) {
        value = parseFloat(
          Calculations.piAdvancePrincipalBalloon(
            existingValues[fields[2]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[3]]),
            balloon,
            frequency
          )
        ).toFixed(2);
      } else {
        value = parseFloat(
          Calculations.piAdvancePrincipal(
            existingValues[fields[2]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[3]]),
            frequency
          )
        ).toFixed(2);
      }
    } else if (type === 'interest-only') {
      value = parseFloat(
        Calculations.interestOnlyPrincipal(
          existingValues[fields[2]],
          Number(existingValues[fields[1]]) + Number(facility_fee),
          frequency
        )
      ).toFixed(2);
    } else if (type === 'simple-interest') {
      value = parseFloat(
        Calculations.simpleInterestPrincipal(
          existingValues[fields[2]],
          Number(existingValues[fields[1]]) + Number(facility_fee),
          Number(existingValues[fields[3]]),
          frequency
        )
      ).toFixed(2);
    } else if (type === 'interest-capitalisation') {
      value = parseFloat(
        Calculations.interestCapitalisationPrincipal(
          existingValues[fields[2]],
          Number(existingValues[fields[1]]) + Number(facility_fee),
          Number(existingValues[fields[3]]),
          frequency
        )
      ).toFixed(2);
    }
  } else if (field === fields[1]) {
    if (type === 'p-i-in-arrears' || type === 'p-i-in-advance') {
      value = parseFloat(
        Calculations.annualInterestRate(
          existingValues[fields[0]],
          existingValues[fields[2]],
          existingValues[fields[3]],
          frequency,
          balloon,
          type
        )
      ).toFixed(2);
    } else if (type === 'interest-only') {
      value = parseFloat(
        Calculations.interestOnlyAnnualRate(
          existingValues[fields[0]],
          existingValues[fields[2]],
          frequency
        )
      ).toFixed(2);
    } else if (type === 'simple-interest') {
      value = parseFloat(
        Calculations.simpleInterestAnnualRate(
          existingValues[fields[0]],
          existingValues[fields[2]],
          Number(existingValues[fields[3]]),
          frequency
        )
      ).toFixed(2);
    } else if (type === 'interest-capitalisation') {
      value = parseFloat(
        Calculations.interestCapitalisationAnnualRate(
          existingValues[fields[0]],
          existingValues[fields[2]],
          Number(existingValues[fields[3]]),
          frequency
        )
      ).toFixed(2);
    }
  } else if (field === fields[2]) {
    if (type === 'p-i-in-arrears') {
      if (balloon > 0) {
        value = parseFloat(
          Calculations.piArrearsAmountBalloon(
            existingValues[fields[0]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[3]]),
            balloon,
            frequency
          )
        ).toFixed(2);
      } else {
        value = parseFloat(
          Calculations.piArrearsAmount(
            existingValues[fields[0]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[3]]),
            frequency
          )
        ).toFixed(2);
      }
    } else if (type === 'p-i-in-advance') {
      if (balloon > 0) {
        value = parseFloat(
          Calculations.piAdvanceAmountBalloon(
            existingValues[fields[0]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[3]]),
            balloon,
            frequency
          )
        ).toFixed(2);
      } else {
        value = parseFloat(
          Calculations.piAdvanceAmount(
            existingValues[fields[0]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[3]]),
            frequency
          )
        ).toFixed(2);
      }
    } else if (type === 'interest-only') {
      value = parseFloat(
        Calculations.interestOnlyAmount(
          existingValues[fields[0]],
          Number(existingValues[fields[1]]) + Number(facility_fee),
          frequency
        )
      ).toFixed(2);
    } else if (type === 'simple-interest') {
      value = Calculations.simpleInterestAmount(
        existingValues[fields[0]],
        Number(existingValues[fields[1]]) + Number(facility_fee),
        Number(existingValues[fields[3]]),
        frequency
      );
    } else if (type === 'sensitised-p-i-at-380-pm') {
      value = parseFloat(Calculations.notional(existingValues[fields[0]], creditCardRate)).toFixed(
        2
      );
    } else if (type === 'interest-capitalisation') {
      value = Calculations.interestCapitalisationAmount(
        existingValues[fields[0]],
        Number(existingValues[fields[1]]) + Number(facility_fee),
        Number(existingValues[fields[3]]),
        frequency
      ).toFixed(2);
    }
  } else if (field === fields[3]) {
    if (type === 'p-i-in-arrears') {
      if (balloon > 0) {
        value = Number(
          Calculations.piArrearsRepaymentTermsBalloon(
            existingValues[fields[2]],
            Number(existingValues[fields[1]]) + Number(facility_fee),
            Number(existingValues[fields[0]]),
            balloon,
            frequency
          )
        );
      } else {
        value = Calculations.piArrearsRepaymentTerm(
          existingValues[fields[2]],
          Number(existingValues[fields[1]]) + Number(facility_fee),
          existingValues[fields[0]],
          frequency
        );
      }
    } else if (type === 'p-i-in-advance') {
      if (balloon > 0) {
        value = Calculations.piAdvanceRepaymentTermBalloon(
          existingValues[fields[2]],
          Number(existingValues[fields[1]]) + Number(facility_fee),
          Number(existingValues[fields[0]]),
          balloon,
          frequency
        );
      } else {
        value = Calculations.piAdvanceRepaymentTerm(
          existingValues[fields[2]],
          Number(existingValues[fields[1]]) + Number(facility_fee),
          Number(existingValues[fields[0]]),
          frequency
        );
      }
    } else if (type === 'simple-interest') {
      value = Calculations.simpleInterestRepaymentTerm(
        existingValues[fields[0]],
        existingValues[fields[2]],
        Number(existingValues[fields[1]]) + Number(facility_fee),
        frequency
      );
    } else if (type === 'interest-capitalisation') {
      value = parseFloat(
        Calculations.interestCapitalisationRepaymentTerm(
          existingValues[fields[0]],
          existingValues[fields[2]],
          Number(existingValues[fields[1]]) + Number(facility_fee),
          frequency
        )
      ).toFixed(2);
    }
  }
  return value;
}

// Current Balance Calculation
function calculateCurrentBalance(P, B, R, fee, A, n, remaining, type) {
  let rate = Number(R);
  if (fee !== '' && fee > 0) {
    rate = Number(R) + Number(fee);
  }
  if (type === 'p-i-in-arrears') {
    return Calculations.currentBalancePIArrears(P, B, rate, A, n, remaining);
  } else if (type === 'p-i-in-advance') {
    return Calculations.currentBalancePIAdvance(P, B, rate, A, n, remaining);
  } else if (type === 'interest-only' || type === 'interest-capitalisation') {
    return Calculations.currentBalanceInterestOnly(P);
  } else if (type === 'simple-interest') {
    return Calculations.currentBalanceSimpleInterest(P, rate, n, remaining);
  }
}

function currentBalanceInterestOnly(P) {
  return P;
}

function currentBalanceSimpleInterest(P, R, n, remaining) {
  let principal = Number(P);
  if (P && R && n && remaining) {
    let amount = Calculations.simpleInterestAmount(P, R, n, 12);
    let r = interest(R, 12);
    let difference = n - remaining;
    let cumulative_principal = (amount - P * r * 1) * difference;
    principal = principal - cumulative_principal;
  }
  return principal.toFixed(2);
}

function currentBalancePIArrears(P, B, R, A, n, remaining) {
  let count = 0;
  let amount = 0;
  if (P && R && A && n && remaining) {
    if (B !== '' && B > 0) {
      amount = Calculations.piArrearsAmountBalloon(P, R, n, B, 12);
    } else {
      amount = Calculations.piArrearsAmount(Number(P), Number(R), Number(n), 12);
    }
    let r = interest(R, 12);
    let principal = Number(P);
    let difference = n - remaining;
    while (count < difference) {
      let cumulative_principal = ((amount - r * principal) * ((1 + r) ** 1 - 1)) / r;
      principal = Number(principal) - cumulative_principal;
      count++;
    }
    return principal.toFixed(2);
  }
  return 0;
}

function currentBalancePIAdvance(P, B, R, A, n, remaining) {
  let count = 0;
  let amount = 0;
  if (P && R && A && n && remaining) {
    if (B !== '' && B > 0) {
      amount = Calculations.piAdvanceAmountBalloon(P, R, n, B, 12);
    } else {
      amount = Calculations.piAdvanceAmount(P, R, n, 12);
    }
    let r = Calculations.interest(R, 12);
    let principal = Number(P);
    let difference = n - remaining;
    while (count < difference) {
      let cumulative_principal = ((amount - r * principal) * ((1 + r) ** 1 - 1)) / r;
      principal = Number(principal) - cumulative_principal;
      count++;
    }
    return principal.toFixed(2);
  }
  return 0;
}

function calculatePAValues(P, B, R, fee, A, n, remaining, frequency, type) {
  let rate = Number(R);
  if (fee !== '' && fee > 0) {
    rate = Number(R) + Number(fee);
  }
  if (type === 'p-i-in-arrears') {
    return Calculations.piArrearsInterestRepayment(P, B, rate, A, n, frequency, remaining);
  } else if (type === 'p-i-in-advance') {
    return Calculations.piAdvanceInterestRepayment(P, B, rate, A, n, frequency, remaining);
  } else if (type === 'interest-only') {
    return Calculations.paInterestOnly(P, rate, frequency, remaining);
  } else if (type === 'simple-interest') {
    return Calculations.paSimpleInterest(P, rate, n, frequency, remaining);
  } else if (type === 'sensitised-p-i-at-380-pm') {
    return Calculations.paNotional(P);
  } else if (type === 'interest-capitalisation') {
    return Calculations.paInterestCapitalisation(P, rate, n, frequency, remaining);
  }
}

function paNotional(P) {
  let data = {};
  let amount = Calculations.notional(P);
  data.interest_pa = 0;
  data.repayment_pa = amount * 12;
  return data;
}

function paInterestOnly(P, R, frequency, remaining) {
  let data = {};
  let amount = Calculations.interestOnlyAmount(P, R, frequency);
  if (remaining < 12) {
    data.interest_pa = amount * remaining;
    data.repayment_pa = amount * 12;
  } else {
    data.interest_pa = amount * 12;
    data.repayment_pa = amount * 12;
  }
  return data;
}

function paInterestCapitalisation(P, R, n, frequency, remaining) {
  let data = {};
  let amount = Calculations.interestCapitalisationAmount(P, R, frequency);
  if (remaining < 12) {
    data.interest_pa = amount * remaining;
    data.repayment_pa = amount * 12;
  } else {
    data.interest_pa = amount * 12;
    data.repayment_pa = amount * 12;
  }
  return data;
}

//
function calculateSensitisedPAValues(P, B, R, A, n, remaining, frequency, type) {
  if (type == 'p-i-in-arrears') {
    return Calculations.piArrearsInterestRepayment(P, B, R, A, n, frequency, remaining);
  } else if (type == 'p-i-in-advance') {
    return Calculations.piAdvanceInterestRepayment(P, B, R, A, n, frequency, remaining);
  } else if (type == 'interest-only') {
    return Calculations.paInterestOnly(P, R, frequency, remaining);
  } else if (type == 'simple-interest') {
    return Calculations.paSimpleInterest(P, R, n, frequency, remaining);
  } else if (type === 'interest-capitalisation') {
    return Calculations.paInterestCapitalisation(P, R, n, frequency, remaining);
  }
}
//
function piArrearsInterestRepayment(P, B, R, A, n, frequency, remaining) {
  let data = {};
  if (P && R && A && n && frequency && remaining) {
    let amount = 0;
    if (B !== '' && B > 0) {
      amount = Calculations.piArrearsAmountBalloon(P, R, n, B, 12);
    } else {
      amount = Calculations.piArrearsAmount(Number(P), Number(R), Number(n), 12);
    }
    let r = interest(R, frequency);
    let terms = 12;
    if (remaining < 12) {
      terms = remaining;
    }
    let count = 0;
    let difference = n - remaining;
    let principal = P;
    while (count < difference) {
      let cumulative_principal = ((amount - r * principal) * ((1 + r) ** 1 - 1)) / r;
      let repayment_pa = amount * 1;
      let interest_pa = repayment_pa - cumulative_principal;
      principal = Number(principal) + Number(interest_pa) - Number(repayment_pa);
      count++;
    }
    let cumulative_principal = ((amount - r * principal) * ((1 + r) ** terms - 1)) / r;
    let total_repayment_pa = amount * terms;
    let interest_pa = total_repayment_pa - cumulative_principal;
    data.interest_pa = interest_pa;
    data.repayment_pa = total_repayment_pa;
  }
  return data;
}

function piAdvanceInterestRepayment(P, B, R, A, n, frequency, remaining) {
  let data = {};
  if (P && R && A && n && frequency && remaining) {
    let amount = 0;
    if (B !== '' && B > 0) {
      amount = Calculations.piAdvanceAmountBalloon(P, R, n, B, 12);
    } else {
      amount = Calculations.piAdvanceAmount(P, R, n, 12);
    }
    let r = Calculations.interest(R, frequency);
    let terms = 12;
    if (remaining < 12) {
      terms = remaining;
    }
    let count = 0;
    let difference = n - remaining;
    let principal = P;
    while (count < difference) {
      let cumulative_principal = ((amount - r * principal) * ((1 + r) ** 1 - 1)) / r;
      let repayment_pa = amount * 1;
      let interest_pa = repayment_pa - cumulative_principal;
      principal = Number(principal) + Number(interest_pa) - Number(repayment_pa);
      count++;
    }
    let cumulative_principal = ((amount - r * principal) * ((1 + r) ** terms - 1)) / r;
    let repayment_pa = amount * terms;
    let interest_pa = repayment_pa - cumulative_principal;
    data.interest_pa = interest_pa;
    data.repayment_pa = repayment_pa;
  }
  return data;
}
function paSimpleInterest(P, R, n, frequency, remaining) {
  let data = {};
  if (P && R && n && frequency && remaining) {
    let r = interest(R, frequency);
    let terms = 12;
    if (remaining < 12) {
      terms = remaining;
    }
    let amount = Calculations.simpleInterestAmount(P, R, n, 12);
    let principal = Number(P);
    data.interest_pa = principal * r * terms;
    data.repayment_pa = amount * 12;
  }
  return data;
}

Calculations.interest = interest;
Calculations.getFields = getFields;
Calculations.calculateValues = calculateValues;
Calculations.annualInterestRate = annualInterestRate;

Calculations.piArrearsAmount = piArrearsAmount;
Calculations.piArrearsPrincipal = piArrearsPrincipal;
Calculations.piArrearsRepaymentTerm = piArrearsRepaymentTerm;
Calculations.piArrearsAmountBalloon = piArrearsAmountBalloon;
Calculations.piArrearsPrincipalBalloon = piArrearsPrincipalBalloon;
Calculations.piArrearsRepaymentTermsBalloon = piArrearsRepaymentTermsBalloon;

Calculations.piAdvanceAmount = piAdvanceAmount;
Calculations.piAdvancePrincipal = piAdvancePrincipal;
Calculations.piAdvanceRepaymentTerm = piAdvanceRepaymentTerm;
Calculations.piAdvanceAmountBalloon = piAdvanceAmountBalloon;
Calculations.piAdvancePrincipalBalloon = piAdvancePrincipalBalloon;
Calculations.piAdvanceRepaymentTermBalloon = piAdvanceRepaymentTermBalloon;

Calculations.interestOnlyAmount = interestOnlyAmount;
Calculations.interestOnlyAnnualRate = interestOnlyAnnualRate;
Calculations.interestOnlyPrincipal = interestOnlyPrincipal;

Calculations.simpleInterestAmount = simpleInterestAmount;
Calculations.simpleInterestPrincipal = simpleInterestPrincipal;
Calculations.simpleInterestAnnualRate = simpleInterestAnnualRate;
Calculations.simpleInterestRepaymentTerm = simpleInterestRepaymentTerm;

Calculations.interestCapitalisationAmount = interestCapitalisationAmount;
Calculations.interestCapitalisationAnnualRate = interestCapitalisationAnnualRate;
Calculations.interestCapitalisationPrincipal = interestCapitalisationPrincipal;
Calculations.interestCapitalisationRepaymentTerm = interestCapitalisationRepaymentTerm;

Calculations.notional = notional;

Calculations.calculateCurrentBalance = calculateCurrentBalance;
Calculations.currentBalancePIArrears = currentBalancePIArrears;
Calculations.currentBalancePIAdvance = currentBalancePIAdvance;
Calculations.currentBalanceInterestOnly = currentBalanceInterestOnly;
Calculations.currentBalanceSimpleInterest = currentBalanceSimpleInterest;

Calculations.calculatePAValues = calculatePAValues;
Calculations.calculateSensitisedPAValues = calculateSensitisedPAValues;
Calculations.paInterestOnly = paInterestOnly;
Calculations.piArrearsInterestRepayment = piArrearsInterestRepayment;
Calculations.piAdvanceInterestRepayment = piAdvanceInterestRepayment;
Calculations.paSimpleInterest = paSimpleInterest;
Calculations.paNotional = paNotional;
Calculations.paInterestCapitalisation = paInterestCapitalisation;

Calculations.PMT = PMT;

export default Calculations;
