import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { formatPercentage, roundOff } from '../../../common/Misc';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
// import { displayNameFromType } from '../../../common/Misc';
import {
  getxerofinancialdata,
  // reimportXerofinancialdata,
  xeroconnection,
  xerorganization,
  financialsState,
  updateFinancialsxero,
  xeroAuth
} from '../../../../slices/slices/FinancialsSlice';
import OrganizationModal from '../../../../components/OrganizationModal';
import { defaultBusinessValue } from '../../../../slices/slices/FinancialsSlice';
import { dataEntryState, getSelectedMemberData } from '../../../../slices/slices/DataEntrySlice';
import { calculateNetProfit } from '../../../../utils/businessDataEntryCalculations';

export default function Importfinancial() {
  const [memberData, setMemberData] = useState({});
  const { selectedMember } = useSelector(dataEntryState);
  const currentMonth = new Date().getMonth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tenantId, setTenantId] = useState();
  const [searchParams] = useSearchParams();
  const [selectedYear, setSelectedYear] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [show, setShow] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState(false);

  var monthNames = [
    { label: 'Jan', value: '01' },
    { label: 'Feb', value: '02' },
    { label: 'Mar', value: '03' },
    { label: 'Apr', value: '04' },
    { label: 'May', value: '05' },
    { label: 'Jun', value: '06' },
    { label: 'July', value: '07' },
    { label: 'Aug', value: '08' },
    { label: 'Sep', value: '09' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' }
  ];

  const {
    xerofinancialData,
    //  xeroconnectionData,
    xerorganizationData
  } = useSelector(financialsState);

  const [items, setItems] = useState();
  const [hiddenItems, setHiddenItems] = useState([]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let index = newIndex - 1;
    let Dindex = newIndex;
    if (
      (items.items[index].title.includes('GROSS') && items.items[Dindex].disable) ||
      (items.items[Dindex].title.includes('GROSS') && items.items[index].disable)
    ) {
      console.log('');
    } else {
      let setArray = {
        items: arrayMoveImmutable(items.items, oldIndex, newIndex)
      };

      setItems(setArray);
    }
  };

  useEffect(() => {
    if (redirectUrl)
      navigate('/job/' + memberData?.jobId + '/data-entry/' + memberData?.member + '/financials');
  }, [redirectUrl]);

  useEffect(() => {
    if (tenantId) {
      // let is_reimport = localStorage.getItem('reimport');
      // if (is_reimport) {
      //   dispatch(reimportXerofinancialdata(memberData?.jobId, memberData?.member, tenantId)).then(
      //     () => {
      //       localStorage.removeItem('reimport');
      //       navigate(
      //         '/job/' + memberData?.jobId + '/data-entry/' + memberData?.member + '/financials'
      //       );
      //     }
      //   );
      // } else {
      const month = localStorage.getItem('month')
        ? localStorage.getItem('month')
        : monthNames[currentMonth].value;

      dispatch(getxerofinancialdata(memberData?.jobId, memberData?.member, tenantId, month));
      // }
    }
  }, [tenantId]);

  useEffect(() => {
    dispatch(xeroconnection());
  }, [memberData]);

  useEffect(() => {
    if (memberData?.jobId && memberData?.member)
      dispatch(getSelectedMemberData(memberData?.jobId, memberData?.member));
  }, [memberData]);

  useEffect(() => {
    if (searchParams.get('code')) {
      let ID = localStorage.getItem('data');
      setMemberData(JSON.parse(ID));
      localStorage.setItem('xero-code', searchParams.get('code'));
    } else {
      navigate('/job/' + memberData?.jobId + '/data-entry/');
    }
  }, []);

  useEffect(() => {
    let code = localStorage.getItem('xero-code');
    if (code) {
      dispatch(xeroAuth({ code: code }));
    }
  }, []);

  const getTitle = (value) => {
    if (selectedMember.type === 'trust' && value === 'dividend') {
      return 'distribution';
    }
    return value;
  };

  useEffect(() => {
    let value = {
      items: []
    };
    let hiddenValue = {
      items: []
    };
    let keyList = [
      'trading-income',
      'operating-expenses',
      'dividend',
      'cost-of-sales',
      'other-income'
    ];
    if (xerofinancialData?.data?.data) {
      let array = [
        'adjusted-ebitda',
        'ebit',
        'ebitda',
        'tax',
        'funds-after-tax',
        'funds-available-years'
      ];
      let data = JSON.parse(JSON.stringify(xerofinancialData?.data?.data));

      for (let key of ['other-income', 'dividend']) {
        let array = defaultBusinessValue?.[key]['items']
          ? defaultBusinessValue?.[key]['items']
          : [];
        if (key === 'other-income' && data?.['other-income']?.items) {
          array = [
            ...array,
            ...data['other-income'].items.filter(
              (item) => !['Interest Income', 'Gross Rental', 'Rental Expenses'].includes(item.title)
            )
          ];
        } else if (data?.[key]?.items && data?.[key]?.items.length > 0) {
          array = [...data[key].items];
        }
        data = {
          ...defaultBusinessValue,
          ...data,
          [key]: {
            ...defaultBusinessValue[key],
            ...data?.[key],
            items: array
          }
        };
        if (key === 'dividend') {
          for (let item of Object.keys(data)) {
            if (item === 'dividend') continue;
            let indexList = [];
            for (let [index, el] of Object.entries(data[item].items)) {
              if (
                el.title.includes('Distributions of') ||
                el.title.includes('Distribution of') ||
                el.title.includes('Dividends Paid')
              ) {
                indexList.push(index);
              }
            }
            indexList.reverse();
            for (let inx of indexList) {
              data['dividend'].items.push(data[item].items[inx]);
              data[item].items.splice(inx, 1);
            }
          }
        }
        if (xerofinancialData?.data?.data?.[key]?.['items'].length > 0) {
          xerofinancialData?.data?.data?.[key]?.['items'].map((item) => {
            let index = data?.[key]?.['items'].findIndex((el) => el.title === item.title);
            if (index > -1) {
              data[key]['items'][index].data = item.data;
            }
          });
        }
      }

      for (let item of ['Income Tax Expense']) {
        keyList.map((el) => {
          let index = data[el].items.findIndex((el) => el.title == item);
          if (index > -1) {
            data[el].items.splice(index, 1);
          }
        });
      }

      let interestIndex = data['trading-income'].items.findIndex(
        (item) => item.title === 'Interest Income'
      );
      if (interestIndex > -1) {
        data['other-income'].items[0] = data['trading-income'].items[interestIndex];
        data['trading-income'].items.splice(interestIndex, 1);
      }
      let grossIndex = data['trading-income'].items.findIndex(
        (item) => item.title === 'Gross Rental'
      );
      if (grossIndex > -1) {
        data['other-income'].items[1] = data['trading-income'].items[grossIndex];
        data['trading-income'].items.splice(grossIndex, 1);
      }
      let operatingIndex = data['operating-expenses'].items.findIndex(
        (item) => item.title === 'Rental Expenses'
      );
      if (operatingIndex > -1) {
        data['other-income'].items[2] = data['operating-expenses'].items[operatingIndex];
        data['operating-expenses'].items.splice(operatingIndex, 1);
      }
      data &&
        Object.entries(data).map((item) => {
          let show = true;
          if (array.includes(item[0])) {
            show = false;
          }
          let obj = {
            title: getTitle(item[0]),
            disable: true,
            ['ischecked']: true,
            ['final-totals']: item[1]['final-totals'] ? item[1]['final-totals'] : [],
            ['totals']: item[1]['totals'] ? item[1]['totals'] : [],
            ['key']: item[0]
          };
          if (Object.keys(item[1]).includes('is_expandable')) {
            obj['is_expandable'] = item[1]['is_expandable'];
          }
          if (show) {
            value.items.push(obj);
          } else {
            hiddenValue.items.push(obj);
          }

          item[1]?.items &&
            Object.entries(item[1]?.items).map((val) => {
              let obj = {
                title: val[1]?.title,
                data: val[1]?.data,
                ['ischecked']: true,
                disable: false,
                checkBox: val[1]?.checkBox || !show ? val[1].checkBox : false
              };
              if (show) {
                value.items.push(obj);
              } else {
                hiddenValue.items.push(obj);
              }
            });
        });

      setItems(value);
      setHiddenItems(hiddenValue);
    }
    if (xerofinancialData?.data?.years?.length > 0)
      setSelectedYear(
        xerofinancialData?.data?.years.map((item) => {
          return { year: item, checked: true };
        })
      );
  }, [xerofinancialData]);

  const SortableItem = SortableElement(({ value }) => {
    return (
      <li className={value.disable ? 'importitle' : 'imporlist'}>
        {!value.disable && (
          <input
            type="checkBox"
            defaultChecked={value?.ischecked || value.checkBox}
            disabled={value.checkBox}
            onClick={(e) => handleOnchecked(value.index, e.target.checked)}
          />
        )}
        {value.title}
      </li>
    );
  });

  const handleOnchecked = (index, value) => {
    if (items) {
      let itemData = JSON.parse(JSON.stringify(items));
      itemData?.items.map((val, key) => {
        if (key == index) {
          itemData.items[key]['ischecked'] = value;
        }
      });
      setItems(itemData);
    }
  };

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items?.items?.map((elem, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={{
              title:
                elem?.title === 'Select if Applicable'
                  ? ''
                  : elem?.title
                  ? capitalize(elem.title.replaceAll('-', ' '))
                  : '',
              disable: elem.disable,
              checkBox: elem?.checkBox,
              ischecked: elem.ischecked,
              show: show,
              index: index
            }}
            disabled={
              elem.disable
                ? elem.disable
                : elem.title.includes('Total') || elem.checkBox
                ? true
                : false
            }
          />
        ))}
      </ul>
    );
  });

  const capitalize = (s) => {
    return s.length > 0 ? s[0].toUpperCase() + s.slice(1) : s;
  };

  const handleSubmit = async () => {
    let financial = {};
    let key = null;

    let data = { items: [...items.items, ...hiddenItems.items] };

    data?.items?.length > 0 &&
      data?.items.map((item) => {
        if (item.disable) {
          delete item.disable;
          key = item.key;
          financial[item.key] = {
            ...item,
            items: []
          };
        } else {
          if (financial[key]?.items && item.ischecked) {
            delete item.disable;
            let final = {};
            selectedYear.map((obj) => {
              if (obj.checked) {
                final[obj.year] = item.data[obj.year];
              }
            });

            financial[key]?.items.push({ title: item.title, data: final });
          }
        }
      });
    Object.keys(financial).map((item) => {
      if (Object.keys(financial).includes(item)) {
        financial = {
          ...financial,
          [item]: {
            ...financial[item],
            totals: {
              data: {}
            }
          }
        };
        xerofinancialData?.data?.years.map((el) => {
          let total = 0;
          if (financial[item]?.items?.length > 0) {
            financial[item].items.map((elem) => {
              if (elem?.data?.[el] && Object.keys(elem?.data).includes(String(el))) {
                total +=
                  // Math.round(Number(elem.data[el]));
                  roundOff(Number(elem.data[el]));
              } else {
                elem.data[el] = null;
              }
            });
          }
          financial[item].totals.data[el] = total;
        });
      }
    });

    let netProfitBeforeTaxData = {};
    let netProfitBeforeTaxData_percentage = {};
    const handlePercentagePerTotal = (year, value) => {
      if (financial['trading-income']) {
        let totals = financial['trading-income'].totals.data;
        return formatPercentage(value / totals[year]);
      }
      return 0;
    };

    xerofinancialData?.data?.years.map((value) => {
      netProfitBeforeTaxData[value] = calculateNetProfit(value, financial);
      netProfitBeforeTaxData_percentage[value] = handlePercentagePerTotal(
        value,
        netProfitBeforeTaxData[value]
      );
    });

    let netProfitBeforeTaxData_val = {
      title: 'Net Profit Before Tax',
      key: 'net-profit-before-tax',
      items: netProfitBeforeTaxData
    };

    let netProfitBeforeTaxDatapercentage_val = {
      title: 'Net Profit Before Tax',
      key: 'net-profit-before-tax',
      items: netProfitBeforeTaxData_percentage
    };

    let finalData = {
      ...financial,
      'net-profit-before-tax': {
        ...netProfitBeforeTaxData_val,
        'net-profit-before-tax-percentage': netProfitBeforeTaxDatapercentage_val
      }
    };
    if (Object.keys(finalData).includes('dividend')) {
      if (finalData['dividend']?.items) {
        finalData['dividend'].items.map((el) => {
          if (el.title !== 'Select if Applicable') {
            el.deletable = true;
          }
        });
      }
      if (finalData['other-income']?.items) {
        finalData['other-income'].items.map((el) => {
          if (!['Interest Income', 'Gross Rental', 'Rental Expenses'].includes(el.title)) {
            el.deletable = true;
          }
        });
      }
      if (finalData['trading-income']?.items) {
        finalData['trading-income'].items.map((el) => {
          el.deletable = true;
        });
      }
      if (finalData['cost-of-sales']?.items) {
        finalData['cost-of-sales'].items.map((el) => {
          el.deletable = true;
        });
      }
      let index = finalData['dividend']?.items.findIndex(
        (el) => el.title === 'Select if Applicable'
      );
      finalData['dividend'].items.push(finalData['dividend'].items[index]);
      finalData['dividend'].items.splice(index, 1);
    }

    if (finalData?.['cost-of-sales']?.items?.length > 0) {
      let expense = false;
      finalData['cost-of-sales'].items.map((el) => {
        if (el?.bundleName) {
          expense = true;
        } else {
          el.bundleName = 'Cost of sales';
        }
      });
      if (expense) {
        finalData = JSON.parse(JSON.stringify(finalData));
      }
    }

    if (finalData?.['operating-expenses']?.items?.length > 0) {
      let expense = false;
      finalData['operating-expenses'].items.map((el) => {
        if (el?.bundleName) {
          expense = true;
        } else {
          el.bundleName = 'Expense';
        }
      });
      if (expense) {
        finalData = JSON.parse(JSON.stringify(finalData));
      }
    }

    dispatch(
      updateFinancialsxero(memberData?.jobId, memberData?.member, {
        rearrange_template: JSON.parse(JSON.stringify(finalData))
      })
    ).then(() => {
      setRedirectUrl(true);
    });
  };

  const onCheckHandle = (event, year) => {
    if (selectedYear) {
      let years = JSON.parse(JSON.stringify(selectedYear));
      let index = years.findIndex((item) => item.year == year.year);
      if (index > -1) {
        years[index].checked = !year.checked;
        setSelectedYear(years);
      }
    }
  };

  const ontouchcancel = () => {
    navigate('/job/' + memberData?.jobId + '/data-entry/');
  };

  useEffect(() => {
    dispatch(xerorganization());
  }, []);

  useEffect(() => {
    setOrganization(xerorganizationData?.data);
  }, [xerorganizationData]);

  return (
    <>
      <div className="dashboard toggle-sidebar">
        <DataEntryHeader />
        <div id="main" className="main">
          <div className="group-breadcrumb">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <Link to={'/job/' + memberData?.jobId + '/dashboard'}>Dashboard</Link>
                </li>
                <li>
                  <Link to={'/job/' + memberData?.jobId + '/data-entry'}>Data Entry</Link>
                </li>
                <li>
                  <strong>Financials</strong>
                </li>
              </ul>
              <h1>Financials - {selectedMember?.name}</h1>
            </div>
            <div className="breadcrumb-right">
              <a href="#" className="info">
                <i className="icon-info"></i>
              </a>
              <div className="information" style={{ display: 'none' }}>
                <h4>
                  Information about
                  <br />
                  relative screen
                </h4>
                <a href="#" className="close">
                  <i className="icon-close"></i>
                </a>
                <p>
                  Lorem ipsum dolor sit amet, consecte tur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                  fugiat nulla pariatur.
                </p>
              </div>
            </div>
          </div>

          <div className="white-card mt-30">
            <div className="profile-tabs financial-tab">
              <h5>Map imported data columns with system&apos;s columns</h5>
            </div>

            <div className="tab-content profile-table-block" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="import-tab"
              >
                <div className="import-table">
                  <div className="container ">
                    Please select Month to import:
                    {selectedYear?.map((item) => {
                      return (
                        <>
                          <label
                            className="checkbox-inline"
                            style={{ marginBottom: '0', paddingBottom: '0' }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked={item.checked}
                              onClick={(e) => onCheckHandle(e, item)}
                              style={{ marginBottom: '0', paddingBottom: '0' }}
                            />
                            {item.year}
                          </label>
                        </>
                      );
                    })}
                  </div>

                  {items && <SortableList items={items} onSortEnd={onSortEnd} />}
                </div>
              </div>
            </div>
            <div className="import-tab-content">
              <p>
                <strong>Note: Mapping Is Required When Importing The Data From Xero.</strong>
                <br />
                1) Select The Field On Left Side And Drag To Map In Systems Group.
                <br />
                2) Right Click On Matched Fields To Add Them In Group Or Ungroup.
              </p>
            </div>
          </div>
          <div className="action-box">
            <button className="button reset" onClick={ontouchcancel}>
              Cancel
            </button>
            <button className="button green" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
      <OrganizationModal
        show={show}
        organization={organization}
        setTenantId={setTenantId}
        setShow={setShow}
      />
    </>
  );
}
