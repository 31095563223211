import React from 'react';

export default function Pagenotfound() {
  return (
    <div className="influence-account-page notfound">
      <div className="notfound-container">
        <i className="fa fa-lock"></i>
        <h2>Access Denied</h2>
        <p>
          You don&rsquo;t have permissions to access this page.
          <br />
          Contact an administrator to get permission or go to the home page
          <br />
          and browse other jobs.
        </p>
        <div className="btn">
          <a href="/dashboard">Go to dashboard</a>
        </div>
      </div>
    </div>
  );
}
