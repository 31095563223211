import { Link, /* useLocation, */ useParams, useNavigate } from 'react-router-dom';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import React, { useEffect, useState } from 'react';
//import { displayNameFromType } from '../../../common/Misc';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { Modal } from 'react-bootstrap';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import {
  financialsState,
  getFinancialData,
  // updateFinancialsxero,
  editLayout,
  //   deleteRecord,
  getTaxRateByMember
} from '../../../../slices/slices/FinancialsSlice';

import { useDispatch, useSelector } from 'react-redux';
import {
  dataEntryState
  // getSelectedMemberData
} from '../../../../slices/slices/DataEntrySlice';
// import constants from '../../../../constant';

const EditLayout = () => {
  // const { state } = useLocation();
  const navigate = useNavigate();
  const { jobId, memberId } = useParams();
  const { selectedMember } = useSelector(dataEntryState);
  //   const formValues = useRef();
  const [groupName, setGroupName] = useState('');
  const [selectedYear, setSelectedYear] = useState([]);
  const dispatch = useDispatch();
  const { financialDataResponse } = useSelector(financialsState);
  const [items, setItems] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [redirectFinancial, setRedirectFinancial] = useState(false);

  useEffect(() => {
    dispatch(getTaxRateByMember(jobId, memberId));
  }, []);

  useEffect(() => {
    dispatch(getFinancialData(jobId, memberId, selectedMember?.type));
  }, [selectedMember]);

  useEffect(() => {
    let value = {
      items: []
    };
    if (financialDataResponse?.data?.data?.data) {
      let importData = JSON.parse(JSON.stringify(financialDataResponse?.data?.data?.data));
      let tempData = JSON.parse(JSON.stringify(financialDataResponse?.data?.data?.data));
      if (importData['operating-expenses']?.items1) {
        importData['operating-expenses'].items = importData['operating-expenses'].items1;
        tempData['operating-expenses'].items = tempData['operating-expenses'].items1;
      }
      // if (financialDataResponse?.data?.data?.data?.['operating-expenses']?.items?.length > 0) {
      //   let expense = false;
      //   importData['operating-expenses'].items.map((el) => {
      //     if (el?.bundleName) {
      //       expense = true;
      //     }
      //     el.bundleName = 'Expense';
      //   });
      //   if (expense) {
      //     importData = JSON.parse(JSON.stringify(tempData));
      //   }
      // }

      if (importData['trading-income']?.items1) {
        let test = importData['trading-income'].items1;
        importData['trading-income']['items'] = test;
      }

      if (importData['cost-of-sales']?.items1) {
        importData['cost-of-sales'].items = importData['cost-of-sales'].items1;
      }
      if (importData['other-income']?.items1) {
        importData['other-income'].items = importData['other-income'].items1;
      }

      let orderbykey = ['trading-income', 'cost-of-sales', 'other-income', 'operating-expenses'];

      orderbykey.forEach((key) => {
        importData &&
          Object.entries(importData).map((item) => {
            if (item[0] === key) {
              let obj = {
                title: item[1]['title'],
                disable: true,
                ['final-totals']: item[1]['final-totals'] ? item[1]['final-totals'] : [],
                ['totals']: item[1]['totals'] ? item[1]['totals'] : [],
                ['key']: item[0],
                ['ischecked']: false,
                bundleName: item[1]?.bundleName,
                type: item[1].key,
                classname: 'importitle'
              };
              if (Object.keys(item[1]).includes('is_expandable')) {
                obj['is_expandable'] = item[1]['is_expandable'];
              }
              value.items.push(obj);
              let group_name = null;

              item[1]?.items &&
                Object.entries(item[1]?.items).map((val) => {
                  if (val[1]?.title) {
                    let childs = item[1]?.items.filter(function (item) {
                      return val[1].bundleName == item.bundleName;
                    }).length;

                    if (val[1].bundleName && group_name != val[1].bundleName) {
                      group_name = val[1].bundleName;
                      // group_name = null;
                      value.items.push({
                        deletable: val[1]?.deletable,
                        title: val[1].bundleName,
                        data: [],
                        bundleName: '',
                        ['ischecked']: false,
                        childs: childs,
                        disable: false,
                        type: item[1].key,
                        classname: 'imporlist'
                      });
                    }

                    // group_name = null;

                    value.items.push({
                      deletable: val[1]?.deletable,
                      title: val[1]?.title,
                      disable:
                        val[1]?.key == 'interest-income' ||
                        val[1]?.key == 'gross-rental' ||
                        val[1]?.key == 'rental-expenses'
                          ? true
                          : false,
                      data: val[1]?.data,
                      ['key']: val[0],
                      ['ischecked']: false,
                      bundleName: val[1].bundleName,
                      childs: val[1]?.childs,
                      type: item[1].key,
                      classname1: 'imporlist'
                    });
                  }
                });
            }
            setItems(value);
          });
      });
    }
    if (financialDataResponse?.data?.data?.years?.length > 0)
      setSelectedYear(
        financialDataResponse?.data?.data?.years.map((item) => {
          return { year: item, checked: true };
        })
      );
  }, [financialDataResponse]);

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items?.items?.map((elem, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={{
                title: elem.title ? capitalize(elem.title.replaceAll('-', ' ')) : '',
                disable: elem.disable,
                ischecked: elem.ischecked,
                index: index,
                childs: elem?.childs,
                bundleName: elem?.bundleName,
                className: elem?.classname ? elem?.classname : 'imporlist'
              }}
              disabled={elem.disable ? elem.disable : false}
            />
          );
        })}
      </ul>
    );
  });

  const SortableItem = SortableElement(({ value }) => {
    return (
      <>
        <li className={value.className}>
          {value.className == 'imporlist' && !value.childs && value?.bundleName == null ? (
            <input
              type="checkBox"
              onClick={() => handleOnchecked(value.index)}
              checked={value.ischecked}
              disabled={value.disable}
              style={{ width: '20px', height: '17px' }}
            />
          ) : (
            ''
          )}
          <span style={{ marginLeft: value?.childs ? '20px' : '50px' }}>{value.title.trim()}</span>
        </li>
      </>
    );
  });

  const handleOnchecked = (index) => {
    items?.items.map((val, key) => {
      if (key == index) {
        items.items[key]['ischecked'] = true;
        setShow(true);
        setSelectedIndex(index);
      } else {
        items.items[key]['ischecked'] = false;
      }
    });

    setItems(items);
  };

  const handleOnrechecked = () => {
    items?.items.map((val, key) => {
      items.items[key]['ischecked'] = false;
    });

    setItems(items);
    setShow(false);
    setSelectedIndex();
  };

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let index = newIndex - 1;
    let Dindex = newIndex;
    if (
      (items.items[index].title.includes('GROSS') && items.items[Dindex].disable) ||
      (items.items[Dindex].title.includes('GROSS') && items.items[index].disable)
    ) {
      console.log('');
    } else {
      let setArray = {
        items: arrayMoveImmutable(items.items, oldIndex, newIndex)
      };

      let groupIndex = newIndex + 1;
      if (
        (groupIndex === items.items.length ||
          !setArray.items[groupIndex]?.bundleName ||
          setArray.items[groupIndex]?.childs > 0 ||
          (!setArray.items[groupIndex]?.childs && !setArray.items[groupIndex]?.bundleName)) &&
        !setArray.items[newIndex].childs
      ) {
        setArray.items[newIndex].bundleName = null;
      } else {
        let incrementChilds = null;
        let array = JSON.parse(JSON.stringify(setArray.items));

        //decrease childs
        let checkOldIndex = oldIndex;

        if (
          array[newIndex].bundleName &&
          (!array[newIndex].childs || array[newIndex].childs <= 0)
        ) {
          let bundleName = array[newIndex].bundleName;
          while (array[checkOldIndex].title !== bundleName) {
            checkOldIndex--;
          }
          if (array[checkOldIndex].title === bundleName) {
            array[checkOldIndex].childs = Number(array[checkOldIndex].childs) - 1;
          }
        }

        // increase childs
        if (!array[newIndex].childs) {
          let bundleName = array[groupIndex].bundleName;
          let index = newIndex;
          while (index > -1 && array[index].title !== bundleName) {
            index--;
          }
          if (array[index].title === bundleName) {
            array[index].childs =
              Number(array[index].childs) + Number(incrementChilds ? incrementChilds : 1);
          }
        }

        // check group
        if (items.items[oldIndex].childs > 0) {
          if (array[groupIndex]?.bundleName) return;
          incrementChilds = items.items[oldIndex].childs;
          let obj = array[newIndex];
          let index = oldIndex;
          for (let i = 1; i <= obj.childs; i++) {
            let index = oldIndex;
            if (oldIndex > newIndex) index += i;
            array.splice(newIndex + i, 0, array[index + i - 1]);
          }
          // array.splice(newIndex, 1);
          if (oldIndex > newIndex) index += obj.childs + 1;
          array.splice(index, obj.childs);
        } else {
          array[newIndex].bundleName = array[groupIndex].bundleName;
        }

        // Group In Group

        setArray.items = array;
      }

      setItems(setArray);
    }
  };

  const ontouchcancel = () => {
    navigate('/job/' + jobId + '/data-entry/');
  };

  const handleSubmit = () => {
    let financial = {};
    let key = null;

    items?.items?.length > 0 &&
      items?.items.map((item) => {
        if (item.disable && item.classname == 'importitle') {
          // delete item.disable;
          key = item.key;
          financial[item.key] = {
            ...item,
            items: []
          };
        } else {
          if (financial[key]?.items) {
            // delete item.disable;

            let final = {};

            selectedYear.map((obj) => {
              if (obj.checked) {
                final[obj.year] = item?.data ? item.data[obj.year] : {};
              }
            });

            if (!item.childs) {
              if (item.bundleName) {
                financial[key]?.items.push({
                  title: item.title,
                  data: final,
                  bundleName: item.bundleName,
                  category: item.bundleName
                  // childs: item?.childs ? item?.childs : 0
                });
              } else {
                financial[key]?.items.push({
                  title: item.title,
                  data: final,
                  bundleName: item.bundleName ? item.bundleName : null,
                  category: item.category
                    ? item.category
                    : item.bundleName
                    ? item.bundleName
                    : null,
                  deletable: item?.deletable ? item?.deletable : false
                });
              }
            }
          }
        }
      });

    let values = {
      financial: financialDataResponse.data.data.data,
      internal_notes: financialDataResponse.data.data.internal_notes,
      is_completed: financialDataResponse.data.data.is_completed,
      report_comments: financialDataResponse.data.data.report_comments,
      year_status: financialDataResponse.data.data.year_status
    };

    values = {
      ...values,
      financial: {
        ...values.financial,
        ...financial
      }
    };

    values.financial['other-income'].items1 = values.financial['other-income'].items;
    values.financial['trading-income'].items1 = values.financial['trading-income'].items;
    values.financial['cost-of-sales'].items1 = values.financial['cost-of-sales'].items;
    values.financial['operating-expenses'].items1 = values.financial['operating-expenses'].items;
    dispatch(editLayout(jobId, memberId, values)).then(() => {
      setRedirectFinancial(true);
    });
  };

  useEffect(() => {
    if (redirectFinancial) navigate('/job/' + jobId + '/data-entry/' + memberId + '/financials');
  }, [redirectFinancial]);

  const handleformSubmit = (e) => {
    e.preventDefault();

    let ans = false;
    items?.items.filter((val) => {
      let bundleName = val?.bundleName ? val?.bundleName.toLowerCase() : val?.bundleName;
      let groupNames = groupName ? groupName.toLowerCase() : groupName;

      if (val.type == items?.items[selectedIndex].type && bundleName == groupNames) {
        ans = true;
      }
      return ans;
    });
    if (!ans) {
      let arr = JSON.parse(JSON.stringify(items));

      arr.items[selectedIndex] = {
        ...arr.items[selectedIndex],
        title: arr.items[selectedIndex].title,
        bundleName: groupName,
        category: groupName
      };

      arr.items.splice(selectedIndex, 0, {
        title: groupName,
        disable: false,
        childs: 1
      });

      arr.items.map((val, key) => {
        if (arr?.items[key]['ischecked']) {
          arr.items[key]['ischecked'] = false;
        }
      });
      setSelectedIndex();

      setItems(arr);
      setShow(false);
    } else {
      setError(true);
    }

    // let financial = {};
    // let key = null;
    // items?.items?.length > 0 &&
    //   items?.items.map((item) => {
    //     if (item.disable) {
    //       // delete item.disable;
    //       key = item.key;
    //       financial[item.key] = {
    //         ...item,
    //         items: []
    //       };
    //     } else {
    //       if (financial[key]?.items) {
    //         // delete item.disable;

    //         let final = {};
    //         selectedYear.map((obj) => {
    //           if (obj.checked) {
    //             final[obj.year] = item.data[obj.year];
    //           }
    //         });

    //         if (item.ischecked) {
    //           financial[key]?.items.push({ title: item.title, data: final, bundleName: groupName });
    //         } else {
    //           financial[key]?.items.push({
    //             title: item.title,
    //             data: final,
    //             bundleName: item.bundleName
    //           });
    //         }
    //       }
    //     }
    //   });
  };

  const handleChange = (e) => {
    setGroupName(e.target.value);
  };

  return (
    <>
      <div className="dashboard toggle-sidebar">
        <DataEntryHeader />
        <div id="main" className="main">
          <div className="group-breadcrumb">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
                </li>
                <li>
                  <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
                </li>
                <li>
                  <strong>Financials</strong>
                </li>
              </ul>
              <h1>Financials </h1>
            </div>
          </div>
          <div className="white-card mt-30">
            <div className="profile-tabs financial-tab">
              <h5>Group Management</h5>
            </div>

            <div className="tab-content profile-table-block" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="import-tab"
              >
                <div className="import-table">
                  <SortableList items={items} onSortEnd={onSortEnd} />
                </div>
              </div>
            </div>
            <div className="import-tab-content"></div>
          </div>

          <div className="action-box">
            <button className="button reset" onClick={ontouchcancel}>
              Cancel
            </button>
            <button className="button green" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={false}
        dialogClassName="modal-90w modal-dialog-centered"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className="modal-header">
          <h5 className="modal-title">Group Name</h5>
        </div>
        <form noValidate onSubmit={(e) => handleformSubmit(e)}>
          <div className="modal-body">
            <Input
              type="text"
              className="form-control"
              onChange={(e) => handleChange(e)}
              // onBlur={handleBlur}
              // value={values.transaction_name}
              name="group_name"
              placeholder="Type Group name here"
            />
            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
              {error ? 'group name already exist' : ''}
            </span>
          </div>
          <div className="modal-footer">
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={handleOnrechecked}
              data-bs-dismiss="modal"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              id="groupanaly_propos_transaction_save"
              className="btn btn-primary"
            >
              Save
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditLayout;
