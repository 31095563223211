import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const TwoFactorOption = () => {
  const navigate = useNavigate();
  return (
    <div className="setting toggle-sidebar">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <p onClick={() => navigate(-1)} className="backlink">
            Back
          </p>
          <div className="container two-factor-screen">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="text-center bottomgap">
                  <h4>How would you like to authenticate this request?</h4>
                  <p>
                    To setup multi-factor authentication, you&rsquo;ll need a{' '}
                    <strong>authenticator app in your phone</strong> and a{' '}
                    <strong>backup email address</strong>.
                  </p>
                </div>

                <div className="col-12 btnarea justify-content-center">
                  <Link className="w-100 mb-0 green" to="/authentication-code-setting?remove=true">
                    Google Authentcator code
                  </Link>
                  <br />
                  <Link className="w-100" to="/confirm-email?remove=true">
                    Backup email address
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default TwoFactorOption;
