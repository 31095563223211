import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import AddFund from '../../../AddFund';
import Label from '../../../../components/Label';
import Title from '../../groupAnalysis/proposedTransaction/Title';
import { useParams } from 'react-router-dom';
import TransactionTable from '../../../../components/job/groupAnalysis/proposedTransaction/table/index';
import CustomModal from '../../../../components/CustomModal';
import { useRef } from 'react';

import {
  getassociateddata,
  ProposedTransactionState,
  deleteassociatedFunds,
  clearFundsData,
  addassociateFunds,
  updateassociatedfunds,
  getTransactionList
} from '../../../../slices/slices/ProposedTransactionSlice';
import { formatNumber } from '../../../../pages/common/Misc';
import { Field } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

export default function AssociatedTab(props) {
  const dispatch = useDispatch();
  const { transactionId, data, transactionData, commentData, setFundDetail, configList } = props;
  const { jobId } = useParams();

  const [associatedfund, setAssociatedFund] = useState([]);
  const [totalAmount, setTotalAmount] = useState({});
  const [fundsId, setFundID] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [reportComment, setReportComment] = useState(EditorState.createEmpty());
  const [internalNotes, setInternalNotes] = useState(EditorState.createEmpty());
  const [transactionType, setTransactionType] = useState('');
  const [transactionTypeName, setTransactionTypeName] = useState('');
  const { getfundsData } = useSelector(ProposedTransactionState);
  const [option, setOptions] = useState([]);
  const targetElement = useRef();
  const cancelElement = useRef();
  useEffect(() => {
    // const contentBlock = htmlToDraft(
    //   values?.funds_report_comments ? values?.funds_report_comments : ''
    // );
    // console.log('contentBlock  : ', values);
    // if (contentBlock) {
    //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    //   const editorState = EditorState.createWithContent(contentState);
    //   setReportComment(editorState);
    // }
    setReportComment(getComment(commentData?.associated_report_comments));
    setInternalNotes(getComment(commentData?.associated_internal_notes));
  }, [commentData]);

  const getComment = (val) => {
    const contentBlock = htmlToDraft(val ? val : '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return EditorState.createEmpty();
  };

  const handelComment = (rawDraftContentState, type) => {
    if (type != 'internal_notes') {
      setReportComment(rawDraftContentState);
    } else {
      setInternalNotes(rawDraftContentState);
    }
  };

  const addTransaction = (name, type) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
    setFundID('');
    dispatch(clearFundsData());
    setTransactionType(type);
    setTransactionTypeName(name);
  };

  const handleClose = () => {
    const elmnt = cancelElement;
    elmnt.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
    setTransactionType('');
  };

  const editTransaction = (fundsId, transaction_Id, type) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
    dispatch(clearFundsData());
    if (fundsId && type == 'associated_funds') {
      setTransactionTypeName('Associated Transaction');
      dispatch(getassociateddata(jobId, transaction_Id, fundsId));
    } else {
      dispatch(clearFundsData());
    }
    setFundID(fundsId);
    setTransactionType(type);
  };

  const handleFormSubmit = (values, { setErrors }) => {
    let data = { ...values };
    // data.amount = data.amount;

    if (fundsId && transactionType == 'associated_funds') {
      let is_custom = configList['proposed_transaction_associated_transaction_item']?.filter(
        (item) => item.key == data.funds_use.value
      );
      data.is_custom = is_custom && is_custom.length > 0 ? 'no' : 'yes';
      data.item = data.funds_use.value;
      delete data.funds_use;
      dispatch(updateassociatedfunds(jobId, transactionId, fundsId, data, setErrors)).then(() => {
        const elmnt = cancelElement;
        elmnt.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        });
        setTransactionType('');
        dispatch(getTransactionList({ jobId }));
      });
    } else {
      if (transactionType == 'associated_funds') {
        let is_custom = configList['proposed_transaction_associated_transaction_item']?.filter(
          (item) => item.key == data.funds_use.value
        );
        data.is_custom = is_custom && is_custom.length > 0 ? 'no' : 'yes';
        data.item = data.funds_use.value;
        delete data.funds_use;

        dispatch(addassociateFunds(jobId, transactionId, transactionType, data, setErrors)).then(
          () => {
            const elmnt = targetElement;
            elmnt.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'start'
            });
            setTransactionType('');
            dispatch(getTransactionList({ jobId }));
          }
        );
      }
    }
  };

  const handleonClickChange = (e, setFunction) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setFunction(fieldName, fieldValue);
  };

  const handelCommentUpdate = (type) => {
    if (type != 'internal_notes') {
      transactionData.setFieldValue(
        `comment.${transactionData.keyIndex}.associated_report_comments`,
        draftToHtml(convertToRaw(reportComment.getCurrentContent()))
      );
    } else {
      transactionData.setFieldValue(
        `comment.${transactionData.keyIndex}.associated_internal_notes`,
        draftToHtml(convertToRaw(internalNotes.getCurrentContent()))
      );
    }
  };

  const theaddata = [
    {
      key: 'item',
      className: 'text-center',
      title: 'Item'
    },
    {
      key: 'comment',
      className: 'text-center',
      title: 'Comment'
    },
    {
      key: 'amount',
      className: 'text-center',
      title: 'Amount',
      render: (item) => formatNumber(item)
    },
    {
      key: 'action',
      className: 'text-center',
      title: '',
      render: (item, record) => {
        return (
          <>
            <a
              className="edit"
              onClick={() => editTransaction(record.key, record.transaction_id, record.type)}
            >
              <i className="icon-edit"></i>
            </a>
            <a className="delete">
              <i
                className="icon-delete"
                onClick={() => handleSelectFundID(record.key, record.type)}
              ></i>
            </a>
          </>
        );
      }
    }
  ];

  const handleSelectFundID = (fundId) => {
    setFundID(fundId);
    setShowDelete(true);
  };
  const handleCancel = () => {
    setFundID(0);
    setShowDelete(false);
  };
  const handleDelete = (fundsId) => {
    setShowDelete(false);
    dispatch(deleteassociatedFunds(jobId, transactionId, fundsId)).then(() => {
      let tempList = JSON.parse(JSON.stringify(data));
      const index = tempList.associated_transactions.findIndex(
        (x) => String(x.id) === String(fundsId)
      );
      let deletedFund = tempList.associated_transactions.splice(index, 1);
      setTotalAmount({
        ...totalAmount,
        associatedFund: totalAmount.associatedFund - deletedFund[0].amount
      });

      setFundDetail(JSON.parse(JSON.stringify(tempList)));
    });
  };

  useEffect(() => {}, [data]);

  useEffect(() => {
    let array = new Array();
    let total = { associatedFund: 0 };
    if (data?.associated_transactions && data.associated_transactions.length > 0) {
      array = data.associated_transactions.map((item) => {
        total.associatedFund += item.amount;
        return {
          key: item.id,
          item: configList['proposed_transaction_associated_transaction_item'].find(
            (el) => el.key == item.item
          )
            ? configList['proposed_transaction_associated_transaction_item'].find(
                (el) => el.key == item.item
              ).value
            : item.custom_item,
          comment: item.comment,
          amount: item.amount,
          action: '',
          transaction_id: item.proposed_transaction_id,
          type: 'associated_funds'
        };
      });
    }
    setTotalAmount({ ...totalAmount, ...total });
    setAssociatedFund(array);
  }, [data]);

  let options = [];
  useEffect(() => {
    if (transactionType == 'source-funds') {
      configList &&
        configList['proposed_transaction_how_sourced']?.map((item) => {
          options.push({
            label: item.value,
            value: item.key
          });
        });
    } else if (transactionType == 'application-funds') {
      configList &&
        configList['proposed_transaction_funds_use']?.map((item) => {
          options.push({
            label: item.value,
            value: item.key
          });
        });
    } else {
      configList &&
        configList['proposed_transaction_associated_transaction_item']?.map((item) => {
          options.push({
            label: item.value,
            value: item.key
          });
        });
    }
    setOptions(options);
  }, [transactionType]);

  return (
    <>
      <div
        className="tab-pane fade show active proposed-tab"
        id="residential-justified"
        role="tabpanel"
        aria-labelledby="residential-tab"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="proarea d-flex justify-content-between align-items-center">
              <Title name="Associated Transaction Details" />
              <button
                id={`newbtn${transactionId}`}
                ref={cancelElement}
                name="Associated Transaction Details"
                type="button"
                className="btn btn-new"
                onClick={() =>
                  addTransaction('Associated Transaction', 'associated_funds', transactionId)
                }
              >
                <i className="icon-plus"></i> New
              </button>
            </div>
            <div className="funds">
              <TransactionTable
                theadData={theaddata}
                tbodyData={associatedfund}
                customClass="table financialtable"
                totalKey="Total"
                total={formatNumber(totalAmount.associatedFund)}
              />
              <CustomModal
                value="delete"
                show={showDelete}
                title="Delete Associated Transaction?"
                text="Are you sure you want to delete this associated transaction permanently?"
                cancel={handleCancel}
                success={handleDelete}
                reference={fundsId}
              />
            </div>
          </div>
          <div ref={targetElement}>
            <AddFund
              configList={configList}
              options={option}
              transactionType={transactionType}
              transactionTypeName={transactionTypeName}
              getfundsData={getfundsData}
              handleonClickChange={handleonClickChange}
              handleFormSubmit={handleFormSubmit}
              setTransactionType={setTransactionType}
              handleCancel={handleClose}
              fundsId={fundsId}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-field">
              <Label>Report Comments</Label>
              {/* <textarea
                className="form-control"
                name={`comment.${transactionData.keyIndex}.associated_report_comments`}
                value={commentData?.associated_report_comments}
                // onChange={transactionData.handleChange}
                onChange={(e) => {
                  transactionData.setFieldValue(
                    `comment.${transactionData.keyIndex}.associated_report_comments`,
                    e.target.value
                  );
                }}
                onBlur={transactionData.handleBlur}
              ></textarea> */}
              <Field name={`comment.${transactionData.keyIndex}.associated_report_comments`}>
                {({ field }) => (
                  <Editor
                    editorState={reportComment}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onBlur={() => handelCommentUpdate('report_comments')}
                    onEditorStateChange={(e) => handelComment(e, 'report_comments')}
                    toolbar={{
                      options: ['inline', 'list'],
                      inline: {
                        options: ['bold', 'italic', 'underline']
                      },
                      list: {
                        options: ['unordered', 'ordered']
                      }
                    }}
                    name={`comment.${transactionData.keyIndex}.associated_report_comments`}
                    id={field.name}
                    values={commentData?.associated_report_comments}
                    // values={getComment(values?.funds_report_comments)}
                    textareaName={`comment.${transactionData.keyIndex}.associated_report_comments`}
                    editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                    stripPastedStyles={true}
                  />
                )}
              </Field>
              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                {transactionData.errors.report_comments}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-field">
              <label>Internal Notes</label>
              {/* <textarea
                className="form-control"
                name={`comment.${transactionData.keyIndex}.associated_internal_notes`}
                value={commentData?.associated_internal_notes}
                // onChange={transactionData.handleChange}
                onChange={(e) => {
                  transactionData.setFieldValue(
                    `comment.${transactionData.keyIndex}.associated_internal_notes`,
                    e.target.value
                  );
                }}
                onBlur={transactionData.handleBlur}
              ></textarea> */}
              <Field name={`comment.${transactionData.keyIndex}.associated_internal_notes`}>
                {({ field }) => (
                  <Editor
                    editorState={internalNotes}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onBlur={() => handelCommentUpdate('internal_notes')}
                    onEditorStateChange={(e) => handelComment(e, 'internal_notes')}
                    toolbar={{
                      options: ['inline', 'list'],
                      inline: {
                        options: ['bold', 'italic', 'underline']
                      },
                      list: {
                        options: ['unordered', 'ordered']
                      }
                    }}
                    name={`comment.${transactionData.keyIndex}.associated_internal_notes`}
                    id={field.name}
                    values={commentData?.associated_internal_notes}
                    // values={getComment(values?.funds_report_comments)}
                    textareaName={`comment.${transactionData.keyIndex}.associated_internal_notes`}
                    editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                    stripPastedStyles={true}
                  />
                )}
              </Field>
              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                {transactionData.errors.internal_notes}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
