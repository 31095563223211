import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const CustomModal = (props) => {
  CustomModal.propTypes = {
    title: PropTypes.any,
    text: PropTypes.any,
    show: PropTypes.bool,
    success: PropTypes.func,
    cancel: PropTypes.func,
    value: PropTypes.string,
    reference: PropTypes.any,
    isHtml: PropTypes.bool
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.cancel(props.reference)}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h2>{props.title}</h2>
      </Modal.Header>
      <Modal.Body>
        {props.isHtml ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </>
        ) : (
          <p>{props.text}</p>
        )}
      </Modal.Body>
      <div className="modal-action modal-footer">
        <Button className="btn btn-secondary" onClick={() => props.cancel(props.reference)}>
          Cancel
        </Button>
        <Button className="btn btn-primary" onClick={() => props.success(props.reference)}>
          {props.value}
        </Button>
      </div>
    </Modal>
  );
};

export default CustomModal;
