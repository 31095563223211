import { useEffect, useState } from 'react';
import { Field } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import React from 'react';
// import Button from '../../../../Button';
import Label from '../../../../Label';
import {
  refreshComment,
  fsgroupList,
  getGroupList,
  securityGroupList
} from '../../../../../slices/slices/ProposedFacilitiesSecuritySlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function Comment(props) {
  const { index, values, setFieldValue, group_id, resetForm, handleInputChange } = props;
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const [reportcomment, setReportcomment] = useState(EditorState.createEmpty());
  const [internalnotes, setInternalnotes] = useState(EditorState.createEmpty());

  const setValues = (type, groupID) => {
    let data = {
      record_type: type,
      record_id: groupID
    };
    dispatch(refreshComment(jobId, data)).then(() => {
      if (jobId) dispatch(fsgroupList({ jobId }));
      dispatch(securityGroupList({ jobId }));
      dispatch(getGroupList({ jobId }));
    });
    resetForm();
  };

  useEffect(() => {
    setReportcomment(getComment(values.group[index]?.report_comments));
  }, [values.group[index]?.report_comments]);

  useEffect(() => {
    setInternalnotes(getComment(values?.group[index]?.internal_notes));
  }, [values?.group[index]?.internal_notes]);

  const getComment = (val) => {
    const contentBlock = htmlToDraft(val ? val : '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return EditorState.createEmpty();
  };

  return (
    <div className="white-card">
      <div className="row">
        <div className="col-md-6">
          <div className="form-field">
            <div className="lablearea">
              <Label>Report Comments</Label>
              <a
                // type="submit"
                name="is_completed"
                title="Refresh Comments"
                className="btn btn-primary"
                onClick={() => setValues('report_comments', group_id)}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </a>
            </div>
            <Field name={`group.${index}.report_comments`}>
              {({ field }) => (
                <Editor
                  editorState={reportcomment}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onBlur={() => {
                    setFieldValue(
                      `group.${index}.report_comments`,
                      draftToHtml(convertToRaw(reportcomment.getCurrentContent()))
                    );
                  }}
                  onEditorStateChange={(rawDraftContentState) => {
                    setReportcomment(rawDraftContentState);
                    handleInputChange(rawDraftContentState);
                  }}
                  toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    },
                    list: {
                      options: ['unordered', 'ordered']
                    }
                  }}
                  name={`group.${index}.report_comments`}
                  id={field.name}
                  values={getComment(
                    values.group[index]?.report_comments ? values.group[index]?.report_comments : ''
                  )}
                  textareaName={`group.${index}.report_comments`}
                  editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                  stripPastedStyles={true}
                />
              )}
            </Field>
            {/* <textarea
              value={values.group[index]?.report_comments}
              onChange={(e) => setFieldValue(`group.${index}.report_comments`, e.target.value)}
              className="form-control"
              name={`group.${index}.report_comments`}
            /> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <div className="lablearea">
              <label>Internal Notes</label>
              <a
                // type="submit"
                name="is_completed"
                title="Refresh Comments"
                className="btn btn-primary"
                onClick={() => setValues('internal_notes', group_id)}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </a>
            </div>
            {/* <textarea
              value={values?.group[index]?.internal_notes}
              className="form-control"
              onChange={(e) => {
                setFieldValue(`group.${index}.internal_notes`, e.target.value);
              }}
              name={`group.${index}.internal_notes`}
            /> */}
            <Field name={`group.${index}.internal_notes`}>
              {({ field }) => (
                <Editor
                  editorState={internalnotes}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onBlur={() => {
                    setFieldValue(
                      `group.${index}.internal_notes`,
                      draftToHtml(convertToRaw(internalnotes.getCurrentContent()))
                    );
                  }}
                  onEditorStateChange={(rawDraftContentState) => {
                    setInternalnotes(rawDraftContentState);
                  }}
                  toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    },
                    list: {
                      options: ['unordered', 'ordered']
                    }
                  }}
                  name={`group.${index}.internal_notes`}
                  id={field.name}
                  values={getComment(
                    values.group[index]?.internal_notes ? values.group[index]?.internal_notes : ''
                  )}
                  textareaName={`group.${index}.internal_notes`}
                  editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                  stripPastedStyles={true}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </div>
  );
}
