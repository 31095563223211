import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import AutomatedSelect from '../../../fields/AutomatedSelect';
import FormActions from '../proposedTransaction/FormAction';
import { AddRelianceValidation } from '../../../../pages/common/Validation';

import {
  addReliance,
  editReliancedata,
  getGroupList
} from '../../../../slices/slices/ProposedFacilitiesSecuritySlice';

export default function AddRelianceModel(props) {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const { onCancel, show, groupData, currentGroupId, selectedGroup, editReliance, relianceId } =
    props;
  // const [commentValue, setCommentValue] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const initialValue = {
    type: editReliance?.data?.type ? editReliance?.data?.type : 'add',
    selected_group: editReliance?.data?.selected_group ? editReliance?.data?.selected_group : '',
    selected_group_comment: editReliance?.data?.selected_group_comment
      ? editReliance?.data?.selected_group_comment
          .replaceAll('Add Reliance on ', '')
          .replaceAll('Add reliance on ', '')
          .replaceAll('Less Reliance on ', '')
          .replaceAll('Less Reliance Provided to ', '')
          .replaceAll('Less reliance on ', '')
          .replaceAll('Less reliance provided to ', '')
      : '',
    comment: editReliance?.data?.comment
      ? editReliance?.data?.comment
          .replaceAll('Add Reliance on ', '')
          .replaceAll('Add reliance on ', '')
          .replaceAll('Less Reliance on ', '')
          .replaceAll('Less Reliance Provided to ', '')
          .replaceAll('Less reliance on ', '')
          .replaceAll('Less reliance provided to ', '')
      : '',
    value: editReliance?.data?.value ? editReliance?.data?.value : '',
    id: selectedGroup
  };

  const handleFormSubmit = (values, { setErrors }) => {
    let data = { ...values };
    data.is_external = '0';
    data.id = selectedGroup;
    if (data.selected_group == 'external') {
      data.is_external = '1';
      delete data.selected_group;
    }
    if (relianceId) {
      delete data.id;
      dispatch(editReliancedata(jobId, selectedGroup, relianceId, data, setErrors)).then(() => {
        dispatch(getGroupList({ jobId }));
      });
    } else {
      dispatch(addReliance(jobId, data, setErrors)).then(() => {
        dispatch(getGroupList({ jobId }));
      });
    }

    onCancel();
  };

  const handleonClickChange = (e, setFunction) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setFunction(fieldName, fieldValue);
  };

  // const handleRadioClick = (type) => {
  //   if (type == 'add') {
  //     setCommentValue(
  //       'principle residence property situated at 100 Home Street. Hometown in the name of John Sample & Mary Louise Sample'
  //     );
  //   } else {
  //     setCommentValue(
  //       'principle residence property situated at 100 Home Street. Hometown in the name of John Sample & Mary Louise Sample'
  //     );
  //   }
  // };

  useEffect(() => {
    let groupArray = new Array();
    groupData?.data?.data.map((item) => {
      if (currentGroupId != item.position) {
        groupArray.push({
          key: item.id,
          value: 'Group ' + item.position
        });
      }
    });
    groupArray.push({
      key: 'external',
      value: 'External'
    });

    setGroupList(groupArray);
  }, [groupData, currentGroupId]);

  return (
    <>
      <Modal show={show} onHide={onCancel} centered className="large-popup">
        <div
          className="modal reliance-popup"
          id="CreatJobModal"
          role="dialog"
          style={{ display: 'block', paddingRight: '0px' }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-title">
                  <h4>{editReliance ? 'Add' : 'Edit'} Indirect Security</h4>
                  {/* <div className="info-block">
                    <div className="breadcrumb-right">
                      <DialogModal
                        title={'Add Reliance'}
                        p1={'Lorem Ipsum is simply dummy text.'}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="modal-sec-content">
                  <Formik
                    initialValues={initialValue}
                    enableReinitialize
                    validationSchema={AddRelianceValidation}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue
                    }) => {
                      return (
                        <>
                          <Form onSubmit={handleSubmit}>
                            <div className="form-field">
                              <label>Reliance Type</label>
                              <div className="radio-block">
                                <input
                                  name="type"
                                  type="radio"
                                  onChange={handleChange}
                                  value="add"
                                  defaultChecked
                                  checked={values?.type === 'add'}
                                  onBlur={handleBlur}
                                  // onClick={() => handleRadioClick('add')}
                                />

                                <label>Add Reliance</label>
                              </div>
                              <div className="radio-block">
                                <input
                                  type="radio"
                                  name="type"
                                  value="less"
                                  checked={values?.type === 'less'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  // onClick={() => handleRadioClick('less')}
                                />
                                <label>Less Reliance</label>
                              </div>
                            </div>
                            <div className="form-field">
                              <label>Select Group</label>
                              <AutomatedSelect
                                data={groupList}
                                name="selected_group"
                                value={values?.selected_group}
                                defaultva
                                onBlur={handleBlur}
                                handleChange={(e) => handleonClickChange(e, setFieldValue)}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.selected_group}
                              </span>
                            </div>
                            <div className="form-field">
                              <label>Add Reliance Comment</label>
                              <textarea
                                name="comment"
                                placeholder={
                                  'Insert property address(es) &/or details of other asset(s) being relied upon & the asset owner(s) name.'
                                }
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                value={values?.comment}
                              ></textarea>
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.comment}
                              </span>
                            </div>
                            <div className="form-field">
                              <label>Less Reliance Comment</label>

                              <textarea
                                name="selected_group_comment"
                                placeholder={'Insert facility product type & the borrower(s) name.'}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                value={values?.selected_group_comment}
                              ></textarea>
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.selected_group_comment}
                              </span>
                            </div>
                            <div className="col-4">
                              <label>Reliance Value $</label>
                              <CurrencyInput
                                className="form-control"
                                name="value"
                                value={values?.value}
                                decimalsLimit={0}
                                onValueChange={(value, name) => {
                                  setFieldValue(name, value);
                                }}
                                onBlur={handleBlur}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.value}
                              </span>
                            </div>
                            <div className="action-box full">
                              <FormActions onhandleCancel={onCancel} />
                            </div>
                          </Form>
                        </>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
