import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import Tableinfo from '../../../../components/job/groupAnalysis/debtServicing/Tableinfo';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import FormActions from '../../../../components/FormActions';
import Sensitised from '../../../../components/job/groupAnalysis/debtServicing/Sensitised';
// displayNameFromType,
import {
  displayOwnerNameFromType,
  formatNumber,
  roundOff,
  unformatNumber
  // sumOfNumbers
} from '../../../../pages/common/Misc';
import { commonState, getConfig } from '../../../../slices/slices/CommonSlice';
import moment from 'moment';
import {
  getsensitisedDebtServicing,
  sensitisedDebtServicingState,
  updateComment
} from '../../../../slices/slices/SensitisedDebtServicingSlice';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

export default function SensitisedDebtServicing() {
  const dispatch = useDispatch();
  const { configList } = useSelector(commonState);
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [internalNotes, setInternalNotes] = useState();
  const [reportComments, setReportComments] = useState();
  // const [sensitisedservice, setSensitisedService] = useState([]);
  const [personaltotalValue, setPersonalTotal] = useState({});
  const [businesstotalValue, setBusinessTotal] = useState({});
  const [personalData, setPersonalData] = useState([]);
  const [businessData, setBusinessData] = useState([]);

  const { sensitiseddebtservicingList } = useSelector(sensitisedDebtServicingState);

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  // let sensitisedDebtArray = {
  //   business: [],
  //   personal: []
  // };

  useEffect(() => {
    setInternalNotes(sensitiseddebtservicingList?.additional_info?.internal_notes);
  }, [sensitiseddebtservicingList]);

  useEffect(() => {
    setReportComments(sensitiseddebtservicingList?.additional_info?.report_comments);
  }, [sensitiseddebtservicingList]);

  const initialValue = {
    report_comments: reportComments || '',
    internal_notes: internalNotes || '',
    is_completed: 1
  };

  useEffect(() => {
    dispatch(getsensitisedDebtServicing(jobId));
  }, []);

  const headData = [
    {
      key: 'purpose',
      headClassName: 'skyblue text-start',
      className: 'text-start purposename',
      title: 'Business',
      colSpan: 14
    },
    {
      key: 'repayment_type',
      className: 'text-center rt',
      colSpan: 0,
      title: 'Repay Type',
      style: { width: '116px', padding: '0' },
      render: (item, record) => {
        if (
          record.sensitise.repayment_type !== null &&
          record.sensitise.repayment_type != record.other.repayment_type
        ) {
          return <p style={{ color: 'red' }}>{item}</p>;
        } else {
          return <p>{item}</p>;
        }
      }
    },
    {
      key: 'product_facility',
      className: 'text-center fp',
      title: 'Facility / Product',
      style: { width: '110px' }
    },
    {
      key: 'lender',
      className: 'text-center ldr',
      title: 'Existing Lender',
      style: { width: '90px' }
    },
    {
      key: 'existing_limit',
      className: 'text-center lmt',
      title: 'Limit ',
      style: { width: '89px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'interest_rate_pa',
      className: 'text-center irpa',
      title: 'Interest Rate p.a.',
      style: { width: '100px' },
      render: (item, record) => {
        if (record.product_facility !== 'Credit Card' && record.product_facility !== 'Store Card') {
          if (
            record.sensitise.interest_rate_pa > 0 &&
            Number(record.sensitise.interest_rate_pa) !=
              Number(record.other.interest_rate_pa) + Number(record.facility_fee)
          ) {
            return (
              <p style={{ color: 'red' }}>{item !== null ? parseFloat(item).toFixed(2) : ''}</p>
            );
          } else {
            if (record.facility_fee != null && record.facility_fee != undefined) {
              return (
                parseFloat(
                  record.other.interest_rate_pa != null ||
                    record.other.interest_rate_pa != undefined
                    ? Number(record.other.interest_rate_pa) + Number(record.facility_fee)
                    : 0.0
                )
                  //  +
                  // parseFloat(
                  //   record.facility_fee != null || record.facility_fee != undefined
                  //     ? record.facility_fee
                  //     : 0.0
                  // )
                  .toFixed(2)
              );
            } else return parseFloat(item != null || item != undefined ? item : 0.0).toFixed(2);

            // return (
            //   <>
            //     <p>{parseFloat(item).toFixed(2)}</p>
            //   </>
            // );
          }
        } else {
          return '';
        }
      }
    },
    {
      key: 'term_remaining_months',
      className: 'text-center tm',
      colSpan: 0,
      title: 'Terms Mths',
      style: { width: '60px' },
      render: (item, record) => {
        if (
          record.sensitise.term_remaining_months > 0 &&
          record.sensitise.term_remaining_months != record.other.term_remaining_months
        ) {
          return <p style={{ color: 'red' }}>{item}</p>;
        } else {
          return <p>{item}</p>;
        }
      }
    },
    // {
    //   key: 'key_star_2',
    //   className: 'text-center start',
    //   title: '*',
    //   style: { width: '53px' },
    //   render: (item, record) => {
    //     if (record.sensitise.repayment_term_identifier != record.other.repayment_term_identifier) {
    //       return (
    //         <>
    //           <p style={{ color: 'red' }}>{item}</p>
    //         </>
    //       );
    //     } else {
    //       return (
    //         <>
    //           <p>{item}</p>
    //         </>
    //       );
    //     }
    //   }
    // },
    {
      key: 'balloon',
      className: 'text-center bkn',
      title: 'Balloon ',
      style: { width: '89px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'facility_expiry_date',
      className: 'text-center le',
      title: 'Facility Expiry',
      style: { width: '93px' },
      render: (item, record) => {
        if (
          record.sensitise.facility_expiry_date &&
          record.sensitise.facility_expiry_date !== record.other.facility_expiry_date
        ) {
          return (
            <p style={{ color: 'red' }}>
              {record.sensitise.facility_expiry_date !== null
                ? moment(record.sensitise.facility_expiry_date, 'YYYY-MM-DD', true).format(
                    'DD/MM/YY'
                  )
                : ''}
            </p>
          );
        } else {
          return (
            <p>
              {record.other.term_remaining_months !== null &&
              record.other.facility_expiry_date !== null &&
              record.product_facility !== 'Credit Card' &&
              record.product_facility !== 'Store Card'
                ? moment(record.other.facility_expiry_date, 'YYYY-MM-DD', true).format('DD/MM/YY')
                : ''}
            </p>
          );
        }
      }
    },
    {
      key: 'repayment_amount',
      className: 'text-center rm',
      title: 'Repay Amt',
      style: { width: '80px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'frequency',
      className: 'text-center Frq',
      title: 'Freq',
      style: { width: '60px' }
      // render: (item) => formatNumber(item)
    },
    {
      key: 'interest_pa',
      className: 'text-center ipa',
      title: 'Interest p.a.',
      style: { width: '76px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'repayment_pa',
      className: 'text-center rplpa',
      title: 'Repay p.a.',
      style: { width: '60px' },
      render: (item) => formatNumber(item)
    },
    {
      key: 'edit',
      className: '',
      title: '',
      style: { width: '60px' },
      render: (item, record) => {
        return (
          <a
            className="edit"
            onClick={() => {
              navigate(
                `/job/${jobId}/data-entry/${record.member_id}/facilities/${record.facility_id}`
              );
            }}
          >
            <i className="icon-edit"></i>
          </a>
        );
      }
    }
  ];

  const getMainHeadData = () => {
    return [
      {
        key: 'purpose',
        className: 'text-start purposename',
        headClassName: 'text-start purposename',
        title: 'Purpose',
        style: { width: '17%' }
      },
      ...headData.slice(1, headData.length)
    ];
  };

  useEffect(() => {
    // let serviceList = JSON.parse(JSON.stringify(sensitiseddebtservicingList));
    let businesstotal = {
      limit: 0,
      balloon: 0,
      repaytype: 0,
      interestpa: 0,
      repaypa: 0
    };
    let personaltotal = {
      limit: 0,
      balloon: 0,
      repaytype: 0,
      interestpa: 0,
      repaypa: 0
    };
    let serviceList = JSON.parse(JSON.stringify(sensitiseddebtservicingList));

    let businessRecord = [];
    serviceList?.data?.business?.length > 0 &&
      serviceList.data.business.map((item) => {
        businesstotal.limit +=
          // Math.round(unformatNumber(item.existing_limit));
          roundOff(unformatNumber(item.existing_limit));
        businesstotal.balloon +=
          // Math.round(unformatNumber(item.balloon));
          roundOff(unformatNumber(item.balloon));
        businesstotal.repaytype +=
          // Math.round(unformatNumber(item.repayment_amount));
          roundOff(unformatNumber(item.repayment_amount));
        let matched = businessRecord.findIndex(
          (el) => el.id == item.facility_owners.map((el) => el.id).join('|')
        );
        // let memberList = item.facility_owners.filter((el) => {
        //   if (el.type != 'individual') return el;
        // });
        // let interest_pa = sumOfNumbers(
        //   memberList.map((el) => {
        //     if (el?.interest_pa) return el.interest_pa;
        //     return 0;
        //   })
        // );
        // let repayment_pa = sumOfNumbers(
        //   memberList.map((el) => {
        //     if (el?.repayment_pa) return el.repayment_pa;
        //     return 0;
        //   })
        // );
        businesstotal.interestpa +=
          // Math.round(unformatNumber(item.interest_pa));
          roundOff(unformatNumber(item.interest_pa));
        businesstotal.repaypa +=
          // Math.round(unformatNumber(item.repayment_pa));
          roundOff(unformatNumber(item.repayment_pa));
        // item.interest_pa = interest_pa;
        // item.repayment_pa = repayment_pa;
        item.member_id = item?.facility_owners?.[0]?.id ? item?.facility_owners?.[0]?.id : '';
        item.balloon =
          item?.product_facility !== 'credit-card' && item?.product_facility !== 'store-card'
            ? item?.balloon
            : '';
        item.term_remaining_months =
          item?.product_facility !== 'credit-card' && item?.product_facility !== 'store-card'
            ? item?.sensitise?.term_remaining_months
              ? item?.sensitise?.term_remaining_months
              : item?.term_remaining_months
            : '';
        item.interest_rate_pa =
          item?.product_facility !== 'credit-card' && item?.product_facility !== 'store-card'
            ? item?.sensitise?.interest_rate_pa
              ? item?.sensitise?.interest_rate_pa
              : item?.other?.interest_rate_pa
            : '';
        item.facility_expiry_date =
          item?.product_facility !== 'credit-card' && item?.product_facility !== 'store-card'
            ? moment(item?.other?.facility_expiry_date, 'YYYY-MM-DD', true).format('DD/MM/YY')
            : '';
        item.interest_pa =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.interest_pa;
        item.frequency =
          item.frequency == '52'
            ? 'W'
            : item.frequency == 26
            ? 'F'
            : item.frequency == 12
            ? 'M'
            : item.frequency == 4
            ? 'Q'
            : item.frequency == 2
            ? 'H'
            : item.frequency == 1
            ? 'A'
            : '';

        item.repayment_type = (
          item?.sensitise?.repayment_type
            ? item?.sensitise?.repayment_type
            : item?.other?.repayment_type
        )
          ? Object.keys(configList).length > 0 &&
            configList['facilities_repayment_types'].find(
              (el) =>
                el.key ==
                (item?.sensitise?.repayment_type
                  ? item?.sensitise?.repayment_type
                  : item?.other?.repayment_type)
            )?.value
          : '';

        item.product_facility = item?.product_facility
          ? Object.keys(configList).length > 0 &&
            configList['facilities_products'].find((el) => el.key == item?.product_facility)?.value
          : '';
        item.lender =
          item.facility_status == 'new'
            ? ''
            : Object.keys(configList).length > 0 &&
              configList['facilities_lenders'].find((el) => el.key == item?.lender)?.value;
        item.edit = '';

        // item.key_star_1 = item?.sensitise?.interest_rate_identifier
        //   ? item?.sensitise?.interest_rate_identifier.charAt(0).toUpperCase()
        //   : item?.other?.interest_rate_identifier.charAt(0).toUpperCase();
        // item.key_star_2 = item?.sensitise?.repayment_term_identifier
        //   ? item?.sensitise?.repayment_term_identifier.charAt(0).toUpperCase()
        //   : item?.other?.repayment_term_identifier.charAt(0).toUpperCase();

        if (matched > -1) {
          businessRecord[matched].facilities.push(item);
        } else {
          businessRecord.push({
            id: item.facility_owners.map((el) => el.id).join('|'),
            name: displayOwnerNameFromType(item.facility_owners),
            facilities: [item]
          });
        }
      });

    let personalRecord = [];
    serviceList?.data?.personal?.length > 0 &&
      serviceList.data.personal.map((item) => {
        personaltotal.limit +=
          // Math.round(unformatNumber(item.existing_limit));
          roundOff(unformatNumber(item.existing_limit));
        personaltotal.balloon +=
          // Math.round(unformatNumber(item.balloon));
          roundOff(unformatNumber(item.balloon));
        personaltotal.repaytype +=
          // Math.round(unformatNumber(item.repayment_amount));
          roundOff(unformatNumber(item.repayment_amount));

        let matched = personalRecord.findIndex(
          (el) => el.id == item.facility_owners.map((el) => el.id).join('|')
        );
        // let memberList = item.facility_owners.filter((el) => {
        //   if (el.type == 'individual') return el;
        // });
        // let interest_pa = sumOfNumbers(
        //   memberList.map((el) => {
        //     if (el?.interest_pa) return el.interest_pa;
        //     return 0;
        //   })
        // );
        // let repayment_pa = sumOfNumbers(
        //   memberList.map((el) => {
        //     if (el?.repayment_pa) return el.repayment_pa;
        //     return 0;
        //   })
        // );
        personaltotal.interestpa +=
          // Math.round(unformatNumber(item.interest_pa));
          roundOff(unformatNumber(item.interest_pa));
        personaltotal.repaypa +=
          // Math.round(unformatNumber(item.repayment_pa));
          roundOff(unformatNumber(item.repayment_pa));
        // item.interest_pa = interest_pa;
        // item.repayment_pa = repayment_pa;
        // item.repayment_amount = item?.repayment_amount;
        item.balloon =
          item?.product_facility !== 'credit-card' && item?.product_facility !== 'store-card'
            ? item?.balloon
            : '';
        item.term_remaining_months =
          item?.product_facility !== 'credit-card' && item?.product_facility !== 'store-card'
            ? item?.sensitise?.term_remaining_months
              ? item?.sensitise?.term_remaining_months
              : item?.term_remaining_months
            : '';
        item.interest_rate_pa =
          item?.product_facility !== 'credit-card' && item?.product_facility !== 'store-card'
            ? item?.sensitise?.interest_rate_pa
              ? item?.sensitise?.interest_rate_pa
              : item?.other?.interest_rate_pa
            : '';
        item.facility_expiry_date =
          item?.product_facility !== 'credit-card' && item?.product_facility !== 'store-card'
            ? moment(item?.other?.facility_expiry_date, 'YYYY-MM-DD', true).format('DD/MM/YY')
            : '';
        item.interest_pa =
          item?.product_facility === 'credit-card' || item?.product_facility === 'store-card'
            ? ''
            : item.interest_pa;
        item.frequency =
          item.frequency == '52'
            ? 'W'
            : item.frequency == 26
            ? 'F'
            : item.frequency == 12
            ? 'M'
            : item.frequency == 4
            ? 'Q'
            : item.frequency == 2
            ? 'H'
            : item.frequency == 1
            ? 'A'
            : '';
        item.member_id = item?.facility_owners?.[0]?.id ? item?.facility_owners?.[0]?.id : '';

        item.repayment_type = (
          item?.sensitise?.repayment_type
            ? item?.sensitise?.repayment_type
            : item?.other?.repayment_type
        )
          ? Object.keys(configList).length > 0 &&
            configList['facilities_repayment_types'].find(
              (el) =>
                el.key ==
                (item?.sensitise?.repayment_type
                  ? item?.sensitise?.repayment_type
                  : item?.other?.repayment_type)
            )?.value
          : '';

        item.product_facility = item?.product_facility
          ? Object.keys(configList).length > 0 &&
            configList['facilities_products'].find((el) => el.key == item?.product_facility)?.value
          : '';

        item.lender =
          item.facility_status == 'new'
            ? ''
            : // : item.facility_status == 'refinance'
              // ? ' '
              Object.keys(configList).length > 0 &&
              configList['facilities_lenders'].find((el) => el.key == item?.lender)?.value;
        item.edit = '';
        // item.key_star_1 = item?.sensitise?.interest_rate_identifier
        //   ? item?.sensitise?.interest_rate_identifier.charAt(0).toUpperCase()
        //   : item?.other?.interest_rate_identifier.charAt(0).toUpperCase();
        // item.key_star_2 = item?.sensitise?.repayment_term_identifier
        //   ? item?.sensitise?.repayment_term_identifier.charAt(0).toUpperCase()
        //   : item?.other?.repayment_term_identifier.charAt(0).toUpperCase();
        if (matched > -1) {
          personalRecord[matched].facilities.push(item);
        } else {
          personalRecord.push({
            id: item.facility_owners.map((el) => el.id).join('|'),
            name: displayOwnerNameFromType(item.facility_owners),
            facilities: [item]
          });
        }
      });

    setBusinessTotal(businesstotal);
    setPersonalTotal(personaltotal);
    setBusinessData(businessRecord);
    setPersonalData(personalRecord);
  }, [sensitiseddebtservicingList]);

  useEffect(() => {
    dispatch(getConfig(configList));
  }, []);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    dispatch(updateComment(jobId, values, setErrors));
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <main id="main" className="main">
        <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard/'}>Dashboard</Link>
              </li>
              <li>
                <a href="#">Group Analysis</a>
              </li>
              <li className="breadcrumb-item active">
                <strong>Sensitised Debt Servicing Calculations</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="pagetitle groupanalysispro">
          <Tableinfo title="Sensitised Debt Servicing Calculations" />
        </div>
        <div className="facility-form form-data">
          <div className="white-card">
            <div className="rentalview">
              <div className="row justify-content-start">
                <div className="col-12">
                  <Sensitised
                    mainheadData={getMainHeadData()}
                    headData={headData}
                    tbodyData={businessData}
                    pbodyData={personalData}
                    businesstotalValue={businesstotalValue}
                    personaltotalValue={personaltotalValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
              {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                return (
                  <Form onSubmit={handleSubmit} className="facility-form form-data">
                    <CommentsNotes
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleInputChange={handleInputChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                    <div className="action-box full">
                      <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </main>
    </div>
  );
}
