import React from 'react';
import { useEffect, useState } from 'react';
import Pagination from '../../pages/common/Pagination';
import AddPracticeModal from '../AddPracticeModal';
import CustomModal from '../CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getpracticeManager,
  deletePracticeManager
  // getdetailPracticeManager
} from '../../slices/slices/CommonSlice';
import { commonState } from '../../slices/slices/CommonSlice';

export default function PracticeUser(props) {
  const { tbodyData } = props;
  const DELETED = 'deleted';
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const [colummName, setColummName] = useState({ type: '', columm: '' });
  const [open, setOpen] = useState(false);
  const [deletemember, setDeleteMember] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [userData, setuserData] = useState([]);
  const dispatch = useDispatch();
  const { getpracticeManagerList } = useSelector(commonState);

  const onSortingData = (columm, type) => {
    setColummName({ type: type, columm: columm });
    const jobDataSort = items?.slice().sort((a, b) => {
      if (type == 'asc') {
        return (
          typeof a?.[columm] == 'string'
            ? String(a?.[columm]).toLocaleLowerCase() > String(b?.[columm]).toLocaleLowerCase()
            : a?.[columm] > b?.[columm]
        )
          ? 1
          : -1;
      }
      return (
        typeof a?.[columm] == 'string'
          ? String(b?.[columm]).toLocaleLowerCase() > String(a?.[columm]).toLocaleLowerCase()
          : b?.[columm] > a?.[columm]
      )
        ? 1
        : -1;
    });
    setItems(jobDataSort);
  };

  useEffect(() => {
    setTotal(tbodyData?.total);
    setLimit(tbodyData?.per_page);
    setCurrentPage(tbodyData?.current_page);
    let newData;
    newData = tbodyData?.data?.map((item, index) => {
      return {
        ...item,
        practice_name: item?.practice_settings?.name,
        user_limit: item?.practice_settings?.user_limit,
        abn: item?.additional_info?.abn,
        id: index + 1,
        tempId: item.id
      };
    });

    setItems(newData);
  }, [tbodyData]);

  useEffect(() => {
    setTotal(getpracticeManagerList?.total);
    setLimit(getpracticeManagerList?.per_page);
    setCurrentPage(getpracticeManagerList?.current_page);
    let newData;
    newData =
      getpracticeManagerList.data &&
      getpracticeManagerList.data?.map((item, index) => {
        return {
          ...item,
          practice_name: item?.practice_settings?.name,
          abn: item?.additional_info?.abn,
          id: index + 1,
          tempId: item.id,
          user_limit: item?.practice_settings?.user_limit
        };
      });
    setItems(newData);
  }, []);

  const handleUpdateLimit = (element) => {
    dispatch(getpracticeManager(element.target.value, currentPage));
    setLimit(element.target.value);
    setCurrentPage(currentPage);
  };
  const handleChangePage = (page) => {
    dispatch(getpracticeManager(limit, page));
    setCurrentPage(page);
  };

  const handleEditmodal = (id) => {
    setuserData(getpracticeManagerList.data[id]);
    // dispatch(getdetailPracticeManager(id));
    setOpen(true);
  };
  const onhandleDelete = (id) => {
    setDeleteMember(id);
    setShowDelete(true);
  };

  const handledeleteMember = () => {
    if (deletemember)
      dispatch(deletePracticeManager(deletemember)).then((res) => {
        if (res) {
          setShowDelete(false);
          dispatch(getpracticeManager(limit, currentPage));
        }
      });
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
  };

  return (
    <>
      <div className="white-card">
        <table className="table table-hover" style={{ marginBottom: '20px' }}>
          <thead>
            <tr>
              <th scope="col" className="align-centre" style={{ width: '10%' }}>
                Sr No.
                <i
                  className={`fa ${
                    colummName.type === 'desc' && colummName.columm === 'id'
                      ? 'fa-sort-down'
                      : colummName.type === 'asc' && colummName.columm === 'id'
                      ? 'fa-sort-up'
                      : 'fa-sort'
                  }`}
                  aria-hidden="true"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() =>
                    onSortingData(
                      'id',
                      colummName.type === 'asc' && colummName.columm === 'id' ? 'desc' : 'asc'
                    )
                  }
                />
              </th>
              <th scope="col" className="align-centre">
                Practice Name
                <i
                  className={`fa ${
                    colummName.type === 'desc' && colummName.columm === 'practice_name'
                      ? 'fa-sort-down'
                      : colummName.type === 'asc' && colummName.columm === 'practice_name'
                      ? 'fa-sort-up'
                      : 'fa-sort'
                  }`}
                  aria-hidden="true"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() =>
                    onSortingData(
                      'practice_name',
                      colummName.type === 'asc' && colummName.columm === 'practice_name'
                        ? 'desc'
                        : 'asc'
                    )
                  }
                />
              </th>
              <th scope="col" className="align-left">
                Email Address
                <i
                  className={`fa ${
                    colummName.type === 'desc' && colummName.columm === 'email'
                      ? 'fa-sort-down'
                      : colummName.type === 'asc' && colummName.columm === 'email'
                      ? 'fa-sort-up'
                      : 'fa-sort'
                  }`}
                  aria-hidden="true"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() =>
                    onSortingData(
                      'email',
                      colummName.type === 'asc' && colummName.columm === 'email' ? 'desc' : 'asc'
                    )
                  }
                />
              </th>
              <th scope="col" className="align-left">
                Phone No
                <i
                  className={`fa ${
                    colummName.type === 'desc' && colummName.columm === 'phone'
                      ? 'fa-sort-down'
                      : colummName.type === 'asc' && colummName.columm === 'phone'
                      ? 'fa-sort-up'
                      : 'fa-sort'
                  }`}
                  aria-hidden="true"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() =>
                    onSortingData(
                      'phone',
                      colummName.type === 'asc' && colummName.columm === 'phone' ? 'desc' : 'asc'
                    )
                  }
                />
              </th>
              <th scope="col" style={{ width: '10%' }}>
                ABN
                <i
                  className={`fa ${
                    colummName.type === 'desc' && colummName.columm === 'abn'
                      ? 'fa-sort-down'
                      : colummName.type === 'asc' && colummName.columm === 'abn'
                      ? 'fa-sort-up'
                      : 'fa-sort'
                  }`}
                  aria-hidden="true"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() =>
                    onSortingData(
                      'abn',
                      colummName.type === 'asc' && colummName.columm === 'abn' ? 'desc' : 'asc'
                    )
                  }
                />
              </th>
              <th scope="col" style={{ width: '10%' }}>
                No. of Users
                <i
                  className={`fa ${
                    colummName.type === 'desc' && colummName.columm === 'user_limit'
                      ? 'fa-sort-down'
                      : colummName.type === 'asc' && colummName.columm === 'user_limit'
                      ? 'fa-sort-up'
                      : 'fa-sort'
                  }`}
                  aria-hidden="true"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() =>
                    onSortingData(
                      'user_limit',
                      colummName.type === 'asc' && colummName.columm === 'user_limit'
                        ? 'desc'
                        : 'asc'
                    )
                  }
                />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.length > 0 &&
              items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="align-centre">{item.id}</td>
                    <td className="align-centre">{item.practice_name}</td>
                    <td className="align-left">{item.email}</td>
                    <td className="align-left">{item.phone}</td>
                    <td>{item?.abn}</td>
                    <td>{item?.user_limit}</td>
                    <td className="staff-btn">
                      <a className="edit" onClick={() => handleEditmodal(index)}>
                        <i className="icon-edit"></i>
                      </a>
                      <a className="delete" onClick={() => onhandleDelete(item.tempId)}>
                        <i className="icon-delete"></i>
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={currentPage}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
        />
        <CustomModal
          value="delete"
          show={showDelete}
          title="Delete Practice User?"
          text="Are you sure you want to delete this practice user?"
          cancel={handleDeleteCancel}
          reference={DELETED}
          success={handledeleteMember}
        />
        {userData && (
          <AddPracticeModal
            open={open}
            setOpen={setOpen}
            userData={userData}
            lastPage={getpracticeManagerList?.current_page}
            type={false}
          />
        )}
      </div>
    </>
  );
}
