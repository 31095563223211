import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import ContentEditable from 'react-contenteditable';
import {
  formatNumber,
  roundOff,
  /* positiveNumber, */ unformatNumber
} from '../../../../common/Misc';
import {
  // updateFinancialDate,
  updateFinancialData
} from '../../../../../slices/slices/FinancialsSlice';
import { dataEntryState } from '../../../../../slices/slices/DataEntrySlice';
import CreatableSelect from 'react-select/creatable';

const EditTableRows = ({
  handleRemove,
  data_key,
  items,
  years,
  colspan,
  type,
  negative,
  handleInputChange,
  isSelectable,
  itemIndex = 0,
  negativeList
}) => {
  EditTableRows.propTypes = {
    handleRemove: PropTypes.func,
    data_key: PropTypes.string,
    items: PropTypes.array,
    negativeList: PropTypes.array,
    years: PropTypes.array,
    colspan: PropTypes.string,
    itemIndex: PropTypes.number,
    type: PropTypes.any,
    negative: PropTypes.bool,
    handleInputChange: PropTypes.any,
    isSelectable: PropTypes.bool
  };
  const dispatch = useDispatch();
  const [localItems, setLocalItems] = useState(items);
  const { selectedMember } = useSelector(dataEntryState);

  const handleTitleUpdate = (index, key, element) => {
    let tempItems = JSON.parse(JSON.stringify(items));
    element.target !== undefined
      ? (tempItems[index][1].title = element.target.innerHTML.replace(/<[^>]+>/g, '')
          ? element.target.innerHTML.replace(/<[^>]+>/g, '')
          : element.target !== undefined
          ? (tempItems[index][1].title = element.target.innerHTML ? element.target.innerHTML : '')
          : (tempItems[index][1].title = element.value))
      : (tempItems[index][1].title = element.value);

    handleUpdate(
      key,
      element.target?.innerHTML
        ? element.target.innerHTML.replace(/<[^>]+>/g, '')
        : element.target !== undefined
        ? (tempItems[index][1].title = element.target.innerHTML
            ? element.target.innerHTML.replace(/<[^>]+>/g, '')
            : '')
        : (tempItems[index][1].title = element.value.replace(/<[^>]+>/g, '')),
      '',
      null,
      'member',
      element.member_id
    );

    // let tempItems = JSON.parse(JSON.stringify(items));
    // element.target !== undefined
    //   ? (tempItems[index][1].title = element.target.innerHTML.replace(/<[^>]+>/g, '')
    //       ? element.target.innerHTML.replace(/<[^>]+>/g, '')
    //       : element.target !== undefined
    //       ? (tempItems[index][1].title = element.target.innerHTML ? element.target.innerHTML : '')
    //       : (tempItems[index][1].title = element.value))
    //   : (tempItems[index][1].title = element.value),
    //   tempItems[index];

    // handleUpdate(
    //   key,
    //   element.target?.innerHTML
    //     ? element.target.innerHTML.replace(/<[^>]+>/g, '')
    //     : element.target !== undefined
    //     ? (tempItems[index][1].title = element.target.innerHTML
    //         ? element.target.innerHTML.replace(/<[^>]+>/g, '')
    //         : '')
    //     : ((tempItems[index][1].title = element.value.replace(/<[^>]+>/g, '')),
    //       '',
    //       null,
    //       null,
    //       element.member_id)
    // );
  };

  // const handleDeleteRow = (index) => {
  //   let tempItems = JSON.parse(JSON.stringify(localItems));
  //   tempItems.splice(index, 1);
  //   setLocalItems(tempItems);
  //   setItems();
  // };

  // const handleValuesUpdate = (index, key, year, element) => {
  //   let tempItems = JSON.parse(JSON.stringify(items));
  //   tempItems[index][1].data = Object.assign({}, tempItems[index][1].data);
  //   let data_key = '';
  //   data_key += year;
  //   tempItems[index][1].data[data_key] = unformatNumber(element.target.value);
  //   // tempItems[index][1].data[data_key] = negative
  //   //   ? unformatNumber(element.target.value) < 0
  //   //     ? unformatNumber(element.target.value)
  //   //     : unformatNumber(element.target.value) * -1
  //   //   : unformatNumber(element.target.value);
  //   handleUpdate(key, unformatNumber(element.target.value), year, tempItems[index][1].title);
  // };

  const convertHTMLEntity = (text) => {
    const span = document.createElement('span');
    if (typeof text == 'string') {
      return text.replace(/&[#A-Za-z0-9]+;/gi, (entity) => {
        span.innerHTML = entity;
        return span.innerText;
      });
    } else {
      return text;
    }
  };

  const handleUpdate = (index, data, year = '', title = null, type, member_id = '') => {
    if (type === 'profit') {
      if (isNaN(data)) data = '';
    } else if (type === 'percentage') {
      if (isNaN(data) || Number(data) < 0) data = '0';
      else if (Number(data) > 100) data = '100';
    }
    let updates = {};
    updates['key'] = data_key;
    updates['index'] = Number(itemIndex) + Number(index);

    if (data_key === 'surplus_profit') {
      if (type === 'member') {
        if (
          localItems?.find(
            (k) => k?.[1]?.member_id == member_id && k?.[1]?.title !== 'Select if Applicable'
          )
        ) {
          alert('Member is already selected.');
          updates['data'] = localItems[index][1].title;
        } else {
          updates['data'] = convertHTMLEntity(data);
        }
      } else if (type) {
        updates['data'] = {
          [type]: convertHTMLEntity(data)
        };
      } else {
        updates['data'] = convertHTMLEntity(data);
      }
    } else {
      updates['data'] = convertHTMLEntity(data);
    }
    updates['title'] = title ? convertHTMLEntity(title) : null;

    if (member_id != null && member_id != undefined && member_id != '') {
      if (localItems?.find((k) => k?.[1]?.member_id === member_id)) {
        updates['member_id'] = localItems[index][1]?.member_id;
      } else if (data === 'Select if Applicable' || data === 'Select if applicable') {
        updates['member_id'] = '';
      } else {
        updates['member_id'] = member_id;
      }
    } else if (data === 'Select if Applicable' || data === 'Select if applicable') {
      updates['member_id'] = '';
    }

    if (year !== '') {
      updates['year'] = year;
    }

    if (data !== null) {
      data;
      dispatch(updateFinancialData({ ...updates, type: selectedMember.type }));
    }
  };

  const handleNegativeValues = (event, index, item, year) => {
    const value = unformatNumber(event.target.innerHTML);

    let tempItems = JSON.parse(JSON.stringify(items));
    tempItems[index][1].data = Object.assign({}, tempItems[index][1].data);

    let finalValue = value;
    if (
      negative ||
      (negativeList && negativeList.length > 0 && negativeList.includes(item[1]?.key))
    ) {
      finalValue = value * -1;
    }

    handleUpdate(index, finalValue, year, tempItems[index][1].title);
  };

  const handleNegativeValuesSurplusPercentage = (event, index, item, year) => {
    let temp = event?.target?.innerHTML?.split('%')[0];
    const value = unformatNumber(temp);
    let tempItems = JSON.parse(JSON.stringify(items));
    tempItems[index][1].data.percentage = Object.assign({}, tempItems[index][1].data.percentage);
    let finalValue = value;
    handleUpdate(index, finalValue, year, tempItems[index][1].title, 'percentage');
  };

  const handleFocusEvent = (event, index, item, year) => {
    if (
      negative ||
      (negativeList && negativeList.length > 0 && negativeList.includes(item[1]?.key))
    ) {
      const value = unformatNumber(event.target.innerHTML);
      let tempItems = JSON.parse(JSON.stringify(items));
      tempItems[index][1].data = Object.assign({}, tempItems[index][1].data);
      let data_key = '';
      data_key += year;
      tempItems[index][1].data[data_key] = value * -1;
      handleUpdate(index, value * -1, year, tempItems[index][1].title);
    }
  };

  const handleFocusSurplusPercentageEvent = (event, index, item, year) => {
    if (
      negative ||
      (negativeList && negativeList.length > 0 && negativeList.includes(item[1]?.key))
    ) {
      const value = unformatNumber(event.target.innerHTML.replace('%', ''));
      let tempItems = JSON.parse(JSON.stringify(items));
      tempItems[index][1].data = Object.assign({}, tempItems[index][1].data);
      let data_key = '';
      data_key += year;
      tempItems[index][1].data.percentage[data_key] = value;
      handleUpdate(index, value, year, tempItems[index][1].title, 'percentage');
    }
  };

  // Validate entered number
  const validateNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const string = String.fromCharCode(keyCode);
    const regex = /[0-9,-]|\./;

    if (!regex.test(string)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  let options = [];
  if (data_key === 'surplus_profit') {
    options = type;
  } else
    type?.map((item) => {
      options.push({
        label: item,
        value: item
      });
    });

  return (
    <>
      {localItems !== undefined
        ? localItems.map((item, index) => {
            return (
              <Table.Row key={item[0]}>
                <Table.Cell key="title" colSpan={colspan}>
                  {item[1]?.deletable !== undefined && item[1]?.deletable && (
                    <>
                      <i className="add">
                        <i
                          className="fa fa-trash-alt"
                          style={{ fontSize: '20px' }}
                          onClick={() =>
                            handleRemove &&
                            handleRemove(Number(index) + Number(itemIndex), data_key)
                          }
                        ></i>
                      </i>
                    </>
                  )}
                  {item[1]?.editable !== undefined && !item[1]?.editable ? (
                    <>
                      <span>{item[1]?.title} </span>
                    </>
                  ) : (
                    <>
                      {(type && Array.isArray(type)) || isSelectable ? (
                        <>
                          <CreatableSelect
                            type="select"
                            classNamePrefix="select"
                            className="custom-select"
                            value={{
                              label: item[1].title,
                              value: item[1].title
                            }}
                            options={options}
                            aria-label="Default select example"
                            placeholder="Select or type a New Entry"
                            onChange={(event) => {
                              handleTitleUpdate(index, index, event, 'dropdown');
                              handleInputChange(event.value);
                            }}
                          ></CreatableSelect>
                          {/* <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {item[1].title && item[1].title
                              ? ''
                              : data_key == 'dividend'
                              ? ''
                              : 'Please select value'}
                          </span> */}
                          {/* <select
                            value={item[1].title}
                            onChange={(event) => {
                              handleTitleUpdate(index, item[0], event);
                              handleInputChange(event.target.value);
                            }}
                          >
                            {type.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select> */}
                        </>
                      ) : (
                        <>
                          <ContentEditable
                            html={item[1]?.title}
                            data-column="item"
                            className="content-editable"
                            onBlur={(event) => {
                              handleTitleUpdate(index, index, event, 'text');
                              handleInputChange(event.target.innerHTML);
                            }}
                            // onChange={(event) => {
                            //   handleTitleUpdate(index, item[0], event);
                            //   handleInputChange(event.target.value);
                            // }}
                          />
                        </>
                      )}
                    </>
                  )}
                </Table.Cell>
                {data_key !== 'surplus_profit' &&
                  (item[1]?.data !== undefined
                    ? years.map((year) => {
                        return item[1].data[year] !== undefined ? (
                          <>
                            <Table.Cell key={year} className="align-right">
                              <ContentEditable
                                html={formatNumber(item[1].data[year])}
                                data-column="item"
                                data-row={year}
                                disabled={
                                  String(item[1]?.editableValues)
                                    ? String(item[1]?.editableValues) === 'false'
                                    : false
                                }
                                className="content-editable"
                                onKeyPress={(e) => validateNumber(e)}
                                onBlur={(event) => {
                                  handleNegativeValues(event, index, item, year);
                                  handleInputChange(event.target.value);
                                }}
                                onFocus={(event) => handleFocusEvent(event, index, item, year)}
                              />
                            </Table.Cell>
                            <Table.Cell />
                          </>
                        ) : (
                          <>
                            <Table.Cell key={year} className="align-right">
                              <ContentEditable
                                html=""
                                data-column="item"
                                data-row={year}
                                disabled={
                                  String(item[1]?.editableValues)
                                    ? String(item[1]?.editableValues) === 'false'
                                    : false
                                }
                                handleInputChange={handleInputChange}
                                className="content-editable"
                                onKeyPress={(e) => validateNumber(e)}
                                onBlur={(event) => {
                                  handleNegativeValues(event, index, item, year);
                                  handleInputChange(event.target.value);
                                }}
                                onFocus={(event) => handleFocusEvent(event, index, item[0], year)}
                              />
                            </Table.Cell>
                            <Table.Cell />
                          </>
                        );
                      })
                    : null)}
                {data_key === 'surplus_profit' &&
                  (item[1]?.data !== undefined
                    ? years.map((year) => {
                        return item[1].data.profit[year] !== undefined ? (
                          <>
                            <Table.Cell key={year} className="align-right">
                              {/* {item[1].data.profit[year] > 0
                                ? formatNumber(Math.floor(item[1].data.profit[year]))
                                : formatNumber(Math.ceil(item[1].data.profit[year]))} */}
                              {formatNumber(roundOff(item[1].data.profit[year]))}
                            </Table.Cell>
                            <Table.Cell key={index} className="align-center">
                              <ContentEditable
                                html={`${
                                  item[1].data.percentage[year]
                                    ? formatNumber(item[1].data.percentage[year])
                                    : 0
                                }%`}
                                data-column="item"
                                data-row={year}
                                disabled={
                                  String(item[1]?.editableValues)
                                    ? String(item[1]?.editableValues) === 'false'
                                    : false
                                }
                                className="content-editable"
                                onKeyPress={(e) => validateNumber(e)}
                                onBlur={(event) => {
                                  handleNegativeValuesSurplusPercentage(event, index, item, year);
                                  handleInputChange(event.target.value);
                                }}
                                onFocus={(event) =>
                                  handleFocusSurplusPercentageEvent(event, index, item, year)
                                }
                              />
                            </Table.Cell>
                          </>
                        ) : (
                          <>
                            <Table.Cell key={year} className="align-right">
                              {''}
                            </Table.Cell>
                            <Table.Cell key={index}>
                              <ContentEditable
                                html={'0%'}
                                data-column="item"
                                data-row={year}
                                disabled={
                                  String(item[1]?.editableValues)
                                    ? String(item[1]?.editableValues) === 'false'
                                    : false
                                }
                                handleInputChange={handleInputChange}
                                className="content-editable"
                                onKeyPress={(e) => validateNumber(e)}
                                onBlur={(event) => {
                                  handleNegativeValuesSurplusPercentage(event, index, item, year);
                                  handleInputChange(event.target.value);
                                }}
                                onFocus={(event) =>
                                  handleFocusSurplusPercentageEvent(event, index, item, year)
                                }
                              />
                            </Table.Cell>
                          </>
                        );
                      })
                    : null)}
              </Table.Row>
            );
          })
        : null}
    </>
  );
};

export default EditTableRows;
