import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { XpmIntFieldsSchema } from '../common/Validation';
import {
  getXpmIntFieldsSettingData,
  settingState,
  changeXpmIntFieldsSettingData,
  setChangeXpmIntFieldsSettingResponse
} from '../../slices/slices/SettingSlice';

import { useNavigate } from 'react-router';
import Label from '../../components/Label';

const XpmIntFields = () => {
  const dispatch = useDispatch();

  const { xpmIntFieldsSettingResponse, xpmIntFieldsSettingChangeResponse } =
    useSelector(settingState);

  useEffect(() => {
    dispatch(getXpmIntFieldsSettingData());
  }, []);

  const handleFormSubmit = (values, { setErrors }) => {
    dispatch(changeXpmIntFieldsSettingData(values, setErrors));
  };

  useEffect(() => {
    if (xpmIntFieldsSettingChangeResponse.message) {
      if (xpmIntFieldsSettingChangeResponse) {
        dispatch(setChangeXpmIntFieldsSettingResponse({}));
      }
    }
  }, [xpmIntFieldsSettingChangeResponse]);

  const navigate = useNavigate();
  const onClickCancle = () => {
    navigate('/dashboard');
  };

  return (
    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <Formik
        initialValues={{
          xero_api_key: xpmIntFieldsSettingResponse.xero_api_key,
          xero_api_secret: xpmIntFieldsSettingResponse.xero_api_secret
        }}
        enableReinitialize
        validationSchema={XpmIntFieldsSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="row needs-validation" noValidate>
            <div className="innertab">
              <div className="container">
                <div className="textarea">
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group flex-column has-validation">
                        <Label>Xero API Key</Label>
                        <Input
                          type="text"
                          name="xero_api_key"
                          placeholder=""
                          className="form-control"
                          id="xerox_api_key"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.xero_api_key}
                          required
                        />
                        <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                          {errors.xero_api_key}
                        </span>
                        {/* <span>
                          <a href="#">Change</a>
                        </span> */}
                        <span className="note-txt">
                          * Enter the API key from Xero account settings.
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group flex-column has-validation">
                        <Label>Xero API Secret</Label>
                        <Input
                          type="text"
                          name="xero_api_secret"
                          placeholder=""
                          className="form-control"
                          id="xerox_api_secret"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.xero_api_secret}
                          required
                        />
                        <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                          {errors.xero_api_secret}
                        </span>
                        {/* <span>
                          <a href="#">Change</a>
                        </span> */}
                        <span className="note-txt">
                          * Enter the API Secret from Xero account settings.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_area text-end">
              <div className="container">
                <Button className="btn btn-outline-secondary" type="reset" onClick={onClickCancle}>
                  Cancel
                </Button>
                <Button className="btn btn-success" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default XpmIntFields;
