import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import {
  deleteSecurity,
  getSecurityListData,
  securityState,
  setDeleteSecurityDataResponse,
  getMemberData,
  getMetaData,
  manageFacilityRelation
} from '../../../../slices/slices/SecuritySlice';
import Button from '../../../../components/Button';
import { Link, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { getMetaData as getMetaDataFacility } from '../../../../slices/slices/FacilitySlice';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType, displayOwnerNameFromType, formatNumber } from '../../../common/Misc';
import Info from '../../../../components/Info';
import { updateMarkAll } from '../../../../slices/slices/JobSetupSlice';

const Securities = () => {
  const dispatch = useDispatch();
  const {
    memberDataResponse,
    securityListDataResponse,
    deleteSecurityDataResponse,
    manageFacilityDataResponse
  } = useSelector(securityState);
  // const { metaDataResponse: metaDataResponseFacility } = useSelector(facilityState);
  const [showDelete, setShowDelete] = useState(false);
  const [securityId, setSecurityId] = useState(0);
  const { jobId, memberId } = useParams();

  let market_value_total = 0,
    lending_value_total = 0;

  const handleDelete = () => {
    setShowDelete(false);
  };

  const handleMarkAllCompleted = (setErrors) => {
    let formData = { module: 'security' };
    // formData.append('module', 'facility');
    dispatch(updateMarkAll(jobId, memberId, formData, setErrors));
  };

  const delSecurity = () => {
    dispatch(deleteSecurity(jobId, memberId, securityId));
  };
  useEffect(() => {
    if (deleteSecurityDataResponse?.message) {
      setShowDelete(false);
      dispatch(getSecurityListData(jobId, memberId));
      dispatch(setDeleteSecurityDataResponse({}));
    }
  }, [deleteSecurityDataResponse?.message]);

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getSecurityListData(jobId, memberId));
    dispatch(getMetaData());
    dispatch(getMetaDataFacility());
  }, []);

  useEffect(() => {
    dispatch(getSecurityListData(jobId, memberId));
  }, [manageFacilityDataResponse]);
  //   if (typeof object === 'object') {
  //     let result = object.find((obj) => {
  //       return obj.key === key;
  //     });
  //     return result.value;
  //   }
  // };

  const handleFacilityLinking = (e) => {
    let securityId, facilityId;
    securityId = e.target.dataset.securityid;
    facilityId = e.target.dataset.facilityid;
    dispatch(manageFacilityRelation(jobId, memberId, securityId, facilityId));
  };

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <strong>Securities</strong>
              </li>
            </ul>
            <h1>Securities - {displayNameFromType(memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/securities/add'}>
              <button type="button" className="green topbtn">
                <i className="icon-plus"></i> Security
              </button>
            </Link>
            <DialogModal
              title="Security"
              p1={constants.security1}
              p2={constants.security2}
              p3={constants.security3}
            />
          </div>
        </div>
        <div className="mt-30" style={{ marginRight: '-15px' }}>
          <Info />
        </div>
        <div className="white-card mt-30">
          <section className="facility-table Securitylist member-data-entry security-table">
            <Table className="table" cellPadding="0" cellSpacing="0" border="0">
              <thead>
                <tr>
                  <th className="drag"></th>
                  <th className="security-owner align-left">Security Owner</th>
                  <th className="address align-left">Address</th>
                  <th className="lender">Description</th>
                  <th className="existing-limit">
                    Market Value
                    {/* <br />$ */}
                  </th>
                  <th className="change">Shading</th>

                  <th className="proposed-limit">
                    Lending Value
                    {/* <br />$ */}
                  </th>

                  <th className="action"></th>
                </tr>
              </thead>
              <tbody>
                {securityListDataResponse?.data?.data?.length > 0 ? (
                  securityListDataResponse?.data?.data.map((item) => {
                    market_value_total += item?.market_value;
                    lending_value_total += item?.lending_value;
                    let totalFacilityExistingValue = 0;
                    let totalFacilityLimit = 0;
                    let totalChange = 0;

                    return (
                      <>
                        <tr key={item?.id}>
                          <td data-toggle="collapse" data-target={'#' + item?.id}>
                            <button className="nested-btn active">
                              <i className="fa fa-caret-up"></i>
                            </button>
                          </td>
                          <td className="align-left">
                            {/* <Link
                                to={
                                  '/job/' +
                                  jobId +
                                  '/data-entry/' +
                                  memberId +
                                  '/securities/' +
                                  item?.id
                                }
                              >
                                <span>{memberDataResponse?.name}</span>
                              </Link> */}
                            {displayNameFromType(memberDataResponse)}
                          </td>
                          <td className="align-left">{item?.address}</td>
                          <td>{item?.asset_description_val}</td>
                          <td className="align-right">
                            <NumberFormat
                              value={item?.market_value}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              // decimalScale={2}
                              displayType="text"
                            />
                          </td>
                          <td className="align-right">
                            <NumberFormat
                              value={item?.shading}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              suffix="%"
                              displayType="text"
                            />
                          </td>
                          <td className="align-right">
                            <NumberFormat
                              value={item?.lending_value}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              // decimalScale={2}
                              displayType="text"
                            />
                          </td>

                          <td className="actionarea" width="10%">
                            <i
                              className={
                                item?.is_completed == 1
                                  ? 'fa fa-check-circle'
                                  : 'fa fa-exclamation-triangle'
                              }
                              aria-hidden="true"
                              style={{
                                color: `${item?.is_completed == 1 ? '#42ba96' : '#eed202'}`
                              }}
                            ></i>
                            <Link
                              to={
                                '/job/' +
                                jobId +
                                '/data-entry/' +
                                memberId +
                                '/securities/' +
                                item?.id
                              }
                            >
                              {/* <a className="edit"> */}
                              <i className="icon-edit"></i>
                              {/* </a> */}
                            </Link>
                            <a
                              onClick={() => {
                                setSecurityId(item?.id);
                                setShowDelete(true);
                              }}
                              className="delete"
                            >
                              <i className="icon-delete"></i>
                            </a>
                          </td>
                        </tr>
                        <tr id={item?.id} className="collapse">
                          <td colSpan="8" className="nested-block">
                            <h3>Linked Facilities</h3>
                            <Link
                              to={
                                '/job/' +
                                jobId +
                                '/data-entry/' +
                                memberId +
                                '/facilities/return/securityList/' +
                                item?.id
                              }
                            >
                              <button type="button">
                                <i className="icon-plus"></i> Facility
                              </button>
                            </Link>
                            <table className="table" cellPadding="0" cellSpacing="0">
                              <thead>
                                <tr>
                                  <th className="purpose align-left" width="15%">
                                    Borrower
                                  </th>
                                  <th className="purpose align-left">Purpose</th>
                                  <th className="product-facility">Product/Facility</th>
                                  <th className="lender">Lender</th>
                                  <th className="existing-limit">Existing Limit</th>
                                  <th className="change">Change + / -{/* <br />$ */}</th>
                                  <th className="proposed-limit">
                                    Proposed Limit
                                    {/* <br />$ */}
                                  </th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {item?.facilities && item.facilities.length > 0 ? (
                                  item.facilities.map((facility) => {
                                    totalFacilityExistingValue += parseFloat(
                                      facility?.existing_limit
                                    );
                                    totalFacilityLimit += parseFloat(
                                      facility?.additional_info?.proposed_limit
                                    );

                                    let change = 0;

                                    change =
                                      parseFloat(facility?.additional_info?.proposed_limit) -
                                      parseFloat(facility?.existing_limit);

                                    totalChange += parseFloat(change);

                                    return (
                                      <tr key={facility?.id}>
                                        <td className="align-left">
                                          {displayOwnerNameFromType(facility?.facility_owners)}
                                        </td>
                                        <td className="align-left">{facility?.purpose}</td>
                                        <td>
                                          {/* {findValueFromKey(
                                              metaDataResponseFacility?.data?.facilities_products,
                                              facility?.product_facility
                                            )} */}
                                          {facility?.product_facility_val}
                                        </td>
                                        <td>
                                          {/* {findValueFromKey(
                                              metaDataResponseFacility?.data?.facilities_lenders,
                                              facility?.lender
                                            )} */}
                                          {facility?.lender_val}
                                        </td>
                                        <td className="align-right">
                                          <NumberFormat
                                            value={formatNumber(facility?.existing_limit)}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            // decimalScale={2}
                                            displayType="text"
                                          />
                                        </td>
                                        <td className="align-right">
                                          <NumberFormat
                                            value={formatNumber(Math.abs(change))}
                                            prefix={change < 0 ? '(' : null}
                                            suffix={change < 0 ? ')' : null}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            // decimalScale={2}
                                            displayType="text"
                                          />
                                        </td>
                                        <td className="align-right">
                                          <NumberFormat
                                            value={formatNumber(
                                              facility?.additional_info?.proposed_limit
                                            )}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            // decimalScale={2}
                                            displayType="text"
                                          />
                                        </td>
                                        <td className="actionarea" width="10%">
                                          <i
                                            className={
                                              item?.is_completed == 1
                                                ? 'fa fa-check-circle'
                                                : 'fa fa-exclamation-triangle'
                                            }
                                            aria-hidden="true"
                                            style={{
                                              color: `${
                                                item?.is_completed == 1 ? '#42ba96' : '#eed202'
                                              }`
                                            }}
                                          ></i>
                                          <a
                                            onClick={(e) => handleFacilityLinking(e)}
                                            data-securityid={item?.id}
                                            data-facilityid={facility?.id}
                                            href="#"
                                          >
                                            Unlink
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="8">No Records Found</td>
                                  </tr>
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td className="align-left">Total</td>
                                  <td className="align-left"></td>
                                  <td></td>
                                  <td></td>
                                  <td className="align-right">
                                    <NumberFormat
                                      value={formatNumber(totalFacilityExistingValue)}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      // decimalScale={2}
                                      displayType="text"
                                    />
                                  </td>
                                  <td className="align-right">
                                    <NumberFormat
                                      value={formatNumber(Math.abs(totalChange))}
                                      prefix={totalChange < 0 ? '(' : null}
                                      suffix={totalChange < 0 ? ')' : null}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      // decimalScale={2}
                                      displayType="text"
                                    />
                                  </td>
                                  <td className="align-right">
                                    <NumberFormat
                                      value={formatNumber(totalFacilityLimit)}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                      // decimalScale={2}
                                      displayType="text"
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                              </tfoot>
                            </table>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} style={{ textAlign: 'center' }}>
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td className="align-left"></td>
                  <td className="align-left">Total</td>
                  <td></td>
                  <td></td>
                  <td className="align-right">
                    <NumberFormat
                      value={market_value_total}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      // decimalScale={2}
                      displayType="text"
                    />
                  </td>
                  <td></td>
                  <td className="align-right">
                    <NumberFormat
                      value={lending_value_total}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      // decimalScale={2}
                      displayType="text"
                    />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          </section>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '30px',
            marginBottom: '20px'
          }}
        >
          <button
            className="btn green"
            onClick={() => {
              handleMarkAllCompleted();
            }}
          >
            Section Completed
          </button>
        </div>
        <Modal
          show={showDelete}
          onHide={handleDelete}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <h2>Delete Security?</h2>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this security permanently?</p>
          </Modal.Body>
          <div className="modal-action modal-footer">
            <Button className="btn btn-secondary" onClick={handleDelete}>
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                delSecurity();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Securities;
