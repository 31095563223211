import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field, FieldArray, ErrorMessage, Form } from 'formik';
import { updateIndividualTaxRateConfig } from '../../slices/slices/CommonSlice';

// import { addIndividualTaxSchema } from '../common/Validation';
import { DialogLeavingPage } from '../common/components/DialogLeavingPage';
import { useNavigatingAway } from '../common/hooks/useNavigatingAway';
import CurrencyInput from 'react-currency-input-field';

export default function IndividualTaxRate(props) {
  const { tbodyData } = props;
  const dispatch = useDispatch();
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const initialValue = { title: [{ minincome: '', maxincome: '', rate: '0' }] };

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);
    dispatch(updateIndividualTaxRateConfig(values?.title, setErrors)).then(() => {
      // dispatch(adminGetConfig([], 1, false));
      // dispatch(adminGetConfig([], 2, false));
    });
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  return (
    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <Formik
        initialValues={tbodyData ? { title: tbodyData } : initialValue}
        // validationSchema={addIndividualTaxSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, setFieldValue }) => {
          return (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />

              <Form className="row needs-validation" noValidate>
                <h6 className="row mt-3">
                  <div className="col-md-5">Income Threshold</div>
                  <div className="col-md-4">Rate (%)</div>
                  <div className="col-md-1 mt-1" style={{ padding: 0, textAlign: 'center' }}>
                    Action
                  </div>
                </h6>
                <FieldArray
                  name="title"
                  render={(arrayHelpers) => {
                    return (
                      <div className="shareholder mb-3">
                        {values.title && values.title.length > 0 ? (
                          values.title.map((item, index) => (
                            <div key={index} className="row mt-3">
                              <div className="col-md-2">
                                {/* <Field
                                  // type="number"
                                  name={`title.${index}.minincome`}
                                  placeholder="Minimum Income"
                                  onBlur={handleBlur}
                                  disabled={true}
                                  // disabled={index == 0 && index !== values.title.length - 1}
                                  style={{ padding: '0.375rem 0.75rem' }}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                /> */}
                                <CurrencyInput
                                  // className="form-control"
                                  name={`title.${index}.minincome`}
                                  value={
                                    values?.title[index].minincome &&
                                    !isNaN(String(values?.title[index].minincome))
                                      ? String(values?.title[index].minincome)
                                      : ''
                                  }
                                  disabled={true}
                                  decimalsLimit={0}
                                  onValueChange={(e) => {
                                    setFieldValue(`title.${index}.minincome`, e.target.value);
                                    handleInputChange(e.target.value);
                                  }}
                                  style={{ padding: '0.375rem 0.75rem' }}
                                  onBlur={handleBlur}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`title.${index}.minincome`} />
                                </span>
                              </div>
                              <div className="col-md-3">
                                {/* <Field
                                  // type="number"
                                  name={`title.${index}.maxincome`}
                                  placeholder="Maximum Income"
                                  style={{ padding: '0.375rem 0.75rem' }}
                                  onBlur={handleBlur}
                                  // disabled={index !== 0 && index == values.title.length - 1}
                                  onChange={(e) => {
                                    // handleChange(e);
                                    setFieldValue(
                                      `title.${index}.maxincome`,
                                      e.target.value
                                        ? numbro(Number(e.target.value?.replace(/,/g, ''))).format({
                                            thousandSeparated: true,
                                            negative: 'parenthesis',
                                            mantissa: 0
                                          })
                                        : e.target.value
                                    );
                                    setFieldValue(
                                      `title.${index + 1}.minincome`,
                                      e.target.value
                                        ? numbro(
                                            Number(e.target.value?.replace(/,/g, '')) + 1
                                          ).format({
                                            thousandSeparated: true,
                                            negative: 'parenthesis',
                                            mantissa: 0
                                          })
                                        : e.target.value
                                    );
                                    handleInputChange(e.target.value);
                                    // if (values.title.length - 1 > index) {
                                    //   values.title[index + 1].minincome = ++e.target.value;
                                    // }
                                  }}
                                /> */}
                                <CurrencyInput
                                  // className="form-control"s
                                  name={`title.${index}.maxincome`}
                                  value={
                                    values?.title[index].maxincome &&
                                    !isNaN(String(values?.title[index].maxincome))
                                      ? String(values?.title[index].maxincome)
                                      : ''
                                  }
                                  decimalsLimit={0}
                                  onValueChange={(value) => {
                                    setFieldValue(`title.${index}.maxincome`, value);
                                    setFieldValue(
                                      `title.${index + 1}.minincome`,
                                      Number(value) + 1
                                    );
                                    handleInputChange(value);
                                  }}
                                  style={{ padding: '0.375rem 0.75rem' }}
                                  onBlur={handleBlur}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`title.${index}.maxincome`} />
                                </span>
                              </div>
                              <div className="col-md-3">
                                <Field
                                  type="number"
                                  name={`title.${index}.rate`}
                                  placeholder="%"
                                  style={{ padding: '0.375rem 0.75rem' }}
                                  value={values?.title[index].rate}
                                  onBlur={(e) => {
                                    setFieldValue(
                                      `title.${index}.rate`,
                                      e.target.value
                                        ? e.target.value <= 100
                                          ? e.target.value < 0
                                            ? 0
                                            : e.target.value
                                          : e.target.value
                                        : 100
                                    );
                                  }}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                />
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  <ErrorMessage name={`title.${index}.rate`} />
                                </span>
                              </div>

                              <div
                                className="col-md-3 mt-1"
                                style={{ padding: 0, textAlign: 'center' }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    margin: '1px',
                                    height: '35px',
                                    width: '35px',
                                    padding: 0,
                                    lineHeight: '33px',
                                    borderRadius: '50%'
                                  }}
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }} // remove a friend from the list
                                >
                                  -
                                </button>
                                <button
                                  type="button"
                                  style={{
                                    margin: '1px',
                                    height: '35px',
                                    width: '35px',
                                    padding: 0,
                                    lineHeight: '33px',
                                    borderRadius: '50%'
                                  }}
                                  onClick={() => {
                                    arrayHelpers.insert(index + 1, {
                                      minincome: values.title[index].maxincome
                                        ? values.title[index].maxincome + 1
                                        : '',
                                      maxincome: '',
                                      rate: ''
                                    });
                                  }} // insert an empty string at a position
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <button
                            type="button btn-secondary"
                            onClick={() => {
                              arrayHelpers.push({
                                minincome: values.title.length - 1 == 0 ? '' : '0',
                                maxincome: '',
                                rate: ''
                              });
                            }}
                          >
                            {/* show this when user has removed all friends from the list */}
                            Add a config
                          </button>
                        )}
                      </div>
                    );
                  }}
                />

                <div className="modal-action modal-footer">
                  <button type="button" className="btn btn-secondary">
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-success">
                    Update
                  </button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
