import Input from '../../../../../components/Input';
import Autocomplete from 'react-google-autocomplete';
import Label from '../../../../../components/Label';
import AutomatedSelect from '../../../../../components/fields/AutomatedSelect';
import CommentsNotes from '../../../../../components/fields/CommentsNotes';
import FormActions from '../../../../../components/FormActions';
import { FieldArray } from 'formik';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatNumber } from '../../../../common/Misc';
import {
  securityState,
  manageFacilityRelation,
  getFacilitiesData
} from '../../../../../slices/slices/SecuritySlice';
import { facilityState } from '../../../../../slices/slices/FacilitySlice';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../components/Button';
import { useNavigate } from 'react-router-dom';
import LabelTooltip from '../../../../../components/tooltip/LabelTooltip';
import label from '../../../../../LabelConstant';
import InputMask from 'react-input-mask';
import ErrorFocus from '../../../../common/ErrorFocus';
import { formatAddress } from '../../../../../utils/helper';
import CurrencyInput from 'react-currency-input-field';
// import { displayNameFromType } from '../../../../common/Misc';

const Form = ({
  values,
  setFieldValue,
  errors,
  jobId,
  memberId,
  securityId,
  facilityId,
  // memberDataResponse,
  // setErrors,
  metaDataResponse,
  memberListResponse,
  handleChange,
  handleBlur,
  handleSubmit,
  formValues,
  setIsDraftPopup,
  handleInputChange,
  MemberValue
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    errors: PropTypes.any,
    jobId: PropTypes.any,
    memberId: PropTypes.any,
    securityId: PropTypes.any,
    facilityId: PropTypes.any,
    // memberDataResponse: PropTypes.any,
    // setErrors: PropTypes.any,
    memberListResponse: PropTypes.any,
    metaDataResponse: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    handleSubmit: PropTypes.any,
    formValues: PropTypes.object,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    setIsDraftPopup: PropTypes.any,
    handleInputChange: PropTypes.any,
    MemberValue: PropTypes.object
  };
  // const { setFieldValue } = useFormikContext();
  const { facilitiesDataResponse } = useSelector(securityState);
  const { metaDataResponse: metaDataResponseFacility } = useSelector(facilityState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [startDate] = useState(new Date());

  useEffect(() => {
    if (securityId !== undefined) {
      dispatch(getFacilitiesData(jobId, memberId, securityId));
    }
  }, []);

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  // parseInt(`${fieldValue}`.replace(/,/g, ''))
  // parseInt(`${formValues.current.values['shading']}`.replace(/,/g, ''))
  // parseInt(`${formValues.current.values['market_value']}`.replace(/,/g, ''))

  const handleCalculatedFields = (e, setFunction) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setFunction(fieldName, fieldValue);
    let value = 0;
    if (fieldName === 'market_value') {
      value =
        (parseInt(`${fieldValue}`.replace(/,/g, '')) *
          parseInt(`${formValues.current.values['shading']}`.replace(/,/g, ''))) /
        100;
    } else if (fieldName === 'shading') {
      value =
        (parseInt(`${formValues.current.values['market_value']}`.replace(/,/g, '')) *
          parseInt(`${fieldValue}`.replace(/,/g, ''))) /
        100;
    }
    if (Number.isFinite(value)) {
      setFunction('lending_value', value);
    }
  };

  const handleFacilityLinking = (e) => {
    let facilityId = e.target.value;
    dispatch(manageFacilityRelation(jobId, memberId, securityId, facilityId));
  };

  const findValueFromKey = (object, key) => {
    if (typeof object === 'object') {
      let result = object.find((obj) => {
        return obj.key === key;
      });
      return result?.value;
    }
  };

  const [showDraft, setShowDraft] = useState(false);
  const handleDraft = () => {
    setShowDraft(false);
  };

  // const handleChange1 = (event) => {
  // };

  return (
    <form onSubmit={handleSubmit} noValidate className="facility-form form-data mt-30">
      <div className="white-card">
        <table className="table add-facilities">
          <thead>
            <tr>
              <th scope="col" className="align-left" style={{ width: '50%' }}>
                <LabelTooltip
                  labelId={label.security.securityTip.key}
                  label={label.security.securityTip.label}
                  hover_text={label.security.securityTip.hover_text}
                />
              </th>
              <th scope="col" className="align-left" style={{ width: '50%' }}>
                <LabelTooltip
                  labelId={label.security.securityAddressTip.key}
                  label={label.security.securityAddressTip.label}
                  hover_text={label.security.securityAddressTip.hover_text}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-start" name="owners" style={{ FontWeight: 'bold' }}>
                <FieldArray
                  name="owners"
                  render={(arrayHelpers) => (
                    <div>
                      <div className="shareholder">
                        {values?.owners && values?.owners?.length > 0
                          ? values?.owners?.map((shareholder, index) => (
                              <div key={index}>
                                <Select
                                  className="form-control"
                                  classNamePrefix="form-control"
                                  name={`owners.${index}.member_id`}
                                  aria-label="Default select example"
                                  value={
                                    values?.owners[index]?.member_id?.label &&
                                    values?.owners[index]?.member_id?.value
                                      ? values?.owners[index]?.member_id
                                      : !values?.owners[index]?.member_id?.label &&
                                        values?.owners[index]?.member_id?.value === memberId
                                      ? MemberValue
                                      : ''
                                  }
                                  onBlur={handleBlur}
                                  onChange={(val) => {
                                    setFieldValue(`owners.${index}.member_id`, val);
                                    handleInputChange(val);
                                  }}
                                  options={newColumnsForAll}
                                ></Select>
                                <CurrencyInput
                                  className="form-control"
                                  name={`owners.${index}.holding`}
                                  value={values?.owners[index]?.holding}
                                  // value={values?.balloon ? values?.balloon : ''}
                                  decimalsLimit={2}
                                  onValueChange={(value, name) => {
                                    setFieldValue(name, value);
                                    handleInputChange(value);
                                    // handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="0"
                                />
                                {/* <Input
                                  type="number"
                                  name={`owners.${index}.holding`}
                                  value={formatNumber(values?.owners[index]?.holding)}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e.target.value);
                                  }}
                                  className="form-control"
                                  placeholder="0"
                                /> */}
                                {index !== 0 && (
                                  <i
                                    className="icon-delete"
                                    onClick={() => index !== 0 && arrayHelpers.remove(index)}
                                  />
                                )}
                                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                  {errors?.['owners.' + index + '.member_id']
                                    ? 'This field is required'
                                    : ''}
                                  {errors?.['owners.' + index + '.holding']
                                    ? 'percentage  is required'
                                    : ''}
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors?.owner_holding}
                        {errors?.owners}
                      </span>
                      <div className="add-btn">
                        <i
                          className="icon-plus"
                          onClick={() => {
                            // var isEmpty = false;
                            // for (var c = 0; c < values?.owners?.length; c++) {
                            //   if (values?.owners[c]?.holding == 0) {
                            //     setErrors({ ...errors, owners: 'enter percentage' });
                            //   } else {
                            //     setErrors({ ...errors, owners: 'select member' });
                            //   }

                            //   if (!values?.owners[c]?.member_id || !values?.owners[c]?.holding) {
                            //     isEmpty = true;
                            //     return;
                            //   }
                            // }
                            // if (!isEmpty) {
                            arrayHelpers.push({
                              member_id: '',
                              holding: ''
                            });
                            // }
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                />
              </td>
              {/* <td scope="row" className="align-left">
                <strong>{displayNameFromType(memberDataResponse)}</strong>
              </td> */}
              <td className="align-left">
                <Autocomplete
                  autoComplete="off"
                  apiKey={process.env.REACT_APP_API_KEY}
                  style={{ width: '100%' }}
                  name="address"
                  placeholder=""
                  className="form-control"
                  value={values?.address}
                  onChange={(val) => {
                    setFieldValue('address', val?.target?.value);
                  }}
                  onPlaceSelected={(place) => {
                    let formattedAddress = formatAddress(place);
                    setFieldValue('address', formattedAddress);
                    handleInputChange(formattedAddress);
                  }}
                  options={{
                    types: 'street_address',
                    componentRestrictions: { country: ['au', 'nz'] }
                  }}
                />
                {/* <Input
                  type="text"
                  name="address"
                  className="form-control"
                  value={values?.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '100%' }}
                /> */}
                <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                  {errors.address}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row">
          <div className="col-6">
            <div className="form-box">
              <div className="row">
                <div className="col-6">
                  <div className="form-field">
                    <Label>Asset Class</Label>
                    <AutomatedSelect
                      name="asset_class"
                      value={values?.asset_class ? values?.asset_class : ''}
                      data={metaDataResponse?.data?.securities_asset_class}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleInputChange(e.target.value);
                        handleChange(e);
                      }}
                      onChange={(e) => {
                        if (values?.asset_class === 'business-assets') {
                          setFieldValue('asset_type', '');
                          setFieldValue('asset_usage', '');
                        }
                        handleInputChange(e.target.value);
                      }}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.asset_class}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>Asset Type</Label>
                    <AutomatedSelect
                      name="asset_type"
                      value={
                        values?.asset_class === 'business-assets'
                          ? ''
                          : values?.asset_type
                          ? values?.asset_type
                          : ''
                      }
                      data={metaDataResponse?.data?.securities_asset_types}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleInputChange(e.target.value);
                        handleChange(e);
                      }}
                      isOptionDisabled={values?.asset_class === 'business-assets' ? true : false}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.asset_type}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>Asset Usage</Label>
                    <AutomatedSelect
                      name="asset_usage"
                      value={
                        values?.asset_class === 'business-assets'
                          ? ''
                          : values?.asset_usage
                          ? values?.asset_usage
                          : ''
                      }
                      data={metaDataResponse?.data?.securities_asset_usages}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleInputChange(e.target.value);
                        handleChange(e);
                      }}
                      placeholderText="Select if Applicable"
                      isOptionDisabled={values?.asset_class === 'business-assets' ? true : false}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.asset_usage}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>Asset Description</Label>
                    <AutomatedSelect
                      name="asset_description"
                      value={values?.asset_description ? values?.asset_description : ''}
                      data={metaDataResponse?.data?.securities_asset_description}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleInputChange(e.target.value);
                        handleChange(e);
                      }}
                      headerValue={['property', 'balance-sheet']}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.asset_description}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <LabelTooltip
                      labelId={label.security.marketValueTip.key}
                      label={label.security.marketValueTip.label}
                      hover_text={label.security.marketValueTip.hover_text}
                    />
                    <NumberFormat
                      decimalScale={0}
                      allowNegative={false}
                      onChange={(e) => {
                        handleInputChange(e.target.value);
                        handleCalculatedFields(e, setFieldValue);
                      }}
                      name="market_value"
                      value={values?.market_value ? values?.market_value : ''}
                      className="form-control"
                      thousandSeparator={true}
                      onBlur={handleBlur}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.market_value}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <LabelTooltip
                      labelId={label.security.shadingTip.key}
                      label={label.security.shadingTip.label}
                      hover_text={label.security.shadingTip.hover_text}
                    />
                    <NumberFormat
                      decimalScale={0}
                      allowNegative={false}
                      onChange={(e) => {
                        handleInputChange(e.target.value);
                        handleCalculatedFields(e, setFieldValue);
                      }}
                      name="shading"
                      value={values?.shading ? values?.shading : ''}
                      className="form-control"
                      // thousandSeparator={true}
                      onBlur={handleBlur}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.shading}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-box">
              <div className="row">
                <div className="col-6">
                  <div className="form-field">
                    <LabelTooltip
                      labelId={label.security.landingValueTip.key}
                      label={label.security.landingValueTip.label}
                      hover_text={label.security.landingValueTip.hover_text}
                    />
                    <NumberFormat
                      decimalScale={0}
                      allowNegative={false}
                      onChange={(e) => {
                        handleInputChange(e.target.value);
                        handleCalculatedFields(e, setFieldValue);
                      }}
                      name="lending_value"
                      value={values?.lending_value ? values?.lending_value : ''}
                      className="form-control"
                      thousandSeparator={true}
                      onBlur={handleBlur}
                      disabled
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.lending_value}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>How Valued</Label>
                    <AutomatedSelect
                      name="value_confirmed"
                      value={values?.value_confirmed ? values?.value_confirmed : ''}
                      data={metaDataResponse?.data?.securities_value_confirmed}
                      handleBlur={handleBlur}
                      handleChange={(e) => {
                        handleInputChange(e.target.value);
                        handleChange(e);
                      }}
                    />
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.value_confirmed}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-field">
                    <Label>Date Value Confirmed</Label>
                    <DatePicker
                      autoComplete="off"
                      selected={values?.date_value ? new Date(values?.date_value) : null}
                      onChange={(val) => {
                        handleInputChange(val);
                        setFieldValue('date_value', val);
                      }}
                      customInput={
                        <InputMask className="form-control" mask="99-99" maskChar={null} />
                      }
                      name="date_value"
                      dateFormat="MM-yy"
                      value={values?.date_value}
                      showMonthYearPicker
                      placeholderText="MM-YY"
                    />

                    {/* <Input
                      type="month"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="datepicker"
                      name="date_value_confirmed_init"
                      value={values?.date_value_confirmed_init}
                      className="form-control hasDatepicker"
                    /> */}
                    <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                      {errors.date_value_confirmed}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {facilityId === undefined && securityId === undefined ? (
          <div className="attach-table">
            <div className="attach-title">
              {/* <h4>Attach Facility</h4> */}
              <a
                onClick={() => {
                  setShowDraft(true);
                }}
              >
                <button className="button btn small-btn" type="button">
                  <i className="icon-plus"></i> Facility
                </button>
              </a>
            </div>
          </div>
        ) : null}
        {securityId !== undefined ? (
          <div className="attach-table">
            <div className="attach-title">
              <h4>Attach Facility</h4>
              <a
                onClick={() => {
                  navigate(
                    '/job/' +
                      jobId +
                      '/data-entry/' +
                      memberId +
                      '/facilities/return/security/' +
                      securityId
                  );
                }}
              >
                <button className="button btn small-btn" type="button">
                  <i className="icon-plus"></i> Facility
                </button>
              </a>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"> </th>
                  <th scope="col" className="align-left">
                    Purpose
                  </th>
                  <th scope="col">Product/Facility</th>
                  <th scope="col">Lender</th>
                  <th scope="col">Existing Limit</th>
                  <th scope="col">Change + / -</th>
                  <th scope="col">Proposed Limit</th>
                </tr>
              </thead>
              <tbody>
                {facilitiesDataResponse?.data?.related?.length === 0 &&
                  facilitiesDataResponse?.data?.not_related?.length === 0 && (
                    <tr>
                      <td colSpan="11">No Data Found</td>
                    </tr>
                  )}
                {facilitiesDataResponse?.data?.related
                  ? facilitiesDataResponse?.data?.related.map((item) => {
                      let existing_limit = Number(item?.existing_limit);
                      if (
                        (item?.product_facility === 'asset-finance-chp' ||
                          item?.product_facility === 'asset-finance-chattel-mortgage' ||
                          item?.product_facility === 'asset-finance-lease') &&
                        item?.additional_info?.facility_status === 'existing'
                      ) {
                        existing_limit = Number(item?.additional_info?.current_balance);
                      }
                      let change = Number(item?.additional_info?.proposed_limit - existing_limit);

                      return (
                        <tr key={item?.id}>
                          <td scope="row">
                            <Input
                              type="checkbox"
                              className="text-success"
                              defaultChecked="true"
                              value={item?.id}
                              onChange={(e) => handleFacilityLinking(e)}
                            ></Input>
                          </td>
                          <td className="align-left">{item?.purpose}</td>
                          <td className="align-left">
                            {findValueFromKey(
                              metaDataResponseFacility?.data?.facilities_products,
                              item?.product_facility
                            )}
                          </td>
                          <td>
                            {findValueFromKey(
                              metaDataResponseFacility?.data?.facilities_lenders,
                              item?.lender
                            )}
                          </td>
                          <td className="align-right">{formatNumber(existing_limit)}</td>
                          <td className="align-right">{formatNumber(change)}</td>
                          <td className="align-right">
                            {formatNumber(item?.additional_info?.proposed_limit)}
                          </td>
                        </tr>
                      );
                    })
                  : null}
                {facilitiesDataResponse?.data?.not_related
                  ? facilitiesDataResponse?.data?.not_related.map((item) => {
                      let existing_limit = Number(item?.existing_limit);
                      if (
                        (item?.product_facility === 'asset-finance-chp' ||
                          item?.product_facility === 'asset-finance-chattel-mortgage' ||
                          item?.product_facility === 'asset-finance-lease') &&
                        item?.additional_info?.facility_status === 'existing'
                      ) {
                        existing_limit = Number(item?.additional_info?.current_balance);
                      }
                      let change = Number(item?.additional_info?.proposed_limit - existing_limit);

                      return (
                        <tr key={item?.id}>
                          <td scope="row">
                            <Input
                              type="checkbox"
                              className="text-success"
                              value={item?.id}
                              onChange={(e) => handleFacilityLinking(e)}
                            ></Input>
                          </td>
                          <td className="align-left">{item?.purpose}</td>
                          <td className="align-left">
                            {findValueFromKey(
                              metaDataResponseFacility?.data?.facilities_products,
                              item?.product_facility
                            )}
                          </td>
                          <td>
                            {findValueFromKey(
                              metaDataResponseFacility?.data?.facilities_lenders,
                              item?.lender
                            )}
                          </td>
                          <td className="align-right">{formatNumber(existing_limit)}</td>
                          <td className="align-right">{formatNumber(change)}</td>
                          <td className="align-right">
                            {formatNumber(item?.additional_info?.proposed_limit)}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
      <CommentsNotes
        values={values ? values : ''}
        errors={errors}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <FormActions
        cancel={'/job/' + jobId + '/data-entry/' + memberId + '/securities'}
        values={values}
      />
      <ErrorFocus />

      <Modal
        show={showDraft}
        onHide={handleDraft}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Add Security?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Do you you want to save facility as a draft?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button
            type="submit"
            name="is_completed"
            className="btn btn-primary"
            onClick={() => {
              values.is_completed = 0;
              setIsDraftPopup(1);
              handleSubmit();
              setShowDraft(false);
            }}
          >
            Yes
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setIsDraftPopup(0);
              setShowDraft(false);
            }}
          >
            No
          </Button>
        </div>
      </Modal>
    </form>
  );
};

export default Form;
