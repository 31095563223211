import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const StaffManagementInitialState = {
  loading: false,
  getMemberDataResponse: {},
  getRoleDataResponse: {},
  addStaffResponse: {},
  deleteStaffResponse: {},
  getAllJobDataResponse: {},
  updateaccount: {},
  userDetail: {},
  updateUserResponse: {},
  permissionResponse: {},
  updatePermissionResponse: {}
};

export const StaffManagement = createSlice({
  name: 'staffManagement',
  initialState: StaffManagementInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setMemberDataResponse: (state, action) => {
      state.getMemberDataResponse = action.payload.data;
      state.loader = false;
    },
    setroleDataResponse: (state, action) => {
      state.getRoleDataResponse = action.payload.data;
      state.loader = false;
    },
    setStaffSuccessResponse: (state, action) => {
      state.addStaffResponse = action.payload.data;
      state.loader = false;
    },
    setdeleteStaffResponse: (state, action) => {
      state.deleteStaffResponse = action.payload.data;
      state.loader = false;
    },
    setAllJobDataResponse: (state, action) => {
      state.getAllJobDataResponse = action.payload.data;
      state.loader = false;
    },
    SetUpdateaccountResponse: (state, action) => {
      state.updateaccount = action.payload.data;
      state.loader = false;
    },
    SetuserDataResponse: (state, action) => {
      state.userDetail = action.payload.data;
      state.loader = false;
    },
    setupdateUserResponse: (state, action) => {
      state.updateUserResponse = action.payload.data;
      state.loader = false;
    },
    setPermissionResponse: (state, action) => {
      state.permissionResponse = action.payload.data;
      state.loader = false;
    },
    setUpdatePermissionResponse: (state, action) => {
      state.updatePermissionResponse = action.payload.data;
      state.loader = false;
    },
    clearPermission: (state) => {
      state.permissionResponse = {};
    }
  }
});

export const getmemberData = (page, limit, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'staff-management?order_by=id&sort=asc&limit=' + limit + '&page=' + page,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setMemberDataResponse(response.data));
  }
  return response;
};

export const getroleData = (setErrors) => async (dispatch) => {
  const response = await clientService.get('user-roles', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setroleDataResponse(response.data));
  }
  return response;
};

export const addStaff = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('staff-management', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setStaffSuccessResponse(response.data));
  }
  return response;
};

export const userDetails = (userId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'staff-management/' + userId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(SetuserDataResponse(response.data));
  }
};

export const deleteMember = (memberId, setErrors) => async (dispatch) => {
  const response = await clientService.remove('staff-management/' + memberId, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
    return false;
  } else {
    dispatch(setdeleteStaffResponse(response.data));
    return true;
  }
};

export const getAllJob = (page, limit, searchKey, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'all-jobs?order_by=id&sort=asc&limit=' + limit + '&page=' + page + '&search=' + searchKey,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAllJobDataResponse(response.data));
  }
  return response;
};

export const accountant = (accountantId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put('accountant/' + accountantId, data, setErrors, dispatch);

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(SetUpdateaccountResponse(response.data));
  }
  return response;
};

export const updateUserDetails = (userId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put('staff-management/' + userId, data, setErrors, dispatch);

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setupdateUserResponse(response.data));
  }
  return response;
};

export const getPermission = (setErrors) => async (dispatch) => {
  const response = await clientService.get('record-permission', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setPermissionResponse(response.data));
  }
  return response;
};

export const updatePermissions = (data, setErrors) => async (dispatch) => {
  const response = await clientService.put('record-permission', data, setErrors, dispatch);

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatePermissionResponse(response.data));
  }
  return response;
};

export const {
  setLoader,
  setMemberDataResponse,
  setroleDataResponse,
  setStaffSuccessResponse,
  setdeleteStaffResponse,
  setAllJobDataResponse,
  SetUpdateaccountResponse,
  SetuserDataResponse,
  setupdateUserResponse,
  setPermissionResponse,
  setUpdatePermissionResponse,
  clearPermission
} = StaffManagement.actions;

export const staffState = (state) => {
  return state.staffManagement;
};

export default StaffManagement.reducer;
