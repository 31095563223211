import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { formatNumber, roundOff } from '../../../common/Misc';
import { taxState } from '../../../../slices/slices/TaxSlice';
import FixedColumnTableRows from './components/FixedColumnTableRows';
import {
  calculateAdjustedEbitdaTax,
  calculateAdjustedTaxableIncome,
  calculatePayableAdjustedTax,
  calculateTaxOnIncome,
  calculateDebtServicingForTrust
} from '../../../../utils/businessDataEntryCalculations';
import SurplusTableRows from './components/SurplusTableRows';

const BusinessTax = (props) => {
  const taxArray = [];
  const {
    taxRateByMember,
    setFieldValue,
    taxDataResponse,
    handleInputChange,
    handleRemove,
    selectedMember
  } = props;
  const { financials, taxData, years, taxDataTotal } = useSelector(taxState);
  // const test = useSelector(taxState);
  const [finalYears, setFinalYears] = useState(years);
  const [taxRate, setTaxRate] = useState(
    taxRateByMember?.data?.options &&
      JSON.parse(taxRateByMember?.data?.options).find((item) => item.is_default == 1).value
  );
  const options = ['Franking Credits', 'Other'];

  useEffect(() => {
    setFinalYears(years);
  }, [years]);

  useEffect(() => {
    setTaxRate(
      taxRateByMember?.data?.options &&
        JSON.parse(taxRateByMember?.data?.options).find((item) => item.is_default == 1).value
    );
  }, [taxRateByMember]);

  useEffect(() => {
    if (taxDataResponse?.data?.data?.data?.['adjusted-tax-income']?.['tax_rate'])
      setTaxRate(
        taxRateByMember?.data?.options &&
          JSON.parse(taxRateByMember?.data?.options).find(
            (item) =>
              item.value == taxDataResponse?.data?.data?.data?.['adjusted-tax-income']?.['tax_rate']
          ) != undefined
          ? taxDataResponse?.data?.data?.data?.['adjusted-tax-income']?.['tax_rate']
          : JSON.parse(taxRateByMember?.data?.options).find((item) => item.is_default == 1).value
      );
  }, [taxDataResponse]);

  const handleOnchange = (event) => {
    const value = event.target.value;
    setFinalYears([...finalYears]);
    setTaxRate(value);
  };

  return (
    <div className="tex_pay" key={1}>
      {Object.entries(taxData).length > 0 ? (
        <Table className="table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2"></Table.HeaderCell>
              {finalYears.map((value, index) => {
                return <Table.HeaderCell key={index}>{value}</Table.HeaderCell>;
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.entries(taxData).length > 0 ? (
              <>
                <Table.Row>
                  <Table.Cell colSpan="2" className="gray text-start">
                    {selectedMember?.type == 'partnership'
                      ? 'Remaining Share of Profit/Loss'
                      : selectedMember?.type == 'other' ||
                        selectedMember?.type == 'trust' ||
                        selectedMember?.type == 'sole-trader'
                      ? 'Remaining Share of Profit/Loss'
                      : 'Total Adjusted EBITDA'}
                  </Table.Cell>
                  {finalYears.map((value, index) => {
                    let totalAdjustedEbitda;
                    if (
                      selectedMember?.type !== 'sole-trader' &&
                      selectedMember?.type !== 'trust' &&
                      selectedMember?.type !== 'partnership' &&
                      selectedMember?.type !== 'other'
                    ) {
                      totalAdjustedEbitda = calculateAdjustedEbitdaTax(value, financials);
                    } else {
                      totalAdjustedEbitda = calculateDebtServicingForTrust(
                        value,
                        taxData,
                        financials
                      );
                    }

                    return (
                      <>
                        <Table.Cell key={index} className="gray align-right">
                          <span>
                            {/* {totalAdjustedEbitda > 0
                              ? formatNumber(Math.floor(totalAdjustedEbitda))
                              : formatNumber(Math.ceil(totalAdjustedEbitda))} */}
                            {formatNumber(roundOff(totalAdjustedEbitda))}
                          </span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                <FixedColumnTableRows
                  category="add"
                  data={taxData['adjusted-tax-income']}
                  handleRemove={handleRemove}
                  taxtotal=""
                  years={finalYears}
                  handleInputChange={handleInputChange}
                  fixedName="Add"
                  itemIndex={0}
                  // type={options}
                />
                <FixedColumnTableRows
                  category="less"
                  data={taxData['adjusted-tax-income']}
                  taxtotal={taxDataTotal}
                  years={finalYears}
                  handleRemove={handleRemove}
                  handleInputChange={handleInputChange}
                  fixedName="Less"
                  negative={true}
                  itemIndex={
                    taxData['adjusted-tax-income']?.items?.length > 0 &&
                    taxData['adjusted-tax-income']?.items.filter((item) => item.category === 'add')
                      .length
                      ? taxData['adjusted-tax-income']?.items.filter(
                          (item) => item.category === 'add' && !Object.keys(item).includes('to_id')
                        ).length
                      : 0
                  }
                />
                {taxData['adjusted-tax-income']?.items.filter((item) =>
                  Object.keys(item).includes('to_id')
                ).length
                  ? (selectedMember.type == 'partnership' ||
                      selectedMember?.type == 'trust' ||
                      selectedMember?.type == 'sole-trader' ||
                      selectedMember?.type == 'other') && (
                      <SurplusTableRows
                        category="surplus"
                        data={taxData['adjusted-tax-income']}
                        handleRemove={handleRemove}
                        taxtotal=""
                        years={finalYears}
                        handleInputChange={handleInputChange}
                        fixedName="Allocation of Remaining Adjusted Taxable Income/Loss"
                        itemIndex={0}
                        // type={options}
                      />
                    )
                  : ''}
                <Table.Row>
                  <Table.Cell colSpan="2" className="gray text-start">
                    {selectedMember?.type == 'partnership'
                      ? 'Adjusted Taxable Share of Profit/Loss'
                      : selectedMember.type == 'other' ||
                        selectedMember?.type == 'trust' ||
                        selectedMember?.type == 'sole-trader'
                      ? 'Adjusted Taxable Undistributed Profit/Loss'
                      : 'Adjusted Taxable Income'}
                  </Table.Cell>
                  {finalYears.map((value, index) => {
                    let adjustedTaxableIncome = calculateAdjustedTaxableIncome(
                      value,
                      taxData,
                      {
                        ...financials,
                        type: selectedMember?.type
                      },
                      false
                    );
                    return (
                      <>
                        <Table.Cell key={index} className="gray align-right">
                          <span>
                            {/* {adjustedTaxableIncome > 0
                              ? formatNumber(Math.floor(adjustedTaxableIncome))
                              : formatNumber(Math.ceil(adjustedTaxableIncome))} */}
                            {formatNumber(roundOff(adjustedTaxableIncome))}
                          </span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="gray text-start">
                    {selectedMember?.type == 'partnership'
                      ? 'Tax Payable/Refundable on Remaining Share of Profit/Loss'
                      : selectedMember?.type == 'other' ||
                        selectedMember?.type == 'trust' ||
                        selectedMember?.type == 'sole-trader'
                      ? 'Tax Payable/Refundable on Remaining Undistributed Profit/Loss'
                      : 'Tax on Adjusted Taxable Income'}
                  </Table.Cell>
                  <Table.Cell className="gray text-start">
                    <span style={{ float: 'left' }}>Select tax rate:</span>
                    <span style={{ float: 'right' }}>
                      <select
                        name="tax_rate"
                        value={taxRate}
                        onChange={(e) => {
                          handleOnchange(e, taxArray);
                          setFieldValue('tax_rate', e.target.value);
                          handleInputChange(e.target.value);
                        }}
                      >
                        {taxRateByMember?.data?.options &&
                          JSON.parse(taxRateByMember?.data?.options).map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.value}%
                            </option>
                          ))}
                        {/* <option>15%</option> */}
                      </select>
                    </span>
                  </Table.Cell>
                  {finalYears.map((value, index) => {
                    let calculateTax = calculateTaxOnIncome(
                      value,
                      taxData,
                      { ...financials, type: selectedMember.type },
                      taxRate,
                      true
                    );

                    return (
                      <>
                        <Table.Cell key={index} className="gray align-right">
                          <span>{formatNumber(calculateTax)}</span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                {selectedMember.type !== 'other' &&
                  selectedMember?.type !== 'trust' &&
                  selectedMember?.type !== 'partnership' &&
                  selectedMember?.type !== 'sole-trader' && (
                    <FixedColumnTableRows
                      category="less"
                      handleRemove={handleRemove}
                      data={taxData['deductions']}
                      years={finalYears}
                      handleInputChange={handleInputChange}
                      fixedName="Less"
                      type={options}
                      negative={true}
                      itemIndex={0}
                    />
                  )}
              </>
            ) : null}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2}>
                {selectedMember?.type == 'company' ||
                selectedMember?.type == 'other' ||
                selectedMember?.type == 'trust' ||
                selectedMember?.type == 'sole-trader'
                  ? 'Tax Payable/Refundable on Adjusted Taxable Income'
                  : 'Tax Payable on Adjusted Taxable Income'}
              </Table.Cell>
              {finalYears.map((value, index) => {
                let calculateTax;
                calculateTax = calculatePayableAdjustedTax(
                  value,
                  taxData,
                  { ...financials, type: selectedMember?.type },
                  taxRate,
                  true
                );
                return (
                  <>
                    <Table.Cell key={index} className="align-right">
                      <span>
                        <strong>
                          {/* {calculateTax > 0
                            ? formatNumber(Math.floor(calculateTax))
                            : formatNumber(Math.ceil(calculateTax))} */}
                          {formatNumber(roundOff(calculateTax))}
                        </strong>
                      </span>
                    </Table.Cell>
                  </>
                );
              })}
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : null}
    </div>
  );
};

export default BusinessTax;
