import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import DataEntryHeader from '../../../../../components/DataEntryHeader';
import { addResidentialRentalSchema } from '../../../../common/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  rentalState,
  setAddResidentialRentalResponse,
  addResidentialRentalData
} from '../../../../../slices/slices/RentalSlice';
import { getMemberData, facilityState } from '../../../../../slices/slices/FacilitySlice';
import Form from './components/Form';
import DialogModal from '../../../../../components/modal/DialogModal';
import constants from '../../../../../constant';
import { displayNameFromType } from '../../../../common/Misc';
import { getMemberList, jobSetupState } from '../../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../../common/components/DialogLeavingPage';

const AddResidentialRental = () => {
  const dispatch = useDispatch();
  const [MemberValue, setMemberValue] = useState({});
  const { jobId, memberId } = useParams();
  const addResidentialRentalResponse = useSelector(rentalState);
  const { memberListResponse } = useSelector(jobSetupState);
  const memberDataResponse = useSelector(facilityState);
  const navigate = useNavigate();
  let existingValues = [];
  let overwrittenFields = [];
  const formValues = useRef();
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFormSubmit = (values, { setErrors }) => {
    let members = [];
    for (let item of JSON.parse(JSON.stringify(values?.owners))) {
      members.push({
        holding: item?.holding,
        member_id: item?.member_id?.value
      });
    }

    setCanShowDialogLeavingPage(false);
    {
      if (values?.is_completed === 0) {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += parseInt(values.owners[index].holding);
        });
        total <= 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      } else {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += parseInt(values.owners[index].holding);
        });
        total === 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      }
    }
    values.tenant[0].current_rental_income_pa = values.tenant[0].current_rental_income_pa
      ? parseInt(`${values.tenant[0].current_rental_income_pa}`.replace(/,/g, ''))
      : null;
    values.owners = members;
    dispatch(addResidentialRentalData(jobId, memberId, values, setErrors));
  };

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    if (addResidentialRentalResponse?.addResidentialRentalResponse?.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setAddResidentialRentalResponse({}));
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/rentals?tab=2');
    }
  }, [addResidentialRentalResponse?.addResidentialRentalResponse?.message]);

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getMemberList(jobId));
  }, []);

  useEffect(() => {
    displayNameFromType(memberDataResponse?.memberDataResponse) &&
      setMemberValue({
        label: displayNameFromType(memberDataResponse?.memberDataResponse),
        value: memberId
      });
  }, [memberDataResponse]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/rentals'}>Rental</Link>
              </li>
            </ul>
            <h1>Rentals - {displayNameFromType(memberDataResponse?.memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <DialogModal
              title="Residential-Rental"
              p1={constants.rentalResidential1}
              p2={constants.rentalResidential2}
            />
          </div>
        </div>
        <Formik
          initialValues={{
            type: 'residential',
            address: '',
            owners: [
              {
                member_id: {
                  label: displayNameFromType(memberDataResponse?.memberDataResponse),
                  value: memberId
                },
                holding: 100
              }
            ],
            report_comments: '',
            internal_notes: '',
            tenant: [
              {
                sensitised_rental_pa: '50',
                current_rental_income_pa: '',
                shading: ''
              }
            ]
          }}
          // enableReinitialize
          validationSchema={addResidentialRentalSchema}
          onSubmit={handleFormSubmit}
          innerRef={formValues}
        >
          {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                handleInputChange={handleInputChange}
                memberDataResponse={memberDataResponse}
                memberListResponse={memberListResponse}
                memberId={memberId}
                jobId={jobId}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
                MemberValue={MemberValue}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddResidentialRental;
