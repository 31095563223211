import numbro from 'numbro';

export const disableFutureDate = () => {
  const today = new Date();
  const d = String(today.getDate() + 1).padStart(2, '0');
  const m = String(today.getMonth() + 1).padStart(2, '0');
  const y = today.getFullYear();
  // return dd + '-' + mm + '-' + yyyy;
  return d + '-' + m + '-' + y;
};

export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate() + 1).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  return dd + '-' + mm + '-' + yyyy;
};

export const checkExpDate = () => {
  var date = new Date();
  return date.setDate(date.getDate() + 1);
};

export const formatNumber = (value, delimiter = 0) => {
  try {
    if (isNaN(unformatNumber(value))) {
      return '';
    }
    let format = numbro(value).format({
      thousandSeparated: true,
      negative: 'parenthesis',
      mantissa: delimiter
    });
    return format;
  } catch {
    return '';
  }
};

export const formatDecimal = (value) => {
  try {
    if (isNaN(unformatNumber(value))) {
      return '';
    }
    let format = numbro(value).format({
      negative: 'parenthesis',
      mantissa: 2,
      trimMantissa: true
    });
    return format;
  } catch {
    return '';
  }
};

export const positiveNumber = (value) => {
  return !value ? value : value > 0 ? value : value * -1;
};

export const roundOff = (number) => {
  // Get the absolute value of the number
  const absNumber = Math.abs(number);

  // Check if the decimal part is less than 0.5
  if (absNumber % 1 < 0.5) {
    return number < 0 ? Math.ceil(number) : Math.floor(number);
  } else {
    return number < 0 ? Math.floor(number) : Math.ceil(number);
  }
};

export const unformatNumber = (value) => {
  return numbro.unformat(value);
};

export const sumOfNumbers = (array) => {
  return array.reduce((accumulator, curr) => accumulator + curr);
};

export const formatPercentage = (value) => {
  try {
    if (isNaN(value)) {
      return 'N/A';
    }
    let format = numbro(value).format({ output: 'percent', mantissa: 0, negative: 'parenthesis' });

    if (format === '-Infinity%' || format === 'Infinity%') {
      return 'N/A'; // Return N/A
    }

    if (value * 100 > 1000) {
      return '1000 + %';
    } else if (-1000 > value * 100) {
      return '1000 - %';
    } else {
      return format;
    }
  } catch {
    return '';
  }
};

export function displayOwnerNameFromType(data) {
  let ownername = data?.map((item) => {
    return displayNameFromType(item);
  });
  return ownername && ownername.length > 0 ? ownername.join(' | ') : '';
}

/**
 * Based on member object returns their only name or first name + last name.
 * @param {Member Object} data
 * @returns
 */
export function displayNameFromType(data) {
  switch (data?.type) {
    case 'individual':
      return (
        // data?.name +
        // ' ' +
        data?.additional_info?.first_name +
        (data?.additional_info?.other_names != undefined
          ? ' ' + data?.additional_info?.other_names
          : '') +
        ' ' +
        data?.additional_info?.last_name
      );
    case 'trust':
      if (data?.name !== null || data?.name != undefined) {
        return data?.additional_info?.trustee_name
          ? data?.additional_info?.trustee_name + ' atf ' + data?.name
          : data?.name;
      } else {
        return data?.additional_info?.trustee_name;
      }
    case 'company':
    case 'partnership':
    case 'sole-trader':
    case 'other':
      return data?.name;
    default:
      return '';
  }
}
