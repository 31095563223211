import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const rentalInitialState = {
  loading: false,
  jobSettingsDataResponse: {},
  rentalListDataResponse: {},
  commercialRentalListDataResponse: {},
  addResidentialRentalResponse: {},
  addCommercialRentalResponse: {},
  deleteRentalDataResponse: {},
  deleteRentalResidentialDataResponse: {},
  rentalDataResponse: {},
  editRentalResponse: {}
};

export const RentalSlice = createSlice({
  name: 'rental',
  initialState: rentalInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setJobSettingsDataResponse: (state, action) => {
      state.jobSettingsDataResponse = action.payload.data;
      state.loader = false;
    },
    setRentalListDataResponse: (state, action) => {
      state.rentalListDataResponse = action.payload;
      state.loader = false;
    },
    setCommercialRentalListDataResponse: (state, action) => {
      state.commercialRentalListDataResponse = action.payload;
      state.loader = false;
    },
    setAddResidentialRentalResponse: (state, action) => {
      state.addResidentialRentalResponse = action.payload;
      state.loader = false;
    },
    setAddCommercialRentalResponse: (state, action) => {
      state.addCommercialRentalResponse = action.payload;
      state.loader = false;
    },
    setDeleteRentalDataResponse: (state, action) => {
      state.deleteRentalDataResponse = action.payload;
      state.loader = false;
    },
    setDeleteRentalResidentialDataResponse: (state, action) => {
      state.deleteRentalResidentialDataResponse = action.payload;
      state.loader = false;
    },
    setRentalDataResponse: (state, action) => {
      state.rentalDataResponse = action.payload;
      state.loader = false;
    },
    setEditRentalResponse: (state, action) => {
      state.editRentalResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getJobSettingsData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get('jobs/' + id + '/settings', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setJobSettingsDataResponse(response.data));
  }
};

export const getRentalListData =
  (id, memberId, type = 'residential', setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/members/' + memberId + '/rentals?limit=500&type=' + type,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setRentalListDataResponse(response.data));
    }
  };

export const getCommercialRentalListData =
  (id, memberId, type = 'commercial', setErrors) =>
  async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + id + '/members/' + memberId + '/rentals?limit=500&type=' + type,
      setErrors,
      dispatch,
      false
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setCommercialRentalListDataResponse(response.data));
    }
  };

export const addResidentialRentalData = (id, memberId, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' + id + '/members/' + memberId + '/rentals',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAddResidentialRentalResponse(response.data));
  }
};

export const addCommercialRentalData = (id, memberId, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' + id + '/members/' + memberId + '/rentals',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAddCommercialRentalResponse(response.data));
  }
};

export const deleteRental =
  (jobId, memberId, rentalId, tenantId, setErrors) => async (dispatch) => {
    const response = await clientService.remove(
      'jobs/' +
        jobId +
        '/members/' +
        memberId +
        '/rentals/delete_tenant/' +
        rentalId +
        '/' +
        tenantId,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setDeleteRentalDataResponse(response.data));
    }
  };

export const deleteResidentialRental =
  (jobId, memberId, rentalId, setErrors) => async (dispatch) => {
    const response = await clientService.remove(
      'jobs/' + jobId + '/members/' + memberId + '/rentals/' + rentalId,
      setErrors,
      dispatch
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setDeleteRentalResidentialDataResponse(response.data));
    }
  };

export const getRentalData = (id, memberId, rentalId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId + '/rentals/' + rentalId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setRentalDataResponse(response.data));
  }
};

export const editRental = (id, memberId, rentalId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/members/' + memberId + '/rentals/' + rentalId,
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setEditRentalResponse(response.data));
  }
};

export const {
  setLoader,
  setJobSettingsDataResponse,
  setRentalListDataResponse,
  setCommercialRentalListDataResponse,
  setAddResidentialRentalResponse,
  setAddCommercialRentalResponse,
  setDeleteRentalDataResponse,
  setDeleteRentalResidentialDataResponse,
  setRentalDataResponse,
  setEditRentalResponse
} = RentalSlice.actions;

export const rentalState = (state) => {
  return state.rental;
};

export default RentalSlice.reducer;
