import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const securityInitialState = {
  loading: false,
  memberDataResponse: {},
  securityListDataResponse: {},
  addSecurityDataResponse: {},
  securityDataResponse: {},
  updateSecurityDataResponse: {},
  deleteSecurityDataResponse: {},
  metaDataResponse: {},
  facilitiesDataResponse: {},
  manageFacilityDataResponse: {}
};

export const SecuritySlice = createSlice({
  name: 'security',
  initialState: securityInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setMemberDataResponse: (state, action) => {
      state.memberDataResponse = action.payload.data;
      state.loader = false;
    },
    setSecurityListDataResponse: (state, action) => {
      state.securityListDataResponse = action.payload;
      state.loader = false;
    },
    setAddSecurityDataResponse: (state, action) => {
      state.addSecurityDataResponse = action.payload;
      state.loader = false;
    },
    setSecurityDataResponse: (state, action) => {
      state.securityDataResponse = action.payload.data;
      state.loader = false;
    },
    setUpdateSecurityDataResponse: (state, action) => {
      state.updateSecurityDataResponse = action.payload;
      state.loader = false;
    },
    setDeleteSecurityDataResponse: (state, action) => {
      state.deleteSecurityDataResponse = action.payload;
      state.loader = false;
    },
    setMetaDataResponse: (state, action) => {
      state.metaDataResponse = action.payload;
      state.loader = false;
    },
    setFacilitiesDataResponse: (state, action) => {
      state.facilitiesDataResponse = action.payload;
      state.loader = false;
    },
    setManageFacilityDataResponse: (state, action) => {
      state.manageFacilityDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const getMemberData = (id, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setMemberDataResponse(response.data));
  }
};

export const getSecurityListData = (id, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId + '/securities?limit=500',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSecurityListDataResponse(response.data));
  }
};

export const addSecurity = (jobId, memberId, data, setErrors) => async (dispatch) => {
  data.date_value_confirmed =
    data.date_value != '' && data.date_value != null
      ? moment(data.date_value, 'YYYY-MM', true).format('MM-YY')
      : null;
  data.date_value_confirmed_init = data.date_value
    ? moment(data.date_value, 'YYYY-MM', true).format('YYYY-MM')
    : null;
  const response = await clientService.post(
    'jobs/' + jobId + '/members/' + memberId + '/securities',
    data,
    setErrors,
    dispatch
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setAddSecurityDataResponse(response.data));
  }
};

export const getSecurityData = (jobId, memberId, securityId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + jobId + '/members/' + memberId + '/securities/' + securityId,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setSecurityDataResponse(response.data));
  }
};

export const updateSecurity =
  (jobId, memberId, securityId, data, setErrors) => async (dispatch) => {
    data.date_value_confirmed =
      data.date_value != '' && data.date_value != null
        ? moment(data.date_value, 'YYYY-MM', true).format('MM-YY')
        : null;
    data.date_value_confirmed_init = data.date_value
      ? moment(data.date_value, 'YYYY-MM', true).format('YYYY-MM')
      : null;

    const response = await clientService.put(
      'jobs/' + jobId + '/members/' + memberId + '/securities/' + securityId,
      data,
      setErrors,
      dispatch
    );

    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setUpdateSecurityDataResponse(response.data));
    }
  };

export const deleteSecurity = (jobId, memberId, securityId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/members/' + memberId + '/securities/' + securityId,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteSecurityDataResponse(response.data));
  }
};

export const getMetaData = (setErrors) => async (dispatch) => {
  const metaData = new URLSearchParams({
    'keys[0]': 'securities-asset-class',
    'keys[1]': 'securities-asset-types',
    'keys[2]': 'securities-asset-usages',
    'keys[3]': 'securities-asset-description',
    'keys[4]': 'securities-value-confirmed'
  }).toString();
  const response = await clientService.get('config?' + metaData, setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setMetaDataResponse(response.data));
  }
};

export const getFacilitiesData = (jobId, memberId, securityId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + jobId + '/members/' + memberId + '/securities/' + securityId + '/facilities',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setFacilitiesDataResponse(response.data));
  }
};

export const manageFacilityRelation =
  (jobId, memberId, securityId, facilityId) => async (dispatch) => {
    const response = await clientService.post(
      'jobs/' +
        jobId +
        '/members/' +
        memberId +
        '/securities/' +
        securityId +
        '/facilities/' +
        facilityId,
      {},
      {},
      dispatch
    );

    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setManageFacilityDataResponse(response.data));
    }
  };

export const {
  setLoader,
  setMemberDataResponse,
  setSecurityListDataResponse,
  setAddSecurityDataResponse,
  setSecurityDataResponse,
  setUpdateSecurityDataResponse,
  setDeleteSecurityDataResponse,
  setMetaDataResponse,
  setFacilitiesDataResponse,
  setManageFacilityDataResponse
} = SecuritySlice.actions;

export const securityState = (state) => {
  return state.security;
};

export default SecuritySlice.reducer;
