import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { AccountManagementSchema } from '../common/Validation';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { updateConfig } from '../../slices/slices/CommonSlice';

import { useNavigate } from 'react-router';
import Label from '../../components/Label';
export default function AccountantManagement(props) {
  const { cardData } = props;
  const dispatch = useDispatch();
  const [cardRate, setCardRate] = useState();

  const handleSubmit = (values, { setErrors }) => {
    let temp = {
      title: values.data[0].title,
      comment: '',
      values: []
    };

    JSON.parse(values.data[0].value)?.map((item) => {
      temp.values.push({
        value: item.key,
        slug: values.creditcardrate
      });
    });
    dispatch(updateConfig(values.data[0].id, temp, setErrors)).then(() => {
      // dispatch(adminGetConfig([], 1, false));
      // dispatch(adminGetConfig([], 2, false));
    });
  };

  useEffect(() => {
    cardData[0] &&
      JSON.parse(cardData[0].value).forEach((element) => {
        setCardRate(element.report_value);
      });
  }, [cardData]);

  const navigate = useNavigate();
  const onClickCancle = () => {
    navigate('/dashboard');
  };

  return (
    <div
      className="tab-pane fade show active"
      id="contact"
      role="tabpanel"
      aria-labelledby="contact-tab"
    >
      <Formik
        initialValues={{
          data: cardData,
          creditcardrate: cardRate
        }}
        enableReinitialize
        validationSchema={AccountManagementSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <form
            encType="multipart/form-data"
            onSubmit={handleSubmit}
            className="row needs-validation"
            noValidate
          >
            <div className="innertab">
              <div className="container">
                <div className="textarea">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group flex-column has-validation">
                        <Label>Credit Card Rate</Label>
                        <Input
                          type="text"
                          name="creditcardrate"
                          placeholder=""
                          className="form-control"
                          id="creditcard"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.creditcardrate}
                          required
                        />
                        <span className="error" style={{ color: 'red', fontSize: '15px' }}>
                          {errors.creditcardrate}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_area text-end">
              <div className="container">
                <Button className="btn btn-outline-secondary" type="reset" onClick={onClickCancle}>
                  Cancel
                </Button>
                <Button className="btn btn-success" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
