import { Table } from 'semantic-ui-react';
import ContentEditable from 'react-contenteditable';
import { formatNumber, roundOff, unformatNumber } from '../../../../common/Misc';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaxData, taxState } from '../../../../../slices/slices/TaxSlice';

const EditTableRows = ({
  data_key,
  items,
  years,
  colspan,
  type,
  negative,
  handleInputChange,
  handleRemove,
  category
}) => {
  EditTableRows.propTypes = {
    data_key: PropTypes.string,
    items: PropTypes.array,
    years: PropTypes.array,
    colspan: PropTypes.string,
    type: PropTypes.any,
    negative: PropTypes.bool,
    handleInputChange: PropTypes.any,
    handleRemove: PropTypes.any,
    category: PropTypes.any
  };

  const { taxData } = useSelector(taxState);
  window.taxData = taxData;
  const dispatch = useDispatch();
  const [localItems, setLocalItems] = useState(items);

  const handleTitleUpdate = (index, key, element) => {
    let dataIndex = +index;
    if (data_key === 'adjusted-tax-income' && category === 'less') {
      dataIndex += window.taxData['adjusted-tax-income'].items.filter(
        (el) => el.category === 'add' && !Object.keys(el).includes('to_id')
      ).length;
    }
    let tempItems = JSON.parse(JSON.stringify(items));

    handleUpdate(
      dataIndex,
      element.target?.innerHTML
        ? element.target.innerHTML.replace(/<[^>]+>/g, '')
        : element.target !== undefined
        ? (tempItems[index][1].title = element.target.value
            ? element.target.value.replace(/<[^>]+>/g, '')
            : '')
        : (tempItems[index][1].title = element.value.replace(/<[^>]+>/g, ''))
    );
  };

  // const handleValuesUpdate = (index, key, year, element) => {
  //   let tempItems = JSON.parse(JSON.stringify(items));
  //   tempItems[index][1].data = Object.assign({}, tempItems[index][1].data);
  //   let data_key = '';
  //   data_key += year;
  //   tempItems[index][1].data[data_key] = unformatNumber(element.target.value);

  //   handleUpdate(key, tempItems[index][1], year);
  // };

  const convertHTMLEntity = (text) => {
    const span = document.createElement('span');
    if (typeof text == 'string') {
      return text.replace(/&[#A-Za-z0-9]+;/gi, (entity) => {
        span.innerHTML = entity;
        return span.innerText;
      });
    } else {
      return text;
    }
  };

  const handleUpdate = (index, data, year = '', title = null) => {
    let updates = {};
    updates['key'] = data_key;
    updates['index'] = index;
    updates['data'] = convertHTMLEntity(data);
    updates['title'] = title ? convertHTMLEntity(title) : null;
    if (year !== '') {
      updates['year'] = year;
    }
    if (data !== null) {
      dispatch(updateTaxData(updates));
    }
  };

  const handleNegativeValues = (event, index, item, year) => {
    const value = unformatNumber(event.target.innerHTML);
    // tempItems[index][1].data = Object.assign({}, tempItems[index][1].data);
    // let data_key = '';
    // data_key += year;
    let finalValue = value;
    if (negative) {
      finalValue = value * -1;
    }
    let dataIndex = +index;
    if (data_key === 'adjusted-tax-income' && category === 'less') {
      dataIndex += window.taxData['adjusted-tax-income'].items.filter(
        (el) => el.category === 'add' && !Object.keys(el).includes('to_id')
      ).length;
    }
    handleUpdate(dataIndex, finalValue, year);
  };

  const handleFocusEvent = (event) => {
    if (negative) {
      const value = unformatNumber(event.target.innerHTML);
      event.target.innerHTML = formatNumber(value * -1);
    }
  };

  // Validate entered number
  const validateNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const string = String.fromCharCode(keyCode);
    const regex = /[0-9,-]|\./;

    if (!regex.test(string)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  return (
    <React.Fragment>
      {localItems !== undefined
        ? localItems.map((item, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell className="align-left" colSpan={colspan}>
                  {item[1].editable !== undefined && !item[1].editable ? (
                    <span>{item[1].title}</span>
                  ) : (
                    <>
                      {item[1].deletable && (
                        <i className="add">
                          <i
                            className="fa fa-trash"
                            style={{ fontSize: '20px' }}
                            onClick={() => handleRemove(item[0], data_key)}
                          ></i>
                        </i>
                      )}
                      {type && Array.isArray(type) ? (
                        <>
                          <ContentEditable
                            html={item[1].title}
                            disabled={item[1]?.editable}
                            data-column="item"
                            className="content-editable"
                            onBlur={(event) => {
                              handleTitleUpdate(index, index, event, 'text');
                              handleInputChange(event.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <ContentEditable
                            html={item[1].title}
                            disabled={item[1]?.editable}
                            data-column="item"
                            className="content-editable"
                            onBlur={(event) => {
                              handleTitleUpdate(index, index, event, 'text');
                              handleInputChange(event.target.value);
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </Table.Cell>
                {item[1].data !== undefined
                  ? years.map((year) => {
                      return item[1].data[year] !== undefined ? (
                        <React.Fragment key={year}>
                          <Table.Cell key={year} className="align-right" data-index={item[0]}>
                            <ContentEditable
                              disabled={item[1]?.editable}
                              html={
                                // item[1].data[year] > 0
                                //   ? formatNumber(Math.floor(item[1].data[year]))
                                //   : formatNumber(Math.ceil(item[1].data[year]))
                                formatNumber(roundOff(item[1].data[year]))
                              }
                              data-column="item"
                              data-row={year}
                              data-item-index={index}
                              className="content-editable"
                              onKeyPress={(e) => validateNumber(e)}
                              // onChange={(event) => {
                              //   handleValuesUpdate(index, item[0], year, event);
                              //   handleInputChange(event.target.value);
                              // }}
                              onBlur={(event) => {
                                handleNegativeValues(
                                  event,
                                  index,
                                  event.target.dataset.itemIndex,
                                  year
                                );
                              }}
                              onFocus={handleFocusEvent}
                            />
                          </Table.Cell>
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={year}>
                          <Table.Cell key={year} className="align-right" data-index={item[0]}>
                            <ContentEditable
                              html=""
                              disabled={item[1]?.editable}
                              data-column="item"
                              data-row={year}
                              className="content-editable"
                              data-item-index={index}
                              onKeyPress={(e) => validateNumber(e)}
                              // onChange={(event) => {
                              //   handleValuesUpdate(index, item[0], year, event);
                              //   handleInputChange(event.target.value);
                              // }}
                              onBlur={(event) => {
                                handleNegativeValues(
                                  event,
                                  index,
                                  event.target.dataset.itemIndex,
                                  year
                                );
                              }}
                              onFocus={handleFocusEvent}
                            />
                          </Table.Cell>
                        </React.Fragment>
                      );
                    })
                  : null}
              </Table.Row>
            );
          })
        : null}
    </React.Fragment>
  );
};

export default EditTableRows;
