import React from 'react';
import { Plock } from 'react-plock';
import Taxitem from './Taxitem';
export default function Admin(props) {
  const { tbodyData, handleOneditClick } = props;
  const breakpoints = [
    { size: 640, columns: 1 },
    { size: 768, columns: 2 },
    { size: 1024, columns: 3 }
    // { size: 1280, columns: 6 }
  ];
  return (
    <>
      <div
        key={1}
        className="tab-pane fade show active admin-setting"
        id="commercial-justified"
        role="tabpanel"
        aria-labelledby="commercial-tab"
      >
        <Plock nColumns={breakpoints}>
          {tbodyData.map((item, index) => {
            return (
              <div className="item" key={index}>
                <div className="corporate-structure-blocks company">
                  <h4>
                    {item.title}
                    <button type="button" onClick={() => handleOneditClick(item)}>
                      <i className="fa fa-edit"></i>
                    </button>
                    {/* <button type="button" style={{ marginLeft: '10px' }}>
                      <i className="fa fa-trash-alt"></i>
                    </button> */}
                  </h4>
                  <ul className="admin-setting">
                    <Taxitem itemData={item?.detailData} />
                  </ul>
                </div>
              </div>
            );
          })}
        </Plock>
      </div>
    </>
  );
}
