export const uniqkey = () => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatAddress = (places) => {
  let street_number = '';
  let route = '';
  let locality = '';
  let administrative_area_level_1 = '';
  places['address_components'].forEach((data) => {
    if (data.types.includes('street_number')) {
      street_number = data.long_name + ' ';
    } else if (data.types.includes('route')) {
      route = data.long_name + ', ';
    } else if (data.types.includes('locality')) {
      locality = data.long_name + ' ';
    } else if (data.types.includes('colloquial_area')) {
      locality = data.long_name + ' ';
    } else if (data.types.includes('administrative_area_level_1')) {
      administrative_area_level_1 = data.short_name;
    }
  });
  return street_number + route + locality + administrative_area_level_1;
};
