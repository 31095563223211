import React from 'react';
import PropTypes from 'prop-types';
import FixedColumnTableRows from './FixedColumnTableRows';

const Surplus = ({ data, years, handleRemove, handleInputChange, memberOptions }) => {
  Surplus.propTypes = {
    data: PropTypes.object,
    years: PropTypes.array,
    percentageCalculation: PropTypes.func,
    handleRemove: PropTypes.func,
    handleInputChange: PropTypes.any,
    memberOptions: PropTypes.any
  };

  return (
    <>
      <FixedColumnTableRows
        category="less"
        data={data}
        years={years}
        type={memberOptions}
        isSelectable={true}
        fixedName={data?.title}
        negative={true}
        handleRemove={handleRemove}
        itemIndex={0}
        handleInputChange={handleInputChange}
      />
    </>
  );
};

export default Surplus;
