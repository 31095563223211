import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableHeadItem from '../../../../../components/job/groupAnalysis/proposedFacilities/FStable/table/TableHead';
// import TableRow from '../../../../../components/job/groupAnalysis/proposedFacilities/FStable/table/TableRow';
import TableRow from '../../proposedFacilities/FStable/table/TableRow';

const DebtServicingTable = (props) => {
  DebtServicingTable.propTypes = {
    theadData: PropTypes.any,
    tbodyData: PropTypes.any,
    customClass: PropTypes.any,
    detailComponent: PropTypes.any,
    Type: PropTypes.any
  };

  let {
    theadData,
    tbodyData,
    customClass,
    // detailComponent,
    // rowSelection,
    headSlice,
    theadColor,
    tfootData
    // Type
  } = props;

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(tbodyData);
  }, [tbodyData]);

  return (
    <table className={customClass}>
      <thead>
        <tr>
          {theadData.slice(0, headSlice ? headSlice : theadData.length).map((h) => {
            return (
              <TableHeadItem
                key={h.key}
                headClassName={h.headClassName}
                keyvalue={h.key}
                sorting={h.sort}
                item={h.title}
                colSpan={h.colSpan}
                theadColor={theadColor}
                style={h.style}
              />
            );
          })}
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.length > 0 ? (
          tableData.map((member) => {
            return (
              <>
                <tr>
                  <td colSpan="16" className="gray text-start">
                    <strong>{member.name}</strong>
                  </td>
                </tr>
                {member?.facilities &&
                  member?.facilities?.length > 0 &&
                  member?.facilities?.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        theadData={theadData}
                        // tableData={member?.data}
                        // setTableData={setTableData}
                        // selection={rowSelection}
                        item={item}
                        index={index}
                        // Type={Type ? Type : ''}
                        // detailComponent={detailComponent}
                      />
                    );
                  })}
              </>
            );
          })
        ) : (
          <tr>
            <td colSpan={theadData.length + 1} style={{ textAlign: 'center' }}>
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
      {tfootData && tfootData}
    </table>
  );
};

export default DebtServicingTable;
