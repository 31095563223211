import React from 'react';
import ReactTooltip from 'react-tooltip';

const TableHeadTooltip = ({ labelId, label, hover_text }) => {
  return (
    <>
      <ReactTooltip id={labelId} place="top" effect="solid">
        <p
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: '14px'
          }}
        >
          {hover_text}
        </p>
      </ReactTooltip>

      {label === 'Rental' && (
        <h5
          data-tip
          data-for={labelId}
          style={{
            cursor: 'pointer',
            color: '#212529',
            font: '16px Swis721 BT'
          }}
        >
          {label}
        </h5>
      )}

      {label !== 'Rental' && labelId !== 'rentalListTip' && (
        <h5
          data-tip
          data-for={labelId}
          style={{
            cursor: 'pointer',
            color: '#000000',
            font: '20px Swis721 BT Bold'
          }}
        >
          {label}
        </h5>
      )}

      {labelId === 'rentalListTip' && (
        <h5
          data-tip
          data-for={labelId}
          style={{
            cursor: 'pointer',
            color: '#ffffff',
            font: '18px Swis721 BT Bold'
          }}
        >
          {label}
        </h5>
      )}
    </>
  );
};

export default TableHeadTooltip;
