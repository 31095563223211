import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { authState } from '../../slices/slices/AuthSlice';

// let AuthContext = React.createContext(null);

// const AuthProvider = ({ component }) => {
//   const authentication = useSelector(authState);
//   const value = { isUserLoggedIn: false };
//   return <AuthContext.Provider value={value}>{component}</AuthContext.Provider>;
// };
const RequireAuth = ({ component, otpVerified }) => {
  let location = useLocation();
  const { isUserLoggedIn, isOTPVerified } = useSelector(authState);
  if (!isUserLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (
    !isOTPVerified &&
    otpVerified &&
    location.pathname != '/authentication' &&
    location.pathname != '/authentication-email'
  ) {
    return <Navigate to="/authentication" state={{ from: location }} />;
  } else if (
    isUserLoggedIn &&
    isOTPVerified &&
    (location.pathname === '/authentication' ||
      location.pathname === '/authentication-email' ||
      location.pathname === '/forgot-password' ||
      location.pathname === '/reset-password')
  ) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }
  return component;
};

// AuthProvider.propTypes = {
//   component: PropTypes.any
// };
RequireAuth.propTypes = {
  component: PropTypes.any,
  otpVerified: PropTypes.bool
};

export default { RequireAuth };
