import React, { useState, useEffect, useRef } from 'react';
import TableRow from './TableRow';
import TableHeadItem from './TableHead';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useParams } from 'react-router-dom';
import {
  setAssociatedTransactionListPosition,
  setFundTransactionListPosition
} from '../../../../../slices/slices/ProposedTransactionSlice';
import { useDispatch } from 'react-redux';

const TransactionTable = (props) => {
  TransactionTable.propTypes = {
    theadData: PropTypes.any,
    tbodyData: PropTypes.any,
    customClass: PropTypes.any,
    detailComponent: PropTypes.any,
    heightObj: PropTypes.any,
    setHeightObj: PropTypes.any,
    heightTotal: PropTypes.any,
    itemDeleted: PropTypes.any,
    setItemDeleted: PropTypes.any,
    setTotalHeight: PropTypes.any,
    addTransaction: PropTypes.any
  };
  let {
    theadData,
    tbodyData,
    customClass,
    totalKey,
    total,
    setHeightObj,
    heightTotal,
    itemDeleted,
    setItemDeleted,
    setTotalHeight
  } = props;

  const ref = useRef(null);
  const [tableData, setTableData] = useState(tbodyData);
  const { jobId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    setTableData(tbodyData);
  }, [tbodyData]);

  useEffect(() => {
    getHeight();
  }, [ref?.current?.clientHeight, tableData, heightTotal]);

  useEffect(() => {
    if (itemDeleted) {
      getHeight(200);
      setItemDeleted(false);
    }
  }, [itemDeleted]);

  const getHeight = (value = 0) => {
    if (setHeightObj) {
      setHeightObj(ref?.current?.clientHeight - value);
      setTotalHeight(
        ref?.current?.clientHeight - value,
        totalKey.toLowerCase().replace('total ', ''),
        value ? ref?.current?.clientHeight - value : null
      );
    }
  };

  const onDeleteTableRow = (value) => {
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].key === value) {
        tableData.splice(i, 1);
        setTableData([...tableData]);
      }
    }
  };

  const SortableItem = SortableElement((props) => {
    return (
      <TableRow
        theadData={theadData}
        tableData={tableData}
        setTableData={setTableData}
        removeTableRow={onDeleteTableRow}
        {...props}
      />
    );
  });

  const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });

  const onSortEnd = ({ oldIndex, newIndex, setErrors }) => {
    if (tableData.length !== 0 && oldIndex !== newIndex) {
      const setMemberId = tableData.filter((item, index) => index === oldIndex && item);
      const getTransactionId = setMemberId && setMemberId[0]?.transaction_id;
      const getFundId = setMemberId && setMemberId[0]?.key;
      const checkType = setMemberId && setMemberId[0]?.type;
      let formData = new FormData();
      formData.append('position', newIndex + 1);
      const updater = (items) =>
        arrayMoveImmutable(items, oldIndex, newIndex).map((item, idx) => {
          return { ...item, order: idx };
        });
      setTableData(updater);
      if (checkType === 'associated_funds') {
        dispatch(
          setAssociatedTransactionListPosition(
            jobId,
            getTransactionId,
            getFundId,
            formData,
            setErrors
          )
        );
      } else {
        dispatch(
          setFundTransactionListPosition(jobId, getTransactionId, getFundId, formData, setErrors)
        );
      }
    }
  };

  return (
    <>
      <table
        ref={ref}
        className={customClass}
        style={{
          height: `${heightTotal ? heightTotal : 0}px`
        }}
      >
        <thead>
          <tr>
            <th></th>
            {theadData.map((h) => {
              return <TableHeadItem key={h.key} keyvalue={h.key} sorting={h.sort} item={h.title} />;
            })}
          </tr>
        </thead>

        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={['30%', '50%']}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {tableData && tableData.length > 0 ? (
            tableData.map((value, index) => (
              <SortableItem key={`item-${index}`} index={index} item={value} />
            ))
          ) : (
            <tr>
              <td colSpan={theadData.length + 1} style={{ textAlign: 'center' }}>
                No Data Found
              </td>
            </tr>
          )}
        </SortableCont>
        <tfoot>
          <tr>
            <td colSpan="3" className="text-start">
              {totalKey}
            </td>
            <td className="text-center">{total}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default TransactionTable;
