import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommentsNotes from '../../../../components/fields/CommentsNotes';
import FormActions from '../../../../components/FormActions';
import Header from '../../Header';
import {
  groupFinancialState,
  getCurrentRentalIncomeData,
  updateCurrentRentalIncome
} from '../../../../slices/slices/GroupFinancialSlice';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Table } from 'semantic-ui-react';
import { displayNameFromType, formatNumber } from '../../../common/Misc';
import Info from '../../../../components/Info';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';

const CurrentRentalIncome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { currentRentalIncomeDataResponse, currentRentalIncomeResponse } =
    useSelector(groupFinancialState);
  const [rentalsData, setRentalData] = useState(currentRentalIncomeDataResponse?.data);
  const [record_type, setRecordtype] = useState();

  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFormSubmit = (values, { setErrors }) => {
    setCanShowDialogLeavingPage(false);

    if (record_type) {
      values.is_refresh = 1;
      values.record_type = record_type;
    }
    dispatch(updateCurrentRentalIncome(jobId, values, setErrors));
    setRecordtype('');
  };

  const handleShowRentalIncome = (item) => {
    navigate('/job/' + jobId + '/data-entry/' + item + '/rentals');
  };

  useEffect(() => {
    setRentalData(currentRentalIncomeDataResponse?.data);
  }, [currentRentalIncomeDataResponse?.data]);

  useEffect(() => {
    dispatch(getCurrentRentalIncomeData(jobId));
  }, []);

  useEffect(() => {
    dispatch(getCurrentRentalIncomeData(jobId));
  }, [currentRentalIncomeResponse]);

  const rentalData =
    currentRentalIncomeDataResponse && rentalsData?.length > 0
      ? rentalsData?.map((item) => {
          return item;
        })
      : null;

  const initialValue = {
    type: 'personal',
    report_comments: currentRentalIncomeDataResponse?.report_comments
      ? currentRentalIncomeDataResponse?.report_comments
      : '',
    internal_notes: currentRentalIncomeDataResponse?.internal_notes
      ? currentRentalIncomeDataResponse?.internal_notes
      : '',
    is_completed: 0
  };

  let commercialRental = 0;
  let commercialSensitised = 0;
  let residentialRental = 0;
  let residentialSensitised = 0;

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard/'}>Dashboard</Link>
              </li>
              <li>
                <a href="#">Group Analysis</a>
              </li>
              <li>
                <strong>Current Rental Income</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-between align-items-center">
            <h1>
              <strong>Current Rental Income</strong>
            </h1>
            <div style={{ marginLeft: 'auto', marginRight: '-15px' }}>
              <Info />
            </div>
          </div>
        </div>
        <Form className="facility-form form-data">
          <div className="gray-card mr-0 mt-0">
            <div className="rentalview ">
              <div className="row justify-content-start">
                <div className="col-md-6">
                  <table className="table financialtable">
                    <thead>
                      <tr>
                        <th className="text-start">Member Name</th>
                        <th className="text-center">Date Created</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {rentalData && rentalData?.length > 0 ? (
                        rentalData?.map((item) => {
                          return (
                            <tr key={item?.id}>
                              <td className="text-start">{displayNameFromType(item)}</td>
                              <td className="text-center">
                                {moment(item?.created_at).format('DD/MM/YYYY')}
                              </td>

                              <td className="text-center">
                                {item?.rentals?.length !== 0 ? (
                                  <>
                                    <i
                                      className={
                                        item?.rental_val === 'completed'
                                          ? 'fa fa-check-circle'
                                          : 'fa fa-exclamation-triangle'
                                      }
                                      aria-hidden="true"
                                      style={{
                                        color: `${
                                          item?.rental_val === 'completed' ? '#42ba96' : '#eed202'
                                        }`
                                      }}
                                    ></i>
                                    <a
                                      className="edit"
                                      onClick={() => {
                                        handleShowRentalIncome(item?.id);
                                      }}
                                    >
                                      <i className="icon-edit"></i>
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <i
                                      className={
                                        item?.rental_val === 'completed'
                                          ? 'fa fa-check-circle'
                                          : 'fa fa-exclamation-triangle'
                                      }
                                      aria-hidden="true"
                                      style={{
                                        color: `${
                                          item?.rental_val === 'completed' ? '#42ba96' : '#eed202'
                                        }`
                                      }}
                                    ></i>

                                    <a
                                      className="notstarted"
                                      onClick={() => {
                                        handleShowRentalIncome(item?.id);
                                      }}
                                    >
                                      <i className="fas fa-folder-plus"></i>
                                    </a>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5">No Records Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="gray-card mb-0">
            <div className="rentalview rentaltableview">
              <div className="row justify-content-start">
                <div className="col-12">
                  <Table className="table mb-0">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="text-start">Consolidated</Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                          Rental
                          <br />
                          (Ex. GST p.a.)
                        </Table.HeaderCell>
                        <Table.HeaderCell className="text-center">
                          Sensitised Rental
                          <br />
                          (Ex. GST p.a.)
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <>
                        <Table.Row className="accordion-toggle clickable">
                          <Table.Cell
                            className="text-start"
                            data-toggle="collapse"
                            data-target="#deductions"
                          >
                            Commercial Rentals<i className="fa fa-caret-up"></i>
                          </Table.Cell>

                          {rentalData && rentalData?.length > 0
                            ? rentalData?.map((data) => {
                                let totalRental = 0;
                                data?.rentals.length !== '0'
                                  ? data?.rentals?.map((rental) => {
                                      if (rental?.type === 'commercial') {
                                        if (!Array.isArray(rental?.additional_info?.tenant)) {
                                          totalRental +=
                                            (rental?.additional_info?.tenant
                                              ?.current_rental_income_pa *
                                              rental?.pivot?.holding) /
                                            100;
                                        } else {
                                          rental?.additional_info?.tenant &&
                                            rental.additional_info.tenant.map((val) => {
                                              totalRental +=
                                                (val?.current_rental_income_pa *
                                                  rental?.pivot?.holding) /
                                                100;
                                            });
                                        }
                                      }
                                    })
                                  : null;
                                commercialRental += totalRental;
                              })
                            : null}
                          <Table.Cell className="text-center">
                            {formatNumber(commercialRental) !== '0'
                              ? formatNumber(commercialRental)
                              : ''}
                          </Table.Cell>

                          {rentalData && rentalData?.length > 0
                            ? rentalData?.map((data) => {
                                let totalSensitised = 0;
                                {
                                  data?.rentals.length !== '0'
                                    ? data?.rentals?.map((rental) => {
                                        if (rental?.type === 'commercial') {
                                          if (!Array.isArray(rental?.additional_info?.tenant)) {
                                            totalSensitised +=
                                              (rental?.additional_info?.tenant?.sensitised_rental *
                                                rental?.pivot?.holding) /
                                              100;
                                          } else {
                                            rental?.additional_info?.tenant &&
                                              rental?.additional_info?.tenant.map((val) => {
                                                totalSensitised +=
                                                  (val?.sensitised_rental *
                                                    rental?.pivot?.holding) /
                                                  100;
                                              });
                                          }
                                        }
                                        // rental?.type === 'commercial' &&
                                        //   rental?.additional_info?.tenant?.map((value) => {
                                        //     totalSensitised +=
                                        //       (value?.sensitised_rental * rental?.pivot?.holding) /
                                        //       100;
                                        //   });
                                      })
                                    : null;
                                }
                                commercialSensitised += totalSensitised;
                              })
                            : null}
                          <Table.Cell className="text-center">
                            {formatNumber(commercialSensitised) !== '0'
                              ? formatNumber(commercialSensitised)
                              : ''}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row className="accordion-body collapse" id="deductions">
                          <Table.Cell className="gray" colSpan="3">
                            <Table className="table memberarea">
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell
                                    className="text-start"
                                    style={{ width: 'auto' }}
                                  >
                                    Member Name
                                  </Table.HeaderCell>
                                  <Table.HeaderCell style={{ width: '19.5%' }}></Table.HeaderCell>
                                  <Table.HeaderCell style={{ width: '18.45%' }}></Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {rentalData && rentalData?.length > 0
                                  ? rentalData?.map((data) => {
                                      let totalRental = 0;
                                      let totalSensitised = 0;
                                      let hasCommercial = false;
                                      {
                                        data?.rentals.length !== '0'
                                          ? data?.rentals?.map((rental) => {
                                              if (
                                                rental?.type === 'commercial' &&
                                                rental?.additional_info?.tenant != ''
                                              )
                                                hasCommercial = true;
                                            })
                                          : null;
                                      }
                                      return (
                                        data.rentals !== null &&
                                        data.rentals !== undefined &&
                                        data.rentals.length > 0 &&
                                        hasCommercial && (
                                          <>
                                            <Table.Row key={data?.id}>
                                              <Table.Cell className="text-start">
                                                {displayNameFromType(data)}
                                              </Table.Cell>

                                              {data?.rentals.length !== '0'
                                                ? data?.rentals?.map((rental) => {
                                                    if (rental?.type === 'commercial') {
                                                      if (
                                                        !Array.isArray(
                                                          rental?.additional_info?.tenant
                                                        )
                                                      ) {
                                                        totalRental +=
                                                          (rental?.additional_info?.tenant
                                                            ?.sensitised_rental *
                                                            rental?.pivot?.holding) /
                                                          100;
                                                      } else {
                                                        rental?.additional_info?.tenant &&
                                                          rental?.additional_info?.tenant.map(
                                                            (val) => {
                                                              totalRental +=
                                                                (val?.current_rental_income_pa *
                                                                  rental?.pivot?.holding) /
                                                                100;
                                                            }
                                                          );
                                                      }
                                                    }
                                                    // rental?.type === 'commercial' &&
                                                    // rental?.additional_info?.tenant?.map(
                                                    //   (value) => {
                                                    //     totalRental +=
                                                    //       (value?.current_rental_income_pa *
                                                    //         rental?.pivot?.holding) /
                                                    //       100;
                                                    //   }
                                                    // )
                                                  })
                                                : null}
                                              <Table.Cell className="text-center">
                                                {formatNumber(totalRental) !== '0'
                                                  ? formatNumber(totalRental)
                                                  : ''}
                                              </Table.Cell>

                                              {data?.rentals.length !== '0'
                                                ? data?.rentals?.map((rental) => {
                                                    if (rental?.type === 'commercial') {
                                                      if (
                                                        !Array.isArray(
                                                          rental?.additional_info?.tenant
                                                        )
                                                      ) {
                                                        totalSensitised +=
                                                          (rental?.additional_info?.tenant
                                                            ?.sensitised_rental *
                                                            rental?.pivot?.holding) /
                                                          100;
                                                      } else {
                                                        rental?.additional_info?.tenant &&
                                                          rental?.additional_info?.tenant.map(
                                                            (val) => {
                                                              totalSensitised +=
                                                                (val?.sensitised_rental *
                                                                  rental?.pivot?.holding) /
                                                                100;
                                                            }
                                                          );
                                                      }
                                                    }
                                                    // return (
                                                    //   rental?.type === 'commercial' &&
                                                    //   rental?.additional_info?.tenant?.map(
                                                    //     (value) => {
                                                    //       totalSensitised +=
                                                    //         (value?.sensitised_rental *
                                                    //           rental?.pivot?.holding) /
                                                    //         100;
                                                    //     }
                                                    //   )
                                                    // );
                                                  })
                                                : null}
                                              <Table.Cell className="text-center">
                                                {formatNumber(totalSensitised) !== '0'
                                                  ? formatNumber(totalSensitised)
                                                  : ''}
                                              </Table.Cell>
                                            </Table.Row>
                                          </>
                                        )
                                      );
                                    })
                                  : null}
                              </Table.Body>
                            </Table>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="accordion-toggle clickable">
                          <Table.Cell
                            className="text-start"
                            data-toggle="collapse"
                            data-target="#ebitda"
                          >
                            Residential Rentals<i className="fa fa-caret-up"></i>
                          </Table.Cell>

                          {rentalData && rentalData?.length > 0
                            ? rentalData?.map((data) => {
                                let totalRental = 0;

                                {
                                  data?.rentals.length !== '0'
                                    ? data?.rentals?.map((rental) => {
                                        if (rental?.type === 'residential') {
                                          if (!Array.isArray(rental?.additional_info?.tenant)) {
                                            totalRental +=
                                              (rental?.additional_info?.tenant
                                                ?.current_rental_income_pa *
                                                rental?.pivot?.holding) /
                                              100;
                                          } else {
                                            rental?.additional_info?.tenant &&
                                              rental?.additional_info?.tenant.map((val) => {
                                                totalRental +=
                                                  (val?.current_rental_income_pa *
                                                    rental?.pivot?.holding) /
                                                  100;
                                              });
                                          }
                                        }
                                        // rental?.type === 'residential' &&
                                        //   (totalRental +=
                                        //     (rental?.additional_info?.tenant
                                        //       ?.current_rental_income_pa *
                                        //       rental?.pivot?.holding) /
                                        //     100);
                                      })
                                    : null;
                                }

                                if (totalRental) {
                                  residentialRental += totalRental;
                                }
                              })
                            : null}
                          <Table.Cell className="text-center">
                            {formatNumber(residentialRental) !== '0'
                              ? formatNumber(residentialRental)
                              : ''}
                          </Table.Cell>

                          {rentalData && rentalData?.length > 0
                            ? rentalData?.map((data) => {
                                let totalSensitised = 0;

                                {
                                  data?.rentals.length !== '0'
                                    ? data?.rentals?.map((rental) => {
                                        if (rental?.type === 'residential') {
                                          if (!Array.isArray(rental?.additional_info?.tenant)) {
                                            totalSensitised +=
                                              (rental?.additional_info?.tenant?.sensitised_rental *
                                                rental?.pivot?.holding) /
                                              100;
                                          } else {
                                            rental?.additional_info?.tenant &&
                                              rental?.additional_info?.tenant.map((val) => {
                                                totalSensitised +=
                                                  (val?.sensitised_rental *
                                                    rental?.pivot?.holding) /
                                                  100;
                                              });
                                          }
                                        }
                                        // rental?.type === 'residential' &&
                                        //   (totalSensitised +=
                                        //     (rental?.additional_info?.tenant?.sensitised_rental *
                                        //       rental?.pivot?.holding) /
                                        //     100);
                                      })
                                    : null;
                                }
                                if (totalSensitised) {
                                  residentialSensitised += totalSensitised;
                                }
                              })
                            : null}
                          <Table.Cell className="text-center">
                            {formatNumber(residentialSensitised) !== '0'
                              ? formatNumber(residentialSensitised)
                              : ''}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row className="accordion-body collapse" id="ebitda">
                          <Table.Cell className="gray" colSpan="3">
                            <Table className="table memberarea">
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell className="text-start">
                                    Member Name
                                  </Table.HeaderCell>
                                  <Table.HeaderCell></Table.HeaderCell>
                                  <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {rentalData && rentalData?.length > 0
                                  ? rentalData?.map((data) => {
                                      let totalRental = 0;
                                      let totalSensitised = 0;
                                      let hasResidential = false;
                                      {
                                        data?.rentals.length !== '0'
                                          ? data?.rentals?.map((rental) => {
                                              if (
                                                rental?.type === 'residential' &&
                                                rental?.additional_info?.tenant
                                              )
                                                hasResidential = true;
                                            })
                                          : null;
                                      }
                                      return (
                                        data.rentals !== null &&
                                        data.rentals !== undefined &&
                                        data.rentals.length > 0 &&
                                        hasResidential && (
                                          <>
                                            <Table.Row key={data?.id}>
                                              <Table.Cell className="text-start">
                                                {displayNameFromType(data)}
                                              </Table.Cell>

                                              {data?.rentals.length !== '0'
                                                ? data?.rentals?.map((rental) => {
                                                    if (rental?.type === 'residential') {
                                                      if (
                                                        !Array.isArray(
                                                          rental?.additional_info?.tenant
                                                        )
                                                      ) {
                                                        totalRental +=
                                                          (rental?.additional_info?.tenant
                                                            ?.current_rental_income_pa *
                                                            rental?.pivot?.holding) /
                                                          100;
                                                      } else {
                                                        rental?.additional_info?.tenant &&
                                                          rental?.additional_info?.tenant.map(
                                                            (val) => {
                                                              totalRental +=
                                                                (val?.current_rental_income_pa *
                                                                  rental?.pivot?.holding) /
                                                                100;
                                                            }
                                                          );
                                                      }
                                                    }
                                                  })
                                                : null}
                                              <Table.Cell className="text-center">
                                                {formatNumber(totalRental) !== '0'
                                                  ? formatNumber(totalRental)
                                                  : ''}
                                              </Table.Cell>

                                              {data?.rentals.length !== '0'
                                                ? data?.rentals?.map((rental) => {
                                                    if (rental?.type === 'residential') {
                                                      if (
                                                        !Array.isArray(
                                                          rental?.additional_info?.tenant
                                                        )
                                                      ) {
                                                        totalSensitised +=
                                                          (rental?.additional_info?.tenant
                                                            ?.sensitised_rental *
                                                            rental?.pivot?.holding) /
                                                          100;
                                                      } else {
                                                        rental?.additional_info?.tenant &&
                                                          rental?.additional_info?.tenant.map(
                                                            (val) => {
                                                              totalSensitised +=
                                                                (val?.sensitised_rental *
                                                                  rental?.pivot?.holding) /
                                                                100;
                                                            }
                                                          );
                                                      }
                                                    }
                                                  })
                                                : null}
                                              <Table.Cell className="text-center">
                                                {formatNumber(totalSensitised) !== '0'
                                                  ? formatNumber(totalSensitised)
                                                  : ''}
                                              </Table.Cell>
                                            </Table.Row>
                                          </>
                                        )
                                      );
                                    })
                                  : null}
                              </Table.Body>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                      </>
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.Cell className="text-start">Total</Table.Cell>
                        <Table.Cell className="text-center">
                          {formatNumber(residentialRental + commercialRental) !== '0'
                            ? formatNumber(residentialRental + commercialRental)
                            : ''}
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          {formatNumber(residentialSensitised + commercialSensitised) !== '0'
                            ? formatNumber(residentialSensitised + commercialSensitised)
                            : ''}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <br />
        <Formik initialValues={initialValue} onSubmit={handleFormSubmit} enableReinitialize>
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="facility-form form-data">
                <CommentsNotes
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleInputChange={handleInputChange}
                  handleBlur={handleBlur}
                  isRefresh={true}
                  setRecordtype={setRecordtype}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                />
                <div className="action-box full">
                  <FormActions cancel={'/job/' + jobId + '/data-entry'} values={values} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CurrentRentalIncome;
