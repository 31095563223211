import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { formatNumber } from '../../../common/Misc';
import { taxState } from '../../../../slices/slices/TaxSlice';
import FixedColumnTableRows from './components/FixedColumnTableRows';
import {
  calculateAdjustedEbitda,
  calculateAdjustedTaxableIncomePersonal,
  calculatePayableTaxPersonal,
  calculateTaxOnIncome
} from '../../../../utils/personalDataEntryCalculations';

const PersonalTax = (props) => {
  const { handleInputChange, handleRemove, selectedMember, individualTaxRateList } = props;
  const { financials, taxData, years } = useSelector(taxState);

  // Define tax brackets as an array of objects with minIncome and rate properties
  // const taxBrackets = [
  //   { minincome: 0, maxincome: 18200, rate: 0 },
  //   { minincome: 18201, maxincome: 45000, rate: 19 },
  //   { minincome: 45001, maxincome: 120000, rate: 32.5 },
  //   { minincome: 120001, maxincome: 180000, rate: 37 },
  //   { minincome: 180001, maxincome: Infinity, rate: 45 }
  // ];

  return (
    <div className="tex_pay">
      {Object.entries(taxData).length > 0 ? (
        <Table className="table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2"></Table.HeaderCell>
              {years.map((value, index) => {
                return (
                  <>
                    <Table.HeaderCell key={index}>{value}</Table.HeaderCell>
                  </>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.entries(taxData).length > 0 ? (
              <>
                <Table.Row>
                  <Table.Cell colSpan="2" className="gray text-start">
                    Adjusted EBITDA
                  </Table.Cell>
                  {years.map((value, index) => {
                    let totalAdjustedEbitda = calculateAdjustedEbitda(value, financials);
                    return (
                      <>
                        <Table.Cell key={index} className="gray align-right">
                          <span>{formatNumber(totalAdjustedEbitda)}</span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                <FixedColumnTableRows
                  category="add"
                  data={taxData['adjusted-tax-income']}
                  years={years}
                  handleInputChange={handleInputChange}
                  fixedName="Add"
                  handleRemove={handleRemove}
                  itemIndex={0}
                />
                <FixedColumnTableRows
                  category="less"
                  data={taxData['adjusted-tax-income']}
                  years={years}
                  handleInputChange={handleInputChange}
                  fixedName="Less"
                  negative={true}
                  handleRemove={handleRemove}
                  itemIndex={
                    taxData['adjusted-tax-income']?.items?.length > 0 &&
                    taxData['adjusted-tax-income']?.items.filter((item) => item.category === 'add')
                      .length
                      ? taxData['adjusted-tax-income']?.items.filter(
                          (item) => item.category === 'add'
                        ).length
                      : 0
                  }
                />
                <Table.Row>
                  <Table.Cell colSpan="2" className="gray text-start">
                    Adjusted Taxable Income
                  </Table.Cell>
                  {years.map((value, index) => {
                    let adjustedTaxableIncome = calculateAdjustedTaxableIncomePersonal(
                      value,
                      taxData,
                      financials
                    );
                    return (
                      <>
                        <Table.Cell key={index} className="gray align-right">
                          <span>{formatNumber(adjustedTaxableIncome)}</span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan="2" className="gray text-start">
                    Tax on Adjusted Taxable Income
                  </Table.Cell>
                  {years.map((value, index) => {
                    let calculateTax = calculateTaxOnIncome(
                      value,
                      taxData,
                      financials,
                      individualTaxRateList
                    );
                    return (
                      <>
                        <Table.Cell key={index} className="gray align-right">
                          <span>{formatNumber(calculateTax)}</span>
                        </Table.Cell>
                      </>
                    );
                  })}
                </Table.Row>
                <FixedColumnTableRows
                  category="less"
                  data={taxData['deductions']}
                  years={years}
                  handleInputChange={handleInputChange}
                  fixedName="Less"
                  negative={true}
                  handleRemove={handleRemove}
                  itemIndex={0}
                />
              </>
            ) : null}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="2">
                {selectedMember?.type == 'company' || selectedMember?.type == 'trust'
                  ? 'Tax Payable/Refundable on Adjusted Taxable Income'
                  : 'Tax Payable on Adjusted Taxable Income'}
              </Table.Cell>
              {years.map((value, index) => {
                let calculateTax = calculatePayableTaxPersonal(
                  value,
                  taxData,
                  financials,
                  individualTaxRateList
                );
                return (
                  <>
                    <Table.Cell key={index} className="align-right">
                      <span>
                        <strong>{formatNumber(calculateTax)}</strong>
                      </span>
                    </Table.Cell>
                  </>
                );
              })}
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : null}
    </div>
  );
};

export default PersonalTax;
