import React, { useState, useEffect } from 'react';
import AddStaffModal from '../../components/AddStaffModal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  staffState,
  getAllJob,
  accountant,
  userDetails
} from '../../slices/slices/StaffManagementSlice';
import UserHeader from '../dashboard/UserHeader';
import Button from '../../components/Button';
import Pagination from '../common/Pagination';
export default function StaffManagementDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, accountantId } = useParams();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [isopen, setOpen] = useState(false);
  const [userData, setuserData] = useState();
  const [items, setItems] = useState([]);
  const [assignjob, setAssignJob] = useState();
  const [type, setType] = useState(false);
  const [mainCheck, setMainCheck] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [unSelectedList, setUnSelectedList] = useState([]);
  const { getAllJobDataResponse, userDetail } = useSelector(staffState);
  const [colummName, setColummName] = useState({ type: '', columm: '' });

  const onhandleClick = () => {
    setOpen(true);
    setType(true);
  };

  const onedithandleClick = () => {
    setOpen(true);
    setType(false);
  };

  useEffect(() => {
    dispatch(getAllJob(0, limit, ''));
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    dispatch(userDetails(userId));
  };

  useEffect(() => {
    setuserData(userDetail);
  }, [userDetail]);

  useEffect(() => {
    const newData =
      getAllJobDataResponse &&
      getAllJobDataResponse?.data?.length > 0 &&
      getAllJobDataResponse?.data?.map((item, index) => {
        let check = false;
        item?.accountants.map((val) => {
          // if (assignjob == null) {
          if (val?.id === accountantId) {
            setAssignJob(val?.job_count);
          }
          // }
          if (val?.users?.id === userData?.id) {
            check = true;
          }
        });
        return { ...item, jobId: index + 1, groupName: item?.groups?.name, selected: check };
      });
    setItems(newData);

    setTotal(getAllJobDataResponse?.total);
    setLimit(getAllJobDataResponse?.per_page);
    setCurrentPage(getAllJobDataResponse?.current_page);
  }, [getAllJobDataResponse]);

  const handleUpdateLimit = (element) => {
    dispatch(getAllJob(currentPage, element.target.value, ''));
    setLimit(element.target.value);
    setCurrentPage(currentPage);
  };
  const handleChangePage = (page) => {
    dispatch(getAllJob(page, limit, ''));
    setCurrentPage(page);
  };

  const handleAllSelect = (event) => {
    let tempList = items;
    tempList.map((member) => (member.selected = event.target.checked));
    setItems(tempList);
    setSelectedList(items.filter((e) => e.selected));
    setMainCheck(event.target.checked);
  };

  const handleSelectRow = (index, item) => {
    let tempList = items;
    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = index.target.checked;
        if (!index.target.checked) {
          setUnSelectedList([...unSelectedList, item.id]);
        } else {
          let unselected = JSON.parse(JSON.stringify(unSelectedList));
          unselected.splice(
            unselected.findIndex((el) => el == item.id),
            1
          );
          setUnSelectedList([...unselected]);
        }
      }
      return user;
    });
    const totalCheckedItems = tempList.filter((e) => e.selected).length;
    setItems(tempList);
    setSelectedList(items.filter((e) => e.selected));
    setMainCheck(items.length === totalCheckedItems);
  };

  const handleSubmit = () => {
    let ids = [];
    selectedList.map((item) => {
      ids.push(item.id);
    });
    dispatch(accountant(accountantId, { ids, uncheck_ids: unSelectedList })).then((res) => {
      if (res) dispatch(getAllJob(currentPage, limit, ''));
    });
  };

  const onhandleCancel = () => {
    navigate('/staff-management');
  };

  const onhandleSearch = (e) => {
    let searchKey = e.target.value;
    dispatch(getAllJob(currentPage, limit, searchKey));
  };

  const onSortingData = (columm, type) => {
    setColummName({ type: type, columm: columm });
    const jobDataSort = items?.slice().sort((a, b) => {
      if (type == 'asc') {
        return (
          typeof a?.[columm] == 'string'
            ? String(a?.[columm]).toLocaleLowerCase() > String(b?.[columm]).toLocaleLowerCase()
            : a?.[columm] > b?.[columm]
        )
          ? 1
          : -1;
      }
      return (
        typeof a?.[columm] == 'string'
          ? String(b?.[columm]).toLocaleLowerCase() > String(a?.[columm]).toLocaleLowerCase()
          : b?.[columm] > a?.[columm]
      )
        ? 1
        : -1;
    });
    setItems(jobDataSort);
  };

  return (
    <>
      <div className="setting toggle-sidebar">
        <UserHeader />
        <main id="main" className="main">
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <Link to={'/dashboard'}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to={'/staff-management'}>Staff Management</Link>
                </li>
              </ul>
              <h1>Staff Management</h1>
            </div>
            <div className="breadcrumb-right">
              <button type="button" className="button green" onClick={onhandleClick}>
                <i className="icon-plus"></i>Add Staff Member
              </button>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-md-9">
              <div className="white-card staff-block">
                <div className="edit" onClick={onedithandleClick}>
                  <i className="fa fa-edit"></i> Edit
                </div>
                <h2>
                  {userData?.name} {userData?.surname}
                </h2>
                <a>{userData?.email}</a>
                <p>{userData?.phone}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="white-card assigned-block">
                <h2>Assigned Jobs</h2>
                <h3>{assignjob ? assignjob : 0}</h3>
              </div>
            </div>
          </div>

          <div className="white-card">
            <input
              type="text"
              onChange={(e) => onhandleSearch(e)}
              placeholder="Search Job"
              className="staff-search"
            ></input>
            <table className="table table-hover mt-4 mb-4">
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      defaultChecked={mainCheck}
                      checked={mainCheck}
                      onClick={(e) => handleAllSelect(e)}
                    />
                  </th>
                  <th scope="col" className="align-left">
                    Job No.
                    <i
                      className={`fa ${
                        colummName.type === 'desc' && colummName.columm === 'job_no'
                          ? 'fa-sort-down'
                          : colummName.type === 'asc' && colummName.columm === 'job_no'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                      aria-hidden="true"
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                      onClick={() =>
                        onSortingData(
                          'job_no',
                          colummName.type === 'asc' && colummName.columm === 'job_no'
                            ? 'desc'
                            : 'asc'
                        )
                      }
                    />
                  </th>
                  <th scope="col" className="align-left">
                    Group Name
                    <i
                      className={`fa ${
                        colummName.type === 'desc' && colummName.columm === 'groupName'
                          ? 'fa-sort-down'
                          : colummName.type === 'asc' && colummName.columm === 'groupName'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                      aria-hidden="true"
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                      onClick={() =>
                        onSortingData(
                          'groupName',
                          colummName.type === 'asc' && colummName.columm === 'groupName'
                            ? 'desc'
                            : 'asc'
                        )
                      }
                    />
                  </th>
                  <th scope="col" className="align-left">
                    Job Name
                    <i
                      className={`fa ${
                        colummName.type === 'desc' && colummName.columm === 'name'
                          ? 'fa-sort-down'
                          : colummName.type === 'asc' && colummName.columm === 'name'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                      aria-hidden="true"
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                      onClick={() =>
                        onSortingData(
                          'name',
                          colummName.type === 'asc' && colummName.columm === 'name' ? 'desc' : 'asc'
                        )
                      }
                    />
                  </th>
                  <th scope="col" className="align-left">
                    State
                    <i
                      className={`fa ${
                        colummName.type === 'desc' && colummName.columm === 'state_value'
                          ? 'fa-sort-down'
                          : colummName.type === 'asc' && colummName.columm === 'state_value'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                      aria-hidden="true"
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                      onClick={() =>
                        onSortingData(
                          'state_value',
                          colummName.type === 'asc' && colummName.columm === 'state_value'
                            ? 'desc'
                            : 'asc'
                        )
                      }
                    />
                  </th>
                  <th scope="col">
                    Start
                    <i
                      className={`fa ${
                        colummName.type === 'desc' && colummName.columm === 'created_at'
                          ? 'fa-sort-down'
                          : colummName.type === 'asc' && colummName.columm === 'created_at'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                      aria-hidden="true"
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                      onClick={() =>
                        onSortingData(
                          'created_at',
                          colummName.type === 'asc' && colummName.columm === 'created_at'
                            ? 'desc'
                            : 'asc'
                        )
                      }
                    />
                  </th>
                  <th scope="col">
                    Due
                    <i
                      className={`fa ${
                        colummName.type === 'desc' && colummName.columm === 'due_date'
                          ? 'fa-sort-down'
                          : colummName.type === 'asc' && colummName.columm === 'due_date'
                          ? 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                      aria-hidden="true"
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                      onClick={() =>
                        onSortingData(
                          'due_date',
                          colummName.type === 'asc' && colummName.columm === 'due_date'
                            ? 'desc'
                            : 'asc'
                        )
                      }
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {items &&
                  items.length > 0 &&
                  items.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <th scope="row" style={{ borderLeft: '#c0bebe40 2px solid' }}>
                            <input
                              type="checkbox"
                              checked={item.selected}
                              onChange={(index) => handleSelectRow(index, item)}
                            />
                          </th>
                          <td className="align-left">{item.job_no}</td>
                          <td className="align-left">
                            <p className="align-left-ellipse">{item?.groupName}</p>
                          </td>
                          <td className="align-left">
                            <p className="align-left-ellipse">{item.name}</p>
                          </td>
                          <td className="align-left">{item.state_value}</td>
                          <td className="align-left nobreakword">
                            {moment(item.created_at).format("DD MMM 'YY")}
                          </td>
                          <td className="align-left nobreakword">
                            {moment(item.due_date).format("DD MMM 'YY")}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            <Pagination
              total={total}
              limit={parseInt(limit)}
              currentPage={currentPage}
              updateLimit={handleUpdateLimit}
              updatePage={handleChangePage}
            />
          </div>
          <div className="btn_area text-end">
            <Button className="btn btn-outline-secondary" type="reset" onClick={onhandleCancel}>
              Cancel
            </Button>
            <Button className="btn btn-success" type="submit" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </main>
        <AddStaffModal
          isopen={isopen}
          setOpen={setOpen}
          type={type}
          userData={userData}
          getUserDetails={getUserDetails}
        />
      </div>
    </>
  );
}
