import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AssociatedTab from '../../../../components/job/groupAnalysis/proposedTransaction/AssociatedTab';
import FundsTab from '../../../../components/job/groupAnalysis/proposedTransaction/FundsTab';

import // addFunds,
// addassociateFunds,
// editFunds,
// updateassociatedfunds,
// editFunds_data,
// getassociateddata
'../../../../slices/slices/ProposedTransactionSlice';
import {
  ProposedTransactionState,
  deleteTransaction,
  setdetetedTransaction,
  getTransactionList,
  editTransaction,
  setEditTransaction
  // clearFundsData
} from '../../../../slices/slices/ProposedTransactionSlice';
import Button from '../../../Button';
import TransactionModel from '../../../modal/TransactionModel';

export default function TransactionItem(props) {
  const dispatch = useDispatch();
  const {
    transactionList,
    values,
    keyIndex,
    activeTab,
    setActiveTab,
    configList,
    handleInputChange
  } = props;
  const { deleteTransactionResponse, editTransactionResponse } =
    useSelector(ProposedTransactionState);

  const [fundDetail, setFundDetail] = useState({});
  const [transactionId, setTransactionId] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [transactionName, setTransactionName] = useState('');
  const [associateName, setAssociateName] = useState('');
  const [error, setError] = useState(1);
  const { jobId } = useParams();

  const handleDelete = () => {
    setShowDelete(false);
  };

  const handleEdit = () => {
    setShowEdit(false);
  };

  // const handleAdd = () => {
  //   setShowAdd(false);
  // };

  // const handleFormSubmit = (values, { setErrors }) => {
  //   let data = { ...values };
  //   // data.amount = data.amount;
  //   if (transactionType == 'source-funds') {
  //     data.how_sourced = data.funds_use;
  //     delete data.funds_use;
  //   } else {
  //     // delete data.funds_use;
  //   }

  //   console.log('transactionType', fundsId, transactionType);

  //   if (fundsId && (transactionType == 'source-funds' || transactionType == 'application-funds')) {
  //     dispatch(editFunds(jobId, transactionId, transactionType, fundsId, data, setErrors)).then(
  //       () => {
  //         setShowAdd(false);
  //         dispatch(getTransactionList({ jobId }));
  //       }
  //     );
  //   } else if (fundsId && transactionType == 'associated_funds') {
  //     data.item = data.funds_use;
  //     delete data.funds_use;
  //     dispatch(updateassociatedfunds(jobId, transactionId, fundsId, data, setErrors)).then(() => {
  //       setShowAdd(false);
  //       dispatch(getTransactionList({ jobId }));
  //     });
  //   } else {
  //     if (transactionType == 'associated_funds') {
  //       data.item = data.funds_use;
  //       delete data.funds_use;

  //       dispatch(addassociateFunds(jobId, transactionId, transactionType, data, setErrors)).then(
  //         () => {
  //           setShowAdd(false);
  //           dispatch(getTransactionList({ jobId }));
  //         }
  //       );
  //     } else {
  //       dispatch(addFunds(jobId, transactionId, transactionType, data, setErrors)).then(() => {
  //         setShowAdd(false);
  //         dispatch(getTransactionList({ jobId }));
  //       });
  //     }
  //   }
  // };

  // const addTransaction = (name, type, transactionId) => {
  //   setFundsId('');
  //   dispatch(clearFundsData());
  //   setShowAdd(true);
  //   settransactionType(type);
  //   settransactionTypeName(name);
  //   setTransactionId(transactionId);
  // };

  // const editTransactionData = (fundsId, transaction_Id, type) => {
  //   settransactionType(type);
  //   setTransactionId(transaction_Id);
  //   setFundsId(fundsId);
  //   dispatch(clearFundsData());
  //   if (fundsId != undefined && (type == 'source-funds' || type == 'application-funds')) {
  //     if (type == 'source-funds') {
  //       settransactionTypeName('Source of Funds');
  //     } else {
  //       settransactionTypeName('Application of Funds');
  //     }
  //     dispatch(editFunds_data(jobId, transaction_Id, fundsId));
  //   } else if (fundsId && type == 'associated_funds') {
  //     settransactionTypeName('Associated Transaction');
  //     dispatch(getassociateddata(jobId, transaction_Id, fundsId));
  //   } else {
  //     dispatch(clearFundsData());
  //   }
  //   setShowAdd(true);
  // };

  const delTransaction = () => {
    setShowDelete(false);
    dispatch(deleteTransaction(jobId, transactionId)).then(() => {
      dispatch(getTransactionList({ jobId }));
    });
  };

  useEffect(() => {
    if (deleteTransactionResponse?.message) {
      setShowDelete(false);
      dispatch(setdetetedTransaction({}));
    }
  }, [deleteTransactionResponse?.message]);

  useEffect(() => {
    setFundDetail({
      applicationFund:
        transactionList?.application_of_funds?.length && transactionList.application_of_funds,
      sourceFund: transactionList?.source_of_funds?.length && transactionList.source_of_funds,
      associated_transactions: transactionList?.associated_transactions
    });
  }, [transactionList]);

  const handleOnclick = (active) => {
    setActiveTab(!active);
  };

  const handleEditTransaction = (values, { setErrors }) => {
    dispatch(editTransaction(jobId, transactionId, values, setErrors)).then(() => {
      dispatch(getTransactionList({ jobId }));
    });
  };

  useEffect(() => {
    if (editTransactionResponse?.message) {
      handleEdit();
      dispatch(setEditTransaction({}));
    }
  }, [editTransactionResponse?.message]);

  return (
    <>
      <div key={transactionList?.id} className="white-card">
        <h2 className="titlePro" style={{ display: 'flex' }}>
          {activeTab ? transactionList.name : transactionList?.associate_name}
          <a style={{ marginLeft: 'auto', marginRight: '16px' }} className="edit">
            <i
              className="icon-edit"
              aria-hidden="true"
              onClick={() => {
                setTransactionName(transactionList?.name);
                setAssociateName(transactionList?.associate_name);
                setTransactionId(transactionList?.id);
                setError(0);
                setShowEdit(true);
              }}
            ></i>
          </a>
          <a className="delete">
            <i
              className="icon-delete"
              aria-hidden="true"
              onClick={() => {
                setTransactionId(transactionList?.id);
                setShowDelete(true);
              }}
            ></i>
          </a>
        </h2>
        <h2 style={{ textAlign: 'right' }}></h2>
        <div className="rentalview">
          <ul className="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
            <li className="nav-item " role="presentation">
              <button
                className={`nav-link ${activeTab && ' active'}`}
                id="commercial-tab"
                data-bs-toggle="tab"
                data-bs-target="#commercial-justified"
                type="button"
                role="tab"
                aria-controls="commercial"
                aria-selected="true"
                onClick={() => handleOnclick(false)}
              >
                Funds Table
              </button>
            </li>
            <li className="nav-item " role="presentation">
              <button
                className={`nav-link ${!activeTab && ' active'}`}
                id="residential-tab"
                data-bs-toggle="tab"
                data-bs-target="#residential-justified"
                type="button"
                role="tab"
                aria-controls="residential"
                aria-selected="false"
                onClick={() => handleOnclick(true)}
              >
                Associated Transaction
              </button>
            </li>
          </ul>
          <div className="tab-content pt-2" id="myTabjustifiedContent">
            {activeTab ? (
              <FundsTab
                configList={configList}
                transactionId={transactionList?.id}
                data={fundDetail}
                setFundDetail={setFundDetail}
                transactionData={props}
                values={values.comment[keyIndex]}
                handleInputChange={handleInputChange}
              />
            ) : (
              <AssociatedTab
                configList={configList}
                transactionId={transactionList?.id}
                data={fundDetail}
                setFundDetail={setFundDetail}
                transactionData={props}
                commentData={values.comment[keyIndex]}
              />
            )}
          </div>
        </div>
      </div>

      <TransactionModel
        showModal={showEdit}
        handleModalClose={handleEdit}
        handleFormSubmit={handleEditTransaction}
        transactionName={transactionName}
        associateName={associateName}
        error={error}
      />

      <Modal
        show={showDelete}
        onHide={handleDelete}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Delete Transaction?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this transaction permanently?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button className="btn btn-secondary" onClick={handleDelete}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              delTransaction();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
}
