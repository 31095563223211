import React, { useState, useEffect } from 'react';
import Title from '../../groupAnalysis/proposedTransaction/Title';
import Label from '../../../../components/Label';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TransactionTable from '../../../../components/job/groupAnalysis/proposedTransaction/table/index';
import CustomModal from '../../../../components/CustomModal';
import { deleteFunds, editFunds_data } from '../../../../slices/slices/ProposedTransactionSlice';
import { formatNumber } from '../../../../pages/common/Misc';
import { Field } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import AddFund from '../../../AddFund';
import { useRef } from 'react';
import {
  ProposedTransactionState,
  clearFundsData,
  addFunds,
  addassociateFunds,
  editFunds,
  updateassociatedfunds,
  getTransactionList
} from '../../../../slices/slices/ProposedTransactionSlice';

export default function FundsTab(props) {
  const dispatch = useDispatch();
  const {
    transactionId,
    data,
    setFundDetail,
    transactionData,
    values,
    configList,
    handleInputChange
    // addTransactions,
    // editTransaction
  } = props;
  const { jobId } = useParams();
  const [applicationfund, setApplicationfund] = useState([]);
  const [sourcefund, setsourcefund] = useState([]);
  const [totalAmount, setTotalAmount] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [fundsId, setFundID] = useState(0);
  const [deleteFundType, setdeleteFundType] = useState(0);
  const [heightTotal, setHeightTotal] = useState(0);
  const [applicationHeight, setApplicationHeight] = useState(0);
  const [sourceHeight, setSourceHeight] = useState(0);
  const [reportComment, setReportComment] = useState(EditorState.createEmpty());
  const [internalNotes, setInternalNotes] = useState(EditorState.createEmpty());
  const [transactionType, setTransactionType] = useState('');
  const [transactionTypeName, setTransactionTypeName] = useState('');
  const [itemDeleted, setItemDeleted] = useState(false);
  const [option, setOptions] = useState([]);
  const { getfundsData } = useSelector(ProposedTransactionState);
  const targetElement = useRef();
  const cancelElement = useRef();

  useEffect(() => {
    setReportComment(getComment(values?.funds_report_comments));
    setInternalNotes(getComment(values?.funds_internal_notes));
  }, [values]);

  // useEffect(() => {
  //   setHeightTotal(applicationHeight > sourceHeight ? applicationHeight : sourceHeight);
  // }, [applicationHeight, sourceHeight]);

  const setTotalHeight = (height, transactionType, secondHeight = null) => {
    let application, source;
    if (transactionType === 'application') {
      application = height;
      source = secondHeight ? secondHeight : sourceHeight;
    } else if (transactionType === 'source') {
      source = height;
      application = secondHeight ? secondHeight : applicationHeight;
    }
    setHeightTotal(application > source ? application : source);
  };

  const getComment = (val) => {
    const contentBlock = htmlToDraft(val ? val : '');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return EditorState.createEmpty();
  };

  const handelComment = (rawDraftContentState, type) => {
    handleInputChange(rawDraftContentState);
    if (type !== 'internal_notes') {
      setReportComment(rawDraftContentState);
    } else {
      setInternalNotes(rawDraftContentState);
    }
  };

  const addTransaction = (name, type) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });

    // console.log('ID', ID);
    // const yOffset = -10;
    // const element = document.getElementById(ID);
    // const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    // window.scrollTo({ top: y, behavior: 'smooth' });

    setFundID('');
    dispatch(clearFundsData());
    setTransactionType(type);
    setTransactionTypeName(name);
  };

  const handleClose = () => {
    const elmnt = cancelElement;
    elmnt.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
    setTransactionType('');
  };

  const editTransaction = (fundsId, transaction_Id, type) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
    dispatch(clearFundsData());
    if (fundsId != undefined && (type == 'source-funds' || type == 'application-funds')) {
      if (type == 'source-funds') {
        setTransactionTypeName('Source of Funds');
      } else {
        setTransactionTypeName('Application of Funds');
      }
      dispatch(editFunds_data(jobId, transaction_Id, fundsId)).then(() => {});
    } else {
      // dispatch(clearFundsData());
    }
    setFundID(fundsId);
    setTransactionType(type);
  };

  const handleFormSubmit = (values, { setErrors }) => {
    let data = { ...values };
    // data.amount = data.amount;
    if (transactionType == 'source-funds') {
      let is_custom = configList['proposed_transaction_how_sourced']?.filter(
        (item) => item.key == data.funds_use.value
      );

      data.is_custom = is_custom && is_custom.length > 0 ? 'no' : 'yes';
      data.how_sourced = data.funds_use.value;
      delete data.funds_use;
    } else if (transactionType == 'application-funds') {
      let is_custom = configList['proposed_transaction_funds_use']?.filter(
        (item) => item.key === data.funds_use.value
      );
      data.is_custom = is_custom && is_custom.length > 0 ? 'no' : 'yes';
      data.funds_use = data.funds_use.value;
      // delete data.funds_use;
    } else {
      //
    }

    if (fundsId && (transactionType == 'source-funds' || transactionType == 'application-funds')) {
      dispatch(editFunds(jobId, transactionId, transactionType, fundsId, data, setErrors)).then(
        () => {
          const elmnt = cancelElement;
          elmnt.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start'
          });
          dispatch(getTransactionList({ jobId }));
          setTransactionType('');
        }
      );
    } else if (fundsId && transactionType == 'associated_funds') {
      let is_custom = configList['proposed_transaction_associated_transaction_item']?.filter(
        (item) => item.key == data.funds_use.value
      );
      data.is_custom = is_custom && is_custom.length > 0 ? 'no' : 'yes';
      data.item = data.funds_use;
      delete data.funds_use;
      dispatch(updateassociatedfunds(jobId, transactionId, fundsId, data, setErrors)).then(() => {
        // setShowAdd(false);
        dispatch(getTransactionList({ jobId }));
      });
    } else {
      if (transactionType == 'associated_funds') {
        let is_custom = configList['proposed_transaction_associated_transaction_item']?.filter(
          (item) => item.key == data.funds_use.value
        );
        data.is_custom = is_custom && is_custom.length > 0 ? 'no' : 'yes';
        data.item = data.funds_use;
        delete data.funds_use;

        dispatch(addassociateFunds(jobId, transactionId, transactionType, data, setErrors)).then(
          () => {
            // setShowAdd(false);
            dispatch(getTransactionList({ jobId }));
          }
        );
      } else {
        dispatch(addFunds(jobId, transactionId, transactionType, data, setErrors)).then(() => {
          const elmnt = targetElement;
          elmnt.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end'
          });

          setTransactionType('');
          dispatch(getTransactionList({ jobId }));
        });
      }
    }
  };

  const handelCommentUpdate = (type) => {
    if (type !== 'internal_notes') {
      transactionData.setFieldValue(
        `comment.${transactionData.keyIndex}.funds_report_comments`,
        draftToHtml(convertToRaw(reportComment.getCurrentContent()))
      );
    } else {
      transactionData.setFieldValue(
        `comment.${transactionData.keyIndex}.funds_internal_notes`,
        draftToHtml(convertToRaw(internalNotes.getCurrentContent()))
      );
    }
  };

  const theaddata = [
    // {
    //   key: 'item',
    //   className: 'text-center',
    //   title: 'item'
    // },
    {
      key: 'comment',
      className: 'text-center',
      title: 'Comment'
    },
    {
      key: 'amount',
      className: 'text-center',
      title: 'Amount',
      render: (item) => formatNumber(item)
    },
    {
      key: 'action',
      className: 'text-center',
      title: '',
      render: (item, record) => {
        return (
          <>
            <a
              className="edit"
              onClick={() => editTransaction(record.key, record.transaction_id, record.type)}
            >
              <i className="icon-edit"></i>
            </a>
            <a className="delete">
              <i
                className="icon-delete"
                onClick={() => handleSelectFundID(record.key, record.type)}
              ></i>
            </a>
          </>
        );
      }
    }
  ];
  useEffect(() => {
    let array = new Array();
    let total = { applicationFundTotal: 0, sourceFundTotal: 0 };
    if (data?.applicationFund && data.applicationFund.length > 0) {
      if (Object.keys(configList).length > 0)
        array = data.applicationFund.map((item) => {
          total.applicationFundTotal += item.amount;
          return {
            key: item.id,
            item: configList['proposed_transaction_funds_use'].find((el) => el.key == item.item)
              ? configList['proposed_transaction_funds_use'].find((el) => el.key == item.item).value
              : item.custom_item,
            comment: item.comment,
            amount: item.amount,
            action: '',
            transaction_id: item.proposed_transaction_id,
            type: item.type
          };
        });
    }
    setApplicationfund([...array]);
    array = new Array();
    if (data?.sourceFund && data.sourceFund.length > 0) {
      if (Object.keys(configList).length > 0)
        array = data.sourceFund.map((item) => {
          total.sourceFundTotal += item.amount;
          return {
            key: item.id,
            item: configList['proposed_transaction_how_sourced'].find((el) => el.key == item.item)
              ? configList['proposed_transaction_how_sourced'].find((el) => el.key == item.item)
                  .value
              : item.custom_item,
            comment: item.comment,
            amount: item.amount,
            action: '',
            transaction_id: item.proposed_transaction_id,
            type: item.type
          };
        });
    }
    setTotalAmount({ ...totalAmount, ...total });
    setsourcefund([...array]);
  }, [data, configList]);

  const handleCancel = () => {
    setFundID(0);
    setShowDelete(false);
  };

  const handleDelete = (fundsId) => {
    setShowDelete(false);

    dispatch(deleteFunds(jobId, transactionId, fundsId)).then(() => {
      let tempList = JSON.parse(JSON.stringify(data));
      if (deleteFundType == 'application-funds') {
        const index = tempList.applicationFund.findIndex((x) => String(x.id) === String(fundsId));

        let deletedFund = tempList.applicationFund.splice(index, 1);
        setTotalAmount({
          ...totalAmount,
          applicationFundTotal: totalAmount.applicationFundTotal - deletedFund[0].amount
        });
        setFundDetail({ ...tempList });
        // dispatch(getTransactionList({ jobId }));

        // setApplicationfund([...tempList]);
      } else {
        const index = tempList.sourceFund.findIndex((x) => String(x.id) === String(fundsId));
        let deletedFund = tempList.sourceFund.splice(index, 1);
        setTotalAmount({
          ...totalAmount,
          sourceFundTotal: totalAmount.sourceFundTotal - deletedFund[0].amount
        });

        setFundDetail(JSON.parse(JSON.stringify(tempList)));
        // dispatch(getTransactionList({ jobId }));

        // setsourcefund([...tempList]);
      }
      setItemDeleted(true);
    });
  };

  const handleSelectFundID = (fundId, type) => {
    setdeleteFundType(type);
    setFundID(fundId);
    setShowDelete(true);
  };

  let options = [];
  useEffect(() => {
    if (transactionType == 'source-funds') {
      configList &&
        configList['proposed_transaction_how_sourced']?.map((item) => {
          options.push({
            label: item.value,
            value: item.key
          });
        });
    } else if (transactionType == 'application-funds') {
      configList &&
        configList['proposed_transaction_funds_use']?.map((item) => {
          options.push({
            label: item.value,
            value: item.key
          });
        });
    } else {
      configList &&
        configList['proposed_transaction_associated_transaction_item']?.map((item) => {
          options.push({
            label: item.value,
            value: item.key
          });
        });
    }
    setOptions(options);
  }, [transactionType]);

  return (
    <div
      className="tab-pane fade show active proposed-tab commercial-justified"
      id="commercial-justified"
      role="tabpanel"
      aria-labelledby="commercial-tab"
    >
      <div className="row">
        <div className="col-md-6">
          <div className="proarea d-flex justify-content-between align-items-center">
            <Title name="Application of Funds" />
            <button
              id={`newbtn${transactionId}`}
              ref={cancelElement}
              type="button"
              className="btn btn-new"
              title={props.name}
              onClick={() =>
                addTransaction('Application of Funds', 'application-funds', transactionId)
              }
            >
              <i className="icon-plus"></i> New
            </button>
          </div>
          <div className="funds">
            <TransactionTable
              theadData={[
                {
                  key: 'item',
                  className: 'text-center',
                  title: 'Funds Use'
                },
                ...theaddata
              ]}
              tbodyData={applicationfund}
              customClass="table financialtable"
              totalKey="Total Application"
              total={formatNumber(totalAmount.applicationFundTotal)}
              setHeightObj={setApplicationHeight}
              setHeightObj1={setSourceHeight}
              setTotalHeight={setTotalHeight}
              heightTotal={heightTotal}
              setItemDeleted={setItemDeleted}
              itemDeleted={itemDeleted}
            />

            <CustomModal
              value="delete"
              show={showDelete}
              title="Delete Fund?"
              text="Are you sure you want to delete this Fund permanently?"
              cancel={handleCancel}
              success={handleDelete}
              reference={fundsId}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="proarea d-flex justify-content-between align-items-center">
            <Title name="Source of Funds" />
            <button
              id={`newbtn${transactionId}`}
              ref={cancelElement}
              type="button"
              className="btn btn-new"
              title={props.name}
              onClick={() => addTransaction('Source of Funds', 'source-funds', transactionId)}
            >
              <i className="icon-plus"></i> New
            </button>
          </div>
          <div className="funds" id={transactionId}>
            <TransactionTable
              theadData={[
                {
                  key: 'item',
                  className: 'text-center',
                  title: 'How Sourced'
                },
                ...theaddata
              ]}
              tbodyData={sourcefund}
              customClass="table financialtable"
              totalKey="Total Source"
              total={formatNumber(totalAmount.sourceFundTotal)}
              setHeightObj={setSourceHeight}
              setHeightObj1={setApplicationHeight}
              setTotalHeight={setTotalHeight}
              heightTotal={heightTotal}
              setItemDeleted={setItemDeleted}
              itemDeleted={itemDeleted}
            />
          </div>
        </div>
      </div>
      <div></div>
      <div id={transactionId} key={transactionId} ref={targetElement}>
        <AddFund
          configList={configList}
          transactionType={transactionType}
          options={option}
          transactionTypeName={transactionTypeName}
          getfundsData={getfundsData}
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleClose}
          fundsId={fundsId}
        />
      </div>
      <div className="row" id={`comment${transactionId}`}>
        <div className="col-md-6">
          <div className="form-field">
            <Label> Report Comments</Label>
            {/* <textarea
              className="form-control"
              name={`comment.${transactionData.keyIndex}.funds_report_comments`}
              value={values?.funds_report_comments}
              onChange={(e) => {
                transactionData.setFieldValue(
                  `comment.${transactionData.keyIndex}.funds_report_comments`,
                  e.target.value
                );
              }}
              onBlur={transactionData.handleBlur}
            ></textarea> */}
            <Field name={`comment.${transactionData.keyIndex}.funds_report_comments`}>
              {({ field }) => (
                <Editor
                  editorState={reportComment}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onBlur={() => handelCommentUpdate('report_comments')}
                  onEditorStateChange={(e) => handelComment(e, 'report_comments')}
                  toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    },
                    list: {
                      options: ['unordered', 'ordered']
                    }
                  }}
                  name={`comment.${transactionData.keyIndex}.funds_report_comments`}
                  id={field.name}
                  values={values?.funds_report_comments}
                  // values={getComment(values?.funds_report_comments)}
                  textareaName={`comment.${transactionData.keyIndex}.funds_report_comments`}
                  editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                  stripPastedStyles={true}
                />
              )}
            </Field>
            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
              {transactionData.errors.report_comments}
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <label>Internal Notes</label>
            {/* <textarea
              className="form-control"
              name={`comment.${transactionData.keyIndex}.funds_internal_notes`}
              value={values?.funds_internal_notes}
              onChange={(e) => {
                transactionData.setFieldValue(
                  `comment.${transactionData.keyIndex}.funds_internal_notes`,
                  e.target.value
                );
              }}
              onBlur={transactionData.handleBlur}
            ></textarea> */}
            <Field name={`comment.${transactionData.keyIndex}.funds_internal_notes`}>
              {({ field }) => (
                <Editor
                  editorState={internalNotes}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onBlur={() => handelCommentUpdate('internal_notes')}
                  onEditorStateChange={(e) => handelComment(e, 'internal_notes')}
                  toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                      options: ['bold', 'italic', 'underline']
                    },
                    list: {
                      options: ['unordered', 'ordered']
                    }
                  }}
                  name={`comment.${transactionData.keyIndex}.funds_internal_notes`}
                  id={field.name}
                  values={values?.funds_internal_notes}
                  // values={getComment(values?.funds_report_comments)}
                  textareaName={`comment.${transactionData.keyIndex}.funds_internal_notes`}
                  editorStyle={{ border: '2px solid #e7e8e8', height: 150 }}
                  stripPastedStyles={true}
                />
              )}
            </Field>
            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
              {transactionData.errors.internal_notes}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
