import Header from './Header';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { facilityState, getMetaData } from '../../slices/slices/FacilitySlice';
import { useParams, Link } from 'react-router-dom';
import { getMembers, jobSetupState } from '../../slices/slices/JobSetupSlice';
import { getDashboardJobData, jobState } from '../../slices/slices/JobSlice';
import { displayNameFromType, formatNumber } from '../common/Misc';

const JobDashboard = () => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { metaDataResponse } = useSelector(facilityState);
  const { membersData } = useSelector(jobSetupState);
  const dashboardData = useSelector(jobState);
  const [members, setMembers] = useState(membersData?.data?.data);
  const [jobDashboardData, setJobDashboardData] = useState(dashboardData);
  let total_group_servicability_business_surplus = 0;

  useEffect(() => {
    setJobDashboardData(dashboardData?.dashboardData?.data);
  }, [dashboardData?.dashboardData?.data]);

  useEffect(() => {
    dispatch(getMembers(jobId, 8));
    dispatch(getMetaData());
    dispatch(getDashboardJobData(jobId));
  }, [jobId]);

  useEffect(() => {
    setMembers(membersData?.data?.data);
  }, [membersData?.data?.data]);

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <section className="joblist_dashboard">
          <div className="boxarea d-flex justify-content-between align-items-start">
            <div className="box_area d-flex justify-content-between">
              <div className="boxgroup bluebox">
                <div className="titleproarea d-flex justify-content-between align-items-start">
                  <h2>
                    Group Servicability
                    {jobDashboardData?.group_servicability_last_update && (
                      <span>
                        <i className="icon-clock-o"></i> Last Update:{' '}
                        {jobDashboardData?.group_servicability_last_update}
                      </span>
                    )}
                  </h2>
                  <img src="../../images/influence-icon.svg" alt="Servicability" />
                </div>
                <div className="busi_text">
                  <div className="busilist d-flex justify-content-between align-items-start">
                    <div className="deficit_txt">
                      <table width="100%" cellPadding="0" cellSpacing="5">
                        <thead>
                          <tr>
                            <th className="txt">Year</th>
                            {jobDashboardData?.years &&
                              jobDashboardData?.years.length > 0 &&
                              jobDashboardData?.years.map((value, index) => {
                                return (
                                  <>
                                    <th
                                      key={index}
                                      className="txt"
                                      style={{ textAlign: 'right', paddingLeft: '5px' }}
                                    >
                                      {value}
                                    </th>
                                  </>
                                );
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="txt">Sensitised Business/(Deficit):</td>
                            {jobDashboardData?.years &&
                              jobDashboardData?.years.length > 0 &&
                              jobDashboardData?.years.map((value, index) => {
                                return (
                                  <>
                                    <td
                                      key={index}
                                      className="txt"
                                      style={{
                                        textAlign: 'right',
                                        paddingLeft: '5px',
                                        wordBreak: 'break-all'
                                      }}
                                    >
                                      $
                                      {formatNumber(
                                        jobDashboardData?.group_servicability_business_surplus?.[
                                          value
                                        ]
                                          ? jobDashboardData
                                              ?.group_servicability_business_surplus?.[value]
                                          : 0
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                          </tr>
                          <tr>
                            <td className="txt">Sensitised Personal/(Deficit):</td>
                            {jobDashboardData?.years &&
                              jobDashboardData?.years.length > 0 &&
                              jobDashboardData?.years.map((value, index) => {
                                return (
                                  <>
                                    <td
                                      key={index}
                                      className="txt"
                                      style={{
                                        textAlign: 'right',
                                        paddingLeft: '5px',
                                        wordBreak: 'break-all'
                                      }}
                                    >
                                      $
                                      {jobDashboardData?.group_servicability_personal_surplus
                                        ? formatNumber(
                                            jobDashboardData?.group_servicability_personal_surplus[
                                              value
                                            ]
                                              ? jobDashboardData
                                                  ?.group_servicability_personal_surplus[value]
                                              : 0
                                          )
                                        : 0}
                                    </td>
                                  </>
                                );
                              })}
                          </tr>
                        </tbody>
                        <tfoot className="total_txt">
                          <tr>
                            <td className="total_txt">Sensitised Surplus/(Deficit):</td>
                            {jobDashboardData?.years &&
                              jobDashboardData?.years.length > 0 &&
                              jobDashboardData?.years.map((value, index) => {
                                total_group_servicability_business_surplus =
                                  (jobDashboardData?.group_servicability_personal_surplus[value]
                                    ? jobDashboardData?.group_servicability_personal_surplus[value]
                                    : 0) +
                                  (jobDashboardData?.group_servicability_business_surplus[value]
                                    ? jobDashboardData?.group_servicability_business_surplus[value]
                                    : 0);

                                return (
                                  <>
                                    <td
                                      key={index}
                                      className="total_txt"
                                      style={{
                                        textAlign: 'right',
                                        paddingLeft: '5px',
                                        wordBreak: 'break-all'
                                      }}
                                    >
                                      ${formatNumber(total_group_servicability_business_surplus)}
                                    </td>
                                  </>
                                );
                              })}
                          </tr>
                        </tfoot>
                      </table>
                      {/* <div className="txt">
                        <i>Year</i>
                        {jobDashboardData?.years &&
                          jobDashboardData?.years.length > 0 &&
                          jobDashboardData?.years.map((value) => {
                            return (
                              <>
                                <span className="txt">{value}</span>
                              </>
                            );
                          })}
                      </div> */}
                      {/* <div className="txt">
                        <i>Sensitised Business/(Deficit): </i>
                        {jobDashboardData?.years &&
                          jobDashboardData?.years.length > 0 &&
                          jobDashboardData?.years.map((value) => {
                            return (
                              <>
                                <span className="txt">
                                  $
                                  {formatNumber(
                                    jobDashboardData?.group_servicability_business_surplus?.[value]
                                      ? jobDashboardData?.group_servicability_business_surplus?.[
                                          value
                                        ]
                                      : 0
                                  )}
                                </span>
                              </>
                            );
                          })}
                      </div>
                      <div className="txt">
                        <i>Sensitised Personal/(Deficit): </i>
                        {jobDashboardData?.years &&
                          jobDashboardData?.years.length > 0 &&
                          jobDashboardData?.years.map((value) => {
                            return (
                              <>
                                <span className="txt">
                                  $
                                  {jobDashboardData?.group_servicability_personal_surplus
                                    ? formatNumber(
                                        jobDashboardData?.group_servicability_personal_surplus[
                                          value
                                        ]
                                          ? jobDashboardData?.group_servicability_personal_surplus[
                                              value
                                            ]
                                          : 0
                                      )
                                    : 0}
                                </span>
                              </>
                            );
                          })}
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="busilist_tal d-flex justify-content-between align-items-start">
                    <div className="deficit_txt">
                      <div className="total_txt">
                        <i>Sensitised Surplus/(Deficit): </i>
                        {jobDashboardData?.years &&
                          jobDashboardData?.years.length > 0 &&
                          jobDashboardData?.years.map((value) => {
                            total_group_servicability_business_surplus =
                              (jobDashboardData?.group_servicability_personal_surplus[value]
                                ? jobDashboardData?.group_servicability_personal_surplus[value]
                                : 0) +
                              (jobDashboardData?.group_servicability_business_surplus[value]
                                ? jobDashboardData?.group_servicability_business_surplus[value]
                                : 0);

                            return (
                              <>
                                <span className="total_txt">
                                  ${formatNumber(total_group_servicability_business_surplus)}
                                </span>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="boxgroup darkblue">
                <div className="titleproarea d-flex justify-content-between align-items-start">
                  <h2>
                    Overall Assessment
                    {jobDashboardData?.bankability_assessment_last_update && (
                      <span>
                        <i className="icon-clock-o"></i> Last Update:{' '}
                        {jobDashboardData?.bankability_assessment_last_update}
                      </span>
                    )}
                  </h2>
                  <img src="../../images/assessment.png" alt="Assessment" />
                </div>
                <div className="busi_text">
                  <div className="busilist d-flex justify-content-between align-items-start">
                    <div className="deficit_txt">
                      <div className="txt">Green Flags:</div>
                      <div className="txt">Orange Flags:</div>
                      <div className="txt">Red Flags:</div>
                    </div>
                    <div className="deficit_taldigi">
                      <div className="digits d-flex justify-content-between">
                        <span></span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.bankability_assessment_green_flags
                        )} */}
                        {formatNumber(jobDashboardData?.overall_assessment_green_flag)}
                      </div>
                      <div className="digits d-flex justify-content-between">
                        <span></span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.bankability_assessment_orange_flags
                        )} */}
                        {formatNumber(jobDashboardData?.overall_assessment_yellow_flag)}
                      </div>
                      <div className="digits d-flex justify-content-between">
                        <span></span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.bankability_assessment_orange_flags
                        )} */}
                        {formatNumber(jobDashboardData?.overall_assessment_red_flag)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boxgroup skyblue">
                <div className="titleproarea d-flex justify-content-between align-items-start">
                  <h2>
                    Facilities
                    {jobDashboardData?.facilities_last_update && (
                      <span>
                        <i className="icon-clock-o"></i> Last Update:{' '}
                        {jobDashboardData?.facilities_last_update}
                      </span>
                    )}
                  </h2>
                  <img src="../../images/bankability-icon.svg" alt="Facility" />
                </div>
                <div className="busi_text">
                  <div className="busilist d-flex justify-content-between align-items-start">
                    <div className="deficit_txt">
                      <div className="txt">Existing Facilities:</div>
                      <div className="txt">Change:</div>
                    </div>
                    <div className="deficit_taldigi">
                      <div className="digits d-flex justify-content-between">
                        <span>$</span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.facilities_existing_limit
                        )} */}
                        {formatNumber(jobDashboardData?.facilities_existing_limit)}
                      </div>
                      <div className="digits d-flex justify-content-between">
                        <span>$</span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.facilities_changed
                        )} */}
                        {formatNumber(jobDashboardData?.facilities_changed)}
                      </div>
                    </div>
                  </div>
                  <div className="busilist_tal d-flex justify-content-between align-items-end">
                    <div className="total_txt">Proposed Facilities:</div>
                    <div className="deficit_taldigi">
                      <div className="digits d-flex justify-content-between">
                        <span>$</span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.facilities_proposed
                        )} */}
                        {formatNumber(jobDashboardData?.facilities_proposed)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boxgroup skyblue">
                <div className="titleproarea d-flex justify-content-between align-items-start">
                  <h2>
                    Securities
                    {jobDashboardData?.securities_last_update && (
                      <span>
                        <i className="icon-clock-o"></i> Last Update:{' '}
                        {jobDashboardData?.securities_last_update}
                      </span>
                    )}
                  </h2>
                  <img src="../../images/security-icon.svg" alt="Securities" />
                </div>
                <div className="busi_text">
                  <div className="busilist d-flex justify-content-between align-items-start">
                    <div className="deficit_txt">
                      <div className="txt">Market Value:</div>
                      <div className="txt">Average Shading:</div>
                    </div>
                    <div className="deficit_taldigi">
                      <div className="digits d-flex justify-content-between">
                        <span>$</span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.securities_market_value
                        )} */}
                        {formatNumber(jobDashboardData?.securities_market_value)}
                      </div>
                      <div className="digits text-end">
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.securities_landing_ratio || 0
                        )} */}
                        {formatNumber(jobDashboardData?.securities_landing_ratio || 0)}%
                      </div>
                    </div>
                  </div>
                  <div className="busilist_tal d-flex justify-content-between align-items-end">
                    <div className="total_txt">Lending Value:</div>
                    <div className="deficit_taldigi">
                      <div className="digits d-flex justify-content-between">
                        <span>$</span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.securities_lending_value || 0
                        )} */}
                        {formatNumber(jobDashboardData?.securities_lending_value || 0)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boxgroup skyblue">
                <div className="titleproarea d-flex justify-content-between align-items-start">
                  <h2>
                    Unencumbered Property
                    {jobDashboardData?.unencumbered_properties_last_update && (
                      <span>
                        <i className="icon-clock-o"></i> Last Update:{' '}
                        {jobDashboardData?.unencumbered_properties_last_update}
                      </span>
                    )}
                  </h2>
                  <img src="../../images/unencumbered-icon.svg" alt="Property" />
                </div>
                <div className="busi_text">
                  <div className="busilist_tal d-flex justify-content-between align-items-end">
                    <div className="total_txt">Market Value:</div>
                    <div className="total_dig d-flex justify-content-between">
                      <span>$</span>
                      {/* {new Intl.NumberFormat('en-IN').format(
                        jobDashboardData?.unencumbered_properties_market_value
                      )} */}
                      {formatNumber(jobDashboardData?.unencumbered_properties_market_value)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="boxgroup skyblue">
                <div className="titleproarea d-flex justify-content-between align-items-start">
                  <h2>
                    Rental Property
                    {jobDashboardData?.rental_last_update && (
                      <span>
                        <i className="icon-clock-o"></i> Last Update:
                        {jobDashboardData?.rental_last_update}
                      </span>
                    )}
                  </h2>
                  <img src="../../images/rental-property-icon.svg" alt="Rental Property" />
                </div>
                <div className="busi_text">
                  <div className="busilist d-flex justify-content-between align-items-start">
                    <div className="deficit_txt">
                      <div className="txt">Residential Rental: Ex. GST p.a.</div>
                      <div className="txt">Commercial Rental: Ex. GST p.a.</div>
                    </div>

                    <div className="deficit_taldigi">
                      <div className="digits d-flex justify-content-between">
                        <span>$</span>
                        {formatNumber(jobDashboardData?.rental_residential_gst_pa)}
                      </div>
                      <div className="digits d-flex justify-content-between">
                        <span>$</span>
                        {formatNumber(jobDashboardData?.rental_commercial_gst_pa)}
                      </div>
                    </div>
                  </div>
                  <div className="busilist_tal d-flex justify-content-between align-items-end">
                    <div className="total_txt">Sensitised Rental: Ex. GST p.a.</div>

                    <div className="deficit_taldigi">
                      <div className="digits d-flex justify-content-between">
                        <span>$</span>
                        {/* {new Intl.NumberFormat('en-IN').format(
                          jobDashboardData?.rental_sensitised_income
                        )} */}
                        {formatNumber(jobDashboardData?.total_rental_propery)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="expo_supersstore">
              <div className="expotitlepro d-flex justify-content-between align-items-start">
                <div className="titlearea">
                  <h3>
                    {/* {memberDataResponse?.name} */}
                    {jobDashboardData?.job?.group_name}
                  </h3>
                </div>
                <div className="date_area">
                  <div className="startdate d-flex justify-content-between align-items-center">
                    <span className="txt">Start Date:</span>
                    <span className="dateformated">{jobDashboardData?.job?.start_date}</span>
                  </div>
                  <div className="startdate d-flex justify-content-between align-items-center">
                    <span className="txt"> Due Date:</span>
                    <span className="dateformated">{jobDashboardData?.job?.due_date}</span>
                  </div>
                  <div className="startdate d-flex justify-content-between align-items-end">
                    <span className="txt">
                      {' '}
                      Anticipated
                      <br />
                      Settlement Date:
                    </span>
                    <span className="dateformated">
                      {jobDashboardData?.anticipated_settlement_date}
                    </span>
                  </div>
                  <div className="offerrecieved d-flex justify-content-between align-items-center">
                    <span className="txt">State:</span>

                    <span className="offer">
                      {metaDataResponse?.data?.job_states?.find(
                        (el) => el.key == jobDashboardData?.job?.state
                      )?.value
                        ? metaDataResponse?.data?.job_states?.find(
                            (el) => el.key == jobDashboardData?.job?.state
                          )?.value
                        : 'Information Request'}
                    </span>
                  </div>
                </div>
              </div>
              <table className="table exposupers_store mb-0">
                <thead>
                  <tr>
                    <th>
                      Member Name{' '}
                      <Link to={'/job/' + jobId + '/group-setup/members/add'}>
                        <i className="icon-plus"></i>
                      </Link>
                    </th>
                    <th>Activity/Occupation</th>
                  </tr>
                </thead>
                <tbody>
                  {members &&
                    members.map((member, index) => {
                      return (
                        <tr key={index}>
                          <td>{displayNameFromType(member)}</td>
                          <td>{member.activity}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Link to={'/job/' + jobId + '/group-setup/members'} className="viewall">
                View All
              </Link>
            </div>
          </div>
          {/*<div className="row commentsarea">
            <div className="col-md-12">
              <div className="card info-card">
                <h3 className="cmt_title">Comments</h3>

                <div className="profile-tabs"></div>

                <div className="tableareas">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Module</th>
                        <th>Name</th>
                        <th>Report Comments</th>
                        <th>Internal Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobDashboardData?.comments?.map((item, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{item.module[0].toLocaleUpperCase() + item.module.slice(1)}</td>
                              <td>
                                {item?.additional_info !== undefined
                                  ? displayNameFromType(item)
                                  : item?.name}
                              </td>
                              <td>{item.report_comments}</td>
                              <td>{item.internal_notes}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>*/}
        </section>
      </div>
    </div>
  );
};

export default JobDashboard;
