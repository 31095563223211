import React, { useState, useEffect } from 'react';
import GroupTables from '../Grouptable/table/index';
import Comment from '../Grouptable/Comment';
import {
  // displayNameFromType,
  formatNumber,
  displayOwnerNameFromType
} from '../../../../../pages/common/Misc';

export default function GroupTable(props) {
  const { groupData, values, setFieldValue, deleteReliancedata, resetForm, handleInputChange } =
    props;
  useEffect(() => {}, [groupData]);
  const [tableGroupData, setTableGroupData] = useState({
    table1: [],
    table2: [],
    existinglimit: 0,
    proposedlimit: 0,
    marketvalue: 0,
    landingvalue: 0,
    finaltotal: 0,
    changevalue: 0
  });

  useEffect(() => {
    let tableData = [];
    groupData?.data?.data?.length > 0 &&
      groupData?.data?.data?.map((item) => {
        let obj = {
          group_id: item.id,
          position: item.position,
          table1: [],
          table2: [],
          reliance: [],
          existinglimit: 0,
          proposedlimit: 0,
          marketvalue: 0,
          landingvalue: 0,
          finaltotal: 0,
          changevalue: 0
        };

        item.reliance.map((relianceItem) => {
          if (relianceItem.type == 'add') {
            obj.marketvalue += relianceItem.value;
            obj.landingvalue += relianceItem.value;
          } else {
            obj.marketvalue -= relianceItem.value;
            obj.landingvalue -= relianceItem.value;
          }
        });

        item?.group_data?.securities?.length > 0 &&
          item.group_data.securities.map(async (securityItem) => {
            let ownerList = securityItem?.security_owners
              ? JSON.parse(JSON.stringify(securityItem?.security_owners))
              : [];
            obj.marketvalue += securityItem?.market_value;
            obj.landingvalue += securityItem?.lending_value;
            obj.table2.push({
              key: securityItem?.id,
              securityowner: displayOwnerNameFromType(
                ownerList.sort((a, b) => a.position - b.position)
              ),
              memberId: securityItem?.security_owners[0]?.id,
              position: securityItem?.security_owners[0]?.position,
              address: securityItem?.address,
              assetclass: securityItem?.asset_class_val,
              is_completed: securityItem?.is_completed,
              assetusages: securityItem?.asset_usage_val,
              assetDescription: securityItem?.asset_description_val,
              marketvalue: securityItem?.market_value,
              shading: formatNumber(securityItem?.shading) + '%',
              landingvalue: securityItem?.lending_value
            });
          });

        item?.group_data?.facilities?.length > 0 &&
          item.group_data.facilities.map((facilityItem) => {
            let ownerList = facilityItem?.facility_owners
              ? JSON.parse(JSON.stringify(facilityItem?.facility_owners))
              : [];
            obj.existinglimit += parseFloat(facilityItem?.existing_limit);
            obj.proposedlimit += parseFloat(facilityItem?.additional_info?.proposed_limit);
            obj.changevalue += parseFloat(facilityItem?.change);
            obj.table1.push({
              key: facilityItem.id,
              borrower: displayOwnerNameFromType(ownerList.sort((a, b) => a.position - b.position)), //displayOwnerNameFromType(facilityItem?.facility_owners),
              is_completed: facilityItem?.is_completed,
              memberId: facilityItem?.facility_owners[0]?.id,
              purpose: facilityItem?.purpose,
              lender:
                facilityItem?.additional_info?.facility_status == 'new'
                  ? ''
                  : facilityItem?.lender_val,
              existinglimit: parseFloat(facilityItem?.existing_limit),
              change:
                parseFloat(facilityItem?.additional_info?.proposed_limit) -
                parseFloat(facilityItem?.existing_limit),
              proposedlimit: parseFloat(facilityItem?.additional_info?.proposed_limit),
              changevalue: parseFloat(facilityItem?.change),
              status:
                facilityItem?.additional_info?.facility_status == 'new'
                  ? 'New'
                  : facilityItem?.additional_info?.facility_status == 'refinance'
                  ? 'Refinance'
                  : facilityItem?.additional_info?.facility_status == 'variation'
                  ? 'Variation'
                  : facilityItem?.additional_info?.facility_status == 'existing'
                  ? 'No Change'
                  : ''
            });
          });
        obj.finaltotal = parseFloat(obj.landingvalue) - parseFloat(obj.proposedlimit);
        obj.reliance = item?.reliance;
        tableData.push(obj);
      });
    setTableGroupData([...tableData]);
  }, [groupData]);

  return (
    <>
      {tableGroupData.length > 0 &&
        tableGroupData.map((item, index) => (
          <div
            key={item.position}
            className="tab-pane fade show active"
            id="commercial-justified"
            role="tabpanel"
            aria-labelledby="commercial-tab"
          >
            <div className="row">
              <div className="col-md-12">
                <h3 className="titlePro">Group {item.position}</h3>
                <div className="securitiesarea">
                  <GroupTables
                    groupData={groupData}
                    customClass="table group1"
                    tbodyData={item}
                    totalKey="Total"
                    existingLimit="15000"
                    deleteReliancedata={deleteReliancedata}
                  />
                </div>
              </div>
            </div>
            <div style={{ margin: '-30px' }}>
              <Comment
                values={values}
                index={index}
                setFieldValue={setFieldValue}
                handleInputChange={handleInputChange}
                group_id={item.group_id}
                resetForm={resetForm}
              />
            </div>
          </div>
        ))}
    </>
  );
}
