import React, { Suspense, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { /* BrowserRouter, */ Route, Routes } from 'react-router-dom';
import Loader from '../../pages/common/Loader';
import { appState, setErrorMessage, setInitial } from '../../slices/slices/AppSlice';
import Auth from './AuthProvider';
import useGetRoutes from './AuthRoutes';
import { GetCookies, RemoveCookie } from '../../services/cookies';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const AppRouter = () => {
  const appRoutes = [...useGetRoutes()];
  const alert = useAlert();
  const dispatch = useDispatch();
  const { errorMessage, successMessage } = useSelector(appState);

  // const getConfirm = (content, callback) => {
  //   const c = window.confirm(content);
  //   if (c) {
  //     callback(true);
  //   }
  // };

  useEffect(() => {
    setInterval(() => {
      if (
        GetCookies('tokenExpire') === undefined ||
        new Date().getTime() > GetCookies('tokenExpire')
      ) {
        RemoveCookie('token');
      }
    }, 10000);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      alert.error(errorMessage);
      dispatch(setErrorMessage(''));
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      alert.success(successMessage);
      dispatch(setInitial(''));
    }
  }, [successMessage]);

  return (
    <HistoryRouter history={createBrowserHistory({ window })}>
      {/* <BrowserRouter getUserConfirmation={getConfirm}> */}
      <Loader />
      <Suspense
        fallback={
          <div id="loader">
            <div className="loader"></div>
          </div>
        }
      >
        <Routes>
          {appRoutes.map((route, i) => {
            if (route.authenticated) {
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={
                    <Auth.RequireAuth
                      component={<route.component />}
                      otpVerified={route.otpVerified}
                    />
                  }
                  exact={route.exact}
                  onChange={() => {}}
                />
              );
            }
            return (
              <Route
                key={i}
                path={route.path}
                element={<route.component />}
                exact={route.exact}
                onChange={() => {}}
              />
            );
          })}
        </Routes>
      </Suspense>
      {/* </BrowserRouter> */}
    </HistoryRouter>
  );
};

export { AppRouter };
