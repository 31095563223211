import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { AppRouter } from './utils/router';
import store from './slices/store';
import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

const options = {
  timeout: 5000,
  position: positions.BOTTOM_LEFT
};

const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
          <AppRouter />
        </AlertProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
