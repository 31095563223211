import { Tab, Tabs, Modal } from 'react-bootstrap';
import JobTable from './JobTable';
import React, { useEffect, useState } from 'react';
import UserHeader from './UserHeader';
import { Formik } from 'formik';
import { AddJobSchema } from '../common/Validation';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';
import { getxpm, refreshXpm } from '../../slices/slices/FinancialsSlice';
import {
  addJob,
  getJobByStatus,
  getStateJobData,
  jobState,
  setReloadJob
} from '../../slices/slices/JobSlice';
import Label from '../../components/Label';
import GroupInput from '../../components/GroupInput';
import { GetCookies, SetCookie } from '../../services/cookies';
import InputMask from 'react-input-mask';
import moment from 'moment';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobData, stateJobDataResponse, updateStateResponse } = useSelector(jobState);
  const newData = jobData?.data?.map((item, index) => {
    return { ...item, jobId: index + 1, is_locked_icon: false, selected: false };
  });

  const { reloadJobs } = useSelector(jobState);
  const json_str = GetCookies('_jobData');

  const [status, setStatus] = useState('active');

  const [search, setSearch] = useState('');

  const [showJob, setShowJob] = useState(false);

  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState(newData);
  const [total, setTotal] = useState(0);
  const [selectedList, setSelectedList] = useState([]);

  const handleJob = () => {
    setShowJob(false);
  };

  const handleManualJobSetup = () => {
    setShowJob(false);
    setShowJobForm(true);
  };

  const [showJobForm, setShowJobForm] = useState(false);
  const handleJobForm = () => {
    setShowJobForm(false);
  };

  // const disablePastDate = () => {
  //   const today = new Date();
  //   const dd = String(today.getDate() + 1).padStart(2, '0');
  //   const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  //   const yyyy = today.getFullYear();
  //   return dd + '-' + mm + '-' + yyyy;
  // };

  useEffect(() => {
    setItems(newData);
    setTotal(jobData?.total);
    setLimit(jobData?.per_page);
    setCurrentPage(jobData?.current_page);
  }, [jobData]);

  useEffect(() => {
    dispatch(getJobByStatus({ status: status, page: currentPage, showing: limit, search: search }));
  }, [updateStateResponse]);

  const handleUpdateLimit = (element) => {
    dispatch(
      getJobByStatus({
        status: status,
        currentPage,
        showing: element.target.value,
        search: search
      })
    );
    setLimit(element.target.value);
    setCurrentPage(currentPage);
  };
  const handleChangePage = (page) => {
    dispatch(getJobByStatus({ status: status, page, showing: limit, search: search }));
    setCurrentPage(page);
    setLimit(limit);
  };

  const handleFormSubmit = (values, { setErrors }) => {
    const data = {
      ...values,
      due_date: moment(values.due_date).format('DD-MM-YYYY')
    };
    dispatch(addJob(data, setErrors)).then((response) => {
      const jobDetail = json_str ? JSON.parse(JSON.stringify(json_str)) : [];
      const setData = {
        id: response?.data?.data?.job.group_id,
        job_no: response?.data?.data?.job.job_no,
        jobId: items.length + 1,
        jobName: response?.data?.data?.job.name
      };
      jobDetail.push(setData);
      SetCookie('_jobData', JSON.stringify(jobDetail));
      SetCookie('_jobHeader', setData);
      navigate('/job/' + response?.data?.data?.id + '/dashboard');
      dispatch(setReloadJob());
    });
    setShowJobForm(false);
  };

  const searchJob = (e) => {
    if (e.key === 'Enter') {
      let searchString = e.target.value;
      dispatch(getJobByStatus({ status, search: searchString }));
    }
  };

  const handleSearchText = (e) => {
    setSearch(e.target.value);
  };

  const handleTabChange = (status) => {
    setStatus(status);
    setSelectedList([]);
    dispatch(getJobByStatus({ status, showing: limit, search: search }));
  };

  useEffect(() => {
    dispatch(getStateJobData());
  }, []);

  useEffect(() => {
    dispatch(getJobByStatus({ status }));
  }, [reloadJobs]);

  const xeroLogin = () => {
    dispatch(getxpm()).then((response) => {
      // localStorage.setItem(
      //   'data',
      //   JSON.stringify({
      //     jobId: jobId,
      //     member: memberId,
      //     name: displayNameFromType(member)
      //   })
      // );
      openInNewTab(response?.data?.data);
    });
  };
  const openInNewTab = (url) => {
    dispatch(refreshXpm()).then(() => {
      window.open(url, '_self');
    });
  };

  return (
    <div className="setting toggle-sidebar">
      <UserHeader />
      <div id="main" className="main">
        <h1></h1>
        <div className="card">
          <div className="card-body">
            <div className="profile-tabs">
              <div className="searchbox">
                <Input
                  type="text"
                  placeholder="Search"
                  name={'search'}
                  value={search}
                  onChange={(e) => handleSearchText(e)}
                  className="searchbox-field"
                  onKeyDown={(e) => searchJob(e)}
                />
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#CreatJobModal"
                  className="green topbtn"
                  onClick={() => {
                    setShowJob(true);
                  }}
                >
                  + Job
                </button>
              </div>
              <Tabs
                className="nav nav-tabs d-flex"
                id="myTab"
                role="tablist"
                activeKey={status}
                onSelect={(status) => handleTabChange(status)}
              >
                <Tab className="nav-item flex-fill" eventKey="active" title="Current">
                  <JobTable
                    status="active"
                    search={search}
                    handleUpdateLimit={handleUpdateLimit}
                    handleChangePage={handleChangePage}
                    limit={limit}
                    currentPage={currentPage}
                    setItems={setItems}
                    items={items}
                    stateJobDataResponse={stateJobDataResponse}
                    total={total}
                    selectedList={selectedList}
                    setSelectedList={setSelectedList}
                  />
                </Tab>
                <Tab className="nav-item flex-fill" eventKey="archived" title="Archived">
                  <JobTable
                    status="archived"
                    search={search}
                    handleUpdateLimit={handleUpdateLimit}
                    handleChangePage={handleChangePage}
                    limit={limit}
                    currentPage={currentPage}
                    setItems={setItems}
                    items={items}
                    stateJobDataResponse={stateJobDataResponse}
                    total={total}
                    selectedList={selectedList}
                    setSelectedList={setSelectedList}
                  />
                </Tab>
                <Tab className="nav-item flex-fill" eventKey="deleted" title="Deleted">
                  <JobTable
                    status="deleted"
                    search={search}
                    handleUpdateLimit={handleUpdateLimit}
                    handleChangePage={handleChangePage}
                    limit={limit}
                    currentPage={currentPage}
                    setItems={setItems}
                    items={items}
                    stateJobDataResponse={stateJobDataResponse}
                    total={total}
                    selectedList={selectedList}
                    setSelectedList={setSelectedList}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>

        <Modal
          show={showJob}
          onHide={handleJob}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="create-job">
            <h3>Create New Job</h3>
            <div className="create-btn">
              <Button type="button" className="btn btn-primary" onClick={xeroLogin}>
                Import from XPM
              </Button>
              or
              <Button
                type="button"
                className="btn btn-secondary"
                id="manualjobbtn"
                onClick={handleManualJobSetup}
              >
                Manual Job SetUp
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* end  */}
        <Modal
          show={showJobForm}
          onHide={handleJobForm}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="create-job">
            <h3>Create New Job</h3>
            <div className="createjob_v2">
              <Formik
                initialValues={{
                  group_id: '',
                  group_name: '',
                  name: '',
                  due_date: ''
                }}
                validationSchema={AddJobSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  setFieldValue,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setValues
                }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <fieldset>
                        <div className="field-box">
                          <Label>Group Name</Label>
                          <GroupInput
                            setGroupName={(e) => {
                              // setValues({
                              //   group_name: e
                              // });
                              setFieldValue('group_name', e);
                            }}
                            setGroupId={(e) => {
                              // setValues({
                              //   group_id: e
                              // });
                              setFieldValue('group_id', e);
                            }}
                            values={values}
                            setValues={setValues}
                          />
                          <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {errors.group_id || errors.group_name}
                          </span>
                        </div>
                        <div className="field-box">
                          <Label>Job Name</Label>
                          <Input
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            name="name"
                          />
                          <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {errors.name}
                          </span>
                        </div>
                        <div className="field-box date">
                          <Label>Due Date</Label>
                          <DatePicker
                            selected={values?.due_date ? new Date(values?.due_date) : null}
                            onChange={(val) => {
                              setFieldValue('due_date', val);
                            }}
                            customInput={
                              <InputMask
                                className="form-control"
                                mask="99-99-9999"
                                maskChar={null}
                              />
                            }
                            name="due_date"
                            dateFormat="dd-MM-yyyy"
                            value={new Date(values?.due_date)}
                            placeholderText="DD-MM-YYYY"
                            minDate={new Date()}
                          />
                          {/* <Input
                            type="date"
                            pattern="\d{2}-\d{2}-\d{4}"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.due_date}
                            id="datepicker"
                            name="due_date"
                            className="hasDatepicker"
                            min={moment(new Date()).format('Y-MM-DD')}
                            // min={disablePastDate()}
                          /> */}

                          <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                            {errors.due_date}
                          </span>
                        </div>
                      </fieldset>
                      <div className="modal-action">
                        <Button
                          type="button"
                          className="btn btn-secondary"
                          id="createbtnjob"
                          onClick={handleJobForm}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" className="btn btn-primary">
                          Create
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Index;
