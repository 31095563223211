import React from 'react';

export default function Xpmtablerow(props) {
  const { item, index, handeClick, setmember, member } = props;

  const handleonselectmember = (key, ID) => {
    let items = member.map((data) => {
      if (data.UUID === ID) {
        data = { ...data, selected: key.target.checked };
      }
      return data;
    });

    setmember(items);
  };

  return (
    <>
      <tr key={index} className="accordion-toggle">
        <td scope="col" className="text-center t_no clickable" colSpan={1}>
          <i
            style={{ margin: '0 auto', float: 'none' }}
            className={item.selected ? 'fa fa-caret-down' : 'fa fa-caret-up'}
            onClick={() => handeClick(item?.UUID)}
          ></i>

          {/* <input
     type="radio"
     name="selectedjob"
     onClick={(e) => handlegroupSelect(e, key)}
   /> */}
        </td>
        <td className="align-left">{item?.Name}</td>
      </tr>
      {item.selected ? (
        <tr>
          <td colSpan="3" className="nested-block">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="align-left" style={{ width: '4.2%' }}></th>
                  <th scope="col" className="align-left" style={{ width: '96.8%' }}>
                    Member Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {member &&
                  member.length > 0 &&
                  member.map((item, key) => {
                    return (
                      <tr key={key}>
                        <th
                          scope="col"
                          className="align-left"
                          style={{
                            borderLeft: '#c0bebe40 2px solid',
                            textAlign: 'center !important'
                          }}
                        >
                          <input
                            type="checkbox"
                            name="selectedjob"
                            defaultValue={item?.selected ? true : false}
                            onClick={(e) => handleonselectmember(e, item?.UUID)}
                          />
                        </th>
                        <td className="align-left">{item?.Name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </td>
        </tr>
      ) : (
        ''
      )}
    </>
  );
}
