import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';
import { setAddSecurityDataResponse } from './SecuritySlice';
import { formatNumber, positiveNumber, roundOff } from '../../pages/common/Misc';
import { defaultValue } from './TaxSlice';
import { calculateAdjustedEbitda } from '../../utils/businessDataEntryCalculations';
// import { calculateDebtServicingForTrust } from '../../utils/businessDataEntryCalculations';

export let financialKeyValuePair = {
  'Trading Income': 'trading-income',
  'Cost of Sales': 'cost-of-sales',
  'Other Income': 'other-income',
  'Interest Income': 'interest-income',
  'Gross Rental': 'gross-rental',
  'Rental Expenses': 'rental-expenses',
  'Operating Expenses': 'operating-expenses',
  'CHP, CM & Leasing Interest': 'chp-cm-leasing-interest',
  'Rental Property Interest': 'rental-property-interest',
  'Other Interest': 'other-interest',
  Amortisation: 'amortisation',
  'Rental Property Depreciation': 'rental-property-depreciation',
  'Other Depreciation': 'other-depreciation',
  'Adjusted EBITDA': 'adjusted-ebitda',
  'Select if Applicable': 'select-if-applicable',
  'Franking Credits Received': 'franking-credits',
  'Franking Credits': 'franking-credits',
  'Add Sensitised Current Rental': 'add-sensitised-current-rental',
  'Add Back Rental Expenses (excl. Interest & Depreciation)': 'add-back-rental-expenses',
  'Less Historical Gross Rental': 'less-historical-gross-rental',
  'Tax Payable on Adjusted Taxable Income': 'tax-payable-adjusted-taxable-income',
  'Less Dividends & Distributions': 'dividend',
  'Less Allocation of Surplus Profit/Loss': 'surplus_profit',
  'Funds Available for Debt Servicing (after Tax)': 'funds-available-debt-tax',
  'Funds available for Debt Servicing (after Tax, Dividends & Distributions)':
    'funds-available-debt-tax-dividends',
  Deductions: 'deductions'
};

export let surplusProfitStructure = {
  key: 'surplus_profit',
  title: 'Less Allocation of Surplus Profit/Loss',
  is_expandable: false,
  items: [
    {
      title: 'Select if Applicable',
      key: 'select-if-applicable',
      category: 'less',
      data: { profit: {}, percentage: {}, type: {} },
      deletable: false,
      checkBox: true
    }
  ],
  totals: {
    key: '',
    title: '',
    data: {}
  }
};

export let defaultBusinessValue = {
  'trading-income': {
    key: 'trading-income',
    title: 'Trading Income',
    is_expandable: true,
    items: [
      {
        title: '',
        data: {},
        deletable: false
      }
    ],
    totals: {
      key: 'total-trading-income-growth',
      title: 'Total Trading Income / % Growth',
      data: {}
    }
  },
  'cost-of-sales': {
    key: 'cost-of-sales',
    title: 'Cost of Sales',
    is_expandable: true,
    items: [
      {
        title: '',
        data: {},
        deletable: false
      }
    ],
    totals: {
      key: 'total-cost-of-sales',
      title: 'Total Cost of Sales',
      data: {}
    }
  },
  'other-income': {
    key: 'other-income',
    title: 'Other Income',
    is_expandable: true,
    items: [
      {
        title: 'Interest Income',
        key: 'interest-income',
        data: {},
        editable: false,
        deletable: false,
        checkBox: true
      },
      {
        title: 'Gross Rental',
        key: 'gross-rental',
        data: {},
        editable: false,
        deletable: false,
        checkBox: true
      },
      {
        title: 'Rental Expenses',
        key: 'rental-expenses',
        data: {},
        editable: false,
        deletable: false,
        checkBox: true
      }
    ],
    totals: {
      key: 'total-other-income',
      title: 'Total Other Income',
      data: {}
    }
  },
  'operating-expenses': {
    key: 'operating-expenses',
    title: 'Operating Expenses',
    is_expandable: true,
    items: [
      {
        title: '',
        data: {},
        deletable: false
      }
    ],
    totals: {
      key: 'total-operating-expenses',
      title: 'Total Operating Expenses',
      data: {}
    }
  },
  ebit: {
    key: 'ebit',
    title: '',
    is_expandable: false,
    items: [
      {
        category: 'add',
        title: 'CHP, CM & Leasing Interest',
        key: 'chp-cm-leasing-interest',
        data: {},
        editable: false,
        deletable: false
      },
      {
        category: 'add',
        title: 'Rental Property Interest',
        key: 'rental-property-interest',
        data: {},
        editable: false,
        deletable: false
      },
      {
        category: 'add',
        title: 'Other Interest',
        key: 'other-interest',
        data: {},
        editable: false,
        deletable: false
      }
      // ,
      // 3: {
      //   category: 'less',
      //   title: 'Franking Credits Received',
      //   data: {}
      // }
    ],
    totals: {
      key: 'total-ebit',
      title: 'EBIT',
      data: {}
    }
  },
  ebitda: {
    key: 'ebitda',
    title: 'EBITDA',
    is_expandable: false,
    items: [
      {
        category: 'add',
        title: 'Amortisation',
        key: 'amortisation',
        data: {},
        editable: false,
        deletable: false
      },
      {
        category: 'add',
        title: 'Rental Property Depreciation',
        key: 'rental-property-depreciation',
        data: {},
        editable: false,
        deletable: false
      },
      {
        category: 'add',
        title: 'Other Depreciation',
        key: 'other-depreciation',
        data: {},
        editable: false,
        deletable: false
      }
    ],
    totals: {
      key: 'total-ebitda',
      title: 'EBITDA',
      data: {}
    }
  },
  'adjusted-ebitda': {
    key: 'adjusted-ebitda',
    title: 'Adjusted EBITDA',
    is_expandable: false,
    items: [
      {
        category: 'add',
        title: 'Select if Applicable',
        key: 'select-if-applicable',
        data: {},
        deletable: false
      },
      {
        category: 'less',
        title: 'Interest Income',
        key: 'interest-income',
        data: {},
        editable: false,
        editableValues: false,
        deletable: false
      },
      {
        category: 'less',
        title: 'Franking Credits Received',
        key: 'franking-credits',
        data: {},
        editable: false,
        deletable: false
      },
      {
        category: 'less',
        title: 'Select if Applicable',
        key: 'select-if-applicable',
        data: {},
        deletable: false
      },
      {
        category: 'rental',
        title: 'Add Sensitised Current Rental',
        key: 'add-sensitised-current-rental',
        data: {},
        editable: false,
        editableValues: false,
        deletable: false
      },
      {
        category: 'rental',
        title: 'Add Back Rental Expenses (excl. Interest & Depreciation)',
        key: 'add-back-rental-expenses',
        data: {},
        editable: false,
        editableValues: false,
        deletable: false
      },
      {
        category: 'rental',
        title: 'Less Historical Gross Rental',
        key: 'less-historical-gross-rental',
        data: {},
        editable: false,
        deletable: false
      }
    ],
    totals: {
      key: 'adjusted-ebitda',
      title: 'Adjusted EBITDA',
      data: {},
      deletable: false
    }
  },
  tax: {
    key: 'tax',
    title: 'Tax Payable on Adjusted Taxable Income',
    is_expandable: false,
    items: [
      {
        title: 'Tax Payable on Adjusted Taxable Income',
        key: 'tax-payable-adjusted-taxable-income',
        data: {},
        editable: false,
        editableValues: false,
        deletable: false
      }
    ]
  },
  dividend: {
    key: 'dividend',
    title: 'Less Dividends & Distributions',
    is_expandable: false,
    items: [
      {
        title: 'Select if Applicable',
        key: 'select-if-applicable',
        category: 'less',
        data: {},
        deletable: false,
        checkBox: true
      }
    ],
    totals: {
      key: '',
      title: '',
      data: {}
    }
  },
  // surplus_profit: surplusProfitStructure,
  'funds-after-tax': {
    key: 'funds-after-tax',
    title: 'Funds Available for Debt Servicing (after Tax)',
    is_expandable: false,
    items: [
      {
        title: 'Funds Available for Debt Servicing (after Tax)',
        key: 'funds-available-debt-tax',
        data: {},
        editable: false,
        deletable: false
      }
    ]
  },
  'funds-available-years': {
    key: 'funds-available-years',
    title: 'Funds available for Debt Servicing (after Tax, Dividends & Distributions)',
    is_expandable: false,
    items: [
      {
        title: 'Funds available for Debt Servicing (after Tax, Dividends & Distributions)',
        key: 'funds-available-debt-tax-dividends',
        data: {},
        editable: false,
        deletable: false
      }
    ]
  }
};
let defaultPersonalValue = {
  'related-party-income': {
    key: 'related-party-income',
    title: 'Related Party Income',
    is_expandable: true,
    items: [
      {
        title: '',
        data: {},
        deletable: false
      }
    ],
    totals: {
      key: 'total-related-party-income',
      title: 'Total Related Party Income',
      data: {}
    }
  },
  'other-income': {
    key: 'other-income',
    title: 'Other Income',
    is_expandable: true,
    items: [
      {
        title: 'Interest Income',
        key: 'interest-income',
        data: {},
        editable: false,
        deletable: false
      },
      {
        title: 'Gross Rental',
        key: 'gross-rental',
        data: {},
        editable: false,
        deletable: false
      },
      {
        title: 'Rental Expenses',
        key: 'rental-expenses',
        data: {},
        editable: false,
        deletable: false
      }
    ],
    totals: {
      key: 'total-other-income',
      title: 'Total Other Income',
      data: {}
    }
  },
  deductions: {
    key: 'deductions',
    title: null,
    is_expandable: true,
    items: [
      {
        title: 'Deductions',
        key: 'deductions',
        data: {},
        deletable: false
      }
    ]
  },
  ebitda: {
    key: 'ebitda',
    title: '',
    is_expandable: false,
    items: [
      {
        category: 'add',
        title: 'CHP, CM & Leasing Interest',
        data: {},
        key: 'chp-cm-leasing-interest',
        deletable: false
      },
      {
        category: 'add',
        title: 'Rental Property Interest',
        key: 'rental-property-interest',
        data: {},
        deletable: false
      },
      {
        category: 'add',
        title: 'Other Interest',
        key: 'other-interest',
        data: {},
        deletable: false
      },
      {
        category: 'add',
        title: 'Amortisation',
        key: 'amortisation',
        data: {},
        deletable: false
      },
      {
        category: 'add',
        title: 'Rental Property Depreciation',
        key: 'rental-property-depreciation',
        data: {},
        deletable: false
      },
      {
        category: 'add',
        title: 'Other Depreciation',
        key: 'other-depreciation',
        data: {},
        deletable: false
      }
      // {
      //   category: 'less',
      //   title: 'Franking Credits Received',
      //   key: 'franking-credits',
      //   data: {},
      //   deletable: false
      // }
    ],
    totals: {
      key: 'total-ebitda',
      title: 'EBITDA',
      data: {}
    }
  },
  'adjusted-ebitda': {
    key: 'adjusted-ebitda',
    title: '',
    is_expandable: false,
    items: [
      {
        category: 'add',
        title: 'Select if Applicable',
        key: 'select-if-applicable',
        data: {},
        deletable: false
      },
      {
        category: 'less',
        title: 'Interest Income',
        key: 'interest-income',
        data: {},
        editable: false,
        editableValues: false,
        deletable: false
      },
      {
        category: 'less',
        title: 'Franking Credits',
        key: 'franking-credits',
        data: {},
        editable: false,
        deletable: false
      },
      {
        category: 'less',
        title: 'Select if Applicable',
        key: 'select-if-applicable',
        data: {},
        deletable: false
      },
      {
        category: 'rental',
        title: 'Add Sensitised Current Rental',
        key: 'add-sensitised-current-rental',
        data: {},
        editable: false,
        editableValues: false,
        deletable: false
      },
      {
        category: 'rental',
        title: 'Add Back Rental Expenses (excl. Interest & Depreciation)',
        key: 'add-back-rental-expenses',
        data: {},
        editable: false,
        editableValues: false,
        deletable: false
      },
      {
        category: 'rental',
        title: 'Less Historical Gross Rental',
        key: 'less-historical-gross-rental',
        data: {},
        editable: false,
        deletable: false
      }
    ],
    totals: {
      key: 'adjusted-ebitda',
      title: 'Adjusted EBITDA',
      data: {}
    }
  },
  tax: {
    key: 'tax',
    title: null,
    is_expandable: false,
    items: [
      {
        title: 'Tax Payable on Adjusted Taxable Income',
        key: 'tax-payable-adjusted-taxable-income',
        data: {},
        editable: false,
        deletable: false
      }
    ]
  },
  'funds-after-tax': {
    key: 'funds-after-tax',
    title: 'Funds Available for Debt Servicing (after Tax)',
    is_expandable: false,
    items: [
      {
        title: 'Funds Available for Debt Servicing (after Tax)',
        key: 'funds-available-debt-tax',
        data: {},
        editable: false,
        deletable: false
      }
    ]
  },
  'funds-available-years': {
    key: 'funds-available-years',
    title: 'Funds available for Debt Servicing (after Tax, Dividends & Distributions)',
    is_expandable: false,
    items: [
      {
        title: 'Funds available for Debt Servicing (after Tax, Dividends & Distributions)',
        key: 'funds-available-debt-tax-dividends',
        data: {},
        editable: false,
        deletable: false
      }
    ]
  }
};

export const financialsInitialState = {
  tax: {},
  add_sensitised_current_rental: '',
  financialDataResponse: {},
  financialData: {},
  years: [],
  year_status: [],
  xerofinancialData: [],
  xeroconnectionData: [],
  xerorganizationData: [],
  updatefinancialXeroData: [],
  xeroAuthData: [],
  xeroRefresh: [],
  xpmRefresh: [],
  taxRateByMember: {},
  deleteFinancial: {},
  dialogOn432Error: ''
};

export const FinancialsSlice = createSlice({
  name: 'financials',
  initialState: financialsInitialState,
  reducers: {
    setFinancialDataResponse: (state, action) => {
      state.financialDataResponse = JSON.parse(JSON.stringify(action.payload));
    },
    setFinancialData: (state, action) => {
      state.financialData = action.payload;

      // let data = JSON.parse(JSON.stringify(state.financialData));
      let years = JSON.parse(JSON.stringify(state.years));

      const response = Object.entries(state.financialData).filter((value) => {
        let item = value;
        if (item[1].key == 'adjusted-ebitda') {
          let array = JSON.parse(JSON.stringify(item[1].items));

          if (array.length > 0) {
            let currentsensitisedIndex = array.findIndex(
              (el) => el.key == 'add-sensitised-current-rental'
            );
            // Add Sensitised Current Rental
            let yearsvalueArray = {};
            years.map((value) => {
              yearsvalueArray[value] = state.add_sensitised_current_rental;
            });
            if (currentsensitisedIndex > -1) {
              array[currentsensitisedIndex] = {
                ...array[currentsensitisedIndex],
                data: yearsvalueArray ? yearsvalueArray : 0
              };
            }
          }

          item[1] = {
            ...item[1],
            items: array
          };
        }

        return item;
      });

      state.financialData = Object.fromEntries(response);

      // state.financialData = action.payload;
    },
    flushFinancials: (state) => {
      state.financialData = {};
    },
    setFinancialTaxData: (state, action) => {
      state.tax = action.payload;
    },
    setYearsData: (state, action) => {
      state.years = action.payload;
    },
    setYearStatusData: (state, action) => {
      state.year_status = action.payload;
    },
    setAddSensetisedData: (state, action) => {
      state.add_sensitised_current_rental = action.payload;
    },
    setTaxRateByMember: (state, action) => {
      state.taxRateByMember = action.payload;
    },
    setInitialTotals: (state, action) => {
      let data = JSON.parse(JSON.stringify(action.payload));

      let keyList = [
        'adjusted-ebitda',
        'cost-of-sales',
        'dividend',
        // 'surplus_profit',
        'ebit',
        'ebitda',
        'operating-expenses',
        'other-income',
        'trading-income',
        'related-party-income'
      ];

      if (
        action.payload.type === 'trust' ||
        action.payload.type === 'partnership' ||
        action.payload.type === 'sole-trader' ||
        action.payload.type === 'other'
      )
        keyList = [...keyList, 'surplus_profit'];

      const years = state.years;
      if (action.payload.type !== 'individual' && action.payload.type !== undefined) {
        data['operating-expenses'].items = data?.['operating-expenses']?.items.map((item) => {
          years.map((value) => {
            if (item.data[value] > 0) {
              item.data[value] = item.data[value] * -1;
            }
          });
          return item;
        });

        let grossRentalIndex = Object.entries(data['other-income'].items).findIndex(
          (item) => item[1].title === 'Gross Rental'
        );
        let index = Object.entries(data['adjusted-ebitda'].items).findIndex(
          (item) => item[1].title === 'Less Historical Gross Rental'
        );
        if (grossRentalIndex > -1 && index > -1) {
          data['adjusted-ebitda'].items[index] = {
            ...data['adjusted-ebitda']?.items[index],
            data: Object.fromEntries(
              Object.entries(data['other-income']?.items[grossRentalIndex].data).map((item) => {
                item[1] = positiveNumber(item[1]) ? positiveNumber(item[1]) * -1 : null;
                return item;
              })
            )
          };
        }

        for (let groupkey of [
          'operating-expenses',
          'other-income',
          'cost-of-sales',
          'trading-income'
        ]) {
          let bundleName = undefined;
          let itemCount = 0;
          let total = {};
          let temp = [];
          let obj = {};
          const setArr = () => {
            for (let item of Object.keys(obj)) {
              temp.push({ [item]: obj[item] });
            }
          };
          const getValue = (val) => {
            return Number(val) ? Number(val) : 0;
          };

          if (!data[groupkey].items1) data[groupkey].items1 = data[groupkey].items;
          for (let item of data[groupkey].items) {
            if (item?.bundleName) {
              // if (bundleName !== undefined) {
              if (bundleName === item?.bundleName) {
                itemCount++;
                for (let el of Object.entries(total)) {
                  total[el[0]] =
                    // Math.round(getValue(el[1])) + Math.round(getValue(item.data[el[0]]));
                    roundOff(getValue(el[1])) + roundOff(getValue(item.data[el[0]]));
                }
              } else {
                if (bundleName) {
                  temp.push({ [null]: [{ data: total, title: bundleName, itemCount }] });
                  bundleName = null;
                  itemCount = 0;
                }
                itemCount = 1;
                bundleName = item?.bundleName;
                total = JSON.parse(JSON.stringify(item.data));
                // }
              }
            } else {
              if (bundleName) {
                temp.push({ [null]: [{ data: total, title: bundleName, itemCount }] });
                bundleName = null;
                itemCount = 0;
              }

              if (!item?.category) {
                obj[item.category ? item.category : null] = [item];
                setArr();
                obj = {};
              } else if (Object.keys(obj).includes(item.category)) {
                obj[item.category ? item.category : null].push(item);
              } else {
                obj[item.category ? item.category : null] = [item];
              }
            }
          }
          if (bundleName) {
            temp.push({ [null]: [{ data: total, title: bundleName, itemCount }] });
            bundleName = null;
            itemCount = 0;
          }
          data[groupkey].items = temp.map((item) => item.null[0]);
        }
      }
      // interest income
      let interestIncomeIndex = Object.entries(data['other-income'].items).findIndex(
        (item) => item[1].key == 'interest-income'
      );

      let adjustedEbitda_interest_income_index = Object.entries(
        data['adjusted-ebitda'].items
      ).findIndex((item) => item[1].key == 'interest-income');

      if (interestIncomeIndex > -1 && adjustedEbitda_interest_income_index > -1) {
        data['adjusted-ebitda'].items[adjustedEbitda_interest_income_index] = {
          ...data['adjusted-ebitda']?.items[adjustedEbitda_interest_income_index],
          data: Object.fromEntries(
            Object.entries(data['other-income']?.items[interestIncomeIndex].data).map((item) => {
              item[1] = positiveNumber(item[1]) ? positiveNumber(item[1]) * -1 : null;

              return item;
            })
          )
        };
      }

      // Tax Payable on Adjusted Taxable Income
      data['tax']['items'] = action?.payload?.data?.tax_payable_on_adjusted_taxable_income
        ? action?.payload?.data?.tax_payable_on_adjusted_taxable_income
        : {};
      // End

      //   Add Sensitised  current Rental
      let sensitisedData = {};
      let currentsensitisedIndex = data?.['adjusted-ebitda']?.['items'].findIndex(
        (el) => el.key == 'add-sensitised-current-rental'
      );
      years.map((value) => {
        sensitisedData[value] = state.add_sensitised_current_rental;
      });
      if (currentsensitisedIndex > -1) {
        data['adjusted-ebitda']['items'][currentsensitisedIndex] = {
          ...data?.['adjusted-ebitda']?.['items']?.[currentsensitisedIndex],
          data: sensitisedData ? sensitisedData : 0
        };
      }

      // Add Back Rental Expenses (excl. Interest & Depreciation)
      let rentalKey = 'ebit';
      if (action.payload.type == 'individual') {
        rentalKey = 'ebitda';
      }
      let RentalExpenseskey =
        data?.['other-income']?.['items'].length > 0 &&
        data?.['other-income']?.['items'].findIndex((el) => el.key == 'rental-expenses');
      let Rentalpropertyinterestkey =
        data?.[rentalKey]?.['items'].length > 0 &&
        data?.[rentalKey]?.['items'].findIndex((el) => el.key == 'rental-property-interest');
      let RentalpropertyDepreciation =
        data?.['ebitda']?.['items'].length > 0 &&
        data?.['ebitda']?.['items'].findIndex((el) => el.key == 'rental-property-depreciation');
      let AddBackRentalExpensesIndex = data?.['adjusted-ebitda']?.['items'].findIndex(
        (el) => el.key == 'add-back-rental-expenses'
      );

      let rentalyear = {};
      years.map((value) => {
        if (
          RentalExpenseskey > -1 &&
          Rentalpropertyinterestkey > -1 &&
          RentalpropertyDepreciation > -1
        ) {
          rentalyear[value] =
            data['other-income']?.['items']?.[RentalExpenseskey]?.['data'][value] * -1 -
            (data?.[rentalKey]?.['items']?.[Rentalpropertyinterestkey]?.['data'][value]
              ? data[rentalKey]?.['items']?.[Rentalpropertyinterestkey]?.['data'][value]
              : 0) -
            (data?.['ebitda']?.['items']?.[RentalpropertyDepreciation]?.['data'][value]
              ? data['ebitda']?.['items']?.[RentalpropertyDepreciation]?.['data'][value]
              : 0);
        }
      });

      data['adjusted-ebitda']['items'][AddBackRentalExpensesIndex] = {
        ...data?.['adjusted-ebitda']?.['items']?.[AddBackRentalExpensesIndex],
        data: rentalyear ? rentalyear : 0
      };

      //  end
      keyList.map((item) => {
        if (Object.keys(data).includes(item)) {
          data[item].totals.data = {};
          years.map((el) => {
            let total = 0;
            if (data[item]?.items?.length > 0) {
              data[item].items.map((elem) => {
                if (item === 'surplus_profit') {
                  if (
                    elem?.data?.profit?.[el] &&
                    Object.keys(elem?.data?.profit).includes(String(el))
                  ) {
                    let totalAdjustedEbitda = calculateAdjustedEbitda(el, data);
                    let dividendTotal = 0;
                    Object.keys(data['dividend'].totals?.data).find((key) => {
                      if (key == el) {
                        dividendTotal = data['dividend'].totals?.data[key];
                      }
                    });

                    let tempdata = totalAdjustedEbitda + Number(dividendTotal);
                    // let tempdata = Math.abs(total) - Math.abs(Number(dividendTotal));
                    // let tempdata = Number(total);
                    let newProfit =
                      elem?.data?.percentage[el] > 0
                        ? (Math.abs(tempdata) * elem?.data?.percentage[el]) / 100
                        : '';
                    if (action.payload.type == 'trust') {
                      elem.data.profit = {
                        ...elem?.data?.profit,
                        [el]: newProfit != '' && tempdata > 0 ? `-${newProfit}` : 0
                      };
                      if (tempdata < 0) {
                        elem.data.percentage = {
                          ...elem?.data?.percentage,
                          [el]: 0
                        };
                      }
                    } else {
                      elem.data.profit = {
                        ...elem?.data?.profit,
                        [el]: newProfit != '' && tempdata > 0 ? `-${newProfit}` : `${newProfit}`
                      };
                    }
                    total +=
                      elem.data.profit[el] !== '-'
                        ? // Math.round(Number(elem.data.profit[el]))
                          roundOff(Number(elem.data.profit[el]))
                        : 0;
                  } else {
                    if (elem?.data?.profit != undefined) elem.data.profit[el] = null;
                    if (elem?.data?.percentage != undefined) elem.data.percentage[el] = 0;
                    if (elem?.data?.type != undefined) elem.data.type[el] = 'add';
                  }
                  if (elem?.data?.type != undefined) {
                    var res = {};
                    let type_obj = elem?.data?.type;
                    Object.keys(type_obj).forEach((key) => {
                      if (type_obj[key] !== null) {
                        res[key] = type_obj[key];
                      }
                    });
                    elem.data.type = res;
                  }
                } else {
                  if (elem?.data && Object.keys(elem?.data).includes(String(el))) {
                    total +=
                      // Math.round(Number(elem.data[el]));
                      roundOff(Number(elem.data[el]));
                  } else {
                    elem.data[el] = null;
                  }
                }
              });
            }
            data[item].totals.data[el] = total;
          });
        }
        let defaultValue = defaultBusinessValue;
        if (
          data.type === 'trust' ||
          data.type === 'partnership' ||
          data.type === 'sole-trader' ||
          data.type === 'other'
        ) {
          if (data.type !== 'trust')
            surplusProfitStructure.title = 'Less Allocation of Surplus Profit/Loss';
          else surplusProfitStructure.title = 'Less Allocation of Surplus Profit/Loss';
          defaultValue = defaultBusinessValue.surplus_profit = surplusProfitStructure;
        }
        if (data.type === 'individual') {
          defaultValue = defaultPersonalValue;
        }
        data[item]?.items.map((el) => {
          let defaultData = defaultValue[item]?.items.find(
            (elem) => elem.key === el.key || financialKeyValuePair[el.title] === elem.key
          );
          if (defaultData) {
            if (Object.keys(defaultData).includes('editableValues')) {
              el.editableValues = defaultData.editableValues;
            }
          }
        });
      });
      setTaxPayable(state, action, data);

      // state.financialData = data;
    },
    updateFinancialData: (state, action) => {
      let data = JSON.parse(JSON.stringify(state.financialData));
      let count = 0;
      if (
        action.payload.key === 'operating-expenses' ||
        action.payload.key === 'trading-income' ||
        action.payload.key === 'cost-of-sales'
      ) {
        for (let i = 0; i < action.payload.index; i++) {
          if (data[action.payload.key].items[i]?.itemCount) {
            count += data[action.payload.key].items[i].itemCount;
          } else {
            count++;
          }
        }
        let val =
          Number(data[action.payload.key].items[action.payload.index].data[action.payload.year]) +
          Number(action.payload.data);
        // let objIndex = data['operating-expenses'].items1.findIndex(
        //   (el) => el.bundleName === data['operating-expenses'].items[action.payload.index].title
        // );

        if (count > -1) {
          if (data[action.payload.key].items1) {
            data[action.payload.key].items1[count].data[action.payload.year] += val;
          } else {
            data[action.payload.key].items1 = JSON.parse(
              JSON.stringify(data[action.payload.key].items)
            );
          }
        }
      }
      if (action.payload.key === 'surplus_profit') {
        data[action.payload.key].items[action.payload.index].member_id = action.payload.member_id
          ? action.payload.member_id
          : data[action.payload.key].items[action.payload.index].member_id &&
            (data[action.payload.key].items[action.payload.index].title != 'Select if Applicable' ||
              data[action.payload.key].items[action.payload.index].title === 'Select if applicable')
          ? data[action.payload.key].items[action.payload.index].member_id
          : '';
        if (
          action.payload.type === 'trust' &&
          calculateAdjustedEbitda(action.payload.year, data) < 0
        ) {
          data[action.payload.key].items[action.payload.index].data.profit = {
            ...data[action.payload.key].items[action.payload.index].data.profit,
            [action.payload.year]: ''
          };
          data[action.payload.key].items[action.payload.index].data.percentage = {
            ...data[action.payload.key].items[action.payload.index].data.percentage,
            [action.payload.year]: ''
          };
          data[action.payload.key].items[action.payload.index].data.type = {
            ...data[action.payload.key].items[action.payload.index].data.type,
            [action.payload.year]: 'add'
          };
        } else if (action.payload.data.profit || action.payload.data.profit === '') {
          if (action.payload.year) {
            data[action.payload.key].items[action.payload.index].data.profit = {
              ...data[action.payload.key].items[action.payload.index].data.profit,
              [action.payload.year]: ''
            };
            data[action.payload.key].items[action.payload.index].data.profit = {
              ...data[action.payload.key].items[action.payload.index].data.profit,
              [action.payload.year]: action.payload.data.profit
                ? String(action.payload.data.profit)
                : action.payload.data.profit
            };
          }
        } else if (
          action.payload.data.percentage ||
          action.payload.data.percentage === '0' ||
          action.payload.data.percentage === 0
        ) {
          if (action.payload.year) {
            data[action.payload.key].items[action.payload.index].data.percentage = {
              ...data[action.payload.key].items[action.payload.index].data.percentage,
              [action.payload.year]: ''
            };
            data[action.payload.key].items[action.payload.index].data.percentage = {
              ...data[action.payload.key].items[action.payload.index].data.percentage,
              [action.payload.year]: action.payload.data.percentage
                ? String(action.payload.data.percentage)
                : action.payload.data.percentage
            };

            // Temp Logic Start
            let profit = 0;
            Object.keys(data[action.payload.key].items[action.payload.index].data?.profit).find(
              (key) => {
                if (key == action.payload.year) {
                  profit = formatNumber(
                    data[action.payload.key].items[action.payload.index].data?.profit[key]
                  );
                }
              }
            );
            console.log('Old Profit: ', profit);
            let total = 0;
            // Object.keys(data['funds-after-tax'].items).find((key) => {
            //   if (key == action.payload.year) {
            //     total = data['funds-after-tax'].items[key];
            //   }
            // });
            total = calculateAdjustedEbitda(action.payload.year, data);
            // total = calculateDebtServicingForTrust(action.payload.year, state.tax, data);
            let dividendTotal = 0;
            Object.keys(data['dividend'].totals?.data).find((key) => {
              if (key == action.payload.year) {
                dividendTotal = data['dividend'].totals?.data[key];
              }
            });
            console.log('Dividend Total: ', dividendTotal);
            let tempdata = total + Number(dividendTotal);
            // let tempdata = Math.abs(total) - Math.abs(Number(dividendTotal));
            // let tempdata = Number(total);
            let newProfit =
              action.payload.data.percentage > 0
                ? (Math.abs(tempdata) * action.payload.data.percentage) / 100
                : '';
            if (action.payload.type === 'trust') {
              if (tempdata > 0) {
                data[action.payload.key].items[action.payload.index].data.profit = {
                  ...data[action.payload.key].items[action.payload.index].data.profit,
                  [action.payload.year]:
                    newProfit != '' && tempdata > 0 ? `-${newProfit}` : `${newProfit}`
                };
              } else {
                data[action.payload.key].items[action.payload.index].data.profit = {
                  ...data[action.payload.key].items[action.payload.index].data.profit,
                  [action.payload.year]: ''
                };
                data[action.payload.key].items[action.payload.index].data.percentage = {
                  ...data[action.payload.key].items[action.payload.index].data.percentage,
                  [action.payload.year]: 0
                };
              }
            } else {
              data[action.payload.key].items[action.payload.index].data.profit = {
                ...data[action.payload.key].items[action.payload.index].data.profit,
                [action.payload.year]:
                  newProfit != '' && tempdata > 0 ? `-${newProfit}` : `${newProfit}`
              };
            }

            let finalTotal = tempdata - Math.abs(newProfit);
            console.log('final Total: ', finalTotal);
            // Temp Logic End

            if (
              data[action.payload.key].items[action.payload.index].data.profit &&
              data[action.payload.key].items[action.payload.index].data.profit[
                action.payload.year
              ] != '-' &&
              data[action.payload.key].items[action.payload.index].data.profit[
                action.payload.year
              ] != null &&
              data[action.payload.key].items[action.payload.index].data.profit[
                action.payload.year
              ] != undefined &&
              !isNaN(
                data[action.payload.key].items[action.payload.index].data.profit[
                  action.payload.year
                ]
              ) &&
              data[action.payload.key].items[action.payload.index].data.profit[
                action.payload.year
              ] != ''
            ) {
              parseInt(
                data[action.payload.key].items[action.payload.index].data.profit[
                  action.payload.year
                ]
              ) < 0
                ? (data[action.payload.key].items[action.payload.index].data.type[
                    action.payload.year
                  ] = 'less')
                : (data[action.payload.key].items[action.payload.index].data.type[
                    action.payload.year
                  ] = 'add');
            } else {
              data[action.payload.key].items[action.payload.index].data.type[action.payload.year] =
                'add';
            }
          } else {
            data[action.payload.key].items[action.payload.index].title = action.payload.data;
            if (
              action.payload.key === 'operating-expenses' ||
              action.payload.key === 'trading-income' ||
              action.payload.key === 'cost-of-sales'
            )
              data[action.payload.key].items1[count].title = action.payload.data;
          }
        } else {
          if (action.payload.year) {
            data[action.payload.key].items[action.payload.index].data = {
              ...data[action.payload.key].items[action.payload.index].data,
              [action.payload.year]: action.payload.data
                ? String(action.payload.data)
                : action.payload.data
            };
          } else {
            data[action.payload.key].items[action.payload.index].title = action.payload.data;
            if (
              action.payload.key === 'operating-expenses' ||
              action.payload.key === 'trading-income' ||
              action.payload.key === 'cost-of-sales'
            )
              data[action.payload.key].items1[count].title = action.payload.data;
          }
        }
      } else {
        if (action.payload.year) {
          data[action.payload.key].items[action.payload.index].data = {
            ...data[action.payload.key].items[action.payload.index].data,
            [action.payload.year]: action.payload.data
              ? String(action.payload.data)
              : action.payload.data
          };
        } else {
          data[action.payload.key].items[action.payload.index].title = action.payload.data;
          if (
            action.payload.key === 'operating-expenses' ||
            action.payload.key === 'trading-income' ||
            action.payload.key === 'cost-of-sales'
          )
            data[action.payload.key].items1[count].title = action.payload.data;
        }

        if (
          action.payload.key === 'surplus_profit' &&
          data['surplus_profit'] &&
          action.payload.year
        ) {
          data[action.payload.key].items[action.payload.index] &&
          data[action.payload.key].items[action.payload.index].data.profit &&
          parseInt(
            data[action.payload.key].items[action.payload.index].data.profit[action.payload.year]
          ) < 0
            ? (data[action.payload.key].items[action.payload.index].data.type[action.payload.year] =
                'less')
            : (data[action.payload.key].items[action.payload.index].data.type[action.payload.year] =
                'add');
        }
      }

      financialCalculation(state, action, data);
    },
    updateFinancialDate: (state, action) => {
      let data = JSON.parse(JSON.stringify(state.financialData));
      data[action.payload.key].items.splice(action.payload.index, 0, action.payload.data);
      if (
        action.payload.key === 'operating-expenses' ||
        action.payload.key === 'trading-income' ||
        action.payload.key === 'cost-of-sales'
      ) {
        let count = 0;
        for (let i = 0; i < action.payload.index; i++) {
          if (data[action.payload.key].items[i]?.itemCount) {
            count += data[action.payload.key].items[i].itemCount;
          } else {
            count++;
          }
        }
        if (Object.keys(data[action.payload.key]).includes('items1')) {
          data[action.payload.key].items1.splice(count, 0, action.payload.data);
        }
      }

      financialCalculation(state, action, data);
    },
    setXerofinancialData: (state, action) => {
      state.xerofinancialData = action.payload;
    },
    setxeroconnectionData: (state, action) => {
      state.xeroconnectionData = action.payload;
    },
    xerorganizationData: (state, action) => {
      state.xerorganizationData = action.payload;
    },
    setxeroAuthData: (state, action) => {
      state.xeroAuthData = action.payload;
    },
    setxeroRefresh: (state, action) => {
      state.xeroRefresh = action.payload;
    },
    setxpmRefresh: (state, action) => {
      state.xpmRefresh = action.payload;
    },
    setdeleteFinancial: (state, action) => {
      state.deleteFinancial = action.payload;
    },
    setDialogOn432Error: (state, action) => {
      state.dialogOn432Error = action.payload;
    }
  }
});

export const deletefinancial = (jobId, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.remove(
    'jobs/' + jobId + '/members/' + memberId + '/financials',
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setdeleteFinancial(response.data));
  }
};

export const getTaxRateByMember = (jobId, memberId, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `tax-rate/${jobId}/${memberId}`,
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setTaxRateByMember(response.data));
  }
};

const financialCalculation = (state, action, data) => {
  let years = JSON.parse(JSON.stringify(state.years));
  let keyList = [
    'adjusted-ebitda',
    'cost-of-sales',
    'dividend',
    // 'surplus_profit',
    'ebit',
    'ebitda',
    'operating-expenses',
    'other-income',
    'trading-income',
    'related-party-income'
  ];

  if (
    action.payload.type === 'trust' ||
    action.payload.type === 'partnership' ||
    action.payload.type === 'sole-trader' ||
    action.payload.type === 'other'
  )
    keyList = [...keyList, 'surplus_profit'];
  if (action.payload.title == 'Gross Rental') {
    let grossRentalIndex = Object.entries(data['other-income'].items).findIndex(
      (item) => item[1].key == 'gross-rental'
    );
    let index = Object.entries(data['adjusted-ebitda'].items).findIndex(
      (item) => item[1].key == 'less-historical-gross-rental'
    );
    if (grossRentalIndex > -1 && index > -1) {
      data['adjusted-ebitda'].items[index] = {
        ...data['adjusted-ebitda']?.items[index],
        data: Object.fromEntries(
          Object.entries(data['other-income']?.items[grossRentalIndex].data).map((item) => {
            item[1] = positiveNumber(item[1]) * -1;
            return item;
          })
        )
      };
    }
  }

  if (
    ['other-income', 'ebit', 'ebitda'].includes(action.payload.key) &&
    [
      'other-income',
      'rental-expenses',
      'rental-property-interest',
      'other-depreciation',
      'interest-income'
    ].includes(action.payload?.key)
  ) {
    let interestIncomeIndex = Object.keys(data).includes('other-income')
      ? Object.entries(data['other-income']?.items).findIndex(
          (item) => item[1].key == 'interest-income'
        )
      : -1;
    let adjustedinterestIncomeIndex = Object.keys(data).includes('adjusted-ebitda')
      ? Object.entries(data['adjusted-ebitda']?.items).findIndex(
          (item) => item[1].key == 'interest-income'
        )
      : -1;
    if (action.payload?.key == 'other-income') {
      if (interestIncomeIndex > -1 && adjustedinterestIncomeIndex > -1) {
        data['adjusted-ebitda'].items[adjustedinterestIncomeIndex] = {
          ...data['adjusted-ebitda']?.items[adjustedinterestIncomeIndex],
          data: Object.fromEntries(
            Object.entries(data['other-income']?.items[interestIncomeIndex].data).map((item) => {
              item[1] = item[1] * -1;

              return item;
            })
          )
        };
      }
    }
  }

  // Less Historical Gross Rental
  let rentalKey = 'ebit';
  if (action.payload.type == 'individual') {
    rentalKey = 'ebitda';
  }

  let RentalExpenseskey =
    data?.['other-income']?.['items'].length > 0 &&
    data?.['other-income']?.['items'].findIndex((el) => el.key == 'rental-expenses');
  let Rentalpropertyinterestkey =
    data?.[rentalKey]?.['items'].length > 0 &&
    data?.[rentalKey]?.['items'].findIndex((el) => el.key == 'rental-property-interest');
  let RentalpropertyDepreciation =
    data?.['ebitda']?.['items'].length > 0 &&
    data?.['ebitda']?.['items'].findIndex((el) => el.key == 'rental-property-depreciation');

  let currentsensitisedIndex = data?.['adjusted-ebitda']?.['items'].findIndex(
    (el) => el.key == 'add-sensitised-current-rental'
  );
  let AddBackRentalExpensesIndex = data?.['adjusted-ebitda']?.['items'].findIndex(
    (el) => el.key == 'add-back-rental-expenses'
  );

  // Add Sensitised Current Rental
  let yearsvalueArray = {};
  let rentalyear = {};
  // data['other-income'].items[RentalExpenseskey].data = Object.fromEntries(
  //   Object.entries(data['other-income'].items[RentalExpenseskey].data).map((item) => {
  //     item[1] = item[1] * -1;
  //     return ite m;
  //   })
  // );
  years.map((value) => {
    yearsvalueArray[value] = state.add_sensitised_current_rental;
    if (
      RentalExpenseskey > -1 &&
      Rentalpropertyinterestkey > -1 &&
      RentalpropertyDepreciation > -1
    ) {
      rentalyear[value] =
        data['other-income']?.['items']?.[RentalExpenseskey]?.['data'][value] * -1 -
        (data?.[rentalKey]?.['items']?.[Rentalpropertyinterestkey]?.['data'][value]
          ? data[rentalKey]?.['items']?.[Rentalpropertyinterestkey]?.['data'][value]
          : 0) -
        (data?.['ebitda']?.['items']?.[RentalpropertyDepreciation]?.['data'][value]
          ? data['ebitda']?.['items']?.[RentalpropertyDepreciation]?.['data'][value]
          : 0);
    }
  });
  if (currentsensitisedIndex > -1) {
    data['adjusted-ebitda']['items'][currentsensitisedIndex] = {
      ...data?.['adjusted-ebitda']?.['items']?.[currentsensitisedIndex],
      data: yearsvalueArray ? yearsvalueArray : 0
    };
  }
  if (AddBackRentalExpensesIndex > -1) {
    data['adjusted-ebitda']['items'][AddBackRentalExpensesIndex] = {
      ...data?.['adjusted-ebitda']?.['items']?.[AddBackRentalExpensesIndex],
      data: rentalyear ? rentalyear : 0
    };
  }

  if (action.payload.year) {
    let year = action.payload.year;
    let sum = 0;
    Object.values(data[action.payload.key]?.items).forEach((item) => {
      if (action.payload.key === 'surplus_profit') {
        if (Number(item?.data?.profit?.[year])) {
          sum += Number(item.data?.profit?.[year]);
        }
      } else {
        if (Number(item?.data?.[year])) {
          sum += Number(item.data[year]);
        }
      }
    });
    if (
      Object.keys(JSON.parse(JSON.stringify(data))).includes(action.payload.key) &&
      JSON.parse(JSON.stringify(data))[action.payload.key]?.totals &&
      !Object.keys(JSON.parse(JSON.stringify(data))[action.payload.key]?.totals).includes('data')
    ) {
      data[action.payload.key].totals = {
        data: {
          [year]: sum
        }
      };
    } else if (data[action.payload.key].totals) {
      data[action.payload.key].totals.data[year] = sum;
    }
  }

  // Duplicate function (same used below search for duplicate)
  keyList.map((item) => {
    if (Object.keys(data).includes(item)) {
      data[item].totals.data = {};
      years.map((el) => {
        let total = 0;
        if (data[item]?.items?.length > 0) {
          data[item].items.map((elem) => {
            if (item === 'surplus_profit') {
              if (
                elem?.data?.profit?.[el] &&
                Object.keys(elem?.data?.profit).includes(String(el))
              ) {
                total += elem.data.profit[el] !== '-' ? Number(elem.data.profit[el]) : 0;
                elem.data.type = {
                  ...elem.data.type,
                  [el]: elem.data.profit[el] < 0 ? 'less' : 'add'
                };
              } else {
                elem.data.profit[el] = null;
                elem.data.type && elem.data.type[el] ? (elem.data.type[el] = 'add') : '';
              }
            } else {
              if (elem?.data?.[el] && Object.keys(elem?.data).includes(String(el))) {
                total += Number(elem.data[el]);
              } else {
                elem.data[el] = null;
              }
            }
          });
        }
        data[item].totals.data[el] = total;
      });
    }
  });

  if (action.payload.key !== 'surplus_profit') {
    let temp = calculateAdjustedEbitda(action.payload.year, data);
    let percentage = 0;
    let dividendTotal = 0;
    data['dividend'] &&
      Object.keys(data['dividend'].totals?.data).find((key) => {
        if (key == action.payload.year) {
          dividendTotal = data['dividend'].totals?.data[key];
        }
      });
    let tempdata = temp + Number(dividendTotal);

    data['surplus_profit'] &&
      data['surplus_profit'].items.map((el, index) => {
        // let tempdata = temp;
        Object.keys(el.data?.profit).find((key) => {
          if (key == action.payload.year) {
            if (action.payload.type === 'trust' && tempdata < 0) {
              el.data.profit = {
                ...el.data.profit,
                [action.payload.year]: ''
              };
              el.data.percentage = {
                ...el.data.percentage,
                [action.payload.year]: 0
              };
              el.data.type = {
                ...el.data.type,
                [action.payload.year]: 'add'
              };
            } else {
              if (action.payload.type === 'trust') {
                percentage =
                  tempdata > 0 ? data['surplus_profit']?.items[index]?.data?.percentage[key] : 0;
                let newProfit = percentage > 0 ? (tempdata * percentage) / 100 : '';
                el.data.profit = {
                  ...el.data.profit,
                  [action.payload.year]: newProfit
                    ? tempdata > 0
                      ? `-${newProfit}`
                      : `${newProfit}`
                    : 0
                };
              } else if (
                action.payload.type === 'partnership' ||
                action.payload.type === 'sole-trader' ||
                action.payload.type === 'other'
              ) {
                percentage = data['surplus_profit']?.items[index]?.data?.percentage[key];
                let newProfit = percentage > 0 ? (Math.abs(tempdata) * percentage) / 100 : '';
                el.data.profit = {
                  ...el.data.profit,
                  [action.payload.year]: newProfit
                    ? tempdata > 0
                      ? `-${newProfit}`
                      : `${newProfit}`
                    : 0
                };
              }
            }
          }
        });
      });
  }
  // }

  // Duplicate function (same used above search for duplicate)
  keyList.map((item) => {
    if (Object.keys(data).includes(item)) {
      data[item].totals.data = {};
      years.map((el) => {
        let total = 0;
        if (data[item]?.items?.length > 0) {
          data[item].items.map((elem) => {
            if (item === 'surplus_profit') {
              if (
                elem?.data?.profit?.[el] &&
                Object.keys(elem?.data?.profit).includes(String(el))
              ) {
                total += elem.data.profit[el] !== '-' ? Number(elem.data.profit[el]) : 0;
                elem.data.type = {
                  ...elem.data.type,
                  [el]: elem.data.profit[el] < 0 ? 'less' : 'add'
                };
              } else {
                elem.data.profit[el] = null;
                elem.data.type && elem.data.type[el] ? (elem.data.type[el] = 'add') : '';
              }
            } else {
              if (elem?.data?.[el] && Object.keys(elem?.data).includes(String(el))) {
                total += Number(elem.data[el]);
              } else {
                elem.data[el] = null;
              }
            }
          });
        }
        data[item].totals.data[el] = total;
      });
    }
  });

  setTaxPayable(state, action, data);
  // state.financialData = data;
};

const setTaxPayable = (state, action, data) => {
  let datas = JSON.parse(JSON.stringify(state.financialDataResponse));
  datas.data.data.tax_data = datas?.data?.data?.tax_data?.['adjusted-tax-income']
    ? datas.data.data.tax_data
    : defaultValue;

  let tax_rate =
    action.payload.type !== 'individual'
      ? datas?.data?.data?.tax_data?.['adjusted-tax-income']?.tax_rate &&
        JSON.parse(JSON.parse(JSON.stringify(state.taxRateByMember)).data.options).find(
          (el) => el?.value == datas?.data?.data?.tax_data?.['adjusted-tax-income']?.tax_rate
        ) != undefined
        ? datas.data.data.tax_data['adjusted-tax-income'].tax_rate
        : JSON.parse(JSON.parse(JSON.stringify(state.taxRateByMember)).data.options).find(
            (el) => el.is_default == 1
          ).value
      : 0;

  data['tax']['tax_rate'] = tax_rate;

  state.financialData = { ...data };
};

export const deleteRecord = (data) => async (dispatch) => {
  dispatch(setInitialTotals(data));
};

export const flushFinancialData = () => async (dispatch) => {
  dispatch(flushFinancials());
};

export const getFinancialData = (id, memberId, type, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/members/' + memberId + '/financials',
    setErrors,
    dispatch,
    false
  );
  if (response) {
    dispatch(setFinancialDataResponse(response));
  }
  if (response.data.data.years) {
    dispatch(setAddSensetisedData(response.data.data.add_sensitised_current_rental));
    dispatch(setYearsData(response.data.data.years));
    dispatch(setYearStatusData(response.data.data.year_status));
    if (response.data.data.data) {
      let finalData;
      if (Object.entries(response.data.data.data).length === 0) {
        if (type === 'individual') {
          finalData = defaultPersonalValue;
        } else {
          if (
            type === 'trust' ||
            type === 'partnership' ||
            type === 'sole-trader' ||
            type === 'other'
          ) {
            defaultBusinessValue.surplus_profit = surplusProfitStructure;
            finalData = defaultBusinessValue;
          } else {
            finalData = defaultBusinessValue;
          }
          // if (type === 'trust' || type === 'partnership' || type === 'sole-trader')
        }
      } else {
        // let obj = {};
        // const getObj = (defaultValue) => {
        //   for (let item of Object.keys(defaultValue)) {
        //     if (item in Object.keys(response.data.data.data)) {
        //       obj[item] = response.data.data.data[item];
        //     } else {
        //       obj[item] = defaultValue[item];
        //     }
        //   }
        // };
        // if (type === 'individual') {
        //   getObj(defaultPersonalValue);
        // } else {
        //   getObj(defaultBusinessValue);
        // }
        // dispatch(setFinancialData(obj));
        // dispatch(setInitialTotals({ ...{ ...response.data, data: obj }, type }));

        finalData = response.data.data.data;

        finalData = { ...finalData };
      }
      // operations for change title member wise

      let DefaultData = JSON.parse(JSON.stringify(finalData));

      //  checking key existing or not

      for (let item of Object.values(DefaultData)) {
        if (item?.items?.length > 0) {
          for (let el of item.items) {
            if (!Object.keys(el).includes('key')) {
              el.key = financialKeyValuePair[el.title];
            }
          }
        }
      }

      let taxData = JSON.parse(JSON.stringify(response.data.data.tax_data));
      for (let item of Object.values(taxData)) {
        if (item?.items?.length > 0) {
          for (let el of item.items) {
            if (!Object.keys(el).includes('key')) {
              el.key = financialKeyValuePair[el.title];
            }
          }
        }
      }
      DefaultData['interest_pa'] = response?.data?.data?.interest_pa
        ? response.data.data.interest_pa
        : 0;
      if (DefaultData) {
        DefaultData['ebitda']['title'] = 'EBITDA';
        DefaultData['adjusted-ebitda'].title = 'Adjusted EBITDA';
        DefaultData['tax'].title = 'Tax Payable on Adjusted Taxable Income';
      }
      if (type != 'individual') {
        DefaultData.ebit.title = 'EBIT';
        DefaultData.dividend.title = 'Less Dividends & Distributions';
      }

      if (DefaultData['adjusted-ebitda']['items']) {
        let frankingIndex = DefaultData['adjusted-ebitda']['items'].findIndex(
          (el) => el.key == 'franking-credits'
        );
        if (frankingIndex > -1)
          DefaultData['adjusted-ebitda']['items'][frankingIndex]['title'] = 'Tax Credits Received';
      }
      if (type == 'company') {
        DefaultData.dividend.title = 'Less Dividends';
        DefaultData['tax'].title = 'Tax Payable/Refundable on Adjusted Taxable Income';
        DefaultData['funds-available-years'] = {
          ...DefaultData['funds-available-years'],
          title: 'Funds available for Debt Servicing (after Tax & Dividends)'
        };
      }
      if (type == 'partnership' || type == 'sole-trader') {
        DefaultData.dividend.title = 'Less Share of Profit/Loss';
        if (type == 'partnership') {
          if (DefaultData.surplus_profit != undefined)
            DefaultData.surplus_profit.title = 'Less Allocation of Surplus Profit/Loss';
          else DefaultData.surplus_profit = surplusProfitStructure;
        }

        DefaultData['funds-available-years'] = {
          ...DefaultData['funds-available-years'],
          title: 'Funds available for Debt Servicing (after Share of Profit/Loss)'
        };
        DefaultData['funds-after-tax'].title = 'Remaining Share of Profit/Loss';
        DefaultData['tax'].title = 'Tax Payable/Refundable on Remaining Undistributed Profit/Loss';
      }

      if (type == 'trust' || type == 'other') {
        DefaultData['funds-after-tax'].title = 'Remaining Undistributed Profit/Loss';
        DefaultData['tax'].title = 'Tax Payable/Refundable on Remaining Undistributed Profit/Loss';
        DefaultData.dividend.title = 'Less Distributions as per Financials';
        if (
          type === 'trust' ||
          type === 'partnership' ||
          type === 'sole-trader' ||
          type === 'other'
        ) {
          if (
            DefaultData.surplus_profit != undefined &&
            DefaultData.surplus_profit?.items?.length > 0
          )
            if (type !== 'trust')
              DefaultData.surplus_profit.title = 'Less Allocation of Surplus Profit/Loss';
            else DefaultData.surplus_profit.title = 'Less Allocation of Surplus Profit/Loss';
          else DefaultData.surplus_profit = surplusProfitStructure;

          // if (DefaultData.surplus_profit != undefined)
          //   DefaultData.surplus_profit.title = 'Less Allocation of Surplus Profits';
          // else {
          //   DefaultData['surplus_profit'] = {
          //     ...DefaultData['surplus_profit'],
          //     surplusProfitStructure
          //   };
          // }
        }
        DefaultData['funds-available-years'] = {
          ...DefaultData['funds-available-years'],
          title: 'Funds available for Debt Servicing (after Tax & Distributions)'
        };
      } else {
        DefaultData['tax'].title = 'Tax Payable on Adjusted Taxable Income';
      }

      dispatch(setInitialTotals({ ...DefaultData, type }));
      // dispatch(setFinancialData(DefaultData));
      dispatch(setFinancialTaxData(taxData));
    }
  }
};

export const updateFinancials =
  (jobId, memberId, financials, data, setErrors) => async (dispatch) => {
    data.financial = { ...financials };
    const response = await clientService.put(
      'jobs/' + jobId + '/members/' + memberId + '/financials',
      { ...data, financial: { ...financials } },
      setErrors,
      dispatch
    );

    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setAddSecurityDataResponse(response.data));
    }
  };

export const editLayout = (jobId, memberId, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + jobId + '/members/' + memberId + '/financials',
    data,
    setErrors,
    dispatch
  );

  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    // dispatch(setAddSecurityDataResponse(response.data));
  }
};

export const getxero = (setErrors) => async (dispatch) => {
  const response = await clientService.get('manage/xero', setErrors, dispatch, false);
  if (response) {
    return response;
  }
};

export const getxpm = (type, setErrors) => async (dispatch) => {
  const response = await clientService.get('manage/xpm?type=' + type, setErrors, dispatch, false);
  if (response) {
    return response;
  }
};

export const postxpm = (code, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'xpm/oauthredirct',
    { code },
    setErrors,
    dispatch,
    false,
    true
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    return response;
    // dispatch(setXerofinancialData(response.data));
  }
};

export const xpmconnection = (setErrors) => async (dispatch) => {
  const response = await clientService.get('xpm/connection', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    return response;
    // dispatch(setxeroconnectionData(response.data));
  }
};

export const xeroconnection = (setErrors) => async (dispatch) => {
  const response = await clientService.get('xero/connection', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setxeroconnectionData(response.data));
  }
};

export const xerorganization = (setErrors) => async (dispatch) => {
  const response = await clientService.get('xero/tenant-data', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(xerorganizationData(response.data));
  }
};

export const getxerofinancialdata =
  (jobId, memberId, tenantId, month, year, setErrors) => async (dispatch) => {
    const response = await clientService.get(
      'jobs/' + jobId + '/members/' + memberId + '/xero/financial?month=' + month,
      setErrors,
      dispatch,
      false,
      true,
      { 'xero-tenant-id': tenantId }
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      if (response?.data) {
        dispatch(setXerofinancialData(response.data));
      }
    }
  };

export const reimportXerofinancialdata =
  (jobId, memberId, tenantId, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      'jobs/' + jobId + '/members/' + memberId + '/xero/financial',
      {},
      setErrors,
      dispatch,
      false,
      true,
      { 'xero-tenant-id': tenantId }
    );
    if (typeof response === 'string') {
      dispatch(setErrorMessage(response));
    } else {
      dispatch(setXerofinancialData(response.data));
    }
  };

export const updateFinancialsxero = (jobId, memberId, data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    'jobs/' + jobId + '/members/' + memberId + '/xero/financial-rearrange',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    return response.data;
  }
};

export const xeroAuth = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('xero/oauthredirct', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setxeroAuthData(response.data));
  }
};

export const refreshXero = (setErrors) => async (dispatch) => {
  const response = await clientService.post('xero/refresh', {}, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setxeroRefresh(response.data));
  }
};

export const refreshXpm = (setErrors) => async (dispatch) => {
  const response = await clientService.post('xpm/refresh', {}, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setxpmRefresh(response.data));
  }
};

export const {
  setFinancialDataResponse,
  setFinancialData,
  flushFinancials,
  updateFinancialDate,
  updateFinancialData,
  setYearsData,
  setAddSensetisedData,
  setYearStatusData,
  setFinancialTaxData,
  setXerofinancialData,
  setxeroconnectionData,
  xerorganizationData,
  setxeroAuthData,
  setxeroRefresh,
  setxpmRefresh,
  setInitialTotals,
  setTaxRateByMember,
  setdeleteFinancial,
  setDialogOn432Error
} = FinancialsSlice.actions;

export const financialsState = (state) => {
  return state.financials;
};

export default FinancialsSlice.reducer;
