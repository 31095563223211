const label = {
  // ------------- Living Expenses ----------- //
  livingExpenses: {
    lifeAdjTip: {
      key: 'lifeAdjTip',
      label: 'Lifestyle Adjustment +/- $ p.a.',
      hover_text:
        'Where required, increase living\nexpenses to reflect income and lifestyle (e.g. a lavish lifestyle)'
    }
  },

  // ------------- Business Overview -------------------- //
  businessOverview: {
    individualTip: {
      key: 'individualTip',
      label: 'Individuals Involved',
      hover_text: 'Past employment history, qualifications,\ncurrent role and key responsibilities.'
    },
    activityTip: {
      key: 'activityTip',
      label: 'Activity',
      hover_text:
        'Details of major activities, core products\nand services sold, end users of these,\nand target market of the business.'
    },
    historyTip: {
      key: 'historyTip',
      label: 'History',
      hover_text:
        'Brief history of the business, including any key\nchanges in ownership, management, core\nbusiness or new products & services, new\nmarkets, additional locations, etc.\n\nFor example:\n\n- How has business growth been\n achieved? (Organic growth via repeat\n work from existing satisfied clients,\n referrals from existing clients, acquisition\n or both, etc.)\n\n- How is the business marketed\n and promoted? (Website, social\n media, advertising, etc.)\n\n- Details of any industry awards or major\n achievements.'
    },
    customerTip: {
      key: 'customerTip',
      label: 'Customers',
      hover_text:
        'Details of major customers, including how\nlong a customer, location, trade terms offered, percentage\nof total sales, and basis of sales (i.e.\ncontractual, one-off or ongoing from new\nand existing clients).'
    },
    supplierTip: {
      key: 'supplierTip',
      label: 'Suppliers',
      hover_text:
        'Where business premises are rented,\ncomment on the suitability of the premises,\nlength of tenancy, lease expiry date,\nfurther options, bond and current annual rent.\n\nDetails of major suppliers, how long a\nsupplier, location, trade terms received,\nand percentage of total purchases.\n\nWhat is the extent of alternative sources\nof key suppliers for the business (i.e.\nreadily available or limited.)?\n\nWhat are the individual timelines from\nwhen the order is placed, to goods\nshipped, delivered, warehoused, sold and\ndebtors collected, etc.?\n\nWhat is the basis for stock purchases\n(i.e. firm customer orders, historic client\nbuying patterns or consignment, etc.)?'
    },
    competitorTip: {
      key: 'competitorTip',
      label: 'Competitors',
      hover_text: 'Details of major competitors.'
    },
    competitiveTip: {
      key: 'competitiveTip',
      label: 'Competitive Advantage',
      hover_text:
        'What is the business’s competitive\nadvantage or unique customer value\nproposition (i.e. critical factors for\nsustained success)?\n\nInclude details of any other key success\nfactors (i.e. brand name, reputation in\n industry, market awareness, client loyalty,\nstrategic alliances).'
    },
    employeeTip: {
      key: 'employeeTip',
      label: 'Employees',
      hover_text:
        'Examples of employee details:\n\n- Number of employees (full-time, part-\ntime and casual).\n\n- Details of any key employees.\n\n- Are there any special qualifications\n needed to work in the business or\n industry?'
    },
    industryTip: {
      key: 'industryTip',
      label: 'Industry',
      hover_text:
        'Brief overview of the industry.\n\nWhat are the biggest threats and challenges\n(Political influence, technological advancements,\ndigitalisation, new imported products, etc.)?\n\nIs the business exposed to material exchange\nrate risk? How is the risk managed?'
    }
  },

  // -------------- Facilities -------------------- //
  facilities: {
    existingTip: {
      key: 'existingTip',
      label: 'Existing Limit $',
      hover_text:
        'The existing limit is the current loan\nbalance owing plus any undrawn\namount / headroom at the time of\nthe anticipated settlement date.'
    },
    originalTip: {
      key: 'originalTip',
      label: 'Original Limit $',
      hover_text:
        'The original limit is the current loan\nbalance owing plus any undrawn\namount / headroom at the time of\nthe anticipated settlement date.'
    },
    currentBalanceTip: {
      key: 'currentBalanceTip',
      label: 'Current Balance $',
      hover_text:
        'The current balance is the loan balance\nowing at the time of the anticipated\nsettlement date for the proposed transaction..'
    },
    proposedLimitTip: {
      key: 'proposedLimitTip',
      label: 'Proposed Limit $',
      hover_text:
        'The proposed limit is the total funds\navailable, including drawn and undrawn\namounts.'
    },
    lineTip: {
      key: 'lineTip',
      label: 'Line / Facility Fee % p.a. (if applicable)',
      hover_text:
        'In addition to the interest rate, some\ncommercial facilities have a line/facility\nfee which is a percentage charge\non the facility limit.\n\nThe interest rate and line/facility rate are\ncombined to calculate the debt servicing\ncommitment. Care needs to be taken\nto ensure that any line/facility charges\nare not omitted.'
    },
    otherFeesTip: {
      key: 'otherFeesTip',
      label: 'Other Fees (if applicable)',
      hover_text:
        'Other fees that are payable to the lender\nare recorded for loan comparison\npurposes.\n\nThese fees are not included in the debt\nservicing calculations.'
    },
    expiryTip: {
      key: 'expiryTip',
      label: 'Initial Fixed Rate or Interest Only Expiry Date (if applicable)',
      hover_text:
        "Record the expiry date here where a\nfacility has an initial fixed interest rate\nperiod or interest-only period.\n\nIt is important to be aware of this date, as\nthe lender may review the facility and the\nborrower's position.\n\nChange in repayments and interest rate\nmay impact the borrowers debt servicing\nposition.\n\nIn both cases, it is important to monitor\nthis date with most lenders undertaking a\nreview 3 to 6 months before the expiry\ndate."
    },
    repayTermTip: {
      key: 'repayTermTip',
      label: 'Repayment Term in Months',
      hover_text:
        'For existing facilities, this is the original\nloan term, not the remaining loan term'
    },
    originalRepayTermTip: {
      key: 'originalRepayTermTip',
      label: 'Original Repayment Term in Months',
      hover_text:
        'For existing facilities, this is the original\nloan term, not the remaining loan term'
    },
    proposedTermTip: {
      key: 'proposedTermTip',
      label: 'Proposed Term in Months',
      hover_text:
        'For existing facilities, this is the original\nloan term, not the remaining loan term'
    },
    remainingTip: {
      key: 'remainingTip',
      label: 'Term Remaining in Months',
      hover_text:
        "For facilities expiring in the near future,\nthe lender may need to be alerted that\nthe borrower's debt servicing position and\nrisk profile will improve, or action may be\nrequired to refinance any residual debt."
    }
  },

  // -------------- Security ------------------ //

  security: {
    marketValueTip: {
      key: 'marketValueTip',
      label: 'Market Value $',
      hover_text:
        'Take care to ensure that the market value\nis realistic and achievable.\n\nOverstated security market values may\ndelay the loan approval process, create\nfalse expectations of borrowing capacity,\nand harm trust and credibility with\nlenders.'
    },
    shadingTip: {
      key: 'shadingTip',
      label: 'Shading %',
      hover_text:
        'A shading percentage is applied to the market\nvalue to reflect the risk of the asset being\nprovided as security.\n\nThe higher perceived risk of the asset,\nthe lower the shading percentage to reflect the\nneed for increased owners equity.\n\nShading percentage varies from lender to lender,\nfrom transaction to transaction, and\nchanges frequently.'
    },
    landingValueTip: {
      key: 'landingValueTip',
      label: 'Lending Value $',
      hover_text:
        'The lending value is the estimated\nmaximum loan amount that the lender is\nprepared to provide against the asset\nprovided as security.\n\nThe lending value is calculated by\nmultiplying the market value by the\nshading percentage.'
    },
    securityTip: {
      key: 'securityTip',
      label: 'Security Owner',
      hover_text: 'The security owner can be different to the\nborrower(s).'
    },
    securityAddressTip: {
      key: 'securityAddressTip',
      label: 'Security Address',
      hover_text:
        'Include the address details for real\nproperty security only.\n\nDo not include address details for\nbalance sheet or other assets.'
    },
    facilityPurposeTip: {
      key: 'facilityPurposeTip',
      label: 'Purpose',
      hover_text:
        'For existing facilities and those to be refinanced, the\npurpose should succinctly describe what the funds\nwere originally used for.\n\nFor new facilities, the purpose should succinctly\ndescribe what the funds will be used for.'
    },
    facilityStatusTip: {
      key: 'facilityStatusTip',
      label: 'Facility Status',
      hover_text:
        'Select existing facility status where there are no changes to be made to the facility.\n\nSelect new facility status where it is neither an existing facility, variation or refinance.\n\nSelect variation status where where the lender remains unchanged but some of the terms of\nthe existing facility are to be varied, e.g. limit, interest rate, repayment amount, repayment frequency, etc.\n\nSelect refinance status where an existing facility is to be paid out and funded by a new lender.'
    }
  },

  // ------------ Unencumbered Property  -----------------//

  unencumberedProperty: {
    marketUnencumberedTip: {
      key: 'marketUnencumberedTip',
      label: 'Market Value $',
      hover_text:
        'Take care to ensure that the market value\nis realistic and achievable.\n\nOverstated security market values may\nharm trust and credibility with lenders.'
    }
  },

  // ----------- Rental ------------------//

  rental: {
    optionTip: {
      key: 'optionTip',
      label: 'Option',
      hover_text: 'Number of options and number of years.'
    },
    currentRentalTip: {
      key: 'currentRentalTip',
      label: 'Current Rental Income (Ex GST) $ p.a.',
      hover_text: 'Exclude any recoverable outgoings'
    },
    residentialShadingTip: {
      key: 'residentialShadingTip',
      label: 'Shading %',
      hover_text:
        'A shading percentage is used to sensitise the\ncurrent rental income to allow for outgoings and\npotential vacancy periods, to the specific\nlender’s requirements.'
    },
    commercialShadingTip: {
      key: 'commercialShadingTip',
      label: 'Shading %',
      hover_text:
        'A shading percentage is used to sensitise the current rental income to allow for\nnon-recoverable outgoings and potential vacancy periods, to the specific\nlender’s requirements.'
    }
  },

  // ----------- Group F&S ------------------//
  groupFS: {
    reliance: {
      key: 'reliance',
      label: 'Insert Reliance',
      hover_text:
        'A group of facilities and securities may result in a security deficit (overlent position) or security surplus (have headroom), in which case:\n  - Reliance may be required from security in another group (or external party) to make up for the security deficit (overlent position), or\n  - Reliance may be allocated from a group with a security surplus (with headroom) to another group (or external party) to address a security deficit\n\nIn reviewing a borrower’s position, look for any overlent positions and seek to address the deficits with surpluses from other groups or external parties (where applicable).'
    }
  }
};

export default label;
