const constants = {
  // Members
  mHeader:
    'Include all key individuals, trading entities and members with material assets within the group and other entities relevant to the proposal.',
  mTag: 'For example:',
  mOption1: '- Exclude non-trading and dormant members with insignificant assets.',
  mOption2:
    '- Include SMSFs with unencumbered real property assets or other substantial assets to demonstrate to the lender the clients overall wealth.',
  mOption3:
    '- Take care to ensure all details are correct. Lenders are very pedantic and sensitive to the accuracy of this information.',

  // Living Expenses
  livingEx1:
    'The living expense rates in this calculator should reflect the lowest average expenses acceptable by the lenders.',
  livingEx2:
    'Generally speaking, the expectation of lenders is the higher the household income, the higher the living expenses. ',

  // Business OverView
  business1: 'The level of information provided should reflect the complexity of the proposal.',
  business2:
    'Where possible, limit responses to minimum detail, to avoid overloading the reviewer.',

  // Facilities
  facilities1: 'Take care to ensure all loan facilities of each member of the group are listed.',
  facilities2:
    'Lenders do not look favorably on a proposal with undisclosed facilities (whether intentional or unintentional).',
  facilities3:
    'However, operating leases and rental arrangements that are included in the operating expenses should not be listed here.',

  // Security
  security1:
    'Take care to ensure that all the security is recorded and that the details are accurate, in particular the security ownership and address.',
  security2: 'Inaccuracies can result in a loss of trust and credibility with lenders.',
  security3:
    'Where security is highly specialised, or has unusual characteristics or risks, ensure these are noted and mitigated.',

  // Unencumbered - Property
  unencumbered1: 'Only include unencumbered real property in this section.',
  unencumbered2:
    "This provides an opportunity to demonstrate to the lender the client's overall wealth (not necessarily to offer the unencumbered property as security).",
  unencumbered3:
    'Unencumbered real property assets held by SMSFs may also be included where relevant.',

  // Unencumbered - Add Property
  unencumberedText1: 'Only include unencumbered real property in this section. ',
  unencumberedText2:
    "This provides an opportunity to demonstrate to the lender the client's overall wealth (not necessarily to offer the unencumbered property as security). ",
  unencumberedText3:
    'Unencumbered real property assets held by SMSFs may also be included where relevant.',

  // Financial
  fHeader:
    'The purpose of this section is to establish the funds available for debt servicing after tax, dividends and distributions, and highlight key factors and trends.',
  fTag: 'This requires:',
  fOption1:
    '- Establishing EBITDA and then identifying all relevant adjustments to normalise the earnings to arrive at adjusted EBITDA (the cash profit excluding movements in working capital).',
  fOption2:
    '- Ignoring the historical tax paid, and deducting at the current rate of tax for all years.',
  fOption3:
    '- Deducting any profit distributions to arrive at the cash profit after dividends and distributions which is available for debt servicing.',
  fTag2:
    'Where applicable any material items relevant to the proposal should be included in the Report Comments.',

  // Rental-List
  rentalCommercial1:
    'Include details of all commercial rental properties (including related party rentals).',
  rentalCommercial2: 'Ensure that all details are correct (i.e. verify with lease agreements).',

  // Add Rental
  rentalResidential1: 'Include details of all residential rental properties. ',
  rentalResidential2:
    'Ensure that current rental income is correct (i.e. verify with lease agreements or latest real estate agent rental statements).',

  // Tax
  tax: 'The purpose of the tax calculations is to establish the tax payable on Adjusted Taxable Income at the current tax rate for all years.',

  //  Guidance & Direction
  guidance1:
    'This section is only required when the borrower requires guidance and direction from lenders regarding the likely parameters and appetite prior to formal submission.',
  guidance2:
    "This may include how best to structure the proposal and is particularly useful for complex deals, where the lender's view of the proposal is uncertain or a collaborative approach may be beneficial.",
  guidance3: 'The standard wording is to be amended as required to suit the proposal.',

  // Proposed Transaction
  proposed_transaction1: 'Provide breakdown of the transaction and how it is to be funded.',
  proposed_transaction2:
    'In the report comments, note any unusual elements that would be relevant to the lender.',
  proposed_transaction3:
    "For example, comment on highly specialised asset purchases, transactions out of the ordinary scope of the borrower's activities and competencies, activity that represents material change, activity that is disruptive and unproven, etc.",
  proposed_transaction4:
    'It is important to ensure that lenders don’t form their own view of the transaction without an appropriate understanding of the transaction and its merit.',
  proposed_transaction5:
    'Where there is more than one major transaction (e.g. business acquisition and new home purchase) add a new transaction.',
  source_of_fund: 'Provide details of how the proposed transaction is to be funded.',
  application_of_funds: 'Provide details of how the proposed funds are being used.',
  associated_transaction: 'Provide details of associated transaction.',
  associated_transaction_info:
    'Add details of any associated asset sale required for the proposed transaction to proceed.',

  // Proposed Group Facility & Security
  group_fands:
    'Review for any over-lent security positions and address the deficits with surpluses from other groups or external parties (where applicable), or seek other mitigation strategies and comment.',

  // Recommandations
  recommandation1:
    'The purpose of this section is to assist borrowers who need to undertake corrective action to obtain finance or to improve their facility structure, pricing, terms & conditions, etc.',
  recommandation2:
    'Often a borrowers willingness to implement change and improve their business management is when borrowings are conditional on this.',

  // Overall Assessment
  overall_assessment1:
    'Complete the overall assessment of the borrowers bankability from a Lender’s perspective based on the key criteria provided.',
  overall_assessment2:
    'This assessment is for internal purposes and discussion with the borrower where applicable and is not to be provided to the Lender.',
  groupserviceability:
    'Review for marginal surplus or any deficit and provide mitigation strategies and comments.',
  guid_abn: 'da8261fe-c566-4db3-91e2-8cad45934200'
};

export default constants;
