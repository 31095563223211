import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { authState, doLogout, setLogoutResponse } from '../../slices/slices/AuthSlice';
import { getJobByStatus } from '../../slices/slices/JobSlice';
import { GetCookies, SetCookie } from '../../services/cookies';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getLastItem = window.location.pathname.split('/').pop();
  const getSecondLastItem = window.location.pathname.split('/').slice(-2)[0];
  const json_str = GetCookies('_jobData');
  const jobItem = json_str ? JSON.parse(JSON.stringify(json_str)) : [];
  const jobHeader = GetCookies('_jobHeader');
  const jobItem1 = jobHeader && JSON.parse(JSON.stringify(jobHeader));
  const _permission = GetCookies('permission');
  const permission = _permission ? JSON.parse(JSON.stringify(_permission)) : [];

  const size = 5;
  const itemsData = jobItem.reverse();
  const getJobItem = itemsData.slice(0, size);

  const { logoutResponse } = useSelector(authState);

  const signOut = () => {
    dispatch(doLogout());
  };

  const onClickRow = (item) => {
    const data = {
      jobId: item.jobId,
      job_no: item.job_no,
      jobName: item.jobName
    };

    SetCookie('_jobHeader', data);

    navigate('/job/' + item?.id + '/dashboard');
  };

  const { jobId } = useParams();

  useEffect(() => {
    if (Object.keys(logoutResponse).length <= 0)
      dispatch(getJobByStatus({ status: 'active', showing: 5 }));
    if (logoutResponse.message) {
      dispatch(setLogoutResponse({}));
    }
  }, [logoutResponse.message]);

  return (
    <>
      <div
        id="header"
        className="header fixed-top d-flex align-items-center justify-content-between"
      >
        <div className="d-flex align-items-center justify-content-between joblistdropdown">
          <NavDropdown
            title={`Job ${jobItem1?.job_no} - ${jobItem1?.jobName}`}
            id="nav-dropdown"
            className="d-flex align-items-center job-title"
          >
            {getJobItem
              ? getJobItem?.map((item, index) => {
                  return (
                    <NavDropdown.Item
                      key={index}
                      onClick={() => {
                        onClickRow(item);
                      }}
                    >
                      <span>
                        Job {item?.job_no} - {item?.jobName}
                      </span>
                    </NavDropdown.Item>
                  );
                })
              : null}
            <p>
              <a href="/dashboard">View All Jobs</a>
            </p>
          </NavDropdown>
        </div>

        <div className="top-navbar">
          <ul>
            <li>
              <Link
                to={`/job/${jobId}/dashboard`}
                className={getLastItem === 'dashboard' ? 'active' : ''}
              >
                Dashboard
              </Link>
            </li>
            {permission.includes('group-setup') && (
              <li className="dropdown">
                <Link to="#" className={getSecondLastItem === 'group-setup' ? 'active' : ''}>
                  Group Setup
                </Link>
                <ul className="dropdownmenu">
                  <li>
                    <Link to={'/job/' + jobId + '/group-setup/settings'}>Settings</Link>
                  </li>
                  <li>
                    <Link to={`/job/${jobId}/group-setup/members`}>Member</Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-setup/living-expense'}>
                      Living Expenses
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-setup/business-overview'}>
                      Business Overview
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {permission.includes('data-entry') && (
              <li>
                <Link
                  to={'/job/' + jobId + '/data-entry'}
                  className={getLastItem === 'data-entry' ? 'active' : ''}
                >
                  Data Entry
                </Link>
              </li>
            )}
            {permission.includes('group-analysis') && (
              <li className="dropdown">
                <Link
                  to="#"
                  className={
                    getSecondLastItem === 'group-analysis' || getSecondLastItem === 'funds'
                      ? 'active'
                      : ''
                  }
                >
                  Group Analysis
                </Link>

                <ul className="dropdownmenu w100">
                  <li className="title">
                    <a href="#">
                      <h6>group facility & security</h6>
                    </a>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/proposed-transaction'}>
                      Proposed Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/proposed-facilities-security'}>
                      Proposed Facilities & Securities
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/unencumbered-properties'}>
                      Unencumbered Property
                    </Link>
                  </li>
                  <li className="title">
                    <a href="#">
                      <h6>group financials</h6>
                    </a>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/business-analysis'}>
                      Business Financial Analysis
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/personal-analysis'}>
                      Personal Financial Analysis
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/current-rental-income'}>
                      Current Rental Income
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/taxcalculations'}>
                      Tax Calculations
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/proposed-debt-servicing'}>
                      Proposed Debt Servicing Calculations
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/group-analysis/sensitised-debt-servicing'}>
                      Sensitised Debt Servicing Calculations
                    </Link>
                  </li>
                  <li>
                    <a href={'/job/' + jobId + '/group-analysis/groupserviceability'}>
                      Group Serviceability
                    </a>
                  </li>
                </ul>
              </li>
            )}
            {permission.includes('group-assessment') && (
              <li className="dropdown">
                <Link to="#" className={getSecondLastItem === 'assessment' ? 'active' : ''}>
                  Group Assessment
                </Link>
                <ul className="dropdownmenu w100">
                  <li className="title">
                    <a href="#">
                      <h6>Bankability Assessment</h6>
                    </a>
                  </li>
                  <li>
                    <a href={'/job/' + jobId + '/assessment/overallassessment'}>
                      Overall Assessment
                    </a>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/assessment/recommendation'}>Recommendation</Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/assessment/corporate'}>Group Structure</Link>
                  </li>
                  <li className="title">
                    <a href="#">
                      <h6>Discussion Paper</h6>
                    </a>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/assessment/business-overview'}>
                      Business Overview
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/assessment/corporate'}>Group Structure</Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/assessment/guidance'}>Guidance & Direction</Link>
                  </li>
                  <li className="title">
                    <a href="#">
                      <h6>Finance Proposal</h6>
                    </a>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/assessment/business-overview'}>
                      Business Overview
                    </Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/assessment/corporate'}>Group Structure</Link>
                  </li>
                </ul>
              </li>
            )}
            {permission.includes('group-reports') && (
              <li className="dropdown">
                <Link to="#" className={getSecondLastItem === 'report' ? 'active' : ''}>
                  Group Reports
                </Link>
                <ul className="dropdownmenu">
                  <li>
                    <Link to={'/job/' + jobId + '/report/new'}>New</Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/report/published'}>Published</Link>
                  </li>
                  <li>
                    <Link to={'/job/' + jobId + '/report/archive'}>Archive</Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>

        <div className="header-nav">
          <ul className="d-flex align-items-center">
            <li className="nav-item">
              <a
                className="nav-link nav-tool d-flex align-items-center pe-0"
                href="/bankability-tools"
              >
                <i className="icon-tools"></i>
              </a>
            </li>
            <NavDropdown
              title=""
              id="nav-dropdown"
              className="d-flex align-items-center icon-user-profile"
            >
              <NavDropdown.Item href="/setting" className="dropdown-item d-flex align-items-center">
                Settings
              </NavDropdown.Item>
              {permission.includes('staff-management') && (
                <NavDropdown.Item
                  href="/staff-management"
                  className="dropdown-item d-flex align-items-center"
                >
                  Staff Management
                </NavDropdown.Item>
              )}
              {/* {permission.includes('practice-setting') && (
                <NavDropdown.Item
                  href="/practice-setting"
                  className="dropdown-item d-flex align-items-center"
                >
                  Practice Settings
                </NavDropdown.Item>
              )} */}
              {permission.includes('administrator') && (
                <NavDropdown.Item
                  href="/admin-setting"
                  className="dropdown-item d-flex align-items-center"
                >
                  Admin Settings
                </NavDropdown.Item>
              )}

              <NavDropdown.Item
                onClick={() => {
                  signOut();
                }}
                className="dropdown-item d-flex align-items-center"
              >
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
