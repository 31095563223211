import React from 'react';
import { Formik } from 'formik';
import { Addtranscaction } from '../../pages/common/Validation';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { Modal } from 'react-bootstrap';
export default function TransactionModel(props) {
  let initialValue = {
    transaction_name: props?.transactionName ? props?.transactionName : '',
    associate_name: props?.associateName ? props?.associateName : ''
  };

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.handleModalClose}
        dialogClassName="modal-90w modal-dialog-centered"
        aria-labelledby="example-custom-modal-styling-title "
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {props?.transactionName ? 'Update Transaction Name' : 'Enter Transaction Name'}
          </h5>
        </div>
        <Formik
          initialValues={initialValue}
          validationSchema={Addtranscaction}
          onSubmit={props.handleFormSubmit}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
            return (
              <>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="modal-body" style={{ marginTop: '15px' }}>
                    Funds Title:
                    <div>
                      <Input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.transaction_name}
                        name="transaction_name"
                        placeholder="Type transaction name here"
                      />
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors.transaction_name}
                      </span>
                    </div>
                  </div>
                  <div className="modal-body" style={{ marginTop: '15px' }}>
                    Associated Title:
                    <div>
                      <Input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.associate_name}
                        name="associate_name"
                        placeholder="Type transaction name here"
                      />
                      <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                        {errors.associate_name}
                      </span>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={props.handleModalClose}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <Button
                      type="submit"
                      id="groupanaly_propos_transaction_save"
                      className="btn btn-primary"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
