import { Table, Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import React, { useEffect, useState } from 'react'; // ,{ useEffect, useState }
// import UserHeader from './UserHeader';
import Button from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { CreateImportJob } from '../common/Validation';
import 'react-datepicker/dist/react-datepicker.css';
import { getJobByXpm, addxpmJob, jobState } from '../../slices/slices/JobSlice';
import { postxpm, xpmconnection } from '../../slices/slices/FinancialsSlice';
import Label from '../../components/Label';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import UserHeader from './UserHeader';
import OrganizationModal from '../../components/OrganizationModal';
import GroupInput from '../../components/GroupInput';
import moment from 'moment';

export default function Importjob() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { xpmjobData } = useSelector(jobState);
  const [show, setShow] = useState(true);
  const [showmodal, setShowmodal] = useState(false);
  const [organization, setOrganization] = useState([]);
  const [jobitem, setJobitem] = useState([]);
  const [tenantId, settenantId] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(false);

  useEffect(() => {
    dispatch(postxpm(searchParams.get('code'))).then(() => {
      dispatch(xpmconnection()).then((response) => {
        if (response?.data?.data) {
          let practicedata = [];
          response?.data?.data.length > 0 &&
            response?.data?.data &&
            response?.data?.data.map((item) => {
              if (item.tenantType === 'PRACTICEMANAGER') {
                practicedata.push(item);
              }
            });
          setOrganization(practicedata);
        }
      });
    });
  }, []);

  useEffect(() => {
    setJobitem(xpmjobData);
  }, [xpmjobData]);

  const handleSelect = (key, Id) => {
    let items = jobitem.map((data) => {
      if (data.ID === Id) {
        data = { ...data, selected: key.target.checked };
      } else {
        data = { ...data, selected: false };
      }
      return data;
    });

    setJobitem(items);
  };

  const onhandleCancel = () => {
    navigate('/dashboard');
  };

  const getjob = (tenantId) => {
    settenantId(tenantId);
    if (tenantId) dispatch(getJobByXpm(tenantId));
  };

  const handleFormSubmit = (values, { setErrors }) => {
    const selectedjobData = jobitem.filter((e) => e.selected);
    if (selectedjobData.length > 0) {
      values.job_id = selectedjobData[0].ID;
      dispatch(addxpmJob(values, tenantId, setErrors)).then(() => {
        setRedirectUrl(true);
      });
    } else {
      alert.error('Must select one Job');
    }
  };

  useEffect(() => {
    if (redirectUrl) navigate('/dashboard');
  }, [redirectUrl]);

  const handleJob = () => {
    setShowmodal(false);
  };

  const onSubmit = () => {
    const selectedjobData = jobitem.filter((e) => e.selected);
    if (selectedjobData.length > 0) {
      setShowmodal(true);
    } else {
      alert.error('Must select one Job');
    }
  };

  useEffect(() => {}, [jobitem]);
  return (
    <div className="setting toggle-sidebar">
      <UserHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="/staff-management">Import XPM Job</a>
              </li>
            </ul>
            <h1>Import XPM Job</h1>
          </div>
        </div>

        <div className="pagetitle groupanalysispro">
          <div className="title_pro overall_title d-flex justify-content-between align-items-center">
            <div className="addmemberarea"></div>
          </div>
        </div>
        {xpmjobData?.length > 0 ? (
          <>
            <div className="innertab mt-5 white-card">
              <Table className="table table-hover mt-4 mb-4">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '5%' }}></th>
                    <th scope="col" className="align-left">
                      Job No.
                    </th>
                    <th scope="col" className="align-left">
                      Job Name
                    </th>
                    <th scope="col" className="align-left">
                      Due
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {jobitem?.length > 0 &&
                    jobitem?.map((item, key) => {
                      return (
                        <tr key={key}>
                          <th scope="row" style={{ borderLeft: '#c0bebe40 2px solid' }}>
                            <input
                              type="radio"
                              name="selectedjob"
                              onClick={(key) => handleSelect(key, item?.ID)}
                            />
                          </th>

                          <td className="align-left">{item?.ID}</td>

                          <td className="align-left">{item?.Name}</td>

                          <td className="align-left">
                            {moment(item?.DueDate).format("DD MMM 'YY")}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div className="btn_area text-end">
              <Button className="btn btn-outline-secondary" type="reset" onClick={onhandleCancel}>
                Cancel
              </Button>
              <Button className="btn btn-success" type="submit" onClick={onSubmit}>
                Save
              </Button>
            </div>
          </>
        ) : (
          ''
        )}
        <OrganizationModal
          show={show}
          organization={organization}
          setShow={setShow}
          getjob={getjob}
        />
        <Modal
          show={showmodal}
          onHide={handleJob}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <>
            <Modal.Body className="create-job">
              <h3>Create New Job</h3>
              <div className="createjob_v2">
                <Formik
                  initialValues={{
                    group_name: ''
                  }}
                  validationSchema={CreateImportJob}
                  onSubmit={handleFormSubmit}
                >
                  {({ values, setFieldValue, errors, handleSubmit, setValues }) => {
                    return (
                      <form onSubmit={handleSubmit} noValidate>
                        <fieldset>
                          <div className="field-box">
                            <Label>Group Name</Label>
                            <GroupInput
                              setGroupName={(e) => {
                                // setValues({
                                //   group_name: e
                                // });
                                setFieldValue('group_name', e);
                              }}
                              setGroupId={(e) => {
                                // setValues({
                                //   group_id: e
                                // });
                                setFieldValue('group_id', e);
                              }}
                              values={values}
                              setValues={setValues}
                            />
                            <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                              {errors.group_name}
                            </span>
                          </div>
                        </fieldset>
                        <div className="modal-action">
                          <Button
                            type="button"
                            className="btn btn-secondary"
                            id="createbtnjob"
                            onClick={handleJob}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" className="btn btn-primary">
                            Save
                          </Button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </Modal.Body>
          </>
        </Modal>
      </div>
    </div>
  );
}
