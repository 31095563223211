import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { commonState, getConfig } from '../../../../slices/slices/CommonSlice';

const Trust = (props) => {
  Trust.propTypes = {
    data: PropTypes.any
  };
  const dispatch = useDispatch();
  const { configList } = useSelector(commonState);

  const { jobId } = useParams();

  useEffect(() => {
    dispatch(getConfig(configList));
  }, []);

  return (
    <div className="corporate-structure-blocks trust">
      <h4>
        Trust
        <Link to={'/job/' + jobId + '/group-setup/members/edit/' + props?.data?.id}>
          <button type="button" className="fa fa-edit"></button>
        </Link>
      </h4>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <tr>
          <td width="34%">Trustee Name</td>
          <td colSpan="2">{props?.data?.additional_info?.trustee_name}</td>
        </tr>
        <tr>
          <td>ACN</td>
          <td colSpan="2">{props?.data?.additional_info?.acn}</td>
        </tr>
        {props?.data?.directors?.map((item, index) => {
          return (
            <tr key={index}>
              <td htmlFor="">Director</td>
              <td colSpan="2">{item?.label}</td>
            </tr>
          );
        })}
        {props?.data?.shareholders?.map((item, index) => {
          return (
            <tr key={index}>
              <td htmlFor="">Shareholder</td>
              <td>{item?.name?.label}</td>
              <td>
                {item?.holding}
                {item?.holding ? '%' : ''}
              </td>
            </tr>
          );
        })}
        <tr>
          <td htmlFor="">Trust Name</td>
          <td colSpan="2">{props?.data?.name}</td>
        </tr>
        <tr>
          <td htmlFor="">ABN</td>
          <td colSpan="2">{props?.data?.additional_info?.abn}</td>
        </tr>
        <tr>
          <td htmlFor="">Trading Name</td>
          <td colSpan="2">{props?.data?.additional_info?.trading_name}</td>
        </tr>
        <tr>
          <td htmlFor="">Activity</td>
          <td colSpan="2">{props?.data?.activity}</td>
        </tr>
        <tr>
          <td htmlFor="">Type Of Trust</td>
          <td colSpan="2">
            {Object.keys(configList).length > 0 &&
              configList['member_trust_types'].find(
                (el) => el.key == props?.data?.additional_info?.trust_type
              )?.value}
          </td>
        </tr>
        {props?.data?.additional_info?.beneficiaries?.map((item, index) => {
          return (
            <tr key={index}>
              <td htmlFor="">Beneficiary</td>
              <td colSpan="2">{item?.label}</td>
            </tr>
          );
        })}
        {props?.data?.additional_info?.beneficiary && (
          <tr>
            <td htmlFor="">Beneficiary</td>
            <td colSpan="2">{props?.data?.additional_info?.beneficiary?.label}</td>
          </tr>
        )}
        {props?.data?.additional_info?.unitholders?.map((item, index) => {
          return (
            <tr key={index}>
              <td htmlFor="">Unitholder / Fixed</td>
              <td>{item?.name?.label}</td>
              <td>
                {item?.holding}
                {item?.holding ? '%' : ''}
              </td>
            </tr>
          );
        })}
        {props?.data?.additional_info?.members?.map((item, index) => {
          return (
            <tr key={index}>
              <td htmlFor="">Member</td>
              <td colSpan="2">{item?.label}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default Trust;
