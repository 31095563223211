import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const guidanceInitialState = {
  loading: false,
  guidanceDataResponse: {},
  editGuidanceResponse: {}
};

export const GuidanceSlice = createSlice({
  name: 'guidance',
  initialState: guidanceInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setUpdatedGuidanceResponse: (state, action) => {
      state.updateGuidanceResponse = action.payload;
      state.loader = false;
    },
    setGuidanceDataResponse: (state, action) => {
      state.GuidanceDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const updateGuidance = (id, data, setErrors) => async (dispatch) => {
  const response = await clientService.put(
    'jobs/' + id + '/business-assessment/guidance-direction',
    data,
    setErrors,
    dispatch
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedGuidanceResponse(response.data));
  }
};

export const getGuidanceData = (id, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    'jobs/' + id + '/business-assessment/guidance-direction',
    setErrors,
    dispatch,
    false
  );
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGuidanceDataResponse(response.data));
  }
};

export const { setLoader, setUpdatedGuidanceResponse, setGuidanceDataResponse } =
  GuidanceSlice.actions;

export const guidanceState = (state) => {
  return state.guidance;
};

export default GuidanceSlice.reducer;
