import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import {
  unencumberedPropertyState,
  getUnencumberedPropertyData,
  editUnencumberedProperty,
  setEditUnencumberedPropertyResponse,
  getMemberData
} from '../../../../slices/slices/UnencumberedPropertySlice';
import { addPropertySchema } from '../../../common/Validation';
import Form from './components/Form';
import { displayNameFromType } from '../../../common/Misc';
import { getMemberList, jobSetupState } from '../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const EditProperty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formValues = useRef();
  const { jobId, memberId, propertyId } = useParams();

  const unencumberedPropertyDataResponse = useSelector(unencumberedPropertyState);
  const { editUnencumberedPropertyResponse, memberDataResponse } =
    useSelector(unencumberedPropertyState);
  const { memberListResponse } = useSelector(jobSetupState);
  let existingValues = [];
  let overwrittenFields = [];
  let initialval = {};
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  useEffect(() => {
    dispatch(getMemberList(jobId));
    dispatch(getMemberData(jobId, memberId));
    dispatch(getUnencumberedPropertyData(jobId, memberId, propertyId));
  }, []);

  const handleFormSubmit = (values, { setErrors }) => {
    let members = [];
    setCanShowDialogLeavingPage(false);
    {
      for (let item of JSON.parse(JSON.stringify(values?.asset_owner))) {
        members.push({
          holding: item?.holding,
          member_id: item?.member_id?.value
        });
      }
      let data = values?.asset_owner;
      data.members;
      if (values?.is_completed === 0) {
        let total = 0;
        values?.asset_owner?.map((val, index) => {
          total += parseInt(values.asset_owner[index].holding);
        });
        total <= 100 &&
          values?.asset_owner?.map((val, index) => {
            return (values.asset_owner[index].member_id = val?.member_id?.value);
          });
      } else {
        let total = 0;
        values?.asset_owner?.map((val, index) => {
          total += parseInt(values.asset_owner[index].holding);
        });
        total === 100 &&
          values?.asset_owner?.map((val, index) => {
            return (values.asset_owner[index].member_id = val?.member_id?.value);
          });
      }
    }

    const data = {
      ...values,
      date_confirmation:
        values?.date_value === null || values?.date_value === '' || values?.date_value === undefined
          ? ''
          : moment(values?.date_value).format('MM-YY'),
      market_value: parseInt(`${values?.market_value}`.replace(/,/g, ''))
    };
    data.asset_owner = members;
    dispatch(editUnencumberedProperty(jobId, memberId, propertyId, data, setErrors));
  };

  useEffect(() => {
    if (editUnencumberedPropertyResponse.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setEditUnencumberedPropertyResponse({}));
      navigate(-1);
    }
  }, [editUnencumberedPropertyResponse.message]);

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const getOwners = () => {
    let temp;

    if (
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.uc_owners?.length >
      0
    ) {
      temp =
        unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.uc_owners?.map(
          (share) => ({
            member_id: newColumnsForAll?.find((item) => item?.value === share?.pivot?.member_id),
            holding: share?.pivot?.holding
          })
        );
    } else {
      temp = [
        {
          member_id: '',
          holding: 0
        }
      ];
    }
    return temp;
  };

  initialval = {
    asset_owner: getOwners(),
    address: unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.address,
    asset_class:
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.asset_class,
    asset_type:
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.asset_type,
    asset_usage:
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.asset_usage,
    asset_description:
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.asset_description,
    confirmation:
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.confirmation,
    date_confirmation:
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.date_confirmation === null
        ? ''
        : moment(
            unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data
              ?.additional_info?.date_confirmation,
            'MM-YY'
          ).format('YYYY-MM'),
    date_value:
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.date_confirmation != '' &&
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.date_confirmation != null
        ? new Date(
            moment(
              unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data
                ?.additional_info?.date_confirmation,
              'MM-YY',
              true
            )
          )
        : null,
    market_value:
      unencumberedPropertyDataResponse?.unencumberedPropertyDataResponse?.data?.additional_info
        ?.market_value,
    is_completed: 0
  };

  useEffect(() => {
    initialval = {};
  }, [unencumberedPropertyDataResponse]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/unencumbered-properties'}>
                  Unencumbered Property
                </Link>
              </li>
            </ul>
            <h1>Unencumbered Property - {displayNameFromType(memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <NavDropdown
              title=""
              id="nav-dropdown"
              style={{ marginLeft: 10 + 'px' }}
              className="d-flex align-items-center icon-info"
            >
              <NavDropdown.Item className="dropdown-item information">
                <h4>
                  Information about
                  <br />
                  relative screen
                </h4>
                <a href="/facilities" className="close">
                  <i className="icon-close"></i>
                </a>
                <p>
                  Lorem ipsum dolor sit amet, consecte tur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                  fugiat nulla pariatur.
                </p>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={initialval}
          validationSchema={addPropertySchema}
          onSubmit={handleFormSubmit}
          innerRef={formValues}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form
                id="username"
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                memberListResponse={memberListResponse}
                memberId={memberId}
                jobId={jobId}
                propertyId={propertyId}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditProperty;
