import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../../../constant';
import Header from '../../../../pages/job/Header';
// import { unformatNumber } from '../../../../pages/common/Misc';
import { AddFundValidation } from '../../../../pages/common/Validation';
import DialogModal from '../../../../components/modal/DialogModal';
import {
  addFunds,
  addassociateFunds,
  editFunds,
  updateassociatedfunds,
  editFunds_data,
  getassociateddata
} from '../../../../slices/slices/ProposedTransactionSlice';
// import FormActions from '../../../FormActions';
import AutomatedSelect from '../../../../components/fields/AutomatedSelect';
import { transactionType } from '../../../../utils/constant';
import { transactionSelected } from '../../../../utils/constant';
import CurrencyInput from 'react-currency-input-field';
import { commonState, getConfig } from '../../../../slices/slices/CommonSlice';
import {
  ProposedTransactionState,
  clearFundsData
} from '../../../../slices/slices/ProposedTransactionSlice';
import FormAction from './FormAction';

export default function AddFund() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const jobId = useParams();
  const [redirectUrl, setRedirectUrl] = useState(false);
  const [associatedurl, setAssociatedurl] = useState(false);
  const { howSourceTypeList, getfundsData } = useSelector(ProposedTransactionState);
  const { configList } = useSelector(commonState);

  useEffect(() => {
    // let selectvaluetype = '';
    // if (params?.state?.type == 'application-funds') {
    //   selectvaluetype = 'proposed-transaction-funds-use';
    // } else if (params?.state?.type == 'source-funds') {
    //   selectvaluetype = 'proposed-transaction-how-sourced';
    // } else {
    //   selectvaluetype = 'proposed-transaction-associated-transaction-item';
    // }
    dispatch(getConfig(configList));
  }, []);

  const handleFormSubmit = (values, { setErrors }) => {
    let data = { ...values };
    // data.amount = data.amount;
    if (params?.state?.type == 'source-funds') {
      data.how_sourced = data.funds_use;
      delete data.funds_use;
    } else {
      // delete data.funds_use;
    }
    if (
      params?.state?.fundsId != undefined &&
      (params?.state?.type == 'source-funds' || params?.state?.type == 'application-funds')
    ) {
      dispatch(
        editFunds(
          jobId.jobId,
          params.state?.transactionId,
          params.state?.type,
          params.state?.fundsId,
          data,
          setErrors
        )
      ).then(() => {
        setRedirectUrl(true);
      });
    } else if (params?.state?.fundsId != undefined && params?.state?.type == 'associated_funds') {
      data.item = data.funds_use;
      delete data.funds_use;
      dispatch(
        updateassociatedfunds(
          jobId.jobId,
          params?.state?.transactionId,
          params?.state?.fundsId,
          data,
          setErrors
        )
      ).then(() => {
        setAssociatedurl(true);
      });
    } else {
      if (params?.state?.fundsId == undefined && params?.state?.type == 'associated_funds') {
        data.item = data.funds_use;
        delete data.funds_use;
        dispatch(
          addassociateFunds(
            jobId.jobId,
            params?.state?.transactionId,
            params?.state?.type,
            data,
            setErrors
          )
        ).then(() => {
          setAssociatedurl(true);
        });
      } else {
        dispatch(
          addFunds(jobId.jobId, params?.state?.transactionId, params?.state?.type, data, setErrors)
        ).then(() => {
          setRedirectUrl(true);
        });
      }
    }
  };

  useEffect(() => {
    if (redirectUrl) navigate('/job/' + jobId.jobId + '/group-analysis/proposed-transaction');
  }, [redirectUrl]);

  useEffect(() => {
    if (associatedurl)
      navigate('/job/' + jobId.jobId + '/group-analysis/proposed-transaction?tab=associated_funds');
  }, [associatedurl]);

  const handleonClickChange = (e, setFunction) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setFunction(fieldName, fieldValue);
  };

  // const getSelectData = () => {
  //   let arr = null;
  //   if (params?.state?.type == 'source-funds') {
  //     arr = configList['proposed-transaction-how-sourced'];
  //   } else if (params?.state?.type == 'application-funds') {
  //     arr = configList['proposed-transaction-funds-use'];
  //   } else {
  //     arr = configList['proposed-transaction-associated-transaction-item'];
  //   }
  //   return arr;
  // };
  useEffect(() => {}, [howSourceTypeList, getfundsData]);

  const onhandleCancel = () => {
    navigate('/job/' + jobId.jobId + '/group-analysis/proposed-transaction');
  };

  const geteditfundsData = async () => {
    dispatch(clearFundsData());
    if (
      params?.state?.fundsId != undefined &&
      (params?.state?.type == 'source-funds' || params?.state?.type == 'application-funds')
    ) {
      dispatch(editFunds_data(jobId.jobId, params?.state?.transactionId, params?.state?.fundsId));
    } else if (params?.state?.fundsId && params?.state?.type == 'associated_funds') {
      dispatch(
        getassociateddata(jobId.jobId, params?.state?.transactionId, params?.state?.fundsId)
      );
    } else {
      dispatch(clearFundsData());
    }
  };

  useEffect(() => {
    geteditfundsData();
  }, []);

  return (
    <>
      <div className="dashboard toggle-sidebar">
        <Header />
        <main id="main" className="main">
          <div className="group-breadcrumb d-flex justify-content-between align-items-center">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <a href="#">Dashboard</a>
                </li>
                <li>
                  <a href="#">Group Analysis</a>
                </li>
                <li>
                  <Link to={'/job/' + jobId.jobId + '/group-analysis/proposed-transaction/'}>
                    Proposed Transaction
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>{transactionType[params?.state?.type]}</strong>
                </li>
              </ul>
              <h1>Proposed Transaction - Group 1</h1>
            </div>
            <div className="breadcrumb-right">
              {params?.state?.type === 'application-funds' ? (
                <DialogModal title="Application of Funds" p1={constants.application_of_funds} />
              ) : params?.state?.type === 'associated_funds' ? (
                <DialogModal title="Associated Transaction" p1={constants.associated_transaction} />
              ) : (
                <DialogModal title="Source of Funds" p1={constants.source_of_fund} />
              )}
            </div>
          </div>
          {((params?.state?.fundsId && getfundsData) ||
            (!params?.state?.fundsId && !getfundsData)) && (
            <Formik
              initialValues={{
                // funds_use: '',
                funds_use:
                  getfundsData?.data && getfundsData?.data[transactionSelected[params.state?.type]],
                amount: getfundsData?.data?.amount,
                comment: getfundsData?.data?.comment
              }}
              validationSchema={AddFundValidation}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={handleFormSubmit}
            >
              {({ values, errors, handleBlur, handleSubmit, handleChange, setFieldValue }) => {
                return (
                  <form onSubmit={handleSubmit} className="facility-form form-data mt-30 fund-form">
                    <div className="white-card">
                      <h3 className="titlePro text-center">
                        {transactionType[params?.state?.type]}
                      </h3>
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <div className="group_areaof row">
                            <div className="col-6">
                              <label>
                                {params?.state?.type === 'application-funds'
                                  ? 'Fund Used'
                                  : params?.state?.type === 'associated_funds'
                                  ? 'Item'
                                  : 'How Sourced'}
                              </label>
                              <AutomatedSelect
                                name="funds_use"
                                data={
                                  params?.state?.type == 'source-funds'
                                    ? configList['proposed_transaction_how_sourced']
                                    : params?.state?.type == 'application-funds'
                                    ? configList['proposed_transaction_funds_use']
                                    : configList['proposed_transaction_associated_transaction_item']
                                }
                                value={values ? values.funds_use : null}
                                onBlur={handleBlur}
                                handleChange={(e) => handleonClickChange(e, setFieldValue)}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.funds_use}
                              </span>
                            </div>
                            <div className="col-6">
                              <label>Amount $</label>
                              <CurrencyInput
                                allowNegativeValue={true}
                                className="form-control"
                                name="amount"
                                value={values?.amount}
                                allowDecimals={false}
                                onValueChange={(value, name) => {
                                  setFieldValue(name, value);
                                }}
                                onBlur={handleBlur}
                              />
                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.amount}
                              </span>
                            </div>
                            <div className="col-12">
                              <label>Comment (if applicable)</label>
                              <textarea
                                name="comment"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                value={values?.comment}
                                className="form-control"
                                style={{ height: '100px' }}
                              ></textarea>

                              <span className="error" style={{ color: 'red', fontSize: '20px' }}>
                                {errors.comment}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="action-box full">
                      <FormAction onhandleCancel={onhandleCancel} />
                    </div>
                  </form>
                );
              }}
            </Formik>
          )}
        </main>
      </div>
    </>
  );
}
