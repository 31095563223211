import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import {
  unencumberedPropertyState,
  addUnencumberedProperty,
  getMemberData,
  setUnencumberedPropertyResponse
} from '../../../../slices/slices/UnencumberedPropertySlice';
import Form from './components/Form';
import { addPropertySchema } from '../../../common/Validation';
import DialogModal from '../../../../components/modal/DialogModal';
import constants from '../../../../constant';
import { displayNameFromType } from '../../../common/Misc';
import { getMemberList, jobSetupState } from '../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const AddProperty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formValues = useRef();
  const [MemberValue, setMemberValue] = useState({});
  const { jobId, memberId } = useParams();
  const { memberDataResponse, addUnencumberedPropertyResponse } =
    useSelector(unencumberedPropertyState);
  const { memberListResponse } = useSelector(jobSetupState);
  let existingValues = [];
  let overwrittenFields = [];
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  const handleFormSubmit = async (values, { setErrors }) => {
    let members = [];
    setCanShowDialogLeavingPage(false);
    {
      for (let item of JSON.parse(JSON.stringify(values?.asset_owner))) {
        members.push({
          holding: item?.holding,
          member_id: item?.member_id?.value
        });
      }

      if (values?.is_completed === 0) {
        let total = 0;
        values?.asset_owner?.map((val, index) => {
          total += parseInt(values.asset_owner[index].holding);
        });
        total <= 100 &&
          values?.asset_owner?.map((val, index) => {
            return (values.asset_owner[index].member_id = val?.member_id?.value);
          });
      } else {
        let total = 0;
        values?.asset_owner?.map((val, index) => {
          total += parseInt(values.asset_owner[index].holding);
        });
        total === 100 &&
          values?.asset_owner?.map((val, index) => {
            return (values.asset_owner[index].member_id = val?.member_id?.value);
          });
      }
    }

    const data = {
      ...values,
      date_confirmation:
        values?.date_value === '' ? '' : moment(values?.date_value).format('MM-YY'),
      market_value: parseInt(`${values?.market_value}`.replace(/,/g, ''))
    };
    data.asset_owner = members;
    dispatch(addUnencumberedProperty(jobId, memberId, data, setErrors));
  };

  useEffect(() => {
    if (addUnencumberedPropertyResponse.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setUnencumberedPropertyResponse({}));
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/unencumbered-properties');
    }
  }, [addUnencumberedPropertyResponse.message]);

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getMemberList(jobId));
  }, []);

  useEffect(() => {
    displayNameFromType(memberDataResponse) &&
      setMemberValue({
        label: displayNameFromType(memberDataResponse),
        value: memberId
      });
  }, [memberDataResponse]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/unencumbered-properties'}>
                  Unencumbered Property
                </Link>
              </li>
            </ul>
            <h1>Unencumbered Property - {displayNameFromType(memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right d-flex">
            <DialogModal
              title="Add Property"
              p1={constants.unencumberedText1}
              p2={constants.unencumberedText2}
              p3={constants.unencumberedText3}
            />
          </div>
        </div>
        <Formik
          initialValues={{
            // asset_owner: getOwners(),
            asset_owner: [
              {
                member_id: { label: displayNameFromType(memberDataResponse), value: memberId },
                holding: 100
              }
            ],
            address: '',
            asset_class: '',
            asset_type: '',
            asset_usage: '',
            asset_description: '',
            confirmation: '',
            date_confirmation: '',
            date_value: '',
            market_value: '',
            is_completed: 0
          }}
          validationSchema={addPropertySchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleFormSubmit}
          innerRef={formValues}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              <Form
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                memberListResponse={memberListResponse}
                memberId={memberId}
                jobId={jobId}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
                MemberValue={MemberValue}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddProperty;
