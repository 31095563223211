import { Formik } from 'formik';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import {
  securityState,
  getSecurityData,
  getMemberData,
  getMetaData,
  setUpdateSecurityDataResponse,
  updateSecurity,
  getFacilitiesData
} from '../../../../slices/slices/SecuritySlice';
import { getMetaData as getMetaDataFacility } from '../../../../slices/slices/FacilitySlice';
import DataEntryHeader from '../../../../components/DataEntryHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Form from './components/Form';
import { displayNameFromType } from '../../../common/Misc';
import { addSecuritySchema } from '../../../common/Validation';
import { getMemberList, jobSetupState } from '../../../../slices/slices/JobSetupSlice';
import { useNavigatingAway } from '../../../common/hooks/useNavigatingAway';
import { DialogLeavingPage } from '../../../common/components/DialogLeavingPage';

const EditSecurity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formValues = useRef();
  const { jobId, memberId, securityId } = useParams();
  const [defaultData, setDefaultData] = useState({});
  const { memberListResponse } = useSelector(jobSetupState);
  let existingValues = [];
  let overwrittenFields = [];
  const { memberDataResponse, securityDataResponse, updateSecurityDataResponse, metaDataResponse } =
    useSelector(securityState);
  const [canShowDialogLeaving, setCanShowDialogLeaving] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleInputChange = (e) => {
    if (e !== '') setCanShowDialogLeaving(true);
    else setCanShowDialogLeaving(false);
  };

  useEffect(() => {
    canShowDialogLeaving !== false
      ? setCanShowDialogLeavingPage(true)
      : setCanShowDialogLeavingPage(false);
  }, [canShowDialogLeaving]);

  // const { metaDataResponse: metaDataResponseFacility } = useSelector(facilityState);
  useEffect(() => {
    setDefaultData({ ...securityDataResponse });
  }, [securityDataResponse]);

  useEffect(() => {
    dispatch(getMemberData(jobId, memberId));
    dispatch(getSecurityData(jobId, memberId, securityId));
    dispatch(getMetaData());
    dispatch(getMetaDataFacility());
    dispatch(getFacilitiesData(jobId, memberId, securityId));
  }, []);

  const handleFormSubmit = (values, { setErrors }) => {
    let member = [];
    values?.owners?.map((item) => {
      member.push({
        member_id: item.member_id?.value,
        holding: item?.holding
      });
    });
    setCanShowDialogLeavingPage(false);
    {
      if (values?.is_completed === 0) {
        values?.owners?.map((val, index) => {
          return (values.owners[index].member_id = val?.member_id?.value);
        });
      } else {
        let total = 0;
        values?.owners?.map((val, index) => {
          total += values.owners[index].holding;
        });
        total === 100 &&
          values?.owners?.map((val, index) => {
            return (values.owners[index].member_id = val?.member_id?.value);
          });
      }
    }
    values.market_value = parseInt(`${values?.market_value}`.replace(/,/g, ''));
    values.lending_value = parseInt(`${values?.lending_value}`.replace(/,/g, ''));
    values.shading = parseInt(`${values?.shading}`);
    values.owners = member;
    dispatch(updateSecurity(jobId, memberId, securityId, values, setErrors));
  };

  const newColumnsForAll = memberListResponse?.data?.all?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });

  const getOwners = () => {
    let temp;
    if (defaultData?.security_owners && defaultData?.security_owners?.length > 0) {
      temp = defaultData?.security_owners?.map((share) => {
        return {
          member_id: newColumnsForAll?.find((item) => item?.value === share?.pivot?.member_id),
          holding: share?.pivot?.holding
        };
      });
    } else {
      temp = [
        {
          member_id: '',
          holding: 0
        }
      ];
    }
    return temp;
  };

  useEffect(() => {
    dispatch(getMemberList(jobId));
  }, []);

  useEffect(() => {
    if (updateSecurityDataResponse.message) {
      setCanShowDialogLeavingPage(false);
      dispatch(setUpdateSecurityDataResponse({}));
      navigate('/job/' + jobId + '/data-entry/' + memberId + '/securities');
    }
  }, [updateSecurityDataResponse]);

  return (
    <div className="dashboard toggle-sidebar">
      <DataEntryHeader />
      <div id="main" className="main">
        <div className="group-breadcrumb">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry'}>Data Entry</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/data-entry/' + memberId + '/securities'}>
                  Securities
                </Link>
              </li>
            </ul>
            <h1>Securities - {displayNameFromType(memberDataResponse)}</h1>
          </div>
          <div className="breadcrumb-right">
            <NavDropdown title="" id="nav-dropdown" className="d-flex align-items-center icon-info">
              <NavDropdown.Item className="dropdown-item information">
                <h4>
                  Information about
                  <br />
                  relative screen
                </h4>
                <a href="/securities" className="close">
                  <i className="icon-close"></i>
                </a>
                <p>
                  Lorem ipsum dolor sit amet, consecte tur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ulla mco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                  fugiat nulla pariatur.
                </p>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <section className="new_addmember">
            <Formik
              initialValues={{
                address: defaultData?.address,
                owners: getOwners(),
                asset_class: defaultData?.asset_class,
                market_value: defaultData?.market_value,
                shading: defaultData?.shading,
                lending_value: defaultData?.lending_value,
                asset_type: defaultData?.additional_info?.asset_type,
                asset_usage: defaultData?.additional_info?.asset_usage,
                asset_description: defaultData?.additional_info?.asset_description,
                value_confirmed: defaultData?.additional_info?.value_confirmed,
                // date_value_confirmed_init:
                //   defaultData?.additional_info?.date_value_confirmed != '' &&
                //   defaultData?.additional_info?.date_value_confirmed != null
                //     ? moment(
                //         defaultData?.additional_info?.date_value_confirmed,
                //         'MM-YY',
                //         true
                //       ).format('YYYY-MM')
                //     : null,
                date_value:
                  defaultData?.additional_info?.date_value_confirmed != '' &&
                  defaultData?.additional_info?.date_value_confirmed != null
                    ? new Date(
                        moment(defaultData?.additional_info?.date_value_confirmed, 'MM-YY', true)
                      )
                    : new Date(),
                report_comments: defaultData?.additional_info?.report_comments,
                internal_notes: defaultData?.additional_info?.internal_notes,
                is_completed: defaultData?.is_completed
              }}
              enableReinitialize
              onSubmit={handleFormSubmit}
              innerRef={formValues}
              validationSchema={addSecuritySchema}
            >
              {({
                values,
                setErrors,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              }) => (
                <>
                  <DialogLeavingPage
                    showDialog={showDialogLeavingPage}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                  />
                  <Form
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    setErrors={setErrors}
                    metaDataResponse={metaDataResponse}
                    memberDataResponse={memberDataResponse}
                    memberListResponse={memberListResponse}
                    memberId={memberId}
                    jobId={jobId}
                    securityId={securityId}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                  />
                </>
              )}
            </Formik>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditSecurity;
