import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import Button from '../../components/Button';
import { useSearchParams } from 'react-router-dom';
import OrganizationModal from '../../components/OrganizationModal';
import {
  getgroupMember,
  importxpmMember,
  selectMember,
  selectJob,
  jobSetupState
} from '../../slices/slices/JobSetupSlice';
import { postxpm, xpmconnection } from '../../slices/slices/FinancialsSlice';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Xpmtablerow from '../../components/Xpmtablerow';
import UserHeader from './UserHeader';
import { useNavigate } from 'react-router';

export default function AdminSetting() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const localData = localStorage.getItem('data');
  const [show, setShow] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [memberData, setMemberData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [member, setMember] = useState([]);
  const [xpmId, setXpmId] = useState('');
  const [organization, setOrganization] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(false);
  const { xpmMemberResponse, getGroupmemberdata } = useSelector(jobSetupState);

  const handletabOnclick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(postxpm(searchParams.get('code'))).then(() => {
      dispatch(xpmconnection()).then((response) => {
        let practiceData = [];
        response?.data?.data.length > 0 &&
          response?.data?.data.map((item) => {
            if (item?.tenantType === 'PRACTICEMANAGER') {
              practiceData.push(item);
            }
          });
        setOrganization(practiceData);
      });
    });
  }, []);

  useEffect(() => {
    if (xpmMemberResponse?.data) {
      setMemberData(xpmMemberResponse?.data?.members);

      let data =
        xpmMemberResponse?.data?.groups &&
        xpmMemberResponse.data.groups.length > 0 &&
        xpmMemberResponse.data.groups.map((data) => {
          data = { ...data, selected: false };
          return data;
        });
      setGroupData(data);
    }
  }, [xpmMemberResponse]);

  const onhandleCancel = () => {
    navigate('/dashboard');
  };

  const handleonselectmember = (key, index) => {
    let items = memberData.map((data, indexval) => {
      if (indexval === index) {
        data = { ...data, selected: key.target.checked };
      }
      return data;
    });
    setMemberData(items);
  };

  const setOpen = (ID) => {
    let items = groupData.map((item) => {
      if (item.UUID == ID) {
        if (item.selected) {
          item = { ...item, selected: false };
        } else {
          item = { ...item, selected: true };
        }
      } else {
        item = { ...item, selected: false };
      }
      return item;
    });
    setGroupData(items);
  };

  // const handlegroupSelect = (key, index) => {
  //   let items = groupData.map((data, indexval) => {
  //     if (indexval === index) {
  //       data = { ...data, selected: key.target.checked };
  //     } else {
  //       data = { ...data, selected: false };
  //     }
  //     return data;
  //   });
  //   setGroupData(items);
  // };

  const onSubmitForm = () => {
    const selectedmemberData = memberData.filter((e) => e.selected);
    let member = [];
    if (memberData) {
      selectedmemberData.map((item) => {
        member.push(item.UUID);
      });
    } else {
      alert.error('Must select one member');
    }

    if (member.length > 0) {
      dispatch(selectMember(xpmId, { members: member }, {})).then(() => {
        setRedirectUrl(true);
      });
    } else {
      alert.error('Must select one member');
    }
  };

  const handeClick = (ID) => {
    setMember([]);
    setOpen(ID);
    dispatch(getgroupMember(xpmId, ID, {}));
  };

  useEffect(() => {
    setMember(getGroupmemberdata?.data?.members);
  }, [getGroupmemberdata]);

  const onSubmitgroup = () => {
    const selectedmembers = member?.filter((e) => e.selected);
    let memebers = [];
    if (member) {
      selectedmembers.map((item) => {
        memebers.push(item.UUID);
      });
    } else {
      alert.error('Must select one member');
    }
    if (memebers.length > 0) {
      dispatch(selectMember(xpmId, { members: memebers }, {})).then(() => {
        setRedirectUrl(true);
      });
    }
  };

  const getmember = (tenantId) => {
    if (tenantId)
      dispatch(selectJob(JSON.parse(localData).jobId, tenantId)).then((res) => {
        if (res?.data?.data?.id && tenantId)
          dispatch(importxpmMember(res?.data?.data?.id, tenantId));
        setXpmId(res?.data?.data?.id);
      });
  };

  useEffect(() => {
    if (redirectUrl) navigate('/job/' + JSON.parse(localData).jobId + '/group-setup/members');
  }, [redirectUrl]);

  return (
    <>
      <div className="setting toggle-sidebar">
        <UserHeader />
        <div id="main" className="main">
          <div className="group-breadcrumb">
            <div className="breadcrumb-left">
              <ul>
                <li>
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li>
                  <strong>Admin Settings</strong>
                </li>
              </ul>
              <h1>Import XPM Member</h1>
            </div>
          </div>
          <div className="pagetitle groupanalysispro">
            <div className="title_pro overall_title d-flex justify-content-between align-items-center">
              <div className="addmemberarea"></div>
            </div>
          </div>
          <div className="facility-form form-data">
            <div className="white-card">
              <div className="rentalview">
                <ul className="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 1 && 'active'}`}
                      id="residential-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#residential-justified"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="false"
                      onClick={() => handletabOnclick(1)}
                    >
                      Client
                    </button>
                  </li>
                  <li className="nav-item " role="presentation">
                    <button
                      className={`nav-link ${activeTab == 2 && ' active'}`}
                      id="securities-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#securities-justified"
                      type="button"
                      role="tab"
                      aria-controls="securities"
                      aria-selected="false"
                      onClick={() => handletabOnclick(2)}
                    >
                      Group
                    </button>
                  </li>
                </ul>
                <div className="innertab">
                  <div className="tab-content pt-2" id="myTabjustifiedContent">
                    <div
                      className="tab-pane fade  show  active"
                      id="residential-justified"
                      role="tabpanel"
                      aria-labelledby="residential-tab"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="funds">
                            {activeTab == 1 ? (
                              <>
                                <Table className="member-data-entry facility-table">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="align-left"
                                        style={{ width: '5%' }}
                                      ></th>
                                      <th
                                        scope="col"
                                        className="align-left"
                                        style={{ width: '45%' }}
                                      >
                                        Member Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="align-left"
                                        style={{ width: '50%' }}
                                      >
                                        Account Manager
                                      </th>

                                      {/* <th scope="col" className="align-left">
                                        Address
                                      </th>
                                      <th scope="col" className="align-left">
                                        Email
                                      </th>
                                      <th scope="col" className="align-left">
                                        Fax
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {memberData &&
                                      memberData.length > 0 &&
                                      memberData.map((item, key) => {
                                        return (
                                          <tr key={key}>
                                            <th
                                              scope="col"
                                              className="align-left"
                                              style={{
                                                borderLeft: '#c0bebe40 2px solid',
                                                textAlign: 'center !important'
                                              }}
                                            >
                                              <input
                                                type="checkbox"
                                                name="selectedjob"
                                                defaultValue={item?.selected ? true : false}
                                                onClick={(e) => handleonselectmember(e, key)}
                                              />
                                            </th>
                                            <td className="align-left">{item?.Name}</td>
                                            <td className="align-left" style={{ padding: '15px' }}>
                                              {item?.AccountManager?.Name}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <div className="btn_area text-end">
                                  <Button
                                    className="btn btn-outline-secondary"
                                    type="reset"
                                    onClick={onhandleCancel}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="btn btn-success"
                                    type="submit"
                                    onClick={onSubmitForm}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <>
                                <Table className="table table-hover">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="align-left"
                                        style={{ width: '5%', textAlign: 'center' }}
                                      ></th>
                                      <th
                                        scope="col"
                                        className="align-left"
                                        style={{ width: '95%' }}
                                      >
                                        Group Name
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupData &&
                                      groupData.length > 0 &&
                                      groupData.map((item, key) => {
                                        return (
                                          <>
                                            <Xpmtablerow
                                              index={key}
                                              item={item}
                                              setOpen={setOpen}
                                              handeClick={handeClick}
                                              member={member}
                                              setmember={setMember}
                                              xpmJobId={'ce56fa8d-4b2c-4433-8fd6-b57c0206388f'}
                                            />
                                          </>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                                <div className="btn_area text-end">
                                  <Button
                                    className="btn btn-outline-secondary"
                                    type="reset"
                                    onClick={onhandleCancel}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="btn btn-success"
                                    type="submit"
                                    onClick={onSubmitgroup}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrganizationModal
        show={show}
        organization={organization}
        setShow={setShow}
        getjob={getmember}
      />
    </>
  );
}
