import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  rentalState,
  getRentalListData,
  deleteResidentialRental,
  setDeleteRentalResidentialDataResponse
} from '../../../../../slices/slices/RentalSlice';
import Button from '../../../../../components/Button';
import { formatNumber } from '../../../../common/Misc';
import NumberFormat from 'react-number-format';

const Residential = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [rentalId, setRentalId] = useState(0);
  const deleteRentalResidentialDataResponse = useSelector(rentalState);
  let rental_total = 0;
  let rental_sensitised_total = 0;

  const delRental = () => {
    setShowDelete(false);
    dispatch(deleteResidentialRental(jobId, memberId, rentalId));
    dispatch(getRentalListData(jobId, memberId));
  };

  const handleDelete = () => {
    setShowDelete(false);
  };

  const rentalListDataResponse = useSelector(rentalState);
  const { jobId, memberId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteRentalResidentialDataResponse?.message) {
      setShowDelete(false);
      dispatch(getRentalListData(jobId, memberId));
      dispatch(setDeleteRentalResidentialDataResponse({}));
    }
  }, [deleteRentalResidentialDataResponse?.message]);

  useEffect(() => {
    dispatch(getRentalListData(jobId, memberId));
  }, [showDelete]);

  return (
    <div id="myTabjustifiedContent">
      <div
        className="tab-pane fade show active"
        id="commercial-justified"
        role="tabpanel"
        aria-labelledby="commercial-tab"
      >
        <div className="commercialview" style={{ padding: '0' }}>
          <Table className="table">
            <thead>
              <tr>
                <th className="adr text-start" style={{ width: '60%' }}>
                  Address
                </th>
                <th className="rentalex" width="15%">
                  Current Rental Income
                  <br />
                  (Ex. GST p.a.)
                </th>
                <th width="10%">Shading</th>
                <th width="12%">
                  Sensitised Rental
                  <br />
                  (Ex. GST p.a.)
                </th>
                <th width="120"></th>
              </tr>
            </thead>
            <tbody>
              {rentalListDataResponse?.rentalListDataResponse &&
              rentalListDataResponse?.rentalListDataResponse?.data?.data?.length > 0 ? (
                rentalListDataResponse?.rentalListDataResponse?.data?.data.map((item) => {
                  rental_total +=
                    (item?.additional_info?.tenant?.current_rental_income_pa *
                      item?.rental_owners[0]?.pivot?.holding) /
                    100;
                  rental_sensitised_total +=
                    (item?.additional_info?.tenant?.sensitised_rental *
                      item?.rental_owners[0]?.pivot?.holding) /
                    100;
                  return (
                    <tr key={item?.id}>
                      <td className="text-start">{item?.address}</td>
                      <td className="text-end">
                        {formatNumber(
                          (item?.additional_info?.tenant?.current_rental_income_pa *
                            item?.rental_owners[0]?.pivot?.holding) /
                            100
                        )}
                      </td>

                      <td>
                        {item?.additional_info?.tenant?.shading
                          ? item?.additional_info?.tenant?.shading
                          : 0}
                      </td>
                      <td className="text-end">
                        {formatNumber(
                          (item?.additional_info?.tenant?.sensitised_rental *
                            item?.rental_owners[0]?.pivot?.holding) /
                            100
                        )}
                      </td>
                      <td className="actionarea" width="10%">
                        <i
                          className={
                            item?.is_completed == 1
                              ? 'fa fa-check-circle'
                              : 'fa fa-exclamation-triangle'
                          }
                          aria-hidden="true"
                          style={{
                            color: `${item?.is_completed == 1 ? '#42ba96' : '#eed202'}`
                          }}
                        ></i>
                        <Link
                          className="edit"
                          to={
                            '/job/' +
                            jobId +
                            '/data-entry/' +
                            memberId +
                            '/edit-residential-rental/' +
                            item?.id
                          }
                        >
                          <i className="icon-edit"></i>
                        </Link>
                        <a
                          onClick={() => {
                            setRentalId(item?.id);
                            setShowDelete(true);
                          }}
                          className="delete"
                        >
                          <i className="icon-delete"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} style={{ textAlign: 'center' }}>
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td className="text-end">
                  <NumberFormat
                    value={formatNumber(rental_total)}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    // decimalScale={2}
                    displayType="text"
                  />
                </td>
                <td></td>
                <td className="text-end">
                  <NumberFormat
                    value={formatNumber(rental_sensitised_total)}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    // decimalScale={2}
                    displayType="text"
                  />
                </td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
      <Modal
        show={showDelete}
        onHide={handleDelete}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Delete Rental?</h2>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this rental permanently?</p>
        </Modal.Body>
        <div className="modal-action modal-footer">
          <Button className="btn btn-secondary" onClick={handleDelete}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              delRental();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Residential;
