import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SettingBasic from './SettingBasic';
import XpmIntFields from '../settings/XpmIntFields';
import PasswordManagement from '../settings/PasswordManagement';
import PracticeSetting from '../../../src/pages/cms/PracticeSetting';
import Disclaimer from '../settings/Disclaimer';
import UserHeader from '../dashboard/UserHeader';

const Setting = () => {
  const [currentTab, setCurrentTab] = useState('general');

  return (
    <div className="setting toggle-sidebar">
      <UserHeader />
      <div id="main" className="main" style={{ marginTop: '115px' }}>
        <div className="pagetitle tab-title">
          <h1>Settings</h1>
        </div>
        <div className="card">
          <div className="card-body setting-tabs">
            <Tabs
              className="nav nav-tabs d-flex justify-content-center container"
              activeKey={currentTab}
              onSelect={(k) => setCurrentTab(k)}
              id="myTab"
              role="tablist"
            >
              <Tab className="nav-item flex-fill" eventKey="general" title="General">
                {currentTab === 'general' ? <SettingBasic /> : null}
              </Tab>
              <Tab
                className="nav-item flex-fill"
                eventKey="xpm-integration-fields"
                title="XPM Integration Fields"
              >
                {currentTab === 'xpm-integration-fields' ? <XpmIntFields /> : null}
              </Tab>
              <Tab
                className="nav-item flex-fill"
                eventKey="account-management"
                title="Practice Settings"
              >
                {currentTab === 'account-management' ? <PracticeSetting /> : null}
              </Tab>
              <Tab
                className="nav-item flex-fill"
                eventKey="password-management"
                title="Password Management"
              >
                {currentTab === 'password-management' ? <PasswordManagement /> : null}
              </Tab>
              <Tab className="nav-item flex-fill" eventKey="disclaimer" title="Report Disclaimer">
                {currentTab === 'disclaimer' ? <Disclaimer /> : null}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
