import React, { useEffect, useState } from 'react';
//import { Table } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import Header from '../Header';
import DialogModal from '../../../components/modal/DialogModal';
import constants from '../../../constant';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import TableRow from './TableRow';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getxpm, refreshXpm } from '../../../slices/slices/FinancialsSlice';
import {
  getMembers,
  jobSetupState,
  setMemberListPosition
} from '../../../slices/slices/JobSetupSlice';
import { arrayMoveImmutable } from 'array-move';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Info from '../../../components/Info';

const MyTableWrapper = styled.div`
  padding: 10px;

  .fixed_header {
    width: ;
    table-layout: fixed;
    border-collapse: collapse;

    & > tbody {
      display: block;
      width: 807px;
      overflow: auto;
      height: 400px;
      cursor: grabbing;
      background: white;
    }

    & > thead {
      background: yellow;
      color: black;

      & > tr {
        display: block;
        //width: 793px;
      }
    }

    & > thead th,
    & > tbody td {
      padding: 5px;
      text-align: left;
      width: 200px;
      border: 1px solid #000;
    }
  }
`;

const SortableItem = SortableElement((props) => <TableRow {...props} />);

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const Members = () => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { membersData } = useSelector(jobSetupState);
  const [items, setItems] = useState(membersData?.data?.data);

  useEffect(() => {
    setItems(membersData?.data?.data);
  }, [membersData?.data?.data]);

  useEffect(() => {
    dispatch(getMembers(jobId));
  }, []);

  const onSortEnd = ({ oldIndex, newIndex, setErrors }) => {
    if (items.length !== 0 && oldIndex !== newIndex) {
      const setMemberId = items.filter((item, index) => index === oldIndex && item);
      const getData = setMemberId && setMemberId[0]?.id;
      let formData = new FormData();
      formData.append('position', newIndex + 1);
      const updater = (items) =>
        arrayMoveImmutable(items, oldIndex, newIndex).map((item, idx) => {
          return { ...item, order: idx };
        });
      setItems(updater);
      dispatch(setMemberListPosition(jobId, getData, formData, setErrors));
    }
  };

  const xeroLogin = () => {
    dispatch(getxpm('member')).then((response) => {
      localStorage.setItem(
        'data',
        JSON.stringify({
          jobId: jobId
        })
      );
      openInNewTab(response?.data?.data);
    });
  };
  const openInNewTab = (url) => {
    dispatch(refreshXpm()).then(() => {
      window.open(url, '_self');
    });
  };

  return (
    <div className="dashboard toggle-sidebar">
      <Header />
      <div id="main" className="main">
        <div className="group-breadcrumb d-flex justify-content-between align-items-center">
          <div className="breadcrumb-left">
            <ul>
              <li>
                <Link to={'/job/' + jobId + '/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/job/' + jobId + '/group-setup/members'}>Group Setup</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>Members</strong>
              </li>
            </ul>
          </div>
          <div className="breadcrumb-right">
            <DialogModal
              title="Members"
              p1={constants.mHeader}
              p2={constants.mTag}
              p3={constants.mOption1}
              p4={constants.mOption2}
              p5={constants.mOption3}
            />
          </div>
        </div>
        <div className="pagetitle proversion">
          <div className="title_pro d-flex justify-content-between align-items-center">
            <h1>Members</h1>
            <div className="addmemberarea">
              <Info />
              <a className="btn btn-secondary" onClick={xeroLogin}>
                <i className="icon-fileimport"></i>Import from XPM
              </a>
              <Link className="btn btn-primary" to={'/job/' + jobId + '/group-setup/members/add'}>
                <i className="icon-plus"></i>Member
              </Link>
              {/* <Link className="btn btn-primary ms-2" to="/edit-member">
                <i className="icon-plus"></i>Edit Member
              </Link> */}
            </div>
          </div>
        </div>

        <MyTableWrapper className="drag-table" style={{ marginBottom: '30px' }}>
          <table className="table table-light">
            <thead>
              <tr>
                <th style={{ width: '58px' }}></th>
                <th style={{ width: '25%' }} className="align-left">
                  Member Name
                </th>
                <th style={{ width: '25%' }} className="align-left">
                  Activity / Occupation
                </th>
                <th className="align-left" style={{ width: '50%' }}>
                  Notes
                </th>

                <th style={{ width: '77px' }}></th>
              </tr>
            </thead>
            <SortableCont
              onSortEnd={onSortEnd}
              axis="y"
              lockAxis="y"
              lockToContainerEdges={true}
              lockOffset={['30%', '50%']}
              helperClass="helperContainerClass"
              useDragHandle={true}
            >
              {items?.length > 0 ? (
                items?.map((value, index) => (
                  <SortableItem key={`item-${index}`} index={index} data={value} />
                ))
              ) : (
                <tr>
                  <td colSpan={5} style={{ textAlign: 'center' }}>
                    No Records Found
                  </td>
                </tr>
              )}
            </SortableCont>
          </table>
        </MyTableWrapper>
      </div>
    </div>
  );
};

export default Members;
