import React, { useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';

const DialogModal = ({ title, p1, p2, p3, p4, p5, p6 }) => {
  useEffect(() => {});
  return (
    <>
      <NavDropdown title="" id="nav-dropdown" className="d-flex align-items-center icon-info">
        <NavDropdown.Item className="dropdown-item information">
          <h4 className="mt-3">{title}</h4>
          <a href="#" className="close">
            <i className="icon-close" />
          </a>
          <p>{p1}</p>
          <p>{p2}</p>
          <p>{p3}</p>
          <p>{p4}</p>
          <p>{p5}</p>
          <p>{p6}</p>
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

export default DialogModal;
