import React, { useState } from 'react';
import parse from 'html-react-parser';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { deleteMember, getMembers } from '../../../slices/slices/JobSetupSlice';
import CustomModal from '../../../components/CustomModal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { displayNameFromType } from '../../common/Misc';

const TrWrapper = styled.tr`
  cursor: default;

  .firstElement {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &.helperContainerClass {
    width: auto;
    border: 1px solid #efefef;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;

    &:active {
      cursor: grabbing;
    }

    & > td {
      padding: 5px;
      text-align: center;
      width: 200px;
      display: none;
      &:nth-child(2) {
        display: block;
      }
    }
  }
`;

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">
    <div className="draggable-item">
      <span className="column-drag-handle" style={{ float: 'left', padding: '0 10px' }}>
        &#x2630;
      </span>
    </div>
  </Handle>
));

const TableRow = ({ data, className }) => {
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [memberId, setMemberId] = useState(0);
  const { jobId } = useParams();

  const handleCancel = () => {
    setMemberId(0);
    setShowDelete(false);
  };

  const handleDelete = (id) => {
    setShowDelete(false);
    dispatch(deleteMember(jobId, id)).then(() => {
      dispatch(getMembers(jobId));
    });
  };

  const handleSelectMember = (id) => {
    setMemberId(id);
    setShowDelete(true);
  };

  return (
    <TrWrapper className={className}>
      <td>
        <RowHandler />
      </td>
      <td>
        <div className="firstElement">{displayNameFromType(data)}</div>
      </td>
      <td>{data.activity}</td>
      <td>
        {data?.additional_info?.internal_notes
          ? parse(String(data?.additional_info?.internal_notes))
          : ''}
      </td>

      <td className="actionarea" width="10%">
        <i
          className={data?.is_completed == 1 ? 'fa fa-check-circle' : 'fa fa-exclamation-triangle'}
          aria-hidden="true"
          style={{
            color: `${data?.is_completed == 1 ? '#42ba96' : '#eed202'}`
          }}
        ></i>
        <Link className="edit" to={'/job/' + data.job_id + '/group-setup/members/edit/' + data?.id}>
          <i className="icon-edit" />
        </Link>

        <a onClick={() => handleSelectMember(data?.id)} className="delete">
          <i className="icon-delete" />
        </a>
      </td>

      <CustomModal
        value="delete"
        show={showDelete}
        title="Delete Member?"
        text="<p>Deleting this member will remove any distributions, share of profits/losses and taxable amounts made to this member from the member(s) where they originated from.</p>
              <p>Are you sure you want to delete this member?</p>"
        cancel={handleCancel}
        success={handleDelete}
        reference={memberId}
        isHtml={true}
      />
    </TrWrapper>
  );
};

export default TableRow;
