import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const AutomatedSelect = (props) => {
  AutomatedSelect.propTypes = {
    name: PropTypes.any,
    value: PropTypes.any,
    data: PropTypes.any,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    placeholderText: PropTypes.any,
    isOptionDisabled: PropTypes.any,
    headerValue: PropTypes.any
  };

  const [hVal, setHVal] = useState([]);

  useEffect(() => {
    setHVal(props.headerValue);
  });

  return (
    <select
      className="form-select"
      name={props.name}
      value={props.value}
      aria-label="Default select example"
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      disabled={props.isOptionDisabled ? props.isOptionDisabled : ''}
      // headerValue={props?.headerValue ? props.headerValue : []}
    >
      {props.placeholderText !== null && props.placeholderText !== undefined ? (
        <option value="">{props.placeholderText}</option>
      ) : (
        <option value="">Select</option>
      )}
      {hVal !== undefined
        ? props.data
          ? props.data.map((item, index) => {
              return hVal.includes(item.key) ? (
                <optgroup key={index} label={item.value}></optgroup>
              ) : (
                <option key={index} value={item.key}>
                  {item.value}
                </option>
              );
            })
          : null
        : props.data
        ? props.data.map((item, index) => {
            return (
              <option key={index} value={item.key}>
                {item.value}
              </option>
            );
          })
        : null}
      ;
    </select>
  );
};

export default AutomatedSelect;
