import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setErrorMessage } from './AppSlice';

export const practiceSettingInitialState = {
  loading: false,
  practiceSettingDataResponse: {}
};

export const PracticeSettingSlice = createSlice({
  name: 'practiceSetting',
  initialState: practiceSettingInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setUpdatedPracticeResponse: (state, action) => {
      state.updatePracticeSettingResponse = action.payload;
      state.loader = false;
    },
    setPracticeSettingDataResponse: (state, action) => {
      state.getPracticeSettingDataResponse = action.payload;
      state.loader = false;
    }
  }
});

export const addPracticeSetting = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post('practice-setting', data, setErrors, dispatch);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdatedPracticeResponse(response.data));
  }
};

export const getPracticeSettingData = (setErrors) => async (dispatch) => {
  const response = await clientService.get('practice-setting', setErrors, dispatch, false);
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setPracticeSettingDataResponse(response.data));
  }
};

export const { setLoader, setPracticeSettingDataResponse, setUpdatedPracticeResponse } =
  PracticeSettingSlice.actions;

export const practiceSettingState = (state) => {
  return state.practiceSetting;
};

export default PracticeSettingSlice.reducer;
