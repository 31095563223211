import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  authState,
  doLogin,
  xeroLogin,
  setIsOtpVerified,
  setLoginResponse
} from '../../slices/slices/AuthSlice';
import AuthFooter from './Auth-Footer';
import { useNavigate } from 'react-router';
import { LoginSchema } from '../common/Validation';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { RemoveCookie } from '../../services/cookies';
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isUserLoggedIn, loginResponse } = useSelector(authState);
  const handleFormSubmit = (values, { setErrors }) => {
    dispatch(doLogin(values, setErrors));
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      if (loginResponse.data?.is_tfa_enable === false) {
        localStorage.setItem('isOTPVerified', 'true');
        dispatch(setIsOtpVerified(true));
        // setTimeout(() => { // Removed due to the not redirecting dashboard because of HistoryRouter
        navigate('/dashboard');
        // }, 200);
      } else {
        navigate('/authentication');
      }
      dispatch(setLoginResponse({}));
    } else {
      RemoveCookie('token');
      navigate('/login');
    }
  }, [isUserLoggedIn]);

  const loadDynamicScript = (callback) => {
    const existingScript = document.getElementById('xero');

    // if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://edge.xero.com/platform/sso/xero-sso.js'; // URL for the third-party library being loaded.
    script.id = 'xero'; // e.g., googleMaps or stripe
    script.defer = 'defer';
    script.async = 'async';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
    // }
    if (existingScript && callback) callback();
    return () => {
      if (existingScript && callback) {
        existingScript.remove();
      }
    };
  };

  useEffect(() => {
    loadDynamicScript();
  }, []);

  useEffect(() => {
    if (searchParams.get('code')) {
      dispatch(xeroLogin(searchParams.get('code'), {}));
    }
  }, []);
  return (
    <div className="influence-account-page">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="" className="logo d-flex align-items-center w-auto">
                    <img src="images/logo.png" alt="" />
                  </a>
                </div>

                <div className="card mb-3 loginarea">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        email: '',
                        password: ''
                      }}
                      validationSchema={LoginSchema}
                      validateOnBlur={false}
                      validateOnChange={false}
                      onSubmit={handleFormSubmit}
                    >
                      {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="row needs-validation" noValidate>
                          <div className="col-12">
                            <div className="input-group has-validation">
                              <Input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Email Address"
                                id="yourUsername"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                required
                              />
                            </div>
                            <span className="error">{errors.email}</span>
                          </div>

                          <div className="col-12 topspace">
                            <Input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              id="yourPassword"
                              required
                            />
                            <span className="error">{errors.password}</span>
                          </div>

                          <div className="col-12 topgap btnarea justify-content-center">
                            <Button className="btn btn-primary" type="submit">
                              Login
                            </Button>
                          </div>
                          <div className="col-12 topgap btnarea justify-content-center or-txt">
                            OR
                          </div>
                          <div className="col-12 topgap btnarea justify-content-center">
                            <span
                              data-xero-sso
                              data-label="Sign In with Xero"
                              data-theme="light"
                              data-size="large"
                              data-border-radius="20"
                            ></span>
                          </div>
                        </form>
                      )}
                    </Formik>

                    <div className="col-12 justify-content-center middletext">
                      <p className="topspace">
                        <a href="/forgot-password">Forgot password?</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AuthFooter />
    </div>
  );
};

export default Login;
